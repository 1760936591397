import React,{useState,useEffect,useRef} from 'react';
import { Grid } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
function MultipleEmail (props) {
  const {component}=props;
  const [inputValue, setInputValue] = useState("");
  const [emailChips, setEmailChips] = useState([]);
  const [tempEmailChips, setTempEmailChips] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorClass, setErrorClass] = useState([""]);
  const inputElement=useRef();
  var validations = [];
  var errorMessages = [];
  var regex =new RegExp(component.ValidationRegex);
  useEffect(()=>{

      let{component: {SubApplicationName,AttributeName},pagelevelValues}=props;
      if(pagelevelValues && pagelevelValues[SubApplicationName] && pagelevelValues[SubApplicationName][AttributeName]){
        let initialValueForEmail=pagelevelValues[SubApplicationName][AttributeName];
        if(Array.isArray(initialValueForEmail)){
          setTempEmailChips(prev=>{
            return[...initialValueForEmail]
          })
        }
      }
      
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("IsEmptyList", () => {
      if (emailChips.length>0) {
        return true;
      }
      return false;
    });
    ValidatorForm.addValidationRule("IsEmailList", () => {
      if (!hasError) {
        return false;
      }
      return true;
    });
  },[])
  useEffect(()=>{
    setHasError(false);
    let styleClass=[];
    let filteredMailList=isInList(tempEmailChips)
      filteredMailList.forEach((mail, idx) => {
        if (!regex.test(mail)) {
          styleClass[idx] = "withError";
          setHasError(true);
        } else {
          styleClass[idx] = "";
        }
      });
    setErrorClass([...styleClass]);
    setEmailChips([...filteredMailList]);
    let value = Object.assign({}, { [component.AttributeName]: filteredMailList });
    props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex, props);
  },[tempEmailChips])
  const addEmailToList = (e) => {
    if ((e.key === "Enter" || e.key === "Tab" || e.key === ",") && inputValue !== '') {
      e.preventDefault();
      let Value = [...emailChips];
      Value.push(e.target.value)
      if (!regex.test(e.target.value)) {
        return;
      }
      setTempEmailChips([...Value]);
      setInputValue("");
    }
    else if(e.key === "Backspace" && inputValue === '' && !(component.isReadOnly)){
      e.preventDefault();
      emailChips.splice(-1);
      setTempEmailChips(emailChips);
    }
  };
  const isInList = (emails) => {
    return emails.filter((item,
      index) => emails.indexOf(item) === index);
  };
  const focusInput=()=>{
    inputElement.current.focus();
  }
  const onPasteEventHandler = (evt) => {
    evt.preventDefault();
    setInputValue('')
    var paste = evt.clipboardData.getData("text");
    let pasteArr = paste.split(",");
    var emails = [...emailChips, ...pasteArr];
    if (emails.length>0) {
      setTempEmailChips([...emails]);
    }
  };
  let autocomplete="off";
  let custom3=component.Custom3 && JSON.parse(component.Custom3);
  (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
 
  const isRequired = component.IsRequired === "Y" ? true : false;
  let isReadOnly = component.IsReadonly;
  var componentLabel;
  if(component.ComponentLabel){
    componentLabel = isRequired ? component.ComponentLabel + " *" : component.ComponentLabel;
  }
  const deleteChip = (idx) => {
    let currentChips = JSON.parse(JSON.stringify(emailChips));
    currentChips.splice(idx, 1);
    setTempEmailChips(currentChips);
  };
  if (isRequired && emailChips.length<=0 && inputValue==='') {
    validations.push("required");
    errorMessages.push(component.RequiredMessage);
    validations.push("IsEmpty");
    errorMessages.push(component.RequiredMessage);
  }
  if(inputValue !== ''){
    validations.push("isEmail");
    errorMessages.push(component.ValidatorMessage);
  }
  if(isRequired && emailChips.length<=0 && inputValue!==''){
    validations.push('IsEmptyList')
    errorMessages.push("Please Enter atleast one Email.")
  }
  if(hasError){
      validations.push('IsEmailList')
      errorMessages.push("Please Check if all Emails are valid");
    }
  console.log("emaildsfa",emailChips)
return (
    <Grid onClick={focusInput} item xs={12} sm={12} md={component.Size} lg={component.Size}  className={component.AttributeStyleClass}>
      <div className='multipleMailWrapper'>
      <div className={`tags-input ${emailChips.length > 0 ? '': 'hide'}`}>
        <ul id="tags">
          {emailChips.length > 0
            ? emailChips.map((emailChip, idx) => {
                return (
                  <li key={idx} className={`tag ${errorClass[idx]}`}>
                    <span className="tag-title">{emailChip}</span>
                    <i
                      className={`material-icons tag-close-icon ${isReadOnly && 'hide'}`}
                      onClick={() => deleteChip(idx)}
                    >
                      close
                    </i>
                  </li>
                );
              })
            : null}
        </ul> 
        </div>
        <TextValidator
        className='EmailText'
        inputRef={inputElement}  
        name={component.AttributeName}
        onChange={(e)=>setInputValue(e.target.value)} 
        onPaste={onPasteEventHandler}
        onKeyDown={addEmailToList}
        defaultValue={inputValue} 
        value={inputValue}
        disabled={isReadOnly}
        validators={validations}
        errorMessages={errorMessages}
        placeholder={componentLabel} 
        autoComplete={autocomplete}
        />
      </div>
    </Grid>
  );
}
export default MultipleEmail;
