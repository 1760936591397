import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { CardContent } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { v4 as uuidv4 } from "uuid";
import * as authaction from "../../../actions/authActions";
import {
  setprimaryState,
  setAdditionalCoverageVisibility,
  setStateList,
  setFlow
} from "../../../actions/applicationMetadataAction";

import dynamicRender from "../sections/CustomComponents/dynamicRender";
import childComp from "../sections/CustomComponents/ChildComponent/childComponent";
import { EntitySubAppTranslation } from "../utils/requestConstruction/ResponseTranslation";
import TemplateRendering from "../sections/CustomComponents/Location/TemplateRendering";
import {
  LocationandBuildingAxios,
  getServiceRequestDetail,
} from "../utils/Common/API";
import PageLoader from "../controls/PageLoader";
import { invokeGetProductDetailsUtil,renderChildrenWithProps, updateValueWithSubapplicationName } from "../utils/Common/CommonUtils";
import { withRouter } from "react-router-dom";
import { triggerToast } from "../utils/Common/HelperUtils";
import { getNavigation } from "../../../CommonAxios/ResourceAxios";
import {
  templateConstruct
} from "../utils/Common/Navigation_Util";
import * as actionType from "../../../actions/types";
import store from "../../../store";
import PropsContext from "../sections/CustomComponents/Submission/PropsContext";
import RenderTemplate from "../../../template/RenderTemplate";
import { setUpdateExpandPanel,hideLoader, showLoader, setexpandPanelDetailData } from "../../../actions/UIFlowActions";
import { setNavigation, setPageNavigation } from "../../../actions/navigationAction";
import { setFindConsoleInfo } from "../../../actions/UIFlowActions";
import { commonButtonMethod } from "../controls/ButtonUtil";
import { constructAndInvokeMetadataCall } from "../utils/Common/MetaUtils";
import {  handleInputCallbackV1, handleInputChangeV4 } from "../utils/Common/InputUtils";

const initialState = {
  currentIDs: [],
  pageMetadata: {},
  summarySubApp: [],
  subappNameList: [],
  detailSummaryList: {},
  metadataapicallflag: false,
  pagelevelValues: {},
  renderTemplate: false,
  isExpand: false,
  transactionPrimaryObject: {},
  values: {},
  subAppGroupName: undefined,
  loading: false,
  templateClassName:"",
  dynamicLoadingMessage: '',
  intervalData: ''
};

class DynamicComponentHOC extends Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(JSON.stringify(initialState));
    this.childform = React.createRef();
    this.cancelAPICall = React.createRef();
  }
  static contextType=PropsContext;
  async componentDidMount() {
    const LOB =
      this.props.UIFlowReducer.productDetails.hasOwnProperty(
        "LineOfBusiness"
      ) && this.props.UIFlowReducer.productDetails["LineOfBusiness"];
    const key = this.props.templateName ? this.props.templateName : LOB;
    if (this.props.utilfunction[key].hasOwnProperty("componentDidMount")) {
      let values = await this.props.utilfunction[key].componentDidMount(
        this.state,
        this.props,
        this.UpdateState
      );
      if (values && Object.keys(values).length > 0) this.updateValues(values);
    }
  }
  async componentDidUpdate(prevProps, prevState, snapshot) {
    const LOB =
      this.props.UIFlowReducer.productDetails.hasOwnProperty(
        "LineOfBusiness"
      ) && this.props.UIFlowReducer.productDetails["LineOfBusiness"];
    const key = this.props.templateName ? this.props.templateName : LOB;

    if (this.props.utilfunction[key].hasOwnProperty("componentDidUpdate")) {
      let values = await this.props.utilfunction[key].componentDidUpdate(
        this.state,
        this.props,
        prevState,
        prevProps,
        this.UpdateState
      );
      if (values && Object.keys(values).length > 0) this.updateValues(values);
    }
  }
  componentWillUnmount(){
    const LOB =
      this.props.UIFlowReducer.productDetails.hasOwnProperty(
        "LineOfBusiness"
      ) && this.props.UIFlowReducer.productDetails["LineOfBusiness"];
    const key = this.props.templateName ? this.props.templateName : LOB;
    if (this.props.utilfunction[key].hasOwnProperty("componentWillUnmount")) {
      this.props.utilfunction[key].componentWillUnmount(
        this.props,
        this.state,
        this.UpdateState
      );
    }
  }
  async componentWillReceiveProps(nextProps) {
    const key = this.props.templateName
      if (
        this.props.utilfunction[key].hasOwnProperty("componentWillReceiveProps")
      ) {
        await this.props.utilfunction[
          this.props.templateName
        ].componentWillReceiveProps(
          this.state,
          this.props,
          nextProps,
          this.updateValues
        );
      }
  }
  executeScroll = () => {
    try {
      let formRef= this.childform.current;
      formRef = formRef ? formRef : this.childform
      ReactDOM.findDOMNode(formRef.errors[0]).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      if (false || !!document.documentMode) {
        window.scrollBy(0, -120);
      }
    } catch (error) {
      console.log('Error in executeScroll',error);
    }
  };
  handleRTEChange = (value, component) => {
    let attributeName = component.AttributeName;
    let selectedValue = value.toString("html");
    let attributeValue = selectedValue;
    this.setState((prevState) => {
      let subappName = component.SubApplicationName;
      let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
      let transactionPrimaryObject = JSON.parse(
        JSON.stringify(prevState.transactionPrimaryObject)
      );
      if (!pagelevelValues[subappName]) {
        pagelevelValues[subappName] = {};
      }
      pagelevelValues[subappName][attributeName] = attributeValue;

      if (!transactionPrimaryObject[subappName]) {
        transactionPrimaryObject[subappName] = {};
      }
      transactionPrimaryObject[subappName][attributeName] = attributeValue;
      return {
        pagelevelValues,
        transactionPrimaryObject,
      };
    });
  };

  getStateList = () => {
    this.props.showLoader();
    //API Call
    let request = {};
    request.EventName = this.props.pageInfo.getListEventName;
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["ServiceRequestDetail"] = Object.assign(
      {},
      getServiceRequestDetail()
    );
    request.ID = this.props.metadataReducer.submissionResponse.SubmissionID;
    LocationandBuildingAxios.post("/core/invokeWorkflowEvent", request)
      .then((response) => {
        if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
          throw new Error("Response Failed");
        }else{
        let responseArr = response.data.StateList;
        let primaryState = responseArr.find(
          (el) => el.SolartisIsPrimaryState === "Yes"
        );
        primaryState && this.props.setprimaryState(primaryState.State);
        this.props.setStateList(responseArr);
        }
      })
      .catch((err) => {
        console.log(err);
        triggerToast(err.message)
      });
    this.props.hideLoader();
  };

  handleLoader = (params) => {
    this.setState({ loading: params });
  };

  hocFunctions = {
    loader: this.handleLoader,
  };
  onKeyDown = async (e,props) =>{
    if (this.props.utilfunction && this.props.utilfunction[this.props.templateName].onKeyDown)
    {
       await this.props.utilfunction[ this.props.templateName].onKeyDown(e, props);
    }
  }

  handleInputChangeV2 = async (
    value,
    component,
    isDynamicTable,
    datatableRowindex,
    props
  ) => {

   const functions = {
      updateValues: this.updateValues,
      UpdateState: this.UpdateState,
      UpdateCallBack: this.UpdateCallBack
    }


    // For Verdant: we check the key in the template if key exist this below method or existing method
    if(this.props.pageInfo && this.props.pageInfo.handleAsyncUpdate === true){
      let {currentStateUpdate='Y', immediateParentUpdate=false} = this.props.pageInfo
      currentStateUpdate = currentStateUpdate === 'Y'
      immediateParentUpdate = immediateParentUpdate === 'Y'
      if(currentStateUpdate){
      this.setState((state)=>{
      let obj = {}
      obj = handleInputChangeV4(value, component, this.props, state, functions, props)
       return obj;
      }, async()=>{
          try{
              await handleInputCallbackV1(value,component,this.props,this.state, functions, props, this.cancelAPICall)
          }
          catch(e){
              console.log("Error in HandleInputchangeV2", e)
          }
      })
      }
      if(immediateParentUpdate && this.props.parentFunctions){
        await this.props.parentFunctions.handleInputChangeV2( value,
          component,
          isDynamicTable,
          datatableRowindex,
          props)
      }
  }
  else{
    let parentStateRequired = true;
    let { parentStateUpdateNeeded = true } =
      this.props.utilfunction[this.props.templateName];
    parentStateRequired = parentStateUpdateNeeded;
    if (
      this.props.utilfunction &&
      this.props.utilfunction[this.props.templateName].handleInputChangeV2
    ) {
      let values = await this.props.utilfunction[
        this.props.templateName
      ].handleInputChangeV2(
        value,
        component,
        isDynamicTable,
        datatableRowindex,
        this.props,
        this.state,
        this.hocFunctions,
        props
      );
      this.updateValues(values);
    }

    if (parentStateRequired)
      this.props.handleInputChangeV2(
        value,
        component,
        isDynamicTable,
        datatableRowindex,
        props
      );
    }
  };

  UpdateState = (statename, value, rerender) => {
    if (rerender) {
      this.setState({
        [statename]: value,
      });
    } else {
      this.state[statename] = value;
    }
  };

//Iterator component Fix
  UpdateCallBack = async(callback, props, obj)=>{
  this.setState((state)=>{
    let newObj = callback(state, props, obj)
        return {
          ...state,
          ...newObj
        }
      });
  };

  callMetadata = async (nextProps, callEntitySubapptranslation) => {
    this.props.showLoader();
    const { transactionPrimaryObject, objectId, objectVerId } = nextProps;
    let subappinfo = {
      subappName: this.state.subappNameList,
      objectId,
      objectVerId,
      state: nextProps.getApiResponse.Policy.State,
      subApplicationGroupList: this.state.subAppGroupName,
    };
    await constructAndInvokeMetadataCall(
      subappinfo,
      { transactionPrimaryObject, EffectiveDate: "2021-04-20" },
      (request, response) => {
        // this.props.setMetadataRequest(JSON.stringify(request, null, 2));
        // this.props.setMetadataResponse(JSON.stringify(response, null, 2));
      }
    );
    callEntitySubapptranslation(nextProps);
    this.props.hideLoader();
  };
  callEntitySubapptranslation = async (nextProps, state) => {
    this.props.showLoader();
    let translatedApiResponse = {};
    const { transactionPrimaryObject, objectId, objectVerId } = nextProps;
    if (
      nextProps.getApiResponse &&
      Object.keys(nextProps.getApiResponse).length !== 0
    ) {
      translatedApiResponse = await EntitySubAppTranslation(
        nextProps.getApiResponse,
        this.props,
        nextProps.pagelevelValues
      );
    }

    Object.keys(translatedApiResponse).forEach((subappName) => {
      if (Array.isArray(translatedApiResponse[subappName])) {
        if (subappName.endsWith("Summary")) {
          let SName = subappName.toLowerCase();
          this.props.updateArrayValueOnState(
            subappName,
            translatedApiResponse[subappName]
          );
          SName = SName + "_dataList";
          this.setState({
            [SName]: translatedApiResponse[subappName],
          });
        }
      }
    });
    this.setState((prevState) => {
      let pagelevelValues = Object.assign(
        {},
        prevState.pagelevelValues,
        translatedApiResponse
      );
      let transactionPrimaryObject = Object.assign(
        {},
        prevState.transactionPrimaryObject,
        pagelevelValues
      );
      return { transactionPrimaryObject, pagelevelValues };
    });
    this.props.setprimaryState(nextProps.getApiResponse.Policy.State);
    let subappinfo = {
      subappName: this.state.subappNameList,
      objectId,
      objectVerId,
      state: nextProps.getApiResponse.Policy.State,
      subApplicationGroupList: this.state.subAppGroupName,
    };
    constructAndInvokeMetadataCall(
      subappinfo,
      { transactionPrimaryObject, EffectiveDate: "2021-04-20" },
      this.metadataAPI_callback
    );
  };
  metadataAPI_callback = (request, response) => {
    this.setState(
      (prevState) => {
        let pageMetadata = JSON.parse(JSON.stringify(prevState.pageMetadata));
        pageMetadata = response;
        return { pageMetadata };
      },
      () => {
        this.props.hideLoader();
      }
    );
  };

  callback = async (state) => {
    this.handleLoader(true);
    const { objectId, objectVerId } = this.props;
    let transactionPrimaryObject = {
      ...this.state.transactionPrimaryObject,
      ...this.props.transactionPrimaryObject,
    };
    let pagelevelValues = {};
    if (
      (this.state.subappNameList && this.state.subappNameList.length !== 0) ||
      (this.state.subAppGroupName && this.state.subAppGroupName.length > 0)
    ) {
      pagelevelValues = transactionPrimaryObject;

      const primaryState = state
        ? state
        : this.props.metadataReducer.primaryState !== undefined &&
          this.props.metadataReducer.primaryState !== ""
        ? this.props.metadataReducer.primaryState
        : "CW";
      let EffectiveDate = this.props.values && this.props.values.EffectiveDate;
      let subappinfo = {
        subappName: this.state.subappNameList,
        objectId,
        objectVerId,
        state: primaryState,
        subApplicationGroupList: this.state.subAppGroupName,
      };
      await constructAndInvokeMetadataCall(
        subappinfo,
        { transactionPrimaryObject: pagelevelValues, EffectiveDate },
        this.metadataAPI_callback
      );
    }
    this.handleLoader(false);
  };
  updateArrayValueOnState = async (key, value, expand) => {
    let arr = [];
    value["isExpanded"] = expand.toString();
    let { ID, uid } = value;
    let checkKey = ID ? "ID" : uid ? "uid" : null;

    if (this.state[key] !== undefined && Array.isArray(this.state[key])) {
      arr = this.state[key];
    }

    if (
      (checkKey === "ID" && ID !== undefined) ||
      (checkKey === "uid" && uid !== undefined)
    ) {
      let updated = false;
      await arr.map((el, idx) => {
        if (el[checkKey] === value[checkKey]) {
          arr[idx] = { ...value };
          updated = true;
        }
      });
      if (updated === false) {
        arr.push(value);
      }
    } else {
      arr.push(value);
    }
    this.setState({ [key]: arr });
  };
  onClickSave = (key, subname, value, expand, ID) => {
    this.props.updateArrayValueOnState && this.props.updateArrayValueOnState(subname, value,expand);
    this.updateArrayValueOnState(key, value, expand);
  };
  addRow = (key) => {
    let uid = uuidv4();
    this.updateArrayValueOnState(key, { uid, isExpanded: "true" }, "true");
  };

 addRowIterator = async(dataListName,props) => {
  let currentStateUpdate = !("currentStateUpdate" in this.props.pageInfo) || this.props.pageInfo.currentStateUpdate === 'Y'
  let immediateParentUpdate = "immediateParentUpdate" in this.props.pageInfo && this.props.pageInfo.immediateParentUpdate === 'Y'
  let IteratordataList;
  let IteratorIndex = props.componentState.IteratorIndex
  let ResponseKey = props.componentState.ResponseKey

  if(currentStateUpdate){
    IteratordataList = props.componentState[ResponseKey]
  }
  if(immediateParentUpdate){
     IteratordataList = props.componentProps[ResponseKey]
  }

  let uid = uuidv4();
  let defaultValue =  { uid, isExpanded: "true" }
  if(!(dataListName in IteratordataList[IteratorIndex])){
    IteratordataList[IteratorIndex][dataListName] =  []
  }
  IteratordataList[IteratorIndex][dataListName].push(defaultValue)
  if(currentStateUpdate){
    this.setState({ [ResponseKey]: IteratordataList });
  }
  if(immediateParentUpdate){
    this.props.UpdateState(ResponseKey, IteratordataList, true)
  }
  }



 onExpandIterator = (key, value, isExpand,props) => {
  let { ID, uid } = value;
  let checkKey = ID ? "ID" : uid ? "uid" : null;
  let iteratorIndex = props.componentState.IteratorIndex
  let datalistName =  props.componentState["ResponseKey"]
  let datalist = props.componentProps[datalistName]
  const getDataList  = () => {
    let arr = datalist[iteratorIndex][key];
      arr.map((el, idx) => {
        if (el[checkKey] === value[checkKey]) {
          arr[idx]["isExpanded"] = isExpand.toString();
          return arr;
        }
      });
      return { [key]: arr };
  }
  if (checkKey !== null) {
  let currentStateUpdate = !("currentStateUpdate" in this.props.pageInfo) || this.props.pageInfo.currentStateUpdate === 'Y'
  let immediateParentUpdate = "immediateParentUpdate" in this.props.pageInfo && this.props.pageInfo.immediateParentUpdate === 'Y'
  if(currentStateUpdate){
    this.setState((prevState) => {
      getDataList()
    });
  }
  if(immediateParentUpdate){
    Object.entries(getDataList()).map(([key, value])=> {
      this.props.UpdateState(key, value, true)
    })
  }
  }
};

  deleteRow = (key, value, SubApplnName) => {
    let callDeleteApi = true;
    let { ID, uid } = value;
    let checkKey = ID ? "ID" : uid ? "uid" : null;

    if (uid) {
      callDeleteApi = false;
    }
    let subappname = SubApplnName;
    this.props.deleteArrayValue(subappname, value, callDeleteApi);

    if (checkKey !== null) {
      this.setState((prevState) => {
        let arr = prevState[key];
        arr.map((el, idx) => {
          if (el[checkKey] === value[checkKey]) {
            arr.splice(idx, 1);
            return arr;
          }
        });
        return { [key]: arr };
      });
    }
  };
  onExpand = (key, value, isExpand) => {
    let { ID, uid } = value;
    let checkKey = ID ? "ID" : uid ? "uid" : null;

    if (checkKey !== null) {
      this.setState((prevState) => {
        let arr = prevState[key];
        arr.map((el, idx) => {
          if (el[checkKey] === value[checkKey]) {
            arr[idx]["isExpanded"] = isExpand.toString();
            return arr;
          }
        });
        return { [key]: arr };
      });
    }
  };

  handleSubmit = async (e) => {
    if(e.target.somevalue){
    let {propsObj,stateObj,component,functions,props} = e.target.somevalue
    let ActionNeeded = component.Action ? Object.keys(component.Action).length :false;
    if(ActionNeeded)
      commonButtonMethod(component, {...props}, stateObj, functions, e)
    else
      props[component.OnClickAction] && props[component.OnClickAction](component.EventName,propsObj,stateObj,component,functions,e,props)
    }
    if (this.props.pageInfo.action === "GetSubmissionInfo_WF") {
      this.props.showLoader();
      let LOB = this.props.metadataReducer.lob;
      let responseSuccess = await this.props.utilfunction[LOB].UpdateSubmissionInfo(
        this.props,
        this.state
      );
      if (responseSuccess) {
        let additionalCoverageVisibility =
          this.props.metadataReducer.additionalCoverageVisibility;
        additionalCoverageVisibility[LOB] = "true";
        this.props.setAdditionalCoverageVisibility(
          additionalCoverageVisibility
        );
      }
    }
  };
  updateValues = (obj) => {
    this.setState({
      ...this.state,
      ...obj,
    });
  };

  saveTableData = (
    tablename,
    attributeName,
    attributeValue,
    datatableRowindex
  ) => {
    this.setState((prevState) => {
      let tableData = prevState[tablename];

      tableData = tableData.map((el) => {
        if (el === datatableRowindex) {
          el[attributeName] = attributeValue;
        }
        return el;
      });
      return { [tablename]: tableData };
    });
  };

  handleDateChange = (event, value, AttributeName, component) => {
    let parentStateRequired = true;

    let { parentStateUpdateNeeded = true } =
      this.props.utilfunction[this.props.templateName];
    parentStateRequired = parentStateUpdateNeeded;
    if (
      this.props.utilfunction &&
      this.props.utilfunction[this.props.templateName].handleDateChange
    ) {
      let { pagelevelValues, values } = this.props.utilfunction[
        this.props.templateName
      ].handleDateChange(event, value, AttributeName, component, this.state);
      this.UpdateState("values", values, false);
      this.UpdateState("pagelevelValues", pagelevelValues, false);
      this.UpdateState("transactionPrimaryObject", pagelevelValues, false);
    }

    if (parentStateRequired)
      this.props.handleDateChange(event, value, AttributeName, component);
  };

  updateValueWithSubplicationName = async(
    updatedValue,
    component,
    props,
    prevProps
  ) => {


    let parentStateRequired = true;
    let { parentStateUpdateNeeded = true } =
      this.props.utilfunction[this.props.templateName];
    parentStateRequired = parentStateUpdateNeeded;
    let {updateValueWithIndex=false, currentStateUpdate='Y', immediateParentUpdate=false} = this.props.pageInfo


    if(updateValueWithIndex){
       currentStateUpdate = currentStateUpdate === 'Y'
       immediateParentUpdate = immediateParentUpdate === 'Y'
      if(currentStateUpdate){
        this.setState(async(prev)=>{
          let values = await updateValueWithSubapplicationName(updatedValue, component, prev, props);
          return {...prev,...values}
        })
      }
      if(immediateParentUpdate){
         await this.props.parentFunctions.updateValueWithSubplicationName(updatedValue,component, props)
      }
      return;
    }

    if (
      this.props.utilfunction[this.props.templateName]
        .updateValueWithSubplicationName
    ) {
      let values = this.props.utilfunction[
        this.props.templateName
      ].updateValueWithSubplicationName(updatedValue, component, this.state);
      this.UpdateState("pagelevelValues", values, false);
      this.UpdateState("transactionPrimaryObject", values, true);
    }

    if (parentStateRequired) {
      if (
        this.props.pagelevelValues.SelectedLineOfBusiness.IsMultiLob ===
          "true" &&
        prevProps &&
        props &&
        prevProps.state1.tabPosition === props.state1.tabPosition
      )
        this.props.updateValueWithSubplicationName(
          updatedValue,
          component,
          props,
          prevProps
        );
      if (
        this.props.pagelevelValues.SelectedLineOfBusiness.IsMultiLob !== "true"
      )
        this.props.updateValueWithSubplicationName(
          updatedValue,
          component,
          props,
          prevProps
        );
    }
  };

  handleHiddenInputChange = (component) => {
    let parentStateRequired = true;
    let { parentStateUpdateNeeded = true } =
      this.props.utilfunction[this.props.templateName];
    parentStateRequired = parentStateUpdateNeeded;
    if (
      this.props.utilfunction[this.props.templateName].handleHiddenInputChange
    ) {
      let values = this.props.utilfunction[
        this.props.templateName
      ].handleHiddenInputChange(component, this.state);
      this.updateValues(values);
    }

    if (parentStateRequired) {
      this.props.handleHiddenInputChange(component);
    }
  };

  handleStateCollapseName = (value, data) => {
    let derivedName = value;
    if (
      this.props.utilfunction[this.props.templateName].handleStateCollapseName
    ) {
      derivedName = this.props.utilfunction[
        this.props.templateName
      ].handleStateCollapseName(value, data);
    }

    return derivedName;
  };
  getDocument = (props) => {
    const getDocument = this.props.utilfunction[this.props.templateName][props.component.SubApplicationName.toLowerCase()].getDocument || this.props.utilfunction[this.props.templateName].getDocument;

    getDocument && getDocument(props);
  };
  childTableEditHandler = (params) => {
    if (
      this.props.utilfunction[this.props.templateName].hasOwnProperty(
        "childTableEditHandler"
      )
    ) {
      this.props.utilfunction[this.props.templateName].childTableEditHandler(
        this.props,
        this.state,
        params,
        this.UpdateState
      );
    }
  };
  childTableDeleteHandler = (params) => {
    if (
      this.props.utilfunction[this.props.templateName].hasOwnProperty(
        "childTableDeleteHandler"
      )
    ) {
      this.props.utilfunction[this.props.templateName].childTableDeleteHandler(
        this.props,
        this.state,
        params,
        this.UpdateState
      );
    }
  };
  navigateNext = async (e,component) => {
    if(component.OnClickAction==="startNewSubmisssion"){
        await store.dispatch(authaction.resetsubmission());
    }
    let prdObj = await invokeGetProductDetailsUtil(component);
    let navObj = await getNavigation(["ProductNavigation"],[prdObj.ProductVerNumber]);

    let templatefromredux = this.props.templateInfo;

    if (navObj.hasOwnProperty("productNavigation")) {
      let key = prdObj.LOBName ? `${prdObj.LOBName}_submission` : "template";
      key = navObj.productNavigation[key] ? key : "template"
      templateConstruct(navObj, ['template'], templatefromredux);
      let prodnav = navObj.productNavigation[key];
      let pageNav = Object.assign({}, this.props.navigation);

      pageNav.pageNavigation.submission.sidebar[1].children = prodnav;
      this.props.setNavigation(pageNav);
      this.props.setPageNavigation(prodnav);
      store.dispatch({
        type: actionType.SET_NAVIGATION,
        payload: {...this.props.navigation,"productNavigation":{...navObj.productNavigation}},
    });
    }
    this.props.history.push("/submission");
  };
  handleAutoCompletechange = (value, item, component) => {

    let autoComplete_values = {};
    autoComplete_values=value;
      this.setState((prevState) => {
        let values = Object.assign({}, prevState.values);
        let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
        let transactionPrimaryObject = JSON.parse(JSON.stringify(prevState.transactionPrimaryObject));
        if(!pagelevelValues[component.SubApplicationName])
        {
          pagelevelValues[component.SubApplicationName]={}
        }
        pagelevelValues={...pagelevelValues,[component.SubApplicationName] : {...pagelevelValues[component.SubApplicationName],...value}}
        if(!transactionPrimaryObject[component.SubApplicationName])
        {
          transactionPrimaryObject[component.SubApplicationName]={}
        }
        transactionPrimaryObject={...transactionPrimaryObject,[component.SubApplicationName] : {...transactionPrimaryObject[component.SubApplicationName],...value}}
        values = Object.assign({}, values, autoComplete_values);

        return {
          values,
          pagelevelValues,
          transactionPrimaryObject,
        };
      });
  };
  initializeMultipleMail=(props,
setTempEmailChips)=>{
  try{
    let{component: {SubApplicationName,AttributeName},pagelevelValues}=props;
    if(pagelevelValues && pagelevelValues[SubApplicationName] && pagelevelValues[SubApplicationName][AttributeName]){
      let initialValueForEmail=pagelevelValues[SubApplicationName][AttributeName];
      if(Array.isArray(initialValueForEmail)){
        setTempEmailChips(prev=>{
          return[
            ...initialValueForEmail
          ]
        })
      }
    }
  }catch(e){
    console.log('Error in initializeMultipleMail_HomeOwners',
    e);
  }
}
  render() {
    let subapplicationList = [];
    let contextValue={}
    if(this.context!==undefined){
      contextValue=this.context;
    }
    let isChildNeeded = this.props.pageInfo.hasOwnProperty("isChildNeeded")
      ? this.props.pageInfo.isChildNeeded
      : false;
    let hasChildren =
      this.props.pageInfo.hasOwnProperty("children") &&
      this.props.pageInfo.children != null &&
      this.props.pageInfo.children.length > 0
        ? true
        : false;
    let childrenProps = {
      ...this.state,
      "contextValue": {...contextValue},
      "parentState": {"UpdateState": this.UpdateState, "UpdateCallBack": this.UpdateCallBack},
      values: { ...this.state.values, ...this.props.values },
      parentFunctions: {
        childTableEditHandler: this.childTableEditHandler,
        childTableDeleteHandler: this.childTableDeleteHandler,
        updateValues:this.updateValues,
        updateValueWithSubplicationName: this.updateValueWithSubplicationName,
        handleInputChangeV2: this.handleInputChangeV2
      },
      "parentPropsValue":this.props
    };
    if (this.state.pageMetadata.ApplicationDetail) {
      subapplicationList =
        this.state.pageMetadata.ApplicationDetail &&
        this.state.pageMetadata.ApplicationDetail.SubApplicationDetailList;
    }
    const TableControls = {
      tableUtils: {
        AddRow: this.addRow,
        onExpand: this.onExpand,
        onChildSave: this.onClickSave,
        EditRow: "",
        OnDetailEdit: "",
        redirectTo: "",
      },
      TableOptions: {
        ChildrenSubApplicationName: null,
        ChildrenComponent: childComp,
        AddButtonNeeded: true,
        SortingNeeded: false,
        FilteringNeeded: false,
        FilterIconNeeded: false,
        rowsPerPageOptions: [5, 10, 25],
        RowsPerPage: 5,
        ActionName: "Action",
        ActionListOptions: [
          {
            ActionName: "RemoveVehicle",
            ActionDisplayName: "Delete",
            OnOptionClick: this.deleteRow,
          },
        ],
      },
      tableFunctions: {
        "addRow": this.addRow,
        "onExpand": this.onExpand,
        "onChildSave": this.onClickSave,
        "deleteRow": this.deleteRow,
        "addRowIterator": this.addRowIterator,
        "onExpandIterator": this.onExpandIterator
    }
    };

    const functions = {
      updateValueWithSubplicationName: this.updateValueWithSubplicationName,
      handleHiddenInputChange: this.handleHiddenInputChange,
      handleInputChangeV2: this.handleInputChangeV2,
      navigateNext: this.navigateNext,
      handleRTEChange: this.handleRTEChange,
      UpdateState: this.UpdateState,
      UpdateCallBack: this.UpdateCallBack,
      handleInputChange: this.props.handleInputChange,
      handleAutoCompletechange: this.handleAutoCompletechange,
      initializeMultipleMail: this.initializeMultipleMail,
      handleDateChange: this.handleDateChange,
      invokeGetAccount: this.props.invokeGetAccount,
      saveTableData: this.saveTableData,
      handleStateCollapseName: this.handleStateCollapseName,
      callback: this.callback,
      getDocument: this.getDocument,
      focusToParent: this.props.focusToParent ? this.props.focusToParent : null,
      updateValues:this.updateValues,
      updateStateValues:this.updateValues,
      onKeyDown:this.onKeyDown
    };

    const RenderContent = () => (
      <React.Fragment>
        {this.state.renderTemplate ? (
          <TemplateRendering {...this.props} {...this.state} />
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12} className={`${this.props.className||""} ${this.state.templateClassName || ""}`}>
            <CardContent className="custom-indentation custom-margin-left">
              <Grid container spacing={3}>
                {dynamicRender(
                  subapplicationList,
                  this.props,
                  this.state,
                  functions,
                  TableControls,
                  this.state.summarySubApp,
                  this.state.detailSummaryList,
                  this.state.hierarchicalRendering,
                  this.childform
                )}
              </Grid>
            </CardContent>
            {this.props.metadataReducer.primaryState &&
              this.props.pageInfo.isSavebtn === true && (
                <Grid container className="mt-1-5" justify="flex-end">
                  <div className="form-actions">
                    <Button
                      type="button"
                      color="primary"
                      className="nav-btn theme_color theme_border nav-prev-btn theme_button"
                      onClick={() => this.childform.submit()}
                    >
                      Save
                    </Button>
                  </div>
                </Grid>
              )}
              {renderChildrenWithProps(this.props,childrenProps)}
          </Grid>
        )}
      </React.Fragment>
    );

    const RenderWithOrWithOutForm = (isFormNeeded) => (
      <>
        {isFormNeeded ? (
          <ValidatorForm
            id="child"
            onSubmit={(e) => {
              this.handleSubmit(e);
            }}
            onError={(e) => this.executeScroll()}
            ref={this.childform}
            className="fullWidth mt-1-5"
          >
            {RenderContent()}
          </ValidatorForm>
        ) : (
          RenderContent()
        )}
      </>
    );
    let basePage=this.props.pageInfo.basePage;
    // let {currentPageInfo = {}} = this.state
    let currentPageInfo=this.props.UIFlowReducer && this.props.UIFlowReducer.currentPageInfo && Object.keys(this.props.UIFlowReducer.currentPageInfo).length>0 ? this.props.UIFlowReducer.currentPageInfo: this.state.currentPageInfo ? this.state.currentPageInfo : {};
       return (
      <>
      {
        basePage &&  Object.keys(currentPageInfo).length >0
        ?
        (<>

       <RenderTemplate {...this.props} Template={currentPageInfo.template} />
        </>)
        :
          (<>
            {RenderWithOrWithOutForm(
              this.props.pageInfo.hasOwnProperty("isFormNeeded") &&
              this.props.pageInfo.isFormNeeded === true
            )}
            {isChildNeeded &&
              hasChildren &&
              renderChildrenWithProps(this.props, childrenProps)}
            {(this.state.loading || this.state.dynamicLoadingMessage !== '') && <PageLoader dynamicLoadingMessage={this.state.dynamicLoadingMessage} />}

          </>)
          }
        </>
    );
  }
}

const mapStateToProps = (state) => ({
  metadataReducer: state.metadataReducer,
  navigationReducer: state.navigationReducer,
  navigation: state.navigationReducer.navigation,
  pageNavigation: state.navigationReducer.pageNavigation,
  templateInfo: state.navigationReducer.templateInfo,
  UIFlowReducer: state.UIFlowReducer
});

const mapDispatchToProps = (dispatch) => ({
  showLoader: () => dispatch(showLoader()),
  hideLoader: () => dispatch(hideLoader()),
  setNavigation: (navigation) => dispatch(setNavigation(navigation)),
  setPageNavigation: (navigation) => dispatch(setPageNavigation(navigation)),
  setprimaryState: (primaryState) => dispatch(setprimaryState(primaryState)),
  setAdditionalCoverageVisibility: (additionalCoverageVisibility) =>
  dispatch(setAdditionalCoverageVisibility(additionalCoverageVisibility)),
  setStateList: (stateList) => dispatch(setStateList(stateList)),
  setUpdateExpandPanel: (value) => dispatch(setUpdateExpandPanel(value)),
  setexpandPanelDetailData: (value) => dispatch(setexpandPanelDetailData(value)),
  setfindConsoleInfo: (findConsoleInfo) => dispatch(setFindConsoleInfo(findConsoleInfo)),
  setFlow: (value) =>
  dispatch(setFlow(value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DynamicComponentHOC)
);
