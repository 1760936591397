'use strict';
import { store } from '../../store';
import * as authaction from "../../actions/authActions";
import { setCurrentPageIndex, setConsoleInfo , setCurrentPageInfo} from '../../actions/UIFlowActions';
import appActions from '../../actions';
import { constructEncodedStringSync, decodeString} from '../../views/runtime/utils/Common/NoCodeUtils';
export const __esModule = true;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactstrap = require('reactstrap');

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactPerfectScrollbar = require('react-perfect-scrollbar');

const {actionType} =appActions

var _reactPerfectScrollbar2 = _interopRequireDefault(_reactPerfectScrollbar);
let { withTranslation } = require('react-i18next');

require('react-perfect-scrollbar/dist/css/styles.css');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var propTypes = {
  children: _propTypes2.default.node,
  className: _propTypes2.default.string,
  navConfig: _propTypes2.default.any,
  navFunc: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.string]),
  isOpen: _propTypes2.default.bool,
  staticContext: _propTypes2.default.any,
  tag: _propTypes2.default.oneOfType([_propTypes2.default.func, _propTypes2.default.string]),
  router: _propTypes2.default.any
};

var defaultProps = {
  tag: 'nav',
  navConfig: {
    items: [{
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      badge: { variant: 'info', text: 'NEW' }
    }]
  },
  isOpen: false,
  router: { RsNavLink: _reactstrap.NavLink }
};

// var QuoteNumber = ["Q-7272", "Q-4090"];



var SideNavbar = function (_Component) {

  _inherits(SideNavbar, _Component);

  function SideNavbar(props) {
    _classCallCheck(this, SideNavbar);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.handleClick = _this.handleClick.bind(_this);
    _this.activeRoute = _this.activeRoute.bind(_this);
    _this.hideMobile = _this.hideMobile.bind(_this);
    return _this;
  }

  SideNavbar.prototype.handleClick = function handleClick(e) {
    e.preventDefault();
    e.currentTarget.parentElement.classList.toggle('open');
  };

  SideNavbar.prototype.activeRoute = function activeRoute(routeName, props) {
    return props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open child_align' : 'nav-item nav-dropdown';
  };

  SideNavbar.prototype.hideMobile = function hideMobile() {
    if (document.body.classList.contains('sidebar-show')) {
      document.body.classList.toggle('sidebar-show');
    }
  };

  // nav list


  SideNavbar.prototype.navList = function navList(items) {
    var _this2 = this;
    //console.log("came into navlist->",items);
    return items.map(function (item, index) {

      if (item.display) {
        return _this2.navType(item, index);
      }
    });
  };

  // nav type


  SideNavbar.prototype.navType = function navType(item, idx) {
    return item.title ? this.navTitle(item, idx) : item.divider ? this.navDivider(item, idx) : item.label ? this.navLabel(item, idx) : (item.children && item.children[0] && item.isChildMenuNeeded!==false) ? this.navDropdown(item, idx) : this.navItem(item, idx);
  };

  // nav list section title


  SideNavbar.prototype.navTitle = function navTitle(title, key) {
    //console.log("title->",title);
    var classes = (0, _classnames2.default)('nav-title', title.class);
    return _react2.default.createElement(
      'li',
      { key: key, className: classes },
      this.navWrapper(title),
      ' '
    );
  };

  // simple wrapper for nav-title item


  SideNavbar.prototype.navWrapper = function navWrapper(item) {
    //console.log("item.wrapper->",item.wrapper,item);
    // return item.wrapper  ? _react2.default.createElement(item.wrapper.element, item.wrapper.attributes, item.name) : item.name;
    const { t } = this.props;
    return item.wrapper && item.wrapper.element ? _react2.default.createElement(item.wrapper.element, item.wrapper.attributes, t(item.name)) : t(item.name);
  };

  // nav list divider


  SideNavbar.prototype.navDivider = function navDivider(divider, key) {
    var classes = (0, _classnames2.default)('divider', divider.class);
    return _react2.default.createElement('li', { key: key, className: classes });
  };

  // nav label with nav link


  SideNavbar.prototype.navLabel = function navLabel(item, key) {
    var classes = {
      item: (0, _classnames2.default)('hidden-cn', item.class),
      link: (0, _classnames2.default)('nav-label', item.class ? item.class : ''),
      icon: (0, _classnames2.default)('nav-icon', !item.icon ? 'fa fa-circle' : item.icon, item.label.variant ? 'text-' + item.label.variant : '', item.label.class ? item.label.class : '')
    };
    return this.navLink(item, key, classes);
  };

  // nav dropdown


  SideNavbar.prototype.navDropdown = function navDropdown(item, key, props) {
    const { t } = this.props;
    var classIcon = (0, _classnames2.default)('nav-icon', item.icon);
    var attributes = JSON.parse(JSON.stringify(item.attributes || {}));
    var classes = (0, _classnames2.default)('nav-link', 'nav-dropdown-toggle', item.class, attributes.class);
    delete attributes.class;
    //------- For Multiple QuoteNumber Display --------//

    // if (item.pickQuoteNumberFromVariables) {
    //   return QuoteNumber.map((id) => {
    //     return _react2.default.createElement(
    //       'li',
    //       { key: key, className: this.activeRoute(item.url, this.props) },
    //       _react2.default.createElement(
    //         'a',
    //         //onClick: ()=>{this.handleClick(); }
    //         _extends({ className: classes, href: '#', onClick: this.handleClick }, attributes),
    //         _react2.default.createElement('i', { className: classIcon }),
    //         id !== '' ? `QUOTESUMMARY - ${id}` : item.name,
    //         this.navBadge(item.badge)
    //       ),
    //       _react2.default.createElement(
    //         'ul',
    //         { className: 'nav-dropdown-items' },
    //         this.navList(item.children)
    //       )
    //     );
    //   })
    // }
    return _react2.default.createElement(
      'li',
      { key: key, className: this.activeRoute(item.url, this.props) },
      _react2.default.createElement(
        'a',
        //onClick: ()=>{this.handleClick(); }
        _extends({ className: classes, href: '#', onClick: this.handleClick }, attributes),
        _react2.default.createElement('i', { className: classIcon }),
        // t(item.pickAccountNameFromReducer ? ( this.props.dashboardReducer.accountName ? this.props.dashboardReducer.accountName : item.name ) : (item.pickQuoteNumberFromReducer ? "QUOTE - " + (this.props.metadataReducer.quoteData.QuoteNumber ? this.props.metadataReducer.quoteData.QuoteNumber : "") : item.name)),
        t(item.name),
        this.navBadge(item.badge)
      ),
      _react2.default.createElement(
        'ul',
        { className: 'nav-dropdown-items' },
        this.navList(item.children)
      )
    );
  };

  // SideBar onclick

  const onclickevent = async (item,url,disableClick,e) => {
    if(disableClick){
      e.preventDefault();
      return;
    }
    let refreshPage = {}
    const reduxState = store.getState();
    const [,originUrl = "" ] = window.location.hash.split("#");
    let urlArray = url.split("/")
    urlArray = urlArray.filter(function(val){ return val != ""})
    if(true){
        refreshPage = originUrl.substring(0,originUrl.lastIndexOf("/")) !== url.substring(0,url.lastIndexOf("/"));
    }
    else {
        refreshPage = originUrl !== url;
    }

    if (item.onclickaction === "startNewSubmisssion") {
        await store.dispatch(authaction.resetsubmission());
        window.location.reload();
    }
    if (item.url === "/home") {
        await store.dispatch(authaction.resetsubmission());
    }
    if (item.onclickaction === "resetFindFlow" && refreshPage) {
            await store.dispatch(authaction.resetsubmission());
            //window.location.reload();
    }
    if(item.onclickaction === "pageNameNavigation"){
        if(item.pageName){
            store.dispatch({type: actionType.SET_PAGENAME, payload: item.pageName});
            store.dispatch(setCurrentPageIndex(item.index));
        }
    }
 if (item.onclickaction === "findConsole") {
  let routeName = item.name.toLowerCase();
        await store.dispatch(authaction.resetsubmission());
        if(reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
          let pageNav = Object.assign({}, reduxState.navigationReducer.navigation);
          let sidebar = pageNav.pageNavigation.hasOwnProperty(routeName) ? pageNav.pageNavigation[routeName].sidebar : []
          let sidebarChildIndex = sidebar.findIndex(i => i.url === `/${routeName}`);
          pageNav.pageNavigation[item.name.toLowerCase()].sidebar[sidebarChildIndex].children=[];
          await store.dispatch({
            type : actionType.SET_NAVIGATION,
           payload :  pageNav
          })
        }        
        if(item.pageName){
            store.dispatch({type: actionType.SET_PAGENAME, payload: item.pageName});
            store.dispatch(setCurrentPageIndex(item.index));
        }
    }
    if(item.onclickaction === "navigateToPage"){
        if(item.IncludeForwardNav)
        {
            if(reduxState.UIFlowReducer.SolartisUIPageNavigationStatus >= item.SolartisUIPageNavigationStatus){
                store.dispatch(setCurrentPageIndex(item.index))
            }
        }
        else{
            if(item.index < reduxState.UIFlowReducer.currentPageIndex){
                store.dispatch(setCurrentPageIndex(item.index));
                  await store.dispatch({
                  type: actionType.SET_UI_VALUES,
                  payload: {
                    pageData: {
                      ...reduxState.UIFlowReducer.pageData,
                      pageName: item.pageName,
            },
        },
    });
            }
        }
    }
    if(item.onclickaction === 'NavigationTemplate'){
      store.dispatch(setCurrentPageInfo(item))
      store.dispatch(setCurrentPageIndex(item.index))
    }
    if(item.onclickaction === "ManageNav"){
        let nav = Object.assign({}, reduxState.navigationReducer.navigation);
        await store.dispatch(setConsoleInfo(item));
    }
    }

  // nav item with nav link


  SideNavbar.prototype.navItem = function navItem(item, key) {
    var classes = {
      item: (0, _classnames2.default)(item.class),
      link: (0, _classnames2.default)('nav-link', item.variant ? 'nav-link-' + item.variant : ''),
      icon: (0, _classnames2.default)('nav-icon', item.icon),
      inactive_link: (0, _classnames2.default)('nav-link inactive theme_color', item.variant ? 'nav-link-' + item.variant : ''),
    };
    return this.navLink(item, key, classes);
  };

  // nav link


  SideNavbar.prototype.navLink = function navLink(item, key, classes) {
    const { t } = this.props;
    var url = item.url || '';
    let navURL=window.location.hash.replace('#','');
    var flowURL=navURL.slice(1).split('/')[0];
    let disableClick=false;
    let reduxState = store.getState()
    if(item.url.slice(1).split('/')[0].toLowerCase()===flowURL.toLowerCase()){
      url=navURL;
        navURL=navURL.slice(1).split('/');
        if(item.index > this.props.currentPageIndex && item.url.slice(1).split('/')[0].toLowerCase()===flowURL.toLowerCase() && item.onclickaction === "navigateToPage")
          disableClick = true;
        if(navURL[1]){   
        let obj = decodeString(navURL[1]);
        if(navURL.length > 1 && navURL[1]){
          let encodedData = constructEncodedStringSync({...obj,pageName:item.pageName || ''});
          url=`/${navURL[0]}/${encodedData}`
        }
      }
    }
    if(item.dynamicURL && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
      let encodedData = constructEncodedStringSync({pageName:item.pageName || ''});      
      
      url=`${item.url}/${encodedData}`
    }
    var itemIcon = _react2.default.createElement('i', { className: classes.icon });
    var itemBadge = this.navBadge(item.badge);
    var attributes = item.attributes || {};
    var NavLink = this.props.router.NavLink || _reactstrap.NavLink;
    return _react2.default.createElement(
      _reactstrap.NavItem,
      { key: key, className: classes.item },
      attributes.disabled ? _react2.default.createElement(
        _reactstrap.NavLink,
        _extends({ href: '', className: classes.link }, attributes),
        itemIcon,
        t(item.name),
        itemBadge
      ) : this.isExternal(url) || NavLink === _reactstrap.NavLink ? _react2.default.createElement(
        _reactstrap.NavLink,
        //
        _extends({ href: url, className: classes.link }, attributes),
        itemIcon,
        t(item.name),
        itemBadge
      ) : _react2.default.createElement(
        NavLink,
        //onClick: this.props.runnow
        //_extends({ to: url, className: classes.link, onClick : item.subApplicationName ? () => {this.props.handleNodeSelect(item.index, item.subApplicationName); this.hideMobile();} : this.hideMobile() }, attributes),
        _extends({ to: url, className:(item.index?(item.index===this.props.currentPageIndex && item.url.slice(1).split('/')[0].toLowerCase()===flowURL.toLowerCase() ?`${classes.link} active`:classes.inactive_link):classes.link) , onClick: item.subApplicationName ? // change the login to compare pageName to show Active Sidebar.
           () => { this.props.handleNodeSelect(item.index, item.subApplicationName); this.hideMobile(); } :
           item.findview ? (e) => { onclickevent(item,url,disableClick,e); this.props.updateFindConsoleInfo(item); this.hideMobile();} :
            !item.disableSideNav ? (e) => { this.hideMobile(); onclickevent(item,url,disableClick,e);} :
            item.onclickaction ? (e) => { onclickevent(item,url,disableClick,e); this.hideMobile(); } : this.hideMobile()  }, attributes),
        itemIcon,
        t(item.name),
        itemBadge
      )
    );
  };

  // badge addon to NavItem


  SideNavbar.prototype.navBadge = function navBadge(badge) {
    if (badge) {
      var classes = (0, _classnames2.default)(badge.class);
      return _react2.default.createElement(
        _reactstrap.Badge,
        { className: classes, color: badge.variant },
        badge.text
      );
    }
    return null;
  };

  SideNavbar.prototype.isExternal = function isExternal(url) {
    var link = url ? url.substring(0, 4) : '';
    return link === 'http';
  };

  SideNavbar.prototype.render = function render() {
    var _props = this.props,
      className = _props.className,
      children = _props.children,
      navConfig = _props.navConfig,

      attributes = _objectWithoutProperties(_props, ['className', 'children', 'navConfig']);

    delete attributes.isOpen;
    delete attributes.staticContext;
    delete attributes.Tag;
    delete attributes.router;

    var navClasses = (0, _classnames2.default)(className, 'sidebar-nav theme_color theme_sidebar');

    // ToDo: find better rtl fix
    var isRtl = getComputedStyle(document.documentElement).direction === 'rtl';

    // sidebar-nav root
    return _react2.default.createElement(
      _reactPerfectScrollbar2.default,
      _extends({ className: navClasses }, attributes, { options: { suppressScrollX: !isRtl } }),
      _react2.default.createElement(
        _reactstrap.Nav,
        null,
        children || this.navList(navConfig)
      )
    );
  };

  return SideNavbar;
}(_react.Component);

SideNavbar.propTypes = process.env.NODE_ENV !== "production" ? propTypes : {};
SideNavbar.defaultProps = defaultProps;

export default  withTranslation()(SideNavbar);
//module.exports = exports['default'];
