import React, { Component, Fragment, Suspense, lazy } from 'react';
import DataTableColumnsV2 from './DataTableColumnsV2';
// import DataTableRow from './DataTableRow';
import DataTablePaginationV2 from './DataTablePaginationV2';
import NoRecords from './NoRecords';
// import CustomFilterRow from './CustomFilterRow';
import { TableContainer, Paper, Table, TableHead, TableBody, Accordion , AccordionDetails, AccordionSummary, TableFooter } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from "@material-ui/core/styles";
import DoubleScrollbar from "react-double-scrollbar";
import TableHeadingV2 from './TableHeadingV2';
import CustomFilterRowV2 from './CustomFilterRowV2';
import DataTableRowV2 from './DataTableRowV2';
import DataTablePopup from './DataTablePopup';
import { addRow } from '../../utils/Common/InputUtils';
import { commonRowMethod, onTableLazyLoadData } from '../ButtonUtil';
import { dynamicConditionValidation, dynamicConditionValidationV3 } from '../../utils/Common/NoCodeUtils';


const TableHeaderActions = lazy(() => import('./TableHeaderActions'));

class DataTableV2 extends Component {

    constructor(props) {
        super(props)
        this.pages=React.createRef({});
        this.state = {
            page: 0,
            rowsPerPage: 0,
            order: "asc",
            orderBy: '',
            filtercriteria: {},
            isColumnChecked: false,
            expand: false,
            togglekey: false,
            disableColumnComponent: true,
            popupData: {},
            tempDataList: [],
            dragElement: {index:null,value:null,drag:false}
        }
        this.childformshrink = React.createRef();
        this.cancelAPICall = React.createRef(null);
    }

    componentDidMount() {
        let { subAppInfo, UpdateState, columnList, dataList, TableOptions, tableActions } = this.props;
        if(tableActions.dragRow && Object.keys(tableActions.dragRow).length > 0){
            this.pages.current={initial:0,final:Math.ceil(this.props.dataList.length/15)}
        }
        let { pagination = {}, selectable = {} } = tableActions;
        let SubApplicationDetail = subAppInfo['SubApplicationDetail'];
        let SubApplnName = SubApplicationDetail.ApplicationName.toLowerCase();

        let columnListName = SubApplnName + "_columnList";
        let columnlistValue = subAppInfo['ComponentList'];

        let dataListName = SubApplnName + "_dataList";
        let dataListValue = dataList;

        // let selectable = TableOptions.Selectable ? TableOptions.Selectable : {};
        let { attributeName } = selectable;

        if (dataList) {
            this.setState({isColumnChecked: (!!dataList.length && dataList.every(el => el[attributeName] === "true"))});
        }

        if(pagination.rowsPerPage) {
            this.setState({ rowsPerPage: pagination.rowsPerPage });
        }


        if (columnList.length === 0) {
            UpdateState(dataListName, dataListValue, false)
            UpdateState(columnListName, columnlistValue, true)
        }

        if( tableActions.tableFooter && tableActions.tableFooter.isNeeded){
            this.calculateColumn(this.props.dataList)
        }
    }
    checkAttributes(columnlistValue, columnList, columnListName) {
        // Check if both arrays have the same length
        if (columnlistValue.length !== columnList.length) {
            this.props.UpdateState(columnListName, columnlistValue, true);
            return;
        }
        // Check if all attribute names match
        for (let i = 0; i < columnlistValue.length; i++) {
            if (columnlistValue[i].AttributeName !== columnList[i].AttributeName) {
                this.props.UpdateState(columnListName, columnlistValue, true);
                return;
            }
        }
    }

    componentDidUpdate = (prevProps) => {
        let { page, rowsPerPage } = this.state;
        let { TableOptions, tableActions, subAppInfo,dataList, UpdateState, columnList } = this.props;
        if(tableActions.dragRow && Object.keys(tableActions.dragRow).length > 0){
            this.pages.current={initial:0,final:Math.ceil(this.props.dataList.length/15)}
        }
        let { pagination = {}, selectable = {},readOnly={} } = tableActions;
        let SubApplicationDetail = subAppInfo['SubApplicationDetail'];
        let SubApplnName = SubApplicationDetail.ApplicationName.toLowerCase();
        let columnListName = SubApplnName + "_columnList";
        let columnlistValue = subAppInfo['ComponentList'];
        let dataListName = SubApplnName + "_dataList";
        let dataListValue = dataList,obj={};

        if (columnList.length === 0) {
            UpdateState(dataListName, dataListValue, false)
            UpdateState(columnListName, columnlistValue, true)
        } 
        this.checkAttributes(columnlistValue, this.props.columnList, columnListName)
        // let { lazyLoading = false } = TableOptions;
        let { attributeName } = selectable;
        let { lazyLoading = {} } = tableActions;
                if (prevProps.dataList && this.props.dataList) {
            if(prevProps.dataList !== this.props.dataList) {
            if (dataList) {
              let isColumnCheck = true;
              let readOnlyCount=0;
              dataList.forEach( data=> {

                let isRead = false
                // readOnly.conditions
                if(readOnly && readOnly.condition && readOnly.condition.length){
                  isRead = dynamicConditionValidationV3(data,readOnly.condition)
                  if(isRead){
                    readOnlyCount+=1;
                  }
                }

                if(!isRead && isColumnCheck){
                  isColumnCheck = isColumnCheck && data[attributeName] === "true" ? true : false;
                }
                }
              )

              // const readOnlyDataList = Object.keys(readOnly).length>0 && dataList.filter(el =>!dynamicConditionValidation(el,readOnly.condition))

              // const TocheckSelectAll = readOnlyDataList.length>0 &&  readOnlyDataList.every(el => el[attributeName]===attributeValue)
            if(dataList.length===readOnlyCount)
            obj.disableColumnComponent=true;
            this.setState({isColumnChecked: (dataList.length && isColumnCheck && !(dataList.length===readOnlyCount)),...obj});
            }
        }
            if (prevProps.dataList.length !== this.props.dataList.length) {
                if (!lazyLoading.isNeeded && this.props.dataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length === 0) {
                    if (page !== 0) {
                        this.handleChangePage({}, page - 1);
                    }

                }
            }
            if( tableActions.tableFooter && tableActions.tableFooter.isNeeded &&this.props.dataList.length >0 && prevProps.dataList !== this.props.dataList){
                this.calculateColumn(this.props.dataList)
            }
        }
        if (prevProps.dataTableUpdate !== this.props.dataTableUpdate && this.props.dataTableUpdate){
            this.setState({ page: 0 },
                () => {
                if (lazyLoading.isNeeded) {
                    this.onLazyLoadData()
                }
            });
            this.props.UpdateState(`${SubApplnName}_dataTableUpdate`, false, true);
        }
        if(this.state.navigatePage){
            this.updateDataTableState('page',Math.ceil((this.props.dataList.length+1)/this.state.rowsPerPage)-1,false);
            this.updateDataTableState('navigatePage',false,true);
        }
    }
    nextHandler = (e) =>{
        if(this.state.dragNextEventTriggered)
        return;
        let {subAppInfo:{SubApplicationDetail:{ApplicationName}}} = this.props;
        let SubApplnName = ApplicationName.toLowerCase();
        const element = document.getElementById(`${SubApplnName}__dragIdentifier`);
        const navigation=()=>{
            this.setState({
                dragNextEventTriggered: false
            });
        }
        element.scrollIntoView({ behavior: 'smooth', block:'start' , inline: "nearest" });
        if((this.state.page+1)!==this.pages.final)
        this.setState({
            page: this.state.page+1,
            dragNextEventTriggered: true
        });
        this.typeWatch(navigation,1000)
    }
    previousHandler = () => {
        if(this.state.dragPreviousEventTriggered)
        return;
        let {subAppInfo:{SubApplicationDetail:{ApplicationName}}} = this.props;
        let SubApplnName = ApplicationName.toLowerCase();
        const element = document.getElementById(`${SubApplnName}__dragIdentifier`);
        element.scrollIntoView({ behavior: 'smooth', block:'start' , inline: "nearest" });
        const navigation=()=>{
            this.setState({
                dragPreviousEventTriggered: false
            });
        }
        if((this.state.page-1)!==this.pages.initial)
        this.setState({
            page: this.state.page-1,
            dragPreviousEventTriggered: true
        });
        this.typeWatch(navigation,1000)
    }
    updateDataTableState= (statename, value, rerender) => {
        if (rerender) {
            this.setState({
              [statename]: value,
            });
          } else {
            this.state[statename] = value;
          }
    }
    handleChangePage = (event, newPage) => {
        let { TableOptions, tableActions } = this.props;
        // let { lazyLoading = false } = TableOptions;
        let { lazyLoading = {} } = tableActions;
        this.setState({
            page: newPage,
        }, () => {
            if (lazyLoading.isNeeded) {
                this.onLazyLoadData()
            }
        });
    };

    handleChangeRowsPerPage = (event) => {
        let { TableOptions, tableActions } = this.props;
        // let { lazyLoading = false } = TableOptions;
        let { lazyLoading = {} } = tableActions;
        this.setState({
            rowsPerPage: event.target.value,
            page: 0,
        }, () => {
            if (lazyLoading.isNeeded) {
                this.onLazyLoadData()
            }
        });
    };
    //Need to check....
    onLazyLoadData = () => {
        let { tableUtils, tableFunctions, tableActions } = this.props;
        let { lazyLoading = {} } = tableActions;
        let { getLazyLoadData } = tableUtils;
        let { page, rowsPerPage } = this.state;

        let offsetInfo = {
            ...this.state.filtercriteria,
            "StartLimit": (page * rowsPerPage).toString(), //Offset
            "EndLimit": (rowsPerPage).toString(), //Limit
        }
        // getLazyLoadData(offsetInfo)
        if(lazyLoading.lazyLoadFunction)
        Object.keys(lazyLoading.lazyLoadActions?lazyLoading.lazyLoadActions:{}).length >0
    ? onTableLazyLoadData(offsetInfo,this.props,undefined,this.cancelAPICall)
    : tableFunctions[lazyLoading.lazyLoadFunction](offsetInfo,this.props)
    }

    descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    getComparator = (order, orderBy) => {
        return order === "desc"
            ? (a, b) => this.descendingComparator(a, b, orderBy)
            : (a, b) => -this.descendingComparator(a, b, orderBy);
    }

    stableSort = (array, comparator) => {
        // console.log("Inside stableSort");
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    handleRequestSort = (event, property,attributeName) => {
        // console.log("Inside handleRequestSort");
        const { order, orderBy } = this.state;
        const isAsc = orderBy === property && order === "asc";
        let { tableActions, tableFunctions } = this.props;
        let { lazyLoading = {} }= tableActions;
        this.setState({
            order: isAsc ? "desc" : "asc",
            orderBy: property
        }, () => {
            if (tableActions.sorting && tableActions.sorting.sortLazyLoading) {
                let { page, rowsPerPage, order } = this.state;
                const filtercriteriaCopy={...this.state.filtercriteria};
                if(filtercriteriaCopy['currentAttribute']){
                    delete filtercriteriaCopy[`${filtercriteriaCopy['currentAttribute']}_sort`];
                    delete filtercriteriaCopy['currentAttribute'];
                }
                let offsetInfo = {
                "StartLimit": (page * rowsPerPage).toString(),
                "EndLimit": (rowsPerPage).toString(),
                ...filtercriteriaCopy,
                [`${attributeName}_sort`]: order,
                currentAttribute: attributeName
                }
                if(lazyLoading.lazyLoadFunction)
                Object.keys(lazyLoading.lazyLoadActions?lazyLoading.lazyLoadActions:{}).length >0
                ? onTableLazyLoadData(offsetInfo,this.props, this.updateDataTableState, this.cancelAPICall)
                :  tableFunctions[lazyLoading.lazyLoadFunction](offsetInfo,this.props);
            }
        });
    };

    typeWatch = function (callback, ms) {
        var timer = 0;
        return function (callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        }
    }()

    handleFilterRequest = async (e) => {
        e.preventDefault();
        let { tableUtils = {}, TableOptions, tableActions, tableFunctions } = this.props;
        let { getLazyLoadData } = tableUtils;
        // let { lazyLoading = false } = TableOptions;
        let { lazyLoading = {}, filterLazyLoading={} }= tableActions;
        let keyname = e.target.name;
        let value = typeof e.target.value === 'string' ? e.target.value : e.target.value.toString();

        this.setState((prevState) => {
            let filtercriteria = Object.assign({}, prevState.filtercriteria);
            if (value.length > 0) {
                filtercriteria[keyname] = value;
            } else {
                delete filtercriteria[keyname];
            }

            return { filtercriteria, page:0 };
        }, () => {
            if (filterLazyLoading.isNeeded) {
                let {page,rowsPerPage } = this.state;
                let offsetInfo = {
                "StartLimit": (page * rowsPerPage).toString(),
                "EndLimit": (rowsPerPage).toString(),
                ...this.state.filtercriteria
                }
                this.typeWatch(() => { 
                if(lazyLoading.lazyLoadFunction)
                Object.keys(lazyLoading.lazyLoadActions?lazyLoading.lazyLoadActions:{}).length >0
                ? onTableLazyLoadData(offsetInfo,this.props, this.updateDataTableState, this.cancelAPICall)
                :  tableFunctions[lazyLoading.lazyLoadFunction](offsetInfo,this.props); }, 1000)
            }
        })
    }

    onFilter = (dataList) => {
        const { filtercriteria } = this.state;
        if (Object.keys(filtercriteria).length > 0) {
            let filteredData = dataList.filter((rowdata) => {
                for (var key in filtercriteria) {
                    let keyName = key.endsWith("_Filter") ? key.split('_Filter')[0]  : key
                    if (!rowdata.hasOwnProperty(keyName) || !(rowdata[keyName].toLowerCase().includes(filtercriteria[key].toLowerCase())))
                        return false;
                }
                return true;
            });
            return filteredData;
        } else {
            return dataList;
        }

    }

    onRowDataManipulation = (dataList) => {
        const { TableOptions, tableActions } = this.props;
        let resultData = dataList;
        if (tableActions.filtering && tableActions.filtering.isNeeded) {
            resultData = this.onFilter(resultData);
        }
        if (tableActions.sorting && tableActions.sorting.isNeeded && this.state.orderBy !== '') {
            resultData = this.stableSort(resultData, this.getComparator(this.state.order, this.state.orderBy))
        }
        return resultData;

    }

    handleCheckBox = (e, rowdata, selectable) => {
        let { tableUtils, subAppInfo, dataList, UpdateState } = this.props;
        const { attributeName } = selectable;
        let { SubApplicationDetail } = subAppInfo;
        let { ApplicationName,mAction } = SubApplicationDetail;
        let { readOnly } = mAction;
        let dataListName = ApplicationName.toLowerCase() + "_dataList";
        let checkedCount = 0;
                let updatedDataList = dataList.map(el => {
            if (rowdata) {
                if (el === rowdata) {
                    el[attributeName] = e.target.checked.toString();
                }
            } else if (readOnly && readOnly.isReadOnly && e.target.checked) {
                    let isReadOnly
                    isReadOnly = !dynamicConditionValidationV3(el,readOnly.condition)
                    el[attributeName] = isReadOnly.toString()
                    if (el[attributeName] === 'true') checkedCount++
            } else if (this.props.tableFunctions && this.props.tableFunctions.disablecheckbox && e.target.checked) {
                let isReadOnly
                isReadOnly = !this.props.tableFunctions.disablecheckbox(el)
                el[attributeName] = isReadOnly.toString()
                if (el[attributeName] === 'true') checkedCount++
            } else {
                el[attributeName] = e.target.checked.toString();
                (el[attributeName] === "true") && checkedCount++;
            }
            return el;
        })
        let isColumnCheck
        if ((this.props.tableFunctions && this.props.tableFunctions.disablecheckbox) || (readOnly && readOnly.isReadOnly)) {
            if (e.target.checked) {
                isColumnCheck = updatedDataList.filter((val) => val[attributeName] === 'true').length === checkedCount
            } else {
                isColumnCheck = false
            }
        } else {
            isColumnCheck = updatedDataList.length !== 0 && updatedDataList.length === checkedCount
        }
        if (tableUtils.onRowSelect) tableUtils.onRowSelect();
        this.setState({ isColumnChecked: isColumnCheck });
        UpdateState('rowCheckedCount', checkedCount, false);
        UpdateState(dataListName, updatedDataList, true);
    }


    handleTableRowSelect = async (checkedStatus, rowData, selectableOptions) => {
        const { onRowSelectAction = null, attributeName = null, } = selectableOptions;
        const { dataList, subAppInfo, UpdateState } = this.props;
        const { SubApplicationDetail } = subAppInfo;
        const { ApplicationName } = SubApplicationDetail;
        const dataListName = ApplicationName.toLowerCase() + "_dataList";

        if(!onRowSelectAction || !attributeName) return;

        const response = await onRowSelectAction(checkedStatus, rowData, this.props);
        if(response.toLowerCase() === 'success') {
            rowData[attributeName] = checkedStatus;
            UpdateState(dataListName, dataList, true);
        }

    }

    handleTableColumnSelect = async (checkedStatus, rowData, selectableOptions) => {
        const { onColumnSelectAction = null, attributeName = null } = selectableOptions;
        const { subAppInfo, UpdateState } = this.props;
        const { SubApplicationDetail } = subAppInfo;
        const { ApplicationName } = SubApplicationDetail;
        const dataListName = ApplicationName.toLowerCase() + "_dataList";
        if(!onColumnSelectAction || !attributeName) return;

        const response = await onColumnSelectAction(checkedStatus, this.props);
        if(response &&  response.status.toLowerCase() === 'success') {
            const { data } = response;
            if(checkedStatus === 'true') {
                this.setState({ isColumnChecked: true });
            }
            else {
                this.setState({ isColumnChecked: false });
            }
            UpdateState(dataListName, data.VehicleList, true);
        }

    }

    toggleSelect = (e, rowdata = false) => {
        let { TableOptions, tableActions } = this.props;
        let { selectable = {} } = tableActions;
        // let selectable = TableOptions.Selectable ? TableOptions.Selectable : [];
        let { externalActions = false } = selectable;
        const checkedStatus = e.target.checked.toString();
        if(externalActions) {
            if(rowdata) {
                this.handleTableRowSelect(checkedStatus, rowdata, selectable);
            }
            else {
                this.handleTableColumnSelect(checkedStatus, rowdata, selectable);
            }
        }
        else {
            this.handleCheckBox(e, rowdata, selectable);
        }
    }

    toggleDialog = (dataListName, rowData, SubApplnName, props) => {
      this.setState({togglekey: !this.state.togglekey,
          popupData : {rowData}})
    }

    getTableDataList = (dataList) => {
        let { TableOptions, tableActions } = this.props;
        // let { lazyLoading = false } = TableOptions;
        let { lazyLoading = {} } = tableActions;
        let { page, rowsPerPage } = this.state;
        if (lazyLoading.isNeeded) {
            return this.onRowDataManipulation(dataList);
        } else {
            return this.onRowDataManipulation(dataList).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        }
    }

    calculateColumn = (dataList) => {
        let footerData = [];
        let sums = {}
        // let {tableFooter : {columnString}} = this.props.tableActions;
        let {tableFooter} = this.props.tableActions;
        let {columnString} = tableFooter;
        let validationMessage = tableFooter.validation && tableFooter.validation.validationMessage;
        dataList.forEach((row) => {
            Object.keys(row).forEach((key) => {
              if (!isNaN(parseInt(row[key]))) {
                let method = tableFooter.method[key] ? tableFooter.method[key] : "";
                let equals = tableFooter.equal && tableFooter.equal[key] ? tableFooter.equal[key] : "";
                sums[key] = this.calculateColumnAggregation(dataList, key, method,equals,validationMessage);
              }else if(row[key]!==""){
                columnString && columnString[key] &&
                (sums[key] = columnString[key])
              }
            });
          });
        footerData.push(sums)
        this.props.UpdateState("footerDataList", footerData, true)
    }
    calculateColumnAggregation = (data, columnName, Action,equals,validationMessage) => {
        const aggregationMethod = Action.toLowerCase();
        switch (aggregationMethod) {
            case 'sum':
              return data.reduce((sum, row) => sum + (parseFloat(row[columnName]) || 0), 0);
            case 'average':
              const sum = this.calculateColumnAggregation(data,columnName,"sum");
              return data.length > 0 ? sum / data.length : 0;
            case 'min':
              return Math.min(...data.map((row) => parseFloat(row[columnName]) || 0));
            case 'max':
              return Math.max(...data.map((row) => parseFloat(row[columnName]) || 0));
            case 'equal':
                let res = this.calculateColumnAggregation(data,columnName,"sum")===Number(equals);
                return !res && validationMessage && validationMessage[columnName]?validationMessage[columnName]:'';
            // Add more aggregation methods as needed
            default:
              return null;
        }
      };
    addSequenceToDataList=(dataList)=>{
        let {tableActions:{dragRow:{sequenceKeyName}}}=this.props;
        dataList.forEach((list,index)=>{
            list[sequenceKeyName]=index+1;
        })
        return dataList;
    }
    handleChange = (event, isExpanded) => {
        this.setState({ expand: isExpanded })
    };
    onDragStart=(index)=>{
        let {subAppInfo:{SubApplicationDetail:{ApplicationName}}} = this.props;
        let SubApplnName = ApplicationName.toLowerCase();
        document.getElementById(`${SubApplnName}__dragIdentifier`).querySelector('[aria-label="Previous page"]').addEventListener('dragover',this.previousHandler,false);
        document.getElementById(`${SubApplnName}__dragIdentifier`).querySelector('[aria-label="Next page"]').addEventListener('dragover',this.nextHandler,false);
        this.updateDataTableState('dragNextEventTriggered',false,false)
        this.updateDataTableState('dragPreviousEventTriggered',false,false)
        this.updateDataTableState('dragElement',{...this.state.dragElement,index:index,value:this.props.dataList[index],dragEnd:true,initialPage:this.state.page},true)
    }
    onDragEnd=async()=>{
        if(this.state.dragElement.index===null || this.state.dragElement.previousIndex === undefined || this.state.dragElement.index === this.state.dragElement.previousIndex)
        return;
        let { subAppInfo: {SubApplicationDetail: {ApplicationName}},tableActions:{dragRow={}},dataList } = this.props;
        let SubApplnName = ApplicationName.toLowerCase();
        document.getElementById(`${SubApplnName}__dragIdentifier`).querySelector('[aria-label="Previous page"]').removeEventListener('dragover',this.previousHandler);
        document.getElementById(`${SubApplnName}__dragIdentifier`).querySelector('[aria-label="Next page"]').removeEventListener('dragover',this.nextHandler);
        let dataListName = SubApplnName + "_dataList",dataListCopy=this.state.tempDataList,rowActionBoolean=false,update=true;
        rowActionBoolean=(dragRow.rowAction && Object.keys(dragRow.rowAction).length > 0);
        dataListCopy=this.addSequenceToDataList(dataListCopy)
        if(rowActionBoolean && dragRow.rowAction.LoaderNeeded==='Y' && this.state.dragElement.index !== this.state.dragElement.previousIndex){
            update = false;
        }
        this.updateDataTableState('dragElement',{...this.state.dragElement,value:null},false)
        this.updateDataTableState('tempDataList',[],true);
        this.props.UpdateState(dataListName,dataListCopy,update)
        if(rowActionBoolean && this.state.dragElement.index !== this.state.dragElement.previousIndex)
        commonRowMethod(dataListName, {}, SubApplnName, this.props, {...dragRow,dragEvent: true},{...this.props.componentState,dataListCopy});
    }
    onDragEnter=(index)=>{
        if(this.state.dragElement.index===null || this.state.dragElement.previousIndex===index)
        return;
        this.updateDataTableState('dragNextEventTriggered',false,false)
        this.updateDataTableState('dragPreviousEventTriggered',false,false)
        this.updateDataTableState('dragElement',{...this.state.dragElement,count:this.state.dragElement.count+1},false);
        const callback=()=>{
        let {subAppInfo:{SubApplicationDetail:{ApplicationName}}} = this.props;
        let SubApplnName = ApplicationName.toLowerCase();
        const element = document.getElementById(`${SubApplnName}[${index}]`);
        if(element)
        element.scrollIntoView({ behavior: 'smooth', block:'center' });
        }
        this.typeWatch(callback,1000);
        let { page, rowsPerPage } = this.state;
        let dataListCopy=[...this.props.dataList];
        dataListCopy.splice((this.state.dragElement.initialPage * rowsPerPage)+this.state.dragElement.index,1);
        dataListCopy.splice((page * rowsPerPage)+index,0,{...this.state.dragElement.value,'IsDraggedandDropped': "true"});
        this.updateDataTableState('dragElement',{...this.state.dragElement,'previousIndex':index},false);
        this.updateDataTableState('tempDataList',dataListCopy,true);
    }
    render() {
        const IconLeftAccordionSummary = withStyles({
            expandIcon: {
                order: -1,
                marginRight:'5px',
                padding: '0px 12px 0px 0px'
            },
            root :{
                padding : '0px'
            }

        })(AccordionSummary);
        let { pagination = {}, filtering = {}, sorting = {}, borderNeeded = false, alignContent = "left", topScrollbar = {}, childComponent = {},tableFooter = {}, tableHeadingNeeded = true } = this.props.tableActions;
        let {footerDataList = []} = this.props.componentState;
        let tableFunctions = {...this.props.tableFunctions, toggleDialog : this.toggleDialog, addRow : addRow, updateDataTableState:this.updateDataTableState}
        let alignProperties = ["center", "left", "right"]
                let paginationProperties = {
            page: this.state.page,
            rowsPerPage: this.state.rowsPerPage,
            changePage: this.handleChangePage,
            changeRows: this.handleChangeRowsPerPage,
        }

        let columnOptions = {
            filtercriteria: this.state.filtercriteria,
            handleFilter: this.handleFilterRequest,
            sortOrder: this.state.order,
            sortBy: this.state.orderBy,
            handleSort: this.handleRequestSort
        }

        let { dataList = [], columnList, TableOptions = {}, subAppInfo, IsExpandable, tableActions, children, ChildSubApplicationList } = this.props;
        let { popupData} = this.state
        let tableSize = 0,colSpanValue=0;
        let actionListOptions = [];
        let {dragRow = false} = tableActions;
        if(tableActions.hasOwnProperty('ActionList') && !Array.isArray(tableActions.ActionList)) actionListOptions.push(tableActions.ActionList)
        else if(tableActions.hasOwnProperty('ActionList') && Array.isArray(tableActions.ActionList)) actionListOptions = tableActions.ActionList
        let actionRequired = actionListOptions.length > 0 ? true : false;
        let detailRequired = children || ChildSubApplicationList && ChildSubApplicationList.length > 0;
        dragRow && (colSpanValue++);
        actionRequired && (colSpanValue++);
        detailRequired && (colSpanValue++);
        const { headerActions = null, TopScrollbar= null } = TableOptions;
        let BorderClass = borderNeeded ? "BorderClass" : "";
        let alignTableData = alignProperties.includes(alignContent) ? alignContent : "left"
        if (Array.isArray(dataList)) {
            tableSize = dataList.length;
        }
        let dataListValue=this.state.tempDataList.length > 0 ? this.state.tempDataList : dataList;
        let SubApplicationDetail = subAppInfo['SubApplicationDetail'];
        let SubApplnName = SubApplicationDetail.ApplicationName.toLowerCase();
        if (SubApplicationDetail.Description !== undefined) {
            var HeadingNeeded = SubApplicationDetail.Description.length > 0 ? true : false;
        }
        let tableHeading = <TableHeadingV2 {...this.props} tableFunctions = {tableFunctions} {...paginationProperties}/>
        let tableHeaderActions = <Suspense fallback={<div>Loading...</div>}>
                                    <TableHeaderActions
                                        {...this.props}
                                    />
                                </Suspense>
        let tableContainer=<Paper id={`${SubApplnName}__dragIdentifier`} style={{ width: "100%" }} elevation={5}>
        <TableContainer ref={this.childformshrink}>
            <Table aria-label="collapsible table" className={`custom-table ${BorderClass}`}>
                <TableHead  style={{ width: "100%" }} className={BorderClass}>
                    {
                        <DataTableColumnsV2
                            {...this.props}
                            disableColumnComponent={this.state.disableColumnComponent}
                            toggleSelect={this.toggleSelect}
                            isColumnChecked={this.state.isColumnChecked}
                            alignTableData={alignTableData}
                            columnOptions={columnOptions}
                        />
                    }
                    {
                        (filtering.isNeeded || (Array.isArray(filtering.columns) && filtering.columns.length > 0)) &&
                        <CustomFilterRowV2
                            {...this.props}
                            toggleSelect={this.toggleSelect}
                            isColumnChecked={this.state.isColumnChecked}
                            columnOptions={columnOptions}
                        />
                    }
                </TableHead>
                <TableBody droppable={this.state.tempDataList.length > 0 ? true : false} onDrop={this.onDragEnd}>
                    {
                        this.getTableDataList(dataListValue)
                            .map((data, index) => (
                                <DataTableRowV2
                                    {...this.props}
                                    dragElement={this.state.dragElement}
                                    DragStart={()=>this.onDragStart(index)}
                                    DragEnd={this.onDragEnd}
                                    DragEnter={()=>this.onDragEnter(index)}
                                    disableColumnComponent={this.state.disableColumnComponent}
                                    toggleSelect={this.toggleSelect}
                                    tableFunctions = {tableFunctions}
                                    rowData={data}
                                    alignTableData={alignTableData}
                                    shrink={this.childformshrink}
                                    DataTableRowIndex={index}
                                />
                            ))
                    }
                    {
                        tableSize === 0 && <NoRecords colSpanValue={colSpanValue} columnList={columnList} />
                    }
                </TableBody>
                {
                tableFooter.isNeeded  &&footerDataList && footerDataList.length > 0 &&
                <TableFooter style={tableFooter.colorNeeded && {backgroundColor: '#e5e5e5'}} >
                                        {
                                             footerDataList.map((data, index) => (
                                                <DataTableRowV2
                                                    {...this.props}
                                                    toggleSelect={this.toggleSelect}
                                                    tableFunctions={tableFunctions}
                                                    rowData={data}
                                                    alignTableData={alignTableData}
                                                    shrink={this.childformshrink}
                                                    readOnly={true}
                                                    validationNeeded={tableFooter&&tableFooter.validation && tableFooter.validation.validationNeeded}>
                                                </DataTableRowV2>
                                            ))
                                        }
                                    </TableFooter>
                                }
            </Table>
        </TableContainer>
        {
            tableSize > 0 && pagination.isNeeded &&
            <DataTablePaginationV2
                {...this.props}
                pagination={pagination}
                paginationProperties={paginationProperties}
            />
        }
    </Paper>

let tableContainerDoubleScrollbar = <Paper style={{ width: "100%",overflow: "auto"}} elevation={5}>
                        <TableContainer ref={this.childformshrink}>
                        <DoubleScrollbar>
                            <Table aria-label="collapsible table">
                                <TableHead style={{ width: "100%" }}>
                                    {
                                        <DataTableColumnsV2
                                            {...this.props}
                                            toggleSelect={this.toggleSelect}
                                            isColumnChecked={this.state.isColumnChecked}
                                            columnOptions={columnOptions}
                                            alignTableData={alignTableData}
                                        />
                                    }
                                    {
                                        filtering.FilteringNeeded &&
                                        <CustomFilterRowV2
                                            {...this.props}
                                            toggleSelect={this.toggleSelect}
                                            isColumnChecked={this.state.isColumnChecked}
                                            columnOptions={columnOptions}
                                        />
                                    }
                                </TableHead>
                                <TableBody>
                                    {
                                        this.getTableDataList(dataList)
                                            .map((data, index) => (
                                                <DataTableRowV2
                                                    {...this.props}
                                                    toggleSelect={this.toggleSelect}
                                                    rowData={data}
                                                    alignTableData={alignTableData}
                                                    shrink={this.childformshrink}
                                                />
                                            ))
                                    }
                                    {
                                        tableSize === 0 && <NoRecords colSpanValue={colSpanValue} columnList={columnList} />
                                    }
                                </TableBody>
                            </Table>
                        </DoubleScrollbar>
                        </TableContainer>
                        {
                            tableSize > 0 && pagination.PaginationNeeded &&
                            <DataTablePaginationV2
                                {...this.props}
                                pagination={pagination}
                                paginationProperties={paginationProperties}
                            />
                        }
                    </Paper>
        let tableWithAccordion = <Accordion style={{boxShadow: "none", width: '100%'}} expanded={this.state.expand} onChange={this.handleChange}>
                                    <IconLeftAccordionSummary expandIcon={ <ExpandMoreIcon />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions3-content" >
                                        {tableHeading}
                                    </IconLeftAccordionSummary>
                                    {
                                        headerActions && headerActions.actionVisibility &&
                                        tableHeaderActions
                                    }
                                    <AccordionDetails style={{padding: "0px"}}>
                                    {topScrollbar.isNeeded && tableContainerDoubleScrollbar}
                                    {!topScrollbar.isNeeded && tableContainer}
                                    </AccordionDetails>
                                </Accordion>
        let tableWithoutAccordion =
                                <>
                                     {HeadingNeeded && tableHeadingNeeded && tableHeading}
                                    {headerActions && headerActions.actionVisibility && tableHeaderActions}
                                    {topScrollbar.isNeeded && tableContainerDoubleScrollbar}
                                    {!topScrollbar.isNeeded && tableContainer}
                                </>
        return (
            <Fragment>
                {
                    IsExpandable === 'Y' ?
                    tableWithAccordion
                    :
                    tableWithoutAccordion
                }
                {
                    this.state.togglekey && childComponent.renderPopup && <DataTablePopup {...this.props} {...popupData} tableFunctions = {tableFunctions} />
                }
            </Fragment>
        )
    }
}

export default DataTableV2;
