import appActions from '../../../actions';
import { constructRequestValues,constructRequestValuesV2 } from '../utils/API_utils/ConstructRequestValues';
import store from "../../../store"
import { AxiosInstance, commonProductAxiosInstance, getServiceRequestDetail,getJSONRequestFromAPI } from "../utils/Common/API"
import { formRequestConstruction, updateValueToRedux,postAPI,setDefaultValue,setDataLists } from "../utils/Common/CommonUtils"
import { InvokeAPICall_IneligibleRiskAddressCheckV1, getDataListWithExpand } from '../utils/Common/RiskScheduleUtils';
import { downloadFile,FileHandlerAxios } from '../../../CommonAxios/FileHandlerAxios';
import { successToast,triggerToast } from '../utils/Common/HelperUtils';
import { EntitySubAppTranslationV3 } from '../utils/requestConstruction/ResponseTranslation';
import { constructAndInvokeMetadataCall,deleteDependentAttribute, UpdateMetadata} from '../utils/Common/MetaUtils';
import { dynamicConditionValidationV2,dynamicObjectConstruction,mergeDeep,criteriaValidationCheck,showtoaster, constructEncodedString, decodeString, dynValueListconstructor } from '../utils/Common/NoCodeUtils';
import axios from 'axios';
const { actionType } = appActions


export const commonButtonMethod = async (component,propsObj,stateObj,functions,e) =>{
    // Action:{ResponseKey},
    let {Action = {},AjaxNeeded="false"}=component,response={},request={};
    let tpo={},reduxObject={}    
    if(!Object.keys(Action).length)
    {
        Action={};
    }
    else {
    request["OwnerId"]="";
    let {functions}=propsObj
    if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
    {
        // store.dispatch({type:actionType.SPIN_LOADER})
        let {UpdateState} = functions;
        if(UpdateState){
            UpdateState("loading",true,true)
        }
    }
    let stateValue = stateObj.pagelevelValues
    let reduxState=store.getState();
    let {metadataReducer:{productDetails}}=reduxState;
    request={...request,ServiceRequestDetail:getServiceRequestDetail()}

    if(Action.hasOwnProperty("EventMapping"))
    {

            Object.entries(Action.EventMapping).forEach(([key, value]) => {
                request[key]=value
              });

    }
        if(Action.GenerateJSONNeeded && Action.GenerateJSONNeeded==="Y")
    {
        let r = await getJSONRequestFromAPI(request["EventName"],productDetails,Action.URLVersion);
        request ={...request,...r}
    }
    if(Action.RequestConstruction && Action.RequestConstruction==="Y")
    {
        let rootlevelNeed =false;
        if(Action.IsRootLevelNeeded && Action.IsRootLevelNeeded==="Y" ){
            rootlevelNeed=true;
            let r =await constructRequestValuesV2(propsObj,stateObj.pagelevelValues,request,stateObj,true,false,rootlevelNeed);
            request ={...request,...r}
        }else{
            let r =await constructRequestValues(propsObj,stateObj.pagelevelValues,request,stateObj,true,false);
            request ={...request,...r}
        }
     }
     //construct request from parent for Endorsement pages
     if(Action.ParentRequestConstruction && Action.ParentRequestConstruction==="Y")
        {
            let rootlevelNeed =false;
            if(Action.IsRootLevelNeeded && Action.IsRootLevelNeeded==="Y" ){
                rootlevelNeed=true;
                let r =await constructRequestValuesV2(propsObj,propsObj.componentProps.pagelevelValues,request,propsObj.componentProps,true,false,rootlevelNeed);
                request ={...request,...r}
            }else{
                let r =await constructRequestValues(propsObj,propsObj.componentProps.pagelevelValues, request,propsObj.componentProps,true,false);
                request ={...request,...r}
            }
         }
     if(Action.FromRequestConstructionNeeded && Action.FromRequestConstructionNeeded==="Y"){
        let r =await formRequestConstruction(request,component,Action);
        request ={...request,...r}
    }
    if(Action.RequestMapping && Action.RequestMapping!=="")
    {
        let {RequestMapping}=Action;
        if(RequestMapping.hasOwnProperty("SessionState") && RequestMapping.SessionState.length>0)
        {
            let {UIFlowReducer,metadataReducer}=reduxState
            request = await dynamicObjectConstruction(RequestMapping.SessionState, { ...metadataReducer,...UIFlowReducer }, request, 'request');
        }
        if(RequestMapping.hasOwnProperty('localState') && RequestMapping.localState.length > 0) {
            request = await dynamicObjectConstruction(RequestMapping.localState, {...propsObj, ...stateObj}, request, 'request')
        }
        if(RequestMapping.hasOwnProperty('ApplicationState') && RequestMapping.ApplicationState.length > 0){
            let {metadataReducer}=reduxState
            request = await dynamicObjectConstruction(RequestMapping.ApplicationState, metadataReducer, request, 'request')
        }
        //to get UserRole Temporary UserState
        if(RequestMapping.hasOwnProperty('UserState') && RequestMapping.UserState.length > 0){
            let {userInfoReducer}=reduxState
            request = await dynamicObjectConstruction(RequestMapping.UserState, {...userInfoReducer,...stateValue}, request, 'request')
        }
        if(RequestMapping.hasOwnProperty("CustomProperties") && Object.keys(RequestMapping.CustomProperties).length>0)
        {
            request = await dynamicObjectConstruction(RequestMapping.CustomProperties, {...propsObj, ...stateObj}, request, 'custom')
        }

    }
    if(Action.FileAttachmentNeeded && Action.FileAttachmentNeeded==='Y'){        
        const subAppName = component.SubApplicationName;
        const entityHierarchyName = component.EntityHierarchyName;
        if (request[subAppName] && request[subAppName].hasOwnProperty('attachedFile') && Action.pathVariable !== '') {
            request = await uploadFile(request, subAppName);
        } else if (request[entityHierarchyName] && request[entityHierarchyName].hasOwnProperty('attachedFile') && Action.pathVariable !== '') {
            request = await uploadFile(request, entityHierarchyName);
        }
        async function uploadFile(req,name) {
            try {
                const formData = new FormData();
                formData.append("uploadFileData", req[name].attachedFile, req[name].attachedFile.name);
                let fileUploadResponse = await AxiosInstance.post("/core/fileUpload?pathVariable=" + Action.pathVariable, formData);
                delete req[name].attachedFile;
                return {
                    ...req,[name]: { ...req[name], AttachmentUrl: fileUploadResponse.data.newpath, AttachmentFileName: fileUploadResponse.data.newFileName }
                };
            } catch (e) {
                console.log("Error in Uploading File :: ", e);
                return req;
            }
        }
    }
    if(Action.OnStartMethod && Action.OnStartMethod!=="")
    {
        if(propsObj && propsObj[Action.OnStartMethod])
            request = await propsObj[Action.OnStartMethod](request,propsObj,e,stateObj);
    }
    if(Action.URL)
    {
        response = await postAPI(propsObj,Action,request);
        if(response) {
            if (response.ResponseStatus && response.ResponseStatus.toLowerCase() === 'failed') {
                return response
            }
        }
    }
    if(Action.updateSSOSessionData){
        response = await AxiosInstance.post('core/saveSSOSessionData',request);
    }
    if(Action.ParentDataListUpdate && Action.ParentDataListUpdate.isNeeded){
         let rowData = propsObj.componentProps.rowData;
         let parentDataList = propsObj.componentProps.dataList;
        const updateData = (Data) =>{
        let updatedList = Data.map((obj, index)=>{
            let DataL = obj;
            let key = obj.hasOwnProperty("ID") ? "ID" : "uid";
            if(rowData[key] === DataL[key]){
                for(const [key, value] of Object.entries(propsObj.componentState.SubApplicationEntity)) {
                    let {EntityHierarchyName}=propsObj.componentProps.subAppInfo.SubApplicationDetail
                    if(value!==''){
                        if(EntityHierarchyName===value)
                          DataL = {...DataL,...request[value],[value]:request[value]}
                        else
                        DataL = {...DataL,[value]:request[value]}
                    }
                    else{
                        if(EntityHierarchyName===key)
                          DataL = {...DataL,...request[key],[key]:request[key]}
                        else
                        DataL = {...DataL,[key]:request[key]}
                    }
                }
                DataL["isExpanded"] = false.toString();
                DataL["isSaved"] = 'true';
                let parentSummary = Action.ParentDataListUpdate.displaySummary ? Action.ParentDataListUpdate.displaySummary : ''
                if(parentSummary !== ''){
                let removeChildId = {...DataL[parentSummary]}
                removeChildId['ID'] && delete removeChildId['ID'];
                removeChildId['PARENTID'] && delete removeChildId['PARENTID'];
                DataL = {...DataL,...removeChildId}
                }
            }
            return DataL;
        })
        return updatedList
    }
    parentDataList = updateData(parentDataList);
    let {subAppInfo:{subapplicationName}}= propsObj.componentProps
    subapplicationName=subapplicationName.toLowerCase();
    if(!("immediateParentUpdate" in Action.ParentDataListUpdate) || Action.ParentDataListUpdate.immediateParentUpdate==='Y'){
        propsObj.componentProps.UpdateState(`${subapplicationName}_dataList`, parentDataList, true)
    }
    //Temporary Fix - iteratorlist update for carrierselectionpage - should be cleaned 
    if(Action.ParentDataListUpdate.IsIteratorList === 'Y'){
       let iteratorIndex = propsObj.componentProps.componentState.IteratorIndex
       let datalistName =  propsObj.componentProps.componentState["ResponseKey"]
       let datalist = propsObj.componentProps.componentProps[datalistName]
       datalist[iteratorIndex] = {...datalist[iteratorIndex], [`${subapplicationName}_dataList`]: parentDataList}
       propsObj.componentProps.componentProps.parentState.UpdateState(datalistName, datalist, true)
    }
}
    if(Action.OnExitMethod && Action.OnExitMethod!=="")
    {
        if(propsObj && propsObj[Action.OnExitMethod])
         await propsObj[Action.OnExitMethod](response,propsObj,stateObj,e);
    }
    if(Action.getvaluesapi && Action.getvaluesapi!==""){
        let optionObj= await dynValueListconstructor(Action.getvaluesapi,response)
        if(Object.keys(optionObj).length>0){
            tpo=mergeDeep(tpo,optionObj)
           }
    }
    if(Action.PreviewDocument && Object.keys(Action.PreviewDocument).length > 0){
        try{
        const reduxState = store.getState();
        let rootPath = Action.PreviewDocument.RootPath;
        let path = reduxState.ownerResources.clientInfo[rootPath];
        let responseKey = Action.PreviewDocument.ResponseKey;
        let relativePath = Action.PreviewDocument.RelativePath;
        let documentName = Action.PreviewDocument.DocumentName
        if(!rootPath && !documentName && !relativePath && responseKey){
            let file = response[responseKey]
            if(file){
            let FileName = file.substring(file.lastIndexOf('/') + 1);
            let path = file.replace(FileName,'');
            downloadFile(FileName,file);
            }
        }
        else{
            if(Array.isArray(response[responseKey])){
            path = path + response[responseKey][0][relativePath]
            FileHandlerAxios(path+ response[responseKey][0][documentName])
            }else{
            path = path + response[responseKey][relativePath]
            FileHandlerAxios(path+ response[responseKey][documentName])
            }
        }
        }
        catch(err){
            triggerToast('Error in Preview Document')
        }
    }
    if(Action.OpenTableDetail && Action.OpenTableDetail==='Y'){
        let entityData =[]
        Object.keys(response).map((resKey)=>{
            if(Array.isArray(response[resKey])){
                entityData = response[resKey]
            }
        })
        if(propsObj.componentProps){
            let updatedData =await getDataListWithExpand(entityData,[],stateObj,propsObj.componentProps)
        }
    }
    if(Action.ResponseTranslation && Object.keys(Action.ResponseTranslation).length > 0)
    {
        let {SessionState,ParentState,ParentStateUpdateNeeded} = Action.ResponseTranslation
        if(SessionState && Object.keys(SessionState).length > 0)
        {

            if(SessionState.hasOwnProperty("UIFlow") && SessionState.UIFlow.length > 0)
            {
                let temp = await dynamicObjectConstruction( SessionState.UIFlow, response, {}, "response");
                // store.dispatch({ type: actionType.SET_UI_VALUES, payload: temp }) //Moved to end of line
                if(temp.hasOwnProperty('pageData') && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y' ){
                    let val=window.location.hash.split('/'),pageData={},redirectRoute='';
                    if(val.length > 1 && val[2]){
                        let newVal=val[2];
                        pageData = decodeString(newVal)
                    }
                    pageData={...pageData,...temp.pageData}
                    const encodedString=await constructEncodedString(pageData);
                    redirectRoute+=`${encodedString}`;
                    if( propsObj.parentProps.hasOwnProperty('history') && propsObj.parentProps.history.push)
                        propsObj.parentProps.history.push(`/${val[1]}/${redirectRoute}`);
                    delete temp.pageData;
              }
                reduxObject={...reduxObject,...temp}
            }

            if(SessionState.hasOwnProperty("ApplicationState") && SessionState.ApplicationState.length > 0)
            {
                let obj = await dynamicObjectConstruction( SessionState.ApplicationState, response, {}, "response");
                await store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: obj })
            }
        }
        if(ParentState && Object.keys(ParentState).length > 0)
        {
            let temp={};
            temp = await dynamicObjectConstruction(ParentState, response, {}, "response");
            tpo={...tpo,...temp}
            if(ParentStateUpdateNeeded && ParentStateUpdateNeeded==='Y'){
                let {componentProps}=propsObj;
                if(componentProps)
                {
                    let {functions}=  componentProps
                    if(functions)
                    {
                        let {updateStateValues}=functions
                        updateStateValues(temp)
                        tpo={...tpo,...temp}
                    }
                    }
               }
        }

    }
    if(Action.ResponseTranslation && Object.keys(Action.ResponseTranslation).length > 0){
        if(Action.ResponseTranslation.TranslateResponse){
            let responsefromEntity = await EntitySubAppTranslationV3({...response}, {...propsObj.componentState});
            if(responsefromEntity){
                let {UpdateState} = propsObj.functions ;
                    if(UpdateState){
                        UpdateState("transactionPrimaryObject",{...propsObj.componentState.transactionPrimaryObject,...responsefromEntity.translatedResponse},false)
                        UpdateState("pagelevelValues",{...propsObj.componentState.pagelevelValues,...responsefromEntity.translatedResponse},Action.LoaderNeeded==="Y" ? false : true)
                    }
            }
        }
    }
    if(Action.CustomUpdate && Action.CustomUpdate.SessionState){
        let obj = await dynamicObjectConstruction(Action.CustomUpdate.SessionState, response, {}, "custom");
        // await store.dispatch({ type: actionType.SET_UI_VALUES, payload: obj })
        reduxObject={...reduxObject,...obj}
    }
    if(Action.CustomUpdate && Action.CustomUpdate.ApplicationState){
        let obj = await dynamicObjectConstruction(Action.CustomUpdate.ApplicationState, response, {}, "custom");
        await store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: obj })
    }
    if (Action.CustomUpdate && Action.CustomUpdate.ParentState) {
      let obj = await dynamicObjectConstruction(Action.CustomUpdate.ParentState, response, {}, "custom");
        tpo=mergeDeep(tpo,obj)
    }
    // Update datalists on state
    if (Action.UpdateDataList) {
        let { parentUpdate } = Action.UpdateDataList
        let parentUpdateState;
        if(parentUpdate && propsObj.componentProps && propsObj.componentProps.functions && propsObj.componentProps.functions.UpdateState){
            parentUpdateState = propsObj.componentProps.functions.UpdateState
        }
        setDataLists(Action.UpdateDataList.dataListName,response, functions.UpdateState, parentUpdateState)
    }
       //temporary fix because of Text component DidUpdate issue in RT    
       if(component.DependentComponent){
       let obj = {}
       deleteDependentAttribute(component,stateObj.pagelevelValues,stateObj.transactionPrimaryObject,propsObj)
       obj.pagelevelValues=stateObj.pagelevelValues
       obj.transactionPrimaryObject=stateObj.transactionPrimaryObject
       functions.updateValues(obj)
       propsObj.refs && propsObj.refs.current &&propsObj.refs.current.resetValidations()
    }
      if(Action.ResetPagelevelvalues && Action.ResetPagelevelvalues ==="Y"){
          let {updateValues,updateStateValues,UpdateState}=functions
          let object = {}
          Object.keys(stateObj).forEach(key=>{
              if(key.endsWith("_dataList"))
              object[key] = [];
        })
        object.pagelevelValues = {}
        object.transactionPrimaryObject= {}
        object.values = {}
        await updateValues(object)
        propsObj.refs && propsObj.refs.current &&propsObj.refs.current.resetValidations()
    }

    if(!Action.isTPONeeded && Action.ResetPagelevelvalues!=='Y'){
        tpo.transactionPrimaryObject= tpo.hasOwnProperty("transactionPrimaryObject") ? mergeDeep(stateObj.transactionPrimaryObject,tpo.transactionPrimaryObject):stateObj.transactionPrimaryObject
    }
    // temporary fix
    if(AjaxNeeded && AjaxNeeded.toLowerCase()==='true' && ((propsObj.componentState.pageSubAppInfo) || (propsObj.pageInfo))){
        // let {objectId,objectVerId, subappName=[]}=propsObj.componentState.pageSubAppInfo
        let {objectId,objectVerId}=propsObj.componentState.pageSubAppInfo?propsObj.componentState.pageSubAppInfo:propsObj.pageInfo;
        let subappName=propsObj.componentState.pageSubAppInfo?propsObj.componentState.pageSubAppInfo.subappName:propsObj.pageInfo?propsObj.pageInfo.subApplication:[];
        let {UpdateState}=functions
        let subAppDetail= {
            subappName:subappName,
            objectId : objectId,
            objectVerId : objectVerId
        }

        let MetadataResponse= await constructAndInvokeMetadataCall(subAppDetail,tpo)
        UpdateState("pageMetadata",MetadataResponse,true)
    }
    // if(AjaxNeeded && AjaxNeeded.toLowerCase()==='true' && propsObj.componentState.pageSubAppInfo){
    //     let {objectId,objectVerId, subappName=[]}=propsObj.componentState.pageSubAppInfo
    //     let {UpdateState}=functions
    //     let subAppDetail= {
    //         subappName:subappName,
    //         objectId : objectId,
    //         objectVerId : objectVerId
    //     }

    //     let MetadataResponse= await constructAndInvokeMetadataCall(subAppDetail,tpo)
    //     UpdateState("pageMetadata",MetadataResponse,true)
    // }
     if(!Action.isTPONeeded){
        let {updateValues,updateStateValues}=functions
        if(updateValues){
            Object.keys(tpo).length>0 &&  updateValues(tpo)
        }
    }

    if(Action.dynamicButton){
        const pageName = Action.pageName ? Action.pageName : Action.goto;
        store.dispatch({type: actionType.SET_RIDERECT_TO, payload: pageName});
    }
    if(Action.RedirectNextPage && Action.RedirectNextPage==="Y")
    {
        if(Action.RedirectPageName && Action.RedirectPageName != ""){
            if(Action.RedirectNextPageCriteria && Object.keys(Action.RedirectNextPageCriteria).length>0){
                let criteria =  criteriaValidationCheck(Action.RedirectNextPageCriteria, response)
                if(criteria){
                    reduxObject={...reduxObject,pageName:Action.RedirectPageName}
                }
            }
            else{
                // store.dispatch({type: actionType.SET_PAGENAME, payload: Action.RedirectPageName});
                let val=window.location.hash.split('/'),pageData={},redirectRoute='';
               if(val.length > 1 && val[2]){
                        let newVal=val[2];
                    pageData = decodeString(newVal)
                    pageData={...pageData,pageName:Action.RedirectPageName}
                    reduxObject={...reduxObject,pageName:Action.RedirectPageName,pageData}
                    const encodedString=await constructEncodedString(pageData);
                        redirectRoute+=`${encodedString}`;
                if( propsObj.hasOwnProperty('history') && propsObj.history.push){
                    propsObj.history.push(`/${val[1]}/${redirectRoute}`);
                }else if(propsObj.componentProps && propsObj.componentProps.history && propsObj.componentProps.history.push ){
                    propsObj.componentProps.history.push(`/${val[1]}/${redirectRoute}`);
                }
            } else
                reduxObject={...reduxObject,pageName:Action.RedirectPageName}
        }
    }
        else{
            let { UIFlowReducer:{currentPageIndex}}=reduxState;
            let criteria = true;
            if(Action.RedirectNextPageCriteria){
                criteria =  criteriaValidationCheck(Action.RedirectNextPageCriteria, response)
            }
            if(criteria){
                let landingIndex = Action.LandingIndex?Action.LandingIndex:currentPageIndex+1;
                    // store.dispatch({type:actionType.SET_CURRENT_PAGEINDEX,payload:Action.LandingIndex});
                    reduxObject={...reduxObject,currentPageIndex:landingIndex}
            }
        }
    }
    if(Action.RedirectPreviousPage && Action.RedirectPreviousPage==="Y"){
        let {UIFlowReducer:{currentPageIndex}}=reduxState;
        currentPageIndex-=1;
        // store.dispatch({type:actionType.SET_CURRENT_PAGEINDEX,payload:currentPageIndex});
        reduxObject={...reduxObject,currentPageIndex:currentPageIndex}
    }
    if(Action.hasOwnProperty("toaster")){
       await showtoaster(Action.toaster, response)
    }
    if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
    {
        // store.dispatch({type:actionType.LOCK_LOADER})
        let {UpdateState} = functions;
        if(UpdateState){
            UpdateState("loading",false,true)
        }
    }
    if(Object.keys(reduxObject).length>0)
    await store.dispatch({ type: actionType.SET_UI_VALUES, payload: reduxObject });

    if(Action.RedirectTo && Action.RedirectTo !== ""){
        let redirectRoute =Action.RedirectTo.toLowerCase()
        let criteria=true;
        let {navigationCriteria}=Action;
        if(Array.isArray(navigationCriteria) && navigationCriteria.length > 0)
        criteria=dynamicConditionValidationV2(response,navigationCriteria)
        if(criteria){
            await store.dispatch({ type: actionType.SET_FLOW_TYPE, payload: "Find" })
            let {RedirectPageName=""} =Action;
            store.dispatch({type: actionType.SET_PAGENAME, payload:RedirectPageName})
            if(component.Action.URLInfo && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
                let encryptedURLData = await constructEncodedString({...reduxState.UIFlowReducer,...reduxState.metadataReducer,...response},component.Action.URLInfo);
                redirectRoute+=`/${encryptedURLData}`;
            }
            store.dispatch({ type: actionType.SET_ROUTE_INFO, payload:`${redirectRoute.charAt(0).toUpperCase()}${redirectRoute.slice(1).split('/')[0]}`} )
           if( propsObj.hasOwnProperty('history') && propsObj.history.push){
            propsObj.history.push(`/${redirectRoute}`);
           }else if(propsObj.componentProps && propsObj.componentProps.history && propsObj.componentProps.history.push ){
            propsObj.componentProps.history.push(`/${redirectRoute}`);
           }
         }
    }
}
}

export const  onTableLazyLoadData = async (offsetInfo,parentProps,updateDataTableState,apicallcancelRef)=>{
let { tableUtils, tableFunctions, tableActions,componentProps, componentState,functions } = parentProps;
let {lazyLoading:{lazyLoadActions}} = tableActions;
let Action = lazyLoadActions;
try{
    let response={},request={};
    if(!Object.keys(lazyLoadActions).length)
    {
        lazyLoadActions={};

    }else{
        request["OwnerId"]="";
        if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
        {
            // store.dispatch({type:actionType.SPIN_LOADER})
            let {UpdateState} = functions;
            if(UpdateState){
                UpdateState("loading",true,true)
            }
        }
        let reduxState=store.getState();
        let {metadataReducer:{productDetails}}=reduxState;
        request={...request,ServiceRequestDetail:getServiceRequestDetail()}

        if(Action.hasOwnProperty("EventMapping"))
        {

                Object.entries(Action.EventMapping).forEach(([key, value]) => {
                    request[key]=value
                  });

        }
        if(Action.GenerateJSONNeeded && Action.GenerateJSONNeeded==="Y")
        {
            let r = await getJSONRequestFromAPI(request["EventName"],productDetails,Action.URLVersion);
            request ={...request,...r}
        }
        if(Action.RequestConstruction && Action.RequestConstruction==="Y")
        {
            let rootlevelNeed =false;
            if(Action.IsRootLevelNeeded && Action.IsRootLevelNeeded==="Y" ){
            rootlevelNeed=true;
            let r =await constructRequestValuesV2(componentProps,componentState.pagelevelValues,request,componentState,true,false,rootlevelNeed);
            request ={...request,...r}
        }else{
            let r =await constructRequestValues(componentProps,componentState.pagelevelValues,request,componentState,true,false);
            request ={...request,...r}
        }

        }
        if(Action.RequestMapping && Action.RequestMapping!=="")
        {
            let {RequestMapping}=Action;
            if(RequestMapping.hasOwnProperty("SessionState") && RequestMapping.SessionState.length>0)
            {
                let {UIFlowReducer}=reduxState
                request = await dynamicObjectConstruction(RequestMapping.SessionState, UIFlowReducer, request, 'request')
            }
            //Temporary UserState
            if(RequestMapping.hasOwnProperty('UserState') && RequestMapping.UserState.length > 0){
                let {userInfoReducer}=reduxState
                request = await dynamicObjectConstruction(RequestMapping.UserState, userInfoReducer, request, 'request')
            }
            if(RequestMapping.hasOwnProperty('localState') && RequestMapping.localState.length > 0) {
                request = await dynamicObjectConstruction(RequestMapping.localState, {...componentProps, ...componentState}, request, 'request')
            }
            if(RequestMapping.hasOwnProperty("CustomProperties") && Object.keys(RequestMapping.CustomProperties).length>0)
            {
                RequestMapping.CustomProperties.forEach((property) => {
                    let {RequestKey,Value}=property
                    if(RequestKey.includes("/"))
                    {
                        let temp=RequestKey;
                        let KeyList = temp.split("/");
                        checkNested(request,KeyList,Value)
                    }else
                    {
                        request[RequestKey]=Value
                    }

                })
        }
    }
        // if(Action.OnStartMethod && Action.OnStartMethod!=="")
        // {
        //     if(componentProps && componentProps[Action.OnStartMethod])
        //         request = componentProps[Action.OnStartMethod](request,componentProps,e);
        // }
        let trimmedOffsetInfo = {}
        Object.entries(offsetInfo).map(([key, value])=>{
            if(typeof value === 'string'){
                trimmedOffsetInfo[key] =  value.trim()
            }
            else{
                trimmedOffsetInfo[key] =  value
            }
            if(key.includes('_sort') && key===`${offsetInfo['currentAttribute']}_sort`){
                request[key]=value;
            }
        })
        request = {...request,...trimmedOffsetInfo};
        delete trimmedOffsetInfo['StartLimit']
        delete trimmedOffsetInfo['EndLimit']
        updateDataTableState && updateDataTableState("filtercriteria", trimmedOffsetInfo, false)
        if(Action.URL)
        {
            if(apicallcancelRef && apicallcancelRef.current){
                apicallcancelRef.current.cancel();
            }
            apicallcancelRef.current = axios.CancelToken.source();
            let APIURL =  Action.URL && Action.URLVersion ? `${Action.URL}/${Action.URLVersion}` : Action.URL
            if(Action.URLVersion ==="V3" || Action.URLVersion ==="V4")
                response = await commonProductAxiosInstance.post(APIURL,request,{
                    cancelToken : apicallcancelRef.current ? apicallcancelRef.current.token : ''
                });
            else
                response = await AxiosInstance.post(APIURL,request,{
                    cancelToken : apicallcancelRef.current ? apicallcancelRef.current.token : ''
                });
            if(response) {
                response=response.data
                if (response.ResponseStatus && (response.ResponseStatus.toLowerCase() === 'failed' || response.ResponseStatus.toLowerCase() === 'error')) {
                    triggerToast(response.ResponseStatus)
                    console.log('Error:: ', response.ResponseStatus)
                    if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
                    {
                        let {UpdateState} = functions;
                        if(UpdateState){
                            UpdateState("loading",false,true)
                        }
                    }
                    return response
                }
            }
        }
        if(Action.successToastNotificationMessage && Action.successToastNotificationMessage !== ""){

            successToast(Action.successToastNotificationMessage);

        }
        // if(Action.OnExitMethod && Action.OnExitMethod!=="")
        // {
        //     if(propsObj && propsObj[Action.OnExitMethod])
        //         propsObj[Action.OnExitMethod](response,propsObj,stateObj,e);
        // }
        if(Action.ResponseTranslation && Object.keys(Action.ResponseTranslation).length > 0)
        {
            let {SessionState,ParentState} = Action.ResponseTranslation
            if(SessionState && Object.keys(SessionState).length > 0)
            {

                if(SessionState.hasOwnProperty("UIFlow") && SessionState.UIFlow.length > 0)
                {
                    let temp={};
                    let {UIFlow}=SessionState
                    UIFlow.forEach((State) =>{
                        if(State.ResponseKey==='APIResponse')
                            temp[State.TargetAttribute]=response
                        else
                            temp[State.TargetAttribute]=response[State.ResponseKey]
                    })
                    store.dispatch({ type: actionType.SET_UI_VALUES, payload: temp })
                }
            }
            if(ParentState && Object.keys(ParentState).length > 0)
            {
                let temp={};
                ParentState.forEach((state)=>{
                    temp[state.TargetAttribute]=response[state.ResponseKey]
                })


                    if(functions)
                    {
                        let {updateValues,updateStateValues}=functions
                        updateStateValues(temp)
                    }

            }
        }
        if(Action.CustomUpdate && Action.CustomUpdate.SessionState){
            let obj = await dynamicObjectConstruction(Action.CustomUpdate.SessionState, {}, {}, "custom");
            store.dispatch({ type: actionType.SET_UI_VALUES, payload: obj })
        }
        if(Action.RedirectNextPage && Action.RedirectNextPage==="Y")
        {
            if(Action.RedirectPageName && Action.RedirectPageName != ""){
                store.dispatch({type: actionType.SET_PAGENAME, payload: Action.RedirectPageName});
            }
            else {
                let {UIFlowReducer:{currentPageIndex}}=reduxState;
                currentPageIndex+=1;
                store.dispatch({type:actionType.SET_CURRENT_PAGEINDEX,payload:currentPageIndex});
            }

        }
        if(Action.RedirectTo && Action.RedirectTo !== ""){
            let redirectTo = `/${Action.RedirectTo}`;
            store.dispatch({type: actionType.SET_PAGENAME, payload: ''});
            if(componentProps.hasOwnProperty('history') && componentProps.history.push) componentProps.history.push(redirectTo);
        }
        if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
        {
            // store.dispatch({type:actionType.LOCK_LOADER})
            let {UpdateState} = functions;
            if(UpdateState){
                UpdateState("loading",false,true)
            }
        }
    }

}catch(e){
    if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
        {
            // store.dispatch({type:actionType.LOCK_LOADER})
            let {UpdateState} = functions;
            if(UpdateState){
                UpdateState("loading",false,true)
            }
        }
    console.log('Error in onTableLazyLoadData',e);
}
}

// common select util initial
export const commonSelectMethod = async (component, value, props, handlerNeeded = true, functions) => {
  try {
    let reduxState=store.getState()

    let { Action = {},AjaxNeeded="false" } = component, parentUpdateObject = {}, request = {}, response = {}
    request["OwnerId"] = ""
    request = { ...request, ServiceRequestDetail: getServiceRequestDetail() }
    if (props.customTable && handlerNeeded)
      props.handleRowLevelInputChange && props.handleRowLevelInputChange(value, component, props.customTableRowIndex, props)
    else if(handlerNeeded) props.handleInputChangeV2 && await props.handleInputChangeV2(value, component,'','',props)

    if (Object.keys(Action).length > 0) {
        let tpo={}
        if(Action.LoaderNeeded && Action.LoaderNeeded==="Y"){
            let {UpdateState} = props.functions;
            if(UpdateState){
                UpdateState("loading",true,true)
            }
        }
        if(Action.AttributeLoaderNeeded && Action.AttributeLoaderNeeded === "Y"){
             let {setLoading} = functions;
            if(setLoading){
                setLoading(true)
            }
        }
      if (Action.hasOwnProperty("EventMapping")) {
        Object.entries(Action.EventMapping).forEach(([key, value]) => {
          request[key] = value
        });
      }
      if (Action.RequestMapping && Action.RequestMapping !== "") {
        let { RequestMapping } = Action;
        if(RequestMapping.hasOwnProperty('ConstructRowDataWithTranslation') && RequestMapping.ConstructRowDataWithTranslation != "" && RequestMapping.ConstructRowDataWithTranslation.toString().toLowerCase() === "true"){
            let valuesForRequest={...props.customTableRowIndex};
            let keys = Object.keys(value);
            let attributeName = keys[0];
            valuesForRequest[attributeName] = value[attributeName].toString();
            request={...request,[component.EntityHierarchyName ? component.EntityHierarchyName : component.SubApplicationName]:valuesForRequest};
        }
        if (RequestMapping.hasOwnProperty('ConstructRowData') && RequestMapping.ConstructRowData != "" && RequestMapping.ConstructRowData.toString().toLowerCase() === "true") {
          Object.entries(props.customTableRowIndex).forEach(([key, value]) => {
            request[key] = value
          });
          // to update the latest value selected
          let keys = Object.keys(value)
          let attributeName = keys[0]
          request[attributeName] = value[attributeName].toString()
        }
        else{
            if(RequestMapping.hasOwnProperty("SessionState") && RequestMapping.SessionState.length>0)
            {
                let {UIFlowReducer,metadataReducer}=reduxState
                request = await dynamicObjectConstruction(RequestMapping.SessionState, { ...metadataReducer,...UIFlowReducer }, request, 'request');
            }
            if(RequestMapping.hasOwnProperty('localState') && RequestMapping.localState.length > 0) {
                request = await dynamicObjectConstruction(RequestMapping.localState, {...props,...value}, request, 'request')
            }
            if(RequestMapping.hasOwnProperty("CustomProperties") && Object.keys(RequestMapping.CustomProperties).length>0)
            {
                RequestMapping.CustomProperties.forEach((property) => {
                    let {RequestKey,Value}=property
                    if(RequestKey.includes("/"))
                    {
                        let temp=RequestKey;
                        let KeyList = temp.split("/");
                        checkNested(request,KeyList,Value)
                    }else
                    {
                        request[RequestKey]=Value
                    }

                })
        }
        }
      }
      if(Action.OnStartMethod && Action.OnStartMethod!=="")
        {
            if(props && props[Action.OnStartMethod])
                request = await props[Action.OnStartMethod](request,props);
        }
      if (Action.URL) {
        let APIURL = Action.URL && Action.URLVersion ? `${Action.URL}/${Action.URLVersion}` : Action.URL
        if (Action.URLVersion === "V3" || Action.URLVersion === "V4")
          response = await commonProductAxiosInstance.post(APIURL, request);
        else
          response = await AxiosInstance.post(APIURL, request);
        if (response) {
          response = response.data
          if (response.ResponseStatus && (response.ResponseStatus.toLowerCase() === 'failed' || response.ResponseStatus.toLowerCase() === 'error')) {
            triggerToast(response.ResponseStatus)
            if(Action.AttributeLoaderNeeded && Action.AttributeLoaderNeeded === "Y"){
                let {setLoading} = functions;
                if(setLoading){
                    setLoading(false)
                }
            }
            if(Action.LoaderNeeded && Action.LoaderNeeded==="Y"){
                let {UpdateState} = props.functions;
                if(UpdateState){
                        UpdateState("loading",false,true)
                    }
            }
            console.log('Error:: ', response.ResponseStatus)
            return response
          }
          else if (Action.successToastNotificationMessage && Action.successToastNotificationMessage !== "") {
            successToast(Action.successToastNotificationMessage);
          }
        }
      }
      if(Action.OnExitMethod && Action.OnExitMethod!=="")
        {
            if(props && props[Action.OnExitMethod])
             await props[Action.OnExitMethod](response,props);
        }
      if(Action.ResponseTranslation){
        if(Action.ResponseTranslation && Action.ResponseTranslation.LocalState){
            let obj = await dynamicObjectConstruction(Action.ResponseTranslation.LocalState, response, {}, "response");
            obj.hasOwnProperty("Options") && obj.Options && functions && functions.setOptions && functions.setOptions(obj.Options);
        }
        if(Action.ResponseTranslation.customOptionsConstruction && Object.keys(Action.ResponseTranslation.customOptionsConstruction).length>0){
            let obj = await setDefaultValue(Action.ResponseTranslation.customOptionsConstruction, response);
            functions && functions.setOptions && functions.setOptions(obj);
        }
        if(Action.ResponseTranslation && Action.ResponseTranslation.SessionState){
          let obj = await dynamicObjectConstruction(Action.ResponseTranslation.SessionState, response, {}, "response");
          store.dispatch({ type: actionType.SET_UI_VALUES, payload: obj })
      }
        // sessionState, parentState update needs to be handled.
        if(Action.ResponseTranslation.ParentState && Object.keys(Action.ResponseTranslation.ParentState).length > 0)
            {
                let temp={};
                temp = await dynamicObjectConstruction(Action.ResponseTranslation.ParentState, {...response,...value}, {}, "response");
                tpo={...tpo,...temp}
                if(Action.ResponseTranslation.ParentStateUpdateNeeded && Action.ResponseTranslation.ParentStateUpdateNeeded==='Y'){
                    let {componentProps}=props;
                    if(componentProps)
                    {
                        let {functions}=  componentProps
                        if(functions)
                        {
                            let {updateStateValues}=functions
                            updateStateValues(temp)
                            tpo={...tpo,...temp}
                        }
                        }
                   }
            }
        if(Action.ResponseTranslation  && Action.ResponseTranslation.TranslateResponse){
            let responsefromEntity = await EntitySubAppTranslationV3({...response}, {...props.componentState});
            if(responsefromEntity){
                let {UpdateState} = props.functions;
                    if(UpdateState){
                        if(Object.keys(responsefromEntity.translatedList).length > 0)
                        Object.keys(responsefromEntity.translatedList).forEach(datalist=>{
                            UpdateState(datalist,responsefromEntity.translatedList[datalist],false)
                        })
                        UpdateState("transactionPrimaryObject",{...props.componentState.transactionPrimaryObject,...responsefromEntity.translatedResponse},false)
                        UpdateState("pagelevelValues",{...props.componentState.pagelevelValues,...responsefromEntity.translatedResponse},Action.LoaderNeeded==="Y" ? false : true)
                    }
            }
        }
      }
      if(Action.CustomUpdate && Action.CustomUpdate.SessionState){
        let obj = await dynamicObjectConstruction(Action.CustomUpdate.SessionState, response, {}, "custom");
        await store.dispatch({ type: actionType.SET_UI_VALUES, payload: obj })
      }
      if(Action.CustomUpdate && Action.CustomUpdate.ApplicationState){
        let obj = await dynamicObjectConstruction(Action.CustomUpdate.ApplicationState, response, {}, "custom");
        await store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: obj })
      }
      if (Action.CustomUpdate && Action.CustomUpdate.ParentState) {
        tpo = await dynamicObjectConstruction(Action.CustomUpdate.ParentState, {}, parentUpdateObject, "custom");
      }
      if(handlerNeeded && AjaxNeeded.toLowerCase()==="true" && Action.ResetSummary ){
        let columnList={}
        let {ResetSummary=[]}=Action
        ResetSummary=ResetSummary.map(str => str.toLowerCase());
        Object.keys(props.componentState).map(key=>{
            if(key.endsWith("_dataList") && ResetSummary.includes(key.slice(0, key.lastIndexOf("_dataList")))){
                columnList[key]=[]
            }
        })
        let {updateValues}=props.functions
        updateValues && Object.keys(columnList).length > 0  && updateValues(columnList)
    }
    if(Action.isTPONeeded){
         tpo.transactionPrimaryObject= tpo.hasOwnProperty("transactionPrimaryObject") ? mergeDeep(props.componentState.transactionPrimaryObject,tpo.transactionPrimaryObject):props.componentState.transactionPrimaryObject
         tpo.pagelevelValues=tpo.hasOwnProperty("pagelevelValues")?mergeDeep(props.componentState.pagelevelValues,tpo.pagelevelValues):props.componentState.pagelevelValues
    }
      if(Action.isTPONeeded && AjaxNeeded.toLowerCase()==='true'){
        let {objectId,objectVerId, subappName=[]}=props.componentState.pageSubAppInfo
        let {UpdateState}=props.functions
        let subAppDetail= {
            subappName:subappName,
            objectId : objectId,
            objectVerId : objectVerId
         }
        let MetadataResponse= await constructAndInvokeMetadataCall(subAppDetail,tpo)
        UpdateState("pageMetadata",MetadataResponse,true)
    }
    Object.keys(tpo).length>0 && props.functions.updateValues(tpo)
    if(Action.AttributeLoaderNeeded && Action.AttributeLoaderNeeded === "Y"){
        let {setLoading} = functions;
        if(setLoading){
            setLoading(false)
        }
    }
    if(Action.LoaderNeeded && Action.LoaderNeeded==="Y"){
        let {UpdateState} = props.functions;
        if(UpdateState){
                UpdateState("loading",false,true)
            }
    }
    }
  }
  catch (err) {
    console.log("Error in Common Select Method :: ", err)
  }
}
// common hyperLink in DataTable util
export const commonLinkMethod = async (component, data, props, dynamicURL='') => {   
    try {
        let { Action = {},AjaxNeeded="false" } = component, request={}, response={},reduxObject={},tpo={};
        if(Object.keys(Action).length > 0){
                request["OwnerId"]="";
                let {functions}=props
                if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
                {
                    // store.dispatch({type:actionType.SPIN_LOADER})
                    let {UpdateState} = functions;
                    if(UpdateState){
                        UpdateState("loading",true,true)
                    }
                }
                let reduxState=store.getState();
                let {metadataReducer:{productDetails}}=reduxState;
                request={...request,ServiceRequestDetail:getServiceRequestDetail()}

                if(Action.hasOwnProperty("EventMapping"))
                {

                        Object.entries(Action.EventMapping).forEach(([key, value]) => {
                            request[key]=value
                          });

                }
                    if(Action.GenerateJSONNeeded && Action.GenerateJSONNeeded==="Y")
                {
                    let r = await getJSONRequestFromAPI(request["EventName"],productDetails,Action.URLVersion);
                    request ={...request,...r}
                }
                if(Action.RequestConstruction && Action.RequestConstruction==="Y")
                {
                    let rootlevelNeed =false;
                    if(Action.IsRootLevelNeeded && Action.IsRootLevelNeeded==="Y" ){
                        rootlevelNeed=true;
                        let r =await constructRequestValuesV2(props,props.componentState.pagelevelValues,request,props.componentState,true,false,rootlevelNeed);
                        request ={...request,...r}
                    }else{
                        let r =await constructRequestValues(props,props.componentState.pagelevelValues,request,props.componentState,true,false);
                        request ={...request,...r}
                    }
                 }
                 if(Action.FromRequestConstructionNeeded && Action.FromRequestConstructionNeeded==="Y"){
                    let r =await formRequestConstruction(request,component,Action);
                    request ={...request,...r}
                }
                if(Action.RequestMapping && Action.RequestMapping!=="")
                {
                    let {RequestMapping}=Action;
                    if(RequestMapping.hasOwnProperty("SessionState") && RequestMapping.SessionState.length>0)
                    {
                        let {UIFlowReducer}=reduxState
                        request = await dynamicObjectConstruction(RequestMapping.SessionState, UIFlowReducer, request, 'request');
                    }
                    if(RequestMapping.hasOwnProperty('localState') && RequestMapping.localState.length > 0) {
                        request = await dynamicObjectConstruction(RequestMapping.localState, {...props, ...props.componentState}, request, 'request')
                    }
                    //Temporary UserState
                    if(RequestMapping.hasOwnProperty('UserState') && RequestMapping.UserState.length > 0){
                        let {userInfoReducer}=reduxState
                        request = await dynamicObjectConstruction(RequestMapping.UserState, {...userInfoReducer,...props.rowData}, {}, 'request')
                    }
                    if(RequestMapping.hasOwnProperty("CustomProperties") && Object.keys(RequestMapping.CustomProperties).length>0)
                    {
                        //request = await dynamicObjectConstruction(RequestMapping.CustomProperties, {}, request, 'custom')
                            RequestMapping.CustomProperties.forEach((property) => {
                                let {RequestKey,Value}=property
                                if(RequestKey.includes("/"))
                                {
                                    let temp=RequestKey;
                                    let KeyList = temp.split("/");
                                    checkNested(request,KeyList,Value)
                                }else
                                {
                                    request[RequestKey]=Value
                                }

                            })
                    }
                }
                if(Action.OnStartMethod && Action.OnStartMethod!=="")
                {
                    if(props && props[Action.OnStartMethod])
                        request = props[Action.OnStartMethod](request,props);
                }
                if(Action.URL)
                {
                    response = await postAPI(props,Action,request);
                }
                if(Action.updateSSOSessionData){
                    response = await AxiosInstance.post('core/saveSSOSessionData',request);
                }
                if(Action.OnExitMethod && Action.OnExitMethod!=="")
                {
                    if(props && props[Action.OnExitMethod])
                        props[Action.OnExitMethod](response,props,props.componentState);
                }
                if(Action.PreviewDocument && Object.keys(Action.PreviewDocument).length > 0){
                    try{
                    const reduxState = store.getState();
                    let rootPath = Action.PreviewDocument.RootPath;
                    let path = reduxState.ownerResources.clientInfo[rootPath];
                    let responseKey = Action.PreviewDocument.ResponseKey;
                    let relativePath = Action.PreviewDocument.RelativePath;
                    let documentName = Action.PreviewDocument.DocumentName
                    if (Array.isArray(response[responseKey])) {
                        path = path + response[responseKey][0][relativePath]
                        FileHandlerAxios(path+response[responseKey][0][documentName] )
                        }else{
                        path = path + response[responseKey][relativePath]
                        FileHandlerAxios(path+response[responseKey][documentName])
                        }
                    }
                    catch(err){
                        triggerToast('Error in Preview Document')
                    }
                }
                if(Action.OpenTableDetail && Action.OpenTableDetail==='Y'){
                    let entityData =[]
                    Object.keys(response).map((resKey)=>{
                        if(Array.isArray(response[resKey])){
                            entityData = response[resKey]
                        }
                    })
                    if(props.componentProps){
                        let updatedData =await getDataListWithExpand(entityData,[],props.componentState,props.componentProps)
                    }
                }
                if(Action.ResponseTranslation && Object.keys(Action.ResponseTranslation).length > 0)
                {
                    let {ParentState,ParentStateUpdateNeeded} = Action.ResponseTranslation
                    if(ParentState && Object.keys(ParentState).length > 0)
                    {
                        let temp={};
                        temp = await dynamicObjectConstruction(ParentState, response, {}, "response");
                       tpo={...temp}
                        // if(functions)
                        //  {
                        //     let {updateStateValues}=functions
                        //     updateStateValues(temp)
                        // }
                        if(ParentStateUpdateNeeded && ParentStateUpdateNeeded==='Y'){
                            let {componentProps}=props;
                            if(componentProps)
                            {
                                let {functions}=  componentProps
                                if(functions)
                                {
                                    let {updateStateValues}=functions
                                    updateStateValues(temp)
                                }
                            }
                           }
                    }

                }
                if (Action.CustomUpdate && Action.CustomUpdate.ParentState) {
                    let obj = await dynamicObjectConstruction(Action.CustomUpdate.ParentState, {}, {}, "custom");
                    tpo=mergeDeep(tpo,obj)
                    // let {updateValues,updateStateValues}=functions
                    // updateValues(obj)
                  }
                  if(Action.ResponseTranslation && Action.ResponseTranslation.SessionState){
                    let obj = await dynamicObjectConstruction(Action.ResponseTranslation.SessionState, data, {}, "response");                    
                   await store.dispatch({ type: actionType.SET_UI_VALUES, payload: obj })
                }
                if(Action.CustomUpdate && Action.CustomUpdate.SessionState){
                    let obj = await dynamicObjectConstruction(Action.CustomUpdate.SessionState, {}, {}, "custom");
                    await store.dispatch({ type: actionType.SET_UI_VALUES, payload: obj })
                }
                if(Action.ResponseTranslation && Action.ResponseTranslation.ApplicationState){
                    let obj = await dynamicObjectConstruction( Action.ResponseTranslation.ApplicationState, data, {}, "response");
                    await store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: obj })
                }
                if(Action.CustomUpdate && Action.CustomUpdate.ApplicationState){
                    let obj = await dynamicObjectConstruction(Action.CustomUpdate.ApplicationState, {}, {}, "custom");
                    await store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: obj })
                }
                if(Action.ResetPagelevelvalues && Action.ResetPagelevelvalues ==="Y"){
                    let {updateValues,updateStateValues,UpdateState}=functions
                    Object.keys(props.componentState).forEach(key=>{
                        if(key.endsWith("_dataList"))
                        UpdateState(key,[],true)
                    })
                    UpdateState("pagelevelValues",{},false)
                    UpdateState("transactionPrimaryObject",{},false)
                    UpdateState("values",{},true)
                }
                if(!Action.isTPONeeded){
                    tpo.transactionPrimaryObject= tpo.hasOwnProperty("transactionPrimaryObject") ? mergeDeep(props.componentState.transactionPrimaryObject,tpo.transactionPrimaryObject):props.componentState.transactionPrimaryObject
                }
                tpo.pagelevelValues=tpo.hasOwnProperty("pagelevelValues")?mergeDeep(props.componentState.pagelevelValues,tpo.pagelevelValues):props.componentState.pagelevelValues
                if(AjaxNeeded && AjaxNeeded.toLowerCase()==='true' && props.componentState.pageSubAppInfo){
                    let {objectId,objectVerId, subappName=[]}=props.componentState.pageSubAppInfo
                    let {UpdateState}=functions
                    let subAppDetail= {
                        subappName:subappName,
                        objectId : objectId,
                        objectVerId : objectVerId
                     }

                    let MetadataResponse= await constructAndInvokeMetadataCall(subAppDetail,tpo)
                    UpdateState("pageMetadata",MetadataResponse,true)
                }
                    if(!Action.isTPONeeded){
                    let {updateValues,updateStateValues}=functions
                    if(updateValues){
                        Object.keys(tpo).length>0 &&  updateValues(tpo)
                    }
                }
                if(Action.RedirectNextPage && Action.RedirectNextPage==="Y")
                {
                    if(Action.RedirectPageName && Action.RedirectPageName != ""){
                            let val=window.location.hash.split('/'),pageData={},redirectRoute='';
                            if(val.length > 1 && val[2]){
                                let newVal=val[2];
                                pageData = decodeString(newVal) 
                                pageData={...pageData,pageName:Action.RedirectPageName}
                                const encodedString= await constructEncodedString(pageData); 
                                if(component.Action.RedirectTo){
                                    redirectRoute+=`/${encodedString}`;
                                }
                            if( props.hasOwnProperty('history') && props.history.push){
                                props.history.push(`/${val[1]}/${redirectRoute}`);
                            }else if(props.componentProps && props.componentProps.history && props.componentProps.history.push ){
                                props.componentProps.history.push(`/${val[1]}/${redirectRoute}`);
                            }
                            store.dispatch({ type: actionType.SET_UI_VALUES, payload: {pageData:{...pageData}}});
                            store.dispatch({type: actionType.SET_PAGENAME, payload: Action.RedirectPageName});
                        }else{
                            if(Action.RedirectPageName && Action.RedirectPageName != ""){
                                store.dispatch({type: actionType.SET_PAGENAME, payload: Action.RedirectPageName});
                            }
                            else {
                                let {UIFlowReducer:{currentPageIndex}}=reduxState;
                                currentPageIndex+=1;
                                store.dispatch({type:actionType.SET_CURRENT_PAGEINDEX,payload:currentPageIndex});
                            }
                        }
                    }
                }

                if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
                {
                    // store.dispatch({type:actionType.LOCK_LOADER})
                    let {UpdateState} = functions;
                    if(UpdateState){
                        UpdateState("loading",false,true)
                    }
                }
            if(Action.RedirectTo && Action.RedirectTo !== ""){
                let redirectRoute =Action.RedirectTo.toLowerCase();
                 await store.dispatch({ type: actionType.SET_FLOW_TYPE, payload: "Find" })
                 let {RedirectPageName=""} =Action;
                 if(Action.URLInfo && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
                    const encodedString=await constructEncodedString({...reduxState.UIFlowReducer,...data},Action.URLInfo);
                    if(component.Action.RedirectTo){
                        redirectRoute+=`/${encodedString}`;
                    }
                }
                 store.dispatch({type: actionType.SET_PAGENAME, payload:RedirectPageName})
                 store.dispatch({ type: actionType.SET_ROUTE_INFO, payload:`${redirectRoute.charAt(0).toUpperCase()}${redirectRoute.slice(1).split('/')[0]}`} )
                if( props.hasOwnProperty('history') && props.history.push){
                    if(dynamicURL && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y')
                        props.history.push(dynamicURL);
                    else
                    props.history.push(`/${redirectRoute}`);
                }else if(props.componentProps && props.componentProps.history && props.componentProps.history.push ){
                    if(dynamicURL && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y')
                        props.componentProps.history.push(dynamicURL);
                    else
                    props.componentProps.history.push(`/${redirectRoute}`);
                }
            }

            if(Action.DynamicRedirection && Object.keys(Action.DynamicRedirection).length > 0){
                let { ObjectID, ObjectName } = await dynamicObjectConstruction(Action.DynamicRedirection.RedirectionInfo, data, {}, "response");
                if(ObjectID)
                    store.dispatch({ type: actionType.SET_UI_VALUES, payload: {[`${ObjectName.toLowerCase()}ID`]: ObjectID} })
                if(ObjectName && ObjectName !== "" ){
                    ObjectName=ObjectName.toLowerCase();
                 let {RedirectPageName=""} =Action;
                 store.dispatch({type: actionType.SET_PAGENAME, payload:RedirectPageName})
                 store.dispatch({ type: actionType.SET_ROUTE_INFO, payload:`${ObjectName.charAt(0).toUpperCase()}${ObjectName.slice(1)}`} )
                 if(props.hasOwnProperty('history') && props.history.push) props.history.push(`/${ObjectName}`);
                 else if(props.componentProps.hasOwnProperty('history') && props.componentProps.history.push)  props.componentProps.history.push(`/${ObjectName}`);
                }

            }
           }
        }
    catch(err) {
        console.log("Error in Common Link Method :: ", err);
    }

}

export const commonEmailUploader = async (attachedFile,setAttachment,attachment,props,uploadDocuments, component, setNewErr,newEr)=>{
    try{
      if(uploadDocuments){
          let fileNameError='',fileExtensionError='',fileSizeError='';
          let {Action} = component
          if(Object.keys(Action).length>0 && Action.ValidationMessages){
              fileNameError = Action.ValidationMessages["fileNameError"] ? component.Action.ValidationMessages["fileNameError"] : ""
              fileExtensionError = Action.ValidationMessages["fileExtensionError"] ? component.Action.ValidationMessages["fileExtensionError"] : ""
              fileSizeError = Action.ValidationMessages["fileSizeError"] ? component.Action.ValidationMessages["fileSizeError"] : ""
          }
        let pathVariable = (component.Action && component.Action.pathVariable) ? component.Action.pathVariable  : 'ATTACHMENTS_POLICY'
        let valueForAttachment=JSON.parse(JSON.stringify(attachment));
        let valueForError=[], errr={}
        for (let idx in attachedFile){
          if(attachedFile[idx].name && typeof(attachedFile[idx])=='object'){
            const fileToUpload = new FormData();
            fileToUpload.append("uploadFileData",attachedFile[idx],attachedFile[idx].name);
            let fileNameWithoutExtension = attachedFile[idx] &&attachedFile[idx].name && attachedFile[idx].name.substring(0,attachedFile[idx].name.lastIndexOf('.'));
            let fileExtension = attachedFile[idx] && attachedFile[idx].name.split(".").pop().toLowerCase();
            //component.ItemTipParams = 	"pdf,docx,xlsx,pptx,ppt,doc,xls,zip,png,jpeg,tiff,jpg,txt,rtf,rar";
            let fileTypeVal = component.ItemTipParams==='' ? false : !(component.ItemTipParams.split(',').map(
              item => item.trim()).includes(attachedFile[idx] ? fileExtension: ""))
              //component.ValidationRegex= "[`!@#$%^&*()+\\-=\\[\\]{};':\"\\\\|,.×<>\\/?~ ]"
            let splcharRgxValidation = component.ValidationRegex !== "" ? new RegExp(component.ValidationRegex).test(fileNameWithoutExtension) : false
            //component.MaxValue= "2097";
            let fileSize=attachedFile[idx] && attachedFile[idx].size> component.MaxValue;
              if(fileTypeVal){
                  errr["fileExtensionError"]=fileExtensionError
              }
              if(splcharRgxValidation){
                  errr["fileNameError"]=fileNameError
              }
              if(fileSize){
                  errr["fileSizeError"]=fileSizeError
              }
              if(Object.keys(errr).length>0){
                valueForError.push({AttachmentName:attachedFile[idx].name,...errr})
                setNewErr([...newEr,...valueForError])
              }
            let {data:fileUploadResponse} = await AxiosInstance.post("/core/fileUpload?pathVariable="+`${pathVariable}`, fileToUpload);
            let filename = attachedFile[idx].name && attachedFile[idx].name.substring(0,attachedFile[idx].name.lastIndexOf('.'));
            valueForAttachment.push({AttachmentUrl:fileUploadResponse.relativePath,AttachmentName: fileUploadResponse.newFileName,newpath:fileUploadResponse.newpath,size:attachedFile[idx].size, orgFileName:attachedFile[idx].name});
            setAttachment([...valueForAttachment])
          }
          }
            return [...valueForAttachment]
      }
      else{
        let {AttachmentUrl,AttachmentName}=attachedFile
        let redux =store.getState()
        let transactionRootPath=redux.ownerResources.clientInfo.UI_TRANSACTION_ROOT_PATH
        let path;
        if (AttachmentUrl.endsWith('/')) {
         path=`${transactionRootPath}${AttachmentUrl}${AttachmentName}`;
        }
        else{
         path = `${transactionRootPath}${AttachmentUrl}`;
        }
        downloadFile(AttachmentName,path);
      }

    }catch(e){
      console.log("Error in commonEmailUploader",e);
    }
  }

  // commonRowActionMethod in DataTableAction
  export const commonRowMethod = async (dataListKey, rowData, SubApplnName, parentProps, option,modifiedState={}) => {
    let { tableUtils, componentProps, componentState, functions } = parentProps;
    if(Object.keys(modifiedState).length > 0){
        componentState=modifiedState;
    }
    let { rowAction = {}, ActionType = "" } = option
    let Action = rowAction;
    if (ActionType.toLowerCase() === "delete") {
       const key =  (rowData.hasOwnProperty("uid") && "uid") || (rowData.hasOwnProperty("ID") && "ID") || ("")
       //temporary fix:
       // deleteAndSave (boolean) - API delete | UI delete (delete the datalist)

        if (key === 'uid' || (key === 'ID' && Action.deleteAndSave === false)) {
            if(ActionType.toLowerCase() === "delete" && componentState.hasOwnProperty('IteratorIndex') && componentState.IteratorIndex != undefined){
                 
                 let iteratorIndex = componentState.IteratorIndex
                let datalistName =  componentState["ResponseKey"]
                let datalist = componentProps[datalistName]
                let list = datalist && datalist[iteratorIndex][dataListKey]
                let currentRowIndex = list.findIndex(row => row[key] === rowData[key]);
                list.splice(currentRowIndex, 1);
                // datalist[iteratorIndex] = {...datalist[iteratorIndex], ...list}
                componentProps.parentState.UpdateState(datalistName, datalist, true)
                return;
            }
            let list = componentState && componentState[dataListKey] && JSON.parse(JSON.stringify(componentState[dataListKey]))
            let currentRowIndex = list.findIndex(row => row[key] === rowData[key]);
            list.splice(currentRowIndex, 1);
            functions.UpdateState(dataListKey, list, true)
            return;
        }
    }
    let response = {}, request = {};
    if (Object.keys(Action).length) {
        request["OwnerId"] = "";
        if (Action.LoaderNeeded && Action.LoaderNeeded === "Y") {
            // store.dispatch({type:actionType.SPIN_LOADER})
            let { UpdateState } = functions;
            if (UpdateState) {
                UpdateState("loading", true, true)
            }
        }
        let reduxState = store.getState();
        let { metadataReducer: { productDetails } } = reduxState;
        request = { ...request, ServiceRequestDetail: getServiceRequestDetail() }

        if (Action.hasOwnProperty("EventMapping")) {
            Object.entries(Action.EventMapping).forEach(([key, value]) => {
                request[key] = value
            });
        }
        if (Action.GenerateJSONNeeded && Action.GenerateJSONNeeded === "Y") {
            let r = await getJSONRequestFromAPI(request["EventName"], productDetails, Action.URLVersion);
            request = { ...request, ...r }
        }
        if (Action.RequestConstruction && Action.RequestConstruction === "Y") {
            let rootlevelNeed = false;
            if (Action.IsRootLevelNeeded && Action.IsRootLevelNeeded === "Y") {
                rootlevelNeed = true;
                let r = await constructRequestValuesV2(componentProps, componentState.pagelevelValues, request, componentState, true, false, rootlevelNeed);
                request = { ...request, ...r }
            } else {
                let r = await constructRequestValues(componentProps, componentState.pagelevelValues, request, componentState, true, false);
                request = { ...request, ...r }
            }
        }
        if (Action.RequestMapping && Action.RequestMapping !== "") {
            let { RequestMapping } = Action;
            if(RequestMapping.hasOwnProperty('ConstructRowDataWithTranslation') && RequestMapping.ConstructRowDataWithTranslation != "" && RequestMapping.ConstructRowDataWithTranslation.toString().toLowerCase() === "true"){
                let valuesForRequest={...parentProps.rowData};
                let key = parentProps.subAppInfo.subapplicationEntity ? parentProps.subAppInfo.subapplicationEntity : parentProps.subAppInfo.subapplicationName;
                request={...request,[key]:valuesForRequest};
            }
            if (RequestMapping.hasOwnProperty("SessionState") && RequestMapping.SessionState.length > 0) {
                let { UIFlowReducer,metadataReducer } = reduxState
                request = await dynamicObjectConstruction(RequestMapping.SessionState, {...metadataReducer,...UIFlowReducer}, request, 'request');
            }
            if (RequestMapping.hasOwnProperty('localState') && RequestMapping.localState.length > 0) {
                request = await dynamicObjectConstruction(RequestMapping.localState, { ...componentProps, ...componentState, ...rowData }, request, 'request')
            }
            if (RequestMapping.hasOwnProperty("CustomProperties") && Object.keys(RequestMapping.CustomProperties).length > 0) {
                request = await dynamicObjectConstruction(RequestMapping.CustomProperties, {}, request, 'custom');
            }
        }
        // if(Action.OnStartMethod && Action.OnStartMethod!=="")
        // {
        //     if(componentProps && componentProps[Action.OnStartMethod])
        //         request = componentProps[Action.OnStartMethod](request,componentProps,e);
        // }
        if (Action.URL) {
            let APIURL = Action.URL && Action.URLVersion ? `${Action.URL}/${Action.URLVersion}` : Action.URL
            if (Action.URLVersion === "V3" || Action.URLVersion === "V4")
                response = await commonProductAxiosInstance.post(APIURL, request);
            else
                response = await AxiosInstance.post(APIURL, request);
            if (response) {
                response = response.data
                if (response.ResponseStatus && (response.ResponseStatus.toLowerCase() === 'failed' || response.ResponseStatus.toLowerCase() === 'error')) {
                    triggerToast(response.ResponseStatus)
                    console.log('Error:: ', response.ResponseStatus)
                    if(option.dragEvent===true)
                    functions && functions.UpdateState && functions.UpdateState(dataListKey,parentProps.dataList,Action.LoaderNeeded === "Y" ? false : true);
                    if (Action.LoaderNeeded && Action.LoaderNeeded === "Y") {
                        let { UpdateState } = functions;
                        if (UpdateState) {
                            UpdateState("loading", false, true)
                        }
                    }
                    return response
                }
            }
        }
        if (Action.successToastNotificationMessage && Action.successToastNotificationMessage !== "") {
            successToast(Action.successToastNotificationMessage);
        }
        // if(Action.OnExitMethod && Action.OnExitMethod!=="")
        // {
        //     if(propsObj && propsObj[Action.OnExitMethod])
        //         propsObj[Action.OnExitMethod](response,propsObj,stateObj,e);
        // }
        if (Action.ResponseTranslation && Object.keys(Action.ResponseTranslation).length > 0) {
            let { SessionState, ParentState } = Action.ResponseTranslation
            if (SessionState && Object.keys(SessionState).length > 0) {
                let temp = {};

                if (SessionState.hasOwnProperty("UIFlow") && SessionState.UIFlow.length > 0) {
                    temp = await dynamicObjectConstruction(SessionState.UIFlow, response, temp, 'response')
                    store.dispatch({ type: actionType.SET_UI_VALUES, payload: temp })
                }

            }
            if (ParentState && Object.keys(ParentState).length > 0) {
                let temp = {};
                temp = await dynamicObjectConstruction(ParentState, response, temp, 'response')
                if (functions) {
                    let { updateValues, updateStateValues } = functions
                    updateValues(temp)
                }

            }
        }
        if(Action.ResponseTranslation  && Action.ResponseTranslation.TranslateResponse){
            let responsefromEntity = await EntitySubAppTranslationV3({...response}, {...parentProps.componentState});
            if(responsefromEntity){
                let {UpdateState} = parentProps.functions ;
                    if(UpdateState){
                        if(Object.keys(responsefromEntity.translatedList).length > 0)
                        Object.keys(responsefromEntity.translatedList).forEach(datalist=>{
                            UpdateState(datalist,responsefromEntity.translatedList[datalist],false)
                        })
                        UpdateState("transactionPrimaryObject",{...parentProps.componentState.transactionPrimaryObject,...responsefromEntity.translatedResponse},false)
                        UpdateState("pagelevelValues",{...parentProps.componentState.pagelevelValues,...responsefromEntity.translatedResponse},Action.LoaderNeeded==="Y" ? false : true)
                    }
            }
        }
        if (Action.UpdateDataList && Action.UpdateDataList.dataListName) {
        setDataLists(Action.UpdateDataList.dataListName,response, functions.UpdateState)
        }
        if (Action.CustomUpdate && Action.CustomUpdate.SessionState) {
            let obj = await dynamicObjectConstruction(Action.CustomUpdate.SessionState, rowData, {}, "custom");
            store.dispatch({ type: actionType.SET_UI_VALUES, payload: obj })
        }
        if(Action.PreviewDocumentLink && Object.keys(Action.PreviewDocumentLink).length > 0){
            let { ResponseKey} = Action.PreviewDocumentLink
            
            let sourceData ={ ...response ,...rowData}

            let link = '';

            const getLink = (Key) =>{
                if(Key.includes('/')){
                    let valueObj = {...sourceData}
                    let resObjArr = Key.split('/')
                    for(const searchkey of resObjArr){
                        if(!(searchkey in valueObj)){
                            return '';
                        }
                        else if(typeof valueObj[searchkey]=== 'string'){
                            return valueObj[searchkey]
                        }
                        else if(typeof valueObj[searchkey]=== 'object'){
                            valueObj = valueObj[searchkey]
                        }
                    }
                }
                else{
                  return sourceData[Key]
                }
            }
            link = getLink(ResponseKey)
            let documentURL = link || '';
            window.open(documentURL, '_blank')
        }
        if (Action.RedirectNextPage && Action.RedirectNextPage === "Y") {
            if (Action.RedirectPageName && Action.RedirectPageName != "") {
                store.dispatch({ type: actionType.SET_PAGENAME, payload: Action.RedirectPageName });
            }
            else {
                let { UIFlowReducer: { currentPageIndex } } = reduxState;
                currentPageIndex += 1;
                store.dispatch({ type: actionType.SET_CURRENT_PAGEINDEX, payload: currentPageIndex });
            }
        }
        if (Action.RedirectTo && Action.RedirectTo !== "") {
            let redirectTo = `/${Action.RedirectTo}`;
            store.dispatch({ type: actionType.SET_PAGENAME, payload: '' });
            if (componentProps.hasOwnProperty('history') && componentProps.history.push) componentProps.history.push(redirectTo);
        }
        if (Action.LoaderNeeded && Action.LoaderNeeded === "Y") {
            // store.dispatch({type:actionType.LOCK_LOADER})
            let { UpdateState } = functions;
            if (UpdateState) {
                UpdateState("loading", false, true)
            }
        }
    }
}

function checkNested(obj , keyList,value) {
    for (let i = 0; i < keyList.length; i++) {
      if (!obj || !obj.hasOwnProperty(keyList[i])) {
        if(i===keyList.length - 1)
        {
            obj[keyList[i]]=value
        }else
        {
            obj[keyList[i]]={}
        }
      }
      obj = obj[keyList[i]];
    }
  }



  export const commonCheckBoxMethod = async(component, value, props, handlerNeeded = true)=>{
    try{
        let {Action}=component
        if (props.customTable && handlerNeeded)
        props.handleRowLevelInputChange && props.handleRowLevelInputChange(value, component, props.customTableRowIndex, props);
        else if(handlerNeeded) props.handleInputChangeV2 &&  props.handleInputChangeV2(value, component,'','',props)
        if(!(Object.keys(Action).length>0))
        return;
        else{
            let tpo={},request={}, response={};
            let reduxState=store.getState();
            request["OwnerId"]="";
            let {functions}=props
            if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
            {
                let {UpdateState} = functions;
                if(UpdateState){
                    UpdateState("loading",true,true)
                }
            }
            request={...request,ServiceRequestDetail:getServiceRequestDetail()}
            if (Action.hasOwnProperty("EventMapping")) {
            Object.entries(Action.EventMapping).forEach(([key, value]) => {
                request[key] = value
            });
            }
            if(Action.RequestMapping && Action.RequestMapping!==""){
                    let {RequestMapping}=Action;
                    let {UIFlowReducer}=reduxState
                    if(RequestMapping.hasOwnProperty("SessionState") && RequestMapping.SessionState.length>0){
                        request = await dynamicObjectConstruction(RequestMapping.SessionState, UIFlowReducer, request, 'request')
                    }
            }
            if(Action.RequestConstruction && Action.RequestConstruction==="Y"){
                let values={...props.componentState.pagelevelValues};
                if(!values[component.SubApplicationName])
                values[component.SubApplicationName]={};
                let keys = Object.keys(value)
                let attributeName = keys[0]
                values[component.SubApplicationName][attributeName] = value[attributeName].toString();
                let r =await constructRequestValues(props,{...values},request,props.componentState,true,false);
                request ={...request,...r}
            }
            if(Action.URL){
                let APIURL =  Action.URL && Action.URLVersion ? `${Action.URL}/${Action.URLVersion}` : Action.URL
                if(Action.URLVersion ==="V3" || Action.URLVersion ==="V4")
                    response = await commonProductAxiosInstance.post(APIURL,request);
                else
                    response = await AxiosInstance.post(APIURL,request);
                if(response) {
                    response=response.data;
                    if (response.ResponseStatus && (response.ResponseStatus.toLowerCase() === 'failed' || response.ResponseStatus.toLowerCase() === 'error')) {
                        triggerToast(response.ResponseStatus)
                        console.log('Error:: ', response.ResponseStatus)
                        if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
                        {
                            let {UpdateState} = props.functions ;
                            if(UpdateState){
                                UpdateState("loading",false,true)
                            }
                        }
                        return response
                    }
                }
            }
            if(Action.successToastNotificationMessage && Action.successToastNotificationMessage !== ""){
                successToast(Action.successToastNotificationMessage);
            }
            if(Action.ResponseTranslation && Object.keys(Action.ResponseTranslation).length > 0){
                if(Action.ResponseTranslation.TranslateResponse){
                    let responsefromEntity = await EntitySubAppTranslationV3({...response}, {...props.componentState});
                    if(responsefromEntity){
                        let {UpdateState} = props.functions ;
                            if(UpdateState){
                                UpdateState("transactionPrimaryObject",{...props.componentState.transactionPrimaryObject,...responsefromEntity.translatedResponse},false)
                                UpdateState("pagelevelValues",{...props.componentState.pagelevelValues,...responsefromEntity.translatedResponse},Action.LoaderNeeded==="Y" ? false : true)
                            }
                    }
                }
            }
            if(Action.SaveDataInSession==='Y'){
                updateValueToRedux(props,value[`${component.AttributeName}`])
            }
            if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
                        {
                            let {UpdateState} = props.functions ;
                            if(UpdateState){
                                UpdateState("loading",false,true)
                            }
                        }
        }
    }catch(e){
        console.log('Error in commonCheckBoxMethod',e);
    }
}

export const commonAutoCompleteMethod = async (component, value, props,searchQuery, handlerNeeded = true, functions) => {
    try {
        let { Action = {}} = component, parentUpdateObject = {}, request={}, response = {},reduxObject={},returnObj={},tpo={};
        let reduxState=store.getState();
        if(typeof(value)==="object"){
            request[component.AttributeName] = value[component.AttributeName];
        }
        request["OwnerId"] = sessionStorage.getItem("OwnerID");;
        request={ ...request, ServiceRequestDetail: getServiceRequestDetail(),...{ [component.LookupInstanceName] : searchQuery, [component.AttributeName]: searchQuery }  }
        let {axiosCancelToken}=props;
        if(Object.keys(Action).length > 0){
            if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
            {
                let {UpdateState} = props.functions;
                if(UpdateState){
                    UpdateState("loading",true,true)
                }
            }
            if(Action.hasOwnProperty("EventMapping")) {
                Object.entries(Action.EventMapping).forEach(([key, value]) => {
                    request[key] = value;
                });
            }
            if(Action.RequestConstruction && Action.RequestConstruction==="Y")
            {
                let componentState = props.componentState;
                let r =await constructRequestValues(props,componentState.pagelevelValues,request,componentState,true,false);
                request ={...request,...r}
            }
            if(Action.RequestMapping && Action.RequestMapping!=="") {
                let { RequestMapping }= Action;
                if(RequestMapping.hasOwnProperty('ConstructRowData') && RequestMapping.ConstructRowData != "" && RequestMapping.ConstructRowData.toString().toLowerCase() === "true") {
                    Object.entries(props.customTableRowIndex).forEach(([key, value]) => {
                        request[key] = value;
                    });
                    // to update the latest value selected
                    let keys = Object.keys(value);
                    let attributeName = keys[0];
                    request[attributeName] = value[attributeName].toString();
                }
                if(RequestMapping.hasOwnProperty("SessionState") && RequestMapping.SessionState.length>0)
                    {
                        let {UIFlowReducer}=reduxState
                        request = await dynamicObjectConstruction(RequestMapping.SessionState,UIFlowReducer, request, 'request')
                    }
                if(RequestMapping.hasOwnProperty('ApplicationState') && RequestMapping.ApplicationState.length > 0){
                        let {metadataReducer}=reduxState
                        request = await dynamicObjectConstruction(RequestMapping.ApplicationState, metadataReducer, request, 'request')
                    }
                if(RequestMapping.hasOwnProperty('localState') && RequestMapping.localState.length > 0) {
                        request = await dynamicObjectConstruction(RequestMapping.localState, {...props,...value}, request, 'request')
                    }
                //Temporary UserState
                if(RequestMapping.hasOwnProperty('UserState') && RequestMapping.UserState.length > 0){
                    let {userInfoReducer}=reduxState
                    request = await dynamicObjectConstruction(RequestMapping.UserState, {...userInfoReducer}, request, 'request')
                }
                 if(RequestMapping.hasOwnProperty("CustomProperties") && RequestMapping.CustomProperties.length>0){
                            request = await dynamicObjectConstruction(RequestMapping.CustomProperties, {...props}, request, 'custom')
                    }
            }
            if(Action.URL) {
                let APIURL =  Action.URL && Action.URLVersion ? `${Action.URL}/${Action.URLVersion}` : Action.URL
                let Request = {...component.Request,...request}
                if(Action.URLVersion ==="V3" || Action.URLVersion ==="V4")
                    response = await commonProductAxiosInstance.post(APIURL,Request,{
                        cancelToken: axiosCancelToken ? axiosCancelToken.token : ''
                    });
                else
                    response = await AxiosInstance.post(APIURL,request,{
                        cancelToken: axiosCancelToken ? axiosCancelToken.token : ''
                    });
                if(response) {
                    response = response.data
                    if (response.ResponseStatus && response.ResponseStatus.toLowerCase() === 'failed') {
                        triggerToast(response.ResponseStatus)
                        console.log('Error:: ', response.ResponseStatus)
                    }
                    else if(Action.successToastNotificationMessage && Action.successToastNotificationMessage !== "") {
                        successToast(Action.successToastNotificationMessage);
                    }
                    else{
                        // return response
                    }
                }
            }
            if(Action.ResponseTranslation){
                if(Action.ResponseTranslation && Action.ResponseTranslation.LocalState){
                    let obj = await dynamicObjectConstruction(Action.ResponseTranslation.LocalState, response, {}, "response");
                    returnObj=obj;
                }
            }
            if(Action.CustomUpdate && Action.CustomUpdate.ParentState){
                let obj = await dynamicObjectConstruction(Action.CustomUpdate.ParentState, {}, parentUpdateObject, "custom");
                props.functions.updateValues(obj)
            }
        }
        if(Action.ResponseTranslation && Object.keys(Action.ResponseTranslation).length > 0){
        let {SessionState=[],ParentState,ParentStateUpdateNeeded,ApplicationState} = Action.ResponseTranslation
        if(SessionState && SessionState.length > 0){
                let temp = await dynamicObjectConstruction( SessionState, response, {}, "response");
                reduxObject={...reduxObject,...temp}
        }
        if(ApplicationState && Object.keys(ApplicationState).length > 0)
            {
                let obj = await dynamicObjectConstruction(ApplicationState, response, {}, "response");
                await store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: obj })
            }
        if(ParentState && Object.keys(ParentState).length > 0){
            let temp={};
            temp = await dynamicObjectConstruction(ParentState, response, {}, "response");
            tpo={...tpo,...temp}
            if(ParentStateUpdateNeeded && ParentStateUpdateNeeded==='Y'){
                let {componentProps}=props;
                if(componentProps){
                    let {functions}=  componentProps
                    if(functions){
                        let {updateStateValues}=functions
                        updateStateValues(temp)
                        tpo={...tpo,...temp}
                    }
                }
            }
        else 
            props.functions.updateValues(temp)
        }
    }
    if(Action.CustomUpdate && Action.CustomUpdate.SessionState){
        let obj = await dynamicObjectConstruction(Action.CustomUpdate.SessionState, {}, {}, "custom");
        await store.dispatch({ type: actionType.SET_UI_VALUES, payload: obj })
    }
    if(Action.ResetPagelevelvalues && Action.ResetPagelevelvalues ==="Y" && Object.keys(props.componentState.transactionPrimaryObject).length > 0){
            let {updateStateValues}=props.functions
            let object = {}
            Object.keys(props.componentState).forEach(key=>{
                if(key.endsWith("_dataList"))
                object[key] = [];
          })
          let pageLeveValuesCopy={...props.componentState.pagelevelValues}
          object.pagelevelValues = {};
          object.transactionPrimaryObject= {};
          object.values = {};
          if(pageLeveValuesCopy[component.SubApplicationName] && pageLeveValuesCopy[component.SubApplicationName][component.AttributeName] && pageLeveValuesCopy[component.SubApplicationName][component.AttributeName]===searchQuery){
              object.pagelevelValues={[component.SubApplicationName]:{[component.AttributeName]:searchQuery}};
              object.transactionPrimaryObject={[component.SubApplicationName]:{[component.AttributeName]:searchQuery}};
              object.values={[component.AttributeName]:searchQuery};
          }
          let MetadataResponse= await constructAndInvokeMetadataCall({...props.componentState.pageSubAppInfo},tpo)
          object.pageMetadata=MetadataResponse
          await updateStateValues(object)
          props.refs && props.refs.current && props.refs.current.resetValidations()
          props.reference && props.reference && props.reference.resetValidations()
      }
        if(Action.ResponseTranslation  && Action.ResponseTranslation.TranslateResponse){
            let responsefromEntity = await EntitySubAppTranslationV3({...response}, {...props.componentState});
            if(responsefromEntity){
                let {UpdateState} = props.functions ;
                    if(UpdateState){
                        UpdateState("transactionPrimaryObject",{...props.componentState.transactionPrimaryObject,...responsefromEntity.translatedResponse},false)
                        UpdateState("pagelevelValues",{...props.componentState.pagelevelValues,...responsefromEntity.translatedResponse},Action.LoaderNeeded==="Y" ? false : true)
                    }
            }
        }
        if(Action.URLInfo && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
            let val=window.location.hash.split('/'),pageData={},redirectRoute='',obj={};
            if(val.length > 1 && val[2]){
                let newVal=val[2];
                pageData = decodeString(newVal)
            }
            obj = await dynamicObjectConstruction(Action.URLInfo, {...reduxState.metadataReducer,...reduxState.UIFlowReducer,...value}, obj, 'response');
            const encodedString=await constructEncodedString({...pageData,...obj});
            redirectRoute+=`${val[1]}/${encodedString}`;
            if(props.hasOwnProperty('history') && props.history.push) props.history.push(`/${redirectRoute}`);
            else if(props.componentProps.hasOwnProperty('history') && props.componentProps.history.push)  props.componentProps.history.push(`/${redirectRoute}`);
            else if(props.props && props.props.hasOwnProperty('history') && props.props.history.push) props.props.history.push(`/${redirectRoute}`);
        }
         if(props.customTable && Action.handlerNeeded)
             props.handleRowLevelInputChange && props.handleRowLevelInputChange(value, component, props.customTableRowIndex, props);
         else if (Action.handlerNeeded) props.handleAutoCompletechange && props.handleAutoCompletechange(value, props, component);
        if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
        {
            let {UpdateState} = props.functions;
            if(UpdateState){
                UpdateState("loading",false,true)
            }
        }
        store.dispatch({ type: actionType.SET_UI_VALUES, payload: reduxObject })
        return returnObj
    }
    catch(err){
        console.log("Error in common AutoComplete Method :: ", err)
    }

}


const dynamicConstructData=async function(Methodprops,value, props, state, component, transactionPrimaryObject){
    const reduxState = store.getState()
    if("ConstructData" in Methodprops){
        let { ConstructData } = Methodprops
            if("SessionState" in ConstructData && ConstructData.SessionState.length>0)
            {
                let {UIFlowReducer}=reduxState
                this.constructedData =await dynamicObjectConstruction(ConstructData.SessionState, UIFlowReducer, this.constructedData, 'custom')
            }
            if('localState' in ConstructData && ConstructData.localState.length > 0) {
                this.constructedData = await dynamicObjectConstruction(ConstructData.localState, {...props.componentProps, ...props.componentState}, this.constructedData, 'custom')
            }
            if('ApplicationState' in ConstructData && ConstructData.ApplicationState.length > 0){
                let {metadataReducer}=reduxState
                this.constructedData = await dynamicObjectConstruction(ConstructData.ApplicationState, metadataReducer, this.constructedData, 'custom')
            }
}
}


function constructDataWrapper(func){
    let Obj = {constructedData: {}}
    return async function(){
       await dynamicConstructData.call(Obj, ...arguments)
       await func.call(Obj, ...arguments)
    }
}


const dynamicUpdateStateFunc = function(Methodprops,value, props, state, component, transactionPrimaryObject){
    try{
      let {currentStateUpdate=true, immediateParentUpdate} = Methodprops
      let stateFunc =  props.functions.UpdateState
      let parentFunc = props.componentProps.parentState && props.componentProps.parentState.UpdateState
      Object.entries(this.constructedData).forEach(([key, value], i) => {
              if(currentStateUpdate){
                  stateFunc(key, value, false)
                }
                if(immediateParentUpdate){
                    parentFunc(key, value, (Object.keys(this.constructedData).length - 1) === i)
                }
        })
    }
    catch(e){
        console.error("Error in Dynamic UpdateState Method", e)
    }
}


export const commonChangeMethod = async(Actions,value, props, state, component, transactionPrimaryObject) => {
    let {UpdateState} = props && props.functions


    const {utilfunction} = props.pageInfo

    const UtilFunctions = {
        //temporary code: Util method used: cleanup later
        ...utilfunction,
        IneligibleRiskAddressCheck: InvokeAPICall_IneligibleRiskAddressCheckV1,
        dynamicUpdateState: constructDataWrapper(dynamicUpdateStateFunc)
    }

    const executeFunctions = async(functions, order) => {
        try{
            UpdateState("loading", true, true)
            for (const Methodprops of order) {
                const {actionName}  = Methodprops
                if (functions[actionName] && typeof functions[actionName] === 'function') {
                    await functions[actionName](Methodprops,value, props, state, component, transactionPrimaryObject);
                } else {
                    console.error(`Function ${actionName} not found in commonChangeMethod or not a function`);
                }
            }
        }
        catch(e){
            console.log("Error in commonChangeMethod executeFunctions", e)
        }
        finally {
            UpdateState("loading", false, true)
        }
    };
   await executeFunctions(UtilFunctions, Actions);
}

export const commonActionSelectMethod = async (Action,props,state,functions,AttributeName) =>{
    let  parentUpdateObject = {},request = {},response = {},tpo = {...state.transactionPrimaryObject};
    let reduxState = store.getState()
    let { updateValues,UpdateState } = functions
    
    if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
        {
            UpdateState("loading",true,true)
        }
    if(Action.hasOwnProperty("EventMapping"))
        {
            Object.entries(Action.EventMapping).forEach(([key, value]) => {
                request[key]=value
            });
        }
        if(Action.RequestConstruction && Action.RequestConstruction==="Y")
            {
                if(Action.IsRootLevelNeeded && Action.IsRootLevelNeeded==="Y" ){
                    let rootlevelNeeded = true
                    let r =await constructRequestValuesV2(props,state.pagelevelValues,request,state,true,false,rootlevelNeeded);
                    request ={...request,...r}
                }else{
                    let r =await constructRequestValues(props,state.pagelevelValues,request,state,true,false);
                    request ={...request,...r}
                }
            }
        if(Action.RequestMapping && Action.RequestMapping!=="")
        {
            let {RequestMapping}=Action;
            if(RequestMapping.hasOwnProperty("SessionState") && RequestMapping.SessionState.length>0)
            {
                let {UIFlowReducer}=reduxState
                request = await dynamicObjectConstruction(RequestMapping.SessionState,UIFlowReducer, request, 'request')
            }
            if(RequestMapping.hasOwnProperty('localState') && RequestMapping.localState.length > 0) {
                request = await dynamicObjectConstruction(RequestMapping.localState, {...props, ...state}, request, 'request')
            }
            if(RequestMapping.hasOwnProperty("CustomProperties") && Object.keys(RequestMapping.CustomProperties).length>0)
                request = await dynamicObjectConstruction(RequestMapping.CustomProperties, {}, request, 'custom')
    }
    if(Action.URL)
        {  
            response = await postAPI(props,Action,request);
            if(response) {
                if (response.ResponseStatus && response.ResponseStatus.toLowerCase() === 'failed') {
                    return response
                }
            }
        }
    
        if (Action.ResponseTranslation && Object.keys(Action.ResponseTranslation).length > 0) {
            let { SessionState, ParentState } = Action.ResponseTranslation
            if (SessionState && Object.keys(SessionState).length > 0) {
                if (SessionState.hasOwnProperty("UIFlow") && SessionState.UIFlow.length > 0) {
                    let temp={}
                    temp = await dynamicObjectConstruction(SessionState.UIFlow, response, temp, 'response')
                    tpo ={...tpo,...temp}
                    store.dispatch({ type: actionType.SET_UI_VALUES, payload: temp })
                }
            }
            if (ParentState && Object.keys(ParentState).length > 0) {
                let temp={}
                temp = await dynamicObjectConstruction(ParentState, response, temp, 'response')
                tpo ={...tpo,...temp}
                updateValues(temp)
            }
        }
    
    if(Action.CustomUpdate && Action.CustomUpdate.ApplicationState){
        let obj = await dynamicObjectConstruction(Action.CustomUpdate.ApplicationState, {}, {}, "custom");
        await store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: obj })
    }
    if(Action.CustomUpdate && Action.CustomUpdate.SessionState){
        let obj = await dynamicObjectConstruction(Action.CustomUpdate.SessionState, {}, {}, "custom");
        store.dispatch({ type: actionType.SET_UI_VALUES, payload: obj })
    }
    if(Action.CustomUpdate && Action.CustomUpdate.ParentState){
        let obj = await dynamicObjectConstruction(Action.CustomUpdate.ParentState, {}, parentUpdateObject, "custom");
        updateValues(obj)
    }
    if(Action.AjaxNeeded && Action.AjaxNeeded.toLowerCase()==='true'){
        tpo= {...{[AttributeName]:state[AttributeName]},...tpo}
        await UpdateMetadata(props,tpo,functions)
    }
    if(Action.LoaderNeeded && Action.LoaderNeeded==="Y")
        {
        UpdateState("loading",false,true)
        }
}