import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { commonEmailUploader } from './ButtonUtil';

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 600,
    backgroundColor: "rgb(47,53,58)",
    fontSize: "13px"
  },
}));

const ImportV2 =(props)=>{

    const [file, setFile] = useState({});
    const [Error, setError]= useState([]);
    const [attachment,setAttachment]=useState([]);
    const [drag, setDrag] = useState(false);
    const dropRef = useRef()
    let dragCounter;

    useEffect( () => {
        getAddEventListener()
        ValidatorForm.addValidationRule("IsEmpty", (value) => {
            if(!value) return false;
            return true;
        });

        ValidatorForm.addValidationRule("IsInvalidFile", (value) => {
            if (Error) {
              return false;
            }
            return true;
        });
        return () => {
          getRemoveEventListener()
        };
},[]);

   const getAddEventListener = () => {
    try {
      ["dragenter", "dragover", "dragleave", "mouseout", "drop"].forEach(
        (eventName) => {
          window.addEventListener(eventName, handleDragEvents);
        }
      );
      window.addEventListener("dragenter", handleDragIn);
      window.addEventListener("dragleave", handleDragOut);
      window.addEventListener("mouseout", handleMouseOut);
      window.addEventListener("drop", handleMouseOut);

      let div = dropRef.current;
      div.addEventListener("drop", handleDrop);
    } catch (err) {
      console.log("Error in getAddEventListener method", err.message);
    }
  }

  const getRemoveEventListener = () => {
    try {
      ["dragenter", "dragover", "dragleave", "mouseout", "drop"].forEach(
        (eventName) => {
          window.removeEventListener(eventName, handleDragEvents);
        }
      );
      window.removeEventListener("dragenter", handleDragIn);
      window.removeEventListener("dragleave", handleDragOut);
      window.removeEventListener("mouseout", handleMouseOut);
      window.removeEventListener("drop", handleMouseOut);

      let div = dropRef.current;
      div.removeEventListener("drop", handleDrop);
    } catch (err) {
      console.log("Error in getRemoveEventListener method", err.message);
    }
  }

  const handleDragEvents = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
    } catch (err) {
      console.log("Error in handleDragEvents method", err.message);
    }
  };

  const handleDragIn = (e) => {
    try {
      dragCounter++;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setDrag(true);
      }
    } catch (err) {
      console.log("Error in handleDragIn method", err.message);
    }
  };

  const handleDragOut = (e) => {
    try {

      dragCounter--;
      if (dragCounter === 0) {
        setDrag(false);
      }
    } catch (err) {
      console.log("Error in handleDragOut method", err.message);
    }
  };

  const handleMouseOut = (e) => {
    try {
      dragCounter = 0;
      if (dragCounter === 0) {
        setDrag(false);
      }
    } catch (err) {
      console.log("Error in handleMouseOut method", err.message);
    }
  };

  const handleDrop = (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      console.log(props,"handledrop")
      dragCounter = 0;
      if (dragCounter === 0) {
        setDrag(false);
      }
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        let data = { ...e.dataTransfer.files };
        let event = {
          target: {
            files: data,
          },
        };
        handleAttachmentImport(event);
        e.dataTransfer.clearData();
      }
    } catch (err) {
      console.log("Error in handleDrop method", err.message);
    }
  };


    const onChange = (e) => {
        if (props.customTable) {
            let value = Object.assign({}, { [e.target.name]: e.target.value });
            props.handleRowLevelInputChange(value, props.component, props.customTableRowIndex, props);
        }
        else if(props.component.OnChangeAction!=='' && props[props.component.OnChangeAction]) {
            let value = Object.assign({}, { [e.target.name]: e.target.value });
            props[props.component.OnChangeAction](value, props.component, props.isDynamicTable, props.datatableRowindex);
        }
        else {
            let value = Object.assign({}, { [e.target.name]: e.target.value });
            props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex);
            props.handleInputChangeV2(Object.assign({}, { [e.target.attachmentname]: e.target.attachmentvalue }), props.component, props.isDynamicTable, props.datatableRowindex);
        }
    }

    const handleAttachmentImport = async(event)=>{
        let attachedFile=event.target.files;
        component.MaxValue="20971520"
        if(attachedFile.length<=0){
          return;
        }
        else {
          let doc = await commonEmailUploader(attachedFile,setAttachment,[],props,true,component,setError,Error)
          doc.length>0 &&   setFile(doc[0])
          let e = {
            target : {
                name : component.AttributeName,
                value:doc[0] && doc[0].AttachmentName,
                attachmentname : component.Action.AttachmentURL,
                attachmentvalue: doc[0] && doc[0].newpath
            }
          }
          onChange(e);
         }
 }

    const { component } = props;
        let componentvalue = "";
        if(props.componentvalue === undefined)
            componentvalue = "";
        else
            componentvalue = props.componentvalue

        let value = file === undefined ? "" : file.AttachmentName
        let isRequired = component.IsRequired === "Y" ? true : false;
        let componentLabel = "" ;
        if(component.ComponentLabel){
            componentLabel = isRequired ? component.ComponentLabel + " *" : component.ComponentLabel;
        }
        const validations = [];
        const errorMessages = [];
        if (isRequired) {
            validations.push("required");
            errorMessages.push(component.RequiredMessage);
            validations.push("IsEmpty");
            errorMessages.push(component.RequiredMessage);
        }
        if(Error.length>0){
            validations.push("IsInvalidFile");
            errorMessages.push(component.ValidatorMessage);
        }
        let autocomplete="off";
        let custom3=component.Custom3 && JSON.parse(component.Custom3);
        (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
        const classes = useStyles();
    return (
        <Grid item xs={12} sm={12} md={component.Size} lg={component.Size} ref={dropRef} className={`grid-drag__container ${component.StyleClass}`}>
                <Tooltip title={componentvalue} classes={{ tooltip: classes.customWidth }} arrow >
                <TextValidator
                    autoFocus
                    // multiline
                    className="custom-input"
                    margin="normal"
                    autoComplete={autocomplete}
                    label={
                        <Typography className="custom-label-down text-font" style={styles.label}>
                            {componentLabel}
                        </Typography>
                    }
                    name={component.AttributeName}
                    placeholder={componentLabel.replace(/&nbsp;/g, '')}
                    value={componentvalue}
                    onChange = {(e) => onChange(e)}
                    disabled={true}
                    validators={validations}
                    errorMessages={errorMessages}
                    fullWidth
                    InputProps={{
                      inputProps: {
                          style: {
                              textOverflow: 'ellipsis',
                              cursor: "pointer"
                          }
                      }
                  }}
                />
                </Tooltip>
                <br/>
                {drag && (
                  <div className="drag__content">
                    <div className="drag__text">
                      <div>
                        <Publish style={{ fontSize: "2rem" }} />
                        <span> Drag & Drop your file here to upload</span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="upload upload-container theme_button">
                    <div className={`upload-button theme_button theme_buttonColor${component.IsReadonly?" disableButtonColor":""}`}>
                        <span className="upload-button-text theme_button">
                            <Publish/><span>Choose File</span>
                        </span>
                        <input
                            id="contained-button-file"
                            type="file"
                            onChange={handleAttachmentImport}
                            className="fileInputOverflow upload-button-input"
                            disabled={component.IsReadonly}
                            autoComplete={autocomplete}
                        />
                    </div>
                   <span className={`upload-filename ${component.AttributeStyleClass}`}>{value}</span>
                   <span className="Drag-message">Drag & Drop your file here to upload</span>
                </div>
            </Grid>
    )
}

const styles = {
    label: {
      width: '150%'
    }
};

export default ImportV2
