import React, {useEffect, useState} from 'react'
import NumberFormat from "react-number-format";
function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    // const [value,setValue] = useState(props.value);

    // useEffect(() => {
    //     setValue(props.value)
    // },[props.value])

    const handleValueChange = (values,e) => {
      onChange({
        target:{
          name: props.name,
          value: values.value
        }
      })
      // setValue(values.value)
    }
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        value={props.value}
        onValueChange={values => {
          handleValueChange(values);
        }}
        thousandSeparator
        // isNumericString
      />
    );
  }
export default   NumberFormatCustom;