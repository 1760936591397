import {  constructAndInvokeMetadataCall } from "./MetaUtils";
import { EntitySubAppTranslation, getEntitySubAppMapping,EntitySubAppTranslationV2,getEntitySubAppMappingV2 } from '../requestConstruction/ResponseTranslation';
import { subAppListConstruction } from './DetailSummarySubAppList'
import { AxiosInstance, LocationandBuildingAxios, getJSONRequestFromAPI,getServiceRequestDetail } from './API';
import store from '../../../../store';
import appActions from "../../../../actions/index";
import { v4 as uuidv4 } from 'uuid';
import { constructTransactionRequest,constructTransactionRequestV1 } from '../requestConstruction/DynamicReqConstruction';
import { format } from "date-fns";
import { handleInputChangeV2 } from "./InputUtils";
import { Toast, failureToast, triggerToast } from "./HelperUtils";
import { dynamicObjectConstruction } from "./NoCodeUtils";
const Axios = require("axios");
const { actionType } = appActions


const getRequestConstruction = (request, props) => {
    const redux = store.getState();
    // console.log("UtilFile called! [props] --> ", props);

    if (props.pageInfo.parentHierarchy) {
        let listName = props.pageInfo.parentHierarchy + "IdList"
        if (redux.metadataReducer[listName]) {
            if (redux.metadataReducer[listName].includes(props.rowData.ID)) {
                request["PARENTID"] = props.rowData.ID
            }
        }
        request["EntityName"] = props.pageInfo.entityName
        request["ROOTID"] = redux.metadataReducer.submissionResponse.SubmissionID
        request["EventName"] = props.pageInfo.eventName
        request["TargetEventName"] = props.pageInfo.getTargetEvent
    }
    else {
        if (props.pageInfo.entityName === "CommercialPropertyLocation") {
            getStateList(props)
            request["EventName"] = props.pageInfo.geteventName
            if (redux.metadataReducer.submissionResponse) {
                request["ID"] = redux.metadataReducer.submissionResponse.SubmissionID
            }
        }
    }
}

const getRequestConstruction_GL = (request, props) => {
    const redux = store.getState();
    if (props.pageInfo.parentHierarchy) {
        let listName = props.pageInfo.parentHierarchy + "IdList"
        if (redux.metadataReducer[listName]) {
            if (redux.metadataReducer[listName].includes(props.rowData.ID)) {
                request["PARENTID"] = props.rowData.ID
                request["ID"] = props.rowData.ID
            }
        }
        request["EntityName"] = props.pageInfo.entityName
        request["ROOTID"] = redux.metadataReducer.submissionResponse.SubmissionID
        request["EventName"] = props.pageInfo.eventName
        request["TargetEventName"] = props.pageInfo.getTargetEvent
    }
    else {
        getStateList(props)
        request["EntityName"] = props.pageInfo.entityName
        request['ID'] = redux.metadataReducer.submissionResponse.SubmissionID
        //request['ROOTID'] = redux.metadataReducer.submissionResponse.SubmissionID
        //request['PARENTID'] = redux.metadataReducer.submissionResponse.SubmissionID
        request["EventName"] = props.pageInfo.geteventName
        //request["TargetEventName"] =props.pageInfo.getTargetEvent
    }

}

const getRequestConstruction_HomeOwners = (request, props) => {
    const redux = store.getState();
    console.log("navigationreducer in risk utils--->",redux,props)
    if (props.pageInfo.parentHierarchy) {
        let listName = props.pageInfo.parentHierarchy + "IdList"
        if (redux.metadataReducer[listName]) {
            if (redux.metadataReducer[listName].includes(props.rowData.ID)) {
                request["PARENTID"] = props.rowData.ID
                request["ID"] = props.rowData.ID
            }
        }
        request["EntityName"] = props.pageInfo.entityName
        request["ROOTID"] = redux.metadataReducer.submissionResponse.SubmissionID
        request["EventName"] = props.pageInfo.eventName
        request["TargetEventName"] = props.pageInfo.getTargetEvent
    }
    else {
        //getStateList(props)
        request["EntityName"] = props.pageInfo.entityName
        request["EventName"] = props.pageInfo.eventName
        request["TargetEventName"] = props.pageInfo.getEventName
        request["ID"] = redux.UIFlowReducer.submissionID
        request["PARENTID"] = redux.UIFlowReducer.submissionID
        request["ROOTID"] = redux.UIFlowReducer.submissionID
    }

}

const getRequestConstruction_CLV1 = (request, props) => {
    try {
        const redux = store.getState();
        if (props.pageInfo.parentHierarchy) {
            request["PARENTID"] = props.rowData.ID
            request["ID"] = props.rowData.ID
            request["EntityName"] = props.pageInfo.entityName
            request["ROOTID"] = redux.metadataReducer.submissionResponse.SubmissionID
            request["EventName"] = props.pageInfo.eventName
            request["TargetEventName"] = props.pageInfo.getTargetEvent
        }
        else {
            request["EntityName"] = props.pageInfo.entityName
            request["EventName"] = props.pageInfo.eventName
            request["TargetEventName"] = props.pageInfo.getEventName
            request["ID"] = redux.UIFlowReducer.insuredID
            request["PARENTID"] = redux.UIFlowReducer.insuredID
            request["ROOTID"] = redux.UIFlowReducer.insuredID
        }
    }
    catch (err) {
        console.log("Error in getRequestConstruction_CLV1", err)
    }

}

const getRequestConstruction_HomeOwners_RateIndication = (request, props) => {
    const redux = store.getState();
    console.log("navigationreducer in risk utils--->",redux,props)
    if (props.pageInfo.parentHierarchy) {
        let listName = props.pageInfo.parentHierarchy + "IdList"
        if (redux.metadataReducer[listName]) {
            if (redux.metadataReducer[listName].includes(props.rowData.ID)) {
                request["PARENTID"] = props.rowData.ID
                request["ID"] = props.rowData.ID
            }
        }
        request["EntityName"] = props.pageInfo.entityName
        request["ROOTID"] = redux.metadataReducer.submissionResponse.SubmissionID
        request["EventName"] = props.pageInfo.eventName
        request["TargetEventName"] = props.pageInfo.getTargetEvent
    }
    else {
        //getStateList(props)
        request["EntityName"] = props.pageInfo.entityName
        request["EventName"] = props.pageInfo.eventName
        request["TargetEventName"] = props.pageInfo.getEventName
        request["ID"] = redux.UIFlowReducer.submissionID
        request["PARENTID"] = redux.UIFlowReducer.submissionID
        request["ROOTID"] = redux.UIFlowReducer.submissionID
    }

}

const getRequestConstruction_CL = (request, props) => {
    const redux = store.getState();
    console.log("navigationreducer in risk utils--->",redux,props)
    if (props.pageInfo.parentHierarchy) {
        let listName = props.pageInfo.parentHierarchy + "IdList"
        if (redux.metadataReducer[listName]) {
            if (redux.metadataReducer[listName].includes(props.rowData.ID)) {
                request["PARENTID"] = props.rowData.ID
                request["ID"] = props.rowData.ID
            }
        }
        request["EntityName"] = props.pageInfo.entityName
        request["ROOTID"] = redux.metadataReducer.submissionResponse.SubmissionID
        request["EventName"] = props.pageInfo.eventName
        request["TargetEventName"] = props.pageInfo.getTargetEvent
    }
    else {
        //getStateList(props)
        request["EntityName"] = props.pageInfo.entityName
        request["EventName"] = props.pageInfo.eventName
        request["TargetEventName"] = props.pageInfo.getEventName
        request["ID"] = redux.UIFlowReducer.insuredID
        request["PARENTID"] = redux.UIFlowReducer.insuredID
        request["ROOTID"] = redux.UIFlowReducer.insuredID
    }

}

const getStateList = (props) => {
    props.showLoader()
    const redux = store.getState();
    let request = {};
    request.EventName = props.pageInfo.getListEventName;
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["ServiceRequestDetail"] = Object.assign({},getServiceRequestDetail());
    request.ID = redux.UIFlowReducer.submissionID;
    LocationandBuildingAxios.post("/core/invokeWorkflowEvent", request)
        .then((response) => {
            let responseArr = response.data.StateList
            store.dispatch({type: actionType.SET_STATELIST, payload: responseArr})
            if(response.data.PrimaryState){
                store.dispatch({ type:actionType.SET_PRIMARY_STATE, payload: response.data.PrimaryState })
            }
            props.hideLoader()
        })
        .catch((err) => {
            props.hideLoader()
            triggerToast('')
            console.log(err);
        });
};

//For Multi Subline
const setStateList_GL = (updateState) => {
    const redux = store.getState();
    let stateList = redux.metadataReducer.stateList
    let stateListVal = []
    if (stateList.length > 0) {
        for (let i = 0; i < stateList.length; i++) {
            if (!stateListVal.includes(stateList[i].State)) {
                stateListVal.push(stateList[i].State+"-"+stateList[i].Subline)
            }
        }
    }
    updateState("stateListVal", stateListVal, false);
}

export const setStateList_CF = (updateState) => {
    const redux = store.getState();
    let stateList = redux.metadataReducer.stateList
    let stateListVal=[]
    if (stateList.length > 0) {
        for (let i = 0; i < stateList.length; i++) {
            if (!stateListVal.includes(stateList[i].State)) {
                stateListVal.push(stateList[i].State)
            }
        }
    }
    updateState("stateListVal", stateListVal, false);
}

const setParentHierarchyId = async (responseEntity, props , idArray = []) => {
    let conditions;
    for (let i = 0; i < responseEntity.length; i++) {
        const redux = store.getState();
        let conditionKey = props.pageInfo.entityName + responseEntity[i].ID
        conditions = Object.assign({}, redux.metadataReducer.showHideCondition, {[conditionKey]:true})
        // conditions = { ...props.metadataReducer.showHideCondition , [conditionKey] : true}
        await props.setShowHideCondition(conditions)
        idArray.push(responseEntity[i].ID)
        let action;
        switch (props.pageInfo.entityName){
            case 'CommercialPropertyLocation':
                action = actionType.SET_LOCATION_ID_LIST
                break;
            case 'CommercialPropertyStructure':
                action = actionType.SET_BUILDING_ID_LIST
                break;
            case 'CommercialPropertyOccupClass':
                action = actionType.SET_OCCUPANCY_ID_LIST
                break;
            default:
                action = null
        }
        if(action){
            store.dispatch({type: action, payload: idArray});
        }
    }

}

const setHierarchyIds = async (responseEntity, request, getLocationList, callback, key, props,state,updateState) => {
    let idArray = []
    let action;
    for (let i = 0; i < responseEntity.length; i++) {
        const redux = store.getState();
        let conditionKey = props.pageInfo.entityName + responseEntity[i].ID
        console.log("request in setHierarchyIds RiskscheduleUtils--->",request)
        console.log("conditionKey in RiskscheduleUtils--->",redux.metadataReducer.showHideCondition,conditionKey)
        let conditions = Object.assign({}, redux.metadataReducer.showHideCondition, {[conditionKey]:true})
        console.log("conditions in RiskscheduleUtils--->",conditions)
        await props.setShowHideCondition(conditions)
        idArray.push(responseEntity[i].ID)
        console.log("request[EntityName] in setHierarchyIds RiskscheduleUtils--->",request["EntityName"])
        switch (request["EntityName"]){
            case 'Location':
                action = actionType.SET_LOCATION_ID_LIST
                break;
            case 'CommercialPropertyLocation':
                action = actionType.SET_LOCATION_ID_LIST
                break;
            case 'CommercialPropertyStructure':
                action = actionType.SET_BUILDING_ID_LIST
                break;
            case 'CommercialPropertyOccupClass':
                action = actionType.SET_OCCUPANCY_ID_LIST
                break;
            default:
                action = null
        }
    }
    if(action){
        console.log('action in riskschedule utils--->',action,idArray)
        store.dispatch({type: action, payload: idArray});
        if(request['EntityName'] === "Location"){
            getLocationList(callback, key,state,props,updateState)
        }
    }
}

const getLocationList = async(callback, key,state,props,updateState) => {
    let request = Object.create({});
    let reduxState=store.getState();
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["EventName"] = props.pageInfo.eventName
    request["TargetEventName"] = props.pageInfo.getEventName
    request["ID"] = props.UIFlowReducer.insuredID
    request["PARENTID"] = props.UIFlowReducer.insuredID
    request["ROOTID"] = props.UIFlowReducer.insuredID
    if(props.pageInfo.GetLocationListReq){
       const Action = props.pageInfo.GetLocationListReq
       const eventNames = Action.eventNames || {};
       request = {...request, ...eventNames}
       if(Action.RequestMapping && Action.RequestMapping!=="")
        {
            let {RequestMapping}=Action;
            if(RequestMapping.hasOwnProperty("SessionState") && RequestMapping.SessionState.length>0)
            {
                let {UIFlowReducer,metadataReducer}=reduxState
                request = await dynamicObjectConstruction(RequestMapping.SessionState, { ...metadataReducer,...UIFlowReducer }, request, 'request');
            }
        }
    }
    console.log("getlocation list request--->",request)
    await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async(response) => {
            if(response.data.Location.length>0){
                await store.dispatch({
                    type: actionType.SET_HASLOCATION,
                    payload: false
                });
            }
            if(response.data.hasOwnProperty("IsUnderWritingDetailsAvailable") && response.data.IsUnderWritingDetailsAvailable==="No"){
                await store.dispatch({
                    type: actionType.SET_HASUNDERWRITINGDETAILS,
                    payload: true
                });
            }else if(response.data.hasOwnProperty("IsUnderWritingDetailsAvailable") && response.data.IsUnderWritingDetailsAvailable==="Yes"){
                await store.dispatch({
                    type: actionType.SET_HASUNDERWRITINGDETAILS,
                    payload: false
                });
            }
            updateState("values", { ...state.values, ...response.data[props.pageInfo.entityName] }, true)
             callback(response.data[props.pageInfo.entityName], key, state,props,updateState)
        })
}

const getLocationList_CL = async(callback, key,state,props,updateState) => {
    let request = Object.create({});
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["EventName"] = props.pageInfo.eventName
    request["TargetEventName"] = props.pageInfo.getEventName
    request["ID"] = props.UIFlowReducer.insuredID
    request["PARENTID"] = props.UIFlowReducer.insuredID
    request["ROOTID"] = props.UIFlowReducer.insuredID
    console.log("getlocation list request--->",request)

    await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async(response) => {
            if(response.data.Location.length>0){
                await store.dispatch({
                    type: actionType.SET_HASLOCATION,
                    payload: false
                });
            }
            console.log("getlocation list response--->",response)
             updateState("values", { ...state.values, ...response.data[props.pageInfo.entityName] }, true)
             callback(response.data[props.pageInfo.entityName], key, state,props,updateState)
        })
}

const getLocationList_RateIndication = async(callback, key,state,props,updateState) => {
    let request = Object.create({});
    let reduxState=store.getState();
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["EventName"] = props.pageInfo.eventName
    request["TargetEventName"] = props.pageInfo.getEventName
    request["ID"] = props.UIFlowReducer.insuredID
    request["PARENTID"] = props.UIFlowReducer.insuredID
    request["ROOTID"] = props.UIFlowReducer.insuredID
    if(props.pageInfo.GetLocationListReq){
        const Action = props.pageInfo.GetLocationListReq
        const eventNames = Action.eventNames || {};
        request = {...request, ...eventNames}
        if(Action.RequestMapping && Action.RequestMapping!=="")
         {
             let {RequestMapping}=Action;
             if(RequestMapping.hasOwnProperty("SessionState") && RequestMapping.SessionState.length>0)
             {
                 let {UIFlowReducer,metadataReducer}=reduxState
                 request = await dynamicObjectConstruction(RequestMapping.SessionState, { ...metadataReducer,...UIFlowReducer }, request, 'request');
             }
         }
     }
    console.log("getlocation list request--->",request)
    await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async(response) => {
            if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                triggerToast("Unable to get location list");
                console.log("Response Failed");
               return;
              }
            if(response.data.Location.length>0){
                await store.dispatch({
                    type: actionType.SET_HASLOCATION,
                    payload: false
                });
            }
            await store.dispatch({
                type: actionType.SET_HASUNDERWRITINGDETAILS,
                payload: false
            });
             updateState("values", { ...state.values, ...response.data[props.pageInfo.entityName] }, true)
             callback(response.data[props.pageInfo.entityName], key, state,props,updateState)
        })
}

const setParentHierarchyId_GL = async (responseEntity, props , idArray = []) => {

    let conditions
    for (let i = 0; i < responseEntity.length; i++) {
        const redux = store.getState();
        let conditionKey = props.pageInfo.entityName + responseEntity[i].ID
        conditions = Object.assign({}, redux.metadataReducer.showHideCondition, {[conditionKey]:true})
        // conditions = { ...props.metadataReducer.showHideCondition , [conditionKey] : true}
        await props.setShowHideCondition(conditions)
        idArray.push(responseEntity[i].ID)
        let action;
        switch (props.pageInfo.entityName){
            case 'GeneralLiabilityLocation':
                action = actionType.SET_LOCATION_ID_LIST
                break;
            case 'GeneralLiabilityClassification':
                action = actionType.SET_BUILDING_ID_LIST
                break;
            default:
                action = null
        }
        if(action){
            store.dispatch({type: action, payload: idArray});
        }
    }

}

const setHierarchyIds_GL = async (responseEntity, request, getLocationList, callback, key, props) => {
    let idArray = []
    let action;
    for (let i = 0; i < responseEntity.length; i++) {
        const redux = store.getState();
        let conditionKey = props.pageInfo.entityName + responseEntity[i].ID
        let conditions = Object.assign({}, redux.metadataReducer.showHideCondition, {[conditionKey]:true})
        await props.setShowHideCondition(conditions)
        idArray.push(responseEntity[i].ID)
        switch (request["EntityName"]){
            case 'GeneralLiabilityLocation':
                action = actionType.SET_LOCATION_ID_LIST
                break;
            case 'GeneralLiabilityClassification':
                action = actionType.SET_BUILDING_ID_LIST
                break;
            default:
                action = null
        }
    }
    if(action){
        store.dispatch({type: action, payload: idArray});
        if(request['EntityName'] === "GeneralLiabilityLocation"){
            getLocationList(callback, key)
        }
    }
}

const setParentHierarchyId_HomeOwners = async (responseEntity,props,idArray) => {
    let action;
    let conditionObj={};
    const redux = store.getState();
    console.log()
    for (let i = 0; i < responseEntity.length; i++) {
        let conditionKey = props.pageInfo.entityName + responseEntity[i].ID
        conditionObj[conditionKey]=true
    }
        let conditions = Object.assign({}, redux.metadataReducer.showHideCondition, conditionObj)
        await props.setShowHideCondition(conditions)
}

const setParentHierarchyId_CL = async (responseEntity,props,idArray) => {
    let action;
    let conditionObj={};
    const redux = store.getState();
    console.log()
    for (let i = 0; i < responseEntity.length; i++) {
        let conditionKey = props.pageInfo.entityName + responseEntity[i].ID
        conditionObj[conditionKey]=true
    }
        let conditions = Object.assign({}, redux.metadataReducer.showHideCondition, conditionObj)
        await props.setShowHideCondition(conditions)
}

const setHierarchyIds_HomeOwners = async (responseEntity, request, getLocationList, callback, key, props,state,updateState) => {
    let idArray = []
    let action;
    let conditionObj={};
    const redux = store.getState();
    for (let i = 0; i < responseEntity.length; i++) {
        let conditionKey = props.pageInfo.entityName + responseEntity[i].ID
        conditionObj[conditionKey]=true
        idArray.push(responseEntity[i].ID)
    }
        let conditions = Object.assign({}, redux.metadataReducer.showHideCondition, conditionObj)
        await props.setShowHideCondition(conditions)
        switch (request["EntityName"]){
            case 'Location':
                action = actionType.SET_LOCATION_ID_LIST
                break;
            default:
                action = null
        }

    if(action){
        if(request['EntityName'] === "Location"){
            await getLocationList(callback, key,state,props,updateState)
        //commented these line of codes as we are using location wrapper as expandable component

        //     let {objectId,objectVerId,subApplication}=props.pageInfo
        //    let subappName = subApplication.locationSummary
        //     let {transactionPrimaryObject} = props
        //     let metadataResponse=await constructAndInvokeMetadataCall({objectId,objectVerId,subappName},{transactionPrimaryObject})
        //     updateState("pageMetadata",{"ApplicationDetail":metadataResponse.ApplicationDetail},true)
        //     callback({"ApplicationDetail":metadataResponse.ApplicationDetail},"pageMetadata",state)

        //commented these line of codes as we are using location wrapper as expandable component
        }
    }
}


const setHierarchyIds_HomeOwners_RateIndication = async (responseEntity, request, getLocationList_RateIndication, callback, key, props,state,updateState) => {
    let idArray = []
    let action;
    let conditionObj={};
    const redux = store.getState();
    for (let i = 0; i < responseEntity.length; i++) {
        let conditionKey = props.pageInfo.entityName + responseEntity[i].ID
        conditionObj[conditionKey]=true
        idArray.push(responseEntity[i].ID)
    }
        let conditions = Object.assign({}, redux.metadataReducer.showHideCondition, conditionObj)
        await props.setShowHideCondition(conditions)
        switch (request["EntityName"]){
            case 'Location':
                action = actionType.SET_LOCATION_ID_LIST
                break;
            default:
                action = null
        }

    if(action){
        if(request['EntityName'] === "Location"){
            await getLocationList_RateIndication(callback, key,state,props,updateState)
        //commented these line of codes as we are using location wrapper as expandable component

        //     let {objectId,objectVerId,subApplication}=props.pageInfo
        //    let subappName = subApplication.locationSummary
        //     let {transactionPrimaryObject} = props
        //     let metadataResponse=await constructAndInvokeMetadataCall({objectId,objectVerId,subappName},{transactionPrimaryObject})
        //     updateState("pageMetadata",{"ApplicationDetail":metadataResponse.ApplicationDetail},true)
        //     callback({"ApplicationDetail":metadataResponse.ApplicationDetail},"pageMetadata",state)

        //commented these line of codes as we are using location wrapper as expandable component
        }
    }
}

const setHierarchyIds_CL = async (responseEntity, request, getLocationList_CL, callback, key, props,state,updateState) => {
    let idArray = []
    let action;
    let conditionObj={};
    const redux = store.getState();
    for (let i = 0; i < responseEntity.length; i++) {
        let conditionKey = props.pageInfo.entityName + responseEntity[i].ID
        conditionObj[conditionKey]=true
        idArray.push(responseEntity[i].ID)
    }
        let conditions = Object.assign({}, redux.metadataReducer.showHideCondition, conditionObj)
        await props.setShowHideCondition(conditions)
        switch (request["EntityName"]){
            case 'Location':
                action = actionType.SET_LOCATION_ID_LIST
                break;
            case 'Building':
                action = actionType.SET_BUILDING_ID_LIST
                break;
            default:
                action = null
        }

    if(action){
        if(request['EntityName'] === "Location"){
            await getLocationList_CL(callback, key,state,props,updateState)
        //commented these line of codes as we are using location wrapper as expandable component

        //     let {objectId,objectVerId,subApplication}=props.pageInfo
        //    let subappName = subApplication.locationSummary
        //     let {transactionPrimaryObject} = props
        //     let metadataResponse=await constructAndInvokeMetadataCall({objectId,objectVerId,subappName},{transactionPrimaryObject})
        //     updateState("pageMetadata",{"ApplicationDetail":metadataResponse.ApplicationDetail},true)
        //     callback({"ApplicationDetail":metadataResponse.ApplicationDetail},"pageMetadata",state)

        //commented these line of codes as we are using location wrapper as expandable component
        }
    }
}

const setStateID = (stateList, props, request) =>{
    if (stateList.length > 0) {
        for (let i = 0; i < stateList.length; i++) {
            if (request[props.pageInfo.entityName]) {
                if (stateList[i].State === request[props.pageInfo.entityName]["State"]) {
                    request["PARENTID"] = stateList[i].ID
                    request["ID"] = stateList[i].ID
                }
                if (stateList[i].State === request[props.pageInfo.entityName]["LocationState"]) {
                    request["PARENTID"] = stateList[i].ID
                    request["ID"] = stateList[i].ID
                }
            }
        }
    }
}

//For Multi Subline
const setStateID_GL = (stateList, props, request) =>{
    if (stateList.length > 0) {
        for (let i = 0; i < stateList.length; i++) {
            if (request[props.pageInfo.entityName]) {
                if (stateList[i].State === request[props.pageInfo.entityName]["LocationState"]) {
                    let Subline = request[props.pageInfo.entityName]["SublineState"].split('-')
                    if(stateList[i].Subline === Subline[1]){
                        request["PARENTID"] = stateList[i].ID
                        request["ID"] = stateList[i].ID
                    }
                }
            }
        }
    }
}

const onAddRow_CF = (props, updateArrayValueOnState, key) => {
    if (props.metadataReducer.iconcondition && props.pageInfo.parentHierarchy) {
        if (props.metadataReducer.iconcondition.includes(props.pageInfo.parentHierarchy)) {
            let uid = uuidv4();
            let conditionKey = props.pageInfo.entityName + uid
            // console.log(ID)
            let conditions = Object.assign({}, props.metadataReducer.showHideCondition, {[conditionKey]:false})

            props.setShowHideCondition(conditions)
            updateArrayValueOnState(key, { uid, isExpanded: true }, true);
        }
        else {
            triggerToast('Please save ' + props.pageInfo.parentHierarchy + ' before adding ' + props.pageInfo["subApplication"]["locationSummary"], { position: Toast.POSITION.TOP_CENTER })
        }
    }
    else {
        if (props.pageInfo.parentHierarchy) {
            triggerToast('Please save ' + props.pageInfo.parentHierarchy + ' before adding ' + props.pageInfo["subApplication"]["locationSummary"], { position: Toast.POSITION.TOP_CENTER })
        }
        else {
            let uid = uuidv4();
            let conditionKey = props.pageInfo.entityName + uid
            let conditions = Object.assign({}, props.metadataReducer.showHideCondition, {[conditionKey]:false})
            props.setShowHideCondition(conditions)
            updateArrayValueOnState(key, { uid, isExpanded: true }, true);
        }
    }
}

const onAddRow_GL = (props, updateArrayValueOnState, key) => {
    if (props.metadataReducer.iconcondition && props.pageInfo.parentHierarchy) {
        if (props.metadataReducer.iconcondition.includes(props.pageInfo.parentHierarchy)) {
            let uid = uuidv4();
            let conditionKey = props.pageInfo.entityName + uid
            // console.log(ID)
            let conditions = Object.assign({}, props.metadataReducer.showHideCondition, {[conditionKey]:false})

            props.setShowHideCondition(conditions)
            updateArrayValueOnState(key, { uid, isExpanded: true }, true);
        }
        else {
            triggerToast('Please save ' + props.pageInfo.parentHierarchy + ' before adding ' + props.pageInfo["subApplication"]["locationSummary"], { position: Toast.POSITION.TOP_CENTER })
        }
    }
    else {
        if (props.pageInfo.parentHierarchy) {
            triggerToast('Please save ' + props.pageInfo.parentHierarchy + ' before adding ' + props.pageInfo["subApplication"]["locationSummary"], { position: Toast.POSITION.TOP_CENTER })
        }
        else {
            let uid = uuidv4();
            let conditionKey = props.pageInfo.entityName + uid
            let conditions = Object.assign({}, props.metadataReducer.showHideCondition, {[conditionKey]:false})
            props.setShowHideCondition(conditions)
            updateArrayValueOnState(key, { uid, isExpanded: true }, true);
        }
    }
}

/*
New Methods
*/

const invokeGetPolicyCoverage = async (props, State) => {
    const redux = store.getState();
    let request = {};
    let getPolicyResponse;
    let stateList = redux.metadataReducer.stateList
        if (stateList.length > 0) {
            for (let i = 0; i < stateList.length; i++) {
                if (stateList[i].State === State) {
                    request["ID"] = stateList[i].ID
                }
            }
        }
    request.ROOTID = redux.metadataReducer.submissionResponse.SubmissionID;
    request.EventName = props.pageInfo.eventName;
    request.TargetEventName = props.pageInfo.getpolicyEventName;
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["ServiceRequestDetail"] = Object.assign({},getServiceRequestDetail());
    await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request).then((response) => {
        getPolicyResponse = response.data
    })
    return getPolicyResponse
}

//For Multi Subline
const invokeGetPolicyCoverage_GL = async (props, State, Subline) => {
    const redux = store.getState();
    let request = {};
    let getPolicyResponse;
    let stateList = redux.metadataReducer.stateList
    let primaryState = redux.metadataReducer.primaryState // use primary state logic for ID
        if (stateList.length > 0) {
            for (let i = 0; i < stateList.length; i++) {
                if(Subline != undefined && Subline != null){
                    if(stateList[i].State === State && stateList[i].Subline === Subline){
                        request["ID"] = stateList[i].ID
                    }
                }
                else if (stateList[i].State === primaryState) {
                    request["ID"] = stateList[i].ID
                    break;
                }
            }
        }
    request.ROOTID = redux.metadataReducer.submissionResponse.SubmissionID;
    request.EventName = props.pageInfo.eventName;
    request.TargetEventName = props.pageInfo.getpolicyEventName;
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["ServiceRequestDetail"] = Object.assign({},getServiceRequestDetail());
    await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request).then((response) => {
        getPolicyResponse = response.data
    })
    return getPolicyResponse
}


const metaDataCall = async (props,  subapp, tpo, subApplicationGroupList) => {
try{
    let { objectId, objectVerId } = props.pageInfo
    let subAppInfo={
        subappName:subapp,
        objectId,
        objectVerId,

        subApplicationGroupList
    }
    let pagelevelValues = {}
    pagelevelValues = props.parentProps && props.parentProps.pagelevelValues
    let transactionPrimaryObject = {...pagelevelValues, ...tpo}
    const response = await constructAndInvokeMetadataCall(subAppInfo, {transactionPrimaryObject, EffectiveDate: props.EffectiveDate ? props.EffectiveDate : props.values ? props.values.EffectiveDate : format(new Date(), 'yyyy-MM-dd')});
    return response
}
catch(e){
    console.log('Error in metaDataCall', e);
}
}

export const getStateListString = (state, props) => {

    let stateString = state.stateListVal.toString()
    let replacedString
    if (stateString.includes(",")) {
        replacedString = stateString.replaceAll(",", '","')
    }
    else {
        replacedString = stateString
    }

    return replacedString
}

const setParentValues_GL = (state) => {

    let parentProps = {}
    // let pagelevelValues = await cloneDeep(state.pagelevelValues)
    // let transactionPrimaryObject = await cloneDeep(state.transactionPrimaryObject)
    let pagelevelValues = state.pagelevelValues
    let transactionPrimaryObject = state.transactionPrimaryObject
    parentProps = {pagelevelValues: pagelevelValues, transactionPrimaryObject: transactionPrimaryObject}
    return parentProps

}

const handleInputChangeV2_CF = async (value, component, isDynamicTable, datatableRowindex,state,props) =>{
    props.showLoader();
    let keys = Object.keys(value),attributeName = keys[0],attributeValue = value[attributeName].toString(),temp ={};
    let {pagelevelValues, transactionPrimaryObject}= handleInputChangeV2(value, component, isDynamicTable, datatableRowindex,null,state);
    temp['pagelevelValues']=pagelevelValues;
    temp['transactionPrimaryObject']=transactionPrimaryObject;
    let stateList = getStateListString(state)
    transactionPrimaryObject["StateInfo"] = {}
    transactionPrimaryObject["StateInfo"]["StateList"] = stateList

    if (attributeName === 'State') {
        props.setprimaryState(attributeValue);
        let { PolicySubApplication } = props.pageInfo
        let getPolicyCoveragesResponse = await invokeGetPolicyCoverage(props, attributeValue);
        // let policy = await invokeGetPolicyCov(attributeValue,props,temp);
        let policyMetaData = await metaDataCall (props, attributeValue, PolicySubApplication ,{} )
        let responseTranslation = await EntitySubAppTranslation(getPolicyCoveragesResponse, props, state.pagelevelValues, {}, {}, policyMetaData )
        temp["pagelevelValues"] =  { ...temp.pagelevelValues, ...responseTranslation };
        temp["transactionPrimaryObject"] = { ...temp.transactionPrimaryObject, ...responseTranslation}

        let metadata = await metaDataCall(props, attributeValue, props.pageInfo["subApplication"]["locationDetail"] ,temp.transactionPrimaryObject,"")
        temp["pageMetadata"] = metadata

    }
    else {
        if (
            component.ComponentType.toLowerCase() === "select" ||
            component.ComponentType.toLowerCase() === "radio" ||
            component.ComponentType.toLowerCase() === "checkbox"
        ) {
            let stateValue = state.pagelevelValues.LocationDetail ? state.pagelevelValues.LocationDetail.State : props.parentProps.pagelevelValues.LocationDetail.State
            let metadata = await metaDataCall (props, stateValue, props.pageInfo["subApplication"]["locationDetail"] ,transactionPrimaryObject,"")
            temp["pageMetadata"] = metadata

            // temp['isSubquestionRequired']=true;
        }
    }
    props.hideLoader()
    return temp;
}


const copyValue_CF = async (value, component, isDynamicTable, datatableRowindex,state,props) =>{
    props.showLoader();
    let keys = Object.keys(value),attributeName = keys[0],attributeValue = value[attributeName].toString(),temp ={};
    let {pagelevelValues, transactionPrimaryObject}= handleInputChangeV2(value, component, isDynamicTable, datatableRowindex,null,state);
    temp['pagelevelValues']=pagelevelValues;
    temp['transactionPrimaryObject']=transactionPrimaryObject;
    let stateList = getStateListString(state)
    transactionPrimaryObject["StateInfo"] = {}
    transactionPrimaryObject["StateInfo"]["StateList"] = stateList

    let stateValue = state.pagelevelValues.LocationDetail ? state.pagelevelValues.LocationDetail.State : props.parentProps.pagelevelValues.LocationDetail.State

            temp["pagelevelValues"][component.SubApplicationName]["SquareFootageOfBuilding"] = attributeValue
            temp["transactionPrimaryObject"][component.SubApplicationName]["SquareFootageOfBuilding"] = attributeValue
             let metadata =  typeWatch( async() => {
             props.showLoader();
             await metaDataCall (props, stateValue, props.pageInfo["subApplication"]["locationDetail"] ,temp["transactionPrimaryObject"],"")
             temp["pageMetadata"] = metadata
             props.hideLoader()
            }, 1500)

            props.hideLoader()
            return temp;

        }


const typeWatch =  ((callback, ms) => {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    }
})()

export const getCoordinates = async (Component, value, props, func) => {
    try{
    let property = {}
    let addressvalue = {};
    let {componentState,componentProps, functions, component} = Component
    let subappname =  (Component && Component.component.SubApplicationName) || Component.SubApplicationName
    let {pagelevelValues, transactionPrimaryObject}= await handleInputChangeV2(value, Component.component,componentProps,componentState, functions);
    if(value[component.AttributeName]=='Yes'){
    let redux=store.getState();
    let accountaddress = redux.UIFlowReducer.insuredAddress;
    if(!accountaddress)
        return;
    let searchAddress = accountaddress.FullAddress ? accountaddress.FullAddress : accountaddress.InsuredFullAddress;
    let key = redux.ownerResources.clientInfo.GoogleMapsAPIKey;
    let replacer = new RegExp('#', 'g');
    let newSearchAddress=searchAddress.replace(replacer, '%23');
    await Axios.post("https://maps.googleapis.com/maps/api/geocode/json?address="+newSearchAddress+'&key='+key)
      .then(response => {
        property.Latitude = response.data.results[0].geometry.location.lat.toString();
        property.Longitude = response.data.results[0].geometry.location.lng.toString();
      })
    //   let pagelevelValues = state1.pagelevelValues
    //   Component.state2.transactionPrimaryObject={...{[Component.component.SubApplicationName]:{}},...Component.state2.transactionPrimaryObject}
      let addressheaders = component.ItemTipParams.split(",");
        addressheaders.map((addressheader) => {
          let address = addressheader.split("~");
          addressvalue[address[0]] = accountaddress[address[1]];
      });
      addressvalue["Latitude"] = property.Latitude.toString()
      addressvalue["Longitude"] = property.Longitude.toString()
      }
      else {
        let addressheaders = component.ItemTipParams.split(",");
        addressheaders.map((addressheader) => {
        let address = addressheader.split("~");
        addressvalue[address[0]] = ""
        });
        addressvalue["Latitude"] = Component.center.lat.toString();
        addressvalue["Longitude"] = Component.center.lng.toString();
        // addressvalue["Latitude"] = ""
        // addressvalue["Longitude"] = ""
      }
      pagelevelValues[subappname] = { ...pagelevelValues[subappname], ...addressvalue }
      transactionPrimaryObject[subappname] = { ...pagelevelValues[subappname], ...addressvalue }
      functions.UpdateState("isValueChanged", false, false)
      functions.UpdateState("transactionPrimaryObject",transactionPrimaryObject,false)
      functions.UpdateState("pagelevelValues", pagelevelValues, true)
    }
    catch(err){
        console.log("Error check getcoordinates", err)
    }
    }

export const getCoordinatesV2 = async (Component, value, props, func) => {
    try {
        let property = {}
        let addressvalue = {};
        let redux=store.getState();
        let accountaddress = redux.UIFlowReducer.insuredAddress;
        if(!accountaddress)
        return;
        let searchAddress = accountaddress.FullAddress ? accountaddress.FullAddress : accountaddress.InsuredFullAddress;
        let key = redux.ownerResources.clientInfo.GoogleMapsAPIKey;
        let replacer = new RegExp('#', 'g');
        let newSearchAddress=searchAddress.replace(replacer, '%23');
        let {componentState, componentProps, functions, component} = Component
        let subappname =  (Component && Component.component.SubApplicationName) || Component.SubApplicationName
        let {pagelevelValues, transactionPrimaryObject}= await handleInputChangeV2(value, Component.component, componentProps, componentState, functions, componentState.pageSubAppInfo, undefined, true);
        if(pagelevelValues[subappname][component.AttributeName] === 'Yes'){
        await Axios.post("https://maps.googleapis.com/maps/api/geocode/json?address=" + newSearchAddress + '&key=' + key)
            .then(response => {
                property.Latitude = response.data.results[0].geometry.location.lat.toString();
                property.Longitude = response.data.results[0].geometry.location.lng.toString();
            })
                functions.UpdateState("isValueChanged", false, false)
                let addressheaders = component.ItemTipParams.split(",");
        addressheaders.map((addressheader) => {
            let address = addressheader.split("~");
            addressvalue[address[0]] = accountaddress[address[1]];
        });
        addressvalue["Latitude"] = property.Latitude.toString()
        addressvalue["Longitude"] = property.Longitude.toString()
        pagelevelValues[subappname] = {...pagelevelValues[subappname], ...addressvalue}
        transactionPrimaryObject[subappname] = { ...pagelevelValues[subappname], ...addressvalue }
    }
        functions.UpdateState("transactionPrimaryObject", transactionPrimaryObject, false)
        functions.UpdateState("pagelevelValues", pagelevelValues, true)
    }
    catch(err){
        console.log("Error check get coordinates", err)
    }
}

const handleInputChangeV2_GL = async (value, component, isDynamicTable, datatableRowindex,props, state, functions,Dynamicprops,cancelAPIRef) =>{

    let SubApplicationList = props.pageInfo["subApplication"]["locationDetail"]
    let detailSubApp = []
    let summarySubApp = []
    let subappNameList = []
    let detailSummaryList = {}
    subAppListConstruction(SubApplicationList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
    let { objectId, objectVerId } = props.pageInfo
    let subAppInfo={
        subappName:subappNameList,
        objectId,
        objectVerId,
    }
   let temp = await handleInputChangeV2(value, component,props, state,functions, subAppInfo, {}, true, Dynamicprops,cancelAPIRef)
    console.log("temp in utils---->",temp)
    return temp;
}
const invokeGetPolicyCoverageByPolicyID = async (props) => {
    props.showLoader()
    let request = {},temp={};
    let { subApplicationGroupList } = props.pageInfo
    request.ROOTID = props.metadataReducer.submissionResponse.SubmissionID;
    request["ID"] = props.rowData.PARENTID;
    request.EventName = props.pageInfo.eventName;
    request.TargetEventName = props.pageInfo.getpolicyEventName;
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["ServiceRequestDetail"] = Object.assign({},getServiceRequestDetail());
     await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async (response) => {
            temp = response.data
        })
    props.hideLoader()
    return temp;
}

//For Multi Subline
const invokeGetPolicyCov_GL = async (stateVal,props,state) => {
    props.showLoader()
    let request = {},temp={};
    let { subApplicationGroupList } = props.pageInfo
    request.ROOTID = props.metadataReducer.submissionResponse.SubmissionID;
    let stateList = props.metadataReducer.stateList
    let selectedState
    if (stateList.length > 0) {
        for (let i = 0; i < stateList.length; i++) {
            let stateSubline = stateList[i].State+"-"+stateList[i].Subline
            if (stateSubline === stateVal) {
                selectedState = stateList[i].State
                request["ID"] = stateList[i].ID
            }
        }
    }
    request.EventName = props.pageInfo.eventName;
    request.TargetEventName = props.pageInfo.getpolicyEventName;
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
     await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async (response) => {
            let metadata = await metaDataCall (props, selectedState, {} ,{} ,subApplicationGroupList)
            let responseTranslations = EntitySubAppTranslation(response.data, props, {}, "StateResponse", undefined, metadata);
            temp["pagelevelValues"] =  { ...state.pagelevelValues, ...responseTranslations };
            temp["transactionPrimaryObject"] = { ...state.transactionPrimaryObject, ...responseTranslations}
            //temp['isSubquestionRequired']=true;
            })
            props.hideLoader()
        return temp;
}

export const parentOnLoad = async (props, state, updateState, StateString, subApplicationGroupList, detailList, SubApplicationList) => {
    const redux = store.getState()
    let NAICSCode = props.transactionPrimaryObject.hasOwnProperty('Account') &&
     props.transactionPrimaryObject.Account.hasOwnProperty('NAICSCode') ? props.transactionPrimaryObject.Account['NAICSCode'] : " ";
    props.showLoader()
    if(!props.rowData.ID) {
        let { subApplicationGroupList } = props.pageInfo

        let getPolicyCoveragesResponse = {}, entitySubAppObject
        let policyMetaData, policyMetaDataWithTList, responseTranslation, hierarchyMetaData
        let PrimaryState = redux.metadataReducer.primaryState;

        [getPolicyCoveragesResponse, entitySubAppObject] = await Promise.all([
            invokeGetPolicyCoverage_GL(props, PrimaryState),
            getEntitySubAppMapping({ OwnerId: props.OwnerId, objectId: props.objectId, objectVerId: props.objectVerId,subApplicationGroupList: subApplicationGroupList,subApplication: SubApplicationList, State: PrimaryState})
        ])

        responseTranslation = await EntitySubAppTranslation(getPolicyCoveragesResponse, props, state.pagelevelValues, {}, {}, undefined, undefined, entitySubAppObject)
        responseTranslation["Account"] = {}
        responseTranslation["Account"]["NAICSCode"] = NAICSCode
        let transactionPrimaryObject = {...responseTranslation}
        transactionPrimaryObject["StateInfo"] = {}
        transactionPrimaryObject["StateInfo"]["StateList"] = StateString

        updateState("pagelevelValues", {...responseTranslation}, false)
        updateState("transactionPrimaryObject", {...responseTranslation}, false)
        hierarchyMetaData = await metaDataCall (props, PrimaryState, SubApplicationList, transactionPrimaryObject)

        updateState("pageMetadata", {...hierarchyMetaData}, true)
    }

    else {
        let getPolicyCoveragesResponse, responseTranslation, hierarchyTranslation, entitySubAppObject
        let LocationState = props.rowData.LocationState;
        [getPolicyCoveragesResponse, entitySubAppObject] = await Promise.all([
            invokeGetPolicyCoverageByPolicyID(props),
            getEntitySubAppMapping({ OwnerId: props.OwnerId, objectId: props.objectId, objectVerId: props.objectVerId, subApplicationGroupList: subApplicationGroupList,subApplication: SubApplicationList, State: LocationState})
        ])
        hierarchyTranslation = await EntitySubAppTranslation(props.rowData, props, state.pagelevelValues, "Yes", detailList, undefined, undefined, entitySubAppObject)
        responseTranslation = await EntitySubAppTranslation(getPolicyCoveragesResponse, props, state.pagelevelValues, {}, {}, undefined, undefined, entitySubAppObject)
        responseTranslation["Account"] = {}
        responseTranslation["Account"]["NAICSCode"] = NAICSCode
        updateState("pagelevelValues", {...responseTranslation, ...hierarchyTranslation}, false)
        updateState("transactionPrimaryObject", {...responseTranslation, ...hierarchyTranslation}, false)

        let transactionPrimaryObject = {...responseTranslation, ...hierarchyTranslation}
        transactionPrimaryObject["StateInfo"] = {}
        transactionPrimaryObject["StateInfo"]["StateList"] = StateString

        let metadata = await metaDataCall (props, LocationState, SubApplicationList, transactionPrimaryObject)
        updateState("pageMetadata", {...metadata}, true)

    }
    props.hideLoader()
}

export const parentOnLoad_HomeOwners = async (props, state, updateState, subApplicationGroupList, detailList, SubApplicationList) => {
    const redux = store.getState()
    updateState("loading",true,true)
    //props.showLoader()
    let response={};
    if(props.UIFlowReducer.submissionID && props.pageInfo.getProductBrowseEventName){
        let request={};
        request["ServiceRequestDetail"] = getServiceRequestDetail();
        request.OwnerId = sessionStorage.getItem("OwnerID");
        request.EventName=props.pageInfo.eventName;
        request.TargetEventName=props.pageInfo.getProductBrowseEventName;
        request.ID=props.UIFlowReducer.submissionID;
        response=await AxiosInstance.post("/core/invokeWorkflowEvent/V2",request);
        response=response.data;
    }
    if(props.rowData.ID) {
       await getLocationDetail(props,updateState,SubApplicationList.subappName,response,null,true)
    }
    else{
    let tpo = {...response, OverrideClearance:"false"}
    let metadataResponse = await constructAndInvokeMetadataCall(SubApplicationList,{transactionPrimaryObject:tpo});
    updateState("pagelevelValues", tpo, false)
    updateState("transactionPrimaryObject", tpo, false)
    updateState("loading",false,false)
    updateState("pageMetadata", {...metadataResponse}, true)
     }
    //props.hideLoader()
}

export const parentOnLoad_CL = async (props, state, updateState, subApplicationGroupList, detailList, SubApplicationList) => {
    const redux = store.getState()
    props.showLoader()
    if(props.rowData.ID) {
       await Promise.all([
        await getLocationDetail_CL(props,updateState,SubApplicationList.subappName),
        await getBuildingList_CL(props,updateState,SubApplicationList.subappName)
    ])
    }
    else{
    console.log("SubApplicationList and props in parentonLoad-->",SubApplicationList,props)
    let metadataResponse = await constructAndInvokeMetadataCall(SubApplicationList);
    updateState("pageMetadata", {...metadataResponse}, true)
     }
    props.hideLoader()
}

export const parentOnLoad_CLV1 = async (props, state, updateState, subApplicationGroupList, detailList, SubApplicationList) => {
    try {
        props.showLoader()
        if (props.rowData.ID) {
            getLocationDetail_CLV1(props, updateState, SubApplicationList.subappName)
        }
        else {
            let metadataResponse = await constructAndInvokeMetadataCall(SubApplicationList);
            updateState("pageMetadata", { ...metadataResponse }, true)
        }
        props.hideLoader()
    }
    catch (err) {
        console.log("Error in parentOnLoad_CLV1", err)
    }
}
export const parentOnLoad_ClaimsHistory = async (props, state, updateState, subApplicationGroupList, detailList, SubApplicationList) => {
    try{
        const redux = store.getState()
        props.showLoader()
        let getClaimsHistoryresponse,entitySubAppObject
        let subApps = SubApplicationList.subappName
        if(props.rowData.ID) {
            [getClaimsHistoryresponse, entitySubAppObject] = await Promise.all([
                invokeGetClaimsHistoryDetail(props),
                getEntitySubAppMapping({ OwnerId: sessionStorage.getItem("OwnerID"), objectId: props.pageInfo.objectId, objectVerId: props.pageInfo.objectVerId, subApplication: subApps, State: "CW"})
            ])
            let responseTranslation = await EntitySubAppTranslation(getClaimsHistoryresponse, props, state.pagelevelValues, "Yes", {}, undefined, undefined, entitySubAppObject )
            let transactionPrimaryObject = {...responseTranslation}
            updateState("pagelevelValues", {...responseTranslation}, false)
            updateState("transactionPrimaryObject", {...responseTranslation}, false)
            let pagelevelValues = {}
            pagelevelValues = props.parentProps && props.parentProps.pagelevelValues
            transactionPrimaryObject = {...pagelevelValues, ...transactionPrimaryObject}
            let metadataResponse = await constructAndInvokeMetadataCall(SubApplicationList,{ transactionPrimaryObject});
            updateState("pageMetadata", {...metadataResponse}, true)
        }
        else{
            let pagelevelValues = {}
            pagelevelValues = props.parentProps && props.parentProps.pagelevelValues
            let metadataResponse = await constructAndInvokeMetadataCall(SubApplicationList,{transactionPrimaryObject: pagelevelValues});
            updateState("pageMetadata", {...metadataResponse}, true)
        }
        props.hideLoader()
    }catch(e){
        console.log('Error in parentOnLoad_ClaimsHistory',e)
    }
}

export const parentOnLoad_Building = async (props, state, updateState, subApplicationGroupList, detailList, SubApplicationList) => {
  try{
         const redux = store.getState()
         props.showLoader()
         let getBuildingresponse,entitySubAppObject
         let subApps = SubApplicationList.subappName
         if(props.rowData.ID) {
             [getBuildingresponse, entitySubAppObject] = await Promise.all([
                 invokeGetBuildingDetail(props),
                 //getEntitySubAppMapping({ OwnerId: sessionStorage.getItem("OwnerID"), objectId: props.pageInfo.objectId, objectVerId: props.pageInfo.objectVerId, subApplication: subApps, State: "CW"})
             ])
             let responseTranslation = getBuildingresponse;//await EntitySubAppTranslation(getBuildingresponse, props, state.pagelevelValues, "Yes", {}, undefined, undefined, entitySubAppObject )
             let transactionPrimaryObject = {...responseTranslation}
             let pagelevelValues = {}
             pagelevelValues = props.parentProps && props.parentProps.pagelevelValues
             transactionPrimaryObject = {...pagelevelValues, ...transactionPrimaryObject}
             let metadataResponse = await constructAndInvokeMetadataCall(SubApplicationList,{ transactionPrimaryObject});
             updateState("transactionPrimaryObject", {...transactionPrimaryObject}, false)
             updateState("pagelevelValues", {...transactionPrimaryObject}, false)
             updateState("pageMetadata", {...metadataResponse}, true)
         }
         else{
             let pagelevelValues = {}
             pagelevelValues = props.parentProps && props.parentProps.pagelevelValues
             let metadataResponse = await constructAndInvokeMetadataCall(SubApplicationList,{transactionPrimaryObject: pagelevelValues});
             updateState("pagelevelValues", {...pagelevelValues}, false)
             updateState("transactionPrimaryObject", {...pagelevelValues}, false)
             updateState("pageMetadata", {...metadataResponse}, true)
         }
         props.hideLoader()
     }catch(e){
        console.log('Error in parentOnLoad_ClaimsHistory',e)
    }
}


export const parentOnLoad_BuildingV1 = async (props, state, updateState, subApplicationGroupList, detailList, SubApplicationList) => {
    try{
        const redux = store.getState()
        props.showLoader()
        let getBuildingresponse
        let subApps = SubApplicationList.subappName
        if(props.rowData.ID) {
            [getBuildingresponse] = await Promise.all([
                invokeGetBuildingDetailV1(props),
            ])
            let responseTranslation = getBuildingresponse;//await EntitySubAppTranslation(getBuildingresponse, props, state.pagelevelValues, "Yes", {}, undefined, undefined, entitySubAppObject )
            let transactionPrimaryObject = {...responseTranslation}
            updateState("pagelevelValues", {...responseTranslation}, false)
            updateState("transactionPrimaryObject", {...responseTranslation}, false)
            let pagelevelValues = {}
            pagelevelValues = props.parentProps && props.parentProps.pagelevelValues
            transactionPrimaryObject = {...pagelevelValues, ...transactionPrimaryObject}
            let metadataResponse = await constructAndInvokeMetadataCall(SubApplicationList,{ transactionPrimaryObject});
            updateState("pageMetadata", {...metadataResponse}, true)
        }
        else{
            let pagelevelValues = {}
            pagelevelValues = props.parentProps && props.parentProps.pagelevelValues
            let metadataResponse = await constructAndInvokeMetadataCall(SubApplicationList,{transactionPrimaryObject: pagelevelValues});
            updateState("pageMetadata", {...metadataResponse}, true)
        }
        props.hideLoader()
    }catch(e){
        console.log('Error in parentOnLoad_BuildingV1',e)
    }
}

export const invokeGetClaimsHistoryDetail = async(props) => {
    const redux = store.getState();
    let request = {};
    let  getClaimsHistoryDetailResponse;
    request["ServiceRequestDetail"] = Object.assign({},getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.ROOTID = props.UIFlowReducer.insuredID;
    request.PARENTID = props.parentProps.pagelevelValues.Building.ID
    request.ID = props.rowData.ID ? props.rowData.ID  : ""
    request.EventName = props.pageInfo.eventName;
    request.TargetEventName = props.pageInfo.getEventDetailName;
    let {SubmissionType} = props.metadataReducer;
    if(props.pageInfo.eventNames && props.pageInfo.eventNames.hasOwnProperty(SubmissionType)){
        request.TargetEventName = props.pageInfo.eventNames[SubmissionType].getEventDetailName;
    }
    let {RequestMapping} = props.pageInfo;
    if(RequestMapping && RequestMapping.length > 0){
        request = await dynamicObjectConstruction(RequestMapping, { ...props }, request, 'request');
    }
    await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request).then((response) => {
        getClaimsHistoryDetailResponse = response.data
        console.log("getClaimsHistoryDetailResponse--->",getClaimsHistoryDetailResponse)
    })
    return getClaimsHistoryDetailResponse
}

export const invokeGetBuildingDetailV1 = async (props) => {
    try {
        const redux = store.getState();
        let request = {};
        let getBuildingDetailResponse;
        request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
        request.OwnerId = sessionStorage.getItem("OwnerID");
        request.ROOTID = props.UIFlowReducer.insuredID;
        request.PARENTID = props.UIFlowReducer.insuredID
        request.ID = props.rowData.ID ? props.rowData.ID : ""
        request.EventName = props.pageInfo.eventName;
        request.TargetEventName = props.pageInfo.getEventDetailName;
        await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request).then((response) => {
            getBuildingDetailResponse = response.data
            console.log("invokeGetBuildingDetailRes--->", getBuildingDetailResponse)
        })
        return getBuildingDetailResponse
    }
    catch (err) {
        console.log("Error in invokeGetBuildingDetailV1", err)
    }
}
export const invokeGetBuildingDetail = async(props) => {
    const redux = store.getState();
    let request = {};
    let  getBuildingDetailResponse;
    request["ServiceRequestDetail"] = Object.assign({},getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.PARENTID= props.parentProps.parentRowData.ID
    request.ROOTID= props.UIFlowReducer.insuredID
    request.ID= props.rowData.ID ? props.rowData.ID :""
    request.EventName = props.pageInfo.eventName;
    request.TargetEventName = props.pageInfo.getEventDetailName;

    await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request).then((response) => {
         getBuildingDetailResponse = response.data
        console.log("invokeGetBuildingDetailRes--->",getBuildingDetailResponse)
    })
    return getBuildingDetailResponse
}

export const getLocationDetail = async(props,updateState,SubApplicationList,responseData,state,isMount=null) => {
  let request = Object.create({});
  let responseTranslation= {}
  request["ServiceRequestDetail"] = Object.assign(
      {},
      getServiceRequestDetail()
  );
  request.OwnerId = sessionStorage.getItem("OwnerID");
      request.PARENTID= props.UIFlowReducer.submissionID
      request.ROOTID= props.UIFlowReducer.submissionID
      request.ID= props.rowData.ID ? props.rowData.ID :""

      request["TargetEventName"] = props.pageInfo.getEventDetailName
      request["EventName"] = props.pageInfo.eventName
      console.log("request in getlocation----->",request)

      LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
      .then(async (response) => {
          console.log("response in getlocationdetail---->",response)
          if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
              throw new Error('')
          }
          else{
              responseTranslation= response.data
              if(isMount && props.UIFlowReducer && props.UIFlowReducer.submissionData && props.UIFlowReducer.submissionData.SubmissionType && props.UIFlowReducer.submissionData.SubmissionType==="RENEWAL" && props.clientInfo&& props.clientInfo.IsWildFireNeeded){
                let wildFire = await getWildFireScore(props,{pagelevelValues:{...response.data}})
                responseTranslation.Building={...responseTranslation.Building,...wildFire.Building}
          }
              let stateValues = state && state.pagelevelValues ? {...state.pagelevelValues} : {}
              let transactionPrimaryObject = {...stateValues,...responseTranslation}
              let metadataResponse = await metaDataCall (props, SubApplicationList, transactionPrimaryObject)
              let metaResponseTranslation = await EntitySubAppTranslationV2(responseTranslation,metadataResponse.ApplicationDetail)
              metaResponseTranslation={...stateValues,...responseData,...metaResponseTranslation}
              if(response.data && response.data.LocationDetail && "OverrideClearance" in response.data.LocationDetail && response.data.LocationDetail.OverrideClearance==='Yes'){
                metaResponseTranslation = {...metaResponseTranslation, OverrideClearance:"true"}
              }
              else{
                metaResponseTranslation = {...metaResponseTranslation, OverrideClearance:"false"}
              }
              updateState("scheduleofpersonalproperty_dataList", response.data.PersonalProperty, false)
              let metadataRes = await metaDataCall (props, SubApplicationList, metaResponseTranslation)
              let pagelevelValues =   {...stateValues,...responseTranslation,...metaResponseTranslation}
              updateState("pageMetadata", {...metadataRes}, false)
              updateState("pagelevelValues", pagelevelValues, false)
              updateState("loading",false,false)
              updateState("transactionPrimaryObject", pagelevelValues, true)
          }
      })
    }

export const getLocationDetail_CLV1 = async(props,updateState,SubApplicationList) => {
    try{
    let request = Object.create({});
    let responseTranslation= {}
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
    request.OwnerId = sessionStorage.getItem("OwnerID");
        request.PARENTID= props.UIFlowReducer.submissionID
        request.ROOTID= props.UIFlowReducer.submissionID
        request.ID= props.rowData.ID ? props.rowData.ID :""
        request["TargetEventName"] = props.pageInfo.getEventDetailName
        request["EventName"] = props.pageInfo.eventName
        LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async (response) => {
            if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                throw new Error('')
            }
            else{
                responseTranslation= response.data
                let transactionPrimaryObject = {...responseTranslation}
                let metadataResponse = await metaDataCall (props, SubApplicationList, transactionPrimaryObject)
                updateState("pagelevelValues", {...responseTranslation}, false)
                updateState("transactionPrimaryObject", {...responseTranslation}, false)
                updateState("pageMetadata", {...metadataResponse}, true)
           }
        })
    }
    catch (err) {
        console.log("Error in getLocationDetail_CLV1", err)
    }
}

export const getLocationDetail_CL = async(props,updateState,SubApplicationList) => {
    let request = Object.create({});
    let responseTranslation= {}
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
    request.OwnerId = sessionStorage.getItem("OwnerID");
        request.PARENTID= props.UIFlowReducer.insuredID
        request.ROOTID= props.UIFlowReducer.insuredID
        request.ID= props.rowData.ID ? props.rowData.ID :""

        request["TargetEventName"] = props.pageInfo.getEventDetailName
        request["EventName"] = props.pageInfo.eventName
        console.log("request in getlocation----->",request)

        LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async (response) => {
            console.log("response in getlocationdetail---->",response)
            if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                throw new Error('')
            }
            else{
                console.log('Get location response- Aju-->',response.data)
                responseTranslation= response.data
                let transactionPrimaryObject = {...responseTranslation}
                console.log('SubApplicationList--->',SubApplicationList)
                let metadataResponse = await metaDataCall (props, SubApplicationList, transactionPrimaryObject)
                updateState("pagelevelValues", {...responseTranslation}, false)
                updateState("transactionPrimaryObject", {...responseTranslation}, false)
                updateState("pageMetadata", {...metadataResponse}, true)
           }

        })
}

export const getBuildingList_CL = async(props,updateState,SubApplicationList) => {
    let request = Object.create({});
    let responseTranslation= {}
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.PARENTID= props.parentProps.location_dataList[0].ID;
    request.ROOTID= props.UIFlowReducer.insuredID
    request.ID= props.rowData.ID ? props.rowData.ID :""
    request["TargetEventName"] = props.pageInfo.children[0].children[0].getEventName //"GetBuildingList_V1"
    request["EventName"] = props.pageInfo.eventName

        LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async (response) => {
            if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                throw new Error('')
            }
            else{
                responseTranslation= response.data
                let {entityName, subApplication} = props.pageInfo.children[0].children[0];
                updateState("pagelevelValues", {...responseTranslation}, false)
                updateState("transactionPrimaryObject", {...responseTranslation}, false)
                if (Array.isArray(response.data[entityName])) {
                    let subapplicationName = subApplication['locationSummary'].toLowerCase();
                    let dataListName = subapplicationName + "_dataList";
                    let dataListValue = response.data[entityName]
                    updateState(dataListName, dataListValue, true);
                }
           }

        })
}

export const childOnLoad = async (props, state, updateState, StateString, subApplicationGroupList, detailList, SubApplicationList) => {
    const redux = store.getState()
    props.showLoader()
    if(!props.rowData.ID) {
        let transactionPrimaryObject = props.parentProps.transactionPrimaryObject
        let LocationState = props.parentProps.pagelevelValues.LocationDetail.LocationState

        let metadata = await metaDataCall (props, LocationState, SubApplicationList, transactionPrimaryObject)
        updateState("transactionPrimaryObject", {...transactionPrimaryObject}, false)
        updateState("pagelevelValues", {...props.parentProps.pagelevelValues}, false)
        updateState("pageMetadata", {...metadata}, true)

    }

    else {
        let childCanLoad = {...state.childFlagProps,childLoad: true}
        updateState("childFlagProps", {...childCanLoad}, false)
        let hierarchyMetaData, hierarchyTranslation
        let LocationState = props.parentProps.pagelevelValues.LocationDetail.LocationState
        let parentProps = props.parentProps.transactionPrimaryObject
        let entitySubAppObject = await getEntitySubAppMapping({ OwnerId: props.OwnerId, objectId: props.objectId, objectVerId: props.objectVerId, subApplication: SubApplicationList, State: LocationState})
        hierarchyTranslation = await EntitySubAppTranslation(props.rowData, props, state.pagelevelValues, "Yes", detailList, hierarchyMetaData, undefined, entitySubAppObject)
        Object.keys(hierarchyTranslation).forEach((subappName) => {
            if (Array.isArray(hierarchyTranslation[subappName])) {
                let subapp = subappName.toLowerCase()
                // props.updateArrayValueOnState(subappName, hierarchyTranslation[subappName]);
                subapp = subapp + '_dataList'
                updateState(subapp, hierarchyTranslation[subappName], false)
            }
        })
        let transactionPrimaryObject = {...parentProps, ...hierarchyTranslation}
        updateState("pagelevelValues", {...hierarchyTranslation}, false)
        updateState("transactionPrimaryObject", {...transactionPrimaryObject}, false)

        let metadata = await metaDataCall (props, LocationState, SubApplicationList, {...transactionPrimaryObject})
        updateState("pageMetadata", {...metadata}, true)
    }
    props.hideLoader()
}

export const transform_GL = (props) =>{
    let parentProps = {}
    console.log("props in transform---->",props)
    parentProps = {
        modifiedAdditionalCoverage: {...props.parentState.modifiedAdditionalCoverage},
        pagelevelValues: {...props.parentState.pagelevelValues},
        transactionPrimaryObject: {...props.parentState.pagelevelValues},
        functions: {...props.functions},
        childFlagProps : {...props.parentState.childFlagProps},
        parentRowData: {...props.currentProps.rowData}
    }
    return parentProps
}
const componentDidMount_GL = async (props, state, updateState) => {
    const redux = store.getState()
    const onLoad = props.pageInfo.onLoadMethod
    let { subApplicationGroupList } = props.pageInfo
    let detailList = props.pageInfo["subApplication"]["locationSummary"] + "Detail"
    let SubApplicationList = props.pageInfo["subApplication"]["locationDetail"]
    let detailSubApp = []
    let summarySubApp = []
    let subappNameList = []
    let detailSummaryList = {}
    // let dataListName =''
    subAppListConstruction(SubApplicationList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
    updateState("summarySubAppState", summarySubApp, false)
    updateState("subappNameListState", subappNameList, false)
    updateState("detailSummaryListState", detailSummaryList, false)

    let StateString = await getStateListString(state, props)

    onLoad(props, state, updateState, StateString, subApplicationGroupList, detailList, subappNameList)
}

const getAdditionalCoveragesList = async (props, eventName) => {
    try {
        const reduxState = store.getState()
        let reqObj = {
            Hierarchy: props.Hierarchy,
            EffectiveDate: props.values.EffectiveDate,
            ROOTID: reduxState.UIFlowReducer.submissionID,
            ID: props.rowData.ID,
            ProductVerNumber: reduxState.UIFlowReducer.productDetails.ProductVerNumber,
            ProductNumber: reduxState.UIFlowReducer.productDetails.ProductNumber
        }
        let additionalCoverageRequest = await getJSONRequestFromAPI(eventName, reduxState.UIFlowReducer.productDetails);
        additionalCoverageRequest = Object.assign({},additionalCoverageRequest, reqObj, reduxState.UIFlowReducer.productDetails)
        let response = await AxiosInstance.post('core/invokeWorkflowEvent', additionalCoverageRequest)
        if(response.data.hasOwnProperty('CoverageList'))
            return response.data.CoverageList
        else
            throw new Error('Error in Get Additional Coverage List')
    }
    catch(error){
        console.log(error)
    }
}

export const parentOnLoad_CF = async (props, state, updateState, StateString, detailList, SubApplicationList = []) => {
    const redux = store.getState()
    props.showLoader()
    let { PolicySubApplication } = props.pageInfo

    if(!props.rowData.ID) {

        let getPolicyCoveragesResponse, entitySubAppObject, policyMetaDataWithTList, responseTranslation, hierarchyMetaData
        let PrimaryState = redux.metadataReducer.primaryState
        let SubApps = [];
        SubApps.push(PolicySubApplication);
        [getPolicyCoveragesResponse, entitySubAppObject] = await Promise.all([
                invokeGetPolicyCoverage(props, PrimaryState),
                getEntitySubAppMapping({ OwnerId: props.OwnerId, objectId: props.objectId, objectVerId: props.objectVerId, subApplication: SubApps, State: PrimaryState})
            ])
        responseTranslation = await EntitySubAppTranslation(getPolicyCoveragesResponse, props, state.pagelevelValues, {}, {}, undefined, undefined, entitySubAppObject )
        let transactionPrimaryObject = {...responseTranslation}
        transactionPrimaryObject["StateInfo"] = {}
        transactionPrimaryObject["StateInfo"]["StateList"] = StateString

        updateState("pagelevelValues", {...responseTranslation}, false)
        updateState("transactionPrimaryObject", {...responseTranslation}, false)
        hierarchyMetaData = await metaDataCall (props, PrimaryState, SubApplicationList, transactionPrimaryObject)

        updateState("pageMetadata", {...hierarchyMetaData}, true)
    }
    else {
        let getPolicyCoveragesResponse, getAdditionalCoverages, entitySubAppObject = [], policyEntity = [], getPolicyResponseTranslation, metadataResponse, responseTranslation, hierarchyMetaData, hierarchyTranslation, State;
        State = props.rowData.State
        let SubApps = [...SubApplicationList];
        let PolicySubApp = []
        SubApps.push(PolicySubApplication);
        PolicySubApp.push(PolicySubApplication);
        [getPolicyCoveragesResponse, getAdditionalCoverages, entitySubAppObject, policyEntity] = await Promise.all(
            [
                invokeGetPolicyCoverage(props, props.rowData.State),
                getAdditionalCoveragesList(props, props.pageInfo.getAdditionalCoverageListEventName),
                getEntitySubAppMapping({ OwnerId: props.OwnerId, objectId: props.objectId, objectVerId: props.objectVerId, subApplication: SubApps, State: props.rowData.State}),
                getEntitySubAppMapping({ OwnerId: props.OwnerId, objectId: props.objectId, objectVerId: props.objectVerId, subApplication: PolicySubApp, State: props.rowData.State})

            ])
        // metadataResponse = await metaDataCall (props, State, PolicySubApplication ,{} )
        // hierarchyMetaData = await metaDataCall (props, State, SubApplicationList)

        responseTranslation = await EntitySubAppTranslation(getPolicyCoveragesResponse, props, state.pagelevelValues, {}, {}, metadataResponse, undefined, policyEntity)
        hierarchyTranslation = await EntitySubAppTranslation(props.rowData, props, state.pagelevelValues, "Yes", detailList, hierarchyMetaData, getAdditionalCoverages, entitySubAppObject)

        updateState("pagelevelValues", {...responseTranslation, ...hierarchyTranslation}, false)
        updateState("transactionPrimaryObject", {...responseTranslation, ...hierarchyTranslation}, false)

        let transactionPrimaryObject = {...responseTranslation, ...hierarchyTranslation}
        transactionPrimaryObject["StateInfo"] = {}
        transactionPrimaryObject["StateInfo"]["StateList"] = StateString

        let metadata = await metaDataCall (props, State, SubApplicationList, transactionPrimaryObject)
        updateState("pageMetadata", {...metadata}, true)

    }
    props.hideLoader()
}

export const childOnLoad_CF = async (props, state, updateState, StateString, detailList, SubApplicationList) => {
    const redux = store.getState()
    props.showLoader()

    let LocationState;

    LocationState = props.parentProps.pagelevelValues.LocationDetail.State

    if(!props.rowData.ID) {
        let transactionPrimaryObject = props.parentProps.transactionPrimaryObject
        let metadata = await metaDataCall (props, LocationState, SubApplicationList, transactionPrimaryObject)
        updateState("transactionPrimaryObject", {...transactionPrimaryObject}, false)
        updateState("pagelevelValues", {...props.parentProps.pagelevelValues}, false)
        updateState("pageMetadata", {...metadata}, true)
    }
    else {
        let hierarchyMetaData, hierarchyTranslation, additionalCoverages = []
        let childCanLoad = {...state.childFlagProps,childLoad: true}
        updateState("childFlagProps", {...childCanLoad}, false)

        let parentProps = props.parentProps.transactionPrimaryObject
        if(props.Hierarchy)
            additionalCoverages = await getAdditionalCoveragesList(props,props.pageInfo.getAdditionalCoverageListEventName)

            let entitySubAppObject = await getEntitySubAppMapping({ OwnerId: props.OwnerId, objectId: props.objectId, objectVerId: props.objectVerId, subApplication: SubApplicationList, State: LocationState})
            //hierarchyTranslation = await EntitySubAppTranslation(props.rowData, props, state.pagelevelValues, "Yes", detailList, hierarchyMetaData, undefined, entitySubAppObject)



        //hierarchyMetaData = await metaDataCall (props, LocationState, SubApplicationList)
        hierarchyTranslation = await EntitySubAppTranslation(props.rowData, props, state.pagelevelValues, "Yes", detailList, hierarchyMetaData, additionalCoverages,entitySubAppObject)
        let transactionPrimaryObject = {...parentProps, ...hierarchyTranslation}

        updateState("pagelevelValues", {...hierarchyTranslation}, false)
        updateState("transactionPrimaryObject", {...transactionPrimaryObject}, false)

        let metadata = await metaDataCall (props, LocationState, SubApplicationList, {...transactionPrimaryObject})
        updateState("pageMetadata", {...metadata}, true)
    }
    props.hideLoader()
}

const componentDidMount_CF = async (props, state, updateState, metadata) => {
    let SubApplicationList = props.pageInfo["subApplication"]["locationDetail"]
    const onLoad = props.pageInfo.onLoadMethod
    let detailList = props.pageInfo["subApplication"]["locationSummary"] + "Detail"

    let detailSubApp = []
    let summarySubApp = []
    let subappNameList = []
    let detailSummaryList = {}

    subAppListConstruction(SubApplicationList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)

    console.log("rowdata [newgin]",props.rowData)
    console.log("GL test [newgin]",summarySubApp,subappNameList,detailSummaryList);

    updateState("summarySubAppState", summarySubApp, false)
    updateState("subappNameListState", subappNameList, false)
    updateState("detailSummaryListState", detailSummaryList, false)
    let StateString = await getStateListString(state, props)

    onLoad(props, state, updateState, StateString, detailList, subappNameList)
}

const constructTransactionRequest_CF = async (state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer) =>{
    let request = await constructTransactionRequest(state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer)
    return request
}

const constructTransactionRequest_Location = async (state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer, callback, key,updateState) =>{
    try {
        let redux = store.getState();
        let {Building}=state.pagelevelValues;
        let {scheduleofpersonalproperty_dataList=[]}=state;

        if(state.transactionPrimaryObject.BlockLocationSave === "true"){
            if(redux.userInfoReducer.Role.toLowerCase() === 'agent'){
                let message = props.pageInfo.toaster && props.pageInfo.toaster.Agent || 'Please check "Override Clearance" to proceed.'
                failureToast(message )
              }else{
                let message = (props.pageInfo.toaster && props.pageInfo.toaster.Underwriter) || 'Please check "Override Clearance" to proceed.'
                failureToast( message)
              }
            return
        }
        // Section to block the flow when there is entries for Schedule of Property Data Table when Personal Property is "Yes"
        if(Building && Building.hasOwnProperty("PersonalPropertyYN") && Building.PersonalPropertyYN ==="Yes" &&
        scheduleofpersonalproperty_dataList && scheduleofpersonalproperty_dataList.length <=0 )
        {
            let SubApplicationList = props.pageInfo["subApplication"]["locationDetail"];
            let { objectId, objectVerId } = props.pageInfo
            let detailSubApp = []
            let summarySubApp = []
            let subappNameList = []
            let detailSummaryList = {}

            subAppListConstruction(SubApplicationList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
            updateState("loading",true,true)
            const response = await constructAndInvokeMetadataCall({ subappName: subappNameList, objectId, objectVerId}, { transactionPrimaryObject:{...state.transactionPrimaryObject,PersonalPropertyLength:"0"}}, null)
            updateState("pageMetadata",response,false)
            updateState("loading",false,true)
            return
        }
        updateState("loading",true,true)
        let request = await constructTransactionRequest(state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer)
        // let request = await constructTransactionRequestV1(state,props, pagelevelValues,null,true,false,'')
        // request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
        // request.OwnerId = sessionStorage.getItem("OwnerID");
        request.LocationDetail.overRideClearanceDate=props.UIFlowReducer.BlockLocationSave === false &&  props.UIFlowReducer.OverideClearanceDate;
        request.LocationDetail.overRideClearanceName=props.UIFlowReducer.BlockLocationSave === false && redux.userInfoReducer.UserName;
        request.PersonalProperty=state.pagelevelValues.ScheduleOfPersonalProperty
            request.InsuredID=props.UIFlowReducer.insuredID
            request.SubmissionID=props.UIFlowReducer.submissionID
            request.LocationDetail.PARENTID= props.UIFlowReducer.insuredID
            request.LocationDetail.ROOTID= props.UIFlowReducer.submissionID
            request.LocationDetail.ID= props.rowData.ID ? props.rowData.ID :""
            request.Building.PARENTID= props.rowData.ID ? props.rowData.ID :""
            request.Building.ID= props.rowData.hasOwnProperty("Building") ? props.rowData.Building[0].ID ? props.rowData.Building[0].ID :"" : ""
            request.Building.ROOTID=props.UIFlowReducer.submissionID
            request["TargetEventName"] = props.pageInfo.targetEventName
            request.ProductVerNumber = redux.UIFlowReducer.productDetails.ProductVerNumber;
            request.ProductNumber = redux.UIFlowReducer.productDetails.ProductNumber;
            request["EventName"] = props.pageInfo.eventName;
            if(props.pageInfo.LocationActionEvent){
              request['EventAction']= props.pageInfo.LocationActionEvent;
            }
            console.log("request in savelocation----->",request)
            request["EntityName"] = props.pageInfo.entityName
            LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
            .then(async (response) => {
                console.log("response in detailsummarylist---->",response)
                if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                    throw new Error('')
                }else if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "error"){
                    if(response.data.Location.Building && response.data.Location.Building.hasOwnProperty("ErrorMessage")){
                        let subappInfo = {
                            objectId : props.pageInfo.objectId,
                            objectVerId : props.pageInfo.objectVerId,
                            subappName : state.subappNameListState
                          }
                        updateState("loading",true,true)
                        let tpo = state.transactionPrimaryObject;
                        tpo = {...tpo,"Building__ErrorMessage":response.data.Location.Building.ErrorMessage}
                        const metadataresponse = await constructAndInvokeMetadataCall(subappInfo, { transactionPrimaryObject:tpo}, null)
                        updateState("pageMetadata",metadataresponse,false)
                        updateState("transactionPrimaryObject", tpo, false)
                        updateState("loading",false,false)
                        await store.dispatch({
                            type: actionType.SET_HASLOCATION,
                            payload: true
                        });
                        return;
                    }
                }
                else{
                    console.log('Save location response--->',response.data)
                    let responseEntity = response.data[props.pageInfo.entityName]
                    await setHierarchyIds_HomeOwners(responseEntity, request, getLocationList, callback, key, props,state,updateState);

                    updateState("values", { ...state.values, ...response.data[props.pageInfo.entityName] }, true)
                    let tpo = state.transactionPrimaryObject
                    delete tpo["Building__ErrorMessage"]
                    updateState("transactionPrimaryObject", tpo, true)
                        if (props.pageInfo.parentHierarchy) {
                            callback(response.data[props.pageInfo.entityName], key, state)
                        }
                        // updateState("loading",false,true)
                         await store.dispatch({ type: actionType.UPDATE_VALIDATIONS, payload:true});
                }
            })
            .catch((err) => {
                updateState("loading",false,true)
                triggerToast("Error in Saving location")
                console.log(err);
            });

    } catch (error) {
        console.error("error in constructTransactionRequest_Location",error.message)
    }
  //  return request
}

const constructTransactionRequest_Location_CL = async (state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer, callback, key,updateState) =>{
    try {
        let redux = store.getState();
        let {Building}=state.pagelevelValues;
        let {scheduleofpersonalproperty_dataList=[]}=state;

        if(redux.UIFlowReducer.BlockLocationSave === true){
            triggerToast('Please check "Override Clearance" to proceed.' , { position: Toast.POSITION.TOP_CENTER })
            return
        }
        updateState("loading",true,true)
        let request = await constructTransactionRequest(state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer)
            request.LocationDetail.PARENTID= props.UIFlowReducer.insuredID
            request.LocationDetail.ROOTID= props.UIFlowReducer.insuredID
            request.LocationDetail.ID= props.rowData.ID ? props.rowData.ID :""
            request["TargetEventName"] = props.pageInfo.targetEventName
            request["EventName"] = props.pageInfo.eventName
            console.log("request in savelocation- Aju---->",request)
            request["EntityName"] = props.pageInfo.entityName

            LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
            .then(async (response) => {
                console.log("response in detailsummarylist---->",response)
                if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                    throw new Error('')
                }
                else{
                    console.log('Save location response--->',response.data)
                    let responseEntity = response.data[props.pageInfo.entityName]
                    await setHierarchyIds_CL(responseEntity, request, getLocationList_CL, callback, key, props,state,updateState);

                    updateState("values", { ...state.values, ...response.data[props.pageInfo.entityName] }, true)
                        if (props.pageInfo.parentHierarchy) {
                            callback(response.data[props.pageInfo.entityName], key, state)
                        }
                        updateState("loading",false,true)

                }
            })
            .catch((err) => {
                updateState("loading",false,true)
                triggerToast(err.message)
                console.log(err);
            });

    } catch (error) {
        console.error("error in constructTransactionRequest_Location_CL",error.message)
    }
    //return request
}

const constructTransactionRequest_Location_RateIndication = async (state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer, callback, key,updateState) =>{
    try {
        let redux = store.getState();
        let {Building}=state.pagelevelValues;
        let {scheduleofpersonalproperty_dataList=[]}=state;

        if(state.transactionPrimaryObject.BlockLocationSave === "true"){
            if(redux.userInfoReducer.Role.toLowerCase() === 'agent'){
              let message = props.pageInfo.toaster && props.pageInfo.toaster.Agent || 'Please check "Override Clearance" to proceed.'
              failureToast(message )
            }else{
              let message = (props.pageInfo.toaster && props.pageInfo.toaster.Underwriter) || 'Please check "Override Clearance" to proceed.'
              failureToast( message)
            }
            return
        }
        // Section to block the flow when there is entries for Schedule of Property Data Table when Personal Property is "Yes"
        if(Building && Building.hasOwnProperty("PersonalPropertyYN") && Building.PersonalPropertyYN ==="Yes" &&
        scheduleofpersonalproperty_dataList && scheduleofpersonalproperty_dataList.length <=0 )
        {
            let SubApplicationList = props.pageInfo["subApplication"]["locationDetail"];
            let { objectId, objectVerId } = props.pageInfo
            let detailSubApp = []
            let summarySubApp = []
            let subappNameList = []
            let detailSummaryList = {}

            subAppListConstruction(SubApplicationList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
            updateState("loading",true,true)
            const response = await constructAndInvokeMetadataCall({ subappName: subappNameList, objectId, objectVerId}, { transactionPrimaryObject:{...state.transactionPrimaryObject,PersonalPropertyLength:"0"}}, null)
            updateState("pageMetadata",response,false)
            updateState("loading",false,true)
            return
        }
        updateState("loading",true,true)
        let request = await constructTransactionRequest(state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer)
        // let request = await constructTransactionRequestV1(state,props, pagelevelValues,null,true,false,'')
        // request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
        // request.OwnerId = sessionStorage.getItem("OwnerID");
        request.LocationDetail.overRideClearanceDate=state.transactionPrimaryObject.BlockLocationSave === false &&  props.UIFlowReducer.OverideClearanceDate;
        request.LocationDetail.overRideClearanceName=state.transactionPrimaryObject.BlockLocationSave === false && redux.userInfoReducer.UserName;
        request.PersonalProperty=state.pagelevelValues.ScheduleOfPersonalProperty
        request.InsuredID=props.UIFlowReducer.insuredID
        request.LocationDetail.PARENTID= props.UIFlowReducer.insuredID
        request.LocationDetail.ROOTID= props.UIFlowReducer.submissionID
        request.LocationDetail.ID= props.rowData.ID ? props.rowData.ID :""
        request.Building.PARENTID= props.rowData.ID ? props.rowData.ID :""
        request.ProductVerNumber = redux.UIFlowReducer.productDetails.ProductVerNumber;
        request.ProductNumber = redux.UIFlowReducer.productDetails.ProductNumber;
        request.Building.ID= props.rowData.hasOwnProperty("Building") ? props.rowData.Building[0].ID ? props.rowData.Building[0].ID :"" : ""
        request.Building.ROOTID=props.UIFlowReducer.submissionID
        request.SubmissionID= props.UIFlowReducer.submissionID;
        if(props.pageInfo.LocationActionEvent){
          request['EventAction']= props.pageInfo.LocationActionEvent;
        }
        request["TargetEventName"] = props.pageInfo.targetEventName
        request["EventName"] = props.pageInfo.eventName
            console.log("request in savelocation----->",request)
            request["EntityName"] = props.pageInfo.entityName
            LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
            .then(async (response) => {
                console.log("response in detailsummarylist---->",response)
                if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                    triggerToast("Unable to save location");
                    console.log("Response Failed");
                    updateState("loading",false,true)
                   return;
                  }
                else{
                    console.log('Save location response--->',response.data)
                    let responseEntity = response.data[props.pageInfo.entityName]
                    await setHierarchyIds_HomeOwners(responseEntity, request, getLocationList_RateIndication, callback, key, props,state,updateState);

                    updateState("values", { ...state.values, ...response.data[props.pageInfo.entityName] }, true)
                    let tpo = state.transactionPrimaryObject
                    delete tpo["Building__ErrorMessage"]
                    updateState("transactionPrimaryObject", tpo, true)
                        if (props.pageInfo.parentHierarchy) {
                            callback(response.data[props.pageInfo.entityName], key, state)
                        }
                        updateState("loading",false,true)

                }
            })
            .catch((err) => {
                updateState("loading",false,true)
                triggerToast("Error in Saving Location")
                console.log(err);
            });

    } catch (error) {
        console.error("error in constructTransactionRequest_Location_RateIndication",error.message)
    }
  //  return request
}


const deletePersonalProperty = async (propsObj, stateObj, ID) =>{
    let deleteRequest = {}
    let ServiceRequestDetail = await Object.assign({}, getServiceRequestDetail());
    deleteRequest["ServiceRequestDetail"] = {...ServiceRequestDetail}
    deleteRequest.OwnerId = sessionStorage.getItem("OwnerID");
    deleteRequest.TargetEventName = propsObj.pageInfo.deleteEventName
    deleteRequest["EventName"] = propsObj.pageInfo.eventName
    deleteRequest.ID = ID
    deleteRequest.PARENTID = stateObj.pagelevelValues.Building.ID
    deleteRequest.ROOTID = propsObj.UIFlowReducer.insuredID;
    let response = await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", deleteRequest)
    let responseData = response.data;
    return responseData;
}

const deleteLocation_CL = async (propsObj, ID) =>{
    let deleteRequest = {}
    let ServiceRequestDetail = await Object.assign({}, getServiceRequestDetail());
    deleteRequest["ServiceRequestDetail"] = {...ServiceRequestDetail}
    deleteRequest.OwnerId = sessionStorage.getItem("OwnerID");
    deleteRequest.TargetEventName = propsObj.pageInfo.deleteEventName//'RemoveInsuredObject'
    deleteRequest["EventName"] = propsObj.pageInfo.eventName
    deleteRequest.ID = ID
    deleteRequest.PARENTID = propsObj.propsValue.UIFlowReducer.insuredID
    deleteRequest.ROOTID = propsObj.propsValue.UIFlowReducer.insuredID
    deleteRequest["EntityName"] = propsObj.pageInfo.entityName
    let response = await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", deleteRequest)
    let responseData = response.data;
    return responseData;

}

const constructTransactionRequest_ClaimsHistory= async (state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer, callback, key,updateState) =>{
        updateState("loading",true,true)
    let request = await constructTransactionRequest(state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer)
    request.PARENTID= props.parentProps.pagelevelValues.Building.ID
    request.SubmissionID= props.UIFlowReducer.submissionID
    request.ROOTID= props.UIFlowReducer.insuredID
    request.ID= props.rowData.ID ? props.rowData.ID : ""
    request["TargetEventName"] = props.pageInfo.targetEventName
    request["EventName"] = props.pageInfo.eventName
    console.log("request claims hostory-->",request)
    console.log("building id-->",props.rowData,props.parentProps.pagelevelValues.Building.ID)
    LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async (response) => {
            console.log("response in claimshistory---->",response)
            if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                throw new Error('')
            }
            else{
                console.log('claimshistory response--->',response.data)
                let responseEntity = response.data[props.pageInfo.entityName]
                console.log('responseEntity response--->',responseEntity,props.pageInfo.entityName)
                await setHierarchyIds_HomeOwners(responseEntity, request, getLocationList, callback, key, props,state,updateState);
                updateState("values", { ...state.values, ...response.data[props.pageInfo.entityName] }, true)
                console.log('state values response--->',state.values)
                if (props.pageInfo.parentHierarchy) {
                        callback(response.data[props.pageInfo.entityName], key, state,props)
                    }
                   props.hideLoader()

            }
            updateState("loading",false,true)
        })
        .catch((err) => {
            props.hideLoader()
            triggerToast(err.message)
            console.log(err);
            updateState("loading",false,true)
        });
}

const constructTransactionRequest_GL = async (state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer) =>{
    let request = await constructTransactionRequestV1(state, props, pagelevelValues, props.pageInfo.targetEventName,includePagelevelValues, metadataResponseFromReducer)
    return request
}

const deleteAdditionalCoverageInRequest = (addCovObj, request, stateObj) => {
    let newRequest = Object.assign({}, request);
    let addCovKeys = Object.keys(addCovObj)
    let entity = stateObj.pageMetadata.ApplicationDetail.SubApplicationEntity[stateObj.subappNameListState[0]]
    if(addCovKeys.length > 0){
        addCovKeys.forEach( key => {
            if(newRequest[entity].hasOwnProperty(key) && addCovObj.hasOwnProperty(key) && newRequest[entity][key].hasOwnProperty("ID") && addCovObj[key].hasOwnProperty("ID")){
                if(newRequest[entity][key]["ID"] === addCovObj[key]["ID"]){
                    delete newRequest[entity][key]
                }
            }
        })
    }
    if (newRequest[entity].hasOwnProperty("OptionalFormsSelectedList")){
        delete newRequest[entity]["OptionalFormsSelectedList"]
    }
    return newRequest
}

const getMetadataRequest = async (params, props, state, callback) => {
    props.showLoader();
    let subappNameList = params.subappNameListState
    const { pageInfo: { objectId, objectVerId } } = props;
    let transactionPrimaryObject = params.transactionPrimaryObject
    let stateString = params.stateListVal.toString()
    let replacedString
    if (stateString.includes(",")) {
        replacedString = stateString.replaceAll(",", '","')
        if (!params.stateListVal.includes(state)) {
            if (params.pagelevelValues.LocationDetail && params.pagelevelValues.LocationDetail.State) {
                state = params.pagelevelValues.LocationDetail.State
            }
            if (params.pagelevelValues.LocationDetail && params.pagelevelValues.LocationDetail.LocationState) {
                state = params.pagelevelValues.LocationDetail.LocationState
            }
        }
    }
    else {
        replacedString = stateString
        state = stateString
    }
    transactionPrimaryObject["StateInfo"] = {}
    transactionPrimaryObject["StateInfo"]["StateList"] = replacedString
    state = state && state.slice(0, state.indexOf('-'))
    await props.setprimaryState(state)
    const response = await constructAndInvokeMetadataCall({ subappName: subappNameList, objectId, objectVerId, state }, { transactionPrimaryObject, EffectiveDate: params.values.EffectiveDate }, callback)
    return response;
}
const componentDidUpdate_HomeOwners=async(props,state,UpdateState,prevState,prevProps)=>{
    const {addressCheckNeeded = true, addressValidate=true} = props.pageInfo;
    const LocationChangeCondition = (state.pagelevelValues.LocationDetail && prevState.pagelevelValues.LocationDetail && state.pagelevelValues.LocationDetail.LocationFullAddress!== prevState.pagelevelValues.LocationDetail.LocationFullAddress)

     if((LocationChangeCondition && !state.isValueChanged && addressCheckNeeded && addressValidate)){
        await InvokeAPICall_IneligibleRiskAddressCheck(props,state,UpdateState)
    }
     if(props.rowData && props.rowData.ID  && state.getLocationCall==true ) {
       await getLocationDetail(props,UpdateState,state.subappNameListState,{},state)
        UpdateState('getLocationCall',false,false)
     }
}

const componentDidUpdate_CL=async(props,state,UpdateState,prevState,prevProps)=>{
    if(props.rowData && props.rowData.ID  && state.getLocationCall==true ) {
      await getLocationDetail_CL(props,UpdateState,state.subappNameListState)
       UpdateState('getLocationCall',false,false)
    }
}

const componentDidUpdate_CLV1 = async (props, state, UpdateState, prevState, prevProps) => {
    try {
        if (props.rowData && props.rowData.ID && state.getLocationCall == true) {
            await getLocationDetail_CLV1(props, UpdateState, state.subappNameListState)
            UpdateState('getLocationCall', false, false)
        }
    }
    catch (err) {
        console.log("Error in componentDidUpdate_CLV1", err)
    }
}

const getAIRDistanceToCoast = async (props, state) => {
    try{
    let request = Object.create({});
    let property = {}
    let {Latitude, Longitude} = state.pagelevelValues.LocationDetail
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
    property = {
        Latitude : Latitude,
        Longitude : Longitude
    }
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.TargetEventName= props.pageInfo.getDTCEventName
    request["EventName"]=props.pageInfo.eventName
    request.Property = [property]
    let response = await LocationandBuildingAxios.post("core/invokeWorkflowEvent/V2", request)
    return response.data;
}
catch (err) {
    console.log("Error in DTC", err)
}
}

const getWildFireScore = async (props, state) => {
    try{
    let request = Object.create({});
    let property = {}
    let {StreetNumberAddress1 ,City,StateRegionCode,Zipcode1} = state.pagelevelValues && state.pagelevelValues.LocationDetail;
    let reduxState =store.getState()
    let SubmissionID = reduxState.UIFlowReducer.submissionID;
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
    property = {
        StreetAddress : StreetNumberAddress1,
        City : City,
        State:StateRegionCode,
        ZipCode:Zipcode1,
        SubmissionID:SubmissionID
    }
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["LOB"] = props.pageInfo.LineOfBusiness;
    request["EventName"]=props.pageInfo.getWildfireEventName;
    request.Property = [property]
    let response = await LocationandBuildingAxios.post("core/invokeWorkflowEvent/V2", request);
    return response.data;
}
    catch (err) {
        console.log("Error in Wildfire", err)
    }
}

export const InvokeAPICall_IneligibleRiskAddressCheckV1 = async (Methodprops,value, props, state, component, tpo) => {
    // props.functions.UpdateState('loading',true,true)
    const reduxState = store.getState()
    let{objectId,objectVerId}=props.pageInfo;
    let SubApplicationList= props.pageInfo["subApplication"]["locationDetail"]
    let locationPageValues = props.componentState.pagelevelValues.LocationDetail
    let detailSubApp = []
    let summarySubApp = []
    let subappNameList = []
    let detailSummaryList = {}
    subAppListConstruction(SubApplicationList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
    let transactionPrimaryObject = {}
    let request = Object.create({});
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.EventName= props.pageInfo.changeEventName
    request.Location=[locationPageValues];
    let eligibleValues = {}
    await LocationandBuildingAxios.post("/core/invokeWorkflowEvent", request)
        .then(async (response) => {
            let disableButton;
            if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                  triggerToast('Address Eligibility Check failed')
                  console.log('Response Failed')
                   return;
            }
            if(response.data.hasOwnProperty("IneligibilityMessage")){
                disableButton=true;
                eligibleValues = {LocationIneligible:"true",OverrideClearance:"false"}
                //await store.dispatch({ type: actionType.SET_LOCATION_DETAILS, payload:{LocationIneligible:true,OverrideClearance:false}});
            }
            else if(response.data.hasOwnProperty("OverrideMessage")){
                disableButton=false;
                eligibleValues = {LocationIneligible:"false",OverrideClearance:"true",BlockLocationSave:"true"}
                //await store.dispatch({ type: actionType.SET_LOCATION_DETAILS, payload:{LocationIneligible:false,OverrideClearance:true,BlockLocationSave:true}});
            }
            else{
                disableButton=false;
                eligibleValues = {LocationIneligible:"false",OverrideClearance:"false",BlockLocationSave:"false"}
                //await store.dispatch({ type: actionType.SET_LOCATION_DETAILS, payload:{LocationIneligible:false,OverrideClearance:false,BlockLocationSave:false}});
            }
            let distanceToCoast =props.pageInfo.hasOwnProperty("IsAIRDistanceToCoastNeeded") && props.pageInfo.IsAIRDistanceToCoastNeeded === true ?  await getAIRDistanceToCoast(props, props.componentState):{}
            let entitySubAppObject = await getEntitySubAppMappingV2({ subApplicationGroupList: [], objectId: props.pageInfo.objectId, objectVerId: props.pageInfo.objectVerId, subappName: subappNameList, state: 'CW'},'V2')
            let responseDTCTranslation = await EntitySubAppTranslationV2(distanceToCoast,  { "SubApplicationEntity": entitySubAppObject})
            let wildFireScore = props.pageInfo.hasOwnProperty("IsWildFireNeeded") && props.pageInfo.IsWildFireNeeded === true ? await getWildFireScore(props,props.componentState) : {};
            let wildFireTranslation = Object.keys(wildFireScore).length > 0 ? await EntitySubAppTranslationV2(wildFireScore,  { "SubApplicationEntity": entitySubAppObject}) : {}
            let getVeRiskDetails= props.pageInfo.hasOwnProperty("IsVeriskNeeded") && props.pageInfo.IsVeriskNeeded === true ?  await invokeVeRiskAPI(props,props.componentState):{}
            let responseVeriskTranslation = await EntitySubAppTranslationV2(getVeRiskDetails,  { "SubApplicationEntity": entitySubAppObject})
            console.log(responseVeriskTranslation,"Verisk")
            let values =await mergeResponseKeys(subappNameList,responseVeriskTranslation,responseDTCTranslation,wildFireTranslation)
            values.LocationDetail = {...values.LocationDetail,...locationPageValues}
            Object.assign(transactionPrimaryObject,{...values,...eligibleValues})
            props.functions.UpdateState("disableButton",disableButton, false)
            props.functions.UpdateState("pagelevelValues",values,false)
            props.functions.UpdateState("transactionPrimaryObject",transactionPrimaryObject,false)
          })
          .catch((err) => {
            triggerToast(err.message)
            console.log(err);
          });
          let metadataResponse=await constructAndInvokeMetadataCall({objectId,objectVerId,subappName:subappNameList},{transactionPrimaryObject:{...transactionPrimaryObject,...eligibleValues}})
          props.functions.UpdateState("pageMetadata",{"ApplicationDetail":metadataResponse.ApplicationDetail},false)
        //   props.functions.UpdateState('loading',false,true)
}

const InvokeAPICall_IneligibleRiskAddressCheck = async (props,state,UpdateState) => {
    UpdateState('loading',true,true)
    const reduxState = store.getState()
    let{objectId,objectVerId}=props.Template[0]
    let subappName=state.subappNameListState
    let locationPageValues = state.pagelevelValues.LocationDetail
    let{transactionPrimaryObject}=state
    let request = Object.create({});
    request["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
    );
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.EventName= props.pageInfo.changeEventName
    request.Location=[locationPageValues];
    let eligibleValues = {}
    const { inEligibileMessageNeeded =  true, overrideMessageNeeded = true } = props.pageInfo;
        await LocationandBuildingAxios.post("/core/invokeWorkflowEvent", request)
        .then(async (response) => {
            let disableButton;
            if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                  triggerToast('Address Eligibility Check failed')
                  console.log('Response Failed')
                   return;
            }
            if(response.data.hasOwnProperty("IneligibilityMessage") && inEligibileMessageNeeded){
                disableButton=true;
                eligibleValues = {LocationIneligible:"true",OverrideClearance:"false"}
                //await store.dispatch({ type: actionType.SET_LOCATION_DETAILS, payload:{LocationIneligible:true,OverrideClearance:false}});
            }
            else if(response.data.hasOwnProperty("OverrideMessage") && overrideMessageNeeded){
                disableButton=false;
                eligibleValues = {LocationIneligible:"false",OverrideClearance:"true",BlockLocationSave:"true"}
                //await store.dispatch({ type: actionType.SET_LOCATION_DETAILS, payload:{LocationIneligible:false,OverrideClearance:true,BlockLocationSave:true}});
            }
            else{
                disableButton=false;
                eligibleValues = {LocationIneligible:"false",OverrideClearance:"false",BlockLocationSave:"false"}
                //await store.dispatch({ type: actionType.SET_LOCATION_DETAILS, payload:{LocationIneligible:false,OverrideClearance:false,BlockLocationSave:false}});
            }
            let distanceToCoast =props.pageInfo.hasOwnProperty("IsAIRDistanceToCoastNeeded") && props.pageInfo.IsAIRDistanceToCoastNeeded === true ?  await getAIRDistanceToCoast(props, state):{}
            let entitySubAppObject = await getEntitySubAppMappingV2({ subApplicationGroupList: [], objectId: props.pageInfo.objectId, objectVerId: props.pageInfo.objectVerId, subappName, state: 'CW'},'V2')
            let responseDTCTranslation = distanceToCoast && Object.keys(distanceToCoast).length > 0 ? await EntitySubAppTranslationV2(distanceToCoast,  { "SubApplicationEntity": entitySubAppObject}) : {}
            let wildFireScore = props.pageInfo.hasOwnProperty("IsWildFireNeeded") && props.pageInfo.IsWildFireNeeded === true ? await getWildFireScore(props,state) : {};
            let wildFireTranslation = wildFireScore && Object.keys(wildFireScore).length > 0 ? await EntitySubAppTranslationV2(wildFireScore,  { "SubApplicationEntity": entitySubAppObject}) : {}
            let getVeRiskDetails= props.pageInfo.hasOwnProperty("IsVeriskNeeded") && props.pageInfo.IsVeriskNeeded === true ?  await invokeVeRiskAPI(props,state):{}
            let responseVeriskTranslation = await EntitySubAppTranslationV2(getVeRiskDetails,  { "SubApplicationEntity": entitySubAppObject})
            let values =await mergeResponseKeys(subappName,responseVeriskTranslation,responseDTCTranslation,wildFireTranslation)
            values.LocationDetail = {...values.LocationDetail,...locationPageValues}
            Object.assign(transactionPrimaryObject,{...values,...eligibleValues})
            UpdateState("disableButton",disableButton, false)
            UpdateState("pagelevelValues",values,false)
          })
          .catch((err) => {
            triggerToast(err.message)
            console.log(err);
          });
            let metadataResponse=await constructAndInvokeMetadataCall({objectId,objectVerId,subappName},{transactionPrimaryObject:{...transactionPrimaryObject,...eligibleValues}})
            UpdateState("pageMetadata",{"ApplicationDetail":metadataResponse.ApplicationDetail},false)
            UpdateState('loading',false,true)
}

const componentDidMount_HomeOwners = async (props, state, updateState) => {
    const redux = store.getState()
    const onLoad = props.pageInfo.onLoadMethod
    console.log("onLoad-->",onLoad)
    let { objectId, objectVerId } = props.pageInfo
    // let objectId="362363";
    // let objectVerId="361434"
    let { subApplicationGroupList } = props.pageInfo
    let detailList = props.pageInfo["subApplication"]["locationSummary"] + "Detail"
    console.log("detailList in homeowners util-->",detailList)
    let SubApplicationList = props.pageInfo["subApplication"]["locationDetail"]
    console.log("SubApplicationList in homeowners util-->",SubApplicationList)
    let detailSubApp = []
    let summarySubApp = []
    let subappNameList = []
    let detailSummaryList = {}
    subAppListConstruction(SubApplicationList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
    updateState("summarySubAppState", summarySubApp, false)
    updateState("subappNameListState", subappNameList, false)
    updateState("detailSummaryListState", detailSummaryList, false)

   let subappinfo = {subappName: subappNameList, objectId, objectVerId}
   console.log("state  in didmount util-->",state)
   props.pageInfo.utilfunction[props.pageInfo.util][onLoad](props, state, updateState, subApplicationGroupList, detailList, subappinfo)
}

const componentDidMount_CL = async (props, state, updateState) => {
    const redux = store.getState()
    const onLoad = props.pageInfo.onLoadMethod
    console.log("onLoad-->",onLoad)
    let { objectId, objectVerId } = props.pageInfo
    // let objectId="362363";
    // let objectVerId="361434"
    let { subApplicationGroupList } = props.pageInfo
    let detailList = props.pageInfo["subApplication"]["locationSummary"] + "Detail"
    console.log("detailList in CL util-->",detailList)
    let SubApplicationList = props.pageInfo["subApplication"]["locationDetail"]
    console.log("SubApplicationList in CL util-->",SubApplicationList)
    let detailSubApp = []
    let summarySubApp = []
    let subappNameList = []
    let detailSummaryList = {}
    subAppListConstruction(SubApplicationList, detailSummaryList, subappNameList, detailSubApp, summarySubApp)
    updateState("summarySubAppState", summarySubApp, false)
    updateState("subappNameListState", subappNameList, false)
    updateState("detailSummaryListState", detailSummaryList, false)

   let subappinfo = {subappName: subappNameList, objectId, objectVerId}
   console.log("state  in didmount util-->",state)
   props.pageInfo.utilfunction[props.pageInfo.util][onLoad](props, state, updateState, subApplicationGroupList, detailList, subappinfo)
}

const componentDidMountSummary_HomeOwners = (props,state,updatestate,handlemetadataCall) => {
    let modifyQuote =!('modifyQuote' in props.pageInfo) || props.pageInfo.modifyQuote ==='Y';
    if(props.UIFlowReducer.quoteID && modifyQuote){
        store.dispatch({
          type: actionType.SET_QUOTE_DETAILS,
          payload: {
          quoteStatus:"",
           quoteNumber:"",
             quoteID:""
          }
        });
        store.dispatch({
            type: actionType.SET_QUOTE_STATUS,
            payload: {
            quoteStatus:"",
            }
          });

      }
    invokeAPICall_Summary(props,state,updatestate,handlemetadataCall)
}


const componentDidMountSummary_HomeOwners_RateIndication = (props,state,updatestate,handlemetadataCall) => {
    let modifyQuote =!('modifyQuote' in props.pageInfo) || props.pageInfo.modifyQuote ==='Y';
    if(props.UIFlowReducer.quoteID && modifyQuote){
        store.dispatch({
          type: actionType.SET_QUOTE_DETAILS,
          payload: {
          quoteStatus:"",
           quoteNumber:"",
             quoteID:""
          }
        });
      }
    invokeAPICall_Summary_RateIndication(props,state,updatestate,handlemetadataCall)
}


const componentDidMountSummary_CL = (props,state,updatestate,handlemetadataCall) => {
    if(props.UIFlowReducer.quoteID){
        store.dispatch({
          type: actionType.SET_QUOTE_DETAILS,
          payload: {
          quoteStatus:"",
           quoteNumber:"",
             quoteID:""
          }
        });
        store.dispatch({
            type: actionType.SET_QUOTE_STATUS,
            payload: {
            quoteStatus:"",
            }
          });
      }
    invokeAPICall_Summary_CL(props,state,updatestate,handlemetadataCall)
}

const invokeAPICall_Summary_CL = async (props,state,updatestate,handlemetadataCall) => {
    props.showLoader()
    const { subApplication, entityName } = props.pageInfo;
    let request = {};
    request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request["OwnerId"] = sessionStorage.getItem("OwnerID")
    await props.pageInfo.utilfunction[props.pageInfo.util].getRequestContruction(request,props);

    LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async(response) => {
            console.log("[Aju]Responsee summary==>", response)
            props.hideLoader()
                if(!response.data[entityName] || response.data[entityName].length <= 0){
                    await store.dispatch({
                        type: actionType.SET_HASLOCATION,
                        payload: true
                    });
                }
                else if (response.data[entityName]) {
                    if (response.data[entityName].length > 0) {
                        await store.dispatch({
                            type: actionType.SET_HASLOCATION,
                            payload: false
                        });
                        let parentHierarchyArr = state.parentHierarchyArr
                        parentHierarchyArr = props.metadataReducer.iconcondition ? props.metadataReducer.iconcondition : []
                        if (!parentHierarchyArr.includes(props.pageInfo.subApplication.locationSummary.toLowerCase())) {
                            parentHierarchyArr.push(props.pageInfo.subApplication.locationSummary.toLowerCase())
                        }
                        props.setIconcondition(parentHierarchyArr)
                    }
                }
                // updatestate("values", { ...state.values, ...response.data[entityName] }, true);
                // updatestate("pagelevelValues",  { ...state.pagelevelValues, ...response.data[entityName] }, true);
                // this.setState({
                //     values: { ...this.state.values, ...response.data[entityName] },
                //     pagelevelValues: { ...this.state.pagelevelValues, ...response.data[entityName] }
                // }, async () => {

                    let responseEntity = response.data[entityName]
                    let idArray = []
                    if (responseEntity) {
                        await CL_Location['setParentHierarchyId'](responseEntity, props, idArray);
                    }
                    if (Array.isArray(response.data[entityName])) {
                        let subapplicationName = subApplication['locationSummary'].toLowerCase();
                        let dataListName = subapplicationName + "_dataList";
                        console.log("dataListName", dataListName, entityName, subapplicationName, entityName.includes(subapplicationName))
                        let dataListValue = response.data[entityName]
                        console.log("arryyyyyyy", dataListName, dataListValue)
                        updatestate(dataListName, dataListValue, true);
                        // }
                    }
                    handlemetadataCall();
                //})
        })
        .catch((err) => {
            console.log(err);
            triggerToast(err.message)
        });
}

const componentDidMountSummary_CLV1 = (props,state,updatestate,handlemetadataCall) => {
    try{
    if(props.UIFlowReducer.quoteID){
        store.dispatch({
          type: actionType.SET_QUOTE_DETAILS,
          payload: {
          quoteStatus:"",
           quoteNumber:"",
             quoteID:""
          }
        });
        store.dispatch({
            type: actionType.SET_QUOTE_STATUS,
            payload: {
            quoteStatus:"",
            }
          });
      }
    invokeAPICall_Summary_CLV1(props,state,updatestate,handlemetadataCall)
}
catch (err) {
    console.log("Error in componentDidMountSummary_CLV1", err)
}
}

const invokeAPICall_Summary_CLV1 = async (props,state,updatestate,handlemetadataCall) => {
    try{
    props.showLoader()
    const redux = store.getState();
    const { subApplication,entityName } = props.pageInfo;
    let request = {};
    request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request["OwnerId"] = sessionStorage.getItem("OwnerID")
    await props.pageInfo.utilfunction[props.pageInfo.util].getRequestContruction(request,props);
    request["ROOTID"] = redux.UIFlowReducer.submissionID
    LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
    .then(async(response) => {
        props.hideLoader()
            if(!response.data[entityName] || response.data[entityName].length <= 0){
                await store.dispatch({
                    type: actionType.SET_HASLOCATION,
                    payload: true
                });
            }
            else if (response.data[entityName]) {
                if (response.data[entityName].length > 0) {
                    await store.dispatch({
                        type: actionType.SET_HASLOCATION,
                        payload: false
                    });
                    let parentHierarchyArr = state.parentHierarchyArr
                    parentHierarchyArr = props.metadataReducer.iconcondition ? props.metadataReducer.iconcondition : []
                    if (!parentHierarchyArr.includes(props.pageInfo.subApplication.locationSummary.toLowerCase())) {
                        parentHierarchyArr.push(props.pageInfo.subApplication.locationSummary.toLowerCase())
                    }
                    props.setIconcondition(parentHierarchyArr)
                }
            }
                let responseEntity = response.data[entityName]
                let idArray = []
                if (responseEntity) {
                    await CL_LocationV1['setParentHierarchyId'](responseEntity, props, idArray);
                }
                if (Array.isArray(response.data[entityName])) {
                    let subapplicationName = subApplication['locationSummary'].toLowerCase();
                    let dataListName = subapplicationName + "_dataList";
                    let dataListValue = response.data[entityName]
                    updatestate(dataListName, dataListValue, true);
                }
                handlemetadataCall();
    })
        .catch((err) => {
            console.log(err);
            triggerToast(err.message)
        });
    }
    catch (err) {
        console.log("Error in invokeAPICall_Summary_CLV1", err)
    }
}

const componentDidMountSummary_ClaimsHistoryAndAdditionalInterest = (props,state,updatestate,handlemetadataCall) =>  {
    // const { subApplication, entityName } = props.pageInfo;
    // let subapplicationName = subApplication['locationSummary'].toLowerCase();
    // let dataListName = subapplicationName + "_dataList";
    // console.log("props in didmount util claimshistory-->",props,state)
    // updatestate(dataListName,props.rowData.Building[0][entityName],true);
}

const invokeAPICall_Summary = async (props,state,updatestate,handlemetadataCall) => {
    props.showLoader()
    const { subApplication, entityName } = props.pageInfo;
    let request = {};
    request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request["OwnerId"] = sessionStorage.getItem("OwnerID")
    await props.pageInfo.utilfunction[props.pageInfo.util].getRequestContruction(request,props);
    console.log("[karthuu]request-->", request)
    LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async(response) => {
            console.log("[karthuu]Responsee summary==>", response)
            props.hideLoader()
            await store.dispatch({type: actionType.SET_INSUREDID, payload: response.data.InsuredID});
            await store.dispatch({type: actionType.SET_SUBMISSION_NUMBER,payload: response.data.SubmissionNumber});
            if(response.data && response.data.InsuredAddress){
                await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {'insuredAddress': response.data.InsuredAddress} })
            }
            props.UpdateState("pagelevelValues",{...props.pagelevelValues, ...response.data,...props.metadataReducer}, true);
            props.UpdateState("transactionPrimaryObject",{...props.transactionPrimaryObject, ...response.data}, true);
            await store.dispatch({type: actionType.SET_EXPANDPANEL_DATA,payload: true});
                if(!response.data[entityName] || response.data[entityName].length <= 0){
                    await store.dispatch({
                        type: actionType.SET_HASLOCATION,
                        payload: true
                    });
                }
                else if (response.data[entityName]) {
                    if(response.data.hasOwnProperty("IsUnderWritingDetailsAvailable") && response.data.IsUnderWritingDetailsAvailable==="No"){
                        await store.dispatch({
                            type: actionType.SET_HASUNDERWRITINGDETAILS,
                            payload: true
                        });
                    }  else if(response.data.IsUnderWritingDetailsAvailable==="Yes"){
                        await store.dispatch({
                            type: actionType.SET_HASUNDERWRITINGDETAILS,
                            payload: false
                        });
                    }
                    if (response.data[entityName].length > 0) {
                        await store.dispatch({
                            type: actionType.SET_HASLOCATION,
                            payload: false
                        });
                        let parentHierarchyArr = state.parentHierarchyArr
                        parentHierarchyArr = props.metadataReducer.iconcondition ? props.metadataReducer.iconcondition : []
                        if (!parentHierarchyArr.includes(props.pageInfo.subApplication.locationSummary.toLowerCase())) {
                            parentHierarchyArr.push(props.pageInfo.subApplication.locationSummary.toLowerCase())
                        }
                        props.setIconcondition(parentHierarchyArr)
                    }
                }
                // updatestate("values", { ...state.values, ...response.data[entityName] }, true);
                // updatestate("pagelevelValues",  { ...state.pagelevelValues, ...response.data[entityName] }, true);
                // this.setState({
                //     values: { ...this.state.values, ...response.data[entityName] },
                //     pagelevelValues: { ...this.state.pagelevelValues, ...response.data[entityName] }
                // }, async () => {

                    let responseEntity = response.data[entityName]
                    let idArray = []
                    if (responseEntity) {
                        await props.pageInfo.utilfunction[props.pageInfo.util]['setParentHierarchyId'](responseEntity, props, idArray);
                    }
                    if (Array.isArray(response.data[entityName])) {
                        let subapplicationName = subApplication['locationSummary'].toLowerCase();
                        let dataListName = subapplicationName + "_dataList";
                        console.log("dataListName", dataListName, entityName, subapplicationName, entityName.includes(subapplicationName))
                        let dataListValue = response.data[entityName]
                        console.log("arryyyyyyy", dataListName, dataListValue)
                        updatestate(dataListName, dataListValue, true);
                        // }
                    }
                    handlemetadataCall();
                    store.dispatch({type: actionType.SET_PAGEHEADER_UPDATE,payload: true});
                // })
        })
        .catch((err) => {
            console.log(err);
            triggerToast("Error in response")
        });
}


const invokeAPICall_Summary_RateIndication = async (props,state,updatestate,handlemetadataCall) => {
    props.showLoader()
  const { subApplication, entityName } = props.pageInfo;
  let request = {};
  request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request["OwnerId"] = sessionStorage.getItem("OwnerID")
    await props.pageInfo.utilfunction[props.pageInfo.util].getRequestContruction(request,props);
  await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async(response) => {
            console.log("[karthuu]Responsee summary==>", response)
            props.hideLoader()

            await store.dispatch({type: actionType.SET_INSUREDID, payload: response.data.InsuredID});
            await store.dispatch({type: actionType.SET_SUBMISSION_NUMBER,payload: response.data.SubmissionNumber});
            props.UpdateState("pagelevelValues",{...props.pagelevelValues, ...response.data,...props.metadataReducer}, false);
            props.UpdateState("transactionPrimaryObject",{...props.transactionPrimaryObject, ...response.data}, false);

                if(!response.data[entityName] || response.data[entityName].length <= 0){
        await store.dispatch({
          type: actionType.SET_HASLOCATION,
          payload: true
        });
      }
      else if (response.data[entityName]) {
        await store.dispatch({
          type: actionType.SET_HASUNDERWRITINGDETAILS,
          payload: false
        });
        if (response.data[entityName].length > 0) {
          await store.dispatch({
            type: actionType.SET_HASLOCATION,
            payload: false
          });
                        let parentHierarchyArr = state.parentHierarchyArr
                        parentHierarchyArr = props.metadataReducer.iconcondition ? props.metadataReducer.iconcondition : []
          if (!parentHierarchyArr.includes(props.pageInfo.subApplication.locationSummary.toLowerCase())) {
                            parentHierarchyArr.push(props.pageInfo.subApplication.locationSummary.toLowerCase())
          }
                        props.setIconcondition(parentHierarchyArr)
        }
      }
      // updatestate("values", { ...state.values, ...response.data[entityName] }, true);
      // updatestate("pagelevelValues",  { ...state.pagelevelValues, ...response.data[entityName] }, true);
      // this.setState({
      //     values: { ...this.state.values, ...response.data[entityName] },
      //     pagelevelValues: { ...this.state.pagelevelValues, ...response.data[entityName] }
      // }, async () => {

                    let responseEntity = response.data[entityName]
                    let idArray = []
      if (responseEntity) {
        await props.pageInfo.utilfunction[props.pageInfo.util]['setParentHierarchyId'](responseEntity, props, idArray);
      }
      if (Array.isArray(response.data[entityName])) {
        let subapplicationName = subApplication['locationSummary'].toLowerCase();
        let dataListName = subapplicationName + "_dataList";
                        console.log("dataListName", dataListName, entityName, subapplicationName, entityName.includes(subapplicationName))
                        let dataListValue = response.data[entityName]
                        console.log("arryyyyyyy", dataListName, dataListValue)
        updatestate(dataListName, dataListValue, true);
        // }
      }
      handlemetadataCall();
                   await store.dispatch({type: actionType.SET_EXPANDPANEL_DATA,payload: true});
      // })
    })
    .catch((err) => {
      console.log(err);
            triggerToast("Error in response")
    });
}

const componentWillReceiveProps_ClaimsHistory = (props, nextprops,updatestate) => {
    console.log("will receive propsss nandhu---->",!props.parentProps.pagelevelValues.hasOwnProperty(props.pageInfo.entityName),nextprops.parentProps.pagelevelValues.hasOwnProperty(props.pageInfo.entityName))
    if(!props.parentProps.pagelevelValues.hasOwnProperty(props.pageInfo.entityName) && nextprops.parentProps.pagelevelValues.hasOwnProperty(props.pageInfo.entityName)){
    const { subApplication, entityName } = props.pageInfo;
    let subapplicationName = subApplication['locationSummary'].toLowerCase();
    let dataListName = subapplicationName + "_dataList";
    console.log("props in will receive props util claimshistory-->",props,nextprops)
    updatestate(dataListName,nextprops.parentProps.pagelevelValues[entityName],true);
    }
}

const constructTransactionRequest_AdditionalInterest = async (state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer, callback, key,updateState) =>{
  try{
  updateState("loading",true,true)
  const redux = store.getState();
     let request = await constructTransactionRequest(state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer)
        request.PARENTID = props.parentProps.pagelevelValues.Building.ID;
        request.ROOTID = props.UIFlowReducer.insuredID;
        request.ID = props.rowData.ID ? props.rowData.ID : "";
        request.SubmissionID = props.UIFlowReducer.submissionID;
        request.TargetEventName = props.pageInfo.targetEventName
        request.EventName= props.pageInfo.eventName
        request.ProductVerNumber = redux.UIFlowReducer.productDetails.ProductVerNumber;
        request.ProductNumber = redux.UIFlowReducer.productDetails.ProductNumber;
        LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async (response) => {
            if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                throw new Error('')
            }
            else{
                updateState("values", { ...state.values, ...response.data[props.pageInfo.entityName] }, true)
                if (props.pageInfo.parentHierarchy) {
                        callback(response.data[props.pageInfo.entityName], key, state,props)
                    }
                   props.hideLoader()

            }
        })
        .catch((err) => {
            props.hideLoader()
            triggerToast(err.message)
            console.log(err);
        }).finally(()=>updateState("loading",false,true))
      }catch(err){
        console.log('Error in constructTransactionRequest_AdditionalInterest',err)
      }
}

const constructTransactionRequest_Building = async (state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer, callback, key,updateState) =>{
  try{
  updateState("loading",true,true)
     let request = await constructTransactionRequest(state, props, pagelevelValues, includePagelevelValues, metadataResponseFromReducer)
        request.PARENTID = props.parentProps.pagelevelValues.LocationDetail.ID;
        request.ROOTID = props.UIFlowReducer.insuredID;
        request.ID = props.rowData.ID ? props.rowData.ID : "";
        request.TargetEventName = props.pageInfo.targetEventName
        request.EventName= props.pageInfo.eventName

        LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async (response) => {
            if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                throw new Error('')
            }
            else{
                updateState("values", { ...state.values, ...response.data[props.pageInfo.entityName] }, true)
                if (props.pageInfo.parentHierarchy) {
                        callback(response.data[props.pageInfo.entityName], key, state,props)
                    }
                   props.hideLoader()

            }
        })
        .catch((err) => {
            props.hideLoader()
            triggerToast(err.message)
            console.log(err);
        }).finally(()=>updateState("loading",false,true))
      }catch(err){
        console.log('Error in constructTransactionRequest_Building',err)
      }
}





const onAddRow_HomeOwners = (props, updateArrayValueOnState, key,Length) => {
    console.log('coming inside onaddrow HO');
    // if (props.metadataReducer.iconcondition && props.pageInfo.parentHierarchy) {
    //     if (props.metadataReducer.iconcondition.includes(props.pageInfo.parentHierarchy)) {
    //         let uid = uuidv4();
    //         let conditionKey = props.pageInfo.entityName + uid
    //         // console.log(ID)
    //         let conditions = Object.assign({}, props.metadataReducer.showHideCondition, {[conditionKey]:false})

    //         props.setShowHideCondition(conditions)
    //         updateArrayValueOnState(key, { uid, isExpanded: true }, true);
    //     }
    //     else {
    //         triggerToast('Please save ' + props.pageInfo.parentHierarchy + ' before adding ' + props.pageInfo["subApplication"]["locationSummary"], { position: Toast.POSITION.TOP_CENTER })
    //     }
    // }
    // else {
    //     if (props.pageInfo.parentHierarchy) {
    //         triggerToast('Please save ' + props.pageInfo.parentHierarchy + ' before adding ' + props.pageInfo["subApplication"]["locationSummary"], { position: Toast.POSITION.TOP_CENTER })
    //     }
    //     else {
            let uid = uuidv4();
            let conditionKey = props.pageInfo.entityName + uid
            let conditions = Object.assign({}, props.metadataReducer.showHideCondition, {[conditionKey]:false})
            props.setShowHideCondition(conditions)
            updateArrayValueOnState(key, { uid, isExpanded: true }, true);
            focusScroll(key,Length);
    //     }
    // }
}

const focusScroll = (key,Length)=>{

    var element = document.getElementsByClassName("FocusAnchor_"+key)[Length-1];

   element && element.scrollIntoView({
       behavior: "smooth",
       block : "start"
       });


  }

const deleteLossHistory = async (props,deleterowid) =>{
    const redux = store.getState();
    let request = {};
    let  deleteRowresponse;
    request["ServiceRequestDetail"] = Object.assign({},getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.ROOTID = redux.UIFlowReducer.insuredID;
    request.SubmissionID= props.UIFlowReducer.submissionID
    request.PARENTID = props.parentProps.pagelevelValues.Building.ID
    request.ID = deleterowid
    request.EventName = props.pageInfo.eventName;
    request.TargetEventName = props.pageInfo.deleteEventName;
    console.log("deleteRow requesttt--->",request)

    await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request).then((response) => {
        deleteRowresponse = response.data
        console.log("deleteRowresponse--->",deleteRowresponse)
    })
    return deleteRowresponse
}

const deleteBuilding = async (props,deleterowid) =>{
    const redux = store.getState();
    let request = {};
    let  deleteRowresponse;
    request["ServiceRequestDetail"] = Object.assign({},getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.PARENTID= props.componentState.parentRowData.ID
    request.ROOTID= props.componentProps.propsValue.UIFlowReducer.insuredID
    request.ID = deleterowid
    request.EventName = props.pageInfo.eventName;
    request.TargetEventName = props.pageInfo.deleteEventName;
    request["EntityName"] = props.pageInfo.entityName
    console.log("deleteRow requesttt--->",request)

    await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request).then((response) => {
    deleteRowresponse = response.data
    })
    return deleteRowresponse
}

const invokeVeRiskAPI = async (props,state) =>{
    try{
    let request = {};
    request["ServiceRequestDetail"] = getServiceRequestDetail()
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["TargetEventName"]=props.pageInfo.getVeRiskEventName
    request["EventName"]=props.pageInfo.eventName
    request.property=[{...state.pagelevelValues.LocationDetail,"ID":props.UIFlowReducer.insuredID}]
    if (request.property[0].VeriskCalculatedRcv && request.property[0].VeriskValuationId) {
        delete request.property[0].VeriskValuationId
        delete request.property[0].VeriskCalculatedRcv
    }
    let response = await LocationandBuildingAxios.post("core/invokeWorkflowEvent/V2", request)
    return response.data
}catch(e){
    console.log("Error in invokeVeRiskAPI",e);
}
}
const onOverideClearance = async(value,component,isDynamicTable,datatableRowindex,props)=>{
    try{
        let currentTime = new Date();
      await  props.handleInputChangeV2(value, component,isDynamicTable,datatableRowindex,props);
        if(value[component.AttributeName] === 'Yes'){
            await store.dispatch({type:actionType.SET_OVERIDE_CLEARANCE_DATE,payload: currentTime});
            props.functions.UpdateState("transactionPrimaryObject",{...props.transactionPrimaryObject,...{BlockLocationSave:"false",OverrideClearance:"true"}},false)
        }else{
            props.functions.UpdateState("transactionPrimaryObject",{...props.transactionPrimaryObject,...{BlockLocationSave:"true",OverrideClearance:"true"}},false)
        }
    }catch(e){
        console.log("Error in onOverideClearance",e);
    }
}
const CondoVeriskCheck =async (value,component,isDynamicTable,datatableRowindex,props)=> {
    try{
    const reduxState = store.getState()
    props.functions.UpdateState('loading',true,true)
    let subappName=props.componentProps.subappNameListState
    let distanceToCoast = props.pageInfo.hasOwnProperty("IsAIRDistanceToCoastNeeded") && props.pageInfo.IsAIRDistanceToCoastNeeded === true ?  await getAIRDistanceToCoast(props.componentProps,props.componentState):{}
    let entitySubAppObject = await getEntitySubAppMappingV2({ subApplicationGroupList: [], objectId: props.pageInfo.objectId, objectVerId: props.pageInfo.objectVerId, subappName, state: 'CW'},'V2')
    let responseDTCTranslation = await EntitySubAppTranslationV2(distanceToCoast,  { "SubApplicationEntity": entitySubAppObject})
    let wildFireScore = props.pageInfo.hasOwnProperty("IsWildFireNeeded") && props.pageInfo.IsWildFireNeeded === true ?  await getWildFireScore(props,props.componentState) : {};
    let wildFireTranslation = Object.keys(wildFireScore).length > 0 ? await EntitySubAppTranslationV2(wildFireScore,  { "SubApplicationEntity": entitySubAppObject}) : {}
    let {pagelevelValues, transactionPrimaryObject}= await handleInputChangeV2(value, props.component, props.componentProps,props.componentState,props.functions, props)
    let getVeRiskDetails= props.pageInfo.hasOwnProperty("IsVeriskNeeded") && props.pageInfo.IsVeriskNeeded === true ?  await invokeVeRiskAPI(props.componentProps, props.componentState):{}
    let responseVeriskTranslation = await EntitySubAppTranslationV2(getVeRiskDetails,  { "SubApplicationEntity": entitySubAppObject})
    let values=await mergeResponseKeys(subappName,responseDTCTranslation,responseVeriskTranslation,wildFireTranslation)
    values.LocationDetail = {...values.LocationDetail,...pagelevelValues.LocationDetail}
    Object.assign(transactionPrimaryObject,{...values})
    let metadata = await metaDataCall (props, subappName ,transactionPrimaryObject,"")
    props.functions.UpdateState("pagelevelValues",values,false)
    props.functions.UpdateState("transactionPrimaryObject",transactionPrimaryObject,false)
    props.functions.UpdateState("pageMetadata",metadata,false)
    props.functions.UpdateState('loading',false,true)
  }catch(e){
    props.functions.UpdateState('loading',false,true)
    console.log("Error in CondoVeriskCheck",e);
}
}
 export const mergeResponseKeys=async(subapps,...rest)=>{
    let obj={};
    subapps.forEach(subapp=>{
		let length=rest.length
        for(let i=0;i<length;i++){
        Object.keys(rest[i]).forEach(response=>{
            if(subapp==response){
                if(obj[subapp]===undefined){
                obj[subapp]=rest[i][response];
                }
                else{
                    obj[subapp]={...obj[subapp],...rest[i][response]}
                }
            }
        })
        }
    })
	return obj;
}

export const getDataListWithExpand = (response, dataList,state,props) => {
    try{
    if (props.rowData && props.rowData.ID) {
        response.filter((val, index) => {
            if (val.ID === props.rowData.ID) {
                response[index]['isExpanded'] = true
            }
        })
    } else {
        dataList.filter((val, index) => {
            if (!val.ID) {
                response[index]['isExpanded'] = true
            }
        })
}
    return response
}catch(error){
    console.log('Error in response',error)
    throw new Error('Error in response')
}
}

const handleRowLevelInputChange = (value, component, datatableRowindex, props) => {
  try {
    let keys = Object.keys(value);
    let attributeName = keys[0];
    let attributeValue = value[attributeName].toString();

    let tablename = component.SubApplicationName.toLowerCase() + "_dataList";
    props.functions.saveTableData(tablename, attributeName, attributeValue, datatableRowindex);
  } catch (error) {
    console.log("handleRowLevelInputChange error", error);
  }
}



const toPickPrimaryLocation = async (value,component, isDynamicTable,datatableRowindex,props,state)=>{
  try{
    props.UpdateState('loading',true,true)

    let requestDetails = {
      OwnerId : sessionStorage.getItem("OwnerID"),
      EventName :  props.pageInfo.selectPrimaryLocation,
      TargetEventName : props.pageInfo.selectPrimaryLocation,
      ID : props.customTableRowIndex.ID,
    }
    let tableName = component.SubApplicationName.toLowerCase() +'_dataList'
    handleRowLevelInputChange(value,component, props.customTableRowIndex, props)
    let request =Object.create({})
    request["ServiceRequestDetail"] = getServiceRequestDetail()
    Object.assign(request,requestDetails)
    await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
        .then(async (response) => {
            if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
                throw new Error('')
            }
            else{
                props.UpdateState(tableName,response.data.Location,false)
            }
        })
    // let tableData = props.dataList.map(data=> {
    //    if(data.ID ===props.customTableRowIndex.ID){
    //       data.PrimaryLocation='Yes'
    //    } else{
    //     data.PrimaryLocation='No'
    //    }
    //    return data
    // })

    props.UpdateState('loading',false,true)
  } catch(err){
    console.log('toPickPrimaryLocation error',err)
    triggerToast(err.message)
    props.UpdateState('loading',false,true)
    }
  }

const quoteListTableUtils = {
    "AddRow": null,
    "onExpand": null,
    "onChildSave": null,
    "EditRow": null,
    "OnDetailEdit": "",
    "redirectTo": ""
  }

  const quoteListTableOptions =  {
    "ChildrenSubApplicationName": null,
    "ChildrenComponent":'templaterendering',
    "AddButtonNeeded": false,
    "SortingNeeded": false,
    "FilteringNeeded": false,
    "rowsPerPageOptions": [5, 10, 25],
    "RowsPerPage": 5,
    "ActionName": "",
    "ActionListOptions": []
  }

const onExpand = (key, value, isExpand, props) => {
    try {
        let { ID, uid } = value;
        let checkKey = ID ? "ID" : uid ? "uid" : null;
        if (checkKey !== null) {
            let arr = props.componentState[key];
            arr.forEach((el, idx) => {
                if (el[checkKey] === value[checkKey]) {
                    arr[idx]["isExpanded"] = isExpand.toString();
                    return arr;
                }
            });
            props.UpdateState(`${key}`, arr, true)
        }
    }
    catch (err) {
        console.log("Error in onExpand", err)
    }
};

export const ChangeClaimsDate=async(props,value)=>{
    try{
        let {transactionPrimaryObject}=await handleInputChangeV2(value, props.component, props.componentProps,props.componentState,props.functions)
        let metadata = await metaDataCall (props.componentProps, props.componentState.subappNameListState || props.component.SubApplicationName ,{...transactionPrimaryObject},"")
        props.functions.UpdateState("pageMetadata",metadata,false);
        await props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex, props);
    }catch(e){
    console.log("Error in ChangeClaimsDate",e);
    }
}
const callMetadata=(props)=>{
    try{
        props.callMetadata && props.callMetadata();
    }catch(e){
    console.log("Error in callMetadata",e);
    }
}

const locationOnExit = async (response,propsObj,stateObj,e) =>{
    propsObj.componentProps.UpdateState(propsObj.component.Action.DataList, response.Location, true)
}

 export const RiskSchedule_CF = {
    "componentDidMount": componentDidMount_CF,
    "setParentHierarchyId": setParentHierarchyId,
    "getRequestContruction": getRequestConstruction,
    "setHierarchyIds": setHierarchyIds,
    "setStateID": setStateID,
    "onAddRow" : onAddRow_CF,
    "setStateList":setStateList_CF,
    "handleInputChangeV2": handleInputChangeV2_CF,
    "copyValue": copyValue_CF,
    "transform":transform_GL,
    "constructTransactionRequest": constructTransactionRequest_CF,
    "deleteAdditionalCoverageInRequest": deleteAdditionalCoverageInRequest,
    "getMetadataRequest": getMetadataRequest
}

export const RiskSchedule_GL = {
    "setParentHierarchyId": setParentHierarchyId_GL,
    "getRequestContruction": getRequestConstruction_GL,
    "setHierarchyIds": setHierarchyIds_GL,
    "setStateID": setStateID_GL,
    "onAddRow" : onAddRow_GL,
    "componentDidMount": componentDidMount_GL,
    "setStateList":setStateList_GL,
    "setParentValues": setParentValues_GL,
    "handleInputChangeV2": handleInputChangeV2_GL,
    "transform":transform_GL,
    "constructTransactionRequest": constructTransactionRequest_GL,
    "deleteAdditionalCoverageInRequest": deleteAdditionalCoverageInRequest,
    "getMetadataRequest": getMetadataRequest
}

export const Location_HomeOwners = {
    "componentDidMount": componentDidMount_HomeOwners,
    "componentDidUpdate":componentDidUpdate_HomeOwners,
    "onAddRow" : onAddRow_HomeOwners,
    "deleteRow": deletePersonalProperty,
    "handleInputChangeV2": handleInputChangeV2_GL,
    "constructTransactionRequest": constructTransactionRequest_Location,
    "getRequestContruction": getRequestConstruction_HomeOwners,
    "setHierarchyIds": setHierarchyIds_HomeOwners,
     "transform":transform_GL,
     "setParentHierarchyId": setParentHierarchyId_HomeOwners,
     "parentOnLoad" : parentOnLoad_HomeOwners,
     "getMetadataRequest": getMetadataRequest,
     "componentDidMount_Summary": componentDidMountSummary_HomeOwners,
     "getCoordinates" : getCoordinates,
     "locationdetail":{
        CondoVeriskCheck,
        getCoordinates,
        onOverideClearance
        // InvokeAPICall_IneligibleRiskAddressCheckV1
     },
     "claimshistorydetail":{
        ChangeClaimsDate,
        callMetadata
     },
     "getDataListWithExpand": getDataListWithExpand,
}

export const LocationUtil={
    CondoVeriskCheck,
    getCoordinates,
    getCoordinatesV2,
    onOverideClearance,
    ChangeClaimsDate,
    callMetadata,
    locationOnExit
}

export const Location_HomeOwners_1 = {
    "componentDidMount": componentDidMount_HomeOwners,
    "componentDidUpdate":componentDidUpdate_HomeOwners,
    "onAddRow" : onAddRow_HomeOwners,
    "deleteRow": deletePersonalProperty,
    "handleInputChangeV2": handleInputChangeV2_GL,
    "constructTransactionRequest": constructTransactionRequest_Location_RateIndication,
    "getRequestContruction": getRequestConstruction_HomeOwners_RateIndication,
    "setHierarchyIds": setHierarchyIds_HomeOwners_RateIndication,
     "transform":transform_GL,
     "setParentHierarchyId": setParentHierarchyId_HomeOwners,
     "parentOnLoad" : parentOnLoad_HomeOwners,
     "getMetadataRequest": getMetadataRequest,
     "componentDidMount_Summary": componentDidMountSummary_HomeOwners_RateIndication,
     "getCoordinates" : getCoordinates,
     "locationdetail":{
        CondoVeriskCheck,
        getCoordinates,
        onOverideClearance
        // InvokeAPICall_IneligibleRiskAddressCheckV1
     },
     "claimshistorydetail":{
        ChangeClaimsDate,
        callMetadata
     },
     "getDataListWithExpand": getDataListWithExpand,
}

export const HomeOwners_LocationDetail = {
    "TableOptions":{
    "ChildrenSubApplicationName": null,
    "ChildrenComponent": "childComponent",
    "AddButtonNeeded": true,
    "SortingNeeded": false,
    "FilteringNeeded": false,
    "rowsPerPageOptions": [5, 10, 25],
    "RowsPerPage": 5,
    "ActionName": "Action",
    "ActionListOptions": []
    }
}

export const ClaimsHistory_HomeOwners = {

    "componentDidMount": componentDidMount_HomeOwners,
    "componentDidUpdate":componentDidUpdate_HomeOwners,
    "onAddRow" : onAddRow_HomeOwners,
    "handleInputChangeV2": handleInputChangeV2_GL,
    "constructTransactionRequest": constructTransactionRequest_ClaimsHistory,
   "getRequestContruction": getRequestConstruction_HomeOwners,
   "setHierarchyIds": setHierarchyIds_HomeOwners,
     "transform":transform_GL,
     "setParentHierarchyId": setParentHierarchyId_HomeOwners,
     "parentOnLoad" : parentOnLoad_ClaimsHistory,
     "getMetadataRequest": getMetadataRequest,
     "componentDidMount_Summary": componentDidMountSummary_ClaimsHistoryAndAdditionalInterest,
     "componentWillReceiveProps" : componentWillReceiveProps_ClaimsHistory,
     "deleteRow" : deleteLossHistory,
     "getDataListWithExpand": getDataListWithExpand,
}

export const AdditionalInterest_HomeOwners = {

    "componentDidMount": componentDidMount_HomeOwners,
    "componentDidUpdate":componentDidUpdate_HomeOwners,
    "onAddRow" : onAddRow_HomeOwners,
    "handleInputChangeV2": handleInputChangeV2_GL,
    "constructTransactionRequest": constructTransactionRequest_AdditionalInterest,
    "getRequestContruction": getRequestConstruction_HomeOwners,
    "setHierarchyIds": setHierarchyIds_HomeOwners,
    "parentOnLoad" : parentOnLoad_ClaimsHistory,
     "transform":transform_GL,
     "getMetadataRequest": getMetadataRequest,
     "setParentHierarchyId": setParentHierarchyId_HomeOwners,
     "deleteRow" : deleteLossHistory,
     "componentWillReceiveProps" : componentWillReceiveProps_ClaimsHistory,
     "componentDidMount_Summary": componentDidMountSummary_ClaimsHistoryAndAdditionalInterest,
     "getDataListWithExpand": getDataListWithExpand,
}

export const CL_LocationDetail = {
    "TableOptions":{
    "ChildrenSubApplicationName": null,
    "ChildrenComponent": "childComponent",
    "AddButtonNeeded": true,
    "SortingNeeded": false,
    "FilteringNeeded": false,
    "rowsPerPageOptions": [5, 10, 25],
    "RowsPerPage": 5,
    "ActionName": "Action",
    "ActionListOptions": []
    }
}

export const CL_Location = {
    "componentDidMount": componentDidMount_CL,
    "componentDidUpdate":componentDidUpdate_CL,
    "onAddRow" : onAddRow_HomeOwners,
    "deleteRow": deleteLocation_CL,
    "handleInputChangeV2": handleInputChangeV2_GL,
    "constructTransactionRequest": constructTransactionRequest_Location_CL,
    "getRequestContruction": getRequestConstruction_CL,
    "setHierarchyIds": setHierarchyIds_CL,
     "transform":transform_GL,
     "setParentHierarchyId": setParentHierarchyId_CL,
     "parentOnLoad" : parentOnLoad_CL,
     "getMetadataRequest": getMetadataRequest,
     "componentDidMount_Summary": componentDidMountSummary_CL,
     "getCoordinates" : getCoordinates,
     "getDataListWithExpand": getDataListWithExpand,
     "location":{
        "TableOptions":{...quoteListTableOptions}, OnchangeEvent: toPickPrimaryLocation
      },
}

export const CL_Building = {
    "componentDidMount": componentDidMount_CL,
    "componentWillReceiveProps" : componentWillReceiveProps_ClaimsHistory,
    "componentDidMount_Summary": componentDidMountSummary_ClaimsHistoryAndAdditionalInterest,
    "componentDidUpdate":componentDidUpdate_CL,
    "onAddRow" : onAddRow_HomeOwners,
    "handleInputChangeV2": handleInputChangeV2_GL,
    "constructTransactionRequest": constructTransactionRequest_Building,
    "getRequestContruction": getRequestConstruction_CL,
    "setHierarchyIds": setHierarchyIds_CL,
    "parentOnLoad" : parentOnLoad_Building,
    "transform":transform_GL,
    "getMetadataRequest": getMetadataRequest,
    "setParentHierarchyId": setParentHierarchyId_CL,
    "deleteRow" : deleteBuilding,
    "getDataListWithExpand": getDataListWithExpand,
    "componentWillReceiveProps" : componentWillReceiveProps_ClaimsHistory,
    "componentDidMount_Summary": componentDidMountSummary_ClaimsHistoryAndAdditionalInterest
}

export const CL_LocationV1 = {
    "componentDidMount": componentDidMount_CL,
    "componentDidUpdate":componentDidUpdate_CLV1,
    "handleInputChangeV2": handleInputChangeV2_GL,
    "parentOnLoad" : parentOnLoad_CLV1,
    "componentDidMount_Summary": componentDidMountSummary_CLV1,
    "getDataListWithExpand": getDataListWithExpand,
    "getRequestContruction": getRequestConstruction_CLV1,
    "getCoordinates" : getCoordinates,
    "setHierarchyIds": setHierarchyIds_CL,
     "transform":transform_GL,
     "setParentHierarchyId": setParentHierarchyId_CL,
     "getMetadataRequest": getMetadataRequest,
     "location":{
        "tableUtils": {...quoteListTableUtils,...{"onExpand": onExpand}},
        "TableOptions":{...quoteListTableOptions}
      },

}


export const CL_BuildingV1 = {
    "componentDidMount": componentDidMount_CL,
    "componentDidMount_Summary":componentDidMountSummary_CLV1,
    "componentDidUpdate":componentDidUpdate_CLV1,
    "getRequestContruction": getRequestConstruction_CLV1,
    "parentOnLoad" : parentOnLoad_BuildingV1,
    "getDataListWithExpand": getDataListWithExpand,
    "handleInputChangeV2": handleInputChangeV2_GL,
    "setHierarchyIds": setHierarchyIds_CL,
    "transform":transform_GL,
    "getMetadataRequest": getMetadataRequest,
    "setParentHierarchyId": setParentHierarchyId_CL,
    "building":{
        "tableUtils": {...quoteListTableUtils,...{"onExpand": onExpand}},
        "TableOptions":{...quoteListTableOptions}
      }

}
