import { commonProductAxiosInstance, GetCommonEventName,getJSONRequestFromAPI, getServiceRequestDetail } from "../utils/Common/API";
import store from "../../../store";
import { triggerToast } from "../utils/Common/HelperUtils";
import { getNavigation } from "../../../CommonAxios/ResourceAxios";
import { templateConstruct,constructTemplateWithUtil } from "../utils/Common/Navigation_Util";
import * as actionType from "../../../actions/types";
import { EntitySubAppTranslation, getEntitySubAppMapping } from "../utils/requestConstruction/ResponseTranslation";
import { constructRequestValues } from "../utils/API_utils/ConstructRequestValues";
import { dynamicObjectConstruction } from "../utils/Common/NoCodeUtils";


export const getFindConsoleAPI_ReqUtil = async (viewName, data, offsetInfo, stateObj, props,existingInsured=false) => {
  let requestMetadata = {};
  let url = "core/invokeWorkflowEvent/";
  let {currentFindConsoleInfo} = store.getState().UIFlowReducer;
  let {geteventName, eventName } = currentFindConsoleInfo;
  if(existingInsured){
    eventName=props.pageInfo.eventName;
  }
  switch (viewName)
  {
    case "Submission":
    {
      requestMetadata.responseKey = "Submission";
      requestMetadata.eventName = geteventName;
      requestMetadata.url = `${url}V2`;

      const state = store.getState();
      if (state.workflowReducer.transactionRequest.hasOwnProperty(requestMetadata.eventName))
      {
        requestMetadata.request = JSON.parse(JSON.stringify(state.workflowReducer.transactionRequest[requestMetadata.eventName]));
      }
      else {

        requestMetadata.request = await constructFindSubmissionRequest(data,requestMetadata.eventName, offsetInfo, stateObj, props);
       }
      break;
    }

    case "Account":
    {
      requestMetadata.responseKey = "Account";
      requestMetadata.eventName = "FindAccount_WF";
      requestMetadata.url = `${url}V4`;
      const state = store.getState();

      if (state.workflowReducer.transactionRequest.hasOwnProperty(requestMetadata.eventName))
      {
        requestMetadata.request = JSON.parse(JSON.stringify(state.workflowReducer.transactionRequest[requestMetadata.eventName]));
      } else{
        requestMetadata.request = constructFindAccountRequest(data, offsetInfo);
      }
      break;

    }

    case "Insured": {
      requestMetadata.responseKey = "Insured";
      requestMetadata.eventName = eventName;
      requestMetadata.url = `${url}`;
      const state = store.getState();

      if (state.workflowReducer.transactionRequest.hasOwnProperty(requestMetadata.eventName))
      {
        requestMetadata.request = JSON.parse(JSON.stringify(state.workflowReducer.transactionRequest[requestMetadata.eventName]));
      } else {
        requestMetadata.request = await constructFindInsuredRequest(data, requestMetadata.eventName, offsetInfo, stateObj, props,existingInsured);
      }
      break;

    }
    case "Agent": {
      requestMetadata.responseKey = "Agent";
      requestMetadata.eventName = eventName;
      requestMetadata.url = `${url}`;
      const state = store.getState();
      if (state.workflowReducer.transactionRequest.hasOwnProperty(requestMetadata.eventName))
      {
        requestMetadata.request = JSON.parse(JSON.stringify(state.workflowReducer.transactionRequest[requestMetadata.eventName]));
      } else {
        requestMetadata.request = await constructFindAgentRequest(data, requestMetadata.eventName, offsetInfo, stateObj, props);
      }
      break;

    }
    case "Quote":
    {
      requestMetadata.responseKey = "Quote";
      requestMetadata.eventName = eventName;
      requestMetadata.url = `${url}V2`;
      const state = store.getState();

      if (state.workflowReducer.transactionRequest.hasOwnProperty(requestMetadata.eventName))
      {
        requestMetadata.request = JSON.parse(JSON.stringify(state.workflowReducer.transactionRequest[requestMetadata.eventName]));
      } else {
        requestMetadata.request = await constructFindQuoteRequest(data, requestMetadata.eventName, offsetInfo, stateObj, props);
      }
      break;

    }
    case "Policy":
    {
      requestMetadata.responseKey = "Policy";
      requestMetadata.eventName = eventName;
      requestMetadata.url = `${url}V2`;
      const state = store.getState();

      if (state.workflowReducer.transactionRequest.hasOwnProperty(requestMetadata.eventName))
      {
        requestMetadata.request = JSON.parse(JSON.stringify(state.workflowReducer.transactionRequest[requestMetadata.eventName]));
      } else {
        requestMetadata.request = await constructFindPolicyRequest(data, requestMetadata.eventName, offsetInfo, stateObj, props);
      }
      break;


    }
    case "Policy By Risk":
      {
        requestMetadata.responseKey = "Policy";
        requestMetadata.eventName = eventName;
        requestMetadata.url = `${url}V2`;
        const state = store.getState();

        if (state.workflowReducer.transactionRequest.hasOwnProperty(requestMetadata.eventName))
        {
          requestMetadata.request = JSON.parse(JSON.stringify(state.workflowReducer.transactionRequest[requestMetadata.eventName]));
        } else {
          requestMetadata.request = await constructFindByRiskRequest(data, requestMetadata.eventName, offsetInfo, stateObj, props);
        }
        break;


      }
    default:
      {break;}
  }


      return requestMetadata;
}

const getFindConsoleAPI_Request = async (viewName, data, offsetInfo, state, props) => {
  try{
  const requestMetadata= await getFindConsoleAPI_ReqUtil(viewName, data, offsetInfo, state, props)
  let response = await commonProductAxiosInstance.post(requestMetadata.url, requestMetadata.request);
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
    throw new Error("Response Failed");
  }else{
    let responseData = response.data;
    let result = {
        "DataList" : [],
        "DataListSize" : 0
      };
      let {responseKey} = requestMetadata
      if (Object.keys(responseData).length > 0) {
        if(responseData.hasOwnProperty(responseKey)){
          return {
            "DataList" : responseData[responseKey],
            "DataListSize" : responseData['Count'] ? parseInt(responseData['Count'],10) : responseData[responseKey].length
          }
        } else{
          return result;
        }
      } else {
        return result;
      }
    }
}catch(err){
  console.log("Error in getFindConsoleAPI_Request",err);
  triggerToast('Error in response')
}
 };


const constructFindSubmissionRequest = async (data, eventName, offsetInfo, state, props) => {
   let FindSubmissionRequest = await constructFindRequest(data, eventName, offsetInfo, state, props)
     FindSubmissionRequest.TargetEventName = props.currentFindConsoleInfo.eventName;
     FindSubmissionRequest.EventName = GetCommonEventName();
     FindSubmissionRequest.SearchName = props.currentFindConsoleInfo.SearchName;
     FindSubmissionRequest.CreatedDate="";
     FindSubmissionRequest.LastModifiedDate="";
     FindSubmissionRequest.EffectiveDate="";
     FindSubmissionRequest.ExpirationDate="";
     return FindSubmissionRequest;
};

const constructFindQuoteRequest = async (data, eventName, offsetInfo, state, props) => {
  let FindQuoteRequest = await constructFindRequest(data, eventName, offsetInfo, state, props)
  FindQuoteRequest.TargetEventName = props.currentFindConsoleInfo.eventName;
  FindQuoteRequest.EventName = props.currentFindConsoleInfo.CommonEventName;
  FindQuoteRequest.SearchName  = props.currentFindConsoleInfo.SearchName;
  FindQuoteRequest.CreatedDate="";
  FindQuoteRequest.LastModifiedDate="";
  FindQuoteRequest.EffectiveDate="";
  FindQuoteRequest.ExpirationDate="";
  FindQuoteRequest.SubmissionNumber=""
  return FindQuoteRequest
}

const constructFindRequest = async (data, eventName, offsetInfo, state, props) => {
  let FindRequest = Object.assign({}, getServiceRequestDetail());
  FindRequest.OwnerId = sessionStorage.getItem("OwnerID");
  FindRequest.EventName = eventName;
  let request = await getJSONRequestFromAPI(eventName,undefined,'V3');
  request["ServiceRequestDetail"] = {...FindRequest}
  let newdata = await constructRequestValues(props, state.pagelevelValues, {},state,false,false)
  let data1 = props.currentFindConsoleInfo ? newdata[props.currentFindConsoleInfo.findview] : data;
  return { ...request,...data1, ...FindRequest,...offsetInfo };
}

const constructFindAgentRequest = async (data, eventName, offsetInfo, state, props) => {
  let FindAgentRequest = await constructFindRequest(data, eventName, offsetInfo, state, props)
  FindAgentRequest.SearchName  = props.currentFindConsoleInfo.SearchName
  FindAgentRequest.AgencyNumber = data.AgencyNumber
  FindAgentRequest.ID = ""
  return {...FindAgentRequest};
}

const constructFindInsuredRequest = async (data, eventName, offsetInfo, state, props,existingInsured) => {
  let FindInsuredRequest = await constructFindRequest(data, eventName, offsetInfo, state, props)
  FindInsuredRequest.AgencyNumber = data.AgencyNumber
  FindInsuredRequest.AgentNumber = data.AgentNumber
  if(existingInsured){
    FindInsuredRequest.SearchName=props.pageInfo.SearchName;
  }else{
    FindInsuredRequest.SearchName = props.currentFindConsoleInfo.SearchName
  }
  return {...FindInsuredRequest};
}

const constructFindAccountRequest = (data, offsetInfo) => {
  let FindAccountRequest = {};
  FindAccountRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  FindAccountRequest["ServiceRequestDetail"].OwnerId = sessionStorage.getItem("OwnerID");
  FindAccountRequest.OwnerId = sessionStorage.getItem("OwnerID");
  FindAccountRequest = Object.assign({}, FindAccountRequest, JSON.parse(JSON.stringify(require("../models/FindAccount.json"))));
  FindAccountRequest.TargetEventName = "FindAccount_WF";
  FindAccountRequest.EventName = "SolartisCommonMaster_WF";
  FindAccountRequest.EventAction = "FindAccount_1.0.0.1";
  FindAccountRequest = { ...FindAccountRequest, ...data, ...offsetInfo };
  return FindAccountRequest;
};

const constructFindPolicyRequest = async (data, eventName, offsetInfo, state, props) => {
  let FindPolicyRequest = await constructFindRequest(data, eventName, offsetInfo, state, props)
  FindPolicyRequest.TargetEventName = props.currentFindConsoleInfo.eventName;
  FindPolicyRequest.EventName = GetCommonEventName();
  FindPolicyRequest.CreatedDate="";
  FindPolicyRequest.LastModifiedDate="";
  FindPolicyRequest.EffectiveDate="";
  FindPolicyRequest.ExpirationDate="";
  FindPolicyRequest.SubmissionNumber=""
  FindPolicyRequest.SearchName = props.currentFindConsoleInfo.SearchName;
  return FindPolicyRequest;
};

const constructFindByRiskRequest = async (data, eventName, offsetInfo, state, props) => {
  let FindByRiskRequest = await constructFindRequest(data, eventName, offsetInfo, state, props)
  FindByRiskRequest.TargetEventName = props.currentFindConsoleInfo.eventName;
  FindByRiskRequest.EventName = GetCommonEventName();
  FindByRiskRequest.SearchName = props.currentFindConsoleInfo.SearchName;
  return FindByRiskRequest;
};

const requestDataFiller = async (request, data) => {
  if (Object.keys(data).length > 0) {
    Object.keys(data).map((key) => {
      if (request.hasOwnProperty(key)) {
        request[key] = data[key];
      }
    });

    return request;
  }
  return request;
};

export const invokeFindConsoleAPI = async (viewName, RequestDatas, offsetInfo, state, props) => {
  let response = await getFindConsoleAPI_Request(viewName, RequestDatas, offsetInfo ,state, props);
  return response;
};

export const getProductDetailsByInsuredId = async(ID, props) => {
  let getNavRequest = {}
  let request = Object.assign({}, getServiceRequestDetail());
  getNavRequest["ServiceRequestDetail"] = request
  getNavRequest.ID = ID
  getNavRequest.OwnerId = sessionStorage.getItem("OwnerID");
  getNavRequest.EventName = "GetInsuredProductDetails";
  let response = await commonProductAxiosInstance.post("core/invokeWorkflowEvent/", getNavRequest);
  // props.setAgentID(response.data.AgentId)
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
    console.log("Response Failed");
    triggerToast("Response Failed");
  }else{
  await store.dispatch({
    type: actionType.SET_AGENTID,
    payload: response.data.AgentId
  });
  await store.dispatch({
    type: actionType.SET_QUOTE_DETAILS,
    payload: {
      quoteID : '',
      quoteNumber : ''
    }
  });
  //await store.dispatch({type : actionType.SET_SUBMISSION_NUMBER,payload : ""})
  await store.dispatch({
    type: actionType.SET_INSUREDID,
    payload: ID
  });
  return response.data;
}
}

export const getProductDetailsByQuoteId = async(ID, props) => {
  let getNavRequest = {}
  let request = Object.assign({}, getServiceRequestDetail());
  getNavRequest["ServiceRequestDetail"] = request
  getNavRequest.ID = ID
  getNavRequest.OwnerId = sessionStorage.getItem("OwnerID");
  getNavRequest.EventName = "GetQuoteProductDetails";
  let response = await commonProductAxiosInstance.post("core/invokeWorkflowEvent/", getNavRequest);
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
    console.log("Response Failed");
    triggerToast("Response Failed");
  }else{
  //   response.data = {
  //     "ProductVerID": "361334",
  //     "ProductName": "Solartis PL Base Product",
  //     "LOBName": "PL",
  //     "ProductType": "Non Configured",
  //     "ProductVerNumber": "PL_1_5_V1",
  //     "ProductID": "362263",
  //     "ProductNumber": "PL_1_5"
  // }
  const redux=store.getState();
  if (props.pageInfo.productInfo)
    {
      let obj={};
      obj = await dynamicObjectConstruction(props.pageInfo.productInfo, {...response.data}, obj, 'response');
      store.dispatch({ type: actionType.SET_FLOW, payload: obj.Flow});
    }
  // if (redux.authReducer.fromSSO && sessionStorage.getItem("OwnerID") === '15793')
  // store.dispatch({ type: actionType.SET_FLOW, payload: response.data.ProductType === 'Non Configured' ? 'BrokerageMarket' : 'QuoteNow'})
  await store.dispatch({
    type: actionType.SET_QUOTE_DETAILS,
    payload: {
      quoteID : ID
    }
  });
  return response.data;
  }
}

export const getProductDetailsByPolicyId = async(ID, props) => {
  let getNavRequest = {}
  let request = Object.assign({}, getServiceRequestDetail());
  getNavRequest["ServiceRequestDetail"] = request
  getNavRequest.ID = ID
  getNavRequest.OwnerId = sessionStorage.getItem("OwnerID");
  getNavRequest.EventName = props.currentFindConsoleInfo ? props.currentFindConsoleInfo.policyEventName : props.pageInfo.policyEventName;
  let response = await commonProductAxiosInstance.post("core/invokeWorkflowEvent/", getNavRequest);
  if(response.data.hasOwnProperty('ResponseStatus')&& response.data.ResponseStatus.toLowerCase() === "failed"){
    console.log("Response Failed");
    triggerToast("Response Failed");
  }else{
    const redux=store.getState();    
    if (props.pageInfo.productInfo)
    {
      let obj={};
      obj = await dynamicObjectConstruction(props.pageInfo.productInfo, {...response.data}, obj, 'response');
      store.dispatch({ type: actionType.SET_FLOW, payload: obj.Flow});
    }
    // if ((redux.authReducer.fromSSO && sessionStorage.getItem("OwnerID") === '15793'))
    // store.dispatch({ type: actionType.SET_FLOW, payload: response.data.ProductType === 'Non Configured' ? 'BrokerageMarket' : 'QuoteNow'})
  await store.dispatch({
    type: actionType.SET_POLICY_DETAILS,
    payload: {
      policyID : ID
    }
  });
  await store.dispatch({
    type: actionType.SET_QUOTE_DETAILS,
    payload: ''
  });
  return response.data;
  }
}

export const getNavigationDetails = async (ID, props, name) => {
  let Name=name.replaceAll(' ','');
  let prdObj = await Find[Name].getProductDetails(ID, props)
  store.dispatch({
    type: "SET_LOB",
    payload: prdObj.LOBName,
  });
  store.dispatch({
    type: "SET_PRODUCT_DETAILS",
    payload: prdObj,
  });
  let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"],[prdObj.ProductVerNumber]);
  const redux=store.getState();
  let templateInfoTemplates = redux.navigationReducer.templateInfo;
  if(navObj.hasOwnProperty('productTemplateInfo')){
    //Util Construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template])
    })
    //SubTemplate construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
    })
    store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}})
  }
  let templatefromredux = {...templateInfoTemplates,...navObj.productTemplateInfo};
  if (navObj.hasOwnProperty("productNavigation")) {
    let key = prdObj.LOBName ? `${prdObj.LOBName}_submission` : 'template';
    key = navObj.productNavigation[key] ? key : "template"
    templateConstruct(navObj, ["template"], templatefromredux);
    let prodnav = navObj.productNavigation[key];
    let pageNav = Object.assign({}, redux.navigationReducer.navigation);
    pageNav.pageNavigation.submission.sidebar[1].children = prodnav;
    await store.dispatch({
      type : actionType.SET_NAVIGATION,
     payload :  pageNav
    })
    await store.dispatch({
      type : actionType.SET_PAGE_NAVIGATION,
     payload :  prodnav
    })
    await store.dispatch({
      type : actionType.SET_QUOTECONSOLE_INFO,
     payload :  {}
    })
    await store.dispatch({
      type : actionType.SET_POLICYCONSOLE_INFO,
     payload :  {}
    })
    store.dispatch({
      type: actionType.SET_NAVIGATION,
      payload: {...redux.navigationReducer.navigation,"productNavigation":{...navObj.productNavigation}},
  });
  }
};

export const getAgencyAgentDetails = async (AgentId, props, state) => {
  let getRequest={};
  getRequest["ServiceRequestDetail"] = await Object.assign({}, getServiceRequestDetail());
  getRequest.OwnerId = sessionStorage.getItem("OwnerID");
  getRequest.EventName = "GetAgentAndAgencyDetails";
  getRequest.ID = AgentId;
  const getAgencyResponse = await commonProductAxiosInstance.post("core/invokeWorkflowEvent/", getRequest);
  if(getAgencyResponse.data.hasOwnProperty('ResponseStatus') && getAgencyResponse.data.ResponseStatus.toLowerCase() === "failed"){
    console.log("Response Failed");
    triggerToast("Response Failed");
  }else{
  let currentViewInfo = JSON.parse(JSON.stringify(props.currentFindConsoleInfo));
  let { ObjectVerId :  objectVerId,  ObjectId:objectId } = currentViewInfo;
  let ownerId = sessionStorage.getItem("OwnerID");
  let subApplication = props.currentFindConsoleInfo["SubApplicationList"];
  const reduxState = store.getState();
  const entitySubAppObject = await getEntitySubAppMapping( { objectId,objectVerId , ownerId, subApplication })
  let entityTranslatedResponse = await EntitySubAppTranslation(getAgencyResponse.data, reduxState.metadataReducer, state.pagelevelValues, {}, {}, state.pageMetadata, undefined, entitySubAppObject)
  let pagelevelValues = Object.assign({}, entityTranslatedResponse);

  return pagelevelValues
}
}

export const handleAutoCompletechange = (value, item, component, func,state) => {
  let pagelevelValues = Object.assign({}, state.pagelevelValues);
  if(!pagelevelValues[component.SubApplicationName])
  {
    pagelevelValues[component.SubApplicationName]={}
  }
  pagelevelValues={...pagelevelValues,[component.SubApplicationName] : {...pagelevelValues[component.SubApplicationName],...value}}
  func("pagelevelValues",pagelevelValues, true)
};

export const Find = {
  "handleAutoCompletechange" : handleAutoCompletechange,
  Agent : {
    "getAccordionDetails" : getAgencyAgentDetails
  },
  Insured : {
    "getProductDetails" : getProductDetailsByInsuredId
  },
  Quote : {
    "getProductDetails" : getProductDetailsByQuoteId
  },
  Policy : {
    "getProductDetails" : getProductDetailsByPolicyId
  },
  PolicyByRisk : {
    "getProductDetails" : getProductDetailsByPolicyId
  },
  Submission: {
    "getProductDetails" : getProductDetailsByInsuredId
  }
}
