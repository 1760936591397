import React, { Component, createRef } from "react";
import ReactDOM from "react-dom";
import DynamicComponent from "../../../corecomponents/DynamicComponent";
import {
  Card,
  CardContent,
  Grid
} from "@material-ui/core";
import NavigationButtons from "../Navigation/NavigationButtons";
import { ValidatorForm } from "react-material-ui-form-validator";
import { FileHandlerAxios } from '../../../../../CommonAxios/FileHandlerAxios'
import {
  getServiceRequestDetail,
  getAccountAxios,
  commonProductAxiosInstance,
  AxiosInstance,
} from "../../../utils/Common/API";
import { connect } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import {
  setLOB,
  setprimaryState,
  setAdditionalCoverageVisibility,
  setAgencyDetails,
  setAgentDetails,
  setexpandPanelData,
} from "../../../../../actions/applicationMetadataAction";
import ChildFormComponent from "../ChildComponent/childFormComponent";
import {
  setNavigation,
  setPageNavigation
} from "../../../../../actions/navigationAction";
import PageLoader from "../../../controls/PageLoader";
import Geocode from "react-geocode";
import store from "../../../../../store";
import Accordion from "../Accordion/AccordionComponent";
// import Accordion3 from "../sections/acctest";
import { getAccountProfile, handlePersistValue,LOBUtil,getDefaultData,asyncForEach,renderChildrenWithPropsV2 } from '../../../utils/Common/CommonUtils';
import MessageDialog from '../../../controls/MessageDialog';
import DynamicNavigation from "../../../corecomponents/DynamicNavigation";
import {multiLobApi} from '../../../utils/expressFunction/multiLob';
import * as actionTypes  from "../../../../../actions/types";
import { EntitySubAppTranslation, getEntitySubAppMapping } from '../../../utils/requestConstruction/ResponseTranslation';
import  cloneDeep  from "lodash/cloneDeep";
import { SubmissionUtils } from "../../../utils/Common/SubmissionUtils";
import { handleInputChangeV2 } from "../../../utils/Common/InputUtils";
import appActions from "../../../../../actions";
import PropsContext from "./PropsContext";
import {getNavigationDetailsByInsured, getNavigationDetailsBySubmission, getProductDetailsBySubmission } from "../../../utils/Common/DynamicHOC_Utils";
import { setCurrentPageIndex, setSubmissionID,setAgentID, showLoader, hideLoader, solartisUIPageNavigationStatus, setProductDetails } from "../../../../../actions/UIFlowActions";
import { getRequiredIndex, triggerToast } from "../../../utils/Common/HelperUtils";
import { constructAndInvokeMetadataCall } from "../../../utils/Common/MetaUtils";
import { constructEncodedString, decodeString, dynamicObjectConstruction } from "../../../utils/Common/NoCodeUtils";
import { getNavigation } from "../../../../../CommonAxios/ResourceAxios";
import { constructTemplateWithUtil, templateConstruct } from "../../../utils/Common/Navigation_Util";
import { differenceInBusinessDays } from "date-fns";
const { actionType } = appActions;
const state = store.getState();
const clientInfo = state.ownerResources.clientInfo;

Geocode.setApiKey(clientInfo.GoogleMapsAPIKey);
Geocode.enableDebug();

const initialState = {
  tabPosition : 0,
  messageDialog: false,
  infoDialog: "",
  contentDialog: "",
  loading: false,
  currentPageIndex: 1,
  pageMetadata: {},
  values: {},
  savedValues: {},
  pagelevelValues : {},
  persistValues: {},
  dynamicdatatablerows: [],
  createSubmissionRes: {},
  Action: "",
  locationinfovalue: {},
  transactionPrimaryObject: {},
  useaccountaddress: false,
  disableCopyInsuredAddress: false,
  snackBarMessage: { type: "", message: "" },
  isInsuredAddressAdded: false,
  checkCopyInsuredAddress: false,
  canProceed: false,
  getApiResponse: {},
  freezeheight: '60',
  parentTabPosition:0,
  activatePreviousLOBInfo: false,
  displayInsuredSearch : false
};

class Submission extends Component {
  constructor(props) {
    super(props);

    this.state = JSON.parse(JSON.stringify(initialState));
    this.form = React.createRef();
    this.expandPanelRef = createRef();
    this.updateDefaultData = this.updateDefaultData.bind(this);
  }

  componentDidMount = async () => {
    this.UpdateState("loading",true,false)
    const reduxState = store.getState();
    let {insuredID, submissionID}= reduxState.UIFlowReducer,LOB,currentPageIndex;
    if(this.props.pageInfo.dynamicFlow && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y')
      {
          store.dispatch({
            type: actionType.SET_PAGE_NAVIGATION,
            payload: [],
        })
      }
  try{
    if(this.props.pageInfo.dynamicFlow && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
      let prodNav=[];
      let val=window.location.hash.split('/'),pageData={};
        if(val.length > 1 && val[2]){
            let newVal=val[2];
            pageData = decodeString(newVal)
        }
        if(pageData && pageData.hasOwnProperty('Flow')){
          store.dispatch({type: actionType.SET_FLOW, payload: pageData.Flow});
        }  
      store.dispatch({type: actionType.SET_UI_VALUES,payload: pageData});
      if(!insuredID && pageData && pageData.hasOwnProperty('insuredID')){
        insuredID = pageData.insuredID;
        store.dispatch({type: actionType.SET_INSUREDID, payload: insuredID});
      }  
      // insuredID ? (prodNav = await getNavigationDetailsByInsured(insuredID,this.props)) : submissionID ? (prodNav = await getNavigationDetailsBySubmission(submissionID, this.props)) : (prodNav = await this.didMount(val,pageData));
      insuredID ? (prodNav = await getNavigationDetailsByInsured(insuredID,this.props)) : submissionID ? (prodNav = await getProductDetailsBySubmission(submissionID, this.props)) : (prodNav = await this.didMount(val,pageData));
      let updatedVal=window.location.hash.split('/'),updatedPageData={};
        if(updatedVal.length > 1 && updatedVal[2]){
            let newVal=updatedVal[2];
            updatedPageData = decodeString(newVal)
        }      
      if(Object.keys(pageData).length===0){
        pageData=updatedPageData
      }     
      let pageName=pageData.pageName || this.props.pageInfo.defaultPageName;
      let pageIndex =  prodNav.findIndex(i => i.pageName === pageName);
      store.dispatch({type: actionType.SET_PAGENAME,payload: pageName});
      this.props.setCurrentPageIndex(pageIndex+1);
      LOB=pageData.LOBName
      currentPageIndex=pageIndex+1;
    }else{
      insuredID ? await getNavigationDetailsByInsured(insuredID, this.props) : submissionID && await getNavigationDetailsBySubmission(submissionID, this.props);
      LOB = this.props.UIFlowReducer.productDetails.hasOwnProperty("LOBName") && this.props.UIFlowReducer.productDetails["LOBName"];
      currentPageIndex = this.props.UIFlowReducer.currentPageIndex;
      this.props.setCurrentPageIndex(currentPageIndex);
    }

      const key = this.props.templateName ? this.props.templateName : LOB;
      await store.dispatch({type: actionType.SET_ROUTE_INFO,payload:"Submission"})
      if(this.props.pageNavigation && this.props.pageNavigation[currentPageIndex-1] )
      {
        this.UpdateState("loading",true,false)
        let templateName = this.props.pageNavigation[currentPageIndex - 1].templateName
        let commonSubmissionUtil=this.props.pageNavigation[currentPageIndex - 1].commonSubmissionUtil;
        let values = {};
        if (templateName && SubmissionUtils[LOB] && SubmissionUtils[LOB][templateName] && SubmissionUtils[LOB][templateName].hasOwnProperty("componentDidMount")) {

          values = await SubmissionUtils[LOB][templateName]["componentDidMount"](this.state, this.props, this.UpdateState)


        }else if(commonSubmissionUtil && SubmissionUtils[commonSubmissionUtil] && SubmissionUtils[commonSubmissionUtil].hasOwnProperty("componentDidMount")){
          values = await SubmissionUtils[commonSubmissionUtil]["componentDidMount"](this.state, this.props, this.UpdateState)
        }
        if (values && Object.keys(values).length > 0) {
          Object.entries(values).forEach(([key, value]) => {
            this.state[key] = value;
          })
        }

          if( SubmissionUtils[LOB] && SubmissionUtils[LOB].hasOwnProperty("getSubmission")){
           await SubmissionUtils[LOB]["getSubmission"](this.state, this.props, this.UpdateState);
        }

        let defaultData =  await this.updateDefaultData();
        let OwnerId = sessionStorage.getItem("OwnerID");
        let { ObjectId: objectId, ObjectVerId: objectVerId } = this.props.pageNavigation[currentPageIndex - 1]
        let subApplication=this.props.pageNavigation[currentPageIndex - 1].SubApplicationName
        let entityTranslatedResponse ={}
        if(defaultData && subApplication.length > 0){
          await Promise.all([store.dispatch({type: actionTypes.GET_AGENCY_DETAILS, payload: defaultData.pagelevelValues.AgencyInformation}),
            store.dispatch({type: actionTypes.GET_AGENT_DETAILS, payload: defaultData.pagelevelValues.AgentInformation})])
          const entitySubAppObject = await getEntitySubAppMapping( { objectId,objectVerId , OwnerId, subApplication })
          entityTranslatedResponse = await EntitySubAppTranslation(defaultData.pagelevelValues, reduxState.metadataReducer, this.state.pagelevelValues, {}, {}, this.state.pageMetadata, undefined, entitySubAppObject)
        }
        let tpo={...this.state.transactionPrimaryObject,...entityTranslatedResponse}
        let response = await constructAndInvokeMetadataCall({ subappName: this.props.pageNavigation[reduxState.UIFlowReducer.currentPageIndex - 1].SubApplicationName, objectId, objectVerId }, { transactionPrimaryObject: tpo, EffectiveDate: this.state.values.EffectiveDate }, this.metadataAPI_callback)
        this.setState({
          pageSubAppInfo: { subappName: this.props.pageNavigation[reduxState.UIFlowReducer.currentPageIndex - 1].SubApplicationName, objectId, objectVerId },
          pageMetadata: response,
          SubApplicationEntity:response.ApplicationDetail.SubApplicationEntity
        });
        this.UpdateState("loading",false,true)
}
      this.props.hideLoader();
}
catch(error){
  console.log('Error in Submission Component Did Mount', error)
}
      // console.log("coming inside did mount in submission",currentPageIndex,this.props.pageNavigation,this.props.pageNavigation.SubApplicationName);
      // let objectId="362363";
      // let objectVerId="361434"
      this.UpdateState("loading",false,true)

};
  didMount = async(val,pageData) =>{
    try {
      let prodnav=[];
      if(pageData.ProductVerNumber){
        let {ProductVerNumber,LOBName}=pageData;
        let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"],[ProductVerNumber]);
        let reduxState = store.getState();
        let templateInfoTemplates = reduxState.navigationReducer.templateInfo;
        let templatefromredux = {...templateInfoTemplates};
        if(navObj.hasOwnProperty('productTemplateInfo')){
          //Util Construction in Templates
          Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
            navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template])
          })
          //SubTemplate construction in Templates
          Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
            navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
          })
          store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}});
          templatefromredux = {...templatefromredux,...navObj.productTemplateInfo};
        }
        if (navObj.hasOwnProperty("productNavigation")) {
          let key = LOBName ? `${LOBName}_submission` : "template";
          key = navObj.productNavigation[key] ? key : "template"
          templateConstruct(navObj, ["template"], templatefromredux);
          prodnav = navObj.productNavigation[key];
          let pageNav = Object.assign({}, this.props.navigation);
          let index =getRequiredIndex(pageNav.pageNavigation.submission.sidebar,'name','Submission')
          pageNav.pageNavigation.submission.sidebar[index].children = prodnav;
          this.props.setNavigation(pageNav);
          this.props.setPageNavigation(prodnav);
          store.dispatch({
            type: actionType.SET_PAGE_NAVIGATION,
            payload: prodnav,
        });
          store.dispatch({
            type: actionType.SET_NAVIGATION,
            payload: {...this.props.navigation,"productNavigation":{...navObj.productNavigation}},
        });
        }
      }
      store.dispatch({
        type: "SET_PRODUCT_DETAILS",
        payload: {...pageData},
      });
      store.dispatch({ type: actionType.SET_UI_VALUES, payload: {pageData:{...pageData}}});
      return prodnav;
    } catch (error) {
      console.log('Error in didMount Submission',error)
    }
  }

  componentDidUpdate = async (prevProps,prevState) => {
    let LOB,submissionID,lobUtil;
    const reduxState = store.getState();
    console.log(this.props)
    if(this.props.pageInfo.dynamicFlow && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
      let {pageData={}}=this.props.UIFlowReducer;
      LOB = pageData.productDetails && pageData.productDetails.hasOwnProperty("LOBName") && pageData.productDetails["LOBName"];
      submissionID = pageData.submissionID
      lobUtil = pageData.productDetails && pageData.productDetails.hasOwnProperty("LineOfBusiness") ? LOBUtil[pageData.productDetails["LineOfBusiness"]] || {} : {};
    }else{
      LOB = this.props.UIFlowReducer.productDetails.hasOwnProperty("LOBName") && this.props.UIFlowReducer.productDetails["LOBName"]
      submissionID = this.props.UIFlowReducer.submissionID
      lobUtil = this.props.productDetails.hasOwnProperty("LineOfBusiness") ? LOBUtil[this.props.productDetails["LineOfBusiness"]] || {} : {};
    }
    const prevCurrentPageIndex = prevProps.UIFlowReducer.currentPageIndex;
    const currentPageIndex = this.props.UIFlowReducer.currentPageIndex;    
    if(this.state.pagelevelValues.SelectedLineOfBusiness && this.state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === 'true'
    && currentPageIndex && this.props.pageNavigation && this.props.pageNavigation[currentPageIndex - 1] && this.props.pageNavigation[currentPageIndex - 1]["ObjectId"] === "Multi_Api")
    {
      lobUtil=LOBUtil['CP']
    }
    let parentTabPosition = this.state.parentTabPosition;
    let prevParentTabPosition = prevState.parentTabPosition;
    let tabPosition = this.state.tabPosition;
    let prevTabPosition = prevState.tabPosition;
    if(this.props.navigation.TabNavigation!==prevProps.navigation.TabNavigation&&this.state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === 'true')
    {
      if(submissionID)
      this.setParentTabEnable();
    }
    if(prevCurrentPageIndex !== currentPageIndex)
    store.dispatch({type: actionType.SET_PAGENAME,payload: this.props.pageNavigation[currentPageIndex - 1].name})
    if (prevCurrentPageIndex !== currentPageIndex || prevParentTabPosition !== parentTabPosition || tabPosition !== prevTabPosition) {
    // console.log("component did update on previous click===================>",this.props.pageNavigation[currentPageIndex - 1]);
    if(prevProps.UIFlowReducer.currentPageIndex!=this.props.UIFlowReducer.currentPageIndex){
      let templateName = this.props.pageNavigation[currentPageIndex - 1].templateName
      let commonSubmissionUtil=this.props.pageNavigation[currentPageIndex - 1].commonSubmissionUtil;
        let values = {};
      if (templateName && SubmissionUtils[LOB] && SubmissionUtils[LOB][templateName] && SubmissionUtils[LOB][templateName].hasOwnProperty("componentDidUpdate")) {
        values = await SubmissionUtils[LOB][templateName]["componentDidUpdate"](this.state, this.props,this.UpdateState, prevState, prevProps)
    }else if(commonSubmissionUtil && SubmissionUtils[commonSubmissionUtil] && SubmissionUtils[commonSubmissionUtil].hasOwnProperty("componentDidMount")){
      values = await SubmissionUtils[commonSubmissionUtil]["componentDidMount"](this.state, this.props, this.UpdateState)
    }
    if (values && Object.keys(values).length > 0) {
      Object.entries(values).forEach(([key, value]) => {
        this.state[key] = value;
      })
  }
  }
      if (currentPageIndex === 1 && parentTabPosition === 0) {
        //await this.invokeGetSubmission();
      }
      if(parentTabPosition===2 && prevParentTabPosition!==parentTabPosition)
      {
        let stateString = await lobUtil.getStateList(this.state, this.props, this.UpdateState)
      }
      if(lobUtil.hasOwnProperty('updateStateInfo') && submissionID!='' && submissionID!=undefined && submissionID!=null){
        let stateString = await lobUtil.updateStateInfo(this.state, this.UpdateState)
      }
      ( (this.state.pagelevelValues.SelectedLineOfBusiness && this.state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === 'true') ? this.updatePageMetadata(parentTabPosition + 1) : this.updatePageMetadata(currentPageIndex) )
      // let { ObjectId: objectId, ObjectVerId: objectVerId } = this.props.pageNavigation[currentPageIndex - 1]
      // let response = await constructAndInvokeMetadataCall({ subappName: this.props.pageNavigation[currentPageIndex - 1].SubApplicationName, objectId, objectVerId }, {  EffectiveDate: this.state.values.EffectiveDate }, this.metadataAPI_callback)
      // this.setState({
      //   pageSubAppInfo: { subappName: this.props.pageNavigation[currentPageIndex - 1].SubApplicationName, objectId, objectVerId },
      //   pageMetadata: response,
      //   SubApplicationEntity:response.ApplicationDetail.SubApplicationEntity
      // });

    }
  }

  updateDefaultData = async () => {

    let userInfo = this.props.userInfoReducer;
    if (!Object.keys(userInfo).length) {
      await getAccountProfile()
    }
    let returnValues;
    let defaultData = this.state.defaultData || await getDefaultData(this.state,this.UpdateState);
    defaultData && this.setState((prevState) => {
      let pagelevelValues, transactionPrimaryObject, values;
      pagelevelValues = defaultData.pagelevelValues && { ...defaultData.pagelevelValues, ...prevState.pagelevelValues }
      transactionPrimaryObject = { ...prevState.transactionPrimaryObject, ...pagelevelValues }
      values = defaultData.pagelevelValues && { ...defaultData.values, ...prevState.values }
      returnValues= { pagelevelValues, transactionPrimaryObject, values, defaultData }
    })
    return returnValues
  }

  executeScroll = () => {
    ReactDOM.findDOMNode(this.form.errors[0]).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    if (false || !!document.documentMode) {
      window.scrollBy(0, -120);
    }
  };

  closeDialog = () => {
    this.setState({ messageDialog: false });
    this.props.setSubmissionID("");
    this.props.history.push("/dashboard");
  }

  invokeGetSubmission = async () => {
    this.props.showLoader();

    let pagelevelValues = { ...this.state.pagelevelValues };
    let values = {};
    let transactionPrimaryObject = {};
    const { currentPageIndex } = this.props.UIFlowReducer;
    let pageDetail= this.props.pageNavigation[currentPageIndex - 1]? this.props.pageNavigation[currentPageIndex - 1]: this.props.navigation.productNavigation[this.props.metadataReducer.lob][currentPageIndex - 1]
    let getSubmissionRequest = JSON.parse(
      JSON.stringify(require("../../../../runtime/models/GetSubmission_WF.json"))
    );
    getSubmissionRequest["OwnerId"] = sessionStorage.getItem("OwnerID");
    // let getSubmissionRequest = await getJSONRequestFromAPI(
    //   "GetSubmissionV1_WF", this.props.UIFlowReducer.productDetails
    // );
    getSubmissionRequest["EventName"] = 'SolartisCommonMaster_WF';
    getSubmissionRequest["EventAction"] = pageDetail.getSubmissionAction
    getSubmissionRequest["TargetEventName"] = pageDetail.getSubmissionEvent
    getSubmissionRequest["ID"] = this.props.UIFlowReducer.submissionID;

    let SubmissionResponse = {}
    SubmissionResponse = await commonProductAxiosInstance
      .post("core/invokeWorkflowEvent/V4", getSubmissionRequest)
      .then(async (response) => {
        let submissionRes = response.data;
        if(submissionRes.hasOwnProperty('ResponseStatus') &&  submissionRes.ResponseStatus.toLowerCase() === "failed"){
          this.setState({ messageDialog: true, infoDialog: "Alert!", contentDialog: "Error: Submission is not found." });
          console.log("Response Failed");
          this.props.hideLoader();
        }

        //Update PageNav
        let productPage;
        if(this.state.pagelevelValues.SelectedLineOfBusiness && this.state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === "true"){
          // productPage = this.props.navigation.productNavigation[submissionRes.SelectedLineOfBusiness[0].LOBName + '_' + this.props.UIFlowReducer.productDetails.ProductVerNumber] ;
        } else {
          productPage = this.props.navigation.productNavigation[submissionRes.SelectedLineOfBusiness[0].LOBName];
        }
        let pageNavigation = productPage;
        if(productPage&&productPage[currentPageIndex].IncludeForwardNav)
        {
          this.updatePageNavigation(pageNavigation);
          this.props.solartisUIPageNavigationStatus(submissionRes.PageNumber);
        }
        Object.keys(submissionRes).map((responsekey) =>{

          if (
            typeof submissionRes[responsekey] === 'object' &&
            !Array.isArray(responsekey) &&
            responsekey !== null
        ) {
           if(responsekey === "SelectedLineOfBusiness")
           {
            let lobValues = "",lobarray=[]
            submissionRes[responsekey].map((lob) => {
              lobValues = lob["LOBName"];
              lobarray.push(lob["LOBName"])
            });
            let temp = {};
            if(submissionRes[responsekey].length>1|| (this.state.pagelevelValues.SelectedLineOfBusiness && this.state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === "true"))
            {
              values["LOBName"] = lobarray;
              temp["LOBName"] = lobarray;
            }
            else
            {
            values["LOBName"] = lobValues;
            temp["LOBName"] = lobValues;
            }
            if (!pagelevelValues[responsekey]) {
              pagelevelValues[responsekey] = {};
            }
            pagelevelValues[responsekey] = temp;
           }else
           {
            Object.keys(submissionRes[responsekey]).map((key, value) => {
              let attributeValue = submissionRes[responsekey][key];

                values[key] = attributeValue;
                if (!pagelevelValues[responsekey]) {
                  pagelevelValues[responsekey] = {};
                }
                pagelevelValues[responsekey][key] = attributeValue;


            });
           }
        }
        })

        this.setState(
          {
            pagelevelValues: pagelevelValues,
            values: values,
            transactionPrimaryObject: pagelevelValues,
          },
          async () => {
            this.props.setCreateSubmissionResponse(submissionRes);
            this.props.setLOB(this.state.values["LOBName"]);

            if (submissionRes.UnderwritingQuestions)
              this.props.setUndewritingQuestionsID(
                submissionRes.UnderwritingQuestions.ID
              );
            this.props.hideLoader();
            // let productDetailsResponse = await this.invokeGetProductDetails(this.state.values["LOBName"]);

            let {ObjectId: objectId, ObjectVerId: objectVerId} = this.props.pageNavigation[currentPageIndex - 1]
            let response = await constructAndInvokeMetadataCall({subappName: this.props.pageNavigation[currentPageIndex - 1].SubApplicationName, objectId, objectVerId}, { transactionPrimaryObject: this.state.transactionPrimaryObject, EffectiveDate: this.state.values.EffectiveDate}, this.metadataAPI_callback)
            this.setState({
              pageMetadata: response,
            });
          }
        );
        let productDetailsResponse = await this.invokeGetProductDetails(this.state.values["LOBName"]);
        return response.data
      })
      .catch((err) => {
        console.log(err);
        this.props.hideLoader();
      });
      return SubmissionResponse;

  };

  invokeGetAccountAPI = (AccountNumber) => {
    let request = require("../../../models/getAccount.json");
    request["ID"] = AccountNumber;
    getAccountAxios.post("core/invokeWorkflowEvent", request).then(
      (response) => {
        if(response.data.hasOwnProperty('ResponseStatus') &&  response.ResponseStatus.toLowerCase() === "failed"){
          console.log("Response Failed");
          triggerToast("Response Failed");
      }else
      {
        this.bindAccountInformation(response.data);
      }
      },
      (error) => { }
    );
  };

  bindAccountInformation = (accountInfo) => {
    let values;
    Object.keys(accountInfo).map((key, value) => {
      let attributeValue = accountInfo[key];
      this.setState((prevState) => {
        values = Object.assign({}, prevState.values);
        values[key] = attributeValue;
        return { values };
      });
    });
  };


  metadataAPI_callback = (request, response) => {
    // this.setState({
    //   pageMetadata: response,
    // });
  };

  updatePageNavigation = async (pageNavigation) => {

    if(this.state.pagelevelValues.SelectedLineOfBusiness && this.state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === 'true'){
      await this.props.setPageNavigation(pageNavigation);
    }else{
      let pageNav = Object.assign({}, this.props.navigation);
      pageNav.pageNavigation[2].children[2].children = pageNavigation;
      await Promise.all([this.props.setNavigation(pageNav), this.props.setPageNavigation(pageNavigation)]);
      return
    }


  };


  // updatePageNavigation = async (pageNavigation) => {

  //   let pageNav = Object.assign({}, this.props.navigation);
  //   pageNav.pageNavigation.submission.sidebar[1].children = pageNavigation;
  //   this.props.setNavigation(pageNav);
  //   this.props.setPageNavigation(pageNavigation);
  // };


  setParentTabEnable=()=>{
    let navigation=this.props.navigation
    let result=this.props.navigation.TabNavigation.map((value)=>{value.disabled=false; return value})
    navigation.TabNavigation=result
    this.props.setNavigation(navigation)
  }
  onValidationFormSubmit = async (e) => {
    try{
    e.preventDefault();
    e.stopPropagation();
    const reduxState = store.getState();
    if(reduxState.metadataReducer.lob==='PL' && !this.props.pageInfo.SkipNextValidation){
      if((reduxState.metadataReducer.isLocationSaved===true && this.props.UIFlowReducer.currentPageIndex===3) || (reduxState.metadataReducer.isLocationSaved===undefined && this.props.UIFlowReducer.currentPageIndex===3)) {
        triggerToast('Please save the location details to proceed.','');
        this.UpdateState("canProceed", false, true);
        return
      }
      if((reduxState.UIFlowReducer.isUnderwritingDetailsSaved===true && this.props.UIFlowReducer.currentPageIndex===3) || (reduxState.metadataReducer.isLocationSaved===undefined && this.props.UIFlowReducer.currentPageIndex===3) || (reduxState.UIFlowReducer.isUnderwritingDetailsSaved===undefined && this.props.UIFlowReducer.currentPageIndex===3)) {
        triggerToast('Please review and save the underwriting questions to proceed.' )
        this.UpdateState("canProceed", false, true);
        return
      }
      if(reduxState.UIFlowReducer.formValidatorIndex > 0) {
        triggerToast('Please review and save the details to proceed.' )
        this.UpdateState("canProceed", false, true);
        return
      }
    }
    if (
      document.activeElement.id &&
      document.activeElement.id.includes("childform")
    ) {
      this.UpdateState("canProceed", true, true);
    } else {
      const { currentPageIndex } = this.props.UIFlowReducer;
      if(this.props.pageNavigation[currentPageIndex - 1].reduxSave === 'submissionState'){
        this.props.showLoader();
        await multiLobApi.setSubmissionState({...this.state, pageMetadata : {}});
        this.props.hideLoader();
      }else if( this.props.pageNavigation[currentPageIndex - 1].reduxSave === 'lobState' ) {
        await multiLobApi.setLobStateValue(this.props.metadataReducer.lob, {...this.state, pageMetadata : {}});
        // await multiLobApi.setLobStateValue(this.props.metadataReducer.lob, {...this.state, primaryState:this.props.metadataReducer.primaryState, policycoverageID:this.props.metadataReducer.policycoverageID, underwritingquestionsID:this.props.metadataReducer.underwritingquestionsID ,pageMetadata : {}});
      }
      if (
        this.props.pageNavigation[currentPageIndex - 1].action &&
        this.props.pageNavigation[currentPageIndex - 1].action !== ""
      ) {
        await this.handleTransaction(this.props.pageNavigation[currentPageIndex - 1]);
      }
      if (
        this.props.pageNavigation[currentPageIndex - 1].nextNodeIndex &&
        this.props.pageNavigation[currentPageIndex - 1].nextNodeIndex !== ""
        //&& !document.activeElement.innerText.includes("Save")
      ) {
        this.jumpToStep(
          this.props.pageNavigation[currentPageIndex - 1].nextNodeIndex,this.props.pageNavigation
        );
      }
      if (
        this.props.pageNavigation[currentPageIndex - 1].nextParentIndex &&
        this.props.pageNavigation[currentPageIndex - 1].nextParentIndex !== ""
      ) {
        this.jumpToParent(
          this.props.pageNavigation[currentPageIndex - 1].nextParentIndex
        );
      }
    }
    }
    catch(exception){
      console.log("Error in onValidationFormSubmit", exception)
    }
  };
  navigateToPrevious = (e) => {
    e.preventDefault();
    const { currentPageIndex } = this.props.UIFlowReducer;
    if (
      this.props.pageNavigation[currentPageIndex - 1].previousNodeIndex &&
      this.props.pageNavigation[currentPageIndex - 1].previousNodeIndex !== ""
    ) {
      this.jumpToStep(
        this.props.pageNavigation[currentPageIndex - 1].previousNodeIndex,this.props.pageNavigation
      );
    }
  // }
  };

  jumpToParent = async (index) => {
    let key = this.props.navigation.TabNavigation[index].key
    this.handleParentTabChange(null,index,{key})
  }

  jumpToStep = async  (index,items) => {
    let reduxState=store.getState();
    if(this.state.pagelevelValues.SelectedLineOfBusiness && this.state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === 'true' && this.state.parentTabPosition === 0){
      if(index === 1){
        let state = await multiLobApi.getSubmissionState();
        this.setState({...initialState,...state})
      }
    }
    if(items[index-1].hasOwnProperty('dynamicNav') && items[index-1]['dynamicNav'] && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
      let val=window.location.hash.split('/'),pageData={},redirectRoute='',obj={};
      if(val.length > 1 && val[2]){
          let newVal=val[2];
          pageData = decodeString(newVal);
      }
      if(items[index-2] && items[index-2].URLInfo && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y')
      obj = await dynamicObjectConstruction(items[index-2].URLInfo,{...reduxState.metadataReducer,...reduxState.UIFlowReducer},obj,'response');
      pageData={...pageData,pageName:items[index-1].pageName,...obj}
      const encodedString= await constructEncodedString(pageData); 
          redirectRoute+=`/${val[1]}/${encodedString}`;
          this.props.history.push(redirectRoute);
          this.props.setCurrentPageIndex(index);
    }else
      this.props.setCurrentPageIndex(index);

  };
  updatePageMetadata = async (index) => {
    this.UpdateState('loading',true,true)
    const { currentPageIndex } = this.props.UIFlowReducer;
    if(this.props.pageNavigation[currentPageIndex - 1].SubApplicationName && this.props.pageNavigation[currentPageIndex - 1].SubApplicationName.length!==0){

      let {ObjectId: objectId, ObjectVerId: objectVerId} = this.props.pageNavigation[currentPageIndex - 1];
      let response = await constructAndInvokeMetadataCall({subappName: this.props.pageNavigation[currentPageIndex - 1].SubApplicationName, objectId, objectVerId}, { transactionPrimaryObject: {...this.state.transactionPrimaryObject, ...this.props.productDetails}, EffectiveDate: this.state.values.EffectiveDate}, this.metadataAPI_callback)
      this.setState({
        pageSubAppInfo: { subappName: this.props.pageNavigation[currentPageIndex - 1].SubApplicationName, objectId, objectVerId },
        pageMetadata: response,
        SubApplicationEntity:response.ApplicationDetail.SubApplicationEntity
      });
    }
    this.UpdateState('loading',false,true)
}
  updateValues = (key, value) => {
    let attributeName = key;
    let attributeValue = value;

    let temp = {
      label: key,
      value: value,
    };

    this.setState((prevState) => {
      let values = Object.assign({}, prevState.values); // creating copy of state variable jasper
      let savedValues = Object.assign({}, prevState.savedValues);
      values[attributeName] = attributeValue; // update the name property, assign a new value
      savedValues[attributeName] = temp;

      let { currentPageIndex } = this.props.UIFlowReducer, { pageNavigation } = this.state;
      let subappName =
        this.props.pageNavigation[currentPageIndex - 1].SubApplicationName;
      let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
      //pagelevelValues[subappName][attributeName] = attributeValue;

      if (!pagelevelValues[subappName]) {
        pagelevelValues[subappName] = {};
      }
      pagelevelValues[subappName][attributeName] = attributeValue;

      return { values, savedValues, pagelevelValues }; // return new object jasper object
    });
  };

    handleInputChange = (event,component) => {
      let attributeName = event.target.name;
      let attributeValue = event.target.value;
      let { currentPageIndex } = this.props.UIFlowReducer;;

      this.setState((prevState) => {
        let values = Object.assign({}, prevState.values); // creating copy of state variable jasper
        if (
          currentPageIndex === 1 &&
          (attributeName === "FirstName" ||
            attributeName === "AccountName" ||
            attributeName === "FirstName1")
        ) {
          values["AccountFullName"] = attributeValue;
        }
        values[attributeName] = attributeValue; // update the name property, assign a new value

        let subappName =
        component.SubApplicationName;
        let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
        // pagelevelValues[subappName][attributeName] = attributeValue;

        if (!pagelevelValues[subappName]) {
          pagelevelValues[subappName] = {};
        }
        pagelevelValues[subappName][attributeName] = attributeValue;
        return { values, pagelevelValues }; // return new object jasper object
      });
    };

    handleGoogleRegion = (addressData, component) => {
      this.setState((prevState) => {
        let pagelevelValues = JSON.parse(
          JSON.stringify(prevState.pagelevelValues)
        );
        let transactionPrimaryObject = JSON.parse(
          JSON.stringify(prevState.transactionPrimaryObject)
        );
        let subappName = component.SubApplicationName;
        if (!pagelevelValues[subappName]) {
          pagelevelValues[subappName] = {};
        }
        if (!transactionPrimaryObject[subappName]) {
          transactionPrimaryObject[subappName] = {};
        }
        pagelevelValues[subappName] = { ...addressData };
        transactionPrimaryObject[subappName] = { ...addressData };
        return { pagelevelValues, transactionPrimaryObject };
      });
    };
    constructLOBName=(prdDetails)=>{
      let result=[];
      prdDetails.forEach( lob => {
        result.push(this.props.navigation[lob.LOBName+"_"+lob.ProductVerNumber])
      })
      return result;
    }

    handleRTEChange = (value, component) => {
      let attributeName = component.AttributeName
      let attributeValue = value.toString('html')
      this.setState(
        (prevState) => {
          let subappName = component.SubApplicationName;
          let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
          let transactionPrimaryObject = JSON.parse(
          JSON.stringify(prevState.transactionPrimaryObject))
        if (!pagelevelValues[subappName]) {
          pagelevelValues[subappName] = {};
        }
        pagelevelValues[subappName][attributeName] = attributeValue;

        if (!transactionPrimaryObject[subappName]) {
          transactionPrimaryObject[subappName] = {};
        }
        transactionPrimaryObject[subappName][attributeName] = attributeValue;
        return {
          pagelevelValues,
          transactionPrimaryObject,
        };
      })
  }
  updateStateValues = (obj) => {

    this.setState({
        ...this.state,
        ...obj
    })
}
setprimaryState=(value)=>{
  this.props.setprimaryState(value);
}
  switchtoTabNavigation=()=>{
    let parentTabNav =this.props.navigationReducer.navigation.TabNavigation[this.state.parentTabPosition] ;
      let pageNav = parentTabNav.ChildrenTabNavigation ? parentTabNav.ChildrenTabNavigation[this.state.tabPosition].Details : parentTabNav.Details ;
      this.props.setPageNavigation(cloneDeep(pageNav));
      this.props.setNavigation(cloneDeep(this.props.navigationReducer.navigation));
      let LOBList=this.state.values["LOBName"]
      if(LOBList&&LOBList.constructor!==Array)
      {
        LOBList=[LOBList]
      }
      if(LOBList&&LOBList.length!=0)
      this.invokeGetProductDetails(LOBList)
  }
  submission_subapp_empty=(value,component)=>{
    Object.keys(this.state.pagelevelValues[component.SubApplicationName]).map((subkeys) => {
      if (subkeys !== component.AttributeName){
        delete this.state.pagelevelValues[component.SubApplicationName][subkeys];
        delete this.state.transactionPrimaryObject[component.SubApplicationName][subkeys];
        delete this.state.values[subkeys];
      }
    });
 }
  switchtoNavigation=()=>{
         let pageNav = cloneDeep(this.props.navigationReducer.navigation);
        this.props.setNavigation(pageNav);
        this.props.setPageNavigation(cloneDeep(pageNav.pageNavigation[2].children[2].children));
  }

  handleInputChangeV2 = async (value,component,isDynamicTable,datatableRowindex,Dynamicprops) => {

    let { currentPageIndex } = this.props.UIFlowReducer;
    let {ObjectId: objectId, ObjectVerId: objectVerId} = this.props.pageNavigation[currentPageIndex - 1]
    let subAppInfo= {subappName: this.props.pageNavigation[currentPageIndex - 1].SubApplicationName, objectId, objectVerId}
     await handleInputChangeV2(value,component,this.props,this.state,this.functions,subAppInfo,undefined,true,Dynamicprops)
     //temporary change
    let keys = Object.keys(value);
    let attributeName = keys[0];
    if (attributeName === "IsMultiLob") {
     if(value[attributeName] === "true"){
      this.switchtoTabNavigation()
     } else{
      this.switchtoNavigation()
     }
    }
  };
  navigateToNext = (e) => {
    e.preventDefault();
    const { currentPageIndex } = this.state;
    let nextPageIndex = currentPageIndex + 1
    this.setState({
      currentPageIndex: nextPageIndex,
    });
    this.props.setCurrentPageIndex(nextPageIndex)
  };

  handleTransaction = async(pageDetail) => {
    const eventName = pageDetail.action;
    const eventNames = eventName.split(",");
    let { currentPageIndex } = this.props.UIFlowReducer;
    let productInfo;
    if(this.state.pagelevelValues.SelectedLineOfBusiness && this.state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === 'true' ){
      productInfo = this.props.navigationReducer.pageNavigation;
    } else {
        productInfo = this.props.navigationReducer.navigation.productNavigation[this.props.metadataReducer.lob];
    }
    const lobUtil = this.props.productDetails.hasOwnProperty("LineOfBusiness") ? LOBUtil[this.props.productDetails["LineOfBusiness"]] || {} : {};

    await asyncForEach(eventNames, async (eventname) => {
      if(SubmissionUtils.hasOwnProperty(eventname))
      await SubmissionUtils[eventname](this.state,this.props,pageDetail,productInfo,lobUtil, this.UpdateState)
    });
  };

  handleAutoCompletechange = async(value, item, component) => {
    let autoComplete_values = {};
    autoComplete_values=value;
      this.setState((prevState) => {
        let values = Object.assign({}, prevState.values);
        let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
        let transactionPrimaryObject = JSON.parse(JSON.stringify(prevState.transactionPrimaryObject));
        if(!pagelevelValues[component.SubApplicationName])
        {
          pagelevelValues[component.SubApplicationName]={}
        }
        pagelevelValues={...pagelevelValues,[component.SubApplicationName] : {...pagelevelValues[component.SubApplicationName],...value}}
        if(!transactionPrimaryObject[component.SubApplicationName])
        {
          transactionPrimaryObject[component.SubApplicationName]={}
        }
        transactionPrimaryObject={...transactionPrimaryObject,[component.SubApplicationName] : {...transactionPrimaryObject[component.SubApplicationName],...value}}
        values = Object.assign({}, values, autoComplete_values);
        switch (component.SubApplicationName){
          case 'AgencyInformation':
            this.props.setAgencyDetails(pagelevelValues[component.SubApplicationName]);
            break;
          case 'AgentInformation':
            this.props.setAgentDetails(pagelevelValues[component.SubApplicationName]);
            this.props.setAgentID(pagelevelValues[component.SubApplicationName]["ID"])
            break
        }
        return {
          values,
          pagelevelValues,
          transactionPrimaryObject,
        };
      }, async() => {
        if (component.AjaxNeeded==='true') {
          let subAppInfo=this.state.pageSubAppInfo,response={};
          let transactionPrimaryObject={...this.state.transactionPrimaryObject}
          if(!transactionPrimaryObject[component.SubApplicationName])
            {
              transactionPrimaryObject[component.SubApplicationName]={}
            }
          response= await constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: transactionPrimaryObject });
          this.setState({
            pageMetadata: response,
          });
          }
      });
  };


  handleDateChange = (event, value, AttributeName, component) => {
    //console.log("From handleDatechange",value);
    let attributeName = AttributeName;
    let formattedDatevalue = value;
    let {OnChangeAction = "" } = component;
    if(OnChangeAction){
      let lobUtil = this.props.productDetails.hasOwnProperty("LineOfBusiness") ? LOBUtil[this.props.productDetails["LineOfBusiness"]] || {} : {};
      lobUtil[OnChangeAction] && lobUtil[OnChangeAction].constructor === Function && lobUtil[OnChangeAction](component, { [AttributeName]: value }, this.updateValues)
    }

    this.setState((prevState) => {
      let temp = {
        label: AttributeName,
        value: formattedDatevalue,
      };
      let values = Object.assign({}, prevState.values);
      let savedValues = Object.assign({}, prevState.savedValues);
      values[attributeName] = formattedDatevalue;
      savedValues[attributeName] = temp;

      let subappName = component.SubApplicationName;
      let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
      if (!pagelevelValues[subappName]) {
        pagelevelValues[subappName] = {};
      }
      pagelevelValues[subappName][attributeName] = formattedDatevalue;

      return { values, savedValues, pagelevelValues };
    });
  };

  deleteArrayValue = (key, value, deleteApi) => {
    let arr = [];
    let { ID, uid } = value;
    let checkKey = ID ? 'ID' : uid ? 'uid' : null;

    if (
      this.state.values[key] !== undefined &&
      Array.isArray(this.state.values[key])
    ) {
      arr = [...this.state.values[key]];

      if (checkKey !== null) {
        arr = arr.filter((el) => {
          return el[checkKey] !== value[checkKey];
        });
      }

      // console.log("delete array", arr);
      this.state.values[key] = [...arr];
      this.state.pagelevelValues[key] = [...arr];
    }
    if (deleteApi) {
      let submissionID =
        this.props.metadataReducer.submissionResponse.SubmissionID;
      let EventName =
        this.props.pageNavigation[this.props.UIFlowReducer.currentPageIndex - 1]
          .deleteRowEventName;
      this.deleteRowApi(ID, submissionID, EventName);
    }
  };

  deleteRowApi = (ID, submissionID, EventName) => {
    let request = {};
    request.ROOTID = submissionID;
    request.ID = ID;
    request.EventName = EventName;
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["ServiceRequestDetail"] = Object.assign(
      {},
      getServiceRequestDetail()
    );
    AxiosInstance.post("/core/invokeWorkflowEvent", request)
      .then((response) => {
        // console.log("[newgin] delete row Api", response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateArrayValueOnState = (key, value) => {
    let arr = [];
    let push = false;

    let { ID, uid } = value;
    let checkKey = ID ? 'ID' : uid ? 'uid' : null;

    if (Array.isArray(value)) {
      arr = [...value];
    } else {
      if (
        this.state.values[key] !== undefined &&
        Array.isArray(this.state.values[key])
      ) {
        arr = this.state.values[key];
      }
      if (value["isExpanded"]) {
        value["isExpanded"] = value["isExpanded"].toString();
      }
      if ((checkKey === 'ID' && ID !== undefined) || (checkKey === 'uid' && uid !== undefined)) {
        arr.forEach((el, idx) => {
          if (value[checkKey] === el[checkKey]) {
            arr[idx] = value;
            push = true;
          }
        });
      }
      if (push === false) {
        arr.push(value);
      }
    }
    let values = Object.assign({}, this.state.values);
    let pagelevelValues = Object.assign({}, this.state.pagelevelValues);
    pagelevelValues[key] = arr;
    values[key] = arr;
    this.setState({ values, pagelevelValues });
  };

  handleHiddenInputChange = (component, isDynamicTable, datatableRowindex) => {
    let attributeName = component.AttributeName;
    let attributeValue = component.DefaultValue;
    if (component.SubApplicationAttributeValueList.length == 2) {
      attributeValue = component.SubApplicationAttributeValueList[1].Value;
    }
    this.setState((prevState) => {
      let temp = {
        label: component.ComponentLabel,
        value: attributeValue,
      };
      let values = Object.assign({}, prevState.values); // creating copy of state variable jasper
      let savedValues = Object.assign({}, prevState.savedValues); // creating copy of state variable jasper
      values[attributeName] = attributeValue; // update the name property, assign a new value
      savedValues[attributeName] = temp;

      let { currentPageIndex } = this.props.UIFlowReducer;
      let { SubApplicationName: subappName } = component;
      let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
      //pagelevelValues[subappName][attributeName] = attributeValue;
      if (typeof subappName !== "string") {
        return;
      }
      if (!pagelevelValues[subappName]) {
        pagelevelValues[subappName] = {};
      }
      pagelevelValues[subappName][attributeName] = attributeValue;

      return { values, savedValues, pagelevelValues, transactionPrimaryObject: pagelevelValues }; // return new object jasper object
    });
  };

  updateValueWithSubplicationName = (updatedValue, component) => {
    let SubapplicationName = component.SubApplicationName;
    let attributeName = component.AttributeName;
    let attributeValue = component.DefaultValue;
    this.setState((prevState) => {
      let temp = {
        label: component.ComponentLabel,
        value: attributeValue,
      };
      let values = Object.assign({}, prevState.values, updatedValue);
      let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
      let savedValues = Object.assign({}, prevState.savedValues);
      savedValues[attributeName] = temp;
      if (!pagelevelValues[SubapplicationName]) {
        pagelevelValues[SubapplicationName] = {};
      }
      pagelevelValues[SubapplicationName] = Object.assign(
        {},
        prevState.pagelevelValues[SubapplicationName],
        updatedValue
      );
      return {
        values,
        pagelevelValues,
        savedValues,
        transactionPrimaryObject: pagelevelValues,
      };
    },()=>{
      if(attributeName === "IsMultiLob" && attributeValue==='true')
      this.switchtoTabNavigation()
      else if(attributeName === "IsMultiLob" && attributeValue==='false')
          this.switchtoNavigation();
    });
  };

  bindValues = (detail, response) => {
    for (var key in response) {
      if (!response.hasOwnProperty(key)) {
        continue;
      }
      if (response[key] instanceof Object) {
        this.bindValues(detail, response[key]);
      } else {
        detail[key] = response[key];
      }
    }
    return detail;
  };

  invokeGetProductDetails = async (LOBName) => {
    let getProductDetailsRequest = {};
    let SelectedLineOfBusiness = [];
    let getProductDetailsResponse = {};
    let productDetails = {};

    getProductDetailsRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    getProductDetailsRequest = Object.assign({}, getProductDetailsRequest, {
      OwnerId: sessionStorage.getItem("OwnerID"),
      EventName: "SolartisCommonMaster_WF",
      TargetEventName : "GetProductDetails",
      EventAction : "GetProductDetailsV1"
    });

    if(LOBName.constructor === Array && this.state.values.IsMultiLob === "true"){
      let lob;
      if(LOBName.length > 0 ){
        let Lobarr = LOBName.map(lob => ( {['LOBName'] : lob} ));

        getProductDetailsRequest["SelectedLineOfBusiness"] = Lobarr;
        this.props.showLoader();
        try {
          getProductDetailsResponse = await commonProductAxiosInstance.post(
            "/core/invokeWorkflowEvent/V4",
            getProductDetailsRequest
          );
          getProductDetailsResponse = getProductDetailsResponse.data;
        } catch (e) {
          console.error("Error in GetProductDetails API", e.message);
        }finally{
          this.props.hideLoader();
        }
        if (getProductDetailsResponse.hasOwnProperty("SelectedLineOfBusiness") && getProductDetailsResponse["SelectedLineOfBusiness"].length > 0) {
          productDetails = getProductDetailsResponse.SelectedLineOfBusiness;

          let curretnLobsNav = this.constructLOBName(productDetails)
          let pageNav = Object.assign({}, this.props.navigation);
          if(pageNav.TabNavigation){
          pageNav.TabNavigation[1].ChildrenTabNavigation = curretnLobsNav;
          this.props.setNavigation(pageNav);
          this.props.setSelectedLOB(productDetails);
          this.props.setProductDetails(productDetails[0]);
          }
        }

      }else{
        this.props.setSelectedLOB([]);
        this.props.setProductDetails({});
      }

    }

    else{

      SelectedLineOfBusiness.push({
        LOBName: LOBName,
      });
      getProductDetailsRequest["SelectedLineOfBusiness"] = SelectedLineOfBusiness;
      this.props.showLoader();
      try {
        getProductDetailsResponse = await commonProductAxiosInstance.post(
          "/core/invokeWorkflowEvent/V4",
          getProductDetailsRequest
        );
        getProductDetailsResponse = getProductDetailsResponse.data;
      } catch (e) {
        console.error("Error in GetProductDetails API", e.message);
      } finally{
        this.props.hideLoader();
      }
      if (getProductDetailsResponse.hasOwnProperty("SelectedLineOfBusiness") && getProductDetailsResponse["SelectedLineOfBusiness"].length > 0) {
        productDetails = getProductDetailsResponse.SelectedLineOfBusiness[0];
      }
      this.props.setLOB(productDetails.LOBName);
      this.props.setProductDetails(productDetails);
    }
    return productDetails
  };

  onvalidationFormError = (error) => {
    console.log(error);
    this.UpdateState("canProceed", false, true);
  };

  UpdateState = (statename, value, rerender) => {
    if (rerender) {
      this.setState({
        [statename]: value,
      });
    } else {
      this.state[statename] = value;
    }
  };

  enableEditOption =  (component) => {
    this.setState((prevState) => {
      let pagelevelValues = JSON.parse(JSON.stringify(prevState.pagelevelValues));
      let transactionPrimaryObject = JSON.parse(JSON.stringify(prevState.transactionPrimaryObject));
      if (!pagelevelValues[component.SubApplicationName]) {
        pagelevelValues[component.SubApplicationName] = {};
      }
      if (!transactionPrimaryObject[component.SubApplicationName]) {
        transactionPrimaryObject[component.SubApplicationName] = {};
      }
      pagelevelValues[component.SubApplicationName][component.AttributeName] = 'true';
      transactionPrimaryObject[component.SubApplicationName][component.AttributeName] = 'true';
      return { pagelevelValues, transactionPrimaryObject }
    }, async() => {
      let { currentPageIndex } = this.props.UIFlowReducer;
      let {ObjectId: objectId, ObjectVerId: objectVerId} = this.props.pageNavigation[currentPageIndex - 1]
      let response= await constructAndInvokeMetadataCall({subappName: this.props.pageNavigation[currentPageIndex - 1].SubApplicationName, objectId, objectVerId}, { transactionPrimaryObject: this.state.transactionPrimaryObject, EffectiveDate: this.state.values.EffectiveDate}, this.metadataAPI_callback)
      this.setState({
        pageMetadata: response,
      });
    })
  }

  handlePersistValue = (value, component) => {
    handlePersistValue(value, component, this.state, this.UpdateState);
  }

  handleTabChange = async (event, newValue, parent) => {

    let {activatePreviousLOBInfo} = this.state;

    newValue = activatePreviousLOBInfo === true ? this.props.multiFlowReducer.previousLOBInfo.currentTABIndex : newValue;
    let parentKey = event ? event.target.attributes.parentKey.value : null;
    let parentTabPosition = (parent && parent.parentTabPosition) ? parent.parentTabPosition : this.state.parentTabPosition
    let productNavigation = this.props.navigation.TabNavigation[parentTabPosition].ChildrenTabNavigation ? this.props.navigation.TabNavigation[parentTabPosition].ChildrenTabNavigation[newValue].Details : {} ;
    let primaryState = "";

    if((parent && parent.key === 'SubmissionLob') || parentKey === 'SubmissionLob'){
      this.props.setprimaryState(primaryState);
      !this.state.activatePreviousLOBInfo && this.props.setPreviousLOBInfo({ currentLOBName: this.props.multiFlowReducer.activeLOB.LOBName, currentTABIndex: newValue, currentPageIndex: this.props.UIFlowReducer.currentPageIndex})
    }
    if(parent && parent.key === "SubmissionProductBrowse")
    {
      this.props.setPreviousLOBInfo({ currentLOBName: this.props.multiFlowReducer.activeLOB.LOBName, currentTABIndex: this.state.tabPosition, currentPageIndex: this.props.UIFlowReducer.currentPageIndex})
    }

    if((parent && parent.key === 'SubmissionLob') || parentKey === 'SubmissionLob'){
      let LOBName = this.props.multiFlowReducer.selectedLOB[newValue].LOBName ;
      this.props.setProductDetails(this.props.multiFlowReducer.selectedLOB[newValue]);
      this.props.setActiveLob(this.props.multiFlowReducer.selectedLOB[newValue]);
      // let productNavigation = this.props.navigation.productNavigation[LOBName];
      //this.setState({tabPosition : newValue});
      // console.log(this.props.navigation.GeneralLiability.Details)
      let lobState = await multiLobApi.getLobStateValue(LOBName);
      let submissionState = await multiLobApi.getSubmissionState();
      if (this.state.pagelevelValues.SelectedLineOfBusiness) {
        //if(!submissionState)
         submissionState.pagelevelValues.SelectedLineOfBusiness=this.state.pagelevelValues.SelectedLineOfBusiness
        //  else
        //  {
          //  console.log("came to else part")
          // submissionState= {pagelevelValues:{}}
          // submissionState.pagelevelValues.SelectedLineOfBusiness=this.state.pagelevelValues.SelectedLineOfBusiness
        //  }
        }
      let stateval = Object.assign({},this.state,submissionState,lobState,{tabPosition : newValue, parentTabPosition : parentTabPosition});
      let currentstate=cloneDeep(this.state);
      //this.props.setLOB(LOBName);
      this.updatePageNavigation(productNavigation);
        //this.props.setCurrentPageIndex( activatePreviousLOBInfo === true ? this.props.multiFlowReducer.previousLOBInfo.currentPageIndex : 1)
      //this.state={...stateval}
      this.setState({...stateval},  () =>{
        //this.props.setLOB(LOBName);
        //this.updatePageNavigation(productNavigation);
        this.props.setCurrentPageIndex( activatePreviousLOBInfo === true ? this.props.multiFlowReducer.previousLOBInfo.currentPageIndex : 1)
       });
      if(this.props.navigation.TabNavigation[this.state.parentTabPosition].key === 'SubmissionLob'){
       await multiLobApi.setLobStateValue(this.props.metadataReducer.lob, {...currentstate, pageMetadata:{}})
        // await multiLobApi.setLobStateValue(this.props.metadataReducer.lob, {...this.state, primaryState:this.props.metadataReducer.primaryState, policycoverageID:this.props.metadataReducer.policycoverageID, underwritingquestionsID:this.props.metadataReducer.underwritingquestionsID ,pageMetadata:{}})
      }
      this.props.setLOB(LOBName);
    }else{
      this.updatePageNavigation(productNavigation);
        this.props.setCurrentPageIndex( activatePreviousLOBInfo === true ? this.props.multiFlowReducer.previousLOBInfo.currentPageIndex : 1)
      this.setState({tabPosition : newValue, parentTabPosition : parentTabPosition})
    }

};

navigateToPreviousLOB = async () =>
{
  this.setState({
    activatePreviousLOBInfo: true
  })
  let productNavigation = this.props.navigation.TabNavigation[this.state.parentTabPosition - 1].ChildrenTabNavigation ? this.props.navigation.TabNavigation[this.state.parentTabPosition - 1].ChildrenTabNavigation[0].Details : {} ;
  this.updatePageNavigation(productNavigation);
  if(this.props.multiFlowReducer.previousLOBInfo && this.props.multiFlowReducer.previousLOBInfo.currentPageIndex)
  await this.props.setCurrentPageIndex(this.props.multiFlowReducer.previousLOBInfo.currentPageIndex)
  await this.handleParentTabChange(null, this.state.parentTabPosition - 1, { key : "SubmissionLob" });
}

handleParentTabChange = async (event, newValue,changeObj) => {

  let key = event ? event.target.attributes.keyName.value : changeObj.key;
  let obj = {};
  let {activatePreviousLOBInfo} = this.state;
  obj.tabPosition = 0;
  if( key === 'SubmissionAccountAndInsured'){
    let productNavigation = this.props.navigation.TabNavigation[0].Details ;
    this.updatePageNavigation(productNavigation);
    obj.parentTabPosition = newValue ;
  }
  else{
    !this.state.activatePreviousLOBInfo && this.props.setPreviousLOBInfo({ currentLOBName: this.props.multiFlowReducer.activeLOB.LOBName, currentTABIndex: 0, currentPageIndex: this.props.UIFlowReducer.currentPageIndex})
    this.handleTabChange(null,obj.tabPosition,{ key, parentTabPosition : newValue });
  }

  await this.props.setCurrentPageIndex(activatePreviousLOBInfo === true ? this.props.multiFlowReducer.previousLOBInfo.currentPageIndex : 1)
  this.setState({...obj});
};

  focusToParent = () => {
    // console.log("in Focus to Parent", this.expandPanelRef, ReactDOM.findDOMNode(this.expandPanelRef.current));
    this.expandPanelRef.current && ReactDOM.findDOMNode(this.expandPanelRef.current).scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  getRiskAddress = (value, Component) => {
      try{
      let addressvalue = {};
      let subappname = Component.SubApplicationName;
      let pagelevelValues = this.state.pagelevelValues;
      let transactionPrimaryObject = this.state.transactionPrimaryObject;
      if(value=='Yes'){
      let redux=store.getState();
      let {RiskAddress} = redux.UIFlowReducer;
      if(!RiskAddress)
          return;
      let addressheaders = Component.ItemTipParams.split(",");
          addressheaders.map((addressheader) => {
            let address = addressheader.split("~");
            addressvalue[address[0]] = RiskAddress[address[1]];
        });
        }
        else {
          let addressheaders = Component.ItemTipParams.split(",");
          addressheaders.map((addressheader) => {
          let address = addressheader.split("~");
          addressvalue[address[0]] = ""
          });
        }
        console.log(addressvalue, "getriskaddress")
        pagelevelValues[subappname] = { ...pagelevelValues[subappname], ...addressvalue }
        transactionPrimaryObject[subappname] = { ...pagelevelValues[subappname], ...addressvalue }
        this.UpdateState("transactionPrimaryObject",transactionPrimaryObject,false)
        this.UpdateState("pagelevelValues", pagelevelValues, true)
      }
      catch(err){
          console.log("Error check getRiskAddress", err)
      }
      }
  
  functions={
    updateStateValues:this.updateStateValues, //mandatory
    switchtoNavigation:this.switchtoNavigation,
    switchtoTabNavigation:this.switchtoTabNavigation,
    invokeGetProductDetails:this.invokeGetProductDetails,
    submission_subapp_empty:this.submission_subapp_empty,
    setprimaryState:this.setprimaryState,
    UpdateState : this.UpdateState,
    getRiskAddress: this.getRiskAddress
  }
  onInsuredSearch = () =>{
    this.setState({
      displayInsuredSearch: true
    })
  }

  UpdateState = (statename, value, rerender) => {
    if (rerender) {
        this.setState({
            [statename]: value
        });
    } else {
        this.state[statename] = value;
    }
}
  addRow = (key) => {
    let uid = uuidv4();
    this.updateArrayValueOnState(key, { uid, isExpanded: "true" }, "true");
   }

   onExpand = (key, value, isExpand) => {
    let { ID, uid } = value;
    let checkKey = ID ? 'ID' : uid ? 'uid' : null;

    if (checkKey !== null) {
        this.setState(prevState => {
            let arr = prevState[key];
            arr.map((el, idx) => {
                if (el[checkKey] === value[checkKey]) {
                    arr[idx]['isExpanded'] = isExpand.toString();
                    return arr;
                }
            })
            return ({ [key]: arr });
        })
    }
}

onClickSave = (key, subname, value, expand, ID) => {
  // this.state.currentIDs.push(ID);
  this.props.updateArrayValueOnState(subname, value);
  this.updateArrayValueOnState(key, value, expand);
}

   TableControls = {
    "tableUtils": {
        "AddRow": this.addRow,
        "onExpand": this.onExpand,
        "onChildSave": this.onClickSave,
        "EditRow": "",
        "OnDetailEdit": "",
        "redirectTo": ""
    },
    "TableOptions": {
        "ChildrenSubApplicationName": null,
        "ChildrenComponent": null,
        "AddButtonNeeded": true,
        "SortingNeeded": false,
        "FilteringNeeded": false,
        "FilterIconNeeded": false,
        "rowsPerPageOptions": [5, 10, 25],
        "RowsPerPage": 5,
        "ActionName": "Action",
        "ActionListOptions": [
            {
                "ActionName": "RemoveVehicle",
                "ActionDisplayName": "Delete",
                "OnOptionClick": this.deleteRow
            }
        ]
    }
}
  render() {
     //console.clear();  
      let key = `${this.props.productDetails.LOBName}_submission`;
      let showcontent = this.props.pageNavigation && this.props.pageNavigation.length > 0 && this.props.navigation.productNavigation && (this.props.navigation.productNavigation[key] || this.props.navigation.productNavigation.template)
      let { currentPageIndex } = this.props.UIFlowReducer;
      // console.log("this state in submission->",this.state);
      // console.log("this.props.pageNavigation",this.props.pageNavigation)
      let subapplicationList = [];
      let isSubAppPresent = (this.props.pageNavigation && currentPageIndex && this.props.pageNavigation[currentPageIndex - 1]) && (Array.isArray(this.props.pageNavigation[currentPageIndex - 1].SubApplicationName) && this.props.pageNavigation[currentPageIndex - 1].SubApplicationName.length > 0)
      let IsMultiLob = this.state.pagelevelValues.SelectedLineOfBusiness ? this.state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob : null;
      let { parentTabPosition, tabPosition} = this.state;
      if (this.state.pageMetadata.ApplicationDetail) {
        subapplicationList = this.state.pageMetadata.ApplicationDetail.SubApplicationDetailList;
      }
        let objectId = (currentPageIndex && this.props.pageNavigation && this.props.pageNavigation[currentPageIndex - 1]) ?
        this.props.pageNavigation[currentPageIndex - 1]["ObjectId"] === "API" ? this.props.productDetails.ProductID :
          this.props.pageNavigation[currentPageIndex - 1]["ObjectId"] === "Multi_Api"  ? clientInfo["multiLob_ObjectId"] :
          this.props.pageNavigation[currentPageIndex - 1]["ObjectId"] : null

          let objectVerId =(currentPageIndex && this.props.pageNavigation && this.props.pageNavigation[currentPageIndex - 1]) ?
          this.props.pageNavigation[currentPageIndex - 1]["ObjectVerId"] === "API" ? this.props.productDetails.ProductVerID :
          this.props.pageNavigation[currentPageIndex - 1]["ObjectVerId"] === "Multi_Api"  ? clientInfo["multiLob_ObjectVerId"] :
            this.props.pageNavigation[currentPageIndex - 1]["ObjectVerId"]: null


      const templateRenderNeeded = (this.props.pageNavigation && currentPageIndex && this.props.pageNavigation[currentPageIndex - 1]) && this.props.pageNavigation[currentPageIndex - 1].renderTemplate;
      let template= (this.props.pageNavigation && currentPageIndex && this.props.pageNavigation[currentPageIndex - 1]) && this.props.pageNavigation[currentPageIndex - 1].template ? this.props.pageNavigation[currentPageIndex - 1].template : [];
      const isExpansionPanelNeeded = (this.props.pageNavigation && currentPageIndex && this.props.pageNavigation[currentPageIndex - 1] && this.props.pageNavigation[currentPageIndex - 1].hasOwnProperty('isExpansionPanelNeeded'))? this.props.pageNavigation[currentPageIndex - 1].isExpansionPanelNeeded : true;
      const renderRateAndPreview =  (this.props.pageNavigation && currentPageIndex && this.props.pageNavigation[currentPageIndex - 1] && this.props.pageNavigation[currentPageIndex - 1]['renderRateAndPreview']) || false;
      const dynamicNavigation = (this.props.pageNavigation && currentPageIndex && this.props.pageNavigation[currentPageIndex - 1] && this.props.pageNavigation[currentPageIndex - 1]['dynamicNavigation']) || false;
      const loadNavBtn = (this.props.pageNavigation && currentPageIndex && this.props.pageNavigation[currentPageIndex - 1] && (this.props.pageNavigation[currentPageIndex - 1]['IsNextNeeded'] || this.props.pageNavigation[currentPageIndex - 1]['IsPrevNeeded'])) || false;
      let lobUtil = this.props.productDetails.hasOwnProperty("LOBName") ? LOBUtil[this.props.productDetails["LOBName"]] || {} : {};
      if(this.state.pagelevelValues.SelectedLineOfBusiness && this.state.pagelevelValues.SelectedLineOfBusiness.IsMultiLob === 'true'
      && this.props.pageNavigation && currentPageIndex && this.props.pageNavigation[currentPageIndex - 1] && this.props.pageNavigation[currentPageIndex - 1]["ObjectId"] === "Multi_Api")
      {
        lobUtil=LOBUtil['CP']
      }
      if(this.props.pageNavigation && this.props.pageNavigation[currentPageIndex - 1] && this.props.pageNavigation[currentPageIndex - 1].additionalUtil!=="" && LOBUtil[this.props.pageNavigation[currentPageIndex - 1].additionalUtil])
      lobUtil={...lobUtil,...LOBUtil[this.props.pageNavigation[currentPageIndex - 1].additionalUtil],[this.props.pageNavigation[currentPageIndex - 1].additionalUtil]:{...LOBUtil[this.props.pageNavigation[currentPageIndex - 1].additionalUtil]}};
      let childrenProps = {
        pagelevelValues: this.state.pagelevelValues,
        values: this.state.values,
        savedValues: this.state.savedValues,
        pageNavigation: this.props.pageNavigation,
        currentPageIndex: currentPageIndex,
        loading: this.state.loading,
        parentTabPosition: this.state.parentTabPosition,
        tabPosition: this.state.tabPosition,
        jumpToStep: this.jumpToStep,
        functions:this.functions
      }

     let childrenWithProps = renderChildrenWithPropsV2(this.props, childrenProps);
     let nextNeeded=this.props.pageNavigation && this.props.pageNavigation.length && currentPageIndex && this.props.pageNavigation[currentPageIndex - 1] && this.props.pageNavigation[currentPageIndex - 1].IsNextNeeded;
     let prevNeeded=this.props.pageNavigation && this.props.pageNavigation.length && currentPageIndex && this.props.pageNavigation[currentPageIndex - 1] && this.props.pageNavigation[currentPageIndex - 1].IsPrevNeeded
     let grid_size_button = prevNeeded && nextNeeded ? 6 : 12;
      return (
        <React.Fragment>
          {
            showcontent ? <>
              {
                this.state.messageDialog &&
                <MessageDialog
                  open={this.state.messageDialog}
                  closeDialog={this.closeDialog}
                  messageInfo={this.state.infoDialog}
                  contentInfo={this.state.contentDialog}
                />
              }
              <div className="sticky-tab-navigation">
                {childrenWithProps}
              </div>
              <Card style={{
                boxShadow: "none"
              }}
              >
                <ValidatorForm
                  ref={(f) => (this.form = f)}
                  onSubmit={this.onValidationFormSubmit}
                  onError={(errors) => {
                    this.executeScroll();
                    this.onvalidationFormError(errors);
                  }}
                >
                  {isSubAppPresent && subapplicationList.map((subapplication, subaapplicationIndex) => {
                    let pageName = subapplication.Description;
                    let attributeList = subapplication.AttributeDetailList;
                    let description = subapplication.Description;
                    let templateType = "";
                    let propsObj = {
                      parentProps: this.state,
                      ...lobUtil
                    }
                    templateType = subapplication.Type;
                    const ChildSubApplicationList = subapplication.ChildSubApplicationList ? subapplication.ChildSubApplicationList : [];
                    const stateProps = { ...this.state, utilfunction: lobUtil, templateName: this.props.pageNavigation[currentPageIndex - 1].templateName, "additionalUtil": this.props.pageNavigation[currentPageIndex - 1].additionalUtil };
                    const renderContent = () => (
                      <DynamicComponent
                        pageInfo={this.props.pageInfo}
                        componentProps={stateProps}
                        TableControls={this.TableControls}
                        onInsuredSearch={this.onInsuredSearch}
                        displayInsuredSearch={this.state.displayInsuredSearch}
                        functions={this.functions}
                        components={attributeList}
                        values={this.state.values}
                        createSubmissionRes={this.state.createSubmissionRes}
                        currentPageIndex={currentPageIndex}
                        handlePersistValue={this.handlePersistValue}
                        handleInputChange={this.handleInputChange}
                        handleInputChangeV2={this.handleInputChangeV2}
                        handleRTEChange={this.handleRTEChange}
                        handleAutoCompletechange={this.handleAutoCompletechange}
                        updateValues={this.updateValues}
                        props={this.props}
                        updateState={this.updateState}
                        handleDateChange={this.handleDateChange}
                        pagelevelValues={this.state.pagelevelValues}
                        transactionPrimaryObject={this.state.transactionPrimaryObject}
                        handleHiddenInputChange={this.handleHiddenInputChange}
                        updateValueWithSubplicationName={this.updateValueWithSubplicationName}
                        useaccountaddress={this.state.useaccountaddress}
                        handleGoogleRegion={this.handleGoogleRegion}
                        useinsuredaddress={this.state.useinsuredaddress}
                        disableCopyInsuredAddress={this.state.disableCopyInsuredAddress}
                        isInsuredAddressAdded={this.state.isInsuredAddressAdded}
                        checkCopyInsuredAddress={this.state.checkCopyInsuredAddress}
                        lobUtil={lobUtil}
                        navigateToPrevious={this.navigateToPrevious}
                        navigateToNext={this.navigateToNext}
                        enableEditOption={this.enableEditOption}
                        focusToParent={this.focusToParent}
                        reference={this.form}
                        ChildSubApplicationList={ChildSubApplicationList}
                        componentState={propsObj.parentProps}
                        {...propsObj}
                      />
                    )
                    return (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CardContent className="custom-indentation custom-class">
                          <Grid container spacing={3}>
                            <React.Fragment key={subapplication.SubApplicationVersionId}>
                              <Grid item xs={11} sm={11} md={12} lg={12} style={{  }}>
                                <CardContent className="custom-indentation custom-margin-left">
                                  {(templateType === "Detail" && subapplication.IsEmptyFormNeeded === "Y") ? (
                                    <ChildFormComponent
                                      pageName={pageName}
                                      subapplicationName={subapplication.ApplicationName}
                                      IsSubmitButtonNeeded={subapplication.IsSubmitButtonNeeded}
                                      IsEmptyFormNeeded={subapplication.IsEmptyFormNeeded}
                                      ButtonType={subapplication.ButtonType}
                                      UpdateState={this.UpdateState}
                                      SubmitButtonName={subapplication.SubmitButtonName}
                                      AdditionalButtonName={subapplication.AdditionalButtonName}
                                      AdditionalButtonClickEvent={subapplication.AdditionalButtonClickEvent}
                                      {...stateProps}
                                    >
                                      {renderContent()}
                                    </ChildFormComponent>
                                  ) : renderContent()}

                                </CardContent>
                              </Grid>
                              {templateType === "NavigationButtons" && dynamicNavigation ? 
                              <Grid container spacing={3} style={styles.navBtnGrid} className={'navigationButtonContainer'}>
                                {templateType === "NavigationButtons" && dynamicNavigation ? (
                                  <DynamicNavigation
                                    pageNavigation={this.props.pageNavigation}
                                    currentPageIndex={currentPageIndex}
                                    navigateToNext={this.navigateToNext}
                                    navigateToPrevious={this.navigateToPrevious}
                                    getQuickRate={this.getQuickRate}
                                    invokeFullRating={this.invokeFullRating}
                                    createQuote={this.createQuote}
                                    submissionResponse={this.props.metadataReducer.submissionResponse}
                                    filehandler={FileHandlerAxios}
                                    statevalue={this.state}
                                    propsValue={this.props}
                                    callback={this.UpdateState}
                                    {...lobUtil}
                                  />
                                ) : null}
                              </Grid> : null}
                            </React.Fragment>
                          </Grid>
                        </CardContent>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CardContent className="custom-indentation custom-margin-left">
                      <Grid>
                        <PropsContext.Provider value={this.state}>
                          <Accordion
                            parentState={this.state}
                            currentPageIndex={currentPageIndex}
                            parentTabPosition={this.state.parentTabPosition}
                            tabPosition={this.state.tabPosition}
                            values={this.state.values}
                            handlePersistValue={this.handlePersistValue}
                            handleInputChange={this.handleInputChange}
                            handleInputChangeV2={this.handleInputChangeV2}
                            handleAutoCompletechange={this.handleAutoCompletechange}
                            functions={this.functions}
                            templateRenderNeeded={templateRenderNeeded}
                            updateValues={this.updateValues}
                            handleDateChange={this.handleDateChange}
                            pagelevelValues={this.state.pagelevelValues}
                            transactionPrimaryObject={this.state.transactionPrimaryObject}
                            handleHiddenInputChange={this.handleHiddenInputChange}
                            getFunction={this.handleTransaction}
                            canProceed={this.state.canProceed}
                            UpdateState={this.UpdateState}
                            updateValueWithSubplicationName={this.updateValueWithSubplicationName}
                            handleGoogleRegion={this.handleGoogleRegion}
                            useaccountaddress={this.state.useaccountaddress}
                            deleteArrayValue={this.deleteArrayValue}
                            updateArrayValueOnState={this.updateArrayValueOnState}
                            navigation={this.props.pageNavigation[currentPageIndex - 1] ? this.props.pageNavigation[currentPageIndex - 1] : this.props.navigation.productNavigation && this.props.navigation.productNavigation[this.props.metadataReducer.lob] && this.props.navigation.productNavigation[this.props.metadataReducer.lob][currentPageIndex - 1] ? this.props.navigation.productNavigation[this.props.metadataReducer.lob][currentPageIndex - 1]: {}}
                            Template={template}
                            objectId={objectId}
                            objectVerId={objectVerId}
                            getApiResponse={this.state.getApiResponse}
                            productBrowseData={this.state.productBrowseData}
                            isExpansionPanelNeeded={isExpansionPanelNeeded}
                            renderRateAndPreview={renderRateAndPreview}
                            navigateToPrevious={this.navigateToPrevious}
                            navigateToNext={this.navigateToNext}
                            lobUtil={lobUtil}
                            propsValue={this.props}
                            focusToParent={this.focusToParent}
                            location={this.props.location}
                          />
                        </PropsContext.Provider>
                      </Grid>
                    </CardContent>
                  </Grid>
                  {/* <Grid item xs={1} sm={1} md={1} lg={1}>
                <div className="loglist">
                  <List component="nav">
                    <ListItem alignItems="flex-start" disableGutters="false">
                      <AppAsideToggler className="" style={{ padding: "0px" }}>
                        <IconButton
                          title="Show Request/Response"
                          component="span"
                        >
                          <CodeRounded />
                        </IconButton>
                      </AppAsideToggler>
                    </ListItem>
                  </List>
                </div>
              </Grid> */}
                  {loadNavBtn && <div className="margin-top-5">
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CardContent className="custom-indentation">
                          <Grid container spacing={3}>
                              <Grid item xs={11} sm={11} md={12} lg={12} style={{  }}>
                                <CardContent className="custom-indentation bottom-10 flex custom-margin-left margin-bottom-10px">
                                {this.props.pageNavigation && this.props.pageNavigation.length && currentPageIndex && this.props.pageNavigation[currentPageIndex - 1] && this.props.pageNavigation[currentPageIndex - 1].SubApplicationName && this.props.pageNavigation[currentPageIndex - 1].SubApplicationName[0] != "AdditionalCoverage" && !dynamicNavigation ? (
                                  <NavigationButtons
                                    grid_size_button={grid_size_button}
                                    pageNavigation={this.props.pageNavigation}
                                    currentPageIndex={currentPageIndex}
                                    handleParentTabChange={this.handleParentTabChange}
                                    handleTabChange={this.handleTabChange}
                                    IsMultiLob={IsMultiLob}
                                    parentTabPosition={parentTabPosition}
                                    tabPosition={tabPosition}
                                    navigateToNext={this.navigateToNext}
                                    navigateToPrevious={this.navigateToPrevious}
                                    navigateToPreviousLOB={this.navigateToPreviousLOB}
                                    getQuickRate={this.getQuickRate}
                                    invokeFullRating={this.invokeFullRating}
                                    createQuote={this.createQuote}
                                    submissionResponse={this.props.metadataReducer.submissionResponse}
                                    filehandler={FileHandlerAxios}
                                    statevalue={this.state}
                                    propsValue={this.props}
                                    callback={this.UpdateState}
                                    {...lobUtil}
                                  />
                                ) : null}
                            </CardContent>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Grid>
                  </div>}
                  {this.state.loading && (
                    <PageLoader />
                  )}
                </ValidatorForm>
              </Card>
            </> : <PageLoader />
          }
        </React.Fragment>
      )


    }
  }

const styles = {
  card: {
    boxShadow: "none",
  },
  navBtnGrid: {
    marginTop: "2% !important",
    marginBottom: "3% !important",
  },
  panelSummaryContent: {
    border: "none !important",
    background: "none !important",
    margin: "0px",
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: "bolder",
  },
};

const mapStateToProps = (state) => ({
  // multiFlowReducer : state.multiFlowReducer,
  // dashboardReducer: state.dashboardReducer,
  metadataReducer: state.metadataReducer,
  workflowReducer: state.workflowReducer,
  navigation: state.navigationReducer.navigation,
  pageNavigation: state.navigationReducer.pageNavigation,
  navigationReducer: state.navigationReducer,
  productDetails: state.UIFlowReducer.productDetails,
  // productFlowReducer: state.productFlowReducer,
  userInfoReducer: state.userInfoReducer,
  UIFlowReducer: state.UIFlowReducer
});

const mapDispatchToProps = (dispatch) => {
  return {
    setNavigation: (navigation) => dispatch(setNavigation(navigation)),
    setPageNavigation : navigation => dispatch(setPageNavigation(navigation)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    setLOB: (lob) => dispatch(setLOB(lob)),
    setprimaryState: (primaryState) => dispatch(setprimaryState(primaryState)),
    setSubmissionID: (submissionID) => dispatch(setSubmissionID(submissionID)),
    setAdditionalCoverageVisibility: (additionalCoverageVisibility) =>
      dispatch(setAdditionalCoverageVisibility(additionalCoverageVisibility)),
    setProductDetails: (ProductDetails) =>
      dispatch(setProductDetails(ProductDetails)),
    setCurrentPageIndex: (index) => dispatch(setCurrentPageIndex(index)),
    solartisUIPageNavigationStatus: (value) => dispatch(solartisUIPageNavigationStatus(value)),
    setAgencyDetails: (agencyDetail) => dispatch(setAgencyDetails(agencyDetail)),
    setAgentDetails: (agentDetail) => dispatch(setAgentDetails(agentDetail)),
    setAgentID: (agentID) => dispatch(setAgentID(agentID)),
    setexpandPanelData: (LOBInfo) => dispatch(setexpandPanelData(LOBInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Submission);
