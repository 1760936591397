import React, { Component } from 'react';
import ExpandableComponent from '../sections/CustomComponents/ExpandableComponent/ExpandableComponent';
import dynamicRender from '../sections/CustomComponents/dynamicRender';
import RenderTemplate from '../../../template/RenderTemplate';
import  cloneDeep  from "lodash/cloneDeep";

class CustomIterator extends Component {

    constructor(props){
    super(props);
    this.IteratorRef = []
    }

    render() {

        let { componentProps, componentState, component,TableControls,functions,summarySubApp, detailSummaryList } = this.props;
        let dataList = componentProps[component.ResponseKey]? componentProps[component.ResponseKey] :
        componentState[component.ResponseKey],
         { SubApplicationDetailList } = component;
         let [{IsExpandable}] = componentState.pageMetadata.ApplicationDetail.SubApplicationDetailList.filter(data => data.ApplicationName === component.SubApplicationName)
        return (
            dataList ?
                dataList.map((data,index) => {
                   if(!this.IteratorRef[index]){
                    this.IteratorRef[index] = React.createRef()
                   }
                   let subappList=cloneDeep(SubApplicationDetailList)
                   let datavalues ={ pagelevelValues: {}, transactionPrimaryObject: {}}
                    Object.entries(data).forEach(([key, value], index) => {
                        if(typeof value === 'object' && value !== null)
                        {
                            datavalues["pagelevelValues"][key] = value;
                            datavalues["transactionPrimaryObject"][key] = value;
                        }
                        });

                    let actionResObj = this.props[component.OnKeyUpAction] ? this.props[component.OnKeyUpAction](data[component.AttributeName],data) : {}

                    let {derivedName=''} = actionResObj
                    let temp=Object.assign({},componentState,{[component.SubApplicationName] : data, parentComponent: component},data,datavalues, actionResObj, {IteratorRef: this.IteratorRef[index]});
                    temp.IteratorIndex = index;
                    temp.ResponseKey = component.ResponseKey;
                        if(data.SubApplicationDetailList)
                        {
                            subappList=data.SubApplicationDetailList
                        }
                        let children =  subappList && subappList.length >  0?
                        dynamicRender(subappList, componentProps,temp, functions,TableControls,summarySubApp, detailSummaryList,false) : null

                        // for Verdant could not get it from children(carrierList/Quota share) 
                        // this.props.pageInfo.children && <RenderTemplate {...componentProps} Template={this.props.pageInfo.children} data={data} hierarchicalRendering = {true} component={component} statevalues={componentState} parentData={this.props.state.data}></RenderTemplate>
                        

                    return (
                        <>
                        { IsExpandable === 'N' ?
                        <div className={component.StyleClass}>
                            {children}
                         </div>
                        :
                     <ExpandableComponent pageName={derivedName ? derivedName : data[component.AttributeName]} key={index}>
                         {children}
                     </ExpandableComponent>
                              }
                         </>)

                }) : null

        );
    }
}

export default CustomIterator;
