import {
  EntitySubAppTranslation,
  EntitySubAppTranslationV2,
  EntitySubAppTranslationV3,
  getEntitySubAppMapping,
  getEntitySubAppMappingV2,
} from "../requestConstruction/ResponseTranslation";
import store from "../../../../store";
import appActions from "../../../../actions/index";
import * as Action from "../../../../actions/types";
import { downloadFile, FileHandlerAxios } from '../../../../CommonAxios/FileHandlerAxios';
import {
  AxiosInstance,
  createQuoteAxios,
  getServiceRequestDetail,
  SaveUnderwritingQuestionsAxios,
  LocationandBuildingAxios,
  commonProductAxiosInstance,
  GetCommonEventName,
  authAxios
} from "./API";
import {
  // handleInputChangeV2,
  handleDateChange,
  setStateList,
  updateValueWithSubplicationName,
  invokeGetProductDetailsUtil,
  changeDOB,
  constructDefaultValues,
  invokeThirdPartyApi,
  setDataLists,
  setPagemetaDataonStateObj,
  getObjectfromString,
  setFlowAndNavigation,
  debounceCall
} from "./CommonUtils";
import { model_PDFGenerateRequest } from "../../models/generatePDFRequest";
import { subAppListConstruction,subAppListConstructionV1 } from "./DetailSummarySubAppList";
import { constructTransactionRequest,constructTransactionRequestV1,constructTransactionRequestV2 } from "../requestConstruction/DynamicReqConstruction";
import { getNavigation } from "../../../../CommonAxios/ResourceAxios";
import * as authaction from "../../../../actions/authActions";
import { format } from "date-fns";
import { clearAvailableMarket,VeRiskRCVCalculator } from "./SelectUtil";
import { v4 as uuidv4 } from 'uuid';
import { EntitySubAppTranslation_Metadata, getEntitySubAppTranslation, handleInputChangeV2_MPL, underwritertranslateupdate } from "../../controls/Popup/PopupUtils/utils_MPL";
import * as actionTypes  from "../../../../actions/types";
import {  getCoordinatesV2,getCoordinates,mergeResponseKeys } from "./RiskScheduleUtils";
import React from 'react';
import { getNavigationDetails, getProductDetailsByInsuredId, getProductDetailsByPolicyId, getProductDetailsByQuoteId } from "../../pages/FindUtils"
import  cloneDeep  from "lodash/cloneDeep";
import {  handleInputChangeV2, handleInputChangeV3, mergeAttributeListwithdata } from "./InputUtils";
import { constructTemplateWithUtil,templateConstruct, updateProdNav } from "./Navigation_Util";
import { commonButtonMethod, commonSelectMethod } from "../../controls/ButtonUtil";
import { setCurrentPageIndex } from "../../../../actions/UIFlowActions";
import { successToast, triggerToast,triggerInfoToast, defaultToast, Toast, getIndex, getRequiredIndex, failureToast } from "./HelperUtils";
import {  constructAndInvokeMetadataCall, attributeSplit,deleteDependentAttribute, deleteObjRowMetaData, deleteRowMetaData, getTableActions } from "./MetaUtils";
import { constructEncodedString, decodeString, dynamicObjectConstruction, pageLoadActions } from "./NoCodeUtils";
import {dateFormat} from './DateUtils'
import { constructRequestValues } from '../API_utils/ConstructRequestValues'
import Axios from "axios";
// import { multiLobApi } from "../expressFunction/multiLob";
const { actionType } = appActions;

const getPrimaryPolicy = (policy) => {
  if (policy.length === 1) {
    return policy[0];
  } else {
    let primaryPolicyInfo_index = policy.findIndex(
      (policy) => policy.SolartisIsPrimaryState === "Yes"
    );
    if (primaryPolicyInfo_index > 0) {
      return policy[primaryPolicyInfo_index];
    } else {
      return policy[0];
    }
  }
  /*  let result = policy.filter(isPrimaryStateExists);
  if (result.constructor === Object) return result;
  else if (result.constructor === Array) return result[0];
  else return undefined; */
};

const getPrimaryPolicyV2 = (stateList) => {
  let PrimaryState = stateList.PrimaryState;
  let StateList = stateList.StateList;
  let PrimaryPolicyIndex = StateList.findIndex(
    (state) => state.State === PrimaryState
  );

  return StateList[PrimaryPolicyIndex];
};

const ClosePopUp__scheduleofforms=(propsObj,stateObj,e)=>{
  stateObj.functions.handleClosePopup(false)
}

const getStateList = (props) => {
  // store.dispatch({type: actionType.SHOW_LOADER})
  const redux = store.getState();
  let request = {};
  request.EventName = props.pageInfo.getListEventName;
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request["ServiceRequestDetail"] = Object.assign(
    {},
    getServiceRequestDetail()
  );
  request.ID = redux.UIFlowReducer.submissionID;
  let response = AxiosInstance.post("/core/invokeWorkflowEvent", request)
    .then((response) => {
      if (response.data.hasOwnProperty("StateList")) {
        let responseArr = response.data.StateList;
        store.dispatch({
          type: actionType.SET_STATELIST,
          payload: responseArr,
        });
        return response.data;
      } else {
        store.dispatch({ type: actionType.SET_STATELIST, payload: [] });
        throw new Error("Couldn't fetch states");
      }
    })
    .catch((err) => {
      console.log(err);
      triggerToast(err.message);
      return [];
    });
  // store.dispatch({type: actionType.HIDE_LOADER})
  return response;
};
export const GetPolicyCoverages_GL = async (props, Update, state) => {
  let transactionPrimaryObject = {};
  let pagelevelValues = {};
  let values = {};
  let getApiResponse = {};
  try {
    let stateList = await getStateList(props);
    if (
      stateList.hasOwnProperty("StateList") &&
      stateList.StateList.length !== 0
    ) {
      store.dispatch({ type: actionType.SHOW_LOADER });
      let policy = await getPrimaryPolicyV2(stateList);
      let LOB = props.metadataReducer.lob;
      let policycoverageID = props.metadataReducer.policycoverageID;
      policycoverageID[LOB] = policy.ID;
      let pageDetail = props.navigation;
      let apirequest = {};
      apirequest.ROOTID = props.UIFlowReducer.submissionID;
      apirequest.ID = apirequest.PARENTID = policy.ID;
      apirequest.EventName = pageDetail.EventName;
      apirequest.TargetEventName = pageDetail.getEventName;
      apirequest.OwnerId = sessionStorage.getItem("OwnerID");
      apirequest["ServiceRequestDetail"] = Object.assign(
        {},
        getServiceRequestDetail()
      );
      await AxiosInstance.post("/core/invokeWorkflowEvent/V2", apirequest)
        .then(async (resp) => {
          if (resp.data.hasOwnProperty("Policy")) {
            let APIResponse = resp.data;
            let entitySubAppObject = await getEntitySubAppMapping({
              ...props,
              State: APIResponse.Policy.State,
            });
            let entityTranslatedResponse = await EntitySubAppTranslation(
              APIResponse,
              props,
              {},
              {},
              {},
              undefined,
              undefined,
              entitySubAppObject
            );
            const primaryState = APIResponse.Policy.State
              ? APIResponse.Policy.State
              : props.values.State !== undefined && props.values.State !== ""
              ? props.values.State
              : "CW";
            // pagelevelValues = Object.assign({}, props.pagelevelValues, state.pagelevelValues, entityTranslatedResponse);
            // transactionPrimaryObject = Object.assign({}, props.transactionPrimaryObject,state.transactionPrimaryObject, pagelevelValues)
            pagelevelValues = Object.assign(
              {},
              state.pagelevelValues,
              entityTranslatedResponse
            );
            transactionPrimaryObject = Object.assign(
              {},
              state.transactionPrimaryObject,
              entityTranslatedResponse
            );
            values = Object.assign(
              {},
              state.values,
              pagelevelValues.PolicyDetail,
              props.values
            );
            store.dispatch({
              type: actionType.SET_POLICYCOVERAGE_ID,
              payload: policycoverageID,
            });
            store.dispatch({
              type: actionType.SET_PRIMARY_STATE,
              payload: primaryState,
            });
          } else throw new Error("");
        })
        .catch((err) => {
          console.log("Error in get policy coverage", err);
          store.dispatch({ type: actionType.HIDE_LOADER });

          throw err;
        });
      // console.log("util metadata")
      // store.dispatch({type: actionType.HIDE_LOADER})
      // return {
      //   values,
      //   getApiResponse,
      //   transactionPrimaryObject,
      //   pagelevelValues,
      // };
    }
  } catch (error) {
    triggerToast("");
    console.log(error);
  } finally {
    // store.dispatch({type: actionType.HIDE_LOADER})
    return {
      values,
      getApiResponse,
      transactionPrimaryObject,
      pagelevelValues,
    };
  }
};

export const GetPolicyCoverages_CF = async (props, Update, state) => {
  // store.dispatch({type: actionType.SHOW_LOADER})
  let transactionPrimaryObject = {};
  let StateString = {};
  let pagelevelValues = {};
  let values = {};
  let getApiResponse = {};
  let stateList = await getStateList(props);
  if (stateList.StateList.length != 0) {
    let policy = await getPrimaryPolicy(stateList.StateList);
    let LOB = props.metadataReducer.lob;
    let policycoverageID = props.metadataReducer.policycoverageID;
    policycoverageID[LOB] = policy.ID;
    let pageDetail = props.navigation;
    let apirequest = {};
    apirequest.ROOTID = props.UIFlowReducer.submissionID;
    apirequest.ID = apirequest.PARENTID = policy.ID;
    apirequest.EventName = props.pageInfo.EventName;
    apirequest.TargetEventName = props.pageInfo.getEventName;
    apirequest.OwnerId = sessionStorage.getItem("OwnerID");
    apirequest["ServiceRequestDetail"] = Object.assign(
      {},
      getServiceRequestDetail()
    );
    await AxiosInstance.post("/core/invokeWorkflowEvent/V2", apirequest)
      .then(async (resp) => {
        if (resp.data.hasOwnProperty("Policy")) {
          let APIResponse = resp.data;
          let entitySubAppObject = await getEntitySubAppMapping({
            ...props,
            State: APIResponse.Policy.State,
          });
          let entityTranslatedResponse = await EntitySubAppTranslation(
            APIResponse,
            props,
            {},
            {},
            {},
            undefined,
            undefined,
            entitySubAppObject
          );
          const primaryState = APIResponse.Policy.State
            ? APIResponse.Policy.State
            : props.values.State !== undefined && props.values.State !== ""
            ? props.values.State
            : "CW";
          StateString =
            primaryState &&
            primaryState !== undefined &&
            (await setStateList(state));

          // pagelevelValues = Object.assign({}, props.pagelevelValues, state.pagelevelValues, entityTranslatedResponse);
          // transactionPrimaryObject = Object.assign({}, props.transactionPrimaryObject,state.transactionPrimaryObject, pagelevelValues)
          pagelevelValues = Object.assign(
            {},
            state.pagelevelValues,
            entityTranslatedResponse
          );
          transactionPrimaryObject = Object.assign(
            {},
            state.transactionPrimaryObject,
            entityTranslatedResponse
          );
          values = Object.assign(
            {},
            state.values,
            pagelevelValues.PolicyDetail,
            props.values
          );
          store.dispatch({
            type: actionType.SET_PRIMARY_STATE,
            payload: primaryState,
          });
          store.dispatch({
            type: actionType.SET_POLICYCOVERAGE_ID,
            payload: policycoverageID,
          });
        } else throw resp.data.ResponseStatus;
      })
      .catch((err) => {
        // store.dispatch({type: actionType.HIDE_LOADER})
        console.log("Error in get policy coverage", err);
        return [];
      });
    // store.dispatch({type: actionType.HIDE_LOADER})
    // console.log("util metadata")
    return {
      values,
      getApiResponse,
      transactionPrimaryObject,
      pagelevelValues,
    };
  } else {
    store.dispatch({ type: actionType.HIDE_LOADER });
    return {
      values,
      getApiResponse,
      transactionPrimaryObject,
      pagelevelValues,
    };
  }
};

export const invokeGetEvent_AU = async (props, UpdateState, state) => {
  props.showLoader();
  const redux = store.getState();
  if (redux.metadataReducer.submissionResponse.SubmissionID) {
    let getSubmissionRequest = Object.create({});
    let resp = {};
    let metadataResponse = {},
      pagelevelValues,
      submissionResponse;
    const { ProductID: objectId, ProductVerID: objectVerId } =
      props.productDetails;
    // console.log("redux ", redux)
    let policy = getPrimaryPolicy(
      redux.metadataReducer.submissionResponse.CommercialAutomobile.Policy
    );
    if (!props.getEventName) return;
    getSubmissionRequest = Object.assign({}, getSubmissionRequest, {
      ServiceRequestDetail: getServiceRequestDetail(),
      OwnerId: props.OwnerId,
      EventName: "SolartisMasterWF",
      TargetEventName: props.getEventName,
      ROOTID: redux.metadataReducer.submissionResponse.SubmissionID,
      ID: policy.ID,
    });
    resp = await AxiosInstance.post(
      "/core/invokeWorkflowEvent/V2",
      getSubmissionRequest
    )
      .then((response) => response.data)
      .catch((err) => console.log(err));
    let primaryState =
      resp.PrimaryState === undefined
        ? policy.State === undefined
          ? undefined
          : policy.State
        : resp.PrimaryState.PrimaryState;
    if (primaryState) {
      // console.log("primary State ", primaryState)
      // const { request, response } = await invokeMetadata(props.pageInfo["subApplication"], {}, primaryState ,objectId, objectVerId, format(new Date(), 'yyyy-MM-dd'));
      let subappinfo = {
        subappName: props.pageInfo["subApplication"],
        objectId,
        objectVerId,
        state: primaryState,
      };
      metadataResponse = await constructAndInvokeMetadataCall(subappinfo);
    }
    submissionResponse = resp;

    props.setMetadataResponse(JSON.stringify(metadataResponse, null, 2));
    let tempProps = {
      SubApplicationEntity: metadataResponse
        ? metadataResponse.ApplicationDetail.SubApplicationEntity
        : null,
    };
    // store.dispatch({type: "SET_METADATA_RESPONSE", payload: JSON.stringify(metadataResponse, null, 2)})
    pagelevelValues = await EntitySubAppTranslationV2(submissionResponse, {
      ...props,
      State: primaryState,
      ...tempProps,
    });
    if (submissionResponse !== undefined)
      primaryState =
        submissionResponse.PrimaryState === undefined
          ? primaryState
          : submissionResponse.PrimaryState.PrimaryState;
    props.setprimaryState(primaryState);
    props.UpdateState("transactionPrimaryObject", pagelevelValues, false);
  }
  props.hideLoader();
};

const handleDateChange_GL = (event, value, attributeName, component, state) => {
  let obj = handleDateChange(event, value, attributeName, component, state);
  return obj;
};

const updateValueWithSubplicationName_GL = (updatedValue, component, state) => {
  let obj = updateValueWithSubplicationName(updatedValue, component, state);
  return obj;
};

const   componentDidUpdate_OrchidUW = async (
  state,
  props,
  prevState,
  prevProps,
  UpdateState
) => {


  try{
    let entityTranslatedResponse = {}
      if(props.hasOwnProperty("parentProps")){
         if(props.parentProps.hasOwnProperty("pagelevelValues")){
           if(props.parentProps.pagelevelValues.Building!==prevProps.parentProps.pagelevelValues.Building){
            let subappinfo = {
              subappName: props.subApplication,
              objectId: props.objectId,
              objectVerId: props.objectVerId,
            };
            let pagelevelValues={},UnderwritingDetails={},tpo={}
            if(state.entityTranslatedResponseNeeded===false){
              let parentPagelevelValues = JSON.parse(JSON.stringify(props.parentProps.pagelevelValues))
              let statePagelevelValues = JSON.parse(JSON.stringify(state.pagelevelValues))
               let parentValues=await constructTransactionRequestV1({pageMetadata: props.parentProps},props,parentPagelevelValues,false,false,false,false);
              let UWValues=await constructTransactionRequestV1({pageMetadata: state.pageMetadata},props,statePagelevelValues,false,false,false,false);
              UnderwritingDetails =state.pagelevelValues && state.pagelevelValues.UnderwritingDetails ? state.pagelevelValues.UnderwritingDetails : {}
              pagelevelValues["Building"] = {...props.parentProps.pagelevelValues,...UnderwritingDetails,  ...parentValues.Building, ...UWValues.Building}
              // pagelevelValues["UnderwritingDetails"] = {... pagelevelValues["Building"]}
               // pagelevelValues['Building'] = {...props.parentProps.pagelevelValues,...UnderwritingDetails}
              pagelevelValues = {...props.parentProps.pagelevelValues, ...state.pagelevelValues,...pagelevelValues, UnderwritingDetails:{...pagelevelValues['Building']}}
              // tpo = {...state.transactionPrimaryObject,...pagelevelValues,UnderwritingDetails:{...pagelevelValues['Building'],...UnderwritingDetails}}
              let {LocationDetail}=props.parentProps.pagelevelValues;
              tpo = {...state.transactionPrimaryObject,...pagelevelValues,UnderwritingDetails:{...pagelevelValues['Building']},LocationDetail};
              let response = await constructAndInvokeMetadataCall(subappinfo, {transactionPrimaryObject: tpo, EffectiveDate: props.EffectiveDate ? props.EffectiveDate : props.values.EffectiveDate});
              return {
                pageMetadata: response,
                transactionPrimaryObject: tpo,
                pagelevelValues:pagelevelValues
             };
            }
            pagelevelValues['Building']=props.parentProps.pagelevelValues.hasOwnProperty("Building")?props.parentProps.pagelevelValues.Building:{}
            let subApplication =  state.pageMetadata.ApplicationDetail.SubApplicationDetailList.map(data => data.ApplicationName)
            let entitySubAppObject = await getEntitySubAppMapping({ objectId:props.objectId,objectVerId:props.objectVerId,OwnerId:props.OwnerId,subApplication:subApplication,productDetails:props.productDetails});
            entityTranslatedResponse = await EntitySubAppTranslation(pagelevelValues, props, state.pagelevelValues, {}, {}, state.pageMetadata, undefined, entitySubAppObject)
            pagelevelValues = Object.assign({},props.parentProps.pagelevelValues, state.pagelevelValues, entityTranslatedResponse);
            let transactionPrimaryObject = Object.assign({}, state.transactionPrimaryObject, pagelevelValues)
            let response = await constructAndInvokeMetadataCall(subappinfo, { transactionPrimaryObject, EffectiveDate: props.EffectiveDate ? props.EffectiveDate : props.values.EffectiveDate});
            UpdateState("entityTranslatedResponseNeeded",false,false)
            // UpdateState("pagelevelValues", pagelevelValues, false)
            // UpdateState("transactionPrimaryObject", transactionPrimaryObject, false)
            // UpdateState("pageMetadata", {...response}, true)
            return {
              pageMetadata: response,
              transactionPrimaryObject: transactionPrimaryObject,
              pagelevelValues:pagelevelValues
           };
        }
      }
    }
  }
  catch (error) {
      console.log("Error in componentDidUpdate_OrchidUW:: ", error)
    } finally {
      // props.hideLoader();
    }

};

const getSubmission_RateIndication = async (props,state) => {
  try{
  props.showLoader()
  const { subApplication, entityName } = props.pageInfo;
  let request = {};
  request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  request["OwnerId"] = sessionStorage.getItem("OwnerID")
  //await props.pageInfo.utilfunction[props.pageInfo.util].getRequestContruction(request,props);
  let req= getRequestConstruction_HomeOwners_RateIndication(request, props)
  //const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
 const response = await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
      // .then(async(response) => {
          props.hideLoader()
          if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
            triggerToast("Unable to get Submission");
            console.log("Response Failed");
            props.hideLoader();
           return;
          }

          if (response.data && response.data.InsuredID)
            await store.dispatch({ type: actionType.SET_INSUREDID, payload: response.data.InsuredID });
          if (response.data && response.data.SubmissionNumber)
            await store.dispatch({ type: actionType.SET_SUBMISSION_NUMBER, payload: response.data.SubmissionNumber });
          if (response.data && response.data.Insured && response.data.Insured.InsuredName)
            await store.dispatch({ type: Action.SET_INSUREDNAME, payload: response.data.Insured.InsuredName });
          if (response.data.ProcessStatus)
            await store.dispatch({ type: Action.SET_PROCESS_STATUS, payload: response.data.ProcessStatus });
          if (response.data && response.data.SubmissionStatus)
            await store.dispatch({ type: Action.SET_SUBMISSION_STATUS, payload: response.data.SubmissionStatus });
          if (response.data && response.data.SubmissionType)
            await store.dispatch({type: Action.SET_SUBMISSION_TYPE, payload: response.data.SubmissionType})
          props.UpdateState("pagelevelValues",{...props.pagelevelValues, ...response.data,...props.metadataReducer}, false);
          props.UpdateState("transactionPrimaryObject",{...props.transactionPrimaryObject, ...response.data}, false);
          await store.dispatch({ type: actionType.SET_EXPANDPANEL_DATA, payload: true });
          await store.dispatch({ type: actionType.SET_PAGEHEADER_UPDATE, payload: true });
          //return response
      // })

      // .catch((err) => {
      //     console.log(err);
      //     triggerToast("Error in response")
      // });
      return response
  }
  catch(err){
    console.log(err, 'Error in getSubmission_RateIndication');
    triggerToast("Unable to get Submission");
  }
}




const getRatingData = async (props,res) => {
  try{
    let request = {}
    request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["TargetEventName"]=props.GetRatingDataEventName;
    request["ID"]= props.UIFlowReducer.submissionID;
    request.EventName=GetCommonEventName();
    request['LOB'] =props.metadataReducer.lob
    const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
      triggerToast("Unable to get rating data");
      console.log("Response Failed");
     return;
    }
    return response
  }catch{
    console.log("error in getSubmission_PL");
    triggerToast("Error in GetRating data");
 }
}
const getSubmission_CL = async (state,props,UpdateState) => {
  try{
    let commonState = store.getState()
    let request=await constructTransactionRequestV2(state,props,{},false,false);
    request.EventName=commonState.ownerResources.clientInfo.CommonEventName_CL
    request.TargetEventName=props.CreateSubmissionEvent
    request.InsuredID=props.UIFlowReducer.insuredID;
    let response =await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    if(response.data.ResponseStatus.toLowerCase()==='failed'){
      triggerToast("  Error in Creating Submission ");
    }
    await store.dispatch({type:actionType.SET_SUBMISSION_NUMBER,payload: response.data.SubmissionNumber});
    return response
  }catch{
    console.log("error in getSubmission_CL");
 }
}
const getProductBrowse_CL = async (state,props, UpdateState) => {
  try{
    let commonState = store.getState()
    let request=await constructTransactionRequestV2(state,props,{},false,false);
    request.EventName=GetCommonEventName()
    //request.TargetEventName=props.GetSubmissionEvent
    request.TargetEventName= 'CL_GetProductBrowse'
    request.ID=props.UIFlowReducer.submissionID;
    let response={
      "data":{}
    }
    await store.dispatch({type:actionType.SET_SUBMISSION_NUMBER,payload: response.data.SubmissionNumber});
    UpdateState("pagelevelValues",response.data,false)
    UpdateState("transactionPrimaryObject", response.data, false)

    if(response.data.hasOwnProperty('Premium')){
      store.dispatch({type: actionType.SET_BUILDING_PREMIUM,payload: true})
      UpdateState("premium_dataList",response.data.Premium,true)
    }
        return response
  }catch{
    console.log("error in getProductBrowse_CL");
 }
}
const componentDidMount_NonConfiguredProductSelection = async (state, props, transactionPrimaryObject_arg= {}) =>{
  let {UpdateState}=props.parentState;
  try{
  UpdateState("loading",true,true);
  let { pagelevelValues } = state
  let availableMarketsResponse = {}
  let quoteValues={};
  let returnValues={};
  let response = {},submissionValues={},obj={},flowBlockKey= props.pageInfo.flowBlockKey;
  let tpo = {}
  let policyformdisplay;
  let getRatingDataResponse={}
  let isPolicyformExist = false;
  if(props.UIFlowReducer.insuredID){
  response=await getSubmission_RateIndication(props,state)
  getRatingDataResponse= await getRatingData(props,response)
  obj[`${flowBlockKey}`] = false
  store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: obj})
  if(getRatingDataResponse.data[flowBlockKey]){
    obj[`${flowBlockKey}`] = true
    store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: obj})
  }
  response.data= getRatingDataResponse.data ? {...response.data,...getRatingDataResponse.data} : {...response.data}
  tpo[props.pageInfo.name] = response.data.ProductSelection
  }
   await store.dispatch({type: actionType.SET_PAGEHEADER_UPDATE,payload: true});
 // await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
 if((response.data && response.data.ProductSelection && response.data.ProductSelection.LOB && response.data.ProductSelection.LOB.length>0)){
    if(!response.data){
     // response = await getQuoteData(props, props.GetQuoteTargetEventName,UpdateState);
      await store.dispatch({type: actionType.SET_INSUREDADDRESS, payload: response.data.InsuredAddress});
      response.data.SubmissionID ? await store.dispatch({type:actionType.SET_SUBMISSIONID,payload:response.data.SubmissionID}) : await store.dispatch({type:actionType.SET_SUBMISSIONID,payload:response.data.SubmissionId})
      submissionValues = await getSubmission_RateIndication(props,state)
  }
    availableMarketsResponse =  await getAvailableMarketsData(state, props, response);

 let SubApplicationList = props.pageInfo.dataListName ? props.pageInfo.dataListName : [];
  let childDatalist={};
  getRatingDataResponse.data && Object.keys(getRatingDataResponse.data).forEach(
    (key) =>{
      if(SubApplicationList.includes(key)){
        childDatalist[key]=response.data[key]
        }
      }
    )

  await store.dispatch({type: actionType.SET_INSUREDID, payload: response.data.InsuredID});
  await store.dispatch({type: actionType.SET_EXPANDPANEL_DATA,payload: true})
  policyformdisplay = !availableMarketsResponse.hasOwnProperty('IsRuleMessageAvailable') ? true : 'Ineligible';//called in wrong area. Take from template
  await store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: policyformdisplay});
  if(policyformdisplay!=="Ineligible"){
    let productSelection = response.data.ProductSelection;
    productSelection["LocationFullAddress"] = response.data.Location && response.data.Location[0] ? response.data.Location[0].ID : response.data.Location.ID;
    pagelevelValues["ProductSelection"] = productSelection//iterate subapp array
    tpo["ProductSelection"]= 	productSelection;
    let {subApplication,objectId,objectVerId}=props;
    let subAppInfo={
      subappName: subApplication,
      objectId,
      objectVerId
    }
    const pageMetadata=await constructAndInvokeMetadataCall(subAppInfo,{transactionPrimaryObject:{...response.data}});
    pagelevelValues=await constructTransactionRequestV1({pageMetadata},props,{...response.data},false,false,false,false);
    quoteValues.pagevalues = {...productSelection}
    quoteValues.ProductSelection_RadioValues = availableMarketsResponse
    quoteValues.pagelevelValues = cloneDeep({...pagelevelValues,...productSelection})
    quoteValues.transactionPrimaryObject=cloneDeep({...tpo})
    let carrierList=[],AvailableMarket={}, AllSublines = [];
    if(availableMarketsResponse.CarrierList && availableMarketsResponse.CarrierList.length>0){
   availableMarketsResponse.CarrierList.forEach(item => {
      carrierList.push({
        "AvailableMarket": {
          ...item,
          Subline: item.SublineName && item.SublineName.map(subline => subline.SublineName).join(' , '),
        }
      });
     Object.keys(item).forEach(key => {
       if (Array.isArray(item[key])) {
         AllSublines.push(...item[key].map(subline => subline.SublineName));
       } else {
         AvailableMarket[key] = item[key];
       }
     });
     AvailableMarket.SublineName = AllSublines;
 });

      UpdateState("AvailableMarketList_dataList",carrierList,false);
      Object.keys(childDatalist).forEach(
        (res) => {
          if(Array.isArray(childDatalist[res])){
            UpdateState(`${res.toLocaleLowerCase()}_dataList`,childDatalist[res],false);
          }
          else {
            UpdateState(`${res}`,childDatalist[res],false)
          }
        }
      )
      UpdateState(`getRatedSubmissionResponse`,{...childDatalist},false)
      UpdateState("AvailableMarket",{...productSelection,...AvailableMarket},false);
      UpdateState("ProductSelection",{...productSelection},false);
    }
  }

//tpo.ProductSelection && delete tpo["ProductSelection"];
  }
  // if(availableMarketsResponse && Object.keys(availableMarketsResponse.ErrorMessage).length>0){
  //   let productSelection = response.data.ProductSelection;
  //   pagelevelValues["ProductSelection"] = productSelection;
  //   policyformdisplay = !availableMarketsResponse.hasOwnProperty('IsRuleMessageAvailable') ? true : 'Ineligible';
  //   tpo={...pagelevelValues,...availableMarketsResponse.ErrorMessage}
  //   quoteValues = {transactionPrimaryObject:tpo}

  // }
  let locationResponse = await getLocationList_Verdant(props)
  tpo = {...tpo, ...locationResponse}

  returnValues=await componentDidMount_Orchid(state, props, tpo)
  if(Object.keys(quoteValues).length>0){
    returnValues={...returnValues,...quoteValues};
  }
  returnValues.transactionPrimaryObject = {...returnValues.transactionPrimaryObject, ...tpo}
  returnValues.pagelevelValues = {...returnValues.pagelevelValues, ...locationResponse}
  returnValues = {...returnValues, ...locationResponse}
  UpdateState("initialPageData",returnValues,false);
  UpdateState('loading',false,true)
  return returnValues
}
catch(e){
  UpdateState("loading",false,true);
  props.parentState.UpdateState("dynamicLoadingMessage",'',true);
  console.log("Error in componentDidMount_ProductSelection::",e);
}finally{
  //  UpdateState("dynamicLoadingMessage",'',true);
  UpdateState("loading",false,true);
}
}

const componentDidMount_ProductSelection = async (state, props, transactionPrimaryObject_arg= {}) =>{
  let {UpdateState}=props.parentState;
  try{
  UpdateState("loading",true,true);
  let { pagelevelValues } = state
  let availableMarketsResponse = {}
  let quoteValues={};
  let commonState = store.getState()
  let submissionValues = {}
  let returnValues={};
  let response = {};
  let tpo = {};
  let locationResponse = {};
  let policyformdisplay;
  let getRatingDataResponse={}
  let isPolicyformExist = false;
  if((!props.UIFlowReducer.quoteID && props.UIFlowReducer.insuredID)||(props.UIFlowReducer.quoteID && props.metadataReducer.isLocationSaved === false)){
  [response, getRatingDataResponse, locationResponse] = (await Promise.allSettled([getSubmission_RateIndication(props,state), getRatingData(props,response), getLocationList_Verdant(props)])).map((response)=>response.status == 'fulfilled' && response.hasOwnProperty('value') ? response.value : {})
  response.data= getRatingDataResponse.data ? {...response.data,...getRatingDataResponse.data} : {...response.data}
  //await store.dispatch({type:actionType.SET_SUBMISSIONID,payload: response.data.SubmissionID});
  tpo[props.pageInfo.name] = response.data.ProductSelection
  }
  //  await store.dispatch({type: actionType.SET_PAGEHEADER_UPDATE,payload: true});
 // await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
  if((props.UIFlowReducer && props.UIFlowReducer.quoteID) || ((response.data && response.data.SublineName && response.data.SublineName!=="") &&
   (response.data && response.data.ProductSelection && response.data.ProductSelection.DwellingCoverage && response.data.ProductSelection.DwellingCoverage!==""))){
    if(!response.data){
      response = await getQuoteData(props, props.GetQuoteTargetEventName,UpdateState);
      await store.dispatch({type: actionType.SET_INSUREDADDRESS, payload: response.data.InsuredAddress});
      response.data.SubmissionID ? await store.dispatch({type:actionType.SET_SUBMISSIONID,payload:response.data.SubmissionID}) : await store.dispatch({type:actionType.SET_SUBMISSIONID,payload:response.data.SubmissionId});
      [submissionValues, locationResponse] = (await Promise.allSettled([getSubmission_RateIndication(props,state), getLocationList_Verdant(props)])).map((response)=>response.status == 'fulfilled' && response.hasOwnProperty('value') ? response.value : {})
  }
    availableMarketsResponse =  await getAvailableMarketsData(state, props, response)
    isPolicyformExist =  availableMarketsResponse && availableMarketsResponse.SublineList && availableMarketsResponse.SublineList.some(elem => elem.Subline.SublineName === response.data.SublineName);
    if(isPolicyformExist){
  //await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
 // await store.dispatch({type:actionType.SET_SUBMISSIONID,payload: response.data.SubmissionID});

 let SubApplicationList = props.pageInfo.dataListName ? props.pageInfo.dataListName : [];
  let childDatalist={};
  response.data && response.data.Coverage && Object.keys(response.data.Coverage).forEach(
    (key) =>{
      if(SubApplicationList.includes(key)){
        childDatalist[key]=response.data.Coverage[key]
        }
      }
    )

  await store.dispatch({type: actionType.SET_INSUREDID, payload: response.data.InsuredID});

  policyformdisplay = !availableMarketsResponse.hasOwnProperty('IsRuleMessageAvailable') ? true : 'Ineligible';//called in wrong area. Take from template
  if(policyformdisplay!=="Ineligible"){
    if(availableMarketsResponse && availableMarketsResponse.SublineList && Object.keys(availableMarketsResponse.SublineList).length > 0){
    UpdateState("dynamicLoadingMessage",props.pageInfo.LoaderMessage || '',true);
    }
    tpo["AvailableMarket__PolicyForms"] = response.data.SublineName;
    tpo["ProductSelection__PolicyForms"] = response.data.SublineName;
    let ProductSelection = response.data.ProductSelection;
    ProductSelection["LocationFullAddress"] = response.data.Location && response.data.Location[0] ? response.data.Location[0].ID : response.data.Location.ID;
    pagelevelValues["ProductSelection"] = ProductSelection//iterate subapp array
    tpo["ProductSelection"]= 	ProductSelection;
    let {subApplication,objectId,objectVerId}=props;
    let subAppInfo={
      subappName: subApplication,
      objectId,
      objectVerId
    }
    // const pageMetadata=await constructAndInvokeMetadataCall(subAppInfo,{transactionPrimaryObject:{...response.data}});
    // pagelevelValues=await constructTransactionRequestV1({pageMetadata},props,{...response.data},false,false,false,false);
    if(props.UIFlowReducer.insuredID && !props.UIFlowReducer.quoteID && response.data.CarrierName && response.data.SublineName){
      quoteValues["CarrierName"]=response.data.CarrierName
      quoteValues["SublineName"]=response.data.SublineName
    }
    quoteValues.pagevalues = {...ProductSelection}
    quoteValues.ProductSelection_RadioValues = availableMarketsResponse
    quoteValues.pagelevelValues = {ProductSelection}
    quoteValues.transactionPrimaryObject=cloneDeep({...tpo})
    let list;
    let value={};
    let carrierList;
    if (
      availableMarketsResponse.SublineList &&
      availableMarketsResponse.SublineList.length > 0
    ) {
      availableMarketsResponse.SublineList.forEach((ele) => {
        if (ele.Subline.SublineName === response.data.SublineName) {
          list = ele.Subline;
          value.Carriers = ele.Subline.Carriers;
          value.PolicyForms = ele.Subline.SublineName;
          let SelectedPlanName = "";
          if ("SelectedPlanName" in response.data && response.data.SelectedPlanName) {
            store.dispatch({type: actionType.SET_SELECTED_PLAN, payload: response.data.SelectedPlanName});
            SelectedPlanName = response.data.SelectedPlanName
          }else if("PlanName" in response.data && response.data.PlanName) {
            store.dispatch({type: actionType.SET_SELECTED_PLAN, payload: response.data.PlanName});
            SelectedPlanName = response.data.PlanName
          }

          if(props.pageInfo && "activeConfiguration" in props.pageInfo && props.pageInfo.activeConfiguration==='package'){
            if (ele.PackageList && ele.PackageList.length > 0) {
              let updatedCoverage = {}
              if(ele.PackageList && ele.PackageList.length > 1){
                ele.PackageList.map((availablepackage) =>{
                  if("Coverage" in response.data && SelectedPlanName === availablepackage.PlanName){
                    let appliedToOther = props.pageInfo.updateCoverageDetailsToOtherPlans ? props.pageInfo.updateCoverageDetailsToOtherPlans : []
                     appliedToOther.map((key) => {
                       if(key in response.data.Coverage){
                        updatedCoverage = {...updatedCoverage,[key] : response.data.Coverage[key]}
                       }
                    })
                  }
                })
              }
              carrierList = ele.PackageList.map((availablepackage) => {
                let details = {};
                details = {...details, AvailableMarket: {...availablepackage,...availablepackage.CarrierList[0]}}
                if ("PackageList" in response.data) {
                  response.data.PackageList.map((packageData) => {
                    if(packageData.PlanName === availablepackage.PlanName) {
                      details = {...details,...packageData}
                      packageData.IsSelectedPlanName==='Yes' && store.dispatch({type: actionType.SET_SELECTED_PLAN, payload: packageData.PlanName});
                    }
                  });
                } else if ("Coverage" in response.data){
                  if (SelectedPlanName === availablepackage.PlanName)
                  details = {...details,Coverage: response.data.Coverage};
                  else if (SelectedPlanName !== availablepackage.PlanName)
                  details = {...details,Coverage: updatedCoverage};
                }
                return details;
              });
            }
          } else {
            carrierList = ele.CarrierList.map((data) => ({
              AvailableMarket: data,
              Coverage: response.data.Coverage,
            }));
          }
        }
      });
      UpdateState("AvailableMarketList_dataList", carrierList, false);
      UpdateState("selectedPolicy", response.data.SublineName, false);
      UpdateState("ProductSelection_RadioValues", availableMarketsResponse, false);
      let uid = uuidv4();
      UpdateState("RequestID",uid,true);
      Object.keys(childDatalist).forEach((res) => {
        UpdateState(
          `${res.toLocaleLowerCase()}_dataList`,
          childDatalist[res],
          false
        );
      });
      UpdateState("AvailableMarket", { ...ProductSelection, ...value }, false);
      UpdateState("SublineList", list, false);
    }

  }
  await store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: policyformdisplay});
  // await store.dispatch({type: actionType.SET_EXPANDPANEL_DATA,payload: true})
}
// tpo.ProductSelection && delete tpo["ProductSelection"];
  }
  if(availableMarketsResponse && availableMarketsResponse.IsRuleMessageAvailable){
    let productSelection = response.data.ProductSelection;
    pagelevelValues["ProductSelection"] = productSelection;
    policyformdisplay = !availableMarketsResponse.hasOwnProperty('IsRuleMessageAvailable') ? true : 'Ineligible';
    await store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: policyformdisplay});
    tpo={...pagelevelValues,...availableMarketsResponse,...response.data,policyformdisplay}
    UpdateState("viewbusinessrulespl_dataList", availableMarketsResponse.RuleInformationList, false)
  }
  if(policyformdisplay==="Ineligible"){
    tpo[props.pageInfo.name] = {...tpo[props.pageInfo.name],...availableMarketsResponse[props.pageInfo.name]}
    quoteValues = {transactionPrimaryObject:tpo,pagelevelValues:tpo}
  }
  // await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
  // props.UpdateState("pagelevelValues",{...response.data,...submissionValues.data},true);

  // let locationResponse = await getLocationList_Verdant(props)
  tpo = {...tpo, ...locationResponse}

  returnValues=await componentDidMount_Orchid(state, props, tpo)
  if(Object.keys(quoteValues).length>0){
    returnValues={...returnValues,...quoteValues};
  }
  returnValues.transactionPrimaryObject = {...returnValues.transactionPrimaryObject, ...tpo}
  returnValues.pagelevelValues = {...returnValues.pagelevelValues, ...locationResponse}
  returnValues = {...returnValues, ...locationResponse}
  return returnValues
}
catch(e){
  props.parentState.UpdateState("dynamicLoadingMessage",'',true);
  console.log("Error in componentDidMount_ProductSelection::",e);
}finally{
  //  UpdateState("dynamicLoadingMessage",'',true);
  UpdateState("loading",false,true);
}
}
const componentDidMount_ProductSelection_CL = async (state, props,UpdateState, transactionPrimaryObject_arg= {}) =>{
  // let {UpdateState}=props;
   try{
   UpdateState("loading",true,true);
   let { pagelevelValues } = state
   let availableMarketsResponse = {}
   let quoteValues={};
   let commonState = store.getState()
   let submissionValues = {}
   let returnValues={};
   let response = {};
   const tpo = transactionPrimaryObject_arg ? {transactionPrimaryObject: transactionPrimaryObject_arg} : null
   if(!props.UIFlowReducer.quoteID && props.UIFlowReducer.insuredID){
   response=await getSubmission_CL(state,props,UpdateState )
   await store.dispatch({type:actionType.SET_SUBMISSIONID,payload: response.data.SubmissionID});
   tpo[props.pageInfo.name] = response.data.ProductSelection
 }
   if(props.metadataReducer.FlowType==="Find"|| props.metadataReducer.buildingPremium===true){
     let response = await getProductBrowse_CL(state,props,UpdateState)
    // await store.dispatch({type:actionType.SET_SUBMISSIONID,payload: response.data.SubmissionID});
     //await store.dispatch({type: actionType.SET_INSUREDID, payload: response.data.InsuredID});
   }

   props.UpdateState("pagelevelValues",{...response.data,...submissionValues.data},false);
   UpdateState("transactionPrimaryObject",{...response.data},false);
   await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
   returnValues=await componentDidMount_Orchid(state, props, {...tpo,...response.data})
   if(Object.keys(quoteValues).length>1){
     returnValues={...returnValues,...quoteValues};
   }
   return returnValues
 }
 catch(e){
   console.log("Error in componentDidMount_ProductSelection",e);
 }finally{
   UpdateState("loading",false,true);
 }
 }
async function PrintRateComparison_HomeOwners(EventName,propsObj,stateObj,component,functions,e,props){
  try{
    props.functions.UpdateState('loading',true,true);
    let request={}
    request["ServiceRequestDetail"]=getServiceRequestDetail();
    request.SubmissionID=props.componentProps.UIFlowReducer.submissionID;
    request.EventName=props.pageInfo.RateComparisonEvent;
    request.OwnerId=sessionStorage.getItem('OwnerID');
    let list=[]
    props.componentProps.AvailableMarketList_dataList.forEach(data=>{
      list.push(data.SubmissionRatingDataID);
    });
    list=list.join(',');
    request.ApplicableMarketID=list;
    request={...request,...propsObj.AvailableMarket}
    const response=await AxiosInstance.post('core/invokeWorkflowEvent/V3',request);
    const {FileName,GeneratedFilePath}=response.data;
    const relativePath=GeneratedFilePath.concat(FileName);
    let redux =store.getState();
    let transactionRootPath=redux.ownerResources.clientInfo.UI_TRANSACTION_ROOT_PATH
    downloadFile(FileName,`${transactionRootPath}${relativePath}`);
    props.functions.UpdateState('loading',false,true);
  }catch(e){
    props.functions.UpdateState('loading',false,true);
    console.log('Error in PrintRateComparison',e)
  }
}
const constructOfficeMappingRequest = (props) => {
  let request = {}
  let reduxValues = store.getState();
  let {OfficeDetailsRequestKey = [], OfficeDetailsValues = []} = props.pageInfo
  let {userInfoReducer} = reduxValues
  if (OfficeDetailsRequestKey.length > 0 && OfficeDetailsValues.length > 0) {
    let OfficeDetailsRequestKeyLength = OfficeDetailsRequestKey.length
    for (let i = 0; i < OfficeDetailsRequestKeyLength; i++) {
      request[OfficeDetailsRequestKey[i]] = userInfoReducer.OfficeMappingDetails && userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] ? userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] : ''
    }
  }
  return request
}
const getAvailableMarketsData = async(state, props, getQuoteResponse)=>{
  try{
    let request = {}
    request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    let InsuranceType = getQuoteResponse.data && getQuoteResponse.data.InsuranceType;
    let productDetails = (props.productDetails && props.productDetails.InsuranceType )? {...props.productDetails} : {...props.productDetails,InsuranceType}
    request.SelectedLineOfBusiness=[productDetails];
    if(getQuoteResponse.data.SubmissionType && getQuoteResponse.data.SubmissionType.toLowerCase()==='renewal'){
      request={...request,...getQuoteResponse.data}
    }
    request["TargetEventName"]=props.GetAvailableMarketTargetEventName;
    if(props.AvailableMarketEventAction)
    request["EventAction"]=props.AvailableMarketEventAction;
    request["InsuredID"]=getQuoteResponse.data.Insured.ID;
    request["LocationID"]=(getQuoteResponse.data.Location && Array.isArray(getQuoteResponse.data.Location)) ? getQuoteResponse.data.Location[0].ID : getQuoteResponse.data.Location.ID;
    request["SubmissionID"]= props.UIFlowReducer.submissionID ? props.UIFlowReducer.submissionID:getQuoteResponse.data.SubmissionId;
    let reduxValues = store.getState();
    if (props.pageInfo && props.pageInfo.officeDetailsNeeded) {
      let officeMappingReq = {}
      officeMappingReq = constructOfficeMappingRequest(props)
      request = {...request, ...officeMappingReq}
      // request['ProductVerNumber'] = reduxValues.UIFlowReducer.productDetails.ProductVerNumber
      request.SubmissionType = reduxValues.metadataReducer.SubmissionType;
    }
    if(reduxValues.UIFlowReducer && reduxValues.UIFlowReducer.productDetails&&reduxValues.UIFlowReducer.productDetails.ProductVerNumber && reduxValues.UIFlowReducer.productDetails.ProductNumber){
      request['ProductVerNumber'] = reduxValues.UIFlowReducer.productDetails.ProductVerNumber
      request['ProductNumber'] = reduxValues.UIFlowReducer.productDetails.ProductNumber
    }
    request.EventName=GetCommonEventName();
    // if(props.pageInfo.AvailableMarketEventAction){
    //   request["EventAction"] = props.pageInfo.AvailableMarketEventAction
    // }
    request.ProductSelection = getQuoteResponse.data.ProductSelection;
    let redux =store.getState()
    if(redux.metadataReducer.SubmissionType && (redux.metadataReducer.SubmissionType.toLowerCase()==='renewal' || redux.metadataReducer.SubmissionType.toLowerCase()==='endorsement') && getQuoteResponse.data.ProductSelection && getQuoteResponse.data.ProductSelection.PolicyForms){
      request['SublineName'] =   getQuoteResponse.data.ProductSelection.PolicyForms
    }
    const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
      triggerToast("Unable to get availablemarketdata");
      console.log("Response Failed");
     return;
    }
    return response.data
  }
  catch(err){
    console.log('API Response Error',err);
  }
}

const updateValueWithSubplicationName_PL=(updatedValue,component,state)=>{
  let SubapplicationName = component.SubApplicationName;
  let pagelevelValues = Object.assign({}, state.pagelevelValues);
  if (!pagelevelValues[SubapplicationName]) {
    pagelevelValues[SubapplicationName] = {};
  }
  pagelevelValues[SubapplicationName] = Object.assign(
    {},
    state.pagelevelValues[SubapplicationName],
    updatedValue
  );
  return pagelevelValues;
}

const componentDidMount_CarrierSelection = async (state, props, transactionPrimaryObject_arg= {}) =>{
try{
  const tpo = transactionPrimaryObject_arg ? {transactionPrimaryObject: transactionPrimaryObject_arg} : null
  await store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: false});
  await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {"SubjectivityListRenderFlag":"No"} });
return await componentDidMount_Orchid(state, props, tpo);
}
catch(e){
  console.log("Error in componentDidMount_CarrierSelection",e);
}
}

const navigateToAvailableMarket = async(props,state,component)=>{
try{
  let obj={};
  obj.index=4;
  setFlowAndNavigation(component)
  let reduxState = store.getState();
  let templateInfoTemplates = reduxState.navigationReducer.templateInfo
  let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"], [props.UIFlowReducer.productDetails.ProductVerNumber]);
  if(navObj.hasOwnProperty('productTemplateInfo')){
      //Util Construction in Templates
      Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
        navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template])
      })
      //SubTemplate construction in Templates
      Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
        navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
      })
      store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}})
    }
  let templatefromredux = {...templateInfoTemplates,...navObj.productTemplateInfo};
  if (navObj.hasOwnProperty("productNavigation")) {
  let key = reduxState.UIFlowReducer.productDetails.LOBName ? `${reduxState.UIFlowReducer.productDetails.LOBName}_submission` : "template";
  key = navObj.productNavigation[key] ? key : "template"
  templateConstruct(navObj, ["template"], templatefromredux, "productNavigation");
  let prodnav = navObj.productNavigation[key];
  let pageNav = Object.assign({}, props.navigation);
  pageNav.pageNavigation.submission.sidebar[1].children = prodnav;
  props.setNavigation(pageNav);
  props.setPageNavigation(prodnav);
  store.dispatch({
    type: actionType.SET_NAVIGATION,
    payload: {...props.navigation,"productNavigation":{...navObj.productNavigation}},
  });
  }
  store.dispatch({
    type: actionTypes.SET_QUOTECONSOLE_INFO,
    payload: obj
  })
  store.dispatch({
    type: actionTypes.SET_MODIFY_QUOTE,
    payload: true
  })
  if(props.pageInfo.dynamicFlow && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
    let redirectRoute=""
    const encodedString=await constructEncodedString({
      "submissionID":reduxState.UIFlowReducer.submissionID,
      "insuredID":reduxState.UIFlowReducer.insuredID,
      "agentID":reduxState.UIFlowReducer.agentID,
      "Flow":reduxState.UIFlowReducer.Flow,
      "pageName":"Carrier selection"
    });
    redirectRoute+=`${encodedString}`;
    props.history.push(`/submission/${redirectRoute}`);
  }else{
      props.history.push("/submission");
    }
}
catch(e){
  console.log("Error in navigateToAvailableMarket",e);
}
}

const navigateToAvailableMarket_Button = async(EventName,props,state,component,functions,e)=>{
  try{
    let obj={};
    obj.index=4;
    setFlowAndNavigation(component)
    let reduxState = store.getState();
    let templateInfoTemplates = reduxState.navigationReducer.templateInfo
    let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"], [props.UIFlowReducer.productDetails.ProductVerNumber]);
    if(navObj.hasOwnProperty('productTemplateInfo')){
        //Util Construction in Templates
        Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
          navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template])
        })
        //SubTemplate construction in Templates
        Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
          navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
        })
        store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}})
      }
    let templatefromredux = {...templateInfoTemplates,...navObj.productTemplateInfo};
    if (navObj.hasOwnProperty("productNavigation")) {
    let key = reduxState.UIFlowReducer.productDetails.LOBName ? `${reduxState.UIFlowReducer.productDetails.LOBName}_submission` : "template";
    key = navObj.productNavigation[key] ? key : "template"
    templateConstruct(navObj, ["template"], templatefromredux, "productNavigation");
    let prodnav = navObj.productNavigation[key];
    let pageNav = Object.assign({}, props.navigation);
    pageNav.pageNavigation.submission.sidebar[1].children = prodnav;
    props.setNavigation(pageNav);
    props.setPageNavigation(prodnav);
    store.dispatch({
      type: actionType.SET_NAVIGATION,
      payload: {...props.navigation,"productNavigation":{...navObj.productNavigation}},
    });
    }
    store.dispatch({
      type: actionTypes.SET_QUOTECONSOLE_INFO,
      payload: obj
    })
    store.dispatch({
      type: actionTypes.SET_MODIFY_QUOTE,
      payload: true
    })
    props.history.push("/submission");
  }
  catch(e){
    console.log("Error in navigateToAvailableMarket",e);
  }
  }


const navigateToAvailableMarket_CL = async(props)=>{
  try{
    let obj={};
    obj.index=4;
    let navObj = await getNavigation(["ProductNavigation"],["CL_1_4_V1"]);
    let templatefromredux = props.templateInfo;
    if (navObj.hasOwnProperty("productNavigation")) {
    templateConstruct(navObj, ["template"], templatefromredux, "productNavigation");
    let prodnav = navObj.productNavigation.template;
    let pageNav = Object.assign({}, props.navigation);
    pageNav.pageNavigation.submission.sidebar[1].children = prodnav;
    props.setNavigation(pageNav);
    props.setPageNavigation(prodnav);
    store.dispatch({
      type: actionType.SET_NAVIGATION,
      payload: {...props.navigation,"productNavigation":{...navObj.productNavigation}},
    });
    }
    store.dispatch({
      type: actionTypes.SET_QUOTECONSOLE_INFO,
      payload: obj
    })
    props.history.push("/submission");
  }
  catch(e){
    console.log("Error in navigateToAvailableMarket",e);
  }
  }

const componentDidMount_Orchid = async (state, props, transactionPrimaryObject_arg= {},metaRes=false) => {
  //await store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: false});
  let detailSubApp = [];
  let summarySubApp = [];
  let subappNameList = [];
  let detailSummaryList = {};
const subappName=props.subApplication||props.subApplicationName;
const subApplicationGroupList =props.subApplicationGroup;
  subAppListConstruction(
    subappName,
    detailSummaryList,
    subappNameList,
    detailSubApp,
    summarySubApp
  );
  let subappinfo = {
    subappName:subappNameList,
    objectId: props.objectId,
    objectVerId: props.objectVerId,
    subApplicationGroupList
  };
  const tpo = transactionPrimaryObject_arg ? {transactionPrimaryObject: transactionPrimaryObject_arg} : null
  let metadataResponse = await constructAndInvokeMetadataCall(subappinfo, tpo);
  return {
    pageMetadata: metadataResponse,
    summarySubApp: summarySubApp,
    detailSummaryList: detailSummaryList,
    subappNameList: subappNameList,
    detailSubApp: detailSubApp
  };
  /*      const { request, response } = await invokeMetadata(subappNameList, {},  "",objectId, objectVerId, format(new Date(), 'yyyy-MM-dd'));
      let  metadataResponse = response
      console.log("metadataResponse sub app",metadataResponse) * /
        return {pageMetadata:metadataResponse, summarySubApp:summarySubApp,detailSummaryList:detailSummaryList,subappNameList:subappNameList,detailSubApp:detailSubApp};
  /*  let request = sampleMetadataRequest;

    stateSetter.setState(prevState => {
          return {...prevState,summarySubApp, subappNameList, detailSummaryList};
      });

    console.log("PROPSSSSSSSSS sub app",props.pageInfo)
    request.SubApplicationNameList = props.pageInfo["subApplication"]

        let metadataresponse = await metadataAxios.post("core/getmetadata", request).then(
      (response) => {
        console.log("PROPSSSSSSSSS",props)

        console.log("RESPONSEEEEEEEEEE",response.data)
        return response.data;

      },
      (error) => {
          console.log(error);
      }
  );
  return {pageMetadata:metadataresponse}; */
};

const CoverageSelectionQuote_MPL = async(state,props,UpdateState,policySummary={})=> {
  try{
  UpdateState("loading",true,true)
  let {CreateQuoteTargetEventName}= props.UIFlowReducer && props.UIFlowReducer.currentPageInfo.template[0];
  let response , className;
  if(policySummary===true){
    response=await getPolicyData(props,props.UIFlowReducer.currentPageInfo.getPolicyTargetEventName);
    className = props.UIFlowReducer.currentPageInfo.template[0].className;
  }
  else{
    response = await getQuoteData(props,CreateQuoteTargetEventName,UpdateState);
    className = props.UIFlowReducer.currentPageInfo.template[0].className;
  }
  let values;
  let subappName =props.subApplication
  let subAppInfo = {
    objectId : props.objectId,
    objectVerId : props.objectVerId,
    subApplication : props.subApplication,
    subappName
  }
    Object.entries(response.data).forEach(([key,value]) =>{
      if(subappName.includes(key)){
        values={...values,...value}
      }
    })
  let SelectedCoverageSelectionOption_datalist = response.data ? response.data.Coverages : [{}]
  let metadataResponse=await constructAndInvokeMetadataCall(subAppInfo);
  return {"loading": false,"pagelevelValues": response.data,"pageMetadata":metadataResponse,values, SelectedCoverageSelectionOption_datalist, "templateClassName" :className};
}
catch (err){
  UpdateState("loading",false,true)
  console.log("Error in CoverageSelectionQuote_MPL",err);
}
};

const CoverageDetailsQuote_MPL = async(state,props,UpdateState,policySummary={})=>{
  try{
    UpdateState("loading",true,true)
    let {CreateQuoteTargetEventName}=
    props.UIFlowReducer && props.UIFlowReducer.currentPageInfo.template[0];
    let response,className;
  if(policySummary===true){
  response=await getPolicyData(props,props.UIFlowReducer.currentPageInfo.getPolicyTargetEventName);
  className = props.UIFlowReducer.currentPageInfo.template[0].className;
  }
  else{
    response = await getQuoteData(props,CreateQuoteTargetEventName,UpdateState);
    className = props.UIFlowReducer.currentPageInfo.template[0].className;
  }
  let subAppDetail= {
    subappName:props.subApplication,
    objectId : props.objectId,
    objectVerId : props.objectVerId
 }
  let metadataResponse= await constructAndInvokeMetadataCall(subAppDetail);
  let coverage = await EntitySubAppTranslation_Metadata(state, props, response.data,metadataResponse)
  Object.assign(state.transactionPrimaryObject,{...coverage,...response.data})
  let metaResponse= await componentDidMount_MPL(state,props);
  return {"loading":false,...metaResponse, pageLevelValues: {...state.pageLevelValues}, transactionPrimaryObject: {...state.transactionPrimaryObject},loading:false,coverageiapl2_1_c_dataList: response.data.IAPL_EntityList,coverageepl6_8_a_dataList: response.data.EPL_ReportedClaimsBreakDown, coveragefl7_1_dataList: response.data.FL_ApplicantsEmployeeBenefitPlans, coveragefl7_3_a_dataList: response.data.FL_ReportedClaimsBreakDown};
  }
  catch (err){
    UpdateState("loading",false,true)
    console.log("Error in CoverageDetailsQuote_MPL",err);
  }
}

async function avaiableMarket (props,state=undefined,updateState)
{
     let subappName = []
     let subappNameList = props.pageInfo.subApplication
     let selectedSubline = props.pageInfo.selectedSubline ? props.pageInfo.selectedSubline : {};
     let choosedsubline = props.SublineList && props.SublineList.SublineName ? props.SublineList.SublineName : ''
     let neededSubapps = []
     Object.keys(selectedSubline).length > 0 && Object.entries(selectedSubline).forEach(([key,value])=>{
      if(key === choosedsubline){
        neededSubapps.push(value)
       }
     })
   subappName = [...subappNameList,...neededSubapps]
      let obj = {}
    let subappinfo = {
        subappName,
        objectId: props.productDetails.ProductID,
        objectVerId: props.productDetails.ProductVerID,
        state: "CW"
    },transactionPrimaryObject={}
    if(state){
      transactionPrimaryObject = { ...state.transactionPrimaryObject,AvailableMarket: props.AvailableMarket }
    }else
    transactionPrimaryObject = { AvailableMarket: props.AvailableMarket }
    if(props.pageInfo && props.pageInfo.skeletonNeeded){
      transactionPrimaryObject = {...transactionPrimaryObject, "AvailableMarketLoading": "true"}
    }
    let metadataResponse = {},responseFromTranslation ={};
    if(props.pageInfo.loaderNeeded)
      updateState('loading',true,true)
    metadataResponse = await constructAndInvokeMetadataCall(subappinfo, { transactionPrimaryObject });
    obj['pageMetadata']=metadataResponse
    if(props.pageInfo.loaderNeeded){
      Object.keys(props).forEach(key=>{
        if(key.endsWith("_dataList")){
          obj[key] = props[key]
        }
      })
      responseFromTranslation=await EntitySubAppTranslationV2({...props.getRatedSubmissionResponse},metadataResponse.ApplicationDetail);
      if(Object.keys(responseFromTranslation).length> 0){
        let output = await componentDidMount_Orchid(state,props,transactionPrimaryObject={...transactionPrimaryObject,...responseFromTranslation})
        updateState('pagelevelValues',transactionPrimaryObject,false)
        updateState('transactionPrimaryObject',transactionPrimaryObject,false)
        output = {...obj,...output}
        updateState('loading',false,true)
       return output
      }
      updateState('loading',false,true)
    }
    return obj
}
const componentDidUpdate_NonConfiguredProductSelection = async (
  state,
  props,
  prevState,
  prevProps,
  UpdateState
) => {
    if(props.pageInfo.name && props.UIFlowReducer[`ProductSelection__resetWithInitialData`] === 'true'){
      try {
        
       UpdateState('pageMetadata',props.initialPageData.pageMetadata,'false')
       UpdateState('pagelevelValues',props.initialPageData.pagelevelValues,'true')
       store.dispatch({ type: actionType.SET_UI_VALUES, payload: { [`ProductSelection__resetWithInitialData`]: "false" } });
       console.log(props, "booms")
        } catch (error) {
        console.error("Error in updating state:", error);
      }
  }
  else{
    componentDidUpdate_Orchid(state,props,prevState,prevProps,UpdateState)
  }
  }


const componentDidUpdate_Orchid = async (
  state,
  props,
  prevState,
  prevProps,
  UpdateState
) => {
    if (props && props.AvailableMarket && prevProps && props.pageInfo) {
    const condition1=((props.pageInfo.name === "CarrierSelection") && ((prevProps.AvailableMarket === undefined && (props.AvailableMarket.PolicyForms !== "" || props.AvailableMarket.CarrierName !== "")) ||
    prevProps.AvailableMarket &&
    props.AvailableMarket.PolicyForms !== prevProps.AvailableMarket.PolicyForms || prevProps.AvailableMarket && props.AvailableMarket.CarrierName !== prevProps.AvailableMarket.CarrierName || prevProps.AvailableMarket.SublineName && props.AvailableMarket.SublineName !== prevProps.AvailableMarket.SublineName
  ) )

    const condition2=props.pageInfo.name === "ProductSelection" && prevProps.AvailableMarket && props.AvailableMarket && props.AvailableMarket.showAdjustCoverageSection !== prevProps.AvailableMarket.showAdjustCoverageSection
    const condition3=props.pageInfo.name === "CarrierSelection" && props.AvailableMarket_Needed
    const condition4=props.pageInfo.name === "ProductSelection" && props.ProductSelection_Needed
    if (condition1 || condition2 || condition3 || condition4) {
      let outPut = await avaiableMarket(props, state,UpdateState);

      if (condition1) {
        outPut['updatedCoverage'] = {};
        let updateLists = {}
        let dataListName = props.pageInfo.dataListName || []
        dataListName.map(key => {
          updateLists[`${key.toLowerCase()}_dataList`]= [];
        });
        updateLists["carriervalidations_dataList"] = []
        outPut = {...outPut, ...updateLists}
        // props.parentFunctions.updateValues({...updateLists})
      }
      if(condition3){
        props.parentFunctions.updateValues({"AvailableMarket_Needed":false});
      }
      if(condition4){
        props.parentFunctions.updateValues({"ProductSelection_Needed":false});
      }
      return outPut;
    }
  }

  if( props.pageInfo && props.pageInfo.name === "CarrierSelection"){
  if(props.clearCarrierSelection === true){
    props.parentState.UpdateState("clearCarrierSelection",false, true)
    let datalistObj = {}
    Object.keys(state).map((key)=> {
      if(key.endsWith('_dataList') || key.endsWith('_columnList')){
        datalistObj[key] = []
      }
    })
    return {...datalistObj, pageMetadata: [], transactionPrimaryObject: {}, pagelevelValues: {}, values: {}}
  }

  if (props.quoteNumberUpdate === true) {
    props.parentState.UpdateState("quoteNumberUpdate",false, true)
    // UpdateState('loading', true, true)
    let outPut = await avaiableMarket(props, state,UpdateState);
    return {...outPut, carriervalidations_dataList: []};
  }
}

  if (props.location.pathname !== prevProps.location.pathname) {
    props.showLoader();
    try {
      let detailSubApp = [];
      let summarySubApp = [];
      let subappNameList = [];
      let detailSummaryList = {};
      const subApplicationGroupList =props.subApplicationGroup;
      subAppListConstruction(
        props.subApplication,
        detailSummaryList,
        subappNameList,
        detailSubApp,
        summarySubApp
      );
      let subappinfo = {
        subappName: props.subApplication,
        objectId: props.objectId,
        objectVerId: props.objectVerId,
        subApplicationGroupList
      };
      let metadataResponse = await constructAndInvokeMetadataCall(subappinfo);
      return {
        pageMetadata: metadataResponse,
        summarySubApp: summarySubApp,
        detailSummaryList: detailSummaryList,
        subappNameList: subappNameList,
        detailSubApp: detailSubApp,
      };
    } catch (error) {
      console.log("error in Update GL ", error);
    } finally {
      // props.hideLoader();
    }
  }

};


const handleInputChangeV2_Quote = async (
  value,
  component,
  isDynamicTable,
  datatableRowindex,
  props,
  state,
  functions,
  DynamicProps
) => {
  let obj;
  if(props.productDetails.LOBName==='MPL'){
   obj= handleInputChangeV2Callback_MPL(value, component,isDynamicTable, datatableRowindex, props, state, functions, DynamicProps)
  }
  else{
    obj =await handleInputChangeV2_Orchid(value, component,isDynamicTable, datatableRowindex, props, state,functions, DynamicProps)
  }
  return obj
}

export const handleInputChangeV2_Orchid = async (value,component,isDynamicTable,datatableRowindex,props,state,functions, DynamicProps,cancelAPIRef) => {
  try {
    let{Action}= component
    let{isLoaderNeeded = true} = Action
    functions.loader && isLoaderNeeded && functions.loader(true);
      let reduxState = store.getState();
      if (component.AttributeName === "State") {
        store.dispatch({
          type: actionType.SET_PRIMARY_STATE,
          payload: value[component.AttributeName],
        });
      }
      let primaryState = reduxState.metadataReducer.primaryState;
      let route = reduxState.metadataReducer.route ? store.getState().metadataReducer.route : ''
      let subAppInfo= {};
      if(route==='Policy' || route==='Endorsement' || route==='Quote'){
        let {objectId,objectVerId}=props.pageInfo;
        let {subappNameList}=state;
        subAppInfo={
          subappName:subappNameList,
          objectId,
          objectVerId
        }
      }
      else {
        subAppInfo = {
          subappName: props.pageInfo["subApplication"] || props["subApplication"] || props["subApplicationName"]||state.subappNameList,
          objectId: props.objectId,
          objectVerId: props.objectVerId,
          state: primaryState,
          subApplicationGroupList: props.subApplicationGroup,
        };
      }
      let obj = await handleInputChangeV2(value,component,props,state,functions,subAppInfo,{},true, DynamicProps,cancelAPIRef);
      return obj;
  } catch (error) {
    console.log("Error in handleInputChangeV2_Orchid", error);
  } finally {
    functions.loader && functions.loader(false);
  }
};
const callBackmethod_Orchid = async (obj, props,stateObj) => {
  let reduxState = store.getState();
  let state = reduxState.metadataReducer.primaryState;
  let { ProductID: objectId, ProductVerID: objectVerId } = props.productDetails;
  // let { request, response } = await invokeMetadata(props.pageInfo["subApplication"], obj.transactionPrimaryObject, state, objectId, objectVerId, props.values.EffectiveDate, []);
  let subappinfo = {
    subappName: props.pageInfo["subApplication"] || props["subApplication"] || props["subApplicationName"]||stateObj.subappNameList,
    objectId: props.objectId,
    objectVerId: props.objectVerId,
    state,
    subApplicationGroupList: props.subApplicationGroup,
  };
  let response = await constructAndInvokeMetadataCall(subappinfo, {
    transactionPrimaryObject: obj.transactionPrimaryObject,
    EffectiveDate:format (new Date(), 'yyyy-MM-dd') ,
  });

  return { ...obj, pageMetadata: response };
};


const saveUWDetails_HomeOwners = async (
  eventName,
  props,
  state,
  pagelevelValues,
  functions,
  metadataResponseFromReducer
) => {
  let request = await constructTransactionRequest(
    state,
    props,
    state.pagelevelValues,
    functions,
    false
  );

  // let request = await constructTransactionRequestV1(state,props, state.pagelevelValues,null,true,false,'')
  request.EventName = GetCommonEventName()
  // request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  // request.OwnerId = sessionStorage.getItem("OwnerID");
  let routeInfo = store.getState().metadataReducer.route ? store.getState().metadataReducer.route:''
  if(routeInfo=='Endorsement'){
    request.PARENTID = state.pagelevelValues.Building.PARENTID
    request.ID = state.pagelevelValues.Building.ID
    request.ROOTID = store.getState().UIFlowReducer.quoteID;
    request.TargetEventName = props.pageInfo.eventName.saveUWD;
  }else{
    request.ROOTID = props.UIFlowReducer.submissionID;
    request.PARENTID = props.parentProps.pagelevelValues.LocationDetail.PARENTID
    request.ID = props.parentProps.pagelevelValues.Building.ID
    request.TargetEventName = props.pageInfo.targetEventName;
    request.SubmissionID = store.getState().UIFlowReducer.submissionID
  }
  SaveUnderwritingQuestionsAxios.post(
    "/core/invokeWorkflowEvent/V2",
    request
  ).then(async (response) => {
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
      triggerToast("Error in saving Underwriting Details");
      console.log("Response Failed");
  }
  else{
    successToast("Underwriting Details Saved Successfully",  { position: Toast.POSITION.TOP_RIGHT})
    // props.parentProps && props.parentProps.functions.UpdateState('pagelevelValues',{...props.parentProps.pagelevelValues,...state.pagelevelValues,...response.data},false)
    functions.UpdateState('pagelevelValues',{...state.pagelevelValues,...response.data},false)
    await store.dispatch({
      type: actionType.SET_HASUNDERWRITINGDETAILS,
      payload: false
  });
  }
  });
};

const saveUWDetails_HomeOwners_RateIndication = async (
  eventName,
  props,
  state,
  pagelevelValues,
  functions,
  metadataResponseFromReducer
) => {
  let request = await constructTransactionRequest(
    state,
    props,
    state.pagelevelValues,
    functions,
    false
  );
  let redux=store.getState();
  request.EventName = GetCommonEventName()
  let routeInfo = redux.metadataReducer.route ? redux.metadataReducer.route:''
  if(routeInfo=='Endorsement'){
    request.PARENTID = state.pagelevelValues.Building_RateIndication.PARENTID
    request.ID = state.pagelevelValues.Building_RateIndication.ID
    request.ROOTID = redux.UIFlowReducer.quoteID;
    request.TargetEventName = props.pageInfo.eventName.saveUWD;
  }else{
    request.ROOTID = redux.UIFlowReducer.submissionID
    request.PARENTID= props.parentProps.pagelevelValues.LocationDetail.ID
    request.ID = props.parentProps.pagelevelValues.Building_RateIndication.ID
    request.TargetEventName = props.pageInfo.targetEventName;
    request.SubmissionID = redux.UIFlowReducer.submissionID
  }
  SaveUnderwritingQuestionsAxios.post(
    "/core/invokeWorkflowEvent/V2",
    request
  ).then(async (response) => {
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
      triggerToast("Error in saving Underwriting Details");
      console.log("Response Failed");
  }
  else{
    successToast("Underwriting Details Saved Successfully",  { position: Toast.POSITION.TOP_RIGHT})
    // props.parentProps && props.parentProps.functions.UpdateState('pagelevelValues',{...props.parentProps.pagelevelValues,...state.pagelevelValues,...response.data},false)
    functions.UpdateState('pagelevelValues',{...state.pagelevelValues,...response.data},false)
    await store.dispatch({
      type: actionType.SET_HASUNDERWRITINGDETAILS,
      payload: false
  });
  }
  });
};

const createNewSubmission=async(EventName,propsObj,stateObj,component,functions,e,props)=>{

props.functions.UpdateState('loading',true,false);
let reduxState = store.getState();
let PageIndx =  props.pageInfo&& props.pageInfo.rolebasedindex && props.pageInfo.rolebasedindex.includes(reduxState.userInfoReducer.userRole)  ? props.pageInfo.landingpageindex : 1;
await store.dispatch(authaction.resetsubmission());
let obj = {currentPageIndex: PageIndx}
store.dispatch({ type: actionType.SET_UI_VALUES , payload: obj});
store.dispatch({ type: actionType.RESET_APPLICATION_REDUCER });
setFlowAndNavigation(component)
let templateInfoTemplates = reduxState.navigationReducer.templateInfo
let productDetails,prdObj

let selectedProducts = (reduxState.UIFlowReducer.selectedProducts && Array.isArray(reduxState.UIFlowReducer.selectedProducts) && reduxState.UIFlowReducer.selectedProducts.length>0) && reduxState.UIFlowReducer.selectedProducts
 if(selectedProducts){
   const selectedProduct = props.pagelevelValues[component.SubApplicationName].SelectProduct
   productDetails = selectedProducts.find(product=> product.ProductVerNumber===selectedProduct)
   store.dispatch({
     type: "SET_LOB",
     payload: productDetails.LOBName,
   });
   store.dispatch({
     type: "SET_PRODUCT_DETAILS",
     payload: productDetails,
   });
 } else {
    prdObj =  await invokeGetProductDetailsUtil(component);
 }
let ProductVerNumber = (productDetails && productDetails.ProductVerNumber)? productDetails.ProductVerNumber:prdObj.ProductVerNumber
let LOBName = (productDetails && productDetails.LOBName)? productDetails.LOBName:prdObj.LOBName;
let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"],[ProductVerNumber]);
if(navObj.hasOwnProperty('productTemplateInfo')){
  //Util Construction in Templates
  Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
    navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template])
  })
  //SubTemplate construction in Templates
  Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
    navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
  })
  store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}})
}
let templatefromredux = {...templateInfoTemplates,...navObj.productTemplateInfo};
if (navObj.hasOwnProperty("productNavigation")) {
  let key = LOBName ? `${LOBName}_submission` : "template";
  key = navObj.productNavigation[key] ? key : "template"
  templateConstruct(navObj, ["template"], templatefromredux);
  let prodnav = navObj.productNavigation[key];
  let pageNav = Object.assign({}, props.componentProps.navigation);
  let index =getRequiredIndex(pageNav.pageNavigation.submission.sidebar,'name','Submission')
  pageNav.pageNavigation.submission.sidebar[index].children = prodnav;
  props.componentProps.setNavigation(pageNav);
  props.componentProps.setPageNavigation(prodnav);
  store.dispatch({
    type: actionType.SET_NAVIGATION,
    payload: {...props.componentProps.navigation,"productNavigation":{...navObj.productNavigation}},
});
}
if(propsObj.pageInfo.btnAction && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y')
await commonButtonMethod({...component,Action:{...propsObj.pageInfo.btnAction}},props,stateObj,props.functions,e);
else
props.componentProps.history.push("/submission");
props.functions.UpdateState('loading',false,true);
}

const findConsoleNavigation = async(EventName,propsObj,stateObj,component,functions,e,props) =>{
  let tempName = component.Custom1
  store.dispatch(authaction.resetsubmission());
  // multiLobApi.clearSubmission();
  let pageDetail = {}
  props.componentProps.navigationReducer.navigation.pageNavigation.find.sidebar.map((item) => {
    if(item.children){
      item.children.filter((temp) => {
          if(temp.name.toLowerCase() === tempName.toLowerCase()){
            pageDetail ={ ...temp}
          }
        })
    }
  })
  store.dispatch({type: actionType.SET_PAGENAME, payload: pageDetail.pageName });
  props.componentProps.setfindConsoleInfo(pageDetail);
  props.componentProps.history.push("/find");
}




const componentDidMount_MPL=async(state, props)=>{
  const response = await componentDidMount_Orchid(state, props, state.transactionPrimaryObject)
  return response
 }


 const InvokeRating = async(state, props)=>{
   try{
     let request={};
     request["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
     request.OwnerId = sessionStorage.getItem("OwnerID");
     const currentPageIndex  = props.UIFlowReducer.currentPageIndex;
     request.TargetEventName= props.pageNavigation[currentPageIndex-1].geteventName;
     request.ID=  props.UIFlowReducer.insuredID;
     request.EventName=GetCommonEventName();
     const response= await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2",request);
     return response
   }
   catch(err){
     console.log("Error in InvokeRating", err)
   }
 }


const componentDidMount_RatingCreateQuote_MPL=async(state,props)=>
 {
  props.UpdateState("loading",true,true);
   let response = {}
     if(props.UIFlowReducer.insuredID){
       response = await InvokeRating(state, props);
     }

     if(response.data && response.data.Error && Object.values(response.data.Error).length>0){
      let errMessage = <ul>
        {Object.values(response.data.Error).map(val=> <li>{val}</li>)}
        </ul>
      triggerInfoToast(errMessage,{ position: Toast.POSITION.TOP_CENTER, autoClose: 20*1000})
    }

   let subApp=props.subApplicationName[0];
   let tValues={};
   tValues[subApp]={"IsReferralRuleApplied":response.data.BusinessRuleStatus||"false"};
   let RatingCreateQuote_dataList = response.data && response.data.Coverages? response.data.Coverages : [{}]
   let businessrules_dataList = response.data && response.data.BusinessRules? response.data.BusinessRules : []
   props.UpdateState("businessrules_dataList",businessrules_dataList,true);
   let Obj = await componentDidMount_Orchid(state, props,{...state.transactionPrimaryObject,...tValues})
   props.UpdateState("loading",false,true);
   return {...Obj,RatingCreateQuote_dataList,businessrules_dataList,pagelevelValues: {...state.pagelevelValues,...tValues},transactionPrimaryObject: {...state.transactionPrimaryObject,...tValues}}
 }


const getCoverageList = async(state, props)=>{
  try{
    let request={};
    request["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.TargetEventName=props.pageInfo.geteventName;
    request.ID=  props.UIFlowReducer.insuredID;
    request.EventName=GetCommonEventName();
    const response= await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    return response
  }
  catch(err){
    console.log("Error in getCoverageList", err)
  }
}

const setCoveragesList = async(state, props)=>{
  try{
    let response= await getCoverageList(state, props);
    let coverages =response.data.Coverages[0];
    let coverageList=[]
    Object.entries(coverages).forEach(([key,value])=>  {
      if(value.Select==='Yes'){
        coverageList.push(key)
      }
    })


    let MetadataRes =await componentDidMount_MPL(state, props);
    let TranslatedObj = {}
    let detailResponse = {}
    for (let i=0;i<coverageList.length;i++){
      const getRes = await getCoverageDetail_MPL(state,props,coverageList[i])
       detailResponse = {...detailResponse,...getRes.data}
      let Cov1 = await EntitySubAppTranslation_Metadata(state, props, getRes.data, MetadataRes.pageMetadata)
      Object.assign(TranslatedObj, Cov1)
      }
  Object.assign(state.transactionPrimaryObject, {...TranslatedObj}, {...coverages})
    MetadataRes = await componentDidMount_MPL(state, props);


    if(!detailResponse.hasOwnProperty('FL_ApplicantsEmployeeBenefitPlans')|| (detailResponse.hasOwnProperty('FL_ApplicantsEmployeeBenefitPlans') &&detailResponse.FL_ApplicantsEmployeeBenefitPlans.length>0)){
      await store.dispatch({
        type: actionType.IS_COVERAGE_SAVE,
        payload: true
    });
    }

     return {MetadataRes, pageLevelValues: {...state.pageLevelValues}, transactionPrimaryObject: {...state.transactionPrimaryObject}, coverageiapl2_1_c_dataList: detailResponse.IAPL_EntityList,coverageepl6_8_a_dataList: detailResponse.EPL_ReportedClaimsBreakDown, coveragefl7_1_dataList: detailResponse.FL_ApplicantsEmployeeBenefitPlans, coveragefl7_3_a_dataList: detailResponse.FL_ReportedClaimsBreakDown};
  }
  catch(exception){
    console.log("Error in setCoveragesList", exception)
  }
  }

const componentDidMountDetail_MPL=async(state, props,UpdateState)=>{
  UpdateState("loading",true,true);
  try{
  const {MetadataRes, ...rest}= await setCoveragesList(state, props)
  return {...MetadataRes, ...rest};
  }catch(error){
  console.log("Error in componentDidMountDetail_MPL",error);
  }
  finally{
    UpdateState("loading",false,true);
  }
}

const getCoverageDetail_MPL= async (state, props, cov)=>{
  let request={};
  const currentPageIndex  = props.UIFlowReducer.currentPageIndex;
  request["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request.TargetEventName=props.pageNavigation[currentPageIndex-1].geteventName;
  request.ID=props.UIFlowReducer.insuredID;
  const reduxState = store.getState();
request.EventName=GetCommonEventName();
  request.CoverageName= cov;
  const response= await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2",request);
  return response
}





const componentDidMount_CoverageSelection_MPL=async(state, props,UpdateState)=>{
  UpdateState("loading",true,true);
  try{
    let response = {}
    let values = {};
    if(props.UIFlowReducer.insuredID){
      response = await getCoverageList(state, props);
      let subAppName =props.subApplicationName
      Object.entries(response.data).forEach(([key,value]) =>{
        if(subAppName.includes(key)){
          values={...values,...value}
        }
      })
    }
    let Obj = await componentDidMount_Orchid(state, props)
    let SelectedCoverageSelectionOption_datalist = response.data ? response.data.Coverages : [{}]
    let subAppInfo= {subappName:props.pageInfo.iteratorSubappList ,objectId: props.objectId,
      objectVerId: props.objectVerId}
      for(let i=0; i < SelectedCoverageSelectionOption_datalist.length ; i++){
        let metadata = await constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: {...SelectedCoverageSelectionOption_datalist[i]} })
        SelectedCoverageSelectionOption_datalist[i]= { ...SelectedCoverageSelectionOption_datalist[i],SubApplicationDetailList:metadata.ApplicationDetail.SubApplicationDetailList }
      }
    UpdateState("SelectedCoverageSelectionOption_datalist",SelectedCoverageSelectionOption_datalist,false); // check if its needed
    UpdateState("pageMetadata",{},false);
  return {...Obj,values, SelectedCoverageSelectionOption_datalist,pagelevelValues:{...state.pageLevelValues,...response.data},transactionPrimaryObject:{...state.transactionPrimaryObject,...response.data}}
}
  catch(err){
    console.log("Error in componentDidMount_CoverageSelection_MPL", err)
  }
  finally{
    UpdateState("loading",false,true);
  }
}


const getLocationList_Verdant=async(props)=>{
  try{
    let request=Object.create({});
    request["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
    request["OwnerId"] = sessionStorage.getItem("OwnerID")
    request.EventName = GetCommonEventName();
    request.TargetEventName  = props.pageInfo.GetLocationListEventName;
    request.ROOTID = store.getState().UIFlowReducer.insuredID;
    request.ID = store.getState().UIFlowReducer.insuredID;
    request["EntityName"] = "Location";
    request.PARENTID = store.getState().UIFlowReducer.insuredID;
    request.SubmissionID = store.getState().UIFlowReducer.submissionID;
    if(props.pageInfo && props.pageInfo.GetLocationListEventAction)
      request.EventAction = props.pageInfo.GetLocationListEventAction
    const response = await LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request)
    if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
      triggerToast("Unable to get location list");
      console.log("Response Failed");
     return;
    }
    let locationList = await response.data.Location;
    let firstLocation = locationList && locationList[0]
    let Building = firstLocation.Building[0]
    store.dispatch({ type: actionType.SET_UI_VALUES, payload: {StateId:firstLocation.StateRegionCode}})
    return {locationList, Location: firstLocation, Building};
  }catch(error){
    console.log('Error in getting location list: getLocationList_Verdant ',error);
  }
}

const getLocationList_Orchid=async(props)=>{
  let {UpdateState} = props.functions
  try{
    let locationList = props.componentState.locationList || []
      let values = locationList.map(data=>{
        let {LocationFullAddress:DisplayValue,ID:Sequence,ID:Value}=data
        return{DisplayValue,Sequence,Value};
    })
    let {component, componentProps, componentState} = props
    UpdateState("loading",true,true)
    let subappinfo = {
      objectId : componentProps.objectId,
      objectVerId : componentProps.objectVerId,
      subappName : componentProps.subApplication
    }

    let tpo={...componentState.transactionPrimaryObject,
      [`${component.SubApplicationName}`]:{...componentState.transactionPrimaryObject[`${component.SubApplicationName}`],[`${component.AttributeName}`] :componentState.Location.ID}}
    let metadataResponse = await constructAndInvokeMetadataCall(subappinfo, {
      transactionPrimaryObject: {...tpo}
    });
    UpdateState("pageMetadata",metadataResponse,false)
    UpdateState("loading",false,true)
    return {dynSubApplicationAttributeValueList:values};
  }catch(error){
    UpdateState("loading",false,true)
    console.log('Error in getting location list: getLocationList_Orchid ',error);
  }
}


async function callMeataDataIterator(props, dataList){
  try{
      let subappName = []
      let subappNameList = props.pageInfo.iteratorSubappList;
      let selectedSubline = props.pageInfo.selectedSubline ? props.pageInfo.selectedSubline : {};
      let choosedsubline = props.componentState && props.componentState.SublineName ? props.componentState.SublineName : ''
      let neededSubapps = []
      Object.keys(selectedSubline).length > 0 && Object.entries(selectedSubline).forEach(([key,value])=>{
          if(key === choosedsubline){
               neededSubapps.push(value)
          }
        })
          subappName = [...subappNameList,...neededSubapps]
      let subappinfo = {}
      let {objectId,objectVerId, iteratorSubappList
   } = props.pageInfo;
      subappinfo = {
        objectId : objectId,
        objectVerId : objectVerId,
        subappName: subappName
      }
console.log("callMeataDataIterator state", props)
    let dataListKey = props && props.componentState && props.componentState.ResponseKey;
    let IteratordataList = (dataList && dataList[dataListKey]) || props.componentState[dataListKey] || props.componentProps[dataListKey]
    let transactionPrimaryObject = {...props.componentState.transactionPrimaryObject,
      ...IteratordataList[props.componentState.IteratorIndex]}
  let metadataResponse = await constructAndInvokeMetadataCall(subappinfo,{transactionPrimaryObject});
  // IteratordataList[props.componentState.IteratorIndex].SubApplicationDetailList = metadataResponse.ApplicationDetail.SubApplicationDetailList
    console.log("ayy check DisplayCoverages", props.pageInfo)
    console.log("ayy check DisplayCoverages", IteratordataList)
    // return {[dataListKey]: IteratordataList, loading:false}
    return {SubApplicationDetailList: metadataResponse.ApplicationDetail.SubApplicationDetailList, loading:false}
  }
  catch (error)
  {
    console.log("Error in callMeataDataIterator", error);
  }
}

const offerQuoteResponseConstruction = async (response, propsobj, stateobj, e) => {
  try {
    propsobj.functions.UpdateState("loading", true, true);
    let getEmailEventname = propsobj.pageInfo.eventName.getEmailList;
    let getQuoteResponse = await getQuoteData(propsobj, propsobj.pageInfo.CreateQuoteTargetEventName, propsobj.UpdateState);
    let detailSubApp = [];
    let summarySubApp = [];
    let subappNameList = [];
    let detailSummaryList = {};
    const subappName = propsobj.pageInfo.subApplication || propsobj.pageInfo.subApplicationName;
    const subApplicationGroupList = propsobj.pageInfo.subApplicationGroup;
    subAppListConstruction(
      subappName,
      detailSummaryList,
      subappNameList,
      detailSubApp,
      summarySubApp
    );
    let subappinfo = {
      subappName: subappNameList,
      objectId: propsobj.pageInfo.objectId,
      objectVerId: propsobj.pageInfo.objectVerId,
      subApplicationGroupList
    };
    let {getQuoteListTargetEventName,getQuoteListSearchName}=propsobj.pageInfo;
    let getQuoteListResponse=await getQuoteList(propsobj,getQuoteListTargetEventName,getQuoteListSearchName)
    let RuleInformationMessageNeed = response.hasOwnProperty("Message") ? { "RuleInformationMessageNeed": "true" } : {}
    let metadataResponse = await constructAndInvokeMetadataCall(subappinfo, { transactionPrimaryObject: { ...stateobj.transactionPrimaryObject, ...getQuoteResponse.data, ...RuleInformationMessageNeed } });
    let getEmailList = getEmailEventname && await getEmail(propsobj,metadataResponse,getEmailEventname,getQuoteResponse.data.ID);
    let pollEmail = propsobj.pageInfo.pollEmail;
    if(pollEmail){
      let intervalData;
      let intervalSec = 1000 * propsobj.pageInfo.intervalSec;
      let timeOutSec = 1000 * propsobj.pageInfo.timeOutSec;
      intervalData = setInterval(function() {
        getEmailList = updateEmailList(propsobj,metadataResponse,getEmailEventname,getQuoteResponse.data.ID,propsobj.functions.UpdateState); }
        ,intervalSec)
        propsobj.functions.UpdateState('intervalData',intervalData,false);
      setTimeout(() => {
        clearInterval(intervalData);
      }, timeOutSec);
      }
    let temp={};
    temp.quotelist_dataList = getQuoteListResponse
    temp.ruleinformationlist_dataList=getQuoteResponse.data.RuleInformationList
    temp.summarytaxdetailssp_dataList=getQuoteResponse.data.Tax;
    temp.summaryfeedetailssp_dataList=getQuoteResponse.data.Fee;
    temp.emailsummary_dataList =getEmailList && getEmailList.data && getEmailList.data.Email;
    await mergeRowdatawithDatalist(metadataResponse.ApplicationDetail.SubApplicationDetailList,temp,{})
    propsobj.functions.updateValues({
      "pagelevelValues": { ...stateobj.pagelevelValues, ...getQuoteResponse.data },
      "pageMetadata": metadataResponse,
      "transactionPrimaryObject": { ...stateobj.transactionPrimaryObject, ...getQuoteResponse.data },
      "quotelist_dataList": getQuoteListResponse,
      "loading": false ,
      forms_dataList:getQuoteResponse.data.Forms,
      documents_dataList : getQuoteResponse.data.Documents,
      notes_dataList:getQuoteResponse.data.Notes,
      ruleinformationlist_dataList:getQuoteResponse.data.RuleInformationList,
      attachments_dataList:getQuoteResponse.data.Attachments,
      policyattachments_dataList: getQuoteResponse.data.PolicyAttachments,
      summarytaxdetailssp_dataList:getQuoteResponse.data.Tax,
      summaryfeedetailssp_dataList:getQuoteResponse.data.Fee,
      emailsummary_dataList : getEmailList && getEmailList.data ? getEmailList.data.Email : []
    });
  } catch (e) {
    propsobj.functions.UpdateState("loading", false, true);
    console.log('offerQuoteResponseConstruction Failed', e);
  }
}

const updateIteratorMetadata = (state, props, obj) =>{
  let dataListKey = props && props.componentState && props.componentState.ResponseKey;
  let IteratordataList = state[dataListKey]
  IteratordataList[props.componentState.IteratorIndex].SubApplicationDetailList = obj.SubApplicationDetailList
    console.log("ayy check DisplayCoverages", props.pageInfo)
    console.log("ayy check DisplayCoverages", IteratordataList)
    return {[dataListKey]: IteratordataList, loading:false}
}

const handleInputChangeV2Callback_MPL = async (
  value,
  component,
  isDynamicTable,
  datatableRowindex,
  props,
  state,
  functions,
  DynamicProps
) => {
  try {
    if (
      component.AttributeName === "PersonalPropertyCoverage" &&
      component.SubApplicationName === "HO3CoverageDetails"
    ) {
      console.log("ayy check handleInputChangeV2Callback_MPL", value);
    }
    let reduxState = store.getState();
    let primaryState = reduxState.metadataReducer.primaryState;
    let route = reduxState.metadataReducer.route
      ? store.getState().metadataReducer.route
      : "";
    let subAppInfo = {};
    if (route === "Policy" || route === "Endorsement" || route === "Quote") {
      let value;
      value = route === "Endorsement" ? "Quote" : route;
      let { objectId, objectVerId } = props.pageInfo;
      let { subappNameList } = state;
      subAppInfo = {
        subappName: subappNameList,
        objectId,
        objectVerId,
      };
    } else {
      subAppInfo = {
        subappName:
          props.pageInfo["subApplication"] ||
          props["subApplication"] ||
          props["subApplicationName"] ||
          state.subappNameList,
        objectId: props.objectId,
        objectVerId: props.objectVerId,
        state: primaryState,
        subApplicationGroupList: props.subApplicationGroup,
      };
    }

    let currentStateUpdate =
      !("currentStateUpdate" in DynamicProps.pageInfo) ||
      DynamicProps.pageInfo.currentStateUpdate === "Y";
    let immediateParentUpdate =
      "immediateParentUpdate" in DynamicProps.pageInfo &&
      DynamicProps.pageInfo.immediateParentUpdate === "Y";

    let obj = {};
    let dataListKey =
      DynamicProps &&
      DynamicProps.componentState &&
      DynamicProps.componentState.ResponseKey;
    let IteratorIndex =
      DynamicProps &&
      DynamicProps.componentState &&
      DynamicProps.componentState.IteratorIndex;
    let updateFunc =
      (immediateParentUpdate &&
        DynamicProps.componentProps.parentState.UpdateState) ||
      (currentStateUpdate && DynamicProps.functions.UpdateState);
    let setLoader = (val) => updateFunc && updateFunc("loading", val, true);

    if (dataListKey && typeof IteratorIndex === "number") {
      let dataList =
        DynamicProps.componentState[dataListKey] ||
        DynamicProps.componentProps[dataListKey];
      if (immediateParentUpdate) {
        dataList = DynamicProps.componentProps[dataListKey];
      }
      dataList = dataList.map((data, index) => {
        if (index === IteratorIndex) {
          let subappObj = data[component.SubApplicationName] || {};
          let dataObj = {
            [component.SubApplicationName]: { ...subappObj, ...value },
          };
          return { ...data, ...dataObj };
        }
        return data;
      });
      obj = { [dataListKey]: dataList };
      if (
        DynamicProps.pageInfo.iteratorSubappList &&
        DynamicProps.pageInfo.iteratorSubappList.length > 0 &&
        component.AjaxNeeded === "true"
      ) {
        if (
          Object.keys(component.Action).length > 0 &&
          component.Action.timeDelay !== undefined &&
          component.Action.timeDelay !== "" &&
          typeof Number(component.Action.timeDelay) === "number"
        ) {
          debounceCall(async function () {
            setLoader(true)
           let obj1 = await callMeataDataIterator(DynamicProps,obj)
            if (currentStateUpdate) {
                DynamicProps.functions.UpdateCallBack(updateIteratorMetadata, DynamicProps, obj1);
            } else if (immediateParentUpdate) {
              DynamicProps.componentProps && DynamicProps.componentProps.parentState && DynamicProps.componentProps.parentState && DynamicProps.componentProps.parentState.UpdateCallBack(updateIteratorMetadata, DynamicProps, obj1);
            }
          }, Number(component.Action.timeDelay));
        } else {
          setLoader(true)
          obj = await DisplayCoverages(DynamicProps, obj,component);
          setLoader(false)
        }
      }
    }
    else {
      obj = await handleInputChangeV2(
        value,
        component,
        props,
        state,
        functions,
        subAppInfo,
        {},
        true,
        DynamicProps
      );
    }
    if (currentStateUpdate) {
      return { ...obj, loading: false };
    } else if (immediateParentUpdate) {
      Object.entries(obj).forEach(([key, value], i) => {
        DynamicProps.componentProps.parentState.UpdateState(
          key,
          value,
          Object.entries(obj).length - 1 === i
        );
      });
      return { loading: false };
    }
  } catch (exception) {
    triggerToast("Error in handleInputChangeV2Callback_MPL", exception);
    console.log("Error in handleInputChangeV2Callback_MPL", exception);
  }
};


   const handleInputChangeV2Callback_Renewals = async (value,component,isDynamicTable,datatableRowindex,props, state, functions, DynamicProps) => {
    let res = await handleInputChangeV2_Orchid(value,component, isDynamicTable,datatableRowindex,props, {...props.componentProps,...state}, functions, DynamicProps)
    if(DynamicProps && DynamicProps.pageInfo.iteratorSubappList && DynamicProps.pageInfo.iteratorSubappList.length > 0 && DynamicProps.componentState.hasOwnProperty('IteratorIndex') && component.AjaxNeeded==='true'){
     await DisplayCoverages(DynamicProps, res);
    }
    return res
  }

   const getAvailableMarkets_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props,needReturnValues=false)=>{
    try{
    let {SubApplicationName}=component;
    let {UpdateState}=props.functions;
    let bool = false;
    let params = {};
    let policyformdisplay = true;
    UpdateState("loading",true,true);
    let {pageMetadata:{ApplicationDetail:{ObjectId:objectId,ObjectVerId:objectVerId}},transactionPrimaryObject,subappNameList:subappName,pagelevelValues}=stateObj;
    let request = constructTransactionRequest(stateObj,propsObj,{...stateObj.pagelevelValues},true,false);
    request.SelectedLineOfBusiness=[props.componentProps.productDetails];
    request["TargetEventName"]=EventName;
    request["InsuredID"]=props.componentProps.UIFlowReducer.insuredID;
    request["LocationID"]=pagelevelValues[SubApplicationName]["LocationFullAddress"];
    request["SubmissionID"]=props.componentProps.UIFlowReducer.submissionID;
    request.EventName=GetCommonEventName();
    if(stateObj["CarrierName"] && stateObj["SublineName"]){
      request["CarrierName"]=stateObj["CarrierName"];
      request["SublineName"]=stateObj["SublineName"];
    }
    if( props.pageInfo.AvailableMarketEventAction){
      request["EventAction"] = props.pageInfo.AvailableMarketEventAction
    }
    let reduxValues = store.getState();
    if (props.pageInfo && props.pageInfo.officeDetailsNeeded) {
      let {OfficeDetailsRequestKey = [], OfficeDetailsValues = []} = props.pageInfo
      let {userInfoReducer} = reduxValues
      if (OfficeDetailsRequestKey.length > 0 && OfficeDetailsValues.length > 0) {
        let OfficeDetailsRequestKeyLength = OfficeDetailsRequestKey.length
        for (let i = 0; i < OfficeDetailsRequestKeyLength; i++) {
          request[OfficeDetailsRequestKey[i]] = userInfoReducer.OfficeMappingDetails && userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] ? userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] : ''
        }
      }
      // request['ProductVerNumber'] = reduxValues.UIFlowReducer.productDetails.ProductVerNumber
    }
    params={transactionPrimaryObject};
    if(reduxValues.UIFlowReducer && reduxValues.UIFlowReducer.productDetails&& reduxValues.UIFlowReducer.productDetails.ProductVerNumber && reduxValues.UIFlowReducer.productDetails.ProductNumber){
      request['ProductVerNumber'] = reduxValues.UIFlowReducer.productDetails.ProductVerNumber;
      request['ProductNumber'] = reduxValues.UIFlowReducer.productDetails.ProductNumber;
    }
    const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    if(response.data.hasOwnProperty('IsRuleMessageAvailable') || response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() !=='ineligible'){
      UpdateState("viewbusinessrulespl_dataList", response.data.RuleInformationList, false)
      transactionPrimaryObject["ProductSelection"]={...transactionPrimaryObject["ProductSelection"],...response.data.ProductSelection};
      params={transactionPrimaryObject:{...response.data,...transactionPrimaryObject,...response.data.ProductSelection}};
      policyformdisplay = response.data.IsRuleMessageAvailable ===undefined ? true : 'Ineligible';
      bool=true;
      props.componentProps.UpdateState("pagelevelValues",{...props.componentProps.contextValue.pagelevelValues,...response.data.ProductSelection},false);
      await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
    }
    await store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: policyformdisplay});
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
      triggerToast("Unable to get policy forms");
      console.log("Response Failed");
      UpdateState("loading",false,true);
     return;
    }
    const metadataCall = await constructAndInvokeMetadataCall({subappName,objectId,objectVerId},params);
    props.componentProps.parentState.UpdateState(`${component.SubApplicationName}_RadioValues`,response.data,false);
    props.componentProps.UpdateState(`${component.SubApplicationName}_RadioValues`,response.data,false);
    // UpdateState("pageMetadata",{"ApplicationDetail":metadataCall.ApplicationDetail},false)
    // UpdateState("transactionPrimaryObject",transactionPrimaryObject,false);
    // UpdateState("loading",false,bool);
    if(bool){
      props.functions.updateValues({"pageMetadata":{"ApplicationDetail":metadataCall.ApplicationDetail},"transactionPrimaryObject":transactionPrimaryObject,"loading":false})
      return false;
    }
    // UpdateState("pagevalues",request.ProductSelection,false)
    // UpdateState(`${component.SubApplicationName}_RadioValues`,response.data,!bool);
    if(!needReturnValues)
    props.functions.updateValues({"pageMetadata":{"ApplicationDetail":metadataCall.ApplicationDetail},"transactionPrimaryObject":transactionPrimaryObject,"loading":false,"pagevalues":request.ProductSelection,[`${component.SubApplicationName}_RadioValues`]:response.data})
    return {"childValues":{"pageMetadata":{"ApplicationDetail":metadataCall.ApplicationDetail},"transactionPrimaryObject":transactionPrimaryObject,"loading":false},"parentValues":{"AvailableMarket":request.ProductSelection,[`${component.SubApplicationName}_RadioValues`]:response.data}}
    }
    catch(err){
      props.componentProps.UpdateState("loading",false,true);
      console.log("Error in getAvailableMarkets_HomeOwners",err);
      triggerToast("Unable to get policy forms");
    }
  }
  const getAvailableMarkets_Renewals=async(EventName,propsObj,stateObj,component,functions,e,props,needReturnValues=false)=>{
    try{
    let {SubApplicationName}=component;
    let {UpdateState}=props.functions;
    let bool = false;
    let params = {};
    let policyformdisplay = true;
    UpdateState("loading",true,true);
    let reduxValues = store.getState();
    let {pageMetadata:{ApplicationDetail:{ObjectId:objectId,ObjectVerId:objectVerId}},transactionPrimaryObject,subappNameList:subappName,pagelevelValues}=stateObj;
    let request = constructTransactionRequest(stateObj,propsObj,{...stateObj.pagelevelValues},true,false);
    request.SelectedLineOfBusiness=[props.componentProps.productDetails];
    request["TargetEventName"]=EventName;
    request["InsuredID"]=props.componentProps.UIFlowReducer.insuredID;
    request["LocationID"]=pagelevelValues[SubApplicationName]["LocationFullAddress"];
    request["SubmissionID"]=props.componentProps.UIFlowReducer.submissionID;
    request.EventName=GetCommonEventName();
    if(stateObj["CarrierName"] && stateObj["SublineName"]){
      request["CarrierName"]=stateObj["CarrierName"];
      request["SublineName"]=stateObj["SublineName"];
    }
    if( props.pageInfo.AvailableMarketEventAction){
      request["EventAction"] = props.pageInfo.AvailableMarketEventAction
    }
    params={transactionPrimaryObject};
    if(reduxValues.UIFlowReducer && reduxValues.UIFlowReducer.productDetails&& reduxValues.UIFlowReducer.productDetails.ProductVerNumber && reduxValues.UIFlowReducer.productDetails.ProductNumber){
      request['ProductVerNumber'] = reduxValues.UIFlowReducer.productDetails.ProductVerNumber;
      request['ProductNumber'] = reduxValues.UIFlowReducer.productDetails.ProductNumber;
    }
    const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    if(response.data.hasOwnProperty('IsRuleMessageAvailable') || response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() !=='ineligible'){
      UpdateState("viewbusinessrulespl_dataList", response.data.RuleInformationList, false)
      transactionPrimaryObject["ProductSelection"]={...transactionPrimaryObject["ProductSelection"],...response.data.ProductSelection};
      params={transactionPrimaryObject:{...response.data,...transactionPrimaryObject,...response.data.ProductSelection}};
      policyformdisplay = response.data.IsRuleMessageAvailable ===undefined ? true : 'Ineligible';
      bool=true;
      props.componentProps.UpdateState("pagelevelValues",{...props.componentProps.contextValue.pagelevelValues,...response.data.ProductSelection},false);
      await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
    }
    await store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: policyformdisplay});
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
      triggerToast("Unable to get policy forms");
      console.log("Response Failed");
      UpdateState("loading",false,true);
     return;
    }
    const metadataCall = await constructAndInvokeMetadataCall({subappName,objectId,objectVerId},params);
    props.componentProps.parentState.UpdateState(`${component.SubApplicationName}_RadioValues`,response.data,false);
    props.componentProps.UpdateState(`${component.SubApplicationName}_RadioValues`,response.data,false);

    if(!(response.data.hasOwnProperty('IsRuleMessageAvailable') || response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() !=='ineligible')){
    let carrierList;
    if(response && response.data && response.data.SublineList){
    response.data.SublineList.map(element=>{
      if(element.Subline.SublineName===props.pagelevelValues.ProductSelection.PolicyForms){
        if(props.pageInfo && "activeConfiguration" in props.pageInfo && props.pageInfo.activeConfiguration==='package' && "PackageList" in element){
          carrierList  = element.PackageList.map(data =>
            ({"AvailableMarket":{...data,...data.CarrierList[0]}}))
        }
        else{
          carrierList= element.CarrierList.map(data=> ({"AvailableMarket":data}))
        }
        props.pagelevelValues.ProductSelection["Carriers"]=element.Subline["Carriers"];
      }
    });
  }
    let objectConstructed={ "AvailableMarketList_dataList":carrierList,"AvailableMarket":{...props.pagelevelValues.ProductSelection},"AvailableMarket_Needed": true}
    props.componentProps.parentFunctions.updateValues(objectConstructed)
}
    if(bool){
      props.functions.updateValues({"pageMetadata":{"ApplicationDetail":metadataCall.ApplicationDetail},"transactionPrimaryObject":transactionPrimaryObject,"loading":false})
      return false;
    }
    if(!needReturnValues)
    props.functions.updateValues({"pageMetadata":{"ApplicationDetail":metadataCall.ApplicationDetail},"transactionPrimaryObject":transactionPrimaryObject,"loading":false,"pagevalues":request.ProductSelection,[`${component.SubApplicationName}_RadioValues`]:response.data})
    return {"childValues":{"pageMetadata":{"ApplicationDetail":metadataCall.ApplicationDetail},"transactionPrimaryObject":transactionPrimaryObject,"loading":false},"parentValues":{"AvailableMarket":request.ProductSelection,[`${component.SubApplicationName}_RadioValues`]:response.data}}
    }
    catch(err){
      props.componentProps.UpdateState("loading",false,true);
      console.log("Error in getAvailableMarkets_Renewals",err);
      triggerToast("Unable to get policy forms");
    }
  }
 export const getNonConfiguredMarkets=async(EventName,propsObj,stateObj,component,functions,e,props,needReturnValues=false)=>{
    try{
      let {SubApplicationName}=component;
      let {UpdateState}=props.functions;
      let bool = false;
      let params = {};
      UpdateState("loading",true,true);
      let {pageMetadata:{ApplicationDetail:{ObjectId:objectId,ObjectVerId:objectVerId}},transactionPrimaryObject,subappNameList:subappName,pagelevelValues}=stateObj;
      let request = constructTransactionRequest(stateObj,propsObj,{...stateObj.pagelevelValues},true,false);
      let productSelection = request.ProductSelection
      let SelectedLineOfBusiness =  store.getState().UIFlowReducer.productDetails
      request.SelectedLineOfBusiness= [store.getState().UIFlowReducer.productDetails]
      request["TargetEventName"]=EventName;
      request["InsuredID"]=props.componentProps.UIFlowReducer.insuredID;
      request["LocationID"]=pagelevelValues[SubApplicationName]["LocationFullAddress"];
      request["SubmissionID"]=props.componentProps.UIFlowReducer.submissionID;
      request.EventName=GetCommonEventName();
      let reduxValues = store.getState();
      if (props.pageInfo && props.pageInfo.officeDetailsNeeded) {
        let {OfficeDetailsRequestKey = [], OfficeDetailsValues = []} = props.pageInfo
        let {userInfoReducer} = reduxValues
        if (OfficeDetailsRequestKey.length > 0 && OfficeDetailsValues.length > 0) {
          let OfficeDetailsRequestKeyLength = OfficeDetailsRequestKey.length
          for (let i = 0; i < OfficeDetailsRequestKeyLength; i++) {
            request[OfficeDetailsRequestKey[i]] = userInfoReducer.OfficeMappingDetails && userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] ? userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] : ''
          }
        }
        request['ProductVerNumber'] = reduxValues.UIFlowReducer.productDetails.ProductVerNumber
      }
      params={transactionPrimaryObject};
      store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: false })
      request.SubmissionType = props.componentProps.metadataReducer.SubmissionType;
      if(propsObj.parentPropsValue && propsObj.parentPropsValue.pagelevelValues && propsObj.parentPropsValue.pagelevelValues.IsInforcePolicy){
        request['IsInforcePolicy'] = propsObj.parentPropsValue.pagelevelValues.IsInforcePolicy;
      }
      const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
      if(response.data.ErrorMessage){
        transactionPrimaryObject["ProductSelection"]={...transactionPrimaryObject["ProductSelection"],...stateObj.pagelevelValues.ProductSelection};
        params={transactionPrimaryObject:{...transactionPrimaryObject,...response.data.ErrorMessage}};
        bool=true;
      }
      if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
        triggerToast("Unable to get Carrier List");
        UpdateState("loading",false,true);
       return;
      }
      let carrierList=[], AvailableMarket ={}, AllSublines = [];
response.data.CarrierList && response.data.CarrierList.forEach(item => {
  carrierList.push({
    "AvailableMarket": {
      ...item,
      Subline: item.SublineName && item.SublineName.map(subline => subline.SublineName).join(' , '),
    }
  });
    Object.keys(item).forEach(key => {
      if (Array.isArray(item[key])) {
        AllSublines.push(...item[key].map(subline => subline.SublineName));
      } else {
        AvailableMarket[key] = item[key];
      }
    });
    AvailableMarket.SublineName = AllSublines;
});
      props.functions.UpdateState("transactionPrimaryObject",transactionPrimaryObject,false);
      const metadataCall = await constructAndInvokeMetadataCall({subappName,objectId,objectVerId},params);
      const classLists = Array.from(document.getElementsByClassName("AvailableMarket_toggleView"));
      if(classLists.length)
      {
        classLists.forEach((element) => {
          element.classList.remove("AvailableMarket_toggleView");
        });
      }
      let objectConstructed={"AvailableMarketList_dataList":carrierList,AvailableMarket:{...AvailableMarket},"ProductSelection":productSelection}
      if(!needReturnValues){
      props.componentProps.parentFunctions.updateValues({...objectConstructed})

      }
      if(bool){
        props.functions.updateValues({"pageMetadata":{"ApplicationDetail":metadataCall.ApplicationDetail},"transactionPrimaryObject":transactionPrimaryObject,"loading":false})
        return false;
      }
      UpdateState("loading",false,true);

      }
      catch(err){
        props.componentProps.UpdateState("loading",false,true);
        console.log("Error in getNonConfiguredMarkets",err);
        triggerToast("Unable to get policy forms");
      }
  }
  const saveAvailableMarkets_HomeOwners=async(EventName,propsObj,stateObj,component,functions,props,needReturnValues=false)=>{
    try{
    let {SubApplicationName}=component;
    let {UpdateState}=props.functions;
    let bool = false;
    let params = {};
    let policyformdisplay = true;
    UpdateState("loading",true,true);
    let {pageMetadata:{ApplicationDetail:{ObjectId:objectId,ObjectVerId:objectVerId}},transactionPrimaryObject,subappNameList:subappName,pagelevelValues}=stateObj;
    let request = constructTransactionRequest(stateObj,propsObj,{...stateObj.pagelevelValues},true,false);
    props.componentProps.parentState.UpdateState(`AvailableMarket`,{...props.componentProps.AvailableMarket,...stateObj.pagelevelValues[component.SubApplicationName]}, true)
    request.SelectedLineOfBusiness=[props.componentProps.productDetails];
    request["TargetEventName"]=EventName;
    request["InsuredID"]=props.componentProps.UIFlowReducer.insuredID;
    request["LocationID"]=pagelevelValues[SubApplicationName]["LocationFullAddress"];
    request["SubmissionID"]=props.componentProps.UIFlowReducer.submissionID;
    request.EventName=GetCommonEventName();
    if(stateObj["CarrierName"] && stateObj["SublineName"]){
      request["CarrierName"]=stateObj["CarrierName"];
      request["SublineName"]=stateObj["SublineName"];
    }
    request["SublineName"] = props.componentProps.SublineList.SublineName
    params={transactionPrimaryObject};
    const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    if(response.data.hasOwnProperty('IsRuleMessageAvailable')){
      transactionPrimaryObject["ProductSelection"]={...transactionPrimaryObject["ProductSelection"],...response.data.ProductSelection};
      params={transactionPrimaryObject:{...transactionPrimaryObject,...response.data.ProductSelection}};
      policyformdisplay = response.data.IsRuleMessageAvailable ===undefined ? true : 'Ineligible';
      bool=true;
      props.componentProps.UpdateState("pagelevelValues",{...props.componentProps.contextValue.pagelevelValues,...response.data.ProductSelection},false);
      await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
    }
    await store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: policyformdisplay});
    UpdateState("loading",false,true);
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
      triggerToast("Unable to get policy forms");
      console.log("Response Failed");
      UpdateState("loading",false,true);
     return;
    }
    props.componentProps.parentState.UpdateState('coverageUpdateNeeded',true,true)
    }
    catch(err){
      props.componentProps.UpdateState("loading",false,true);
      console.log("Error in saveAvailableMarkets_HomeOwners",err);
      triggerToast("Unable to get policy forms");
    }
  }
  const setDefaultValueBasedOnMinMaxValidation = (currentValue, valueToBeSet, comparator)=>{
    if(comparator==="setMinValue" && (currentValue==="" || parseInt(valueToBeSet) > parseInt(currentValue))){
      return valueToBeSet
    }else if(comparator==="setMaxValue" && (currentValue==="" || parseInt(currentValue) > parseInt(valueToBeSet))){
      return valueToBeSet
    }
    return currentValue;
  }

  const bumpDwellingCoverageToRCV = async(props)=>{
  let {functions:{UpdateState},transactionPrimaryObject, component}=props;
    UpdateState("loading",true,true);
    let key = component.AttributeName;
    let value = setDefaultValueBasedOnMinMaxValidation(props.componentvalue, transactionPrimaryObject.VeriskCalculatedRcv, "setMinValue");
    // component={...component,'AjaxNeeded':'false','Action':{},}
    props.handleInputChangeV2({[key]: value}, component, props);
    UpdateState("loading",false,true);
  }

  function splitDataCL (response) {
    let obj = {};
    obj['pagelevelValues'] = {};
    obj['transactionPrimaryObject'] = {};
    Object.keys(response).map( key => {
      if(Array.isArray(response[key])){
        let dataKey = key.toLowerCase() + '_dataList';
        obj[dataKey] = response[key];
        let dataLen = obj[dataKey].length;
        for(let i = 0; i<dataLen; i++) {
          let split = splitDataCL(obj[dataKey][i]);
          obj[dataKey][i] = { ...obj[dataKey][i], ...split };
        }
      }
      else if(typeof response[key] === "object"){
        obj['pagelevelValues'][key] = response[key];
        obj['transactionPrimaryObject'][key] = response[key];
      }
    })
    return obj;
  }

    const getBuildingPremium_CL=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
      try{
      await store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: true});
      let {SubApplicationName}=component;
      let {UpdateState}=props.functions;
      let bool = false;
      let params = {};
      let policyformdisplay = true;
      UpdateState("loading",true,true);
      let {pageMetadata:{ApplicationDetail:{ObjectId:objectId,ObjectVerId:objectVerId}},transactionPrimaryObject,subappNameList:subappName,pagelevelValues}=stateObj;
      let request={}
      request["ServiceRequestDetail"]= getServiceRequestDetail();
      request["TargetEventName"]=propsObj.GetRate;
      request["ID"]=props.componentProps.UIFlowReducer.submissionID;
      request['OwnerId']=sessionStorage.getItem('OwnerID')
      request.EventName=propsObj.eventName;
      request.ProductNumber=props.componentProps.productDetails.ProductNumber
      request.ProductVersionNumber=props.componentProps.productDetails.ProductVerNumber
      let PagelevelValues= await constructTransactionRequestV1(stateObj,propsObj,{...stateObj.pagelevelValues},false,false,false);
      request.EffectiveDate=request.ApplicableDate=PagelevelValues.PolicyInfo.EffectiveDate
      // request.EffectiveDate=request.ApplicableDate= props.transactionPrimaryObject.hasOwnProperty('PolicyInfo') && props.transactionPrimaryObject.PolicyInfo.EffectiveDate
      request.ExpirationDate= props.transactionPrimaryObject.hasOwnProperty('PolicyInfo') && props.transactionPrimaryObject.PolicyInfo.ExpirationDate
      params={transactionPrimaryObject};
      const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
      splitDataCL(response.data)
      UpdateState("premium_dataList",response.data.Premium,true)
      if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
        triggerToast("Error in getBuildingPremium_CL");
        console.log("Response Failed");
        UpdateState("loading",false,true);
       return;
      }
      if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "success"){
        store.dispatch({type: actionType.SET_BUILDING_PREMIUM,payload: true});
        let {objectId,objectVerId}=propsObj;
        let {subappNameList}=stateObj
        let subAppInfo={
          subappName: subappNameList,
          objectId:objectId,
          objectVerId:objectVerId
        }

        const pageMetadata=await constructAndInvokeMetadataCall(subAppInfo,{transactionPrimaryObject:{...transactionPrimaryObject,...response.data}});
        UpdateState("pageMetadata",{"ApplicationDetail":pageMetadata.ApplicationDetail},true)
            }
     UpdateState("pagelevelValues",response.data,true)
      UpdateState("transactionPrimaryObject",{...transactionPrimaryObject,...response.data},true);
      UpdateState("loading",false,true);
      }
      catch(err){
        props.functions.UpdateState("loading",false,true);
        console.log("Error in getBuildingPremium_CL",err);
      }
    }
  const savePolicyInfo_CL=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
    try{
    await store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: true});
    let {SubApplicationName}=component;
    let {UpdateState}=props.functions;
    let bool = false;
    let params = {};
    UpdateState("loading",true,true);
    let request={}
    let {pageMetadata:{ApplicationDetail:{ObjectId:objectId,ObjectVerId:objectVerId}},transactionPrimaryObject,subappNameList:subappName,pagelevelValues}=stateObj;
    let PagelevelValues= await constructTransactionRequestV1(stateObj,propsObj,{...stateObj.pagelevelValues},false,false,false);
    request.PolicyInfo = PagelevelValues.hasOwnProperty('PolicyInfo') && PagelevelValues.PolicyInfo
    request["ServiceRequestDetail"] = await Object.assign({}, getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request["TargetEventName"]=propsObj.SavePolicyInfoTargetEventName;
    request["ID"]=props.componentProps.UIFlowReducer.submissionID;
    request['CoverageID']=''
    request['LiabilityCoverageID ']=''
    request.EventName=propsObj.eventName;
    params={transactionPrimaryObject};
    const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
      triggerToast("Response Failed");
      console.log("Response Failed");
      UpdateState("loading",false,true);
     return;

    }
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "success"){
      store.dispatch({type: actionType.SET_BUILDING_PREMIUM,payload: true});
      store.dispatch({type: actionType.SET_ENABLE_RATE,payload: true});
      let {objectId,objectVerId}=propsObj;
      let {subappNameList}=stateObj
      let subAppInfo={
        subappName: subappNameList,
        objectId:objectId,
        objectVerId:objectVerId
      }
      const metadataCall=await constructAndInvokeMetadataCall(subAppInfo,{transactionPrimaryObject:{...transactionPrimaryObject,...PagelevelValues,...response.data}});
      UpdateState("pageMetadata",{"ApplicationDetail":metadataCall.ApplicationDetail},false)
    }
    UpdateState("transactionPrimaryObject",{...transactionPrimaryObject,...PagelevelValues},false);
    UpdateState("loading",false,true);
    if(bool){
      return;
    }
    }
    catch(err){
      props.functions.UpdateState("loading",false,true);
      console.log("Error in savePolicyInfo_CL",err);
    }
  }
const availableMarketsValues_HomeOwners=(props)=>{
 try{
  const {component,componentState}=props;
  let newValues= componentState[`${component.SubApplicationName}_RadioValues`] && componentState[`${component.SubApplicationName}_RadioValues`].SublineList.map(data=>{
    let {SublineDisplayName:DisplayValue,SublineName:Value}=data.Subline;
    return{DisplayValue,Value};
  });
  return {dynSubApplicationAttributeValueList:newValues};
 }
 catch(error){
  console.log("Error in availableMarketsValues_HomeOwners",error);
 }
}

const selectForms_HomeOwners=async(props,value,Values=false)=>{
  try{
        let {component, componentProps: {UIFlowReducer:{quoteID}}} = props;
    const mappingKeys = component.ItemTipParams && component.ItemTipParams.split('~')
    const ModifyQuote = (mappingKeys.length > 0 && mappingKeys[0]==='modifyQuote' && mappingKeys[1]=== 'Y')

    if(quoteID!==undefined && quoteID!=='' && !ModifyQuote){
      clearQuoteDetails_HomeOwners();
    }
  let {[`${component.SubApplicationName}_RadioValues`]:values}=props.componentState;
  let {UpdateState}=props.componentProps.parentState;
  let carrierList;
    values.SublineList.map(element=>{
      if(element.Subline.SublineName===value.PolicyForms){
        if(props.pageInfo && "activeConfiguration" in props.pageInfo && props.pageInfo.activeConfiguration==='package' && "PackageList" in element){
          carrierList  = element.PackageList.map(data =>
            ({"AvailableMarket":{...data,...data.CarrierList[0]}}))
        }
        else{
          carrierList= element.CarrierList.map(data=> ({"AvailableMarket":data}))
        }
        value["Carriers"]=element.Subline["Carriers"];
      }
    });
let {transactionPrimaryObject} = props
// if(transactionPrimaryObject.hasOwnProperty(component.SubApplicationName)){
//   transactionPrimaryObject[component.SubApplicationName]["AdjustCoverage"]="false"
// }else{
//   transactionPrimaryObject[component.SubApplicationName] = {}
//   transactionPrimaryObject[component.SubApplicationName]["AdjustCoverage"]="false"
// }
props.functions.UpdateState("transactionPrimaryObject",transactionPrimaryObject,false);
let list;
props.componentState.ProductSelection_RadioValues.SublineList.map(ele=>{
if(ele.Subline.SublineName===value.PolicyForms){list=ele.Subline}})
// Below Section closes all the open Toggle's in previously selected Policy Form
const classLists = Array.from(document.getElementsByClassName("AvailableMarket_toggleView"));
if(classLists.length)
{
  classLists.forEach((element) => {
    element.classList.remove("AvailableMarket_toggleView");
  });
}
let prdSelectionValues = Values ? {...props.componentState.pagevalues,...Values.parentValues.AvailableMarket} : props.componentState.pagevalues
let uid = uuidv4();
let objectConstructed={"RequestID": uid, "AvailableMarketList_dataList":carrierList,[`${component.SubApplicationName}_RadioValues`]:values,"AvailableMarket":{...prdSelectionValues,...value},"SublineList":list,"dynamicLoadingMessage":component.Custom1, updatedCoverage: {}, selectedPolicy: value.PolicyForms}

if(Values){
  props.componentProps.parentFunctions.updateValues({...Values.parentValues,...objectConstructed})
  props.functions.updateValues({...Values.childValues})
}
else
props.componentProps.parentFunctions.updateValues({...objectConstructed})
await props.handleInputChangeV2(value, props.component, props.isDynamicTable, props.datatableRowindex,props);
}
catch(err){
  props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);
  console.log("Error in selectForms_HomeOwners",err);
}
}

let TableControls = {
  TableOptions:{
    ActionListOptions: [],
    rowsPerPageOptions: [5, 10, 25],
    RowsPerPage: 5,
  }
}
const dummyGetInteg_Homes=async(propsObj,stateObj,component,e,props)=>{
  try{
  let {componentProps:{parentState:{UpdateState}}}=props;
  UpdateState("popupPageValues",{...stateObj.pagelevelValues},false);
  UpdateState("Availablemarket_IteratorIndex",stateObj.IteratorIndex,false)
  UpdateState("ScheduleOfForm__popupStatus",true,true);
}
catch(e){
  console.log("error in dummyGetInteg_Homes",e);
}
}

const QuotaShare_popup=async(EventName, propsObj, stateObj, component,functions,e,props,option,rowData)=>{
  try{
  store.dispatch({type:actionType.SET_UI_VALUES,payload: { [`${option.popupName}_popup`]: true}});
  store.dispatch({type:actionType.SET_UI_VALUES,payload: { [`Availablemarket_IteratorIndex`]: stateObj.IteratorIndex}});
}
catch(e){
  console.log("error in QuotaShare_popup",e);
}
}

const dummyGetInteg_Homes_RateIndication=async(EventName, propsObj, stateObj, component,functions,e,props,option,rowData)=>{
  try{
  let {componentProps:{parentState:{UpdateState}}}=props;
  UpdateState("popupPageValues",{...stateObj.pagelevelValues},false);
  UpdateState("Availablemarket_IteratorIndex",stateObj.IteratorIndex,false)
  UpdateState("ScheduleOfForm__popupStatus",true,true);
}
catch(e){
  console.log("error in dummyGetInteg_Homes_RateIndication",e);
}
}

const reteriveSubappList = (subAppList, SubAppName, newSubappList, subAppPresent) => {
  try {
    subAppList.forEach((subappName) => {
      if (subappName.ApplicationName.toLowerCase() === SubAppName.toLowerCase() || subAppPresent) {
        newSubappList.push(subappName.ApplicationName);
        subappName.ChildSubApplicationList.length && reteriveSubappList(subappName.ChildSubApplicationList, SubAppName, newSubappList, true);
        subappName.AttributeDetailList.length && attributeIteration(subappName.AttributeDetailList, SubAppName, newSubappList, true)
      }
      else {
        subappName.ChildSubApplicationList.length && reteriveSubappList(subappName.ChildSubApplicationList, SubAppName, newSubappList, false);
        subappName.AttributeDetailList.length && attributeIteration(subappName.AttributeDetailList, SubAppName, newSubappList, false)
      }
    })
  } catch (e) {
    console.log("error in reteriveSubappList", e);
  }
}

const attributeIteration = (attList, SubAppName, newSubappList, subAppPresent) => {
  try {
    attList.forEach(attribute => attribute.SubApplicationDetailList.length && reteriveSubappList(attribute.SubApplicationDetailList, SubAppName, newSubappList, subAppPresent))
  } catch (e) {
    console.log("error in attributeIteration", e);
  }
}
function decline_Submission_HomeOwners(props,component){
  try{
    let declineSubmissionRequest={};
    declineSubmissionRequest["ServiceRequestDetail"] = getServiceRequestDetail();
    declineSubmissionRequest.ID=props.componentProps.UIFlowReducer.submissionID;
    declineSubmissionRequest.TargetEventName=component.EventName;
    const reduxState = store.getState();
    declineSubmissionRequest.OwnerId=sessionStorage.getItem("OwnerID");
    declineSubmissionRequest.EventName=GetCommonEventName();
    return declineSubmissionRequest
  }catch(e){
    console.log('Error in decline_Submission_HomeOwners',e)
  }
}

const DeclineSubmission_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    functions['UpdateState']("loading",true,true)
    let declineSubmissionRequest=decline_Submission_HomeOwners(props,component);
    const declineSubmissionResponse=await AxiosInstance.post("core/invokeWorkflowEvent/V2",declineSubmissionRequest);
    await store.dispatch({type:actionType.SET_SUBMISSION_STATUS,payload: declineSubmissionResponse.data.SubmissionStatus})
    let constructTpo = {...stateObj.transactionPrimaryObject,AvailableMarket:{...props.componentProps.AvailableMarket,...propsObj.AvailableMarketList_dataList[props.componentState.IteratorIndex]["AvailableMarket"]},"AvailableMarket__IsReferralRuleApplied":"false",...declineSubmissionResponse.data}
    props.componentProps.UpdateState("pagelevelValues",{...props.componentProps.contextValue.pagelevelValues,...declineSubmissionResponse.data},false);
    let temp={}
    temp.ruleinformationlistpl_dataList = stateObj.ruleinformationlistpl_dataList
    await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
    let mountResult = await componentDidMount_Orchid(stateObj, propsObj, {...stateObj.transactionPrimaryObject,AvailableMarket:{...props.componentProps.AvailableMarket,...propsObj.AvailableMarketList_dataList[props.componentState.IteratorIndex]["AvailableMarket"]},"saveRules":"true",...declineSubmissionResponse.data})
    functions.UpdateState("transactionPrimaryObject" , {...stateObj.transactionPrimaryObject,...constructTpo}, false)
    let newList = propsObj.AvailableMarketList_dataList;
      newList[props.componentState.IteratorIndex]['SubApplicationDetailList']= mountResult.pageMetadata.ApplicationDetail.SubApplicationDetailList
      props.componentProps.parentState.UpdateState("AvailableMarketList_dataList",newList,false);
    await mergeRowdatawithDatalist(mountResult.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{});
    component.EventName === "DeclineSubmission" && triggerToast('Submission Declined', { position: Toast.POSITION.TOP_RIGHT });
    functions['UpdateState']("loading",false,true)
  }catch(e){
    functions['UpdateState']("loading",false,true)
    console.log('Error in DeclineSubmission_HomeOwners',e);
  }
}






const invokePremiumandRating_HomeOwners=async(props,state,prevprops,prevstate,referSubmission=false)=>{
  try{
  if((prevprops===undefined) ||(props.componentProps.SublineList!==prevprops.componentProps.SublineList) ){
  let {SubApplicationName,AttributeName}=props.component, subapplist=[`${props.componentProps.SublineList.SublineName}CoverageDetails`]
  let {componentProps:{AvailableMarketList_dataList},componentProps:{parentState:{UpdateState}}}=props;
  reteriveSubappList(props.componentState.pageMetadata.ApplicationDetail.SubApplicationDetailList,SubApplicationName,subapplist,false)
  let pageLevelValues={[`${SubApplicationName}`]:{...props.componentProps.AvailableMarketList_dataList[props.componentState.IteratorIndex],...props.componentProps.SublineList}}
  let request = constructTransactionRequestV2(props.componentState,props.componentProps,pageLevelValues,true,false);
  let SubApplicationList = props.pageInfo.subApplication;
    let subappname = SubApplicationList
    let summarySubApp = []
    let childDatalist={};
    subAppListConstructionV1(subappname, {}, [], [], summarySubApp)
    summarySubApp.forEach(
      (key) =>{
        if(props.componentState.hasOwnProperty(`${key.toLocaleLowerCase()}_dataList`)){
          childDatalist[key]=props.componentState[`${key.toLocaleLowerCase()}_dataList`]
        }
        else if(props.componentProps.hasOwnProperty(`${key.toLocaleLowerCase()}_dataList`)){
          childDatalist[key]=props.componentProps[`${key.toLocaleLowerCase()}_dataList`]
        }
      }
    )
  if(!request[SubApplicationName]){Object.assign(request,{[SubApplicationName]:{}})}
  request.Coverage={}
  request.Coverage=(props.componentState.updatedCoverage && AvailableMarketList_dataList[props.componentState.IteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`])&&
  {...AvailableMarketList_dataList[props.componentState.IteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`],...props.componentState.updatedCoverage,...childDatalist};
  (prevprops || props).componentProps.SublineList && (prevprops || props).componentProps.SublineList.SublineName &&
  delete request[`${SubApplicationName}`][`${(prevprops || props).componentProps.SublineList.SublineName}CoverageDetails`]
  request[`${SubApplicationName}`]["ProductSelection"]=props.componentProps.AvailableMarket;
  request[`${SubApplicationName}`]["insuredID"]=props.componentProps.UIFlowReducer.insuredID;
  request["SubmissionID"]=props.componentProps.UIFlowReducer.submissionID;
  request["TargetEventName"]=props.pageInfo.RatingEvent;request.EventName=GetCommonEventName();
  const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
  if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
    triggerToast("Unable to get carriers list");
    console.log("Response Failed");
    // UpdateState("loading",false,true);
    // props.functions.UpdateState("loading",false,true);
  //  return;
  }
  if(response && response.data && Object.keys(response.data).length > 0 && response.data.ResponseStatus.toLowerCase() !== "failed"){
    await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {"SubjectivityListRenderFlag":"Yes"} });
  }
  if(response && response.data && response.data.hasOwnProperty("ExternalQuoteNumber")){ store.dispatch({ type: actionType.SET_EXTERNAL_QUOTE_NUMBER, payload: response.data.ExternalQuoteNumber }); }
  const { ProductID: objectId, ProductVerID: objectVerId } =props.componentProps.productDetails;
  let subappinfo = {
    subappName: subapplist,
    objectId,
    objectVerId,
    state:"CW",
    subApplicationGroupList: [],
  };
  let Tlist = {AvailableMarket:{...props.componentProps.AvailableMarket,...AvailableMarketList_dataList[props.componentState.IteratorIndex]["AvailableMarket"],"IsReferralRuleApplied": response.data.RuleStatus && response.data.RuleStatus ||"false"},[`${props.componentProps.SublineList.SublineName}CoverageDetails`]:{...response.data.Coverage},...response.data}
  await store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: false })
  let metadataresponse = []
  let checkRuleList;
  // if(response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() ==='ineligible'){
  //   checkRuleList = true;
  //   Tlist["ShowBusinessRules"] ="true";
  //   subappinfo.subappName = props.pageInfo.RuleInfoSubapp;
    // metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
    //   transactionPrimaryObject:Tlist,
    //   EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    // });
    // props.functions.UpdateState(props.pageInfo.RuleInfoSubapp,response.data.RuleInformationList,true)
  // }
   if(response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() !=='ineligible'){
    checkRuleList = false;
  Tlist["ShowBusinessRules"] ="false";
  metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
    transactionPrimaryObject:Tlist,
    EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
  });
}
let responseFromTranslation=await EntitySubAppTranslationV2({...response.data},{...metadataresponse.ApplicationDetail});
  let metadata=metadataresponse && metadataresponse.ApplicationDetail && metadataresponse.ApplicationDetail.SubApplicationDetailList
  AvailableMarketList_dataList[props.componentState.IteratorIndex] = {...AvailableMarketList_dataList[props.componentState.IteratorIndex],...responseFromTranslation}
  AvailableMarketList_dataList[props.componentState.IteratorIndex][`${SubApplicationName}`][`${AttributeName}`]=response.data.Coverage && response.data.Coverage.TotalPremium;
  AvailableMarketList_dataList[props.componentState.IteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`]={}
  AvailableMarketList_dataList[props.componentState.IteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`]={...response.data.Coverage};
  AvailableMarketList_dataList[props.componentState.IteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageInformationDetails`]= [];
  AvailableMarketList_dataList[props.componentState.IteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageInformationDetails`]= (response.data.Coverage && response.data.Coverage.CoverageRuleInformationList) ? [...response.data.Coverage.CoverageRuleInformationList] : [] ;
  AvailableMarketList_dataList[props.componentState.IteratorIndex][`feescarrier_dataList`] = response.data.Fee;
  AvailableMarketList_dataList[props.componentState.IteratorIndex][`taxescarrier_dataList`] = response.data.Tax;
  AvailableMarketList_dataList[props.componentState.IteratorIndex]['SubApplicationDetailList']= metadata|| []
  AvailableMarketList_dataList[props.componentState.IteratorIndex]['SubmissionRatingDataID']=  response.data.SubmissionRatingDataID;
  AvailableMarketList_dataList[props.componentState.IteratorIndex]['isEligible']= response.data.RuleStatus && response.data.RuleStatus.toLowerCase();
  props.functions.UpdateState('ruleinformationlistpl_dataList',response.data.RuleInformationList,false)
  response.data && response.data.Coverage &&  Object.keys(response.data.Coverage).forEach(
    (key) => {
      if(summarySubApp.includes(key)){
        props.functions.UpdateState(`${key.toLocaleLowerCase()}_dataList`,response.data.Coverage[key],false);
      }
    }
  )
  let sortedList=[...AvailableMarketList_dataList]
  let issort=sortedList.every( (data)=>data["AvailableMarket"] && data["AvailableMarket"].hasOwnProperty("TotalPremium") )
  let temp={}
  temp.ruleinformationlistpl_dataList = response.data.RuleInformationList
  if(response.data && response.data.hasOwnProperty("RuleStatus") &&metadata ){
  await mergeRowdatawithDatalist(metadata,temp,{});
  }
  props.functions.UpdateState("pagelevelValues",AvailableMarketList_dataList[props.componentState.IteratorIndex],false);
  props.functions.UpdateState("transactionPrimaryObject",Tlist,false);
  UpdateState("PopUp_ScheduleOfForms",response.data.Coverage,false);
  if(issort){
   let sortedList_ = sortedList.filter(data =>data.AvailableMarket && data.AvailableMarket.TotalPremium )
    sortedList_.length > 1 && sortedList.sort( (a,b)=>{return Number(a.AvailableMarket.TotalPremium) - Number(b.AvailableMarket.TotalPremium)});
      let isEligible=AvailableMarketList_dataList.every( (data)=>data["isEligible"] && data["isEligible"]==='ineligible')
      if(isEligible){
        let subappinfo1 = {
          // subappName: props.pageInfo.subApplication,
          subappName: props.pageInfo.RuleInfoSubapp,
          objectId,
          objectVerId,
          state:"CW",
          subApplicationGroupList: [],
        };
        Tlist["ShowBusinessRules"] ="true";
      //  let TlistV1 = {AvailableMarket:{...props.componentProps.AvailableMarket}};
        const metadataCall=await constructAndInvokeMetadataCall(subappinfo1, {
          transactionPrimaryObject:{...Tlist},
          EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
        })
        // props.componentProps.UpdateState("pagelevelValues",{...props.componentProps.pagelevelValues},false);
        // await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
        props.functions.UpdateState('pageMetadata',metadataCall,false);
        props.functions.UpdateState(props.pageInfo.RuleInfoSubapp,response.data.RuleInformationList,false)
        props.functions.UpdateState("viewbusinessrulespl_dataList",response.data.RuleInformationList,false)
        // props.componentProps.parentState.UpdateState("prevPropsCheck",1,false)
      }
      if(checkRuleList===undefined || checkRuleList===true){
        UpdateState('AvailableMarketList_dataList',sortedList,false);
      }else{
        props.functions.UpdateState('AvailableMarketList_dataList',sortedList,false);
      }
      let isexit=props.componentProps.AvailableMarketList_dataList ? props.componentProps.AvailableMarketList_dataList.every((data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined) : sortedList.every( (data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined);
      isexit && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);
  }
  else{
  let sortedList=[...AvailableMarketList_dataList]
  if(checkRuleList===undefined || checkRuleList===true){
    UpdateState('AvailableMarketList_dataList',sortedList,false);
  }else{
    props.functions.UpdateState('AvailableMarketList_dataList',sortedList,false);
  }
  let isexit=props.componentProps.AvailableMarketList_dataList ? props.componentProps.AvailableMarketList_dataList.every((data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined) : sortedList.every( (data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined)
  isexit && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);

  }
  }else{
    return;
  }
}
 catch(err){
  props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);
  console.log("error in changeValues_HomeOwners",err);
 }
}




const invokePremiumandRating_HomeOwnersV1=async(props,state,prevprops,prevstate,referSubmission=false)=>{
  try{
  if((prevprops===undefined) ||(props.componentProps.SublineList!==prevprops.componentProps.SublineList) ){
  let {SubApplicationName,AttributeName}=props.component, subapplist=[]
  let {componentProps:{AvailableMarketList_dataList},componentProps:{parentState:{UpdateState}}}=props;
  reteriveSubappList(props.componentState.pageMetadata.ApplicationDetail.SubApplicationDetailList,SubApplicationName,subapplist,false)
  let iteratorIndex = props.componentState.IteratorIndex
  let pageLevelValues={...props.componentProps.AvailableMarketList_dataList[iteratorIndex],[SubApplicationName]:{[SubApplicationName]: {...AvailableMarketList_dataList[iteratorIndex][SubApplicationName]},...props.componentProps.SublineList}}
  let request = {}
  let {subApplication, iteratorSubappList} = props.pageInfo;
  let subappName = []
  subappName = iteratorSubappList
  if(!AvailableMarketList_dataList[iteratorIndex].hasOwnProperty("SubApplicationDetailList") && AvailableMarketList_dataList[iteratorIndex].hasOwnProperty("Coverage") && Object.keys(AvailableMarketList_dataList[iteratorIndex].Coverage).length>0){
    pageLevelValues = {...pageLevelValues, ...await EntitySubAppTranslationV2({...pageLevelValues},props.componentState.pageMetadata.ApplicationDetail)}
    delete AvailableMarketList_dataList[iteratorIndex].Coverage
  }
  request = constructTransactionRequestV2(props.componentState,props.componentProps,pageLevelValues,true,false);
    let summarySubApp = []
    let childDatalist={};
    subAppListConstructionV1(iteratorSubappList, {}, [], [], summarySubApp)
    summarySubApp.forEach(
      (key) =>{
        // if(props.componentState.hasOwnProperty(`${key.toLocaleLowerCase()}_dataList`)){
        //   childDatalist[key]=props.componentState[`${key.toLocaleLowerCase()}_dataList`]
        // }
         if(props.componentProps.hasOwnProperty(`${key.toLocaleLowerCase()}_dataList`)){
          childDatalist[key]=props.componentProps[`${key.toLocaleLowerCase()}_dataList`]
        }
      }
    )
  if(!request[SubApplicationName]){Object.assign(request,{[SubApplicationName]:{}})}
  let updatedCoverage = {}
    // if( prevprops && (prevprops.componentProps && prevprops.componentProps.AvailableMarket && (props.componentProps.AvailableMarket.PolicyForms !== prevprops.componentProps.AvailableMarket.PolicyForms))){
    //   updatedCoverage = {}
    // }
    // else{
      // updatedCoverage = props.componentState.updatedCoverage
    // }
      updatedCoverage = props.componentProps.updatedCoverage
      request.Coverage={...request.Coverage, ...updatedCoverage}
     if(Object.keys(request.Coverage).length>0) {request.Coverage ={...request.Coverage, ...childDatalist} }
     else{delete request.Coverage}

  // request.Coverage=(props.componentState.updatedCoverage && AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`])&&
  // {...AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`],...props.componentState.updatedCoverage,...childDatalist};
  // (prevprops || props).componentProps.SublineList && (prevprops || props).componentProps.SublineList.SublineName &&
  // delete request[`${SubApplicationName}`][`${(prevprops || props).componentProps.SublineList.SublineName}CoverageDetails`]
  request[`${SubApplicationName}`]["ProductSelection"]=props.componentProps.AvailableMarket;
  request[`${SubApplicationName}`]["insuredID"]=props.componentProps.UIFlowReducer.insuredID;
  request["SubmissionID"]=props.componentProps.UIFlowReducer.submissionID;
  request["TargetEventName"]=props.pageInfo.RatingEvent;request.EventName=GetCommonEventName();
  const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
  if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
    triggerToast("Unable to get carriers list");
    console.log("Response Failed");
    // UpdateState("loading",false,true);
    // props.functions.UpdateState("loading",false,true);
  //  return;
  }
  if(response && response.data && response.data.hasOwnProperty("ExternalQuoteNumber")){ store.dispatch({ type: actionType.SET_EXTERNAL_QUOTE_NUMBER, payload: response.data.ExternalQuoteNumber }); }
  const { ProductID: objectId, ProductVerID: objectVerId } =props.componentProps.productDetails;
  let subappinfo = {
    subappName,
    objectId,
    objectVerId,
    state:"CW",
    subApplicationGroupList: [],
  };
  await store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: false })

  let Tlist = {AvailableMarket:{...props.componentProps.AvailableMarket,...AvailableMarketList_dataList[iteratorIndex]["AvailableMarket"],"IsReferralRuleApplied": response.data.RuleStatus && response.data.RuleStatus ||"false"},...response.data}
  let metadataresponse = []
  let checkRuleList;
  // if(response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() ==='ineligible'){
  //   checkRuleList = true;
  //   Tlist["ShowBusinessRules"] ="true";
  //   subappinfo.subappName = props.pageInfo.RuleInfoSubapp;
    // metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
    //   transactionPrimaryObject:Tlist,
    //   EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    // });
    // props.functions.UpdateState(props.pageInfo.RuleInfoSubapp,response.data.RuleInformationList,true)
  // }
  let responseFromTranslation = {}
   if(response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() !=='ineligible'){
    checkRuleList = false;
  Tlist["ShowBusinessRules"] ="false";
  responseFromTranslation=await EntitySubAppTranslationV2({...response.data},props.componentState.pageMetadata.ApplicationDetail);
  Tlist = {...Tlist, ...responseFromTranslation}
  metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
    transactionPrimaryObject:Tlist,
    EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
  });
}


  // responseFromTranslation=await EntitySubAppTranslationV2({...response.data},metadataresponse.ApplicationDetail);


  let metadata=metadataresponse && metadataresponse.ApplicationDetail && metadataresponse.ApplicationDetail.SubApplicationDetailList
  AvailableMarketList_dataList[iteratorIndex] = {...AvailableMarketList_dataList[iteratorIndex],...responseFromTranslation,...response.data}
  AvailableMarketList_dataList[iteratorIndex][`${SubApplicationName}`][`${AttributeName}`]=response.data.Coverage && response.data.Coverage.TotalPremium;
  // AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`]={}
  // AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`]={...response.data.Coverage};
  AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageInformationDetails`]= [];
  AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageInformationDetails`]= (response.data.Coverage && response.data.Coverage.CoverageRuleInformationList) ? [...response.data.Coverage.CoverageRuleInformationList] : [] ;
  AvailableMarketList_dataList[iteratorIndex][`feescarrier_dataList`] = response.data.Fee;
  AvailableMarketList_dataList[iteratorIndex][`taxescarrier_dataList`] = response.data.Tax;
  AvailableMarketList_dataList[iteratorIndex]['SubApplicationDetailList']= metadata|| []
  AvailableMarketList_dataList[iteratorIndex]['SubmissionRatingDataID']=  response.data.SubmissionRatingDataID;
  AvailableMarketList_dataList[iteratorIndex]['isEligible']= response.data.RuleStatus && response.data.RuleStatus.toLowerCase();
  if(response.data.CarrierDisplayName)
  AvailableMarketList_dataList[iteratorIndex][SubApplicationName].CarrierDisplayName = response.data.CarrierDisplayName && response.data.CarrierDisplayName;
  props.functions.UpdateState('ruleinformationlistpl_dataList',response.data.RuleInformationList,false)
  response.data && response.data.Coverage &&  Object.keys(response.data.Coverage).forEach(
     (key) => {
      if(summarySubApp.includes(key)){
        props.functions.UpdateState(`${key.toLocaleLowerCase()}_dataList`,response.data.Coverage[key],false);
      }
    }
  )
  let sortedList=[...AvailableMarketList_dataList]
  let issort=sortedList.every( (data)=>data["AvailableMarket"] && data["AvailableMarket"].hasOwnProperty("TotalPremium") )
  let temp={}
  temp.ruleinformationlistpl_dataList = response.data.RuleInformationList
  if(response.data && response.data.hasOwnProperty("RuleStatus") &&metadata ){
  await mergeRowdatawithDatalist(metadata,temp,{});
  }
  props.functions.UpdateState("pagelevelValues",AvailableMarketList_dataList[iteratorIndex],false);
  if(prevprops && props.componentProps.selectedPolicy!==prevprops.componentProps.selectedPolicy){
    summarySubApp.map(key=>{
      UpdateState(`${key.toLocaleLowerCase()}_dataList`,[],false);
      props.functions.UpdateState(`${key.toLocaleLowerCase()}_dataList`,[],false);
    })
    }
  props.functions.UpdateState("transactionPrimaryObject",Tlist,false);
  // props.functions.UpdateState("updatedCoverage",updatedCoverage,false);
  UpdateState("PopUp_ScheduleOfForms",response.data.Coverage,false);
  if(issort){
   let sortedList_ = sortedList.filter(data =>data.AvailableMarket && data.AvailableMarket.TotalPremium )
    sortedList_.length > 1 && sortedList.sort( (a,b)=>{return Number(a.AvailableMarket.TotalPremium) - Number(b.AvailableMarket.TotalPremium)});
      let isEligible=AvailableMarketList_dataList.every( (data)=>data["isEligible"] && data["isEligible"]==='ineligible')
      if(isEligible){
        let subappinfo1 = {
          // subappName: props.pageInfo.subApplication,
          subappName: props.pageInfo.RuleInfoSubapp,
          objectId,
          objectVerId,
          state:"CW",
          subApplicationGroupList: [],
        };
        Tlist["ShowBusinessRules"] ="true";
      //  let TlistV1 = {AvailableMarket:{...props.componentProps.AvailableMarket}};
        const metadataCall=await constructAndInvokeMetadataCall(subappinfo1, {
          transactionPrimaryObject:{...Tlist},
          EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
        })
        // props.componentProps.UpdateState("pagelevelValues",{...props.componentProps.pagelevelValues},false);
        // await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
        props.functions.UpdateState('pageMetadata',metadataCall,false);
        props.functions.UpdateState(props.pageInfo.RuleInfoSubapp,response.data.RuleInformationList,false)
        props.functions.UpdateState("viewbusinessrulespl_dataList",response.data.RuleInformationList,false)
        // props.componentProps.parentState.UpdateState("prevPropsCheck",1,false)
      }
      if(checkRuleList===undefined || checkRuleList===true){
        UpdateState('AvailableMarketList_dataList',sortedList,false);
      }else{
        props.functions.UpdateState('AvailableMarketList_dataList',sortedList,false);
      }
      let isexit=props.componentProps.AvailableMarketList_dataList ? props.componentProps.AvailableMarketList_dataList.every((data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined) : sortedList.every( (data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined);
      isexit && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);
  }
  else{
  let sortedList=[...AvailableMarketList_dataList]
  if(checkRuleList===undefined || checkRuleList===true){
    UpdateState('AvailableMarketList_dataList',sortedList,false);
  }else{
    props.functions.UpdateState('AvailableMarketList_dataList',sortedList,false);
  }
  let isexit=props.componentProps.AvailableMarketList_dataList ? props.componentProps.AvailableMarketList_dataList.every((data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined) : sortedList.every( (data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined)
  isexit && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);

  }
  }else{
    return;
  }
}
 catch(err){
  props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);
  console.log("error in changeValues_HomeOwners",err);
 }
}



const invokePremiumandRating_HomeOwners_CarrierSpecficRules=async(props,state,prevprops,prevstate,referSubmission=false)=>{
  try{
  if((prevprops===undefined) ||(props.componentProps.SublineList!==prevprops.componentProps.SublineList)
   || (props.componentProps.coverageUpdateNeeded && props.componentProps.coverageUpdateNeeded === true)){
  await props.componentProps.coverageUpdateNeeded && props.componentProps.parentState.UpdateState("coverageUpdateNeeded",false,true);
   props.pageInfo.LoaderMessage && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",props.pageInfo.LoaderMessage,true);
  let {SubApplicationName,AttributeName}=props.component, subapplist=[]
  let {componentProps:{AvailableMarketList_dataList},componentProps:{parentState:{UpdateState}}}=props;
  reteriveSubappList(props.componentState.pageMetadata.ApplicationDetail.SubApplicationDetailList,SubApplicationName,subapplist,false)
  let iteratorIndex = props.componentState.IteratorIndex
  let pageLevelValues={...props.componentProps.AvailableMarketList_dataList[iteratorIndex],[SubApplicationName]:{[SubApplicationName]: {...AvailableMarketList_dataList[iteratorIndex][SubApplicationName]},...props.componentProps.SublineList}}
  let request = {}
  let {subApplication, iteratorSubappList} = props.pageInfo;
  let subappName = []
  subappName = iteratorSubappList
  if(!AvailableMarketList_dataList[iteratorIndex].hasOwnProperty("SubApplicationDetailList") && AvailableMarketList_dataList[iteratorIndex].hasOwnProperty("Coverage") && Object.keys(AvailableMarketList_dataList[iteratorIndex].Coverage).length>0){
    pageLevelValues = {...pageLevelValues, ...await EntitySubAppTranslationV2({...pageLevelValues},props.componentState.pageMetadata.ApplicationDetail)}
    delete AvailableMarketList_dataList[iteratorIndex].Coverage
  }
  request = constructTransactionRequestV2(props.componentState,props.componentProps,pageLevelValues,true,false);
    let childDatalist= getDataLists(props.pageInfo.dataListName, props.componentProps);
  if(!request[SubApplicationName]){Object.assign(request,{[SubApplicationName]:{}})}
  let updatedCoverage = {}
  updatedCoverage = props.componentProps.updatedCoverage

  request.Coverage={...request.Coverage, ...updatedCoverage}
  if(Object.keys(request.Coverage).length>0) {request.Coverage ={...request.Coverage, ...childDatalist} }
  else{delete request.Coverage}

  request[`${SubApplicationName}`]["ProductSelection"]=props.componentProps.AvailableMarket;
  request[`${SubApplicationName}`]["insuredID"]=props.componentProps.UIFlowReducer.insuredID;
  request["SubmissionID"]=props.componentProps.UIFlowReducer.submissionID;
  request["UserRole"]=props.componentProps.propsValue.userInfoReducer.userRole;
  request["TargetEventName"]=props.pageInfo.RatingEvent;request.EventName=GetCommonEventName();
  const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
  if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
    props.pageInfo.activeConfiguration==='package' ? triggerToast(`Unable to get
     ${request[SubApplicationName][SubApplicationName].PlanName} package`) :
    triggerToast("Unable to get carriers list");
    AvailableMarketList_dataList[iteratorIndex]['SubApplicationDetailList']= []
    props.functions.UpdateState('AvailableMarketList_dataList',AvailableMarketList_dataList,true);
    throw new Error();
  }

  const {metadataReducer} = store.getState();

  if("ProcessStatus" in response.data && metadataReducer.ProcessStatus !== response.data.ProcessStatus){
      store.dispatch({type: appActions.actionType.SET_PROCESS_STATUS, payload: response.data.ProcessStatus});
      store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
  }
  if(response && response.data && response.data.hasOwnProperty("ExternalQuoteNumber")){ store.dispatch({ type: actionType.SET_EXTERNAL_QUOTE_NUMBER, payload: response.data.ExternalQuoteNumber }); }
  const { ProductID: objectId, ProductVerID: objectVerId } =props.componentProps.productDetails;
  let subappinfo = {
    subappName,
    objectId,
    objectVerId,
    state:"CW",
    subApplicationGroupList: [],
  };
  let editableCoverage = "activeConfiguration" in props.pageInfo && props.pageInfo.activeConfiguration==='package' ? true: false;
  await store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: editableCoverage })
  let Tlist = {AvailableMarket:{...props.componentProps.AvailableMarket,...AvailableMarketList_dataList[iteratorIndex]["AvailableMarket"],"IsReferralRuleApplied": response.data.RuleStatus && response.data.RuleStatus ||"false"},...response.data}
  let metadataresponse = []
  let checkRuleList;
  // if(response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() ==='ineligible'){
  //   checkRuleList = true;
  //   Tlist["ShowBusinessRules"] ="true";
  //   subappinfo.subappName = props.pageInfo.RuleInfoSubapp;
    // metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
    //   transactionPrimaryObject:Tlist,
    //   EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    // });
    // props.functions.UpdateState(props.pageInfo.RuleInfoSubapp,response.data.RuleInformationList,true)
  // }
  let responseFromTranslation = {}
  //  if(response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() !=='ineligible'){
    checkRuleList = false;
    // Tlist["ShowBusinessRules"] =response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() ==='ineligible' ? "true" :  "false";
  responseFromTranslation=await EntitySubAppTranslationV2({...response.data},props.componentState.pageMetadata.ApplicationDetail);
  Tlist = {...Tlist, ...responseFromTranslation}
  metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
    transactionPrimaryObject:Tlist,
    EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
  });
// }

  // responseFromTranslation=await EntitySubAppTranslationV2({...response.data},metadataresponse.ApplicationDetail);
  if(response && response.data && Object.keys(response.data).length > 0 && response.data.ResponseStatus.toLowerCase() !== "failed"){
    await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {"SubjectivityListRenderFlag":"Yes"} });
  }

  let metadata=metadataresponse && metadataresponse.ApplicationDetail && metadataresponse.ApplicationDetail.SubApplicationDetailList
  AvailableMarketList_dataList[iteratorIndex] = {...AvailableMarketList_dataList[iteratorIndex],...responseFromTranslation,...response.data,...props.componentProps.SublineList}
  AvailableMarketList_dataList[iteratorIndex][`${SubApplicationName}`][`${AttributeName}`]=response.data.Coverage && response.data.Coverage.TotalPremium;
  // AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`]={}
  // AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`]={...response.data.Coverage};
  AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageInformationDetails`]= [];
  AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageInformationDetails`]= (response.data.Coverage && response.data.Coverage.CoverageRuleInformationList) ? [...response.data.Coverage.CoverageRuleInformationList] : [] ;
  AvailableMarketList_dataList[iteratorIndex][`feescarrier_dataList`] = response.data.Fee;
  AvailableMarketList_dataList[iteratorIndex][`taxescarrier_dataList`] = response.data.Tax;
  AvailableMarketList_dataList[iteratorIndex]['SubApplicationDetailList']= metadata|| []
  AvailableMarketList_dataList[iteratorIndex]['SubmissionRatingDataID']=  response.data.SubmissionRatingDataID;
  AvailableMarketList_dataList[iteratorIndex]['isEligible']= response.data.RuleStatus && response.data.RuleStatus.toLowerCase();
  AvailableMarketList_dataList[iteratorIndex]['viewbusinessrulespl_dataList']= response.data.RuleInformationList || [];
  AvailableMarketList_dataList[iteratorIndex]["ProductSelection"]=props.componentProps.AvailableMarket;
  if(response.data.CarrierDisplayName)
  AvailableMarketList_dataList[iteratorIndex][SubApplicationName].CarrierDisplayName = response.data.CarrierDisplayName && response.data.CarrierDisplayName;
  props.functions.UpdateState('ruleinformationlistpl_dataList',response.data.RuleInformationList,false)
  setDataLists(props.pageInfo.dataListName, response.data && response.data.Coverage, props.functions.UpdateState)
  let sortedList=[...AvailableMarketList_dataList]
  let issort=sortedList.every( (data)=>data["AvailableMarket"] && data["AvailableMarket"].hasOwnProperty("TotalPremium") )
  let temp={}
  temp.ruleinformationlistpl_dataList = response.data.RuleInformationList
  if(response.data && response.data.hasOwnProperty("RuleStatus") &&metadata ){
  await mergeRowdatawithDatalist(metadata,temp,{});
  }
  props.functions.UpdateState("pagelevelValues",AvailableMarketList_dataList[iteratorIndex],false);
  if(prevprops && props.componentProps.selectedPolicy!==prevprops.componentProps.selectedPolicy){
    ResetDataLists(props.pageInfo.dataListName, UpdateState, props.functions.UpdateState)
    }
  props.functions.UpdateState("transactionPrimaryObject",Tlist,false);
  // props.functions.UpdateState("updatedCoverage",updatedCoverage,false);
  UpdateState("PopUp_ScheduleOfForms",response.data.Coverage,false);
  if(issort){
   let sortedList_ = sortedList.filter(data =>data.AvailableMarket && data.AvailableMarket.TotalPremium )
    sortedList_.length > 1 && sortedList.sort( (a,b)=>{return Number(a.AvailableMarket.TotalPremium) - Number(b.AvailableMarket.TotalPremium)});
      if(checkRuleList===undefined || checkRuleList===true){
        UpdateState('AvailableMarketList_dataList',sortedList,false);
      }else{
        props.functions.UpdateState('AvailableMarketList_dataList',sortedList,false);
      }
      let isexit=props.componentProps.AvailableMarketList_dataList ? props.componentProps.AvailableMarketList_dataList.every((data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined) : sortedList.every( (data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined);
      isexit && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);
  }
  else{
  let sortedList=[...AvailableMarketList_dataList]
  if(checkRuleList===undefined || checkRuleList===true){
    UpdateState('AvailableMarketList_dataList',sortedList,false);
  }else{
    props.functions.UpdateState('AvailableMarketList_dataList',sortedList,false);
  }
  let isexit=props.componentProps.AvailableMarketList_dataList ? props.componentProps.AvailableMarketList_dataList.every((data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined) : sortedList.every( (data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined)
  isexit && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);

  }
  if(props.pageInfo.ratingRequestResponseNeeded){
    await invokeThirdPartyApi(props,response)
  }
  }else{
    return;
  }
}
 catch(err){
  props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);
  console.log("error in invokePremiumandRating_HomeOwners_CarrierSpecficRules,",err);
 }
}

const onRefreshPremiumV2 = async (request,propsObj,e,stateObj) =>{
  let SubApplicationName = "AvailableMarket";
  let AttributeName = "TotalPremium"
  try{
    propsObj.functions.UpdateState("loading",true,true)
    closeToggle('', propsObj, stateObj)
    let props = propsObj;
    const { ProductID: objectId, ProductVerID: objectVerId, ProductNumber, ProductVerNumber } =props.componentProps.productDetails;
    let {componentProps:{AvailableMarketList_dataList},componentProps:{parentState:{UpdateState}}}=props;
    let iteratorIndex = props.componentState.IteratorIndex
    let Tlist = {AvailableMarket:{...props.componentProps.AvailableMarket,...AvailableMarketList_dataList[iteratorIndex]["AvailableMarket"]}}

    let subappListLoader = [
      "AvailableMarket"
  ]

    let subappinfo = {
      objectId,
      objectVerId,
      state:"CW",
      subApplicationGroupList: [],
    };


    let LoadingMetaDataRes = await constructAndInvokeMetadataCall({...subappinfo,subappName: subappListLoader}, {
      transactionPrimaryObject:{...Tlist, "AvailableMarketLoading": "true"},
      EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    });


    let LoadingMetaData=LoadingMetaDataRes && LoadingMetaDataRes.ApplicationDetail && LoadingMetaDataRes.ApplicationDetail.SubApplicationDetailList

    AvailableMarketList_dataList[iteratorIndex]['SubApplicationDetailList']= LoadingMetaData || []
    // const newDataList = [...AvailableMarketList_dataList]
    // newDataList[iteratorIndex] = {...AvailableMarketList_dataList[iteratorIndex], SubApplicationDetailList: LoadingMetaData}
    propsObj.functions.UpdateState("loading",false, false)
    props.functions.UpdateState('AvailableMarketList_dataList',AvailableMarketList_dataList,true);


  // let {SubApplicationName,AttributeName}=props.component, subapplist=[]


  let pageLevelValues={...props.componentProps.AvailableMarketList_dataList[iteratorIndex],[SubApplicationName]:{[SubApplicationName]: {...AvailableMarketList_dataList[iteratorIndex][SubApplicationName]},...props.componentProps.SublineList}}
  let {subApplication, iteratorSubappList} = props.pageInfo;
  let subappName = []
  let subappNameList = []
  subappNameList = iteratorSubappList
  const newState = setPagemetaDataonStateObj(props.componentState.SubApplicationDetailList, props.componentState)
 let request = await constructTransactionRequestV1(newState,props.componentProps, pageLevelValues,null,true,false,'')
  request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  request.OwnerId = sessionStorage.getItem("OwnerID");
  let removeEmptyData =  props.pageInfo.deleteEmptydataList ? props.pageInfo.deleteEmptydataList : props.pageInfo.dataListName
  let childDatalist = []
  props.pageInfo.dataListName && props.pageInfo.dataListName.forEach(
    (key) =>{
       if(key in request){
        childDatalist[key]=request[key]
      }
    }
  )
  request.Coverage ={...request.Coverage, ...childDatalist}
  let savedData = removeEmptyDataV1(stateObj, request, removeEmptyData);
  request.Coverage ={...request.Coverage, ...savedData}
  // request = {...request, ...savedData}
  if(props.componentProps.UIFlowReducer.quoteID && props.componentProps.UIFlowReducer.quoteID !== ""){
    request['QuoteFullNumber'] = props.componentProps.UIFlowReducer.quoteFullNumber;
  }
  request[SubApplicationName]={};
  request[SubApplicationName] = {...props.componentProps.SublineList}
  request[SubApplicationName][SubApplicationName] = props.componentState.AvailableMarket;
  request[`${SubApplicationName}`]["ProductSelection"]=props.componentProps.AvailableMarket;
  request[`${SubApplicationName}`]["insuredID"]=props.componentProps.UIFlowReducer.insuredID;
  request["SubmissionID"]=props.componentProps.UIFlowReducer.submissionID;
  request["UserRole"]=props.componentProps.propsValue.userInfoReducer.userRole;
  if(props.pageInfo && props.pageInfo.RatingEventAction)
  request["EventAction"]=props.pageInfo.RatingEventAction;
  request["TargetEventName"]=props.pageInfo.RatingEvent;request.EventName=GetCommonEventName();
  request["RequestID"] = props.componentProps.RequestID
  request["ProductNumber"] = ProductNumber;
  request["ProductVerNumber"] = ProductVerNumber;
  const response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
  if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
    triggerToast("Unable to get carriers list");
    console.log("Response Failed");
  }

  const {metadataReducer} = store.getState();

  if("ProcessStatus" in response.data && metadataReducer.ProcessStatus !== response.data.ProcessStatus){
      store.dispatch({type: appActions.actionType.SET_PROCESS_STATUS, payload: response.data.ProcessStatus});
      store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
  }
  if (response.data && response.data.SubmissionStatus)
    await store.dispatch({ type: Action.SET_SUBMISSION_STATUS, payload: response.data.SubmissionStatus });

  let selectedSubline = props.pageInfo.selectedSubline;
  let neededSubapps = []
  Object.keys(selectedSubline).length > 0 && Object.entries(selectedSubline).forEach(([key,value])=>{
      if(key === response.data.SublineName){
        neededSubapps.push(value)
      }
   })
   subappName = [...subappNameList,...neededSubapps]
  // if(response && response.data && response.data.hasOwnProperty("ExternalQuoteNumber")){ store.dispatch({ type: actionType.SET_EXTERNAL_QUOTE_NUMBER, payload: response.data.ExternalQuoteNumber }); }
  // let subappinfo = {
  //   subappName,
  //   objectId,
  //   objectVerId,
  //   state:"CW",
  //   subApplicationGroupList: [],
  // };
  await store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: true })
  response.data.CarrierDisplayName = AvailableMarketList_dataList[iteratorIndex].AvailableMarket.CarrierDisplayName;
  let TlistRes = {AvailableMarket:{...props.componentProps.AvailableMarket,...AvailableMarketList_dataList[iteratorIndex]["AvailableMarket"],"IsReferralRuleApplied": response.data.RuleStatus && response.data.RuleStatus ||"false"},...response.data,ProductSelection:props.componentProps.AvailableMarket}
  let metadataresponse = []
  let responseFromTranslation = {}
  responseFromTranslation=await EntitySubAppTranslationV2({...response.data},props.componentState.pageMetadata.ApplicationDetail);
  TlistRes = {...TlistRes, ...responseFromTranslation}

  metadataresponse = await constructAndInvokeMetadataCall({...subappinfo,subappName: subappName}, {
    transactionPrimaryObject:TlistRes,
    EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
  });


  let metadata=metadataresponse && metadataresponse.ApplicationDetail && metadataresponse.ApplicationDetail.SubApplicationDetailList
  AvailableMarketList_dataList[iteratorIndex] = {...AvailableMarketList_dataList[iteratorIndex],...responseFromTranslation,...response.data}
  AvailableMarketList_dataList[iteratorIndex][`${SubApplicationName}`][`${AttributeName}`]=response.data.Coverage && response.data.Coverage.TotalPremium;
  AvailableMarketList_dataList[iteratorIndex][`feescarrier_dataList`] = response.data.Fee;
  AvailableMarketList_dataList[iteratorIndex][`taxescarrier_dataList`] = response.data.Tax;
  AvailableMarketList_dataList[iteratorIndex]['SubApplicationDetailList']= metadata|| []
  AvailableMarketList_dataList[iteratorIndex]['SubmissionRatingDataID']=  response.data.SubmissionRatingDataID;
  AvailableMarketList_dataList[iteratorIndex]['isEligible']= response.data.RuleStatus && response.data.RuleStatus.toLowerCase();
  AvailableMarketList_dataList[iteratorIndex]['viewbusinessrulespl_dataList']= response.data.RuleInformationList || [];
  if(AvailableMarketList_dataList.length > 1){
  AvailableMarketList_dataList[iteratorIndex][`${SubApplicationName}`]['ApplyAll'] = 'true'
  AvailableMarketList_dataList[iteratorIndex][`${SubApplicationName}`]['ApplyAllDisabled'] = 'false'
  }
  props.pageInfo.dataListName.map((datalist)=> {
    if(datalist in response.data.Coverage)
    AvailableMarketList_dataList[iteratorIndex][`${datalist.toLocaleLowerCase()}_dataList`] =  response.data.Coverage[datalist]
  })

  if(response.data.CarrierDisplayName)
  AvailableMarketList_dataList[iteratorIndex][SubApplicationName].CarrierDisplayName = response.data.CarrierDisplayName && response.data.CarrierDisplayName;
  props.functions.UpdateState('ruleinformationlistpl_dataList',response.data.RuleInformationList,false)
  let temp={}
  temp.ruleinformationlistpl_dataList = response.data.RuleInformationList
  UpdateState("PopUp_ScheduleOfForms",response.data.Coverage,false);
  if(AvailableMarketList_dataList.length > 1){
    AvailableMarketList_dataList.map((data,index) => {
      if (index !== iteratorIndex){
        data[`${SubApplicationName}`]['ApplyAll'] = 'false'
      }
    })
    }
  props.functions.UpdateState('AvailableMarketList_dataList',AvailableMarketList_dataList,false);
}
 catch(err){
  console.log("error in refersh premium package",err);
 }
 finally{
  // viewtoggle(e,propsObj,stateObj,{SubApplicationName})
 }
}




const clearAvailableMarket_Currency = async(value, component, isDynamicTable, datatableRowindex, props)=>{
  try{
  let {component,functions:{UpdateState},componentState, componentProps:{UIFlowReducer:{quoteID}}} = props
    store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: false});
      let subappinfo = {
        subappName: props.pageInfo.subApplication,
        objectId: props.componentProps.productDetails.ProductID,
        objectVerId: props.componentProps.productDetails.ProductVerID,
        state:"CW"
      }
    let obj= await handleInputChangeV2(value,component, props.componentProps, props.componentState,props.functions,subappinfo,undefined, true,props);
    props.componentProps.parentState.UpdateState("AvailableMarket",{},false);
    ResetDataLists(props.pageInfo.dataListName, props.componentProps.parentState.UpdateState)
    props.componentProps.parentState.UpdateState("carriervalidations_dataList",[],false)
    props.componentProps.parentState.UpdateState("AvailableMarketList_dataList",[],true);
    props.componentProps.parentState.UpdateState("clearCarrierSelection",true,false)
    if(props.pageInfo && props.pageInfo.clearDetailsInModifyQuote)
      await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {"quoteNumber":"","quoteID":""} });
    await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {"SubjectivityListRenderFlag":"No"}});
  }
  catch(e){
    console.error(`Error in clearAvailableMarket_Currency ${e}`)
  }
}
export const retainPolicyForms = async(action, value,props,state,component,tpo,e)=>{
  try{
    // if(Object.keys(component.Action).length>0 && component.Action.setMinValueNeeded){
    //   let val = await bumpDwellingCoverageToRCV(props)
    // }
     await saveAvailableMarkets_HomeOwners(props.pageInfo.GetAvailableMarketTargetEventName,props,props.componentState,props.component,props.functions,props,true)
}
  catch(e){
    console.error(`Error in retainPolicyForms ${e}`)
  }
}


const toggleView=(EventName,propsObj,stateObj,component,functions,event)=>{
  try {
    event.target.closest(`.${component.SubApplicationName}__wrapperClass`).classList.toggle("toggleview")
  } catch (error) {
    console.log("error in toggleView",error);
  }
}

const AvailableMarket_toggleView=(EventName,propsObj,stateObj,component,functions,event)=>{
  try {
    event.target.closest(`.${component.SubApplicationName}__wrapperClass`).classList.toggle("AvailableMarket_toggleView")
  } catch (error) {
    console.log("error in AvailableMarket_toggleView",error);
  }
}

const toggleButton = (EventName,propsObj,stateObj,component,functions,event,buttonRef) => {
  // Toggle the classList of the button DOM element
  try {
    event.target.closest(`.${component.SubApplicationName}__wrapperClass`).classList.toggle("AvailableMarket_toggleView")
    buttonRef.current.classList.toggle('toggled');
  } catch (error) {
    console.log("error in AvailableMarket_toggleView",error);
  }


};

const onAdjustCoverage =async (propsObj,stateObj,component,e,childprops)=>{
  try {
    let scroll = document.getElementById("child");
    scroll.scrollIntoView();
    e.preventDefault();
    e.stopPropagation();
    const { functions } = childprops;
    propsObj.parentState.UpdateState("dynamicLoadingMessage", component.Custom1, true)
    let AvailableMarket = propsObj.AvailableMarket ? { ...propsObj.AvailableMarket } : {...stateObj.pageLevelValues.AvailableMarket};
    let Coverage = {}, response = {};
    let AvailableMarketList = stateObj.AvailableMarketList_dataList ? stateObj.AvailableMarketList_dataList : propsObj.AvailableMarketList_dataList;
    if (AvailableMarketList.length > 0) {
      let temp =AvailableMarketList[0];
      Object.entries(temp).forEach(([key, value]) => {
        if (typeof value === 'object') {
          Coverage[key] = JSON.parse(JSON.stringify(value));
        }
      });
      // Coverage={Coverage:propsObj.AvailableMarketList_dataList[0].AvailableMarket.Coverage}
      const { ProductID: objectId, ProductVerID: objectVerId } = propsObj.productDetails;
      let subappinfo = {
        subappName: propsObj.pageInfo["subApplication"],
        objectId,
        objectVerId,
        state: "CW",
        subApplicationGroupList: [],
      };
      AvailableMarket['showAdjustCoverageSection'] = "true"
      await store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: true })
      AvailableMarket['AdjustCoverage'] = "true"
      // stateObj.transactionPrimaryObject.AvailableMarket.AdjustCoverage = true;
      response = await constructAndInvokeMetadataCall(subappinfo, {
        transactionPrimaryObject: { ...stateObj.transactionPrimaryObject,...Coverage, AvailableMarket, flowType: "AdjustCoverage" },
        EffectiveDate: propsObj.value && propsObj.value.EffectiveDate ? propsObj.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
      });
      let tpo = { ...stateObj.transactionPrimaryObject,...Coverage, AvailableMarket, flowType: "AdjustCoverage"}
      propsObj.parentState.UpdateState("dynamicLoadingMessage", '', false);
      propsObj.parentState.UpdateState("AvailableMarket", AvailableMarket, true);
      functions.updateValues({ "pageMetadata": response, "showAdjustCoverageSection": true, "loading": false, transactionPrimaryObject: tpo,pagelevelValues:{...stateObj.pagelevelValues,...Coverage, ...tpo} })
    }
  }
  catch (err) {
    propsObj.parentState.UpdateState("dynamicLoadingMessage", '', true);
    console.log("error in onAdjustCoverage", err);
  }

}
export const getDataListKeys = (data) => {
  try {
    let keyList = []
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        keyList.push(key.replace('_dataList', '').toLowerCase());
      }
    })
    return keyList;
  }
  catch (err) {
    console.log("error in getDataListKeys", err);
  }
}
export const mergeRowdatawithDatalist = (subapplicationList, temp, dataObj) => {
  try {
    subapplicationList.forEach((subapplication) => {
      if (temp.hasOwnProperty(`${subapplication.ApplicationName.toLowerCase()}_dataList`)) {
        let subapplicationName = subapplication.ApplicationName.toLowerCase();
        dataObj[subapplicationName + '_dataList'] = temp[subapplicationName + '_dataList']
        mergeAttributeListwithdata(dataObj[subapplicationName + '_dataList'], subapplication.AttributeDetailList);
      }
      if (subapplication.ChildSubApplicationList.length > 0) {
        mergeRowdatawithDatalist(subapplication.ChildSubApplicationList, temp, dataObj)
      }
    });
    return temp;
  }
  catch (err) {
    console.log("error in mergeRowdatawithDatalist", err);
  }
}

const onAdjustCoverageNavigation= async (EventName,propsObj,stateObj,component,functions,e,props) =>{
  try {
    functions.UpdateState("updatedCoverage", {}, false);
    let transactionObj = {}
    let iteratorSubappList = props.pageInfo.iteratorSubappList || []
    ResetDataLists(props.pageInfo.dataListName, functions.UpdateState)
    propsObj.parentState.UpdateState("dynamicLoadingMessage", component.Custom1, true)
    const { ProductID: objectId, ProductVerID: objectVerId } =
      props.componentProps.productDetails;
    let subappinfo = {
      subappName: props.pageInfo["subApplication"],
      objectId,
      objectVerId,
      state: "CW",
      subApplicationGroupList: [],
    };
    let AvailableMarket = { ...propsObj.AvailableMarket };
    transactionObj = {AvailableMarket}
    if(props.pageInfo.loaderNeeded){
      transactionObj = {...transactionObj,...stateObj.transactionPrimaryObject}
      delete transactionObj.flowType
    await store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: false })
    }
    let response = await constructAndInvokeMetadataCall(subappinfo, {
      transactionPrimaryObject: transactionObj,
      EffectiveDate: propsObj.value && propsObj.value.EffectiveDate ? propsObj.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    });
    functions.UpdateState("pageMetadata", response, false);
    functions.UpdateState("showAdjustCoverageSection", false, false);
    if (propsObj.parentState && propsObj.parentState.UpdateState) {
      AvailableMarket['showAdjustCoverageSection'] = "false"
      propsObj.parentState.UpdateState("AvailableMarket", AvailableMarket, true);
    }
    if(props.pageInfo.loaderNeeded){
    propsObj.parentState.UpdateState("dynamicLoadingMessage",'', true)}

  } catch (error) {
    propsObj.parentState.UpdateState("dynamicLoadingMessage",'', true)
    console.log("onAdjustCoverageNavigation error", error);
  }
}

const reduceArrObj = async (props,datalist,keys) => {
  let newList=[]
  let Obj={};
  let list
  for (let i in datalist) {
      if(datalist[i][`${props.componentProps.SublineList.SublineName}CoverageDetails`]){
          list = datalist[i][`${keys[0]}`][`${keys[1]}`];
          Obj[list] = datalist[i];
      }
      else if(datalist[i][`${keys[0]}`]){
          list = datalist[i][`${keys[0]}`][`${keys[1]}`];
          Obj[list] = datalist[i];
      }
  }
  for (let i in Obj) {
     newList.push(Obj[i]);
    }
    return newList
}

const onRefreshPremium = async (EventName,propsObj,stateObj,component,functions,e,props) =>{
  try {
    let { updatedCoverage } = stateObj
    let request = await constructTransactionRequestV1(stateObj,propsObj, stateObj.pagelevelValues,null,true,false,'')
    let removeEmptyData =  props.pageInfo.deleteEmptydataList ? props.pageInfo.deleteEmptydataList : props.pageInfo.dataListName
    let savedData = removeEmptyDataV1(stateObj, request, removeEmptyData);
    request = {...request, ...savedData}
    updatedCoverage = {...updatedCoverage,...request.Coverage}
    functions.UpdateState("updatedCoverage", updatedCoverage, false);
    propsObj.parentState.UpdateState("updatedCoverage", updatedCoverage, false);
    let dataListName = props.pageInfo.dataListName || []
    dataListName.forEach(
      (key) =>{
        const datalistKey = `${key.toLowerCase()}_dataList`
        if(request.hasOwnProperty(key)){
          propsObj.parentState.UpdateState(datalistKey, request[key], false);
        }
        else{
          propsObj.parentState.UpdateState(datalistKey, [], false);
        }
      }
    )
    propsObj.parentState.UpdateState("dynamicLoadingMessage", component.Custom1, true)
    const { ProductID: objectId, ProductVerID: objectVerId } =
      props.componentProps.productDetails;
    let subappinfo = {
      subappName: props.pageInfo["subApplication"],
      objectId,
      objectVerId,
      state: "CW",
      subApplicationGroupList: [],
    };
    let AvailableMarket = { ...propsObj.AvailableMarket };
    let response = await constructAndInvokeMetadataCall(subappinfo, {
      transactionPrimaryObject: { AvailableMarket },
      EffectiveDate: propsObj.value && propsObj.value.EffectiveDate ? propsObj.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    });
    let carrierList =  []
    propsObj.ProductSelection_RadioValues && propsObj.ProductSelection_RadioValues.SublineList.map(element=>{
      if(element.Subline.SublineName===props.componentProps.AvailableMarket.PolicyForms){
        carrierList = element.CarrierList.map(data=> ({"AvailableMarket":data}))
      }
    });
    let list=[...carrierList,...propsObj.AvailableMarketList_dataList];
    let newCarrierList = await reduceArrObj(props,list,props.pageInfo.dependentKeys)
    propsObj.ProductSelection_RadioValues && propsObj.parentState.UpdateState("AvailableMarketList_dataList",newCarrierList,false);
    functions.UpdateState("pageMetadata", response, false);
    functions.UpdateState("showAdjustCoverageSection", false, false);
    if (propsObj.parentState && propsObj.parentState.UpdateState) {
      AvailableMarket['showAdjustCoverageSection'] = "false"
      propsObj.parentState.UpdateState("AvailableMarket", AvailableMarket, true);
    }
  }
  catch (error) {
    propsObj.parentState.UpdateState("dynamicLoadingMessage", '', true)
    console.log("onRefreshPremium error", error);
  }
}
const saveAddCoverage = async (EventName,propsObj,stateObj,component,functions,e,props) =>{
  try {
    propsObj.UpdateState('loading',true,true);
    let obj = {}, flowBlockKey=props.pageInfo.flowBlockKey ;
    let request = await constructTransactionRequestV1(stateObj,propsObj, stateObj.pagelevelValues,null,true,false,'')
    const { ProductID: objectId, ProductVerID: objectVerId } =
      props.componentProps.productDetails;
    let subappinfo = {
      subappName: props.pageInfo["subApplication"],
      objectId,
      objectVerId,
      state: "CW",
      subApplicationGroupList: [],
    };
    request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request.OwnerId= sessionStorage.getItem("OwnerID");
    if(props.pageInfo.hasOwnProperty("EventMapping")){
        Object.entries(props.pageInfo.EventMapping).forEach(([key, value]) => { request[key]=value });
      }
    request.ID= store.getState().UIFlowReducer.submissionID;
    request.ProductSelection= {...props.componentProps.ProductSelection}
    store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: false })
    const apiResponse = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request)
    if(apiResponse.data.hasOwnProperty('ResponseStatus') && apiResponse.data.ResponseStatus.toLowerCase() == "failed"){
      failureToast(request.TargetEventName + " API is getting Failed")
      console.log("Response Failed");
    }
    if(apiResponse.data[flowBlockKey]){
      obj[`${flowBlockKey}`] = true
      store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: obj})
    }
    let AvailableMarket = { ...propsObj.AvailableMarket };
    let translatedData = await EntitySubAppTranslationV2(apiResponse.data, stateObj.pageMetadata.ApplicationDetail);
    let response = await constructAndInvokeMetadataCall(subappinfo, {
      transactionPrimaryObject: { AvailableMarket,...translatedData },
      EffectiveDate: propsObj.value && propsObj.value.EffectiveDate ? propsObj.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    });
    functions.UpdateState("pageMetadata", response, false);
    functions.UpdateState("showAdjustCoverageSection", false, false);
    if (propsObj.parentState && propsObj.parentState.UpdateState) {
      AvailableMarket['showAdjustCoverageSection'] = "false"
      propsObj.parentState.UpdateState("AvailableMarket", AvailableMarket, true);
    }
    propsObj.UpdateState('loading',false,true)

  }
  catch (error) {
    propsObj.parentState.UpdateState("dynamicLoadingMessage", '', true)
    console.log("saveAddCoverage error", error);
  }
}

export const handleRowLevelInputChange = async (value, component, datatableRowindex, props, key=false) => {
  try {
    let keys = Object.keys(value);
    let attributeName = keys[0];
    let attributeValue = value[attributeName].toString();

    let tablename = component.SubApplicationName.toLowerCase() + "_dataList";
    await props.functions.saveTableData(tablename, attributeName, attributeValue, datatableRowindex);
    key && (component.DefaultValue=attributeValue)
  } catch (error) {
    console.log("handleRowLevelInputChange error", error);
  }
}

const handleRowLevelInputChange_V2 = async (value, component, datatableRowindex, props) => {
  try {
    await handleRowLevelInputChange(value, component, datatableRowindex, props, true)
  } catch (error) {
    console.log("handleRowLevelInputChange error", error);
  }
}

const rowValues = (dataList,component,value) => {
  let RuleOverridenStatus;
  component.SubApplicationAttributeValueList.map(options=>{
    if(dataList.find(val=>val[component.AttributeName]===options.Value)){
      RuleOverridenStatus=options.Value
    }
  })
  return {RuleOverridenStatus}
}

const onChangeRuleStatus = async (value,component, isDynamicTable,datatableRowindex,props) => {
try {
  props.UpdateState('loading',true,true)
  await handleRowLevelInputChange(value, component, props.customTableRowIndex, props)
  let {componentState:{transactionPrimaryObject,pagelevelValues}} = props
  component.DependentComponent.map(str=> {
    delete transactionPrimaryObject[str]
    delete pagelevelValues[str]
  })
  let subappinfo = {
    subApplication:props.componentProps.subApplication || props.parentProps.subappNameList,
    objectId: props.componentProps.objectId,
    objectVerId: props.componentProps.objectVerId,
    subApplicationGroup: props.componentProps.subApplicationGroupList || props.parentProps.subApplicationGroupList
  };
  let values= rowValues(props.dataList,component,value)
  let metadataRes = await componentDidMount_Orchid(props,subappinfo,{...transactionPrimaryObject,...values})
  let Updateobj = {
    transactionPrimaryObject : {...props.componentState.transactionPrimaryObject,...values},
    pageMetadata : metadataRes.pageMetadata,
    loading : false
  }
  props.functions.updateValues(Updateobj)
} catch (error) {
  console.log("onChangeRuleStatus error", error);
}
}

const onChangeRuleStatus_ProductSelection = async (value,component, isDynamicTable,datatableRowindex,props) => {
  try {
    props.UpdateState('loading',true,true)
  await handleRowLevelInputChange(value, component, props.customTableRowIndex, props)
  let {componentState:{transactionPrimaryObject,pagelevelValues}} = props
  component.DependentComponent.map(str=> {
    delete transactionPrimaryObject[str]
    delete pagelevelValues[str]
  })
  let subappinfo = {
    subApplication:props.componentProps.subApplication || props.parentProps.subappNameList,
    objectId: props.componentProps.objectId,
    objectVerId: props.componentProps.objectVerId,
    subApplicationGroup: props.componentProps.subApplicationGroupList || props.parentProps.subApplicationGroupList
  };
  let values= rowValues(props.dataList,component,value)
  transactionPrimaryObject = {...transactionPrimaryObject,AvailableMarket:{...props.componentProps.AvailableMarket,...props.componentProps.AvailableMarketList_dataList[props.componentState.IteratorIndex]["AvailableMarket"]},"AvailableMarket__IsReferralRuleApplied":"false"}
  let metadataRes = await componentDidMount_Orchid(props,subappinfo,{...transactionPrimaryObject,...values,"saveRules":"true"})
  let newList = props.componentProps.AvailableMarketList_dataList;
  newList[props.componentState.IteratorIndex]['SubApplicationDetailList']= metadataRes.pageMetadata.ApplicationDetail.SubApplicationDetailList
  let Updateobj = {
    transactionPrimaryObject : {...transactionPrimaryObject,...values},
    AvailableMarketList_dataList : newList,
    loading : false
  }
    props.functions.updateValues(Updateobj)
  } catch (error) {
    console.log("onChangeRuleStatus_ProductSelection error", error);
  }
}

const removeEmptyData = (state, request) => {
  try{
    let object={}
    if(state.summarySubApp && state.summarySubApp.length > 0){
      state.summarySubApp.map(subapp=> {
          let subappName = state.pageMetadata.ApplicationDetail.SubApplicationEntity[subapp] || subapp;
          if(request[subappName] && request[subappName].length > 0){
            request[subappName] = request[subappName].filter((data, index)=> {
              let {uid, isExpanded, ...rest} = data;
              return Object.keys(rest).length > 0;
            })
            object[subappName]=request[subappName];
          }
        })
        return object
      }
    }
    catch(err){
      console.log("Error in removeEmptyData", err)
    }
}

const removeEmptyDataV1 = (state, request,emptySubappName) => {
  try{
    let object={}
    if(emptySubappName && emptySubappName.length > 0){
      emptySubappName.map((subapp)=> {
          let subappName = subapp;
          if(request[subappName] && request[subappName].length > 0){
            request[subappName]=request[subappName].map((data, index)=> {
              Object.entries(data).forEach(([key,value]) => {
                if(Array.isArray(data[key])){
                  data[key] = data[key].filter((subdata, index) => {
                    if(subdata.isSaved && subdata.isSaved === 'true' || subdata.ID && subdata.ID !== '')
                      return subdata
                    })
                }
              })
              return data
            }).filter((data, index) => {
              if(data.isSaved && data.isSaved === 'true' || data.ID && data.ID !== '')
                return data
              })
            object[subappName]=request[subappName];
          }
        })
        return object
      }
    }
    catch(err){
      console.log("Error in removeEmptyData", err)
    }
}

const onSaveRow = (key,subapplicationName,values,val,ID, props, state) => {
  let dataList = props[key].map((data, index)=> {
   return index === props.DataTableRowIndex ? {...values, isExpanded: "false"} : data
  })
  props.UpdateState(key, dataList,true)
}
const onDeleteRow = (dataListName, values, SubApplnName, props) => {
let dataList = props[dataListName] || props.parentProps[dataListName];
   dataList.forEach((data, index)=> {
     index === props.DataTableRowIndex && dataList.splice(index, 1)
  })
  props.UpdateState(dataListName, dataList,true)
}
const onExpandRow = (key,rowData,isExpanded,props) => {
  let arr = props.parentProps[key].map((data, index)=> {
    return index === props.DataTableRowIndex ? {...data, isExpanded} : data
  })
  props.UpdateState(`${key}`,arr,true)
}

const saveCoverageDetailMPL = async (EventName, propsObj, stateObj, component, functions, e, props) => {
  try {
    props.functions.UpdateState('loading',true,true);
      const currentPageIndex  = props.componentProps.UIFlowReducer.currentPageIndex;;
      let request = {};
      request = await constructTransactionRequestV1(stateObj,propsObj, stateObj.pagelevelValues,null,false,false,'')
      request["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
      request.TargetEventName=propsObj.pageNavigation[currentPageIndex-1].saveeventName;
      request.OwnerId = sessionStorage.getItem("OwnerID");
      request.ID=props.componentProps.UIFlowReducer.insuredID;
      request.EventName=GetCommonEventName();
      const newData=removeEmptyData(stateObj, request);
      const response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request)

      if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "success")
      {
            triggerInfoToast("Coverage details saved successfully.",  { position: Toast.POSITION.TOP_RIGHT})

            if(newData.hasOwnProperty('FL_ApplicantsEmployeeBenefitPlans')  && newData.FL_ApplicantsEmployeeBenefitPlans.length > 0){
              await store.dispatch({
                type: actionType.IS_COVERAGE_SAVE,
                payload: true
            });
            }
      }
      props.functions.UpdateState('loading',false,true);
      return response;
  } catch (error) {
      console.log("saveCoverageDetailMPL error", error);
  }
}

const popup_Rating=async(eventName,propsObj,stateObj,component,functions,e,props)=>props.functions.UpdateState(`${component.AttributeName}__popupStatus`,true,true);

const GetCoveragePremiumInfo = async(state, props)=>{
  try{

    let request={};
    request["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.TargetEventName=props.pageInfo.geteventName;
    request.ID=  props.UIFlowReducer.insuredID;
    request.EventName=GetCommonEventName();
    const response= await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    return response
  }
  catch(err){
    console.log("Error in GetCoveragePremiumInfo", err)
  }
}
const Rating_toggleView_MPL= async(EventName,propsObj,stateObj,component,functions,event)=>{
  try{
    let response =  await GetCoveragePremiumInfo(stateObj, propsObj)
    let UpdatedDatalist = {};
    if(response && response.data && response.data.coverages){ response.data.coverages.forEach((dataList)=>{
      Object.assign(UpdatedDatalist,{"ratingcoveragedetailssummary_dataList": dataList.CoverageList})
    })
  }
    let {IteratorIndex, RatingCreateQuote_dataList} = stateObj
  RatingCreateQuote_dataList[IteratorIndex] =  {...UpdatedDatalist,...RatingCreateQuote_dataList[IteratorIndex]}
    propsObj.parentState.UpdateState("RatingCreateQuote_dataList",RatingCreateQuote_dataList,true);
    toggleView(EventName,propsObj,stateObj,component,functions,event)
  }
  catch(err){
    console.log("Error in Rating_toggleView_MPL", err)
  }
}
const saveCoverageDetailMPL_obj = {
  saveCoverageDetailMPL
}

let constructPagelevelValuesbySubappname=(subAppName,pagelevelValues,request, temp)=>
{
  subAppName.forEach(subapp=>{
    if(pagelevelValues.hasOwnProperty(subapp)){
      // Object.assign(request,{[subapp] : {...pagelevelValues[subapp]}})
      request[subapp] = {...pagelevelValues[subapp]}
    }
  });
  temp && Object.entries(temp).forEach(([key, value])=> {
      request[key] = value.map(val=>{
          let newObj = {}
          subAppName.forEach(subapp=>{
            if(val.hasOwnProperty(subapp))
            newObj = {...newObj, [subapp]: val[subapp]}
          })
          return newObj;
    })
  })
}

const underWriterRequest=(props,stateObj,propsObj,request, TargetEventName)=>
{
  request["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
  request.TargetEventName=TargetEventName
  request.OwnerId = sessionStorage.getItem("OwnerID");
  const reduxState = store.getState();
  let routeInfo = reduxState.metadataReducer.route || '';
  let subAppName = {}
  if(routeInfo==='Quote'){
    let {subApplication
    } = props.componentProps.UIFlowReducer.currentPageInfo.template[0];
    subAppName = subApplication;
    request.ID = store.getState().UIFlowReducer.quoteID;
  }
  else{
    subAppName=propsObj.popupDetail.popupContent.subApplicationList
  request.ID=store.getState().UIFlowReducer.insuredID;
  }
  request.FlowType = routeInfo;
  request.EventName=GetCommonEventName();
  let temp = {UnderWriterDetail: stateObj.underwritterworksheet_datalist}
  request.RatingKeys=stateObj.pagelevelValues.RatingKeys
  constructPagelevelValuesbySubappname(subAppName,stateObj.pagelevelValues,request, temp)
  return request
}



let getunderwriterworksheet_calculatepremium_quote=async(eventName,propsObj,stateObj,component,functions,e,props)=>
  {
    try
    {
      let UpdateState = props.functions.UpdateState;
      UpdateState('loading',true,true);
      let request={}
      let {geteventName} = props.componentProps.UIFlowReducer.currentPageInfo.template[0];
      underWriterRequest(props,stateObj,props.componentProps,request,geteventName)
      const response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request)
        let TranslatedResponse = await getEntitySubAppTranslation(props,stateObj.pageMetadata,response.data.UnderWriterDetail);
      let underwritterworksheet_datalist =  TranslatedResponse ? [{...TranslatedResponse}] : [];
      UpdateState("underwritterworksheet_datalist",underwritterworksheet_datalist, false )
      UpdateState("pagelevelValues",{...response.data}, false )
      UpdateState("transactionPrimaryObject",{...response.data}, false )
      UpdateState('loading',false,true);
   }
   catch (error)
  {
    console.log("UW_ModifierDetails_MPL error", error);
  }
  }


  let getunderwriterworksheet_rating=async(value,component,isDynamicTable,datatableRowindex,props)=>
  {
    try
    {
     let request={}
    let routeInfo = store.getState().metadataReducer.route ? store.getState().metadataReducer.route:''
    let pageLevelValues = {}
    handleInputChangeV2_MPL(value, component,props,props.componentState, isDynamicTable, datatableRowindex, props)
    if(routeInfo==='Quote'){
      let {eventName
      } = props.componentProps.UIFlowReducer.currentPageInfo.template[0];
      request.TargetEventName=eventName.fetchRates;
      pageLevelValues = props.componentState.pagelevelValues
    }
    else{
      request.TargetEventName=props.pageInfo.eventName.ftechRates;
      pageLevelValues = props.componentProps.pagelevelValues
    }
    request["ServiceRequestDetail"]= getServiceRequestDetail();
    const reduxState = store.getState();
    request.EventName=GetCommonEventName();
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.ID=reduxState.UIFlowReducer.insuredID;
    Object.assign(request, value)

    let updateState = props.functions.UpdateState
    let subApplicationName= component.SubApplicationName
    request.RatingKeys = pageLevelValues.RatingKeys;
    const response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request)
    let underwritterworksheet_datalist = props.componentState.underwritterworksheet_datalist
    let underwritterworksheet_datalist_iteratorIndex = underwritterworksheet_datalist[props.componentState.IteratorIndex]
    if(underwritterworksheet_datalist && underwritterworksheet_datalist_iteratorIndex && underwritterworksheet_datalist_iteratorIndex.hasOwnProperty(subApplicationName)){
      Object.assign(underwritterworksheet_datalist_iteratorIndex[subApplicationName],{...underwritterworksheet_datalist_iteratorIndex[subApplicationName],...response.data})
      await DisplayCoverages(props,props.componentProps);
      updateState("underwritterworksheet_datalist", underwritterworksheet_datalist, true);
    }
    else{
     if(pageLevelValues.hasOwnProperty(subApplicationName))
    {
      Object.assign(pageLevelValues[subApplicationName],{...pageLevelValues[subApplicationName],...response.data})
      updateState("pagelevelValues", pageLevelValues, true);
      const {  ProductID : objectId , ProductVerID : objectVerId } = props.componentProps.productDetails;
      let subApplicationList = props.componentProps.pageInfo.subApplication
      let subappInfo =  { subappName: subApplicationList, objectId, objectVerId }
      let metadataResponse = await constructAndInvokeMetadataCall(subappInfo,{transactionPrimaryObject: pageLevelValues})
      props.functions.UpdateState("pageMetadata", metadataResponse,true)
    }
    if(routeInfo==='Submission'){
      const {  ProductID : objectId , ProductVerID : objectVerId } = props.componentProps.productDetails;
      let subApplicationList = props.componentProps.popupDetail.popupContent.subApplicationList
      let subappInfo =  { subappName: subApplicationList, objectId, objectVerId }
      let metadataResponse = await constructAndInvokeMetadataCall(subappInfo,{transactionPrimaryObject: pageLevelValues})
      props.functions.UpdateStateContent("pageMetadata", metadataResponse,true)
    }
    // setLoader('loading',false,true);
  }
   }
     catch (error)
    {
      console.log("UW_ModifierDetails_MPL error", error);
    }
    }

    let getunderwriterworksheet_calculatepremium=async(eventName,propsObj,stateObj,component,functions,e,props)=>
    {
     try
     {
      props.componentProps.parentState.UpdateState('loading',true,true);
      let request={}
      underWriterRequest(props,props.componentProps,props.state,request,props.pageInfo.geteventName)
      const response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request)
      underwritertranslateupdate(props,stateObj.pageMetadata,response);
      props.componentProps.parentState.UpdateState('loading',false,true);
     }
     catch (error)
    {
      console.log("UW_ModifierDetails_MPL error", error);
    }
    }

    let underwriterworksheet_save=async(eventName,propsObj,stateObj,component,functions,e,props)=>
    {
     try
     {
       e.preventDefault();
      propsObj.parentState.UpdateState('loading',true,true);
      let request={}
      underWriterRequest(props,stateObj,propsObj,request, props.pageInfo.saveeventName)
     const response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request)
     if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "success")
  {
    triggerInfoToast("Changes saved successfully.", { position: Toast.POSITION.TOP_RIGHT})
  }
     propsObj.parentState.UpdateState(`${propsObj.name}__popupStatus`,false,true);
     // propsObj.parentState.UpdateState(`RatingCreateQuote_dataList`,response.data.Coverages,false); Added for testing purpose
     propsObj.parentState.UpdateState('loading',false,true);
     return response
     }
     catch (error)
    {
      console.log("UW_ModifierDetails_MPL error", error);
    }
    }

    let underwriterworksheetQuote_save=async(eventName,propsObj,stateObj,component,functions,e,props)=>
    {
     try
     {
       e.preventDefault();
      let UpdateState = props.functions.UpdateState
      UpdateState('loading',true,true);
      let {saveeventName, subApplication, objectId,objectVerId
      } = props.componentProps.UIFlowReducer.currentPageInfo.template[0];
      let request={}
      underWriterRequest(props,stateObj,propsObj,request, saveeventName)
     const response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request)
     if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "success")
  {
    triggerInfoToast("Changes saved successfully.", { position: Toast.POSITION.TOP_RIGHT})
    let newProps = {
      objectId : objectId,
      objectVerId : objectVerId,
      subApplication : subApplication,
      subappName: subApplication
    }
   let response = await componentDidMount_UW_WorksheetMPL(stateObj,newProps,UpdateState);
   const keyValuePairs = Object.entries(response)
   await keyValuePairs.forEach((value,i)=>{
     let updateFlag = (keyValuePairs.length)-1===i
      value[1] && UpdateState(value[0],value[1],updateFlag)
   })
  }
    UpdateState('loading',false,true);
     return response
     }
     catch (error)
    {
      console.log("UW_ModifierDetails_MPL error", error);
    }
    }



    const DisplayCoverages= async(props, dataList,component)=>{
      try{
          let subappinfo = {}
        let routeInfo = store.getState().metadataReducer.route ? store.getState().metadataReducer.route:''
        if(routeInfo==='Quote'){
          let {objectId,objectVerId, iteratorSubappList
       } = props.componentProps.UIFlowReducer.currentPageInfo.template[0];
          subappinfo = {
            objectId : objectId,
            objectVerId : objectVerId,
            subApplication : iteratorSubappList,
            subappName: iteratorSubappList
          }
        }
        else{
          let subappName = []
          let subappNameList = props.pageInfo.iteratorSubappList;
          let selectedSubline = props.pageInfo.selectedSubline ? props.pageInfo.selectedSubline : {};
          let choosedsubline = props.componentState && props.componentState.SublineName ? props.componentState.SublineName : ''
          let neededSubapps = []
          Object.keys(selectedSubline).length > 0 && Object.entries(selectedSubline).forEach(([key,value])=>{
            if(key === choosedsubline){
               neededSubapps.push(value)
            }
          })
          subappName = [...subappNameList,...neededSubapps]
          subappinfo = {subappName,objectId: props.componentProps.objectId,objectVerId: props.componentProps.objectVerId};
        }
        // let dataListSubAppName = props.componentState.parentComponent.SubApplicationName
        // let dataListKey = props.componentState.parentComponent.ResponseKey
        let dataListKey = props && props.componentState && props.componentState.ResponseKey;
        let IteratordataList = (dataList && dataList[dataListKey]) || props.componentState[dataListKey] || props.componentProps[dataListKey]
        deleteDependentAttribute(component,props.componentState.transactionPrimaryObject,IteratordataList[props.componentState.IteratorIndex]);
        let transactionPrimaryObject = {...props.componentState.transactionPrimaryObject,
          // ...props.componentState[dataListSubAppName],
          ...IteratordataList[props.componentState.IteratorIndex]}
      let metadataResponse = await constructAndInvokeMetadataCall(subappinfo,{transactionPrimaryObject});
      // props.componentState[dataListKey][props.componentState.IteratorIndex].SubApplicationDetailList = metadataResponse.ApplicationDetail.SubApplicationDetailList
      IteratordataList[props.componentState.IteratorIndex].SubApplicationDetailList = metadataResponse.ApplicationDetail.SubApplicationDetailList
        //state update for component rerender
        if(!("currentStateUpdate" in props.pageInfo) || props.pageInfo.currentStateUpdate === 'Y'){
        props.componentProps.functions && props.componentProps.functions.UpdateState ? props.componentProps.functions.UpdateState(dataListKey, props.componentState[dataListKey], true) : props.functions.UpdateState(dataListKey, props.componentState[dataListKey], true)
        }
        return {[dataListKey]: IteratordataList}
      }
      catch (error)
      {
        console.log("Error in DisplayCoverages", error);
      }
    }


const saveWorkSheet_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    const response = await worksheetRequestConstruct(EventName,stateObj,propsObj,props,component,component.DefaultValue);
    if(!(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "error")){
      defaultToast("Premium saved successfully");
    }
  }
  catch(e){
    console.log("Error in saveWorkSheet_HomeOwners",e);
  }
}
const getWorkSheetRate_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    const response = await worksheetRequestConstruct(EventName,stateObj,propsObj,props,component,'N');
    if(!(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "error")){
      defaultToast("Premium updated successfully");
    }
  }
  catch(e){
    console.log("error in getWorkSheetRate_HomeOwners",e);
  }
}
const worksheetRequestConstruct=async(EventName,stateObj,propsObj,props,component,Flag)=>{
  let {functions:{UpdateState}}=props;
  UpdateState("loading",true,true);
  try{
    stateObj["pagelevelValues"][component.SubApplicationName]||
    (stateObj["pagelevelValues"][component.SubApplicationName]={})
    let request=await constructTransactionRequestV1(stateObj, propsObj, stateObj.pagelevelValues,  false, false, false, false);
    request["ServiceRequestDetail"]=await getServiceRequestDetail();
    request[component.SubApplicationName][component.AttributeName] = Flag;
    request.ID=store.getState().UIFlowReducer.quoteID;
    request.TargetEventName=EventName;
    request.EventName=GetCommonEventName();
    request.OwnerId=sessionStorage.getItem("OwnerID");
    const response=await AxiosInstance.post("core/invokeWorkflowEvent/V2", request);
    let responseFromTranslation=await EntitySubAppTranslationV2({...response.data},{...stateObj.pageMetadata.ApplicationDetail});
    let temp={};
    temp.summarytaxdetails_dataList=response.data.Tax;
    temp.summaryfeedetails_dataList=response.data.Fee;
    let dataList={}
    mergeRowdatawithDatalist(stateObj.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,dataList);
    props.functions.updateValues({...dataList});
    UpdateState("pagelevelValues",{...stateObj.pageLevelValues,...response.data,...responseFromTranslation},true)
    return response;
  }
  catch(e){
    console.log("error in worksheetRequestConstruct",e)
  }
  finally{
    UpdateState("loading",false,true);
  }
}
const saveDiligentEffort=async(eventName,state,props,component)=>{
  try{
    component.UpdateState("loading",true,true)
    let metadataResponse;
    let QuoteId=store.getState().UIFlowReducer.quoteID
    let getQuoteResponse = await getQuoteData(props,props.pageInfo.CreateQuoteTargetEventName,component.UpdateState)
    let diligentId = getQuoteResponse.data.DiligentEffort ? getQuoteResponse.data.DiligentEffort.ID : ''
    let request = await constructTransactionRequestV1(state,
      props,
      {...state.pagelevelValues},
      false,
      false,
      false,
      false
    );
    request["ServiceRequestDetail"]= getServiceRequestDetail();
    request.EventName=GetCommonEventName();
    request.TargetEventName=props.pageInfo.saveEventName;
    request.QuoteId=QuoteId
    request.PolicyId="";
    request.PARENTID=QuoteId
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.DiligentEffort.ID=diligentId
    request.ROOTID=QuoteId
    AxiosInstance.post(
      "/core/invokeWorkflowEvent/V2",
      request
    ).then(async (response) => {
      if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
        throw new Error('Failed to save the Diligent information ')
    }
    else{
      successToast("Diligent Effort Information Saved Successfully");
    }
    let secondParam ={
      subApplication:state.subappNameList,
      objectId: props.objectId,
      objectVerId: props.objectVerId,
    }
    if(response.data.hasOwnProperty("QuoteInformationHeader")){
      const quoteDetailResponse = await quoteDetailInformation(state, props, null, null, component.UpdateState,secondParam)
      const keyValuePairs = Object.entries(quoteDetailResponse)
      await keyValuePairs.forEach((value,i)=>{
        let updateFlag = (keyValuePairs.length)-1===i
        value[1] && component.UpdateState(value[0],value[1],updateFlag)
      })
    }
    });
    component.UpdateState("loading",false,true)
  }
  catch(error){
    console.log("Error while saving the Diligent information",error);
    triggerToast(error.message);
    component.UpdateState("loading",false,true)
  }
}

const saveDiligentEffortV2 = async (eventName, componentProps, componentState, component, functions) => {
  try {
    functions.UpdateState("loading", true, true)
    let request = {}
    let {UIFlowReducer} = store.getState()
    let QuoteId = UIFlowReducer.quoteID
    request["ServiceRequestDetail"] = getServiceRequestDetail()
    request.EventName = GetCommonEventName()
    request.TargetEventName = eventName
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.ROOTID = QuoteId
    request.PARENTID = QuoteId
    request.DiligentEffort = componentState.pagelevelValues.DiligentEffortInformation
    request.DiligentEffort.ID = componentState.pagelevelValues.DiligentEffortInformation.ID || ''
    await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request)
    .then(async (response) => {
      if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed" && componentProps.pageInfo.diligentErrorMessage) {
        throw new Error(componentProps.pageInfo.diligentErrorMessage)
      } else {
        functions.UpdateState("pagelevelValues",{...componentState.pagelevelValues,DiligentEffortInformation : response.data.DiligentEffort},false)
        componentProps.pageInfo.diligentSuccessMessage && successToast(componentProps.pageInfo.diligentSuccessMessage)
      }
    })
    functions.UpdateState("loading", false, true)
    } catch(error) {
      console.log("Error while saving the Diligent information", error)
      triggerToast(error.message)
      functions.UpdateState("loading", false, true)
  }
}

const InvokePDFGeneration = async(EventName,propsObj,stateObj,component,functions,e,props,MPL_Submission=false) =>{
     try {
     functions['UpdateState']('loading',true,true)
     let newRequest= {}
     newRequest["ServiceRequestDetail"] = getServiceRequestDetail();
     newRequest.EventName = EventName;
     newRequest.OwnerId = sessionStorage.getItem("OwnerID");
     newRequest.IsPreview ="No";
     newRequest.DocumentType = "Underwriter Worksheet Document";
     newRequest.SaveDocument = "No";
     let redux = store.getState();
     if(MPL_Submission){
       newRequest.TransactionFlowType=redux.metadataReducer.route;
       newRequest.ID=redux.UIFlowReducer.submissionData.ID;
       newRequest.EffectiveDate = redux.metadataReducer.policyDates.PolicyEffectiveDate;
       newRequest.ExpirationDate = redux.metadataReducer.policyDates.PolicyExpirationDate;
       newRequest.LastRateLookUpDate = redux.metadataReducer.policyDates.RateLookupDate;
      }else{
       newRequest.ID = redux.UIFlowReducer.quoteID;
       newRequest.EffectiveDate = redux.UIFlowReducer.policyEffectiveDate;
       newRequest.ExpirationDate = redux.UIFlowReducer.policyExpirationDate;
       newRequest.LastRateLookUpDate = redux.UIFlowReducer.rateLookupDate;
      }
     newRequest.ProductVerNumber = redux.UIFlowReducer.productDetails.ProductVerNumber;
     let res = await AxiosInstance.post("core/invokeWorkflowEvent/V4", newRequest);
     if(Array.isArray(res.data.GeneratedDocuments) && (res.data.GeneratedDocuments[0]) && (res.data.GeneratedDocuments[0].SolartisDocumentUrl)){
     window.open(res.data.GeneratedDocuments[0].SolartisDocumentUrl, '_blank');
     }
     else{
     triggerToast("Failed to generate the PDF");
     }
     } catch (err) {
      functions['UpdateState']('loading',false,true)
     triggerToast(err.message);
     } finally{
      functions['UpdateState']('loading',false,true)
     }
    }

  const displayDocument=(response)=>{
    if(Array.isArray(response.GeneratedDocuments) && (response.GeneratedDocuments[0]) && (response.GeneratedDocuments[0].SolartisDocumentUrl)){
      window.open(response.GeneratedDocuments[0].SolartisDocumentUrl, '_blank');
      }
      else{
      triggerToast("Failed to generate the PDF");
      }

  }
  const componentDidMount_ColorPalette= async(state, props, UpdateState)=>{
    UpdateState("loading",true,true);
    const res = await componentDidMount_Orchid(state, props)
    const redux = store.getState();
    let response = redux.ownerResources && redux.ownerResources.ColorPalette && JSON.parse(redux.ownerResources.ColorPalette)

    let styleCSS = ""
    if(response && response.ColorPalette){
    let backgroundColor = response.ColorPalette.Background
    let backgroundClassNames = response.ClassName.Background
    Object.keys(backgroundColor).map(el => {
      styleCSS += `.${backgroundClassNames[el]} {
                background-color: ${backgroundColor[el]} !important;
              }`;
    });
    let fontColor = response.ColorPalette.Font
    let fontClassNames = response.ClassName.Font
    Object.keys(fontColor).map(el => {
      styleCSS += `.${fontClassNames[el]} {
                    color: ${fontColor[el]} !important;
                  }`;
    });

    applyDynamicStyles('dynamic-styles', styleCSS);
    return {
      "loading": false,
      ...res,
      "pagelevelValues":{
        "ColorPalette":{
          ...response.ColorPalette
        }
      }
    }
  }else{
    return {
      "loading":false
    }
  }
  }
  const applyDynamicStyles = (elementId, dynamicCSS) => {
    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.id = elementId;
    styleElement.appendChild(document.createTextNode(dynamicCSS));
    document.head.appendChild(styleElement);
    };

  const componentDidUpdate_ColorPalette =(state, props, prevState, prevProps, UpdateState)=>{
    if(props.isColorPaletteOpen !==prevProps.isColorPaletteOpen){
    return {
    colorpalette_showDialog:!prevState.colorpalette_showDialog
    }
  }
  }

  const handleClose_ColorPalette=(state, props)=>{
    let subAppName = props.subAppInfo.SubApplicationDetail.ApplicationName.toLowerCase();
    props.UpdateState("transactionPrimaryObject",{},false)
    props.UpdateState(subAppName+"_showDialog",false,true)
  }


  export const handleInputChangeV2_ColorPalette = async (value,component,isDynamicTable,datatableRowindex,props,state,functions, DynamicProps) => {
    try {
          // let {updateStateValues=false} = functions;
          let keys = Object.keys(value);
          let attributeName = keys[0];
          let attributeValue = value[attributeName]
          if (value[attributeName].constructor !== Array) {
            attributeValue = value[attributeName].toString();
          }
          let subappName = component.SubApplicationName;
          let pagelevelValues = Object.assign({}, props.pagelevelValues);
          let transactionPrimaryObject = Object.assign({}, props.transactionPrimaryObject);
          let values = Object.assign({}, props.values);
          if (!pagelevelValues[subappName]) {
            pagelevelValues[subappName] = {};
          }
          if (!transactionPrimaryObject[subappName]) {
            transactionPrimaryObject[subappName] = {};
          }
          pagelevelValues[subappName][attributeName] = attributeValue;
          transactionPrimaryObject[subappName][attributeName] = attributeValue;
          values[attributeName] = attributeValue;
          props.functions.updateValues && props.functions.updateValues({ pagelevelValues, transactionPrimaryObject, values })

    } catch (error) {
      console.log("Error in handleInputChangeV2_ColorPalette", error);
    }
  };

const onClickApply = (request,props,e,state) =>{
  try {
    const redux = store.getState();
    let subAppName = props.subAppInfo.SubApplicationDetail.ApplicationName.toLowerCase();
    let response = redux.ownerResources && redux.ownerResources.ColorPalette && JSON.parse(redux.ownerResources.ColorPalette);

    let styleCSS = "";
    if (response && response.ColorPalette) {
      let backgroundColor = state.ColorPalette.Background;
      let backgroundClassNames = response.ClassName.Background;

      backgroundColor && Object.keys(backgroundColor).map(el => {
        styleCSS += `.${backgroundClassNames[el]} {
              background-color: ${backgroundColor[el]} !important;
            }`;
      });

      let fontColor = state.ColorPalette.Font;
      let fontClassNames = response.ClassName.Font;

      fontColor && Object.keys(fontColor).map(el => {
        styleCSS += `.${fontClassNames[el]} {
                  color: ${fontColor[el]} !important;
                }`;
      });

      applyDynamicStyles('dynamic-styles', styleCSS);

      props.UpdateState(subAppName + "_showDialog", false, false);
      let updatedColorPalette = {
        ColorPalette: {
          ...props.pagelevelValues.ColorPalette,
          Background: {
            ...props.pagelevelValues.ColorPalette.Background,
            ...backgroundColor
          },
          Font: {
            ...props.pagelevelValues.ColorPalette.Font,
            ...fontColor
          }
        },
        ClassName: {
          ...response.ClassName,
          Background: {
            ...response.ClassName.Background,
          },
          Font: {
            ...response.ClassName.Font,
          }
        }
      };
      props.UpdateState("pagelevelValues", updatedColorPalette, false);
      request.DomainName = window.location.hostname;
      request.ParameterValue = JSON.stringify(updatedColorPalette)
      props.UpdateState("transactionPrimaryObject", {}, false);

    } else {
      props.UpdateState(subAppName + "_showDialog", false, false);
    }
      return request
  } catch (error) {
    console.log("Error in onClickApply", error);
  }
}

const referandOfferQuote = async(eventName,props,state,component,functions,e,prop,targetEventName)=>{
  try{
    functions.UpdateState("loading",true,true)
    let{UpdateState}=functions
    let request = {}
    let {objectId,objectVerId} = props.pageInfo;
    let {transactionPrimaryObject} = state
    request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request.ServiceRequestDetail.Lob=props.productDetails.LOBName
    request.ServiceRequestDetail.UserRole=store.getState().userInfoReducer.Role
    request.OwnerId = sessionStorage.getItem("OwnerID");
    Object.assign(request, props.productDetails)
    request.EventName= GetCommonEventName();
    request.TargetEventName = targetEventName
    request.ID=store.getState().UIFlowReducer.quoteID;
    AxiosInstance.post(
      "/core/invokeWorkflowEvent/V2",
      request
    ).then(async (response) => {
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() ==="failed" && response.data.hasOwnProperty("Message")){
      triggerToast(response.data.ErrorMessage[0].userInfo,'Carrier Error : ',functions.UpdateState)
      return
    }
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
      triggerToast('Exemption in Offer Quote')
      console.log("Response Failed");
    }
    if(response.data.hasOwnProperty('ErrorMessage')){
      transactionPrimaryObject["QuoteDetail__DisplayErrorMessage"]="true"
    }else{
      transactionPrimaryObject["QuoteDetail__DisplayErrorMessage"]="false"
      transactionPrimaryObject["quoteStatus"] = response.data.QuoteStatus
        let quoteStatus = response.data.QuoteStatus
      store.dispatch({
        type: actionType.SET_QUOTE_STATUS,
        payload: {
          quoteStatus,
        }
      });
    }
    const UIFlowReducer = store.getState().UIFlowReducer
      props = {...props,UIFlowReducer}
      let newProps ={
        subApplication : props.pageInfo.subApplication,
        objectId : objectId,
        objectVerId : objectVerId
      }
      state.transactionPrimaryObject = {...transactionPrimaryObject,...response.data}
      const quoteDetailResponse = await quoteDetailInformation(state, props, null, null, UpdateState,newProps)
      const keyValuePairs = Object.entries(quoteDetailResponse)
      await keyValuePairs.forEach((value,i)=>{
        let updateFlag = (keyValuePairs.length)-1===i
        value[1] && UpdateState(value[0],value[1],updateFlag)
      })
    });
    }
    catch(error){
      console.log("Error in OfferQuote",error);
    }
}

const TriggerOfferQuote=async(eventName,props,state,component,functions,e,prop)=>{
  let targetEventName = props.pageInfo.TargetEventName
  referandOfferQuote(eventName,props,state,component,functions,e,prop,targetEventName)
}
const getDocument_forms=async(props)=>{
  try{
  props.UpdateState("loading",true,true);
  let {configurationRootPath} = props
  let relativePath=props.rowData.DocumentURL || props.rowData.DocumentUrl;
  let response = await generatePDF(`${configurationRootPath}${relativePath}`,props.rowData);
  if (response && response.data.hasOwnProperty("ResponseStatus") && response.data.ResponseStatus.toLowerCase() === 'failed') {
    triggerToast('Error in GetDocument')
  } else {
    const file = response.data
    //Support for IE Browser
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, "Myfile.pdf");
    }
    // Support for Chrome & Firefox version
    else if (navigator.userAgent.indexOf('Chrome') != -1 || navigator.userAgent.indexOf('Firefox') != -1) {
      const w = window.open(file, '_blank');
      w && w.focus();
    } else {
      // Safari & Opera iOS
      window.location.href = file;
    }
}
props.UpdateState("loading",false,true);
}
catch {
triggerToast('Error in GetDocument')
}
}
const generatePDF = async (filePath,RowData) => {
try {
  let {FormName,FormEdition,FormNumber}=RowData;
  let xmlRequest = model_PDFGenerateRequest
  xmlRequest = xmlRequest
  .replace("{USER_NAME}", localStorage.getItem("userName"))
  .replace("{FORM_LOCATION}", filePath)
  .replace("{FormName}",FormName)
  .replace("{FormNumber}",FormNumber)
  .replace("{FormEdition}",FormEdition)
  let request = {}
  request.xmlRequest = xmlRequest
  let response = await AxiosInstance.post('core/generatePDF', request)
  return response
  } catch (error) {
    console.log('PDF Conversion Error', error)
   }
}

const getDocument_quote = (props)=>{
  let documentURL = props.rowData.DocumentURL || props.rowData.DocumentUrl;
  window.open(documentURL, '_blank')
}

const getDocument_Attachment = (props)=>{
  let rootPath = props.rowData.AttachmentUrl
  let filename = props.rowData.AttachmentFileName;
  downloadFile(filename, rootPath);
}

const ResetDataLists = (dataListName=[], UpdateState, parentUpdate) => {
  dataListName.forEach((key, i)=> {
      UpdateState(`${key.toLowerCase()}_dataList`,[], false);
      parentUpdate && parentUpdate(`${key.toLowerCase()}_dataList`,[], false);
  })
}

const getDataLists = (dataListName=[] ,source={}) => {
  let childDatalist={};
  dataListName.forEach(
      (key) =>{
         if(`${key.toLowerCase()}_dataList` in source){
          childDatalist[key]=source[`${key.toLowerCase()}_dataList`]
        }
      }
    )
    return childDatalist;
}

const componentDidMount_ProductSelection_RateIndication = async (state, props, transactionPrimaryObject_arg= {}) =>{
  console.log("props.UIFlowReducer.quoteID",props.UIFlowReducer.quoteID);
  let {UpdateState}=props.parentState;
  try{
  UpdateState("loading",true,true);
  let { pagelevelValues } = state
  let availableMarketsResponse = {}
  let quoteValues={};
  let commonState = store.getState()
  let submissionValues = {}
  let returnValues={};
  let response = {};
  let tpo = {}
  let getRatingDataResponse = {};
  let isPolicyformExist = false;
  let locationResponse = {}
if(!props.UIFlowReducer.quoteID && props.UIFlowReducer.insuredID){
  [response, getRatingDataResponse, locationResponse] = (await Promise.allSettled([getSubmission_RateIndication(props,state), getRatingData(props,response), getLocationList_Verdant(props)])).map((response)=>response.status == 'fulfilled' && response.hasOwnProperty('value') ? response.value : {})
  // response=await getSubmission_RateIndication(props,state)
  // getRatingDataResponse= await getRatingData(props,response)
  response.data= getRatingDataResponse && getRatingDataResponse.data ? {...response.data,...getRatingDataResponse.data} : {...response.data}
  //await store.dispatch({type:actionType.SET_SUBMISSIONID,payload: response.data.SubmissionID});
  tpo[props.pageInfo.name] = response.data.ProductSelection
  }
  // await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
  if((props.UIFlowReducer && props.UIFlowReducer.quoteID) || ((response.data && response.data.SublineName && response.data.SublineName!=="") &&
   (response.data && response.data.ProductSelection && response.data.ProductSelection.DwellingCoverage && response.data.ProductSelection.DwellingCoverage!==""))){
    if(!response.data){
      UpdateState("dynamicLoadingMessage",'',false);
      response = await getQuoteData(props, props.GetQuoteTargetEventName,UpdateState);
      await store.dispatch({type: actionType.SET_INSUREDADDRESS, payload: response.data.InsuredAddress});
      [submissionValues, locationResponse] = (await Promise.allSettled([getSubmission_RateIndication(props,state), getLocationList_Verdant(props)])).map((response)=>response.status == 'fulfilled' && response.hasOwnProperty('value') ? response.value : {})
}  

      availableMarketsResponse =  await getAvailableMarketsData(state, props, response);
      isPolicyformExist =  availableMarketsResponse && availableMarketsResponse.SublineList && availableMarketsResponse.SublineList.some(elem => elem.Subline.SublineName === response.data.SublineName);
      if(isPolicyformExist){
 //await store.dispatch({type:actionType.SET_SUBMISSIONID,payload: response.data.SubmissionID});
    await store.dispatch({type: actionType.SET_INSUREDID, payload: response.data.InsuredID});

    if(availableMarketsResponse && availableMarketsResponse.SublineList && Object.keys(availableMarketsResponse.SublineList).length > 0){
       UpdateState("dynamicLoadingMessage",props.pageInfo.LoaderMessage || '',true);
    }
    tpo["AvailableMarket__PolicyForms"] = response.data.SublineName;
    tpo["ProductSelection__PolicyForms"] = response.data.SublineName;
    let ProductSelection = response.data.ProductSelection;
    ProductSelection["LocationFullAddress"] = (response.data.Location && Array.isArray(response.data.Location)) ? response.data.Location[0].ID: response.data.Location.ID;
    pagelevelValues["ProductSelection"] = ProductSelection//iterate subapp array
    tpo["ProductSelection"]=  ProductSelection;
    let {subApplication,objectId,objectVerId}=props;
    let subAppInfo={
      subappName: subApplication,
      objectId,
      objectVerId
    }
    // const pageMetadata=await constructAndInvokeMetadataCall(subAppInfo,{transactionPrimaryObject:{...response.data, "policyFormDisplay": "true"}});
    // pagelevelValues=await constructTransactionRequestV1({pageMetadata},props,{...response.data},false,false,false,false);
    quoteValues.pagevalues = {...ProductSelection}
    quoteValues.ProductSelection_RadioValues = availableMarketsResponse
    // quoteValues.pagelevelValues = cloneDeep({...pagelevelValues,productSelection})
    quoteValues.pagelevelValues = {ProductSelection}
    quoteValues.transactionPrimaryObject=cloneDeep({...tpo})
    let list;
    let value={};
    let carrierList;
    if(availableMarketsResponse.SublineList && availableMarketsResponse.SublineList.length>0){
      availableMarketsResponse.SublineList.forEach((ele) => {
        if (ele.Subline.SublineName === response.data.SublineName) {
          list = ele.Subline;
          value.Carriers = ele.Subline.Carriers;
          value.PolicyForms = ele.Subline.SublineName;
          let SelectedPlanName = "";
          if ("SelectedPlanName" in response.data && response.data.SelectedPlanName) {
            store.dispatch({type: actionType.SET_SELECTED_PLAN, payload: response.data.SelectedPlanName});
          }else if("PlanName" in response.data && response.data.PlanName) {
            store.dispatch({type: actionType.SET_SELECTED_PLAN, payload: response.data.PlanName});
          }

          if(props.pageInfo && "activeConfiguration" in props.pageInfo && props.pageInfo.activeConfiguration==='package'){
            if (ele.PackageList && ele.PackageList.length > 0) {
              carrierList = ele.PackageList.map((availablepackage) => {
                let details = {};
                // if (SelectedPlanName === availablepackage.PlanName) {
                //   details = { SelectedPlanName };
                // }
                details = {...details, AvailableMarket: {...availablepackage,...availablepackage.CarrierList[0]}}
                if ("PackageList" in response.data) {
                  response.data.PackageList.map((packageData) => {
                    if(packageData.PlanName === availablepackage.PlanName) {
                      details = {...details,...packageData}
                      packageData.IsSelectedPlanName==='Yes' && store.dispatch({type: actionType.SET_SELECTED_PLAN, payload: packageData.PlanName});
                    }
                  });
                } else if ("Coverage" in response.data && SelectedPlanName === availablepackage.PlanName) details = {...details,Coverage: response.data.Coverage};
                return details;
              });
            }
          } else {
            carrierList = ele.CarrierList.map((data) => ({
              AvailableMarket: data,
              Coverage: response.data.Coverage,
            }));
          }
        }
      });
      //Method should be changed and only little translation should be done (Should be cleaned)
      UpdateState("AvailableMarketList_dataList",carrierList,false);
      UpdateState("selectedPolicy",response.data.SublineName,false);
      UpdateState("ProductSelection_RadioValues",availableMarketsResponse,false);
      let uid = uuidv4();
      UpdateState("RequestID",uid,true);
      UpdateState("AvailableMarket",{...ProductSelection,...value},false);
      setDataLists(props.pageInfo.dataListName, response.data && response.data.Coverage, UpdateState)
      UpdateState("SublineList",list,false);
      await store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: true});
  }
  props.UpdateState("pagelevelValues",{...response.data,...submissionValues.data},true);
  }
  tpo.ProductSelection && delete tpo["ProductSelection"];
  }
  tpo = {...tpo, ...locationResponse}
  // await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})


  returnValues=await componentDidMount_Orchid(state, props, tpo)

  if(Object.keys(quoteValues).length>1){
    returnValues={...returnValues,...quoteValues};
  }
  returnValues.transactionPrimaryObject = {...returnValues.transactionPrimaryObject, ...tpo}
  returnValues.pagelevelValues = {...returnValues.pagelevelValues, ...locationResponse}
  returnValues = {...returnValues, ...locationResponse}
  return returnValues
}
catch(e){
  props.parentState.UpdateState("dynamicLoadingMessage",'',true);
  console.log("Error in componentDidMount_ProductSelection_RateIndication",e);
}finally{
  UpdateState("loading",false,true);
}

}

const getRequestConstruction_HomeOwners_RateIndication = (request, props) => {
  const redux = store.getState();
  if (props.pageInfo.parentHierarchy) {
      let listName = props.pageInfo.parentHierarchy + "IdList"
      if (redux.metadataReducer[listName]) {
          if (redux.metadataReducer[listName].includes(props.rowData.ID)) {
              request["PARENTID"] = props.rowData.ID
              request["ID"] = props.rowData.ID
          }
      }
      request["EntityName"] = props.pageInfo.entityName
      request["ROOTID"] = redux.metadataReducer.submissionResponse.SubmissionID
      request["EventName"] = props.pageInfo.eventName
      request["TargetEventName"] = props.pageInfo.getTargetEvent
  }
  else {
      //getStateList(props)
      request["EntityName"] = props.pageInfo.entityName
      request["EventName"] = props.pageInfo.eventName
      request["TargetEventName"] = props.pageInfo.getEventName
      request["ID"] = redux.UIFlowReducer.submissionID
      request["PARENTID"] = redux.UIFlowReducer.submissionID
      request["ROOTID"] = redux.UIFlowReducer.submissionID
  }

}



const continueFirm_Quote=async(event ,propsObj,stateObj, component,functions,e,props,option, rowdata) =>{

 try {

  functions.UpdateState("loading",true,true)
  let request={}
  let storeValue = store.getState();
  let redux=store.getState();
  let Index = props.componentState.IteratorIndex;
  let IteratordataList = props.componentState.ResponseKey;
   let plandetails = propsObj[IteratordataList][Index].AvailableMarket
  request["ServiceRequestDetail"]= getServiceRequestDetail();
  request["OwnerId"] = sessionStorage.getItem("OwnerID")
  request.EventName = GetCommonEventName();
  request["ID"]=redux.UIFlowReducer.submissionID;
  let subRateIdCheck;
  await propsObj.AvailableMarketList_dataList.map(data=>{
    if(data.hasOwnProperty("SubApplicationDetailList") && data.SubApplicationDetailList.length!==0 && data.hasOwnProperty("SubmissionRatingDataID") && data.SubmissionRatingDataID!==undefined){
      subRateIdCheck = false;
    }
  })
  await props.componentState.AvailableMarketList_dataList.map(data=>{
    if(data.hasOwnProperty("SubApplicationDetailList") && data.SubApplicationDetailList.length!==0 && data.hasOwnProperty("SubmissionRatingDataID") && data.SubmissionRatingDataID!==undefined){
      subRateIdCheck = true;
    }
  })
  let aVDataList = subRateIdCheck===false ? propsObj.AvailableMarketList_dataList : props.componentState.AvailableMarketList_dataList;
  let index =aVDataList.findIndex(row=> row.AvailableMarket.CarrierName===props.pagelevelValues.AvailableMarket.CarrierName)
  // request["SubmissionRatingDataID"]=aVDataList[index].SubmissionRatingDataID
  request.TargetEventName  = propsObj.RateIndicationTargetEventName;
  request.LOB = redux.metadataReducer.lob;
   request = {...request,...plandetails}
  // request.ProductNumber=props.componentProps.productDetails.ProductNumber
  // request.ProductVerNumber=props.componentProps.productDetails.ProductVerNumber
  // request.PolicyEffectiveDate = propsObj.AvailableMarket.PolicyEffectiveDate;
  // request.PolicyExpirationDate = propsObj.AvailableMarket.PolicyExpirationDate;
  const response = await createQuoteAxios.post("/core/invokeWorkflowEvent/V2", request)
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "success"){
    setFlowAndNavigation(component)
    let navObj = await getNavigation(["ProductNavigation"],[props.componentProps.productDetails.ProductVerNumber]);
      if (navObj.hasOwnProperty("productNavigation")) {
      let templatefromredux = props.componentProps.templateInfo;
      let key = props.componentProps.productDetails.LOBName ? `${props.componentProps.productDetails.LOBName}_submission` : "template";
      key = navObj.productNavigation[key] ? key : "template"
      templateConstruct(navObj, ["template"], templatefromredux);
      let prodnav = navObj.productNavigation[key];
      let pageNav = Object.assign({}, props.componentProps.navigation);
      pageNav.pageNavigation.submission.sidebar[1].children = prodnav;
      props.componentProps.setNavigation(pageNav);
      props.componentProps.setPageNavigation(prodnav);
    }
    store.dispatch({type: actionType.SET_PROCESS_STATUS, payload: response.data.ProcessStatus})
    store.dispatch({type: actionType.SET_CURRENT_PAGEINDEX, payload: propsObj.pageInfo.RedirectionIndex })
    }
    propsObj.UpdateState("pageMetadata",{},false);
    propsObj.UpdateState("pagelevelValues",{...propsObj.metadataReducer,...propsObj.UIFlowReducer},true);
  }  catch(exception){
    console.log("error in continueFirm Quote",exception)
  }
}

const PreviewRateIndication =async(EventName, propsObj, stateObj, component,functions,e,props,option,rowData)=>{
try {
functions.UpdateState("loading",true,true)
let request={}
 let storeValue = store.getState();
let redux=store.getState();
request["ServiceRequestDetail"]= getServiceRequestDetail();
request["OwnerId"] = sessionStorage.getItem("OwnerID")
request.EventName = GetCommonEventName();
request["ID"]=redux.UIFlowReducer.submissionID;
let subRateIdCheck;
await propsObj.AvailableMarketList_dataList.map(data=>{
  if(data.hasOwnProperty("SubApplicationDetailList") && data.SubApplicationDetailList.length!==0 && data.hasOwnProperty("SubmissionRatingDataID") && data.SubmissionRatingDataID!==undefined){
    subRateIdCheck = false;
  }
})
await props.componentState.AvailableMarketList_dataList.map(data=>{
  if(data.hasOwnProperty("SubApplicationDetailList") && data.SubApplicationDetailList.length!==0 && data.hasOwnProperty("SubmissionRatingDataID") && data.SubmissionRatingDataID!==undefined){
    subRateIdCheck = true;
  }
})
let aVDataList = subRateIdCheck===false ? propsObj.AvailableMarketList_dataList : props.componentState.AvailableMarketList_dataList;
let index = "activeConfiguration" in props.pageInfo && props.pageInfo.activeConfiguration === "package" ?  aVDataList.findIndex(row=> row.AvailableMarket.PlanName===rowData.PlanName) : aVDataList.findIndex(row=> row.AvailableMarket.CarrierName===rowData.CarrierName)
 request.SubmissionRatingDataID=aVDataList[index].SubmissionRatingDataID
 request.TargetEventName  = option.TargetEventName;
 request.ProductNumber=props.componentProps.productDetails.ProductNumber
 request.ProductVerNumber=props.componentProps.productDetails.ProductVerNumber
 request.PolicyEffectiveDate = propsObj.AvailableMarket.PolicyEffectiveDate;
 request.PolicyExpirationDate = propsObj.AvailableMarket.PolicyExpirationDate;
 const response = await createQuoteAxios.post("/core/invokeWorkflowEvent/V2", request)
if (response && response.data.ResponseStatus === 'failed') {
  triggerToast('Error in preview rate document')
  functions.UpdateState("loading",false,true);
 } else {
  const file = response.data.DocumentUrl
 //Support for IE Browser
 if (window.navigator && window.navigator.msSaveOrOpenBlob) {
   window.navigator.msSaveOrOpenBlob(file, "Myfile.pdf");
 }
 // Support for Chrome & Firefox version
 else if (navigator.userAgent.indexOf('Chrome') != -1 || navigator.userAgent.indexOf('Firefox') != -1) {
 const w = window.open(file, '_blank');
  w && w.focus();
} else {
 // Safari & Opera iOS
 window.location.href = file;
}
  functions.UpdateState("loading",false,true);
}
functions.UpdateState("loading",false,true);
} catch(exception){
  console.log("error in preview rate indication",exception)
  functions.UpdateState("loading",false,true);
}
}

const ViewRateIndication =async(EventName, propsObj, stateObj, component,functions,e,props,option,rowData)=>{
  try {
  functions.UpdateState("loading",true,true)
  let request={}
   let storeValue = store.getState();
  let redux=store.getState();
  request["ServiceRequestDetail"]= getServiceRequestDetail();
  request["OwnerId"] = sessionStorage.getItem("OwnerID")
  request.EventName = GetCommonEventName();
  request["ID"]=redux.UIFlowReducer.submissionID;
  request["LOB"]=redux.metadataReducer.lob;
   request.TargetEventName  = option.TargetEventName;
   const response = await createQuoteAxios.post("/core/invokeWorkflowEvent/V2", request)
  if (response && response.data.ResponseStatus === 'failed') {
    triggerToast('Error in preview rate document')
    functions.UpdateState("loading",false,true);
   } else {
    const file = response.data.DocumentUrl
   //Support for IE Browser
   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
     window.navigator.msSaveOrOpenBlob(file, "Myfile.pdf");
   }
   // Support for Chrome & Firefox version
   else if (navigator.userAgent.indexOf('Chrome') != -1 || navigator.userAgent.indexOf('Firefox') != -1) {
   const w = window.open(file, '_blank');
    w && w.focus();
  } else {
   // Safari & Opera iOS
   window.location.href = file;
  }
    functions.UpdateState("loading",false,true);
  }
  functions.UpdateState("loading",false,true);
  } catch(exception){
    console.log("error in preview rate indication",exception)
    functions.UpdateState("loading",false,true);
  }
  }

  const referSub_request = async(request,propsObj,e,stateObj) =>{
    try{
       let index = propsObj.componentState.IteratorIndex;
       let IteratordataList = propsObj.componentState.ResponseKey
       let plandetails = propsObj.componentProps[IteratordataList][index].AvailableMarket
       request = {...request,...plandetails}
       return request
    }
    catch(exception){
    console.log("error in refer submission request construction",exception)
    }
    }


const referSub_UW = async(response,propsObj,stateObj,e,props) =>{

  try{
      propsObj.functions.UpdateState("loading",true,true)
        await store.dispatch({type:actionType.SET_SUBMISSION_STATUS,payload:response.SubmissionStatus})
        await store.dispatch({type: actionType.SET_PROCESS_STATUS, payload:response.ProcessStatus})
       await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
       let {iteratorSubappList: subapplist} = propsObj.pageInfo
    const { ProductID: objectId, ProductVerID: objectVerId } =propsObj.componentProps.productDetails;
    let subappinfo = {
      subappName: subapplist,
      objectId,
      objectVerId,
      state:"CW",
      subApplicationGroupList: [],
    };

    let iteratorIndex = propsObj.componentState.IteratorIndex
    let dataListKey = propsObj.componentState.ResponseKey
    let dataList =  propsObj.componentProps[dataListKey]
    let Tlist = {...dataList[iteratorIndex],AvailableMarket:{...propsObj.componentProps.AvailableMarket,...dataList[iteratorIndex]["AvailableMarket"],"IsReferralRuleApplied":"false"},...response.data}

    Tlist["ShowBusinessRules"] ="false";
   let metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
      transactionPrimaryObject:Tlist,
      EffectiveDate: propsObj.value && propsObj.value.EffectiveDate ? propsObj.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    });
    let metadata=metadataresponse && metadataresponse.ApplicationDetail && metadataresponse.ApplicationDetail.SubApplicationDetailList
    dataList[iteratorIndex]["SubApplicationDetailList"] = metadata || []

      propsObj.functions.UpdateState(dataListKey,dataList,false)
      propsObj.functions.UpdateState("loading",false,true)
  }
  catch(exception){
  console.log("error in UW submission",exception)
  }
  }

  const referSub_Package = async(response,propsObj,stateObj,e,props) =>{

    try{
        propsObj.functions.UpdateState("loading",true,true)
        await store.dispatch({type: actionType.SET_SELECTED_PLAN, payload: response.PlanName});
        await store.dispatch({type:actionType.SET_SUBMISSION_STATUS,payload:response.SubmissionStatus})
        await store.dispatch({type: actionType.SET_PROCESS_STATUS, payload:response.ProcessStatus})
        await store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
        let {iteratorSubappList: subapplist} = propsObj.pageInfo
        let subappNameList = propsObj.pageInfo.iteratorSubappList
        let subappName = [];
        let selectedSubline = propsObj.pageInfo.selectedSubline ? propsObj.pageInfo.selectedSubline : {};
        let neededSubapps = []
        let choosedsubline = propsObj.componentState && propsObj.componentState.SublineName ? propsObj.componentState.SublineName : '';
        Object.keys(selectedSubline).length > 0 && Object.entries(selectedSubline).forEach(([key,value])=>{
          if(key === choosedsubline){
            neededSubapps.push(value)
           }
         })
         subappName = [...subappNameList,...neededSubapps]
      const { ProductID: objectId, ProductVerID: objectVerId } =propsObj.componentProps.productDetails;
      let subappinfo = {
        subappName,
        objectId,
        objectVerId,
        state:"CW",
        subApplicationGroupList: [],
      };
      let iteratorIndex = propsObj.componentState.IteratorIndex
      let dataListKey = propsObj.componentState.ResponseKey
      let dataList =  propsObj.componentProps[dataListKey]
      let Promisedata = dataList.map((data,i) =>{
        let Tlist = {...dataList[i],AvailableMarket:{...propsObj.componentProps.AvailableMarket,...dataList[i]["AvailableMarket"],"IsReferralRuleApplied":"false"}}
        if(iteratorIndex === i)
        Tlist = {...Tlist,...response.data}
        Tlist["ShowBusinessRules"] ="false";
        return constructAndInvokeMetadataCall(subappinfo, {
          transactionPrimaryObject:Tlist,
          EffectiveDate: propsObj.value && propsObj.value.EffectiveDate ? propsObj.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
        })
      })
      let metacalls = await Promise.allSettled(Promisedata)
      metacalls.map((meta,i) => {
        if(meta.status == 'fulfilled' && meta.hasOwnProperty('value')){
          let metaresponse = meta.value && meta.value.ApplicationDetail && meta.value.ApplicationDetail.SubApplicationDetailList
          dataList.map((data,index) => {
            if (index === i){
              data["SubApplicationDetailList"] = metaresponse
            }
            return
          })
        }
      })
        propsObj.functions.UpdateState(dataListKey,dataList,false)
        propsObj.componentProps.parentFunctions.updateValues({"ProductSelection_Needed": true})
        propsObj.functions.UpdateState("loading",false,true)
    }
    catch(exception){
    console.log("error in UW submission",exception)
    }
    }

  const UpdateParenState_ProductSelectionumber = async (value, component, isDynamicTable, datatableRowindex, props) => {
    let obj= await handleInputChangeV2(value,component, props.componentProps, props.componentState,props.functions,undefined,undefined, true,props);
    props.componentProps.parentState.UpdateState("AvailableMarket",obj.pagelevelValues[component.SubApplicationName],true);

  }

  const createQuote_Orchid = async(eventName,propsObj,stateObj,component,functions,e,props)=>{
    try{
      const reduxState = store.getState();
    functions.UpdateState("loading",true,true)
    let {componentProps:{AvailableMarketList_dataList}}=props;
    let iteratorIndex = props.componentState.IteratorIndex
    let {SubApplicationName,AttributeName}=props.component
    let pageLevelValues={...AvailableMarketList_dataList[iteratorIndex],[SubApplicationName]:{[SubApplicationName]: {...AvailableMarketList_dataList[iteratorIndex][SubApplicationName]},["ProductSelection"]: props.componentProps.AvailableMarket, "insuredID": props.componentProps.UIFlowReducer.insuredID,...props.componentProps.SublineList}}
    let request = constructTransactionRequestV2(props.componentState,props.componentProps,pageLevelValues,true,false);
    let reduxValues=store.getState();
    request.EventName =  GetCommonEventName();
    if(props.pageInfo.ValidEffectiveDays && props.pageInfo.ValidEffectiveDays===true){
    let EffectiveDate =   props.pagelevelValues.ProductSelection.PolicyEffectiveDate && props.pagelevelValues.ProductSelection.PolicyEffectiveDate
    let currentDate =  dateFormat(new Date());
    let timeDifference = new Date(currentDate) - new Date(EffectiveDate)
    const dayDifference = timeDifference / (1000 * 3600 * 24)
    request.ValidEffectiveDays= dayDifference.toString();
    }
    request["SubmissionID"]=reduxValues.UIFlowReducer.submissionID;
    request.TargetEventName  = props.component.EventName;
    if (props.pageInfo && props.pageInfo.officeDetailsNeeded) {
      let {OfficeDetailsRequestKey = [], OfficeDetailsValues = []} = props.pageInfo
      let {userInfoReducer} = reduxValues
      if (OfficeDetailsRequestKey.length > 0 && OfficeDetailsValues.length > 0) {
        let OfficeDetailsRequestKeyLength = OfficeDetailsRequestKey.length
        for (let i = 0; i < OfficeDetailsRequestKeyLength; i++) {
          request[OfficeDetailsRequestKey[i]] = userInfoReducer.OfficeMappingDetails && userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] ? userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] : ''
        }
      }
    }
    if(props.pageInfo.quoteeventaction)
      request.EventAction  = props.pageInfo.quoteeventaction;
    let childDatalist = {}
    let dataListName = props.pageInfo.dataListName ? props.pageInfo.dataListName : []
    Object.keys(AvailableMarketList_dataList[iteratorIndex]).forEach(
      (key) => {
        dataListName.map(
          (eachkey) => {
            if(`${eachkey.toLocaleLowerCase()}_dataList` === key){
              childDatalist[eachkey] = AvailableMarketList_dataList[iteratorIndex][key]
            }
          }
        )
      }
    )
    // let coverageDetails = `${props.componentProps.SublineList.SublineName}`+"CoverageDetails";
    // let Coverage ={
    //   ...props.componentState[`${coverageDetails}`],...childDatalist
    // }
    // let AvailableMarket = {
    //   insuredID : reduxValues.UIFlowReducer.insuredID,
    //   SublineID : props.componentProps.SublineList.SublineID,
    //   SublineDisplayName : props.componentProps.SublineList.SublineDisplayName,
    //   SublineName : props.componentProps.SublineList.SublineName,
    //   ProductSelection :{
    //     ...props.componentProps.AvailableMarket
    //   },
    //   PersonalArticleFloater: stateObj.personalarticlefloater_dataList,
    //   AvailableMarket:{
    //     ...props.componentState.AvailableMarket
    //   }
    // }
    if(props.componentProps.UIFlowReducer.quoteNumber && props.componentProps.UIFlowReducer.quoteID){
      request.QuoteNumber=props.componentProps.UIFlowReducer.quoteNumber;
      request.ID=props.componentProps.UIFlowReducer.quoteID;
    }
    request.Coverage = {...request.Coverage,...childDatalist}
    // request.AvailableMarket = {...AvailableMarket}
    request.ServiceRequestDetail.Lob =props.componentProps.productDetails.LOBName
    // request.ExternalQuoteNumber = storeValue.metadataReducer.externalQuoteNumber;
    const response = await createQuoteAxios.post("/core/invokeWorkflowEvent/V2", request)
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() ==="failed" && response.data.hasOwnProperty("Message")){
      triggerToast(response.data.ErrorMessage[0].userInfo,'Carrier Error : ',functions.UpdateState)
      return
    }
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
      triggerToast('Unable to Create Quote')
      console.log("Response Failed");
      functions.UpdateState("loading",false,true)
      return;
    }
    if("RuleStatus" in response.data && response.data.RuleStatus.toLowerCase()==='ineligible'){
      let tpo = {...props.componentState.transactionPrimaryObject, QuoteNumberRule: response.data.RuleStatus}
      let newstate = {...props.componentState, transactionPrimaryObject: tpo}
      let {pageMetadata} = await avaiableMarket(props.componentProps, newstate);
      functions.UpdateState("carriervalidations_dataList",response.data.RuleInformationList,true)
      functions.UpdateState("pageMetadata", pageMetadata, true)
    }
    else{
    let QuoteDetails={};
    QuoteDetails.QuoteStatus=response.data.QuoteStatus;
    QuoteDetails.quoteNumber = response.data.QuoteNumber;
    QuoteDetails.quoteID = response.data.ID;
    if(response.data.hasOwnProperty('QuoteSubNumber')){
      QuoteDetails.quoteSubNumber=response.data.QuoteSubNumber;
    }
      store.dispatch({
        type: actionType.SET_QUOTE_DETAILS,
        payload: {
          ...QuoteDetails
        }
      });
      store.dispatch({
        type: actionType.SET_HASLOCATION,
        payload: true
      });

      if(props.pageInfo.dynamicFlow && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
        store.dispatch({type: actionType.SET_PAGENAME, payload: ""});
        let redirectRoute=""
        const encodedString=await constructEncodedString({"quoteID":response.data.ID,"Flow":"QuoteNow"});
        redirectRoute+=`${encodedString}`;
        props.componentProps.history.push(`/quote/${redirectRoute}`);
      }
      else{
        store.dispatch(setCurrentPageIndex(1))
        store.dispatch({type: actionType.SET_PAGENAME, payload: ""});
        props.componentProps.history.push(`/quote`)
      }

      if(props.pageInfo.ratingRequestResponseNeeded){
        await invokeThirdPartyApi(props,response)
      }
    }
    functions.UpdateState("loading",false,true)
  }catch(e){
    console.log("Error in Create Quote Method:: ",e)
    functions.UpdateState("loading",false,true)
  }
  }

  const applyToAll = async(request, propsObj, e, stateObj)=>{
    try{
    let props = propsObj
    let {component} = props
    const reduxState = store.getState();
    let otherCovUpdate ={}
    let {componentProps:{AvailableMarketList_dataList},componentProps:{parentState:{UpdateState}}}=props;
    let AvailableMarketList = AvailableMarketList_dataList
    let subappListLoader = [
      "AvailableMarket"
  ]

  const { ProductID: objectId, ProductVerID: objectVerId, ProductNumber, ProductVerNumber } =props.componentProps.productDetails;

    let subappinfo = {
      objectId,
      objectVerId,
      state:"CW",
      subApplicationGroupList: [],
    };

    let tpo = {ProductSelection:  AvailableMarketList[0].ProductSelection}

    UpdateState("loading", true, true)
    let LoadingMetaDataRes = await constructAndInvokeMetadataCall({...subappinfo,subappName: subappListLoader}, {
      transactionPrimaryObject:{...tpo, "AvailableMarketLoading": "true"},
      EffectiveDate: propsObj.value && propsObj.value.EffectiveDate ? propsObj.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    });

    let LoadingMetaData=(LoadingMetaDataRes && LoadingMetaDataRes.ApplicationDetail && LoadingMetaDataRes.ApplicationDetail.SubApplicationDetailList) || []

    const AvailableMarketList_Loader = AvailableMarketList.map((data)=> {
          return {
            ...data,
            SubApplicationDetailList: LoadingMetaData
          }
    })
    UpdateState("loading", false, true)
    UpdateState("AvailableMarketList_dataList", AvailableMarketList_Loader, true);
    let iteratorIndex = props.componentState.IteratorIndex
    request["ProductNumber"] = ProductNumber;
    request["ProductVerNumber"] = ProductVerNumber;
    AvailableMarketList.map((data,index) => {
      if(index === iteratorIndex){
      data["AvailableMarket"]["ApplyAllDisabled"] = 'true'
      let appliedToOther = component.Action.keysTobeApplied
        appliedToOther.map((key) => {
          if(data.Coverage && key in data.Coverage){
            otherCovUpdate = {...otherCovUpdate,[key] : data.Coverage[key]}
          }
        })
      }
    })
    let PromisedData = AvailableMarketList.map((data,index) => {
      let AvailableMarket = {
        AvailableMarket: data.AvailableMarket,
        ProductSelection: data.ProductSelection,
        insuredID: propsObj.componentProps.UIFlowReducer.insuredID,
        ...propsObj.componentProps.SublineList,
      };
      request = {
        ...request,
        Coverage: {...data.Coverage,...otherCovUpdate},
        AvailableMarket: AvailableMarket,
      };
      return AxiosInstance.post("core/invokeWorkflowEvent/V2", request);
    });
    let ratingCall = await Promise.allSettled(PromisedData);
    let checkRuleList;
      let promises = ratingCall.map(async (rating, i) => {
        if (rating.status == "fulfilled" && rating.hasOwnProperty("value") && rating.value !== undefined) {
          let response = rating.value && rating.value.data;
          let Tlist = {};
          const { metadataReducer } = store.getState();
          if (
            response && "ProcessStatus" in response &&
            metadataReducer.ProcessStatus !== response.ProcessStatus
          ) {
            store.dispatch({
              type: appActions.actionType.SET_PROCESS_STATUS,
              payload: rating.value.ProcessStatus,
            });
            store.dispatch({
              type: appActions.actionType.SET_EXPANDPANEL_DATA,
              payload: true,
            });
          }
          if (response.data && response.data.SubmissionStatus)
            await store.dispatch({ type: Action.SET_SUBMISSION_STATUS, payload: response.data.SubmissionStatus });

          let summarySubApp = [];
          let subappName = [];
          let subappNameList = props.pageInfo.iteratorSubappList;
          let selectedSubline = props.pageInfo.selectedSubline;
          let choosedsubline = props.componentState.SublineName ? props.componentState.SublineName : "";
          let neededSubapps = [];
          Object.keys(selectedSubline).length > 0 &&
            Object.entries(selectedSubline).forEach(([key, value]) => {
              if (key === choosedsubline) {
                neededSubapps.push(value);
              }
            });
          subappName = [...subappNameList, ...neededSubapps];
          subAppListConstructionV1(subappName, {}, [], [], summarySubApp);
          if (response && response.hasOwnProperty("ExternalQuoteNumber")) {
            store.dispatch({
              type: actionType.SET_EXTERNAL_QUOTE_NUMBER,
              payload: response.ExternalQuoteNumber,
            });
          }
          let subappinfo = {
            subappName,
            objectId,
            objectVerId,
            state: "CW",
            subApplicationGroupList: [],
          };
          let metadataresponse = [];
          checkRuleList = false;
          let responseFromTranslation = {};
          responseFromTranslation = await EntitySubAppTranslationV2({ ...response }, props.componentState.pageMetadata.ApplicationDetail);
          Tlist = {...AvailableMarketList[i], AvailableMarket: {...propsObj.componentProps.AvailableMarket,...AvailableMarketList[i]["AvailableMarket"],IsReferralRuleApplied: "false",}};
          Tlist = { ...Tlist, ...responseFromTranslation };
          Tlist["ShowBusinessRules"] = "false";
          metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
            transactionPrimaryObject: Tlist,
            EffectiveDate: propsObj.value && propsObj.value.EffectiveDate ? propsObj.value.EffectiveDate : format(new Date(), "yyyy-MM-dd"),
          });
          AvailableMarketList[i] = {...AvailableMarketList[i],...responseFromTranslation,...response,};
          AvailableMarketList[i]["AvailableMarket"]["TotalPremium"] =response.Coverage && response.Coverage.TotalPremium;
          AvailableMarketList[i][`${props.componentProps.SublineList.SublineName}CoverageInformationDetails`] = [];
          AvailableMarketList[i][`${props.componentProps.SublineList.SublineName}CoverageInformationDetails`] =response.Coverage && response.Coverage.CoverageRuleInformationList ? [...response.Coverage.CoverageRuleInformationList] : [];
          AvailableMarketList[i][`feescarrier_dataList`] = response.Fee;
          AvailableMarketList[i][`taxescarrier_dataList`] = response.Tax;
          AvailableMarketList[i]["SubApplicationDetailList"] = metadataresponse.ApplicationDetail.SubApplicationDetailList || [];
          AvailableMarketList[i]["SubmissionRatingDataID"] = response.SubmissionRatingDataID;
          AvailableMarketList[i]["isEligible"] = response.RuleStatus && response.RuleStatus.toLowerCase();
          AvailableMarketList[i]["viewbusinessrulespl_dataList"] = response.RuleInformationList || [];
          props.functions.UpdateState("ruleinformationlistpl_dataList",response.RuleInformationList,false);
          props.pageInfo.dataListName.map(
            (datalist) => {
              if (datalist in response.Coverage)
                AvailableMarketList[i][`${datalist.toLocaleLowerCase()}_dataList`] = response.Coverage[datalist];
            }
          );
          props.functions.UpdateState("transactionPrimaryObject", Tlist, false);
          UpdateState("PopUp_ScheduleOfForms", response.Coverage, false);
          props.functions.UpdateState("pagelevelValues", { ...Tlist }, false);
        }
      });
      await Promise.all(promises);
      let sortedList = [...AvailableMarketList];
      let issort = sortedList.every(
        (data) =>
          data["AvailableMarket"] &&
          data["AvailableMarket"].hasOwnProperty("TotalPremium")
      );
      if (issort) {
        let sortedList_ = sortedList.filter(
          (data) => data.AvailableMarket && data.AvailableMarket.TotalPremium
        );
        sortedList_.length > 1 &&
          sortedList.sort((a, b) => {
            return (
              Number(a.AvailableMarket.TotalPremium) -
              Number(b.AvailableMarket.TotalPremium)
            );
          });
        if (checkRuleList === undefined || checkRuleList === true) {
          UpdateState("AvailableMarketList_dataList", sortedList, false);
        } else {
          props.functions.UpdateState("AvailableMarketList_dataList", sortedList, false);
        }
      } else {
        let sortedList = [...AvailableMarketList];
        if (checkRuleList === undefined || checkRuleList === true) {
          UpdateState("AvailableMarketList_dataList", sortedList, false);
        } else {
          props.functions.UpdateState("AvailableMarketList_dataList", sortedList, false);
        }
      }
      UpdateState("AvailableMarketList_dataList", sortedList, true);
    }
    catch(err){
      console.log(err,"Error in applytoall method")
    }
  }

  const createQuoteNC = async(eventName,propsObj,stateObj,component,functions,e,props)=>{
    try{
      const reduxState = store.getState();
    functions.UpdateState("loading",true,true)
    let iteratorIndex = props.componentState.IteratorIndex
    let request = {};
    request = props.componentProps[`${props.component.SubApplicationName}List_dataList`]&& props.componentProps[`${props.component.SubApplicationName}List_dataList`].length>0 && {...props.componentProps['ProductSelection'], ...props.componentProps[`${props.component.SubApplicationName}List_dataList`][iteratorIndex][`${props.component.SubApplicationName}`], ...props.pageInfo.createQuoteRequestList}
    let reduxValues=store.getState();
    request.SublineName && delete request.SublineName;
    request.LOB = (props.componentProps[`${props.component.SubApplicationName}List_dataList`][iteratorIndex][`${props.component.SubApplicationName}`].Subline).split(", ").map(item => item.trim());
    request["EventName"] =  GetCommonEventName();
    request["SubmissionID"]=reduxValues.UIFlowReducer.submissionID;
    request.TargetEventName  = props.component.EventName
    request["QuoteNumber"]=reduxValues.UIFlowReducer.quoteNumber || '';
    request["ID"]=reduxValues.UIFlowReducer.quoteID || '';
    request["ServiceRequestDetail"]= getServiceRequestDetail();
    if (props.pageInfo && props.pageInfo.officeDetailsNeeded) {
      let {OfficeDetailsRequestKey = [], OfficeDetailsValues = []} = props.pageInfo
      let {userInfoReducer} = reduxValues
      if (OfficeDetailsRequestKey.length > 0 && OfficeDetailsValues.length > 0) {
        let OfficeDetailsRequestKeyLength = OfficeDetailsRequestKey.length
        for (let i = 0; i < OfficeDetailsRequestKeyLength; i++) {
          request[OfficeDetailsRequestKey[i]] = userInfoReducer.OfficeMappingDetails && userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] ? userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] : ''
        }
      }
      // request['OfficeName'] = reduxValues.userInfoReducer.OfficeMappingDetails && reduxValues.userInfoReducer.OfficeMappingDetails.EntitledOffice ? reduxValues.userInfoReducer.OfficeMappingDetails.EntitledOffice : ''
      // request['AssignedUnderwriter'] = reduxValues.userInfoReducer.OfficeMappingDetails && reduxValues.userInfoReducer.OfficeMappingDetails.AssignedUnderwriter ? reduxValues.userInfoReducer.OfficeMappingDetails.AssignedUnderwriter : ''
    }
    const response = await createQuoteAxios.post("/core/invokeWorkflowEvent/V2", request)
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
      triggerToast('Unable to Create Quote')
      console.log("Response Failed");
      functions.UpdateState("loading",false,true)
      return;
    }
    let QuoteDetails={};
    QuoteDetails.QuoteStatus=response.data.QuoteStatus;
    QuoteDetails.quoteNumber = response.data.QuoteNumber;
    QuoteDetails.quoteID = response.data.ID;
    if(response.data.hasOwnProperty('QuoteSubNumber')){
      QuoteDetails.quoteSubNumber=response.data.QuoteSubNumber;
    }
    if(props.pageInfo.dynamicFlow && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
      store.dispatch({type: actionType.SET_PAGENAME, payload: ""});
      let redirectRoute=""
      const encodedString=await constructEncodedString({"quoteID":response.data.ID,"Flow":"BrokerageMarket"});
      redirectRoute+=`${encodedString}`;
      store.dispatch({type: actionType.SET_QUOTE_DETAILS,payload: { ...QuoteDetails}});
      store.dispatch({type: actionType.SET_PAGENAME, payload: ""});
      props.componentProps.history.push(`/quote/${redirectRoute}`);
    }
    else{

      store.dispatch({type: actionType.SET_QUOTE_DETAILS,payload: { ...QuoteDetails}});
      store.dispatch(setCurrentPageIndex(1))
      store.dispatch({type: actionType.SET_PAGENAME, payload: ""});
      props.componentProps.history.push("/quote");}
      if(props.pageInfo.ratingRequestResponseNeeded){
        await invokeThirdPartyApi(props,response)
      }
    functions.UpdateState("loading",false,true)
  }catch(e){
    console.log("Error in Create Quote Method:: ",e)
    functions.UpdateState("loading",false,true)
  }
  }
const createQuote_CL = async(eventName,propsObj,stateObj,component,functions,e,props)=>{
  functions.UpdateState("loading",true,true)
  let req=await constructTransactionRequestV1(stateObj,propsObj,stateObj.pagelevelValues,undefined,true,false,null)
  let request={};
  let reduxValues=store.getState();
  request["ServiceRequestDetail"]= getServiceRequestDetail();
  request["OwnerId"] = sessionStorage.getItem("OwnerID")
  request.EventName =  GetCommonEventName();
  request["SubmissionID"]=reduxValues.UIFlowReducer.submissionID;
  request.TargetEventName  = "CreateQuote_V1"
  request.ServiceRequestDetail.Lob =props.componentProps.productDetails.LOBName
  request.ProductNumber=props.componentProps.productDetails.ProductNumber
  request.ProductVerNumber=props.componentProps.productDetails.ProductVerNumber
  const response = await createQuoteAxios.post("/core/invokeWorkflowEvent/V2", request)
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
    triggerToast('Unable to Create Quote')
    console.log("Response Failed");
    functions.UpdateState("loading",false,true)
    return;
  }
  let QuoteDetails={};
  QuoteDetails.QuoteStatus=response.data.QuoteStatus;
  QuoteDetails.quoteNumber = response.data.QuoteNumber;
  QuoteDetails.quoteID = response.data.ID;
  if(response.data.hasOwnProperty('QuoteSubNumber')){
    QuoteDetails.quoteSubNumber=response.data.QuoteSubNumber;
  }
    store.dispatch({
      type: actionType.SET_QUOTE_DETAILS,
      payload: {
        ...QuoteDetails
      }
    });
    store.dispatch({
      type: actionType.SET_QUOTE_STATUS,
      payload: {
        QuoteStatus:  QuoteDetails.QuoteStatus
      }
    });
  functions.UpdateState("loading",false,false)
  props.componentProps.history.push("/quotev2");
}
const getQuoteList = async(props,getQuoteListTargetEventName,getQuoteListSearchName)=>{
  let request = {}
  request["ServiceRequestDetail"] =  getServiceRequestDetail();
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request.EventName =  GetCommonEventName();
  request.TargetEventName = getQuoteListTargetEventName;
  let reduxData = store.getState().UIFlowReducer
  request.InsuredID = reduxData.insuredID
  if(props.pageInfo && props.pageInfo.ProductDetailsNeeded ){
    request.ProductNumber = reduxData.productDetails.ProductNumber
    request.ProductVerNumber = reduxData.productDetails.ProductVerNumber
  }
  request.SearchName = getQuoteListSearchName
  let response = await AxiosInstance.post("core/invokeWorkflowEvent/V2", request);
  return response.data.Quote
}
const getQuoteData = async (props,CreateQuoteTargetEventName,UpdateState={},componentProps) => {
  let request = {}
  let reduxState = store.getState();
  request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request.EventName =  GetCommonEventName();
  request.TargetEventName = CreateQuoteTargetEventName;
  request.EventAction = props.pageInfo.EventAction
  request.ID = reduxState.UIFlowReducer.quoteID;
  request.ProductNumber = reduxState.UIFlowReducer.productDetails.ProductNumber;
  request.ProductVerNumber = reduxState.UIFlowReducer.productDetails.ProductVerNumber;
  let response = await AxiosInstance.post("core/invokeWorkflowEvent/V2", request);
  if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
    triggerToast("Unable to get quote");
    console.log("Response Failed");
   return;
  }
  let routeInfo = store.getState().metadataReducer.route ? store.getState().metadataReducer.route:''
  if(routeInfo==="Quote"){
    if(response.data.QuoteStatus==="Bound"  || response.data.QuoteStatus=== "Issued"  || response.data.QuoteStatus==="Declined" || response.data.QuoteStatus==="Lapsed" || response.data.QuoteStatus==="Cancelled")
    {
      UpdateState('templateClassName',props.pageInfo.className+"_readOnly",false)
    }
    else UpdateState('templateClassName',props.pageInfo.className,false)
    if(response.data.AGENTESigned==='Y'){
      UpdateState('templateClassName',props.pageInfo.className+"_hideDiligentSave",false)
    }
  }
  let quoteStatus = response.data.QuoteStatus
  let quoteNumber = response.data.QuoteNumber
  let quoteFullNumber=response.data.QuoteFullNumber;
  let policyEffectiveDate = response.data.PolicyEffectiveDate
  let policyExpirationDate = response.data.PolicyExpirationDate
  let rateLookupDate = response.data.RateLookupDate
  let PolicyFullNumber;
if (response.data && response.data.PolicyFullNumber) {
  PolicyFullNumber = response.data.PolicyFullNumber;
  await store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: {"PolicyFullNumber": PolicyFullNumber}})
}
  let FeeName = response.data.Fee && response.data.Fee.length > 0 ? true : false;
  let TaxName = response.data.Tax && response.data.Tax.length > 0 ? true : false;
  await store.dispatch({type: actionTypes.SET_FEE_NAME, payload: FeeName});
  await store.dispatch({type: actionTypes.SET_TAX_NAME, payload: TaxName});
  store.dispatch({
    type: actionType.SET_QUOTE_DETAILS,
    payload: {
       quoteStatus,
       quoteNumber,
       quoteFullNumber,
       policyEffectiveDate,
       policyExpirationDate,
       rateLookupDate
      }
      });
      store.dispatch({
        type: actionType.SET_QUOTE_STATUS,
        payload: {
          quoteStatus
        }
      });
      if(response.data && response.data.ProcessStatus){
        await store.dispatch({type: appActions.actionType.SET_PROCESS_STATUS, payload: response.data.ProcessStatus});
      }
  if(props.pageInfo.ratingRequestResponseNeeded){
    await invokeThirdPartyApi(props,response)
  }
  //temp code till HOC implementation
  componentProps && componentProps.setUpdateExpandPanel && componentProps.setUpdateExpandPanel(true);
  props.setUpdateExpandPanel && props.setUpdateExpandPanel(true);
  props.setexpandPanelDetailData && props.setexpandPanelDetailData(response.data);
  return response
}
const callGetQuote = async(res,propsObj)=>{
  let template = propsObj.pageInfo;
  let redux = store.getState()
  store.dispatch({
    type: actionType.SET_UI_VALUES,
    payload: { [`${template.name}_popup`]: false },
  });
  let {route} = redux.metadataReducer;
  let {parentPropsValue} = propsObj.componentProps;
  let {UpdateState} = propsObj.componentProps.parentState;
  let values;
  if(route === "Quote"){
    values = await componentDidMount_QuoteDetails({transactionPrimaryObject:{}},parentPropsValue,UpdateState);
  }
  else if(route === "Policy"){
    values = await componentDidMount_PolicyDetails({transactionPrimaryObject:{}},parentPropsValue,UpdateState);
  }
  else if(route === 'Endorsement'){
    values= await componentDidMount_EndorsementDetails({transactionPrimaryObject:{}},parentPropsValue,UpdateState)
  }
  if (values && Object.keys(values).length > 0){
     propsObj.componentProps.parentFunctions.updateValues(values)
  }
}
const GetQuoteInsured = async(res, props)=>{
  let redux = store.getState()
  let {route} = redux.metadataReducer;
  let parentPropsValue = props.componentProps.parentProps;
  let {UpdateState} = props.componentProps.functions;
  let values;
  if(route === "Quote"){
    values = await componentDidMount_QuoteDetails({transactionPrimaryObject:{}},parentPropsValue,UpdateState);
  }
  else if(route === "Policy"){
    values = await componentDidMount_PolicyDetails({transactionPrimaryObject:{}},parentPropsValue,UpdateState);
  }
  if (values && Object.keys(values).length > 0){
    props.componentProps.functions.updateValues(values)
 }
}
const getQuoteDataV1 = async (props,CreateQuoteTargetEventName,UpdateState={}) => {
  let response={}
  try {
    let request = {}
    request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.EventName =  GetCommonEventName();
    request.TargetEventName = CreateQuoteTargetEventName;
    request.EventAction = props.pageInfo.EventAction
    request.ID = store.getState().UIFlowReducer.quoteID;
    response= await AxiosInstance.post("core/invokeWorkflowEvent/V2", request);
    let routeInfo = store.getState().metadataReducer.route ? store.getState().metadataReducer.route:''
    if(routeInfo==="Quote"){
      if(response.data.QuoteStatus==="Bound"  || response.data.QuoteStatus=== "Issued"  || response.data.QuoteStatus==="Declined" || response.data.QuoteStatus==="Lapsed" || response.data.QuoteStatus==="Cancelled")
      {
        UpdateState('templateClassName',props.UIFlowReducer.currentPageInfo.className+"_readOnly",false)
      }
      else UpdateState('templateClassName',props.UIFlowReducer.currentPageInfo.className,false)
    }
    let quoteStatus = response.data.QuoteStatus
    let quoteNumber = response.data.QuoteFullNumber
    let policyEffectiveDate = response.data.PolicyEffectiveDate
    let policyExpirationDate = response.data.PolicyExpirationDate
    let rateLookupDate = response.data.RateLookupDate
    store.dispatch({
      type: actionType.SET_QUOTE_DETAILS,
      payload: {
         quoteStatus,
         quoteNumber,
         policyEffectiveDate,
         policyExpirationDate,
         rateLookupDate
        }
        });
      store.dispatch({
          type: actionType.SET_QUOTE_STATUS,
          payload: {
            quoteStatus
          }
        });

  } catch (error) {
    console.log("error in getQuoteData",error);
  }
  return response
}



const componentDidMount_Quote = async (state,props,UpdateState) => {
  try{
  UpdateState("loading",true,true)
  let quotenav=[];
  let reduxState = store.getState()
  let ID = reduxState.UIFlowReducer.quoteID
  let templateInfoTemplates = reduxState.navigationReducer.templateInfo;
  let pageData={};
  if(props.pageInfo.dynamicFlow && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
    let val=window.location.hash.split('/');
      if(val.length > 1 && val[2]){
          let newVal=val[2];
          pageData = decodeString(newVal)
      }
      ID=pageData && pageData.quoteID?pageData.quoteID:reduxState.UIFlowReducer.quoteID
      if(pageData && pageData.hasOwnProperty('Flow')){
        store.dispatch({type: actionType.SET_FLOW, payload: pageData.Flow});
      }
      store.dispatch({type: actionType.SET_UI_VALUES,payload: pageData});
  }
  let productDetails = await getProductDetailsByQuoteId(ID,props)
  if(ID==="ShrinkWrap-Testing")
  productDetails={
        "ProductVerID": "362094",
        "ProductName": "Shrink Wrap Product",
        "LOBName": "SWP",
        "LineOfBusiness": "SWP",
        "ProductVerNumber": "SWP_1_V1",
        "END_DATE": "2123-10-01 00:00:00.0",
        "ProductID": "363023",
        "InsuranceType": "ShrinkWrap",
        "ProductNumber": "SWP_1"
    }
  store.dispatch({
    type: "SET_LOB",
    payload: productDetails.LOBName,
});
store.dispatch({
    type: "SET_PRODUCT_DETAILS",
    payload: productDetails,
});
  let LOB = productDetails.LOBName
  let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"], [productDetails.ProductVerNumber]);
  if(navObj.hasOwnProperty('productTemplateInfo')){
    //Util Construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template])
    })
    //SubTemplate construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
    })
    store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}})
  }
  let templatefromredux = {...templateInfoTemplates,...navObj.productTemplateInfo};
  if (navObj.hasOwnProperty("productNavigation")) {
    templateConstruct(navObj, ["template"], templatefromredux);
    quotenav = navObj.productNavigation[`${LOB}_quote`];
    let pageNav = Object.assign({}, props.navigation);
    let pagename =props.pageInfo.landingpage;
    if(props.UIFlowReducer.pageName !== "" && !props.pageInfo.dynamicFlow)
    pagename=props.UIFlowReducer.pageName;
    if(props.pageInfo.dynamicFlow && pageData.pageName)
    {
      pagename = pageData.pageName
    }
    pageNav.pageNavigation.quote.sidebar[getRequiredIndex(pageNav.pageNavigation.quote.sidebar,'name','Quote')].children =  quotenav
    let index = await getIndex(props,quotenav,getRequiredIndex,pagename)
    await props.setNavigation(
      { ...pageNav, "productNavigation": { ...navObj.productNavigation } }
    )
    await store.dispatch({
      type: actionTypes.SET_PAGENAME,
      payload: quotenav[index]['pageName']
    })
    if(props.UIFlowReducer.pageName !== "")
    UpdateState("currentPageInfo",quotenav[index],false)
    UpdateState("loading", false, false)
    store.dispatch(setCurrentPageIndex(quotenav[index]["index"]))
    await store.dispatch({ type: actionType.SET_ROUTE_INFO, payload: "Quote" })
    }
  }
  catch (err) {
    console.log("Error in componentDidMount_Quote", err)
  }
}
const componentDidMount_QuoteV2 = async (state, props, UpdateState) => {
  try {
    UpdateState("loading", true, true)
    let quotenav = [];
    let getQuoteResponse = {};

    let navObj = await getNavigation(["QuoteNavigation"], ["CL_1_4_V1"]);
    let templatefromredux = props.templateInfo;
    if (navObj.hasOwnProperty("quoteNavigation")) {
    templateConstruct(navObj, ["template"], templatefromredux);
    quotenav = navObj.quoteNavigation.QuoteDetail;
    let pageNav = Object.assign({}, props.navigation);
      // let pagename =props.pageInfo.landingpage;
      // if(props.UIFlowReducer.currentPageInfo != "" && props.UIFlowReducer.currentPageInfo.hasOwnProperty("name")){
      // pagename=props.UIFlowReducer.currentPageInfo.name;
      // }
      pageNav.pageNavigation.quotev2.sidebar[getRequiredIndex(pageNav.pageNavigation.quotev2.sidebar, 'name', 'QuoteV2')].children = quotenav
      let index = await getIndex(props, quotenav, getRequiredIndex)
      await props.setNavigation(
        { ...pageNav, "quoteNavigation": { ...navObj.quoteNavigation } }
      )
      await store.dispatch({
        type: actionTypes.SET_QUOTECONSOLE_INFO,
        payload: {}
      })
      await store.dispatch({
        type: actionTypes.SET_QUOTECONSOLE_INFO,
        payload: quotenav[index]
      })

      await store.dispatch({
        type: actionTypes.SET_CURRENTPAGE_INFO,
        payload: quotenav[index]
      })


      await store.dispatch({ type: actionType.SET_ROUTE_INFO, payload: "Quote" })
    }
  }
  catch (err) {
    console.log("Error in componentDidMount_QuoteV2", err)
  }
}
const getQuoteDetail_CL = async (state, props, UpdateState) => {

  try {

      if (Object.keys(props.UIFlowReducer.currentPageInfo) <= 1) {
        return;
      }
      let { objectId, objectVerId, subApplication } = props.pageInfo;
      let { transactionPrimaryObject } = state
      if (((props.metadataReducer.quoteStatus === "Bound" && props.metadataReducer.route === "Quote")) || props.metadataReducer.quoteStatus === "Issued" || props.metadataReducer.quoteStatus === "Declined" || props.metadataReducer.quoteStatus === "Lapsed" || props.metadataReducer.quoteStatus === "Cancelled") {
        UpdateState('templateClassName', props.UIFlowReducer.currentPageInfo.className + "_readOnly", false)
        // Temp Fix for Additional Insured and to be changed
      }
      else UpdateState('templateClassName', props.UIFlowReducer.currentPageInfo.className, false)
      let newProps = {
        objectId: objectId,
        objectVerId: objectVerId,
        subApplication: subApplication,
        subappName: subApplication
      }
     let orchidMountResponse = await quoteDetailInformationV1(state, props,UpdateState)
      return orchidMountResponse;


  }
  catch (e) {
    console.log("Error in getQuoteDetail_CL", e);
  }
}

const componentWillUnmount_QuotePolicy = (props,state,updatestate) => {
  clearInterval(state.intervalData)
}

const quoteDetailInformationV1 = async(state, props,  UpdateState,newProps,businessRulesList={},businessRulesKey={})=>{
  try{
  UpdateState("loading",true,true)
  let redux = store.getState()
  let {CreateQuoteTargetEventName,getQuoteListTargetEventName,getQuoteListSearchName}=props.pageInfo  ;
    //let getEmailEventname = props.pageInfo.eventName.getEmailList
    let getEmailEventname = "GetEmailList_WF_1.0.0.1"
  let getQuoteResponse = await getQuoteDataV1(props,CreateQuoteTargetEventName,UpdateState)
  await Promise.all([store.dispatch({type: actionTypes.GET_AGENCY_DETAILS, payload: getQuoteResponse.data.AgencyInformation}),
    store.dispatch({type: actionTypes.GET_AGENT_DETAILS, payload: getQuoteResponse.data.AgentInformation}),
    store.dispatch({type: actionTypes.SET_INSUREDID, payload: getQuoteResponse.data.InsuredID})])
    await store.dispatch({type:actionType.SET_SUBMISSIONID,payload: getQuoteResponse.data.SubmissionID});
  let getQuoteListResponse = getQuoteListTargetEventName && await getQuoteList(props,getQuoteListTargetEventName,getQuoteListSearchName)
  let{transactionPrimaryObject={}}=state
  if(redux.metadataReducer.route!== "Endorsement"){
    transactionPrimaryObject["IssueBinderNavigator"]="false";
  }
  if(getQuoteResponse.data.Location){
    transactionPrimaryObject["StateId"] = getQuoteResponse.data.Location.StateRegionCode;
  }
  if(businessRulesKey==="true"){
    transactionPrimaryObject["TriggerBusinessRules"] ="true"
    UpdateState("viewbusinessrules_dataList",businessRulesList,false)
  }else{
    transactionPrimaryObject["TriggerBusinessRules"]="false"
  }
  // transactionPrimaryObject["totalPremium"]=getQuoteResponse.data.TotalPremium;
  // transactionPrimaryObject["DiligentEffortInformation"] = getQuoteResponse.data.DiligentEffort
  let response = await componentDidMount_Orchid(state,props,{...transactionPrimaryObject,...getQuoteResponse.data})
  let getEmailList = getEmailEventname && await getEmail(props,response,getEmailEventname,getQuoteResponse.data.ID)
  let translatedResponse=await EntitySubAppTranslationV2({...getQuoteResponse.data},{...response.pageMetadata.ApplicationDetail})
  let temp={}
  temp.ruleinformationlist_dataList=getQuoteResponse.data.RuleInformationList
  temp.summarytaxdetailssp_dataList=getQuoteResponse.data.Tax;
  temp.summaryfeedetailssp_dataList=getQuoteResponse.data.Fee;
  temp.quotelist_dataList = getQuoteListResponse
  temp.emailsummary_dataList =getEmailList && getEmailList.data && getEmailList.data.Email
  await mergeRowdatawithDatalist(response.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{});
  UpdateState("loading",false,true)
  if(props.productDetails && props.productDetails.LOBName==='MPL'){
    return {
      ...response,
      pagelevelValues : translatedResponse,
      formsmpl_dataList:getQuoteResponse.data.FormsMPL,
      documentsmpl_dataList : getQuoteResponse.data.DocumentsMPL,
      ruleinformationlist_dataList:getQuoteResponse.data.RuleInformationList,
      quotelist_dataList : getQuoteListResponse,
      subjectivity_dataList: getQuoteResponse.data.Subjectivity
    }
  }
  return {
    detailSubApp: response.detailSubApp,
    detailSummaryList : response.detailSummaryList,
    subappNameList : response.subappNameList,
    summarySubApp : response.summarySubApp,
    pageMetadata : response.pageMetadata,
    pagelevelValues : {...transactionPrimaryObject,...getQuoteResponse.data,...translatedResponse},
    forms_dataList:getQuoteResponse.data.Forms,
    documents_dataList : getQuoteResponse.data.Documents,
    notes_dataList:getQuoteResponse.data.Notes,
    ruleinformationlist_dataList:getQuoteResponse.data.RuleInformationList,
    attachments_dataList:getQuoteResponse.data.Attachments,
    policyattachments_dataList: getQuoteResponse.data.PolicyAttachments,
    quotelist_dataList : getQuoteListResponse,
    emailsummary_dataList :(getEmailList && getEmailList.data && getEmailList.data.Email)|| [],
    summarytaxdetailssp_dataList:getQuoteResponse.data.Tax,
    summaryfeedetailssp_dataList:getQuoteResponse.data.Fee,
    transactionPrimaryObject : {...transactionPrimaryObject,...getQuoteResponse.data,...translatedResponse}
  }
}
catch(err){
  console.log("Error in quoteDetailInformationV1", err)
}
}
const componentDidUpdate_QuoteV2 = async (state, props, prevState, prevProps, UpdateState)=>
{
  try{

  // if(props.UIFlowReducer.currentPageInfo && prevProps.UIFlowReducer.currentPageInfo &&
  //   prevProps.UIFlowReducer.currentPageInfo.name != props.UIFlowReducer.currentPageInfo.name  )
  //   {
  //     console.log("did update was successful ");
  //   }

    // let {objectId,objectVerId,subApplication} = props.UIFlowReducer.currentPageInfo.template[0];
    // let {transactionPrimaryObject} = state

    if(((props.metadataReducer.quoteStatus==="Bound" && props.metadataReducer.route==="Quote")) || props.metadataReducer.quoteStatus=== "Issued"  || props.metadataReducer.quoteStatus==="Declined" || props.metadataReducer.quoteStatus==="Lapsed" || props.metadataReducer.quoteStatus==="Cancelled" )
      {
      props.UIFlowReducer.currentPageInfo &&  UpdateState('templateClassName',props.UIFlowReducer.currentPageInfo.className+"_readOnly",false)
        // Temp Fix for Additional Insured and to be changed
      }
      else {
        props.UIFlowReducer.currentPageInfo && UpdateState('templateClassName',props.UIFlowReducer.currentPageInfo.className,false)
      }
    // let newProps = {
    //   objectId : objectId,
    //   objectVerId : objectVerId,
    //   subApplication : subApplication,
    //   subappName: subApplication
    // }
    let orchidMountResponse;
    return orchidMountResponse;
    }
  catch(err){
    console.log("Error in componentDidUpdate_QuoteV2", err)
  }
}

const getInsuredDetail_CL = async(state ,props,UpdateState) =>{
  try{

    let res;
    res=await getQuoteDataV1(props,props.pageInfo.CreateQuoteTargetEventName,UpdateState);
    let transactionPrimaryObject = state.transactionPrimaryObject ? state.transactionPrimaryObject : {};
   // let metaResponse=await constructAndInvokeMetadataCall(props,{...transactionPrimaryObject,...res.data});
   // let responseFromTranslation=await EntitySubAppTranslationV2({...res.data},{...metaResponse.ApplicationDetail});
    let metadataResponse=await componentDidMount_Orchid(state,props,{...transactionPrimaryObject,...res.data});
     let responseFromTranslation=await EntitySubAppTranslationV2({...res.data},{...metadataResponse.pageMetadata.ApplicationDetail});
    return {"pagelevelValues":{...state.pagelevelValues,...responseFromTranslation},...metadataResponse,"transactionPrimaryObject":{...state.transactionPrimaryObject,...responseFromTranslation},"loading":false};
  }
  catch(err){
    console.log("Error in getInsuredDetail_CL", err)
  }
}

const quoteDetailInformation = async(state, props, prevState, prevProps, UpdateState,newProps,businessRulesList={},businessRulesKey={})=>{
  try{
  UpdateState("loading",true,true)
  let redux = store.getState()
  let {CreateQuoteTargetEventName,getQuoteListTargetEventName,getQuoteListSearchName}=props.pageInfo ;
  let getEmailEventname = props.pageInfo.eventName.getEmailList
  let pollEmail = props.pageInfo.pollEmail
  let getQuoteResponse = await getQuoteData(props,CreateQuoteTargetEventName,UpdateState,state)
  await Promise.all([store.dispatch({type: actionTypes.GET_AGENCY_DETAILS, payload: getQuoteResponse.data.AgencyInformation}),
    store.dispatch({type: actionTypes.GET_AGENT_DETAILS, payload: getQuoteResponse.data.AgentInformation}),
    store.dispatch({type: actionTypes.SET_INSUREDID, payload: getQuoteResponse.data.InsuredID}),
    store.dispatch({type:actionType.SET_SUBMISSION_STATUS,payload: getQuoteResponse.data.SubmissionStatus})
  ])
  let getQuoteListResponse = getQuoteListTargetEventName && await getQuoteList(props,getQuoteListTargetEventName,getQuoteListSearchName)
  let{transactionPrimaryObject}=state
  if(redux.metadataReducer.route!== "Endorsement"){
    transactionPrimaryObject["IssueBinderNavigator"]="false";
  }
  if(getQuoteResponse.data.Location){
    transactionPrimaryObject["StateId"] = getQuoteResponse.data.Location.StateRegionCode;
  }
  if(businessRulesKey==="true"){
    transactionPrimaryObject["TriggerBusinessRules"] ="true"
    UpdateState("viewbusinessrules_dataList",businessRulesList,false)
  }else{
    transactionPrimaryObject["TriggerBusinessRules"]="false"
  }
  transactionPrimaryObject["totalPremium"]=getQuoteResponse.data.TotalPremium;
  transactionPrimaryObject["DiligentEffortInformation"] = getQuoteResponse.data.DiligentEffort || {}
  let response = await componentDidMount_Orchid(state,newProps,{...transactionPrimaryObject,...getQuoteResponse.data})
  let getEmailList = getEmailEventname && await getEmail(props,response,getEmailEventname,getQuoteResponse.data.ID)
  if(pollEmail){
    let intervalData;
    let intervalSec = 1000 * props.pageInfo.intervalSec;
    let timeOutSec = 1000 * props.pageInfo.timeOutSec;
    intervalData = setInterval(function() {
      getEmailList = updateEmailList(props,response,getEmailEventname,getQuoteResponse.data.ID,UpdateState); }
      ,intervalSec)
    UpdateState('intervalData',intervalData,false);
    setTimeout(() => {
      clearInterval(intervalData);
    }, timeOutSec);
    }
  let translatedResponse=await EntitySubAppTranslationV2({...getQuoteResponse.data},{...response.pageMetadata.ApplicationDetail})
  let temp={}
  temp.ruleinformationlist_dataList=getQuoteResponse.data.RuleInformationList
  temp.summarytaxdetailssp_dataList=getQuoteResponse.data.Tax;
  temp.summaryfeedetailssp_dataList=getQuoteResponse.data.Fee;
  temp.ceaseinformationlist_dataList = getQuoteResponse.data.CeaseInformationList && getQuoteResponse.data.CeaseInformationList
  temp.ceaseinformationlistinpolicy_dataList = getQuoteResponse.data.CeaseInformationList && getQuoteResponse.data.CeaseInformationList
  temp.quotelist_dataList = getQuoteListResponse
  temp.emailsummary_dataList =getEmailList && getEmailList.data && getEmailList.data.Email
  await mergeRowdatawithDatalist(response.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{});
  UpdateState("loading",false,true)
  if(props.productDetails && props.productDetails.LOBName==='MPL'){
    return {
      ...response,
      pagelevelValues : translatedResponse,
      formsmpl_dataList:getQuoteResponse.data.FormsMPL,
      documentsmpl_dataList : getQuoteResponse.data.DocumentsMPL,
      ruleinformationlist_dataList:getQuoteResponse.data.RuleInformationList,
      quotelist_dataList : getQuoteListResponse,
      subjectivity_dataList: getQuoteResponse.data.Subjectivity
    }
  }
  return {
    ...temp,
    detailSubApp: response.detailSubApp,
    detailSummaryList : response.detailSummaryList,
    subappNameList : response.subappNameList,
    summarySubApp : response.summarySubApp,
    pageMetadata : response.pageMetadata,
    pagelevelValues : {...transactionPrimaryObject,...getQuoteResponse.data,...translatedResponse},
    forms_dataList:getQuoteResponse.data.Forms ? getQuoteResponse.data.Forms : [],
    documents_dataList : getQuoteResponse.data.Documents ? getQuoteResponse.data.Documents : [],
    notes_dataList:getQuoteResponse.data.Notes ? getQuoteResponse.data.Notes : [],
    ruleinformationlist_dataList:getQuoteResponse.data.RuleInformationList ? getQuoteResponse.data.RuleInformationList : [],
    attachments_dataList:getQuoteResponse.data.Attachments ? getQuoteResponse.data.Attachments : [],
    policyattachments_dataList: getQuoteResponse.data.PolicyAttachments ? getQuoteResponse.data.PolicyAttachments : [],
    quotelist_dataList : getQuoteListResponse,
    emailsummary_dataList : getEmailList && getEmailList.data ? getEmailList.data.Email : [],
    summarytaxdetailssp_dataList:getQuoteResponse.data.Tax ? getQuoteResponse.data.Tax : [],
    summaryfeedetailssp_dataList:getQuoteResponse.data.Fee ? getQuoteResponse.data.Fee : [],
    ceaseinformationlist_dataList : getQuoteResponse.data.CeaseInformationList ? getQuoteResponse.data.CeaseInformationList : [],
    ceaseinformationlistinpolicy_dataList : getQuoteResponse.data.CeaseInformationList ? getQuoteResponse.data.CeaseInformationList : [],
    transactionPrimaryObject : {...transactionPrimaryObject,...getQuoteResponse.data,...translatedResponse}
  }
}
catch(err){
  console.log("Error in quoteDetailInformation", err)
}
}
export const NCEndoRatingAPICall= async(response, props)=>{
  if(response.RuleInformationList){
    let stateobj = props.componentState;
    let transactionPrimaryObject = {...stateobj.transactionPrimaryObject,'LOB':response.LOB, 'EndorsementProductSelection':{"LOB": response.LOB}}
    let pagelevelValues = {...stateobj.pagelevelValues,'LOB':response.LOB, 'EndorsementProductSelection':{"LOB": response.LOB}}
    stateobj.pagelevelValues = pagelevelValues;
    stateobj.transactionPrimaryObject = transactionPrimaryObject;
    return;
  }
  try {
    let tpo = {};
    let {objectId,objectVerId, subappName=[]}=props.componentState.pageSubAppInfo
    let subAppDetail= {
        subappName:subappName,
        objectId : objectId,
        objectVerId : objectVerId
     }
     tpo.transactionPrimaryObject =  props.componentState.transactionPrimaryObject
     tpo.pagelevelValues = props.componentState.pagelevelValues
    await store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: {'LOB':response.LOB} })
    let MetadataResponse= await constructAndInvokeMetadataCall(subAppDetail,tpo)

  let request =  {}
  request =  await constructRequestValues(props,props.componentState.pagelevelValues,request,{...props.componentState,"pageMetadata": MetadataResponse},true,false);
  request={...request,ServiceRequestDetail:getServiceRequestDetail()}
  if (props.pageInfo.hasOwnProperty("EventMapping")) {
      Object.entries(props.pageInfo.EventMapping).forEach(([key, value]) => {
        request[key] = value
      });
    }
    let redux = store.getState();
    request["ID"]=redux.UIFlowReducer.quoteID;
    request["ProductNumber"] = redux.UIFlowReducer.productDetails.ProductNumber;
    request["ProductVerNumber"] = redux.UIFlowReducer.productDetails.ProductVerNumber;
    request["ProductVersionNumber"]= redux.UIFlowReducer.productDetails.ProductVerNumber;
    request["PolicyEffectiveDate"] = props.componentState.pagelevelValues.PolicyEffectiveDate;
    request["PolicyExpirationDate"] = props.componentState.pagelevelValues.PolicyExpirationDate;
    request["CarrierName"] = props.componentState.pagelevelValues.CarrierName;
    request["EndorsementEffectiveDate"] = props.componentState.pagelevelValues.EndorsementEffectiveDate;
    request["State"]=props.componentState.pagelevelValues.Location && (props.componentState.pagelevelValues.Location.State || props.componentState.pagelevelValues.Location.StateRegionCode);
    request["City"]=props.componentState.pagelevelValues.Location && props.componentState.pagelevelValues.Location.City;
    request["Country"] = props.componentState.pagelevelValues.Location && props.componentState.pagelevelValues.Location.Country;
    request["County"] =props.componentState.pagelevelValues.Location && props.componentState.pagelevelValues.Location.County;
    request["Zipcode1"] =props.componentState.pagelevelValues.Location && props.componentState.pagelevelValues.Location.Zipcode1;
    request["CarrierName"] =props.componentState.pagelevelValues && props.componentState.pagelevelValues.CarrierName;
    request["LOB"] = props.componentState.pagelevelValues.EndorsementProductSelection &&props.componentState.pagelevelValues.EndorsementProductSelection.LOB
    request.OwnerId = sessionStorage.getItem("OwnerID");
    await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request).then(response=>{
      //response
    })
    let dispatchPair= {}
    dispatchPair[`${props.pageInfo.pageName}_updatePageName`] = true
    store.dispatch({ type: actionType.SET_UI_VALUES, payload: dispatchPair });

  } catch (error) {
    console.log("Error in NCEndoRatingAPICall :: ", error)
  }
}

const SaveAdditionalInsured_Quote_Policy_Homeowners = async (key,subappName,values,boolean,ID,props, state,QuoteIDPolicyID) =>{
  try{
    let {UpdateState} = props
    UpdateState('loading',true,true)

    let request = constructTransactionRequest( state, props, state.pagelevelValues, true, false)
    let SubApplicationEntity=props.pageMetadata.ApplicationDetail.SubApplicationEntity[subappName]
    let redux = store.getState()
    request['ServiceRequestDetail']['Lob'] = props.productDetails.LOBName
    request[SubApplicationEntity] = {...request[SubApplicationEntity],...{ PARENTID : props.parentProps.pagelevelValues.Building.ID,
    ROOTID : QuoteIDPolicyID , ID : ID ? ID : ""}}
    request.TargetEventName = props.pageInfo.eventName.CreateAdditionalInterest
    request.ProductVerNumber = redux.UIFlowReducer.productDetails.ProductVerNumber;
    request.ProductNumber = redux.UIFlowReducer.productDetails.ProductNumber;
    request.EventName= GetCommonEventName()
    LocationandBuildingAxios.post("/core/invokeWorkflowEvent/V2", request).then(response=>{
      try{
        UpdateState(`${subappName.toLowerCase()}_dataList`,response.data.AdditionalInterest,false)
      }catch(error){
        console.log('API Response Error',error);
      }finally{
        UpdateState('loading',false,true)
      }
    })
  }catch(err){
      console.log('ERROR in SaveAdditionalInsured_Quote_Policy_Homeowners',err);
    }
  }

const SaveAdditionalInsured_Quote_Homeowners = async (key,subappName,values,boolean,ID,props, state) =>{
  try{
    SaveAdditionalInsured_Quote_Policy_Homeowners(key,subappName,values,boolean,ID,props, state,props.UIFlowReducer.quoteID )
  }catch(err){
      console.log('ERROR in SaveAdditionalInsured_Quote_Homeowners',err);
    }
  }

const deleteAdditionalInsured_Quote_Policy_Homeowners = async (key, value, SubApplnName,props, QuoteIDPolicyID, TargetEventName) =>{
  try{
    let {UpdateState} = props
    UpdateState('loading',true,true)
    let request = constructTransactionRequestV2(props.parentProps,props.componentProps,{},true,false)
    request.TargetEventName = TargetEventName
    request.EventName = GetCommonEventName()
    request.ROOTID = QuoteIDPolicyID
    Object.assign(request,value)
    delete request.PARENTID
    await AxiosInstance.post("core/invokeWorkflowEvent/V2",request).then(response=>{
      try{
        UpdateState(`${SubApplnName.toLowerCase()}_dataList`,response.data.AdditionalInterest,false)
      }catch(error){
        console.log('API Response Error',error);
      }finally{
        UpdateState('loading',false,true)
      }
    })
  }catch(err){
      console.log('ERROR in SaveAdditionalInsured_Quote_Homeowners',err);
    }
  }

const deleteAdditionalInsured_Quote_Homeowners = async (key, value, SubApplnName,props) =>{
  try{
    deleteAdditionalInsured_Quote_Policy_Homeowners(key, value, SubApplnName,props,props.componentProps.UIFlowReducer.quoteID,props.pageInfo.eventName.deleteNotesandAttachemnts)
  }catch(err){
      console.log('ERROR in SaveAdditionalInsured_Quote_Homeowners',err);
    }
  }

  const onSaveSubjectivity = async (key,subapp,values,boolean,ID,props, state) => { //make a common method by merging with onSaveNotesAndAttachments
    props.UpdateState('loading',true,true)
    let details={}
      details={TargetEventName:props.pageInfo.eventName.saveSubjectivity,ROOTID:props.UIFlowReducer.quoteID,PARENTID:props.UIFlowReducer.quoteID
      ,QuoteId:props.UIFlowReducer.quoteNumber,PolicyId:"",EventName: GetCommonEventName()}
      let request=await constructTransactionRequestV1(state,props,state.pagelevelValues,undefined,true,false,null)
    Object.assign(request,details)
    values["ID"]= ID !== undefined ? ID : ""
    request[subapp] = {...values}
    props.detailSubApp.forEach((value)=>{
      if(request.hasOwnProperty(value)){
        request[subapp] = {...request[subapp],...request[value]}
      }
     })
    request['OwnerId']=sessionStorage.getItem('OwnerID')
    request["ServiceRequestDetail"] =  getServiceRequestDetail()
    delete request.Subjectivity['uid']
    let response=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
    props.UpdateState("subjectivity_dataList",response.data.Subjectivity,false)
    props.UpdateState('loading',false,true)
  };

const updateEmailList = async(propsObj,stateObj,EventName,id,UpdateState) => {
  let response;
  response = await getEmail(propsObj,stateObj,EventName,id);
  if(response && response.data && response.data.Email && response.data.Email.length > 0){
    UpdateState("emailsummary_dataList",response.data.Email,true)
  }
  return response;
}

const componentDidMount_QuoteDetails = async (state,props,UpdateState)=>{
  try{
    UpdateState('loading',true,true)
    let redux = store.getState()
    let {getQuoteListTargetEventName,getQuoteListSearchName}= props.pageInfo
    let getEmailEventname = props.pageInfo.getEmailList
    let pollEmail = props.pageInfo.pollEmail
    let getQuoteResponse=await getQuoteData(props,props.pageInfo.CreateQuoteTargetEventName,UpdateState);
    props.setUpdateExpandPanel && props.setUpdateExpandPanel(true);
    await Promise.all([store.dispatch({type: actionTypes.GET_AGENCY_DETAILS, payload: getQuoteResponse.data.AgencyInformation}),
      store.dispatch({type: actionTypes.GET_AGENT_DETAILS, payload: getQuoteResponse.data.AgentInformation}),
      store.dispatch({type: actionTypes.SET_INSUREDID, payload: getQuoteResponse.data.InsuredID}),
      store.dispatch({type:actionType.SET_SUBMISSION_STATUS,payload: getQuoteResponse.data.SubmissionStatus}),
      store.dispatch({type:actionType.SET_SUBMISSIONID,payload: getQuoteResponse.data.SubmissionID})
      ])
    const policyID =  getQuoteResponse.data.PolicyID
    if(policyID){
      store.dispatch({type:actionType.SET_POLICY_ID,payload: policyID})
    }

    let getQuoteListResponse = getQuoteListTargetEventName && await getQuoteList(props,getQuoteListTargetEventName,getQuoteListSearchName)
    let{transactionPrimaryObject}=state
    if(redux.metadataReducer.route!== "Endorsement"){
      transactionPrimaryObject["IssueBinderNavigator"]="false";
    }
    if(getQuoteResponse.data.Location){
      transactionPrimaryObject["StateId"] = getQuoteResponse.data.Location.StateRegionCode;
    }
    transactionPrimaryObject["totalPremium"]=getQuoteResponse.data.TotalPremium;
      transactionPrimaryObject["DiligentEffortInformation"] = getQuoteResponse.data.DiligentEffort || {};
    // let response = await componentDidMount_Orchid(state,props,{...transactionPrimaryObject,...getQuoteResponse.data})

    let response = await componentDidMount_Orchid(state,props, {...transactionPrimaryObject,...getQuoteResponse.data});
    let getEmailList = getEmailEventname && await getEmail(props,response,getEmailEventname,getQuoteResponse.data.ID)
    if(pollEmail){
    let intervalData;
    let intervalSec = 1000 * props.pageInfo.intervalSec;
    let timeOutSec = 1000 * props.pageInfo.timeOutSec;
    intervalData = setInterval(function() {
      getEmailList = updateEmailList(props,response,getEmailEventname,getQuoteResponse.data.ID,UpdateState);
    },intervalSec)
    UpdateState('intervalData',intervalData,false)
    setTimeout(() => {
      clearInterval(intervalData);
    }, timeOutSec);
    }
    let translatedResponse=await EntitySubAppTranslationV2({...getQuoteResponse.data},{...response.pageMetadata.ApplicationDetail})
    let temp={}
    temp.ruleinformationlist_dataList=getQuoteResponse.data.RuleInformationList || []
    temp.summarytaxdetailssp_dataList=getQuoteResponse.data.Tax || []
    temp.summaryfeedetailssp_dataList=getQuoteResponse.data.Fee || []
    temp.ceaseinformationlist_dataList = (getQuoteResponse.data.CeaseInformationList && getQuoteResponse.data.CeaseInformationList)|| []
    temp.ceaseinformationlistinpolicy_dataList = (getQuoteResponse.data.CeaseInformationList && getQuoteResponse.data.CeaseInformationList) || []
    temp.quotelist_dataList = getQuoteListResponse || []
    temp.emailsummary_dataList =getEmailList && getEmailList.data && getEmailList.data.Email || []
    await mergeRowdatawithDatalist(response.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{});

    if(props.productDetails && props.productDetails.LOBName==='MPL'){
      return {
        ...response,
        loading: false,
        pagelevelValues : translatedResponse,
        formsmpl_dataList:getQuoteResponse.data.FormsMPL,
        documentsmpl_dataList : getQuoteResponse.data.DocumentsMPL,
        ruleinformationlist_dataList:getQuoteResponse.data.RuleInformationList,
        quotelist_dataList : getQuoteListResponse,
        subjectivity_dataList: getQuoteResponse.data.Subjectivity
      }
    }

    return {
      loading: false,
      detailSubApp: response.detailSubApp,
      detailSummaryList : response.detailSummaryList,
      subappNameList : response.subappNameList,
      summarySubApp : response.summarySubApp,
      pageMetadata : response.pageMetadata,
      pagelevelValues : {...transactionPrimaryObject,...getQuoteResponse.data,...translatedResponse},
      forms_dataList:getQuoteResponse.data.Forms ? getQuoteResponse.data.Forms : [],
      documents_dataList : getQuoteResponse.data.Documents ? getQuoteResponse.data.Documents : [],
      notes_dataList:getQuoteResponse.data.Notes ? getQuoteResponse.data.Notes : [],
      ruleinformationlist_dataList:getQuoteResponse.data.RuleInformationList ? getQuoteResponse.data.RuleInformationList : [],
      attachments_dataList:getQuoteResponse.data.Attachments ? getQuoteResponse.data.Attachments : [],
      policyattachments_dataList: getQuoteResponse.data.PolicyAttachments ? getQuoteResponse.data.PolicyAttachments : [],
      quotelist_dataList : getQuoteListResponse,
      emailsummary_dataList : getEmailList && getEmailList.data ? getEmailList.data.Email : [],
      summarytaxdetailssp_dataList:getQuoteResponse.data.Tax ? getQuoteResponse.data.Tax : [],
      summaryfeedetailssp_dataList:getQuoteResponse.data.Fee ? getQuoteResponse.data.Fee : [],
      ceaseinformationlist_dataList : getQuoteResponse.data.CeaseInformationList ? getQuoteResponse.data.CeaseInformationList : [],
      ceaseinformationlistinpolicy_dataList : getQuoteResponse.data.CeaseInformationList ? getQuoteResponse.data.CeaseInformationList : [],
      transactionPrimaryObject : {...transactionPrimaryObject,...getQuoteResponse.data,...translatedResponse}
    }
  }catch(err){
    UpdateState('loading',false,true)
    console.log('Error in componentDidMount_QuoteDetails',err)
  }
}


const componentDidMount_InsuredV2=async(state,props,UpdateState)=>{
  try{
    UpdateState("loading",true,true)
    let res,eventName;
    const route = props.location.pathname.split('/')[1]
    let IsPolicyData = route ==='policy'?true:false
    if(IsPolicyData){
      eventName = props.pageInfo.getPolicyTargetEventName
      res =await getPolicyData(props,eventName)
    }else{
      eventName = props.pageInfo.CreateQuoteTargetEventName
      res=await getQuoteData(props,eventName,UpdateState)
    }
    let transactionPrimaryObject = state.transactionPrimaryObject ? state.transactionPrimaryObject : {};
    let subAppDetail= {
      subappName:props.subApplication,
      objectId : props.objectId,
      objectVerId : props.objectVerId
   }
    let metaResponse=await constructAndInvokeMetadataCall(subAppDetail,{"transactionPrimaryObject":{...transactionPrimaryObject,...res.data}});
    let responseFromTranslation=await EntitySubAppTranslationV2({...res.data},{...metaResponse.ApplicationDetail});
    let metadataResponse=await componentDidMount_Orchid(state,props,{...transactionPrimaryObject,...responseFromTranslation,...res.data});
    const obj ={"pagelevelValues":{...state.pagelevelValues,...responseFromTranslation},...metadataResponse,"transactionPrimaryObject":{...state.transactionPrimaryObject,...responseFromTranslation},"loading":false}
    return obj;
  }
  catch(e){
    console.log("error in componentDidMount_Insured",e);
  }
}

const componentDidMount_LocationV2=async(state,props,UpdateState)=>{
  try{
    UpdateState("loading",true,true)
    let res,eventName;
    const route = props.location.pathname.split('/')[1]
    let IsPolicyData = route ==='policy'?true:false
    if(IsPolicyData){
      eventName = props.pageInfo.getPolicyTargetEventName
      res =await getPolicyData(props,eventName)
    }else{
      eventName = props.pageInfo.CreateQuoteTargetEventName
      res=await getQuoteData(props,eventName,UpdateState)
    }
    let transactionPrimaryObject = state.transactionPrimaryObject
    let subAppDetail= {
      subappName:props.subApplication,
      objectId : props.objectId,
      objectVerId : props.objectVerId
   }
    let metaResponse=await constructAndInvokeMetadataCall(subAppDetail,{"transactionPrimaryObject":{...transactionPrimaryObject,...res.data}});
    let responseFromTranslation=await EntitySubAppTranslationV2({...res.data},{...metaResponse.ApplicationDetail});
    let Values=await componentDidMount_Orchid(state,props,{transactionPrimaryObject,...res.data,...responseFromTranslation});
    Values["pagelevelValues"]={...state.pagelevelValues,...res.data,...responseFromTranslation};
    Values["additionalinformation_dataList"]=res.data.AdditionalInterest||[];
    Values["claimshistory_dataList"]=res.data.LossHistory||[];
    Values["transactionPrimaryObject"]={transactionPrimaryObject,...res.data,...responseFromTranslation}
    Values["scheduleofpersonalproperty_dataList"]=res.data.PersonalProperty||[];
    Values["BuildingID"]=res.data.Building && res.data.Building["ID"];
    UpdateState("loading",false,true)
    return Values;
  }
  catch(e){
    UpdateState("loading",false,true)
    console.log("error in componentDidMount_Location",e);
  }
}

const componentDidMount_CoverageDetailsV2=async(state,props,UpdateState)=>{
  try{
    UpdateState("loading",true,true)
    let transactionPrimaryObject = state.transactionPrimaryObject ? state.transactionPrimaryObject : {};
    let res,eventName;
    await store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: true })
    const route = props.location.pathname.split('/')[1]
    let IsPolicyData = route ==='policy'?true:false
    if(IsPolicyData){
      eventName = props.pageInfo.getPolicyTargetEventName
      res =await getPolicyData(props,eventName)
    }else{
      eventName = props.pageInfo.CreateQuoteTargetEventName
      res=await getQuoteData(props,eventName,UpdateState)
    }
    let selectedSubline = props.pageInfo && props.pageInfo.selectedSubline && props.pageInfo.selectedSubline;
    let neededSubapps = []
    selectedSubline && Object.keys(selectedSubline).length > 0 && Object.entries(selectedSubline).forEach(([key,value])=>{
      if(key === res.data.SublineName){
        neededSubapps.push(value)
      }
    })
    let subAppList = props.pageInfo && props.pageInfo.iteratorSubappList && props.pageInfo.iteratorSubappList.length && neededSubapps.length ? [...props.pageInfo.iteratorSubappList, ...neededSubapps] : neededSubapps.length ? neededSubapps : []
    let subAppDetail= {
      subappName:subAppList.length ? subAppList : props.subApplication,
      objectId : props.objectId,
      objectVerId : props.objectVerId
    }
    let updatedProps = { ...props, subApplication: subAppDetail.subappName };
    let metadataResponse=await constructAndInvokeMetadataCall(subAppDetail,{"transactionPrimaryObject":{...transactionPrimaryObject,...res.data}});
    let Translatedresponse=await EntitySubAppTranslationV2({...res.data},{...metadataResponse.ApplicationDetail});
    let response=await componentDidMount_Orchid(state,updatedProps,{...state.transactionPrimaryObject,...res.data,...Translatedresponse});
    let temp={};
    temp.summarytaxdetails_dataList=res.data.Tax;
    temp.summaryfeedetails_dataList=res.data.Fee;
    let dataList={}
    let childDatalist = {}
    let dataListName = props.pageInfo.dataListName ? props.pageInfo.dataListName : []
    res.data && res.data.Coverage && Object.keys(res.data.Coverage).forEach(
        (key) => {
          if(dataListName.includes(key)){
            childDatalist[`${key.toLowerCase()}_dataList`] = res.data.Coverage[key]
          }
        }
    )
    await mergeRowdatawithDatalist(response.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,dataList);
    response={...response,...dataList,...childDatalist};
    response["pagelevelValues"]={...state.pagelevelValues,...res.data,...Translatedresponse};
    response["transactionPrimaryObject"]={...state.transactionPrimaryObject,...res.data,...Translatedresponse};
    UpdateState('loading',false,true)
    return response;
  }
  catch(e){
    UpdateState('loading',false,true)
    console.log("error in componentDidMount_CoverageDetails",e);
  }
}

const componentDidMount_UW_WorksheetV2=async(state,props,UpdateState)=>{
  try{
    UpdateState("loading",true,true)
    let subAppDetail= {
      subappName:props.subApplication,
      objectId : props.objectId,
      objectVerId : props.objectVerId
   }
   let transactionPrimaryObject = state.transactionPrimaryObject ? state.transactionPrimaryObject : {};
   let res=await getQuoteData(props,props.pageInfo.CreateQuoteTargetEventName,UpdateState);
   let metadataResponse=await constructAndInvokeMetadataCall(subAppDetail,{"transactionPrimaryObject":{...transactionPrimaryObject,...res.data}});
    let responseFromTranslation=await EntitySubAppTranslationV2({...res.data},{...metadataResponse.ApplicationDetail})
    let Values=await componentDidMount_Orchid(state,props,{...state.transactionPrimaryObject,...res.data,...responseFromTranslation});
    let temp={};
    temp.summarytaxdetails_dataList=res.data.Tax;
    temp.summaryfeedetails_dataList=res.data.Fee;
    let dataList={}
    Values["pagelevelValues"] = {...res.data,...responseFromTranslation}
    Values["transactionPrimaryObject"] = {...res.data,...responseFromTranslation}
    await mergeRowdatawithDatalist(Values.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,dataList);
    metadataResponse={...Values,...dataList};
    UpdateState("loading",false,true)
    return metadataResponse;
  }
  catch(e){
    UpdateState("loading",false,true)
   console.log("error in componentDidMount_UW_Worksheet",e);
  }
}
const componentDidMount_InspectionDetailsV2 = async(state,props,UpdateState)=>{
  try{
    UpdateState("loading",true,true)
    let metadataResponse=await constructAndInvokeMetadataCall(props);
    let res=await getPolicyData(props,props.pageInfo.getPolicyTargetEventName);
    let Translatedresponse=await EntitySubAppTranslationV2({...res.data},{...metadataResponse.ApplicationDetail});
    let translatedRes=await constructTransactionRequestV1({pageMetadata:metadataResponse},props,{...Translatedresponse},false,true,false)
    let response=await componentDidMount_Orchid(state,props,{"transactionPrimaryObject":{...translatedRes,...res.data}});
    let temp={};
    let dataList={}
    await mergeRowdatawithDatalist(response.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,dataList);
    response={...response,...dataList};
    response["transactionPrimaryObject"] = {...Translatedresponse,...res.data}
    response["pagelevelValues"]={...Translatedresponse};
    response["isSaveBtn"] = true;
    UpdateState("loading",false,false)
    return response;
  }
  catch(e){
    console.log("error in componentDidMount_InspectionDetailsV2",e);
  }
}

const componentDidMount_Endorsement_detailsV2=async(state,props,UpdateState)=>{
  try{
    UpdateState("loading",true,true)
    let {getPolicyTargetEventName,getQuoteListTargetEventName,getQuoteListSearchName} =
    props.pageInfo;
    let getPolicyResponse= await getPolicyData(props,getPolicyTargetEventName)
    let tpo={
      "addEndorsementClicked": "false",
      "IssueBinderNavigator":"false",
      ...getPolicyResponse.data
    }
    let getQuoteListResponse = await getQuoteList(props,getQuoteListTargetEventName,getQuoteListSearchName)
    let request={}
    request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request.ServiceRequestDetail['Lob'] = props.productDetails.LOBName
    request.SubmissionID = props.UIFlowReducer.submissionID;
    request.EventName=props.pageInfo.eventName.getEndorsementList;
    request.PolicyID=props.UIFlowReducer.policyID;
    const endorsementListResponse= await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
    const response = await componentDidMount_Orchid(state,props,tpo);
    let translatedResponse=await EntitySubAppTranslationV2({...getPolicyResponse.data},{...response.pageMetadata.ApplicationDetail})
    let temp={}
    temp.endorsementlist_dataList = endorsementListResponse.data.Quote
    props.setUpdateExpandPanel && props.setUpdateExpandPanel(true)
    temp.policylist_dataList = getQuoteListResponse
    await mergeRowdatawithDatalist(response.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{})
    return {
      loading:false,
      endorsementlist_dataList : endorsementListResponse.data.Quote,
      detailSubApp: response.detailSubApp,
      detailSummaryList : response.detailSummaryList,
      policylist_dataList : getQuoteListResponse,
      subappNameList : response.subappNameList,
      summarySubApp : response.summarySubApp,
      pageMetadata : response.pageMetadata,
      pagelevelValues : translatedResponse,
      summaryfeedetailssp_dataList : getPolicyResponse.data.Fee,
      summarytaxdetailssp_dataList : getPolicyResponse.data.Tax
    }
  }catch(e){
    UpdateState("loading",false,true)
    console.log("Error in componentDidMount_Endorsement_details",e);
  }
}

const componentDidMount_EndorsementDetails= async(state,props,UpdateState)=>{
  try{
    UpdateState("loading",true,true)
    let {objectId,objectVerId,subApplication} = props.pageInfo;
    let subAppInfo = {
      objectId : objectId,
      objectVerId : objectVerId,
      subApplication : subApplication,
      subappName: subApplication
    }
    let {response,translatedResponse,getQuoteResponse,transactionPrimaryObject}=await endorsementInformation(state,subAppInfo,props,UpdateState)
    let getEmailList = await getEmail(props,response,props.pageInfo.eventName.getEmailList,props.UIFlowReducer.quoteID);
    props.setUpdateExpandPanel && props.setUpdateExpandPanel(true);
    const {PolicyEffectiveDate,PolicyExpirationDate}=getQuoteResponse.data;
    return {
      loading : false,
      detailSubApp: response.detailSubApp,
      detailSummaryList : response.detailSummaryList,
      subappNameList : response.subappNameList,
      summarySubApp : response.summarySubApp,
      pageMetadata : response.pageMetadata,
      pagelevelValues : {...transactionPrimaryObject,...translatedResponse},
      documents_dataList : getQuoteResponse.data.Documents,
      notes_dataList:getQuoteResponse.data.Notes,
      policyattachments_dataList: getQuoteResponse.data.PolicyAttachments,
      emailsummary_dataList : getEmailList.data.Email,
      ceaseinformationlistinpolicy_dataList : getQuoteResponse.data.CeaseInformationList ? getQuoteResponse.data.CeaseInformationList : [],
      transactionPrimaryObject:{...transactionPrimaryObject,PolicyEffectiveDate,PolicyExpirationDate}
    }
  } catch(err){
    UpdateState("loading",false,true)
    console.log('Error in componentDidMount_EndorsemetemplateClassNamentDetails',err)
  }
}

const componentDidMount_EndorsementSummary= async(state,props,UpdateState)=>{
  try{
    UpdateState("loading",true,true)
    let res=await getQuoteData(props,props.pageInfo.CreateQuoteTargetEventName,UpdateState);
    Object.entries(state.transactionPrimaryObject).map(([key,val],ind)=>{
      if(key.includes('__ValuesChanged')){
        delete state.transactionPrimaryObject[key]
      }
    })
    let temp={}
      temp.ceaseinformationlist_dataList=res.data.CeaseInformationList || []
      let response=await componentDidMount_Orchid(state,props,{...state.transactionPrimaryObject,...res.data});
      await mergeRowdatawithDatalist(response.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{});
    let responseFromTranslation=await EntitySubAppTranslationV2({...res.data},{...response.pageMetadata.ApplicationDetail});
    return {
      ...response,
      "loading":false,
      "templateClassName":"EndorsementTemplate",
      "pagelevelValues":{...responseFromTranslation},
      "ceaseinformationlist_dataList" : res.data.CeaseInformationList ? res.data.CeaseInformationList : [],
  };
    }catch(e){
      UpdateState('loading',false,true)
      console.log("Error in endorsementSummaryDetails",e);
    }
}

const updateArrayValueOnState = async (props,key, value, expand, id, subname) => {
    let arr = [];
    value['isExpanded'] = expand;
    let {ID , uid} = value;
    let checkKey = ID ? 'ID' : uid ? 'uid' : null ;

    if (props.parentProps[key] !== undefined && Array.isArray(props.parentProps[key])) {
      arr = [...props.parentProps[key]];
    }
    if( (checkKey === 'ID' && ID !== undefined) || (checkKey === 'uid' && uid !== undefined) ){
      let updated = false;
      await arr.forEach((el, idx) => {
        if (el[checkKey] === value[checkKey]) {
          arr[idx] = { ...value };
          updated = true;
        }
      })
      if (updated === false) {
        arr.push(value);
      }
    } else {
      arr.push(value);
    }
    let pagelevelValues = Object.assign({}, props.parentProps.pagelevelValues);
    if (subname) {
      pagelevelValues[subname] = arr;
    }
    pagelevelValues[key] = arr;
    props.UpdateState(`${key}`,arr,true)
  }

  const addRow = (key,props) => {
    let uid = uuidv4();
    updateArrayValueOnState(props,key, { uid, isExpanded: "true" }, "true");
  };

  const onExpand = (key, value, isExpand, props) => {
    let { ID, uid } = value;
    let checkKey = ID ? "ID" : uid ? "uid" : null;
    if (checkKey !== null) {
        let arr = props.parentProps[key];
        arr.forEach((el, idx) => {
          if (el[checkKey] === value[checkKey]) {
            arr[idx]["isExpanded"] = isExpand.toString();
            return arr;
          }
        });
        props.UpdateState(`${key}`,arr,true)
    }
  };

const onSaveNotesAndAttachments = async (key,subapp,values,boolean,ID,props,state) => {
  try{
      props.UpdateState('loading',true,true)
      let details={}
    let routeInfo = store.getState().metadataReducer.route ? store.getState().metadataReducer.route:''
    if(routeInfo==='Quote'){
      details={TargetEventName:props.pageInfo.eventName.saveNotes,ROOTID:props.UIFlowReducer.quoteID,PARENTID:props.UIFlowReducer.quoteID
      ,QuoteId:props.UIFlowReducer.quoteNumber,PolicyId:"",EventName:GetCommonEventName()}
    }else if(routeInfo==='Policy'){
      details={TargetEventName:props.pageInfo.eventName.saveNotes,ROOTID:props.UIFlowReducer.policyID,PARENTID:props.UIFlowReducer.policyID
        ,QuoteId:"",PolicyId:props.UIFlowReducer.policyID,EventName:GetCommonEventName()}
    }else if(routeInfo==='Endorsement'){
      details={TargetEventName:props.pageInfo.eventName.saveNotes,ROOTID:props.UIFlowReducer.quoteID,PARENTID:props.UIFlowReducer.quoteID
        ,QuoteId:props.UIFlowReducer.quoteID,PolicyId:"",EventName:GetCommonEventName()}
    }
    if (props.pageInfo.ProductDetailsNeeded) {
      details.ProductNumber = props.productDetails.ProductNumber;
      details.ProductVerNumber = props.productDetails.ProductVerNumber;
    }
    let request=await constructTransactionRequestV1(state,props,state.pagelevelValues,undefined,true,false,null)
    Object.assign(request,details)
    request[`${subapp}`]['ID']= ID !==undefined ? ID : ""
    request['OwnerId']=sessionStorage.getItem('OwnerID')
    request["ServiceRequestDetail"] =  getServiceRequestDetail()
    let reduxValues = store.getState();
    if (props.pageInfo && props.pageInfo.officeDetailsNeeded) {
      let {OfficeDetailsRequestKey = [], OfficeDetailsValues = []} = props.pageInfo
      let {userInfoReducer} = reduxValues
      if (OfficeDetailsRequestKey.length > 0 && OfficeDetailsValues.length > 0) {
        let OfficeDetailsRequestKeyLength = OfficeDetailsRequestKey.length
        for (let i = 0; i < OfficeDetailsRequestKeyLength; i++) {
          request[OfficeDetailsRequestKey[i]] = userInfoReducer.OfficeMappingDetails && userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] ? userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] : ''
        }
      }
    }
    delete request.Notes['isExpanded']
    let response=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
    props.UpdateState("notes_dataList",response.data.Notes,false)
    props.UpdateState('loading',false,true)
  }catch(err){
    props.UpdateState('loading',false,true)
    console.log('Error in onSaveNotesAndAttachments',err)
  }
};

const onSaveAttachments=async (key,subapp,values,boolean,ID,props, state)=>{
  try{
    props.UpdateState('loading',true,true);
    let Attachments, fileUploadResponse;
    if(!values || !values.ID || values.attachedFile){
      const formData = new FormData();
      formData.append("uploadFileData", state.attachedFile, state.attachedFile.name);
      fileUploadResponse = await AxiosInstance.post("/core/fileUpload?pathVariable=ATTACHMENTS_POLICY", formData);
      values["ID"]= ID !== undefined ? ID : ""
      delete values["uid"]
      Attachments = {[`${subapp}`] : {...values, AttachmentUrl: fileUploadResponse.data.newpath, AttachmentFileName: fileUploadResponse.data.newFileName}}
    }
    else {
      Attachments = {[`${subapp}`] : {...values}}
    }
    if(!fileUploadResponse || (fileUploadResponse.statusText==='OK' || fileUploadResponse.status === 200)) {
      let details={}
      let routeInfo = store.getState().metadataReducer.route? store.getState().metadataReducer.route :''
      if(routeInfo==='Quote' || routeInfo==='Cancel'){
        details={TargetEventName:props.pageInfo.eventName.saveAttachments,...Attachments,ROOTID:props.UIFlowReducer.quoteID,PARENTID:props.UIFlowReducer.quoteID
          ,QuoteId:props.UIFlowReducer.quoteID,PolicyId:"",EventName:GetCommonEventName()}
      }else if(routeInfo==='Policy'){
        details={TargetEventName:props.pageInfo.eventName.saveAttachments,...Attachments,ROOTID:props.UIFlowReducer.policyID,PARENTID:props.UIFlowReducer.policyID
          ,QuoteId:"",PolicyId:props.UIFlowReducer.policyID,EventName:GetCommonEventName()}
      }else if(routeInfo==='Endorsement'){
        details={TargetEventName:props.pageInfo.eventName.saveAttachments,...Attachments,ROOTID:props.UIFlowReducer.quoteID,PARENTID:props.UIFlowReducer.quoteID
          ,QuoteId:props.UIFlowReducer.quoteID,PolicyId:"",EventName:GetCommonEventName()}
      }
      if(props.componentProps.productDetails && props.componentProps.productDetails.ProductNumber && props.componentProps.productDetails.ProductVerNumber){
      details.ProductVerNumber = props.componentProps.productDetails.ProductVerNumber;
      details.ProductNumber  = props.componentProps.productDetails.ProductNumber;}
      let request=constructTransactionRequestV2(props,props.componentProps,values,true,false)
      Object.assign(request,details)
      let reduxValues = store.getState();
      if (props.pageInfo && props.pageInfo.officeDetailsNeeded) {
        let {OfficeDetailsRequestKey = [], OfficeDetailsValues = []} = props.pageInfo
        let {userInfoReducer} = reduxValues
        if (OfficeDetailsRequestKey.length > 0 && OfficeDetailsValues.length > 0) {
          let OfficeDetailsRequestKeyLength = OfficeDetailsRequestKey.length
          for (let i = 0; i < OfficeDetailsRequestKeyLength; i++) {
            request[OfficeDetailsRequestKey[i]] = userInfoReducer.OfficeMappingDetails && userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] ? userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] : ''
          }
        }
      }
      let response=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
      props.UpdateState('policyattachments_dataList', response.data.PolicyAttachments,false)
      props.UpdateState('loading',false,true)
  }
}
  catch(error){
    console.log("Error in onSaveAttachments",error)
  }
  }

  const findRowIndex = (statename, rowInfo, key, list) => {
    let rowIndex;
    list.map((row, index) => {
        if (row[key] === rowInfo[key]) {
            rowIndex = index;
        }
    });
    return rowIndex;
}


  const onDeleteNotesandAttachments=async (key, value, SubApplnName,props,ID,eventname)=>{
    try{
      if(value && value.IsEditable && value.IsEditable==="N"){
        triggerInfoToast(props.componentProps.UIFlowReducer.currentPageInfo[`${SubApplnName}Message`])
        return;
      }
      if(value.hasOwnProperty("uid") && !value.hasOwnProperty("ID")){
        let list = props.parentProps && props.parentProps[key] && JSON.parse(JSON.stringify(props.parentProps[key]))
        let currentRowIndex = findRowIndex(key , value, "uid", list);
        list.splice(currentRowIndex, 1);
        props.parentProps[key] && props.UpdateState(key,list,true)
        return;
      }
      props.UpdateState('loading',true,true)
      let request=constructTransactionRequestV2(props.componentState,props.componentProps,{},true,false)
      request.TargetEventName = eventname
      request.EventName = GetCommonEventName();
      request.ROOTID = ID
      Object.assign(request,value)
      delete request.PARENTID
      let response=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
      if(props.componentState.pageMetadata.ApplicationDetail.SubApplicationEntity[SubApplnName]!==""){
        SubApplnName=props.componentState.pageMetadata.ApplicationDetail.SubApplicationEntity[SubApplnName]
      }
      if(response.data.hasOwnProperty("ResponseStatus") && (response.data.ResponseStatus === "Failed")){
        triggerToast("API Response Failed")
        props.UpdateState('loading',false,true)
        return;
      }
      props.UpdateState(key,response.data[`${SubApplnName}`],false)
      props.UpdateState('loading',false,true)
    }catch(e){
      console.log("Error in Delete Policy Object",e)
    }
  }

  const onDeleteNotesandAttachmentsQuote= async (key, value, SubApplnName,props) =>{
    try{
      onDeleteNotesandAttachments(key, value, SubApplnName,props,props.componentProps.UIFlowReducer.quoteID,props.pageInfo.eventName.deleteNotesandAttachemnts)
    }catch(err){
      console.log('Error in DeleteQuoteObject',err);
    }
  }

  const onDeleteNotesandAttachmentsPolicy= async (key, value, SubApplnName,props) =>{
    try{
      onDeleteNotesandAttachments(key, value, SubApplnName,props,props.componentProps.UIFlowReducer.policyID,props.pageInfo.eventName.deleteaddInsuredPolicy)
    }catch(err){
      console.log('Error in DeletePolicyObject',err);
    }
  }

  const onSaveBusinessRule= async(eventName,propsobj,stateobj,component,functions,e,props)=>{
    try{
    functions.UpdateState('loading',true,true)
    let details={TargetEventName:props.pageInfo.eventName.saveBusinessRule,
    [`${component.AttributeName}`]:stateobj[`${component.AttributeName.toLowerCase()}_dataList`],EventName:GetCommonEventName(),
    [`${component.AttributeParams}`]:stateobj.pagelevelValues[`${component.AttributeParams}`]}
    let request=constructTransactionRequestV2(stateobj,props.componentProps,{},true,false)
    Object.assign(request,details)
    request.ROOTID=props.componentProps.UIFlowReducer.quoteID
    request[`${component.AttributeName}`].map((val)=>{
      return  val.ROOTID=props.componentProps.UIFlowReducer.quoteID
    })
    deleteRowMetaData(request[`${component.AttributeName}`])
    await AxiosInstance.post("core/invokeWorkflowEvent/V2",request).then(async(response)=>{
      if(response.hasOwnProperty('ResponseStatus') && response.ResponseStatus.toLowerCase() === "failed"){
        triggerToast("Failed to Save Business Rules.",  { position: Toast.POSITION.TOP_RIGHT})
        console.log("Response Failed");
      }else{
        let subappInfo = {
          objectId : props.pageInfo.objectId,
          objectVerId : props.pageInfo.objectVerId,
          subappName : props.componentState.subappNameList
        }
        let metadataResponse = await constructAndInvokeMetadataCall(subappInfo,{transactionPrimaryObject:{...stateobj.pagelevelValues,...stateobj.transactionPrimaryObject,...response.data}})
        functions.UpdateState("pageMetadata",metadataResponse,false)
        functions.UpdateState('transactionPrimaryObject',{...stateobj.pagelevelValues,...stateobj.transactionPrimaryObject,...response.data},false)
        functions.UpdateState('pagelevelValues',{...stateobj.transactionPrimaryObject,...stateobj.pagelevelValues,...response.data},false)
        let temp={}
        temp.ruleinformationlist_dataList=response.data[`${component.AttributeName}`]
        await mergeRowdatawithDatalist(stateobj.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{})
        functions.UpdateState(`${component.AttributeName.toLowerCase()}_dataList`,response.data[`${component.AttributeName}`],false)
        successToast("Business Rules Saved Successfully");
      }
    })
  }catch(e){
    console.log("Error in Save business Rules",e);
  }finally{
    functions.UpdateState('loading',false,true)
  }
  }

  const saveTransactionCeaseReasons=async(e,props,state,component,functions)=>{
    try{
      component.UpdateState("loading",true,true)
      let details={TargetEventName:props.eventName.saveTransactionCeaseReasons,
      [`${component.AttributeName}`]:state[`${component.AttributeName.toLowerCase()}_dataList`]}
      let request = await constructTransactionRequestV2(state,props,{},false,false);
      Object.assign(request,details)
      request.ROOTID=props.UIFlowReducer.quoteID
      request.EventName=GetCommonEventName();
      deleteRowMetaData(request[`${component.AttributeName}`])
      let response = await AxiosInstance.post("core/invokeWorkflowEvent/V2", request);
      if(response.data.hasOwnProperty("ResponseStatus") && response.data.ResponseStatus.toLowerCase() === "failed"){
        triggerToast("Error in Save Cease Information")
        console.log("Response Failed");
      }else{
      let temp={}
       temp[`${component.AttributeName.toLowerCase()}_dataList`]=response.data[`${component.AttributeName}`]
      await mergeRowdatawithDatalist(state.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{})
      component.UpdateState(`${component.AttributeName.toLowerCase()}_dataList`,response.data[`${component.AttributeName}`],false)
      if(response.data.hasOwnProperty(`QuoteStatus`) && response.data.QuoteStatus === "Declined"){
        await quoteDetailsNavigator_HomeOwners(e,props,state,component,component,"true")
      }
    }
    }
    catch(error){
      console.log("Error while saving the Transaction Cease Reasons",error);
    }finally{
      component.UpdateState("loading",false,true)
    }
    }
  const lapseDecline_QuoteOrchid = async(eventName,propsObj,stateObj,component,functions,e,props)=>{
    functions.UpdateState("lapseDeclineStatus",component.AttributeName,false)
    functions.UpdateState("LapseOrDecline__popupStatus",true,true)
  }

  const lapseDeclineClose = async(eventName,propsObj,stateObj,component,functions,e,props)=>{
    props.functions.handleClosePopup(false)
  }

  const lapseOrDeclineConfirm=async(eventName,propsObj,stateObj,component,functions,e,props)=>{
    let redux = store.getState();
    let {state,componentProps} = props
    let {handleClosePopup} = functions
    let {UpdateState} = props.componentProps.parentState
    UpdateState("loading",true,true);
    let request = {};
    request = Object.assign(request,props.pagelevelValues)
    request["ServiceRequestDetail"] = getServiceRequestDetail();
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.EventName =  GetCommonEventName();
    request.TargetEventName = eventName
    request.ID = store.getState().UIFlowReducer.quoteID;
    request = {...request,...redux.UIFlowReducer.productDetails}
    let response = await createQuoteAxios.post("/core/invokeWorkflowEvent/V2", request)
    let QuoteStatus = response.data.QuoteStatus
    await store.dispatch({
      type: actionType.SET_QUOTE_STATUS,
      payload: {
        QuoteStatus,
      }
    });
    const navigationReducer = store.getState().navigationReducer
    const metadataReducer = store.getState().metadataReducer
    const UIFlowReducer = store.getState().UIFlowReducer
    props = {...props,UIFlowReducer,navigationReducer,metadataReducer}
    let newProps ={
      subApplication : props.componentProps.Template[0].subApplication,
      objectId : props.componentProps.Template[0].objectId,
      objectVerId : props.componentProps.Template[0].objectVerId
    }
    let detailResponse={};
    let routeInfo = store.getState().metadataReducer.route ? store.getState().metadataReducer.route:''
    if(routeInfo=='Endorsement'){
      detailResponse = await endorsementDetailInformation(componentProps,newProps,props, UpdateState)
    }
    else if(routeInfo==='Quote'){
      let dynamicProps={"pageInfo":props.componentProps.Template[0]}
      detailResponse = await quoteDetailInformation(componentProps, dynamicProps, null, null, UpdateState,newProps)
    }
    const keyValuePairs = Object.entries(detailResponse)
    await keyValuePairs.forEach((value,i)=>{
      let updateFlag = (keyValuePairs.length)-1===i
       value[1] && UpdateState(value[0],value[1],updateFlag)
    })
    UpdateState("loading",false,true);
        handleClosePopup(false)
}

const onQuoteNumberClick = async (propsObj,stateObj,component,e,props)=>{
  let {customTableRowIndex,componentProps,UpdateState} = props
  UpdateState("loading",true,true)
  const quoteID = customTableRowIndex.ID
  await store.dispatch({
    type: actionType.SET_QUOTE_ID,
    payload: quoteID
  });
  store.dispatch({type: actionType.SET_PAGENAME, payload: ""});
  let route = store.getState().metadataReducer.route ? store.getState().metadataReducer.route : ''
if(route==='Quote'){
    let {objectId,objectVerId,subApplication} = props.pageInfo;
    let newProps = {
      objectId : objectId,
      objectVerId : objectVerId,
      subApplication : subApplication,
      subappName: subApplication
    }
    let transactionPrimaryObject = {}
    let pagelevelValues = {}
    const quoteDetailResponse = await quoteDetailInformation({...stateObj,transactionPrimaryObject,pagelevelValues}, componentProps, null, null, UpdateState,newProps)
    const keyValuePairs = Object.entries(quoteDetailResponse)
      await keyValuePairs.forEach((value,i)=>{
        let updateFlag = (keyValuePairs.length)-1===i
         value[1] && UpdateState(value[0],value[1],updateFlag)
      })
     }else if(route==='Policy'){
      await store.dispatch({
        type : actionTypes.SET_QUOTECONSOLE_INFO,
        payload :  {}
      })
      store.dispatch({type: actionType.SET_PAGENAME, payload: ''});
      if(customTableRowIndex.Type==='ENDORSEMENT'||customTableRowIndex.Type==='CANCEL'||customTableRowIndex.Type==='REINSTATE'){//need to club the changes together.
      propsObj.history.push(`/${customTableRowIndex.Type.toLowerCase()}`)
    }else{
      propsObj.history.push("/quote")
    }
   } else {
    propsObj.history.push("/quote")
   }
  UpdateState("loading",false,true)
}

const CreateQuote_MPL = async (eventName,propsObj,stateObj,component,functions,e,props)=> {
  try{
    functions.UpdateState("loading",true,true)
    let request={};
    request = await constructTransactionRequestV1(stateObj,propsObj, stateObj.pagelevelValues,null,false,false,'')
    request["ServiceRequestDetail"]= getServiceRequestDetail();
    request["OwnerId"] = sessionStorage.getItem("OwnerID")
    request.EventName =  GetCommonEventName();
    request.TargetEventName  = props.component.EventName
    request.SubmissionID = propsObj.UIFlowReducer.submissionData.ID
    Object.assign(request, propsObj.productDetails)
    const response = await createQuoteAxios.post("/core/invokeWorkflowEvent/V2", request)
    let QuoteDetails = {}
    QuoteDetails.QuoteStatus=response.data.QuoteStatus;
  QuoteDetails.quoteNumber = response.data.QuoteNumber;
  QuoteDetails.quoteID = response.data.ID;
  if(response.data.hasOwnProperty('QuoteSubNumber')){
    QuoteDetails.quoteSubNumber=response.data.QuoteSubNumber;
  }
    store.dispatch({
      type: actionType.SET_QUOTE_DETAILS,
      payload: {
        ...QuoteDetails
      }
    });
    store.dispatch({
      type: actionType.SET_QUOTE_STATUS,
      payload: {
        QuoteStatus: QuoteDetails.QuoteStatus
      }
    });
    functions.UpdateState("loading",false,true)
  props.componentProps.history.push("/quote");
}
  catch(err){
    console.log("Error in CreateQuote_MPL", err)
  }
}


const QuoteBinder_Orchid_HomeOwners = async (e,props,state,component,functions,ceasefunctionality)=> {
  let{UpdateState}=functions
  try{
    UpdateState("loading",true,true)
    let request=constructTransactionRequestV2(state,props,{},true,false)
    request.EventName=GetCommonEventName();
    request.TargetEventName=component.EventName
    request.ID = store.getState().UIFlowReducer.quoteID;
    request.ProductNumber = store.getState().UIFlowReducer.productDetails.ProductNumber;
    request.ProductVerNumber = store.getState().UIFlowReducer.productDetails.ProductVerNumber;

    AxiosInstance.post(
      "/core/invokeWorkflowEvent/V2",
      request
    ).then(async (response) => {
    try{
      let quoteStatus = response.data.QuoteStatus
      store.dispatch({
        type: actionType.SET_QUOTE_STATUS,
        payload: {
          quoteStatus,
        }
      });
    const UIFlowReducer = store.getState().UIFlowReducer
    props = {...props,UIFlowReducer}
    let newProps ={
      subApplication : props.pageInfo.subApplication,
      objectId : props.objectId,
      objectVerId : props.objectVerId
    }
    const quoteDetailResponse = await quoteDetailInformation(state, props, null, null, UpdateState,newProps)
    props.setUpdateExpandPanel && props.setUpdateExpandPanel(true);
    const keyValuePairs = Object.entries(quoteDetailResponse)
    await keyValuePairs.forEach((value,i)=>{
      let updateFlag = (keyValuePairs.length)-1===i
       value[1] && UpdateState(value[0],value[1],updateFlag)
    })
    }
    catch(error){
      console.log('Error in BindRequest',error);
    }
    if(response.data.hasOwnProperty('QuoteStatus') && response.data.QuoteStatus.toLowerCase() === "bind request"){
      successToast("Bind Request processed successfully",  { position: Toast.POSITION.TOP_RIGHT});
    }
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
      console.log('Error in BindRequest : API Response Failed')
      triggerToast("Unable to Request Bind");
    }
    if(props.pageInfo.ratingRequestResponseNeeded){
      await invokeThirdPartyApi(props,response)
    }
    });
  }
  catch(error){
    console.log('Error in QuoteBinder_Orchid',error);
  }finally{
    UpdateState("loading",false,true)
  }
}

const issueBinder_Orchid_HomeOwners= async (e,props,state,component,functions)=>{
  try{
    let {UpdateState}=functions
    UpdateState('loading',true,true)
    let{transactionPrimaryObject}=state
    let {CreateQuoteTargetEventName}=props.pageInfo;
    let newProps ={
        subApplication : state.subappNameList,
        objectId : props.objectId,
        objectVerId : props.objectVerId
      }
    const quoteDetailResponse = await getQuoteData(props, CreateQuoteTargetEventName,functions.UpdateState)
    transactionPrimaryObject["IssueBinderNavigator"]="true"
    let translatedResponse=await EntitySubAppTranslationV2({...quoteDetailResponse.data},{...state.pageMetadata.ApplicationDetail})
    let tpo={...transactionPrimaryObject,...quoteDetailResponse.data,...translatedResponse,"TriggerBusinessRules":"false"}
    let response = await componentDidMount_Orchid(state,newProps,{...tpo})
    let temp={}
    temp.ceaseinformationlist_dataList=quoteDetailResponse.data.CeaseInformationList
    await mergeRowdatawithDatalist(response.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{});
    UpdateState ("ceaseinformationlist_dataList",quoteDetailResponse.data.CeaseInformationList,false)
    UpdateState("pageMetadata", response.pageMetadata, false);
    UpdateState("pagelevelValues",{ ...state.pagelevelValues,"IssueBinderNavigator":"true"}, false);
    props.pageInfo.dataListName && props.pageInfo.dataListName.map((datalist)=> {
      if( quoteDetailResponse.data && datalist in quoteDetailResponse.data)
      UpdateState(`${datalist.toLocaleLowerCase()}_dataList`,quoteDetailResponse.data[datalist], false);
    })
    UpdateState("loading",false,true)
  }catch(e){
    console.log('Error in IssueBinder_Orchid',e);
  }
}

const quoteDetailsNavigator_HomeOwners= async (e,props,state,component,functions,ceasefunctionality)=>{
  try{
    let {UpdateState}=functions
    UpdateState('loading',true,true)
    props.setUpdateExpandPanel && props.setUpdateExpandPanel(true);
    let{transactionPrimaryObject}=state
    let newProps ={
      subApplication : state.subappNameList,
      objectId : props.objectId,
      objectVerId : props.objectVerId
    }
    deleteDependentAttribute(component,state.pagelevelValues,state.transactionPrimaryObject);
    transactionPrimaryObject["IssueBinderNavigator"]="false"
    if (ceasefunctionality === "true"){
      let newProps ={
        subApplication : props.metadataReducer.route !== "Reinstate" ? props.UIFlowReducer.currentPageInfo.template[0].subApplication : props.UIFlowReducer.currentConsoleInfo.template[0].subApplication,
        objectId : props.objectId,
        objectVerId : props.objectVerId
      }
      const quoteDetailResponse = await quoteDetailInformation(state, props, null, null, UpdateState,newProps)
      const keyValuePairs = Object.entries(quoteDetailResponse)
      await keyValuePairs.forEach((value,i)=>{
        let updateFlag = (keyValuePairs.length)-1===i
         value[1] && UpdateState(value[0],value[1],updateFlag)
      })
      }else{
        let response = await componentDidMount_Orchid(state,newProps,{...transactionPrimaryObject})
        UpdateState("pageMetadata", response.pageMetadata, false);
        UpdateState("pagelevelValues",{ ...state.pagelevelValues,"IssueBinderNavigator":"false"}, false);
      }
    UpdateState("loading",false,true)
  }catch(error){
    console.log('Error in quoteDetailsNavigator',error)
  }
}

const previewBinder_HomeOwners = async ( propsObj, stateObj, component, e, props) => {
  const {functions:{UpdateState}} = props
  try{
    UpdateState('loading',true,true)
    let request = constructTransactionRequestV2( stateObj, props, props.componentState.pagelevelValues, true, false)
    request.ServiceRequestDetail['Lob']=props.componentProps.productDetails.LOBName
    request.ID = store.getState().UIFlowReducer.quoteID;
    request.EventName = GetCommonEventName();
    request.TargetEventName = component.EventName
    if(props.pageInfo && props.pageInfo.eventAction)
      request.EventAction = props.pageInfo.eventAction.PreviewDocumenteventAction;
    Object.assign(request, propsObj.productDetails)
    try{
        await createQuoteAxios.post("/core/invokeWorkflowEvent/V2", request).then(response=>{
          let transactionRootPath=store.getState().ownerResources.clientInfo.UI_MEMORY_PDF_ROOT_PATH
          let relativePath=response.data.Documents.DocumentRelativePath
          let documentName=response.data.Documents.DocumentName
          FileHandlerAxios(`${transactionRootPath}${relativePath}${documentName}`)
      })
    }catch(error){
      console.log('Error in BindRequest : API Response Failed',error)
      triggerToast("Unable to preview the document");
    }
    UpdateState('loading',false,true)
  }catch(error){
    console.log('Error in Preview Binder',error)
    triggerToast("Unable to preview the document");
  }
}

const previewIssueBinder = async(response)=>{
  try {
    let transactionRootPath = store.getState().ownerResources.clientInfo.UI_MEMORY_PDF_ROOT_PATH
    let relativePath = response.Documents.DocumentRelativePath
    let documentName = response.Documents.DocumentName
    FileHandlerAxios(`${transactionRootPath}${relativePath}${documentName}`)
  }
  catch (error) {
    console.log('Error in BindRequest : API Response Failed', error)
  }
}

const trigger_canopius_validation_window = async (stateObj, propsObj, functions) => {
  let {transactionPrimaryObject} = stateObj
  let newProps ={
      subApplication : stateObj.subappNameList,
      objectId : propsObj.objectId,
      objectVerId : propsObj.objectVerId
  }
  let tpo={...transactionPrimaryObject,"TriggerCanopiusValidationWindow":"true"}
  let mountResponse = await componentDidMount_Orchid(stateObj,newProps,{...tpo})
  functions.UpdateState("pageMetadata", mountResponse.pageMetadata, false);
}

const bindQuote_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    const reduxState = store.getState();
    props.functions.UpdateState("loading",true,true);
    let request =await constructTransactionRequestV1(stateObj,propsObj,{...stateObj.pagelevelValues},false,false,false,false);
    request["ServiceRequestDetail"]=getServiceRequestDetail();
    request["ServiceRequestDetail"]["Lob"]=props.componentProps.productDetails.LOBName
    if(propsObj.pageInfo && propsObj.pageInfo.eventAction)
      request["EventAction"] = propsObj.pageInfo.eventAction;
    if(props.pageInfo && props.pageInfo.TransactionType)
      request["TransactionType"] = props.pageInfo.TransactionType;
    if(props.pageInfo && props.pageInfo.RequiredProcess)
      request["RequiredProcess"] = props.pageInfo.RequiredProcess;
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.TargetEventName=EventName;
    request.EventName=GetCommonEventName();
    request.ID=propsObj.UIFlowReducer.quoteID;
    request.QuoteId=propsObj.UIFlowReducer.quoteID;
    let reduxValues = store.getState();
    if (props.pageInfo && props.pageInfo.officeDetailsNeeded) {
      let {OfficeDetailsRequestKey = [], OfficeDetailsValues = []} = props.pageInfo
      let {userInfoReducer} = reduxValues
      if (OfficeDetailsRequestKey.length > 0 && OfficeDetailsValues.length > 0) {
        let OfficeDetailsRequestKeyLength = OfficeDetailsRequestKey.length
        for (let i = 0; i < OfficeDetailsRequestKeyLength; i++) {
          request[OfficeDetailsRequestKey[i]] = userInfoReducer.OfficeMappingDetails && userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] ? userInfoReducer.OfficeMappingDetails[OfficeDetailsValues[i]] : ''
        }
      }
    }
    Object.assign(request, propsObj.productDetails)
    const response=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed" && !response.data.hasOwnProperty("Message")){
      triggerToast("Unable to Issue Binder")
      props.functions.UpdateState("loading",false,true);
      return
    }
    let {CreateQuoteTargetEventName}=propsObj && propsObj.pageInfo;
    let {transactionPrimaryObject} = stateObj
    let newProps ={
      subApplication : stateObj.subappNameList,
      objectId : propsObj.objectId,
      objectVerId : propsObj.objectVerId
    }
    const getQuoteResponse = await getQuoteData(propsObj, CreateQuoteTargetEventName,props.functions.UpdateState)
    if(response.data.hasOwnProperty("PolicyID")){
      successToast("Policy Bound Successfully ",  { position: Toast.POSITION.TOP_RIGHT});
      store.dispatch({type:actionType.SET_POLICY_ID,payload:response.data.PolicyID});
      store.dispatch({type: actionType.SET_PAGENAME, payload: ""});
      if(props.pageInfo.dynamicFlow && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
        let redirectRoute=""
        const encodedString=await constructEncodedString({"policyID":response.data.PolicyID});
        redirectRoute+=`${encodedString}`;
        propsObj.history.push(`/policy/${redirectRoute}`);
      }
      else{
        propsObj.history.push("/policy");
      }
    }
    else if(!response.data.hasOwnProperty("PolicyID") && response.data.hasOwnProperty("RuleInformationList")){
      let tpo={...transactionPrimaryObject,...getQuoteResponse.data,"TriggerBusinessRules":"true"}
      let mountResponse = await componentDidMount_Orchid(stateObj,newProps,{...tpo})
      props.functions.UpdateState ("viewbusinessrules_dataList",response.data.RuleInformationList,false)
      props.functions.UpdateState("pageMetadata", mountResponse.pageMetadata, false);
    }
    else if(!response.data.hasOwnProperty("PolicyID") && response.data.hasOwnProperty("CeaseInformationList")){
      let {CreateQuoteTargetEventName}=propsObj && propsObj.pageInfo;
      let {transactionPrimaryObject} = stateObj
      let newProps ={
        subApplication : stateObj.subappNameList,
        objectId : propsObj.objectId,
        objectVerId : propsObj.objectVerId
      }
      const getQuoteResponse = await getQuoteData(propsObj, CreateQuoteTargetEventName,props.functions.UpdateState)
      let tpo={...transactionPrimaryObject,...getQuoteResponse.data}
      let mountResponse = await componentDidMount_Orchid(stateObj,newProps,{...tpo})
      let temp={}
      temp.ceaseinformationlist_dataList=getQuoteResponse.data.CeaseInformationList
      await mergeRowdatawithDatalist(mountResponse.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{});
      props.functions.UpdateState ("ceaseinformationlist_dataList",getQuoteResponse.data.CeaseInformationList,false)
      props.functions.UpdateState("pageMetadata", mountResponse.pageMetadata, false);
    }
    else if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
      if(response.data.hasOwnProperty('Message')){
        await trigger_canopius_validation_window(stateObj, propsObj, props.functions);
      }
    }else if(response.data.Message){
      let tpo={...transactionPrimaryObject,...getQuoteResponse.data,"TriggerValidationError":"true"}
      let mountResponse = await componentDidMount_Orchid(stateObj,newProps,{...tpo})
      props.functions.UpdateState ("viewvalidationerrors_dataList",response.data.ErrorMessage,false)
      props.functions.UpdateState("pageMetadata", mountResponse.pageMetadata, false);
    }else if(!response.data.hasOwnProperty("PolicyID") && response.data.hasOwnProperty("Subjectivity")&& response.data.hasOwnProperty("AllowBind")&& response.data.AllowBind==="N"){
      let Subjectivity = "true";
      let tpo={...transactionPrimaryObject,...getQuoteResponse.data,"AllowBind":response.data.AllowBind,"Subjectivity":Subjectivity}
      let mountResponse = await componentDidMount_Orchid(stateObj,newProps,{...tpo})
      props.functions.UpdateState ("subjectivityvalidationssummary_dataList",response.data.Subjectivity,false)
      props.functions.UpdateState("pageMetadata", mountResponse.pageMetadata, false);
    }else{
      triggerToast("Policy Binding Failed");
      }
      props.functions.UpdateState("loading",false,true);
      if(props.pageInfo.ratingRequestResponseNeeded){
        await invokeThirdPartyApi(props,response)
      }
    }
  catch(e){
    console.log("Error in bindQuote_HomeOwners",e);
  }
}

const clearQuoteDetails_HomeOwners=async()=>{
  await store.dispatch({
    type: actionType.SET_QUOTE_DETAILS,
    payload: {
    quoteStatus:"",
     quoteNumber:"",
       quoteID:""
    }
  });
}
const uploadEmail_HomeOwners=async(attachedFile,setAttachment,attachment,props,uploadDocuments)=>{
  try{
    if(uploadDocuments){
      let filename;
      let valueForAttachment=JSON.parse(JSON.stringify(attachment));
    for (let idx in attachedFile){
        if(attachedFile[idx].name && typeof(attachedFile[idx])=='object'){
          const fileToUpload = new FormData();
          fileToUpload.append("uploadFileData",attachedFile[idx],attachedFile[idx].name);
          let {data:fileUploadResponse} = await AxiosInstance.post("/core/fileUpload?pathVariable=ATTACHMENTS_POLICY", fileToUpload);
          filename = attachedFile[idx].name && attachedFile[idx].name.substring(0,attachedFile[idx].name.lastIndexOf('.'));
            // valueForAttachment.push({[props.component.ResponseKey]:filename,AttachmentUrl:fileUploadResponse.relativePath,AttachmentName: fileUploadResponse.newFileName});
            valueForAttachment.push({AttachmentUrl:fileUploadResponse.relativePath,AttachmentName: fileUploadResponse.newFileName});
              setAttachment([...valueForAttachment])
            }
          }
    }
    else{
      let {AttachmentUrl,AttachmentName}=attachedFile
      let redux =store.getState()
      let transactionRootPath=redux.ownerResources.clientInfo.UI_TRANSACTION_ROOT_PATH
      let path=`${transactionRootPath}${AttachmentUrl}${AttachmentName}`;
      downloadFile(AttachmentName,path);
    }
  }catch(e){
    console.log("Error in uploadEmail_HomeOwners",e);
  }
}
const coverageSubmit_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  let values=await getAvailableMarkets_HomeOwners(EventName,propsObj,stateObj,component,functions,e,props,true)
  if(values)
  await selectForms_HomeOwners(props,{PolicyForms:stateObj["SublineName"]},values)

}
const metadataCallAutoComplete=async(props,obj,attributeInfo)=>{
  props.UpdateState("loading",true,true);
  let reduxState=store.getState();
  let { ObjectId,ObjectVerId,SubApplicationList } = reduxState.UIFlowReducer.currentFindConsoleInfo;
  let subappname = SubApplicationList
  let subappNameList = []
  subAppListConstructionV1(subappname, {}, subappNameList, [], [])
  let transactionPrimaryObject={
      ...props.transactionPrimaryObject,[props.component.SubApplicationName]: {
              ...props.transactionPrimaryObject[props.component.SubApplicationName],...obj
      }
  }
  let subAppInfo={
      subappName:subappNameList,
      objectId:ObjectId,
      objectVerId:ObjectVerId
  }
  let metadataResponse = await constructAndInvokeMetadataCall(subAppInfo,{transactionPrimaryObject})
  props.UpdateState("loading",false,false);
  props.UpdateState("pageMetadata", metadataResponse, true);
}
const coverageBlurEvent_HomeOwners=async(props,e)=>{
  await bumpDwellingCoverageToRCV(props)
  const { component,componentProps,componentState,state,parentProps,functions } = props;
  const propsObj = componentProps || state;
  const stateObj = componentState || parentProps;
  const temp = {propsObj,stateObj,component,functions,props}
 // e.target.somevalue=temp
 // props.refs.current.submit(e)
  retainPolicyForms("","",props,state,component,"")
}
async function componentDidMount_DashboardBusinessRules(state, props, UpdateState){
  let { pageInfo = {} } = props;
  let { pageOnLoadActions = [] } = pageInfo;
  let values = await pageLoadActions(pageOnLoadActions, props, state);

    return values;
   }
   function componentDidUpdate_DashboardBusinessRules(){

    return;

   }

export const clearAvailableMarketText =async (a,value, props, b, c, t) =>{
  await clearAvailableMarket(props, value);
}
const clearAvailableMarketMulti =async (props, value, component, b, c, t) =>{
  await clearAvailableMarket(props, value);
}
const viewtoggle = (EventName,propsObj,stateObj,component,functions,event,buttonRef) => {
  try {
    let divElement = document.querySelectorAll('.AvailableMarket__wrapperClass');
    let IteratorIndex = stateObj.IteratorIndex
    let selectedWrapperclass = divElement[IteratorIndex]
    selectedWrapperclass.classList.toggle("AvailableMarket_toggleView")
  }
  catch (error) {
    console.log("error in AvailableMarket_toggleView",error);
  }
}

 const clearAvailableMarket_Renewals = async (value,a, b, c, props) =>{
  await clearAvailableMarket_All(props, value);
}

const clearAvailableMarket_All =async (props, value) =>{
  try{
  if(!props || !props.hasOwnProperty("functions")){
    return
  }
  let {component,functions:{UpdateState},componentState, componentProps:{UIFlowReducer:{quoteID}}} = props
  let {Action} = component;
  let {isLoaderNeeded=true} = Action;
  if(component.ComponentType==="Currency" && componentState && componentState.pagevalues && componentState.pagelevelValues && componentState.pagevalues[`${component.ComponentId}`] && componentState.pagelevelValues[`${component.SubApplicationName}`][`${component.ComponentId}`] && (componentState.pagevalues[`${component.ComponentId}`]===componentState.pagelevelValues[`${component.SubApplicationName}`][`${component.ComponentId}`])){
      return;
  }
  if(component.OnKeyDownAction && props[component.OnKeyDownAction] && quoteID!==undefined && quoteID!==''){
      await props[component.OnKeyDownAction]();
  }
  isLoaderNeeded && UpdateState("loading",true,false)
  store.dispatch({type:actionType.SET_GETAVAILABLEMARKET_CHECKED,payload: false});
  let {pagelevelValues,transactionPrimaryObject}=props
  // if(transactionPrimaryObject[component.SubApplicationName])
  //     delete transactionPrimaryObject[component.SubApplicationName]['PolicyForms']
  // if(pagelevelValues[component.SubApplicationName])
  //     delete pagelevelValues[component.SubApplicationName]['PolicyForms']
  let obj= handleInputChangeV3(
      value,
      component,
      undefined,
      undefined,
      undefined,
      {pagelevelValues,transactionPrimaryObject}
    );
  let subappinfo = {
      subappName: props.pageInfo.subApplication,
      objectId: props.componentProps.productDetails.ProductID,
      objectVerId: props.componentProps.productDetails.ProductVerID,
      state:"CW"
    }
    transactionPrimaryObject ={...obj.transactionPrimaryObject,AvailableMarket:props.AvailableMarket}
    deleteDependentAttribute(component,pagelevelValues,transactionPrimaryObject)
    let metadataResponse ={};
    metadataResponse = await constructAndInvokeMetadataCall(subappinfo,{transactionPrimaryObject});
    let defaultValues =await constructDefaultValues(metadataResponse);
  UpdateState("pageMetadata",metadataResponse,false);
  UpdateState("pagelevelValues",{...obj.pagelevelValues,"ProductSelection":{...defaultValues.ProductSelection,...obj.pagelevelValues.ProductSelection}},false);
  UpdateState("transactionPrimaryObject",{...obj.pagelevelValues,"ProductSelection":{...defaultValues.ProductSelection,...obj.pagelevelValues.ProductSelection}},true);
  isLoaderNeeded &&  UpdateState("loading",false,true);
  let  flowBlockKey=props.pageInfo.flowBlockKey ;
  if(flowBlockKey){
      obj[`${flowBlockKey}`] = false
      store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: obj})
    }
  props.componentProps.parentState.UpdateState("AvailableMarket",{},false);
  let dataListName = props.pageInfo.dataListName || []
  dataListName.forEach(key=> {
      props.componentProps.parentState.UpdateState(`${key.toLowerCase()}_dataList`,[],false);
  })
  // props.componentProps.parentState.UpdateState("carriervalidations_dataList",[],false)
  props.componentProps.parentState.UpdateState("clearCarrierSelection",true,false)
  props.componentProps.parentState.UpdateState("AvailableMarketList_dataList",[],true);
  if(props.pageInfo && props.pageInfo.clearDetailsInModifyQuote)
    await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {"quoteNumber":"","quoteID":""} });
  await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {"SubjectivityListRenderFlag":"No"}});
}catch(e){
  props.functions.UpdateState("loading",false,false);
  console.log('Error in clearAvailableMarket',e)
}
}

const closeToggle = (EventName,propsObj,stateObj,component,functions,event,props, subappContext)=>{
  try {
    let divElement = document.querySelectorAll('.AvailableMarket__wrapperClass');
    let IteratorIndex = stateObj.IteratorIndex
    let element = divElement[IteratorIndex]
    element.classList.toggle("AvailableMarket_toggleView")
    let expandPanelElement = document.querySelector(".sticky-tab-navigation")
    // let stickyHeader = document.querySelector(".sticky-tab-navigation")
    let Main = document.querySelector("main[class='main']")
    let rect = element.getBoundingClientRect()
    let rectexpand = expandPanelElement.getBoundingClientRect()
    // let TotalHeight = stickyHeader.offsetHeight + Header.offsetHeight
    console.log("stickyHeaderHeight closeToggle", rect, element.offsetTop,rectexpand.height)
    Main.style.scrollMarginTop = `-${element.offsetTop - rect.height - rectexpand.height}px`
  //  element.scrollIntoView({behavior: 'smooth',  block:'start' })
   Main.scrollIntoView({behavior: 'smooth',  block:'start' })
   Main.style.scrollMarginTop = `0px`
  }
  catch(e){
    console.log("ayy check closeToggle method", e)
  }
}


const invokePremiumandRating_HomeOwners_Packages=async(props,state,prevprops,prevstate,referSubmission=false)=>{
  try{
  if((prevprops===undefined) ||(props.componentProps.SublineList!==prevprops.componentProps.SublineList)
   || (props.componentProps.coverageUpdateNeeded && props.componentProps.coverageUpdateNeeded === true)){
  await props.componentProps.coverageUpdateNeeded && props.componentProps.parentState.UpdateState("coverageUpdateNeeded",false,true);
   props.pageInfo.LoaderMessage && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",props.pageInfo.LoaderMessage,true);
  let {SubApplicationName,AttributeName}=props.component, subapplist=[]
  let {componentProps:{AvailableMarketList_dataList},componentProps:{parentState:{UpdateState}}}=props;
  reteriveSubappList(props.componentState.pageMetadata.ApplicationDetail.SubApplicationDetailList,SubApplicationName,subapplist,false)
  let iteratorIndex = props.componentState.IteratorIndex
  let pageLevelValues={...props.componentProps.AvailableMarketList_dataList[iteratorIndex],[SubApplicationName]:{[SubApplicationName]: {...AvailableMarketList_dataList[iteratorIndex][SubApplicationName]},...props.componentProps.SublineList}}
  let request = {}
  let {subApplication, iteratorSubappList} = props.pageInfo;
  let subappName = []
  let subappNameList = []
  subappNameList = iteratorSubappList
  const { ProductID: objectId, ProductVerID: objectVerId,ProductNumber,ProductVerNumber } =props.componentProps.productDetails;
  let selectedSubline = props.pageInfo.selectedSubline;
  let neededSubapps = []
  Object.keys(selectedSubline).length > 0 && Object.entries(selectedSubline).forEach(([key,value])=>{
      if(key === props.componentProps.SublineList.SublineName){
        neededSubapps.push(value)
      }
   })
   subappName = [...subappNameList,...neededSubapps]
  if(!AvailableMarketList_dataList[iteratorIndex].hasOwnProperty("SubApplicationDetailList") && AvailableMarketList_dataList[iteratorIndex].hasOwnProperty("Coverage") && Object.keys(AvailableMarketList_dataList[iteratorIndex].Coverage).length>0){
    pageLevelValues = {...pageLevelValues, ...await EntitySubAppTranslationV2({...pageLevelValues},props.componentState.pageMetadata.ApplicationDetail)}
    delete AvailableMarketList_dataList[iteratorIndex].Coverage
  }
  request = constructTransactionRequestV2(props.componentState,props.componentProps,pageLevelValues,true,false);
    let childDatalist= getDataLists(props.pageInfo.dataListName, props.componentProps);
  if(!request[SubApplicationName]){Object.assign(request,{[SubApplicationName]:{}})}
  let updatedCoverage = {}
  updatedCoverage = props.componentProps.updatedCoverage

  request.Coverage={...request.Coverage, ...updatedCoverage}
  if(Object.keys(request.Coverage).length>0) {request.Coverage ={...request.Coverage, ...childDatalist} }
  else{delete request.Coverage}
  if(props.componentProps.UIFlowReducer.quoteID && props.componentProps.UIFlowReducer.quoteID !== ""){
    request['QuoteFullNumber'] = props.componentProps.UIFlowReducer.quoteFullNumber;
  }
  request[`${SubApplicationName}`]["ProductSelection"]=props.componentProps.AvailableMarket;
  request[`${SubApplicationName}`]["insuredID"]=props.componentProps.UIFlowReducer.insuredID;
  request["SubmissionID"]=props.componentProps.UIFlowReducer.submissionID;
  request["UserRole"]=props.componentProps.propsValue.userInfoReducer.userRole;
  request["TargetEventName"]=props.pageInfo.RatingEvent;request.EventName=GetCommonEventName();
  request["ProductNumber"] = ProductNumber;
  request["ProductVerNumber"] = ProductVerNumber;
  if(props.pageInfo && props.pageInfo.RatingEventAction)
    request["EventAction"]=props.pageInfo.RatingEventAction;
  request["RequestID"] = props.componentProps.RequestID
  const [SubApplicationEntity,response] = await Promise.all([getEntitySubAppMappingV2({subappName , objectId, objectVerId, state: 'CW', subApplicationGroupList: []}, "V2"),AxiosInstance.post("core/invokeWorkflowEvent/V2",request)])
  if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
    //use Toaster method.
    props.pageInfo.activeConfiguration==='package' ? triggerToast(`Unable to get
     ${request[SubApplicationName][SubApplicationName].PlanDisplayName} package`) :
    triggerToast("Unable to get carriers list");
    // AvailableMarketList_dataList[iteratorIndex]['SubApplicationDetailList']= []
    // props.functions.UpdateState('AvailableMarketList_dataList',AvailableMarketList_dataList,true);
    // throw new Error();
  }

  const {metadataReducer} = store.getState();

  if("ProcessStatus" in response.data && metadataReducer.ProcessStatus !== response.data.ProcessStatus){
      store.dispatch({type: appActions.actionType.SET_PROCESS_STATUS, payload: response.data.ProcessStatus});
      store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
  }
  if (response.data && response.data.SubmissionStatus)
    await store.dispatch({ type: Action.SET_SUBMISSION_STATUS, payload: response.data.SubmissionStatus });

  if(response && response.data && response.data.hasOwnProperty("ExternalQuoteNumber")){ store.dispatch({ type: actionType.SET_EXTERNAL_QUOTE_NUMBER, payload: response.data.ExternalQuoteNumber }); }
  let subappinfo = {
    subappName,
    objectId,
    objectVerId,
    state:"CW",
    subApplicationGroupList: [],
  };
  let editableCoverage = "activeConfiguration" in props.pageInfo && props.pageInfo.activeConfiguration==='package' ? true: false;
  await store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: editableCoverage })
  let Tlist = {AvailableMarket:{...props.componentProps.AvailableMarket,...AvailableMarketList_dataList[iteratorIndex]["AvailableMarket"],"IsReferralRuleApplied": response.data.RuleStatus && response.data.RuleStatus ||"false"},...response.data, ProductSelection:props.componentProps.AvailableMarket}
  let metadataresponse = []
  let checkRuleList;
  // if(response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() ==='ineligible'){
  //   checkRuleList = true;
  //   Tlist["ShowBusinessRules"] ="true";
  //   subappinfo.subappName = props.pageInfo.RuleInfoSubapp;
    // metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
    //   transactionPrimaryObject:Tlist,
    //   EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    // });
    // props.functions.UpdateState(props.pageInfo.RuleInfoSubapp,response.data.RuleInformationList,true)
  // }
  let responseFromTranslation = {}
  //  if(response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() !=='ineligible'){
    checkRuleList = false;
    // Tlist["ShowBusinessRules"] =response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() ==='ineligible' ? "true" :  "false";
  responseFromTranslation=await EntitySubAppTranslationV2({...response.data},{SubApplicationEntity: SubApplicationEntity});
  Tlist = {...Tlist, ...responseFromTranslation}
  metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
    transactionPrimaryObject:Tlist,
    EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
  });
// }
if(response && response.data && Object.keys(response.data).length > 0 && response.data.ResponseStatus.toLowerCase() !== "failed"){
  await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {"SubjectivityListRenderFlag":"Yes"} });
}
  // responseFromTranslation=await EntitySubAppTranslationV2({...response.data},metadataresponse.ApplicationDetail);


  let metadata=metadataresponse && metadataresponse.ApplicationDetail && metadataresponse.ApplicationDetail.SubApplicationDetailList
  AvailableMarketList_dataList[iteratorIndex] = {...AvailableMarketList_dataList[iteratorIndex],...responseFromTranslation,...response.data,...props.componentProps.SublineList}
  AvailableMarketList_dataList[iteratorIndex][`${SubApplicationName}`][`${AttributeName}`]=response.data.Coverage && response.data.Coverage.TotalPremium;
  // AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`]={}
  // AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`]={...response.data.Coverage};
  AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageInformationDetails`]= [];
  AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageInformationDetails`]= (response.data.Coverage && response.data.Coverage.CoverageRuleInformationList) ? [...response.data.Coverage.CoverageRuleInformationList] : [] ;
  AvailableMarketList_dataList[iteratorIndex][`feescarrier_dataList`] = response.data.Fee;
  AvailableMarketList_dataList[iteratorIndex][`taxescarrier_dataList`] = response.data.Tax;
  AvailableMarketList_dataList[iteratorIndex]['SubApplicationDetailList']= metadata|| []
  AvailableMarketList_dataList[iteratorIndex]['SubmissionRatingDataID']=  response.data.SubmissionRatingDataID;
  AvailableMarketList_dataList[iteratorIndex]['isEligible']= response.data.RuleStatus && response.data.RuleStatus.toLowerCase();
  AvailableMarketList_dataList[iteratorIndex]['viewbusinessrulespl_dataList']= response.data.RuleInformationList || [];
  AvailableMarketList_dataList[iteratorIndex]["ProductSelection"]=props.componentProps.AvailableMarket;
  if(response.data.CarrierDisplayName)
  //AvailableMarketList_dataList[iteratorIndex][SubApplicationName].CarrierDisplayName = response.data.CarrierDisplayName && response.data.CarrierDisplayName;
  props.functions.UpdateState('ruleinformationlistpl_dataList',response.data.RuleInformationList,false)
  // setDataLists(props.pageInfo.dataListName, response.data && response.data.Coverage, props.functions.UpdateState)
  props.pageInfo.dataListName.map((datalist)=> {
    if( response.data && datalist in response.data.Coverage)
    AvailableMarketList_dataList[iteratorIndex][`${datalist.toLocaleLowerCase()}_dataList`] =  response.data.Coverage[datalist]
  })
  let sortedList=[...AvailableMarketList_dataList]
  let issort=sortedList.every( (data)=>data["AvailableMarket"] && data["AvailableMarket"].hasOwnProperty("TotalPremium") )
  let temp={}
  temp.ruleinformationlistpl_dataList = response.data.RuleInformationList
  if(response.data && response.data.hasOwnProperty("RuleStatus") &&metadata ){
  await mergeRowdatawithDatalist(metadata,temp,{});
  }
  props.functions.UpdateState("pagelevelValues",AvailableMarketList_dataList[iteratorIndex],false);
  if(prevprops && props.componentProps.selectedPolicy!==prevprops.componentProps.selectedPolicy){
    ResetDataLists(props.pageInfo.dataListName, UpdateState, props.functions.UpdateState)
    }
  props.functions.UpdateState("transactionPrimaryObject",Tlist,false);
  // props.functions.UpdateState("updatedCoverage",updatedCoverage,false);
  UpdateState("PopUp_ScheduleOfForms",response.data.Coverage,false);
  if(issort){
   let sortedList_ = sortedList.filter(data =>data.AvailableMarket && data.AvailableMarket.TotalPremium )
    sortedList_.length > 1 && sortedList.sort( (a,b)=>{return Number(a.AvailableMarket.TotalPremium) - Number(b.AvailableMarket.TotalPremium)});
      if(checkRuleList===undefined || checkRuleList===true){
        UpdateState('AvailableMarketList_dataList',sortedList,false);
      }else{
        props.functions.UpdateState('AvailableMarketList_dataList',sortedList,false);
      }
      let isexit=props.componentProps.AvailableMarketList_dataList ? props.componentProps.AvailableMarketList_dataList.every((data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined) : sortedList.every( (data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined);
      isexit && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);
  }
  else{
  let sortedList=[...AvailableMarketList_dataList]
  if(checkRuleList===undefined || checkRuleList===true){
    UpdateState('AvailableMarketList_dataList',sortedList,false);
  }else{
    props.functions.UpdateState('AvailableMarketList_dataList',sortedList,false);
  }
  let isexit=props.componentProps.AvailableMarketList_dataList ? props.componentProps.AvailableMarketList_dataList.every((data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined) : sortedList.every( (data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined)
  isexit && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);

  }
  if(props.pageInfo.ratingRequestResponseNeeded){
    await invokeThirdPartyApi(props,response)
  }
  }else{
    return;
  }
}
 catch(err){
  props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);
  console.log("error in invokePremiumandRating_HomeOwners_CarrierSpecficRules,",err);

 }
}

const invokePremiumandRating_HomeOwners_Packages_V02=async(props,state,prevprops,prevstate,referSubmission=false)=>{
  //for Performance Enhancements (split metadata call, cancelling previous API calls when changing policy forms )
  try{
  if((prevprops===undefined) ||(props.componentProps.SublineList!==prevprops.componentProps.SublineList)
   || (props.componentProps.coverageUpdateNeeded && props.componentProps.coverageUpdateNeeded === true)){
  await props.componentProps.coverageUpdateNeeded && props.componentProps.parentState.UpdateState("coverageUpdateNeeded",false,true);
   props.pageInfo.LoaderMessage && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",props.pageInfo.LoaderMessage,true);
  let {SubApplicationName,AttributeName}=props.component, subapplist=[]
  let {componentProps:{AvailableMarketList_dataList},componentProps:{parentState:{UpdateState}}}=props;
  reteriveSubappList(props.componentState.pageMetadata.ApplicationDetail.SubApplicationDetailList,SubApplicationName,subapplist,false)
  let iteratorIndex = props.componentState.IteratorIndex;
  let IteratorRef = props.componentState.IteratorRef;
  let pageLevelValues={...props.componentProps.AvailableMarketList_dataList[iteratorIndex],[SubApplicationName]:{[SubApplicationName]: {...AvailableMarketList_dataList[iteratorIndex][SubApplicationName]},...props.componentProps.SublineList}}


  const cancelAPIName = "InvokeRating"
  if(IteratorRef.current && IteratorRef.current[cancelAPIName]){
    IteratorRef.current[cancelAPIName].cancel("API canceled")
  }
  IteratorRef.current = {...IteratorRef.current, [cancelAPIName]: Axios.CancelToken.source()}

   const cancelToken =(IteratorRef.current && IteratorRef.current[cancelAPIName] && IteratorRef.current[cancelAPIName].token) || ''

  let request = {}
  let {subApplication, iteratorSubappList} = props.pageInfo;
  let subappName = []
  let subappNameList = []
  subappNameList = iteratorSubappList
  const { ProductID: objectId, ProductVerID: objectVerId,ProductNumber,ProductVerNumber } =props.componentProps.productDetails;
  let selectedSubline = props.pageInfo.selectedSubline;
  let neededSubapps = []
  Object.keys(selectedSubline).length > 0 && Object.entries(selectedSubline).forEach(([key,value])=>{
      if(key === props.componentProps.SublineList.SublineName){
        neededSubapps.push(value)
      }
   })
   subappName = [...subappNameList,...neededSubapps]
  if(!AvailableMarketList_dataList[iteratorIndex].hasOwnProperty("SubApplicationDetailList") && AvailableMarketList_dataList[iteratorIndex].hasOwnProperty("Coverage") && Object.keys(AvailableMarketList_dataList[iteratorIndex].Coverage).length>0){
    pageLevelValues = {...pageLevelValues, ...await EntitySubAppTranslationV2({...pageLevelValues},props.componentState.pageMetadata.ApplicationDetail)}
    delete AvailableMarketList_dataList[iteratorIndex].Coverage
  }
  request = constructTransactionRequestV2(props.componentState,props.componentProps,pageLevelValues,true,false);
    let childDatalist= getDataLists(props.pageInfo.dataListName, props.componentProps);
  if(!request[SubApplicationName]){Object.assign(request,{[SubApplicationName]:{}})}
  let updatedCoverage = {}
  updatedCoverage = props.componentProps.updatedCoverage

  request.Coverage={...request.Coverage, ...updatedCoverage}
  if(Object.keys(request.Coverage).length>0) {request.Coverage ={...request.Coverage, ...childDatalist} }
  else{delete request.Coverage}
  if(props.componentProps.UIFlowReducer.quoteID && props.componentProps.UIFlowReducer.quoteID !== ""){
    request['QuoteFullNumber'] = props.componentProps.UIFlowReducer.quoteFullNumber;
  }
  request[`${SubApplicationName}`]["ProductSelection"]=props.componentProps.AvailableMarket;
  request[`${SubApplicationName}`]["insuredID"]=props.componentProps.UIFlowReducer.insuredID;
  request["SubmissionID"]=props.componentProps.UIFlowReducer.submissionID;
  request["UserRole"]=props.componentProps.propsValue.userInfoReducer.userRole;
  request["TargetEventName"]=props.pageInfo.RatingEvent;request.EventName=GetCommonEventName();
  request["ProductNumber"] = ProductNumber;
  request["ProductVerNumber"] = ProductVerNumber;
  if(props.pageInfo && props.pageInfo.RatingEventAction)
    request["EventAction"]=props.pageInfo.RatingEventAction;
  request["RequestID"] = props.componentProps.RequestID
  const [SubApplicationEntity,response] = await Promise.all([getEntitySubAppMappingV2({subappName , objectId, objectVerId, state: 'CW', subApplicationGroupList: []}, "V2", {cancelToken}),AxiosInstance.post("core/invokeWorkflowEvent/V2",request, {cancelToken})])
  if(response && response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
    //use Toaster method.
    props.pageInfo.activeConfiguration==='package' ? triggerToast(`Unable to get
     ${request[SubApplicationName][SubApplicationName].PlanDisplayName} package`) :
    triggerToast("Unable to get carriers list");
    // AvailableMarketList_dataList[iteratorIndex]['SubApplicationDetailList']= []
    // props.functions.UpdateState('AvailableMarketList_dataList',AvailableMarketList_dataList,true);
    // throw new Error();
  }

  const {metadataReducer} = store.getState();

  if("ProcessStatus" in response.data && metadataReducer.ProcessStatus !== response.data.ProcessStatus){
      store.dispatch({type: appActions.actionType.SET_PROCESS_STATUS, payload: response.data.ProcessStatus});
      store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
  }
  if (response.data && response.data.SubmissionStatus)
    await store.dispatch({ type: Action.SET_SUBMISSION_STATUS, payload: response.data.SubmissionStatus });

  if(response && response.data && response.data.hasOwnProperty("ExternalQuoteNumber")){ store.dispatch({ type: actionType.SET_EXTERNAL_QUOTE_NUMBER, payload: response.data.ExternalQuoteNumber }); }
  let subappinfo = {
    subappName,
    objectId,
    objectVerId,
    state:"CW",
    subApplicationGroupList: [],
  };
  let editableCoverage = "activeConfiguration" in props.pageInfo && props.pageInfo.activeConfiguration==='package' ? true: false;
  await store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: editableCoverage })
  let Tlist = {AvailableMarket:{...props.componentProps.AvailableMarket,...AvailableMarketList_dataList[iteratorIndex]["AvailableMarket"],"IsReferralRuleApplied": response.data.RuleStatus && response.data.RuleStatus ||"false"},...response.data, ProductSelection:props.componentProps.AvailableMarket}
  let metadataresponse = []
  let checkRuleList;
  // if(response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() ==='ineligible'){
  //   checkRuleList = true;
  //   Tlist["ShowBusinessRules"] ="true";
  //   subappinfo.subappName = props.pageInfo.RuleInfoSubapp;
    // metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
    //   transactionPrimaryObject:Tlist,
    //   EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    // });
    // props.functions.UpdateState(props.pageInfo.RuleInfoSubapp,response.data.RuleInformationList,true)
  // }
  let responseFromTranslation = {}
  //  if(response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() !=='ineligible'){
    checkRuleList = false;
    // Tlist["ShowBusinessRules"] =response.data && response.data.hasOwnProperty("RuleStatus") && response.data.RuleStatus.toLowerCase() ==='ineligible' ? "true" :  "false";
  responseFromTranslation=await EntitySubAppTranslationV2({...response.data},{SubApplicationEntity: SubApplicationEntity});
  Tlist = {...Tlist, ...responseFromTranslation}
  const [firstMetadata, SecondMetadata] = [{...subappinfo, subappName: ["AvailableMarket"]}, subappinfo].map(async(subappinfo)=> {
   return constructAndInvokeMetadataCall(subappinfo, {
      transactionPrimaryObject:Tlist,
      EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    },null, {cancelToken});
  });
  (async()=> {
    try{
      let metadataresponse =  await SecondMetadata;
      let metadata=metadataresponse && metadataresponse.ApplicationDetail && metadataresponse.ApplicationDetail.SubApplicationDetailList;
      AvailableMarketList_dataList[iteratorIndex]['SubApplicationDetailList']= metadata|| [];
      props.functions.UpdateState('AvailableMarketList_dataList',AvailableMarketList_dataList,true);
    }
    catch(e){
      console.log("error in Metadata")
    }
  })();
  metadataresponse = await firstMetadata;
  // metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
  //   transactionPrimaryObject:Tlist,
  //   EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
  // });
// }
if(response && response.data && Object.keys(response.data).length > 0 && response.data.ResponseStatus.toLowerCase() !== "failed"){
  await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {"SubjectivityListRenderFlag":"Yes"} });
}
  // responseFromTranslation=await EntitySubAppTranslationV2({...response.data},metadataresponse.ApplicationDetail);


  let metadata=metadataresponse && metadataresponse.ApplicationDetail && metadataresponse.ApplicationDetail.SubApplicationDetailList
  AvailableMarketList_dataList[iteratorIndex] = {...AvailableMarketList_dataList[iteratorIndex],...responseFromTranslation,...response.data,...props.componentProps.SublineList}
  if(IteratorRef.current[cancelAPIName]) IteratorRef.current[cancelAPIName] = '';
  AvailableMarketList_dataList[iteratorIndex][`${SubApplicationName}`][`${AttributeName}`]=response.data.Coverage && response.data.Coverage.TotalPremium;
  // AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`]={}
  // AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageDetails`]={...response.data.Coverage};
  AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageInformationDetails`]= [];
  AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.SublineList.SublineName}CoverageInformationDetails`]= (response.data.Coverage && response.data.Coverage.CoverageRuleInformationList) ? [...response.data.Coverage.CoverageRuleInformationList] : [] ;
  AvailableMarketList_dataList[iteratorIndex][`feescarrier_dataList`] = response.data.Fee;
  AvailableMarketList_dataList[iteratorIndex][`taxescarrier_dataList`] = response.data.Tax;
  AvailableMarketList_dataList[iteratorIndex]['SubApplicationDetailList']= metadata|| []
  AvailableMarketList_dataList[iteratorIndex]['SubmissionRatingDataID']=  response.data.SubmissionRatingDataID;
  AvailableMarketList_dataList[iteratorIndex]['isEligible']= response.data.RuleStatus && response.data.RuleStatus.toLowerCase();
  AvailableMarketList_dataList[iteratorIndex]['viewbusinessrulespl_dataList']= response.data.RuleInformationList || [];
  AvailableMarketList_dataList[iteratorIndex]["ProductSelection"]=props.componentProps.AvailableMarket;
  if(response.data.CarrierDisplayName)
  //AvailableMarketList_dataList[iteratorIndex][SubApplicationName].CarrierDisplayName = response.data.CarrierDisplayName && response.data.CarrierDisplayName;
  props.functions.UpdateState('ruleinformationlistpl_dataList',response.data.RuleInformationList,false)
  // setDataLists(props.pageInfo.dataListName, response.data && response.data.Coverage, props.functions.UpdateState)
  props.pageInfo.dataListName.map((datalist)=> {
    if( response.data && datalist in response.data.Coverage)
    AvailableMarketList_dataList[iteratorIndex][`${datalist.toLocaleLowerCase()}_dataList`] =  response.data.Coverage[datalist]
  })
  let sortedList=[...AvailableMarketList_dataList]
  let issort=sortedList.every( (data)=>data["AvailableMarket"] && data["AvailableMarket"].hasOwnProperty("TotalPremium") )
  let temp={}
  temp.ruleinformationlistpl_dataList = response.data.RuleInformationList
  if(response.data && response.data.hasOwnProperty("RuleStatus") &&metadata ){
  await mergeRowdatawithDatalist(metadata,temp,{});
  }
  props.functions.UpdateState("pagelevelValues",AvailableMarketList_dataList[iteratorIndex],false);
  if(prevprops && props.componentProps.selectedPolicy!==prevprops.componentProps.selectedPolicy){
    ResetDataLists(props.pageInfo.dataListName, UpdateState, props.functions.UpdateState)
    }
  props.functions.UpdateState("transactionPrimaryObject",Tlist,false);
  // props.functions.UpdateState("updatedCoverage",updatedCoverage,false);
  UpdateState("PopUp_ScheduleOfForms",response.data.Coverage,false);
  if(issort){
   let sortedList_ = sortedList.filter(data =>data.AvailableMarket && data.AvailableMarket.TotalPremium )
    sortedList_.length > 1 && sortedList.sort( (a,b)=>{return Number(a.AvailableMarket.TotalPremium) - Number(b.AvailableMarket.TotalPremium)});
      if(checkRuleList===undefined || checkRuleList===true){
        UpdateState('AvailableMarketList_dataList',sortedList,false);
      }else{
        props.functions.UpdateState('AvailableMarketList_dataList',sortedList,false);
      }
      let isexit=props.componentProps.AvailableMarketList_dataList ? props.componentProps.AvailableMarketList_dataList.every((data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined) : sortedList.every( (data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined);
      isexit && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);
  }
  else{
  let sortedList=[...AvailableMarketList_dataList]
  if(checkRuleList===undefined || checkRuleList===true){
    UpdateState('AvailableMarketList_dataList',sortedList,false);
  }else{
    props.functions.UpdateState('AvailableMarketList_dataList',sortedList,false);
  }
  let isexit=props.componentProps.AvailableMarketList_dataList ? props.componentProps.AvailableMarketList_dataList.every((data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined) : sortedList.every( (data)=>data["SubApplicationDetailList"] && data["SubApplicationDetailList"] !== undefined)
  isexit && props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);

  }
  if(props.pageInfo.ratingRequestResponseNeeded){
    await invokeThirdPartyApi(props,response)
  }
  }else{
    return;
  }
}
 catch(err){
  props.componentProps.parentState.UpdateState("dynamicLoadingMessage",'',true);
  if(err.toLowerCase() === 'cancelled'){
    return console.error("Invoke Rating is cancelled")
  }
  console.log("error in invokePremiumandRating_HomeOwners_CarrierSpecficRules,",err);

 }
}

const Orchid = {
  parentStateUpdateNeeded: false,
  componentDidMount: componentDidMount_Orchid,

  productselection:{
    getAvailableMarkets:getAvailableMarkets_HomeOwners,
    getAvailableMarkets_Renewals:getAvailableMarkets_Renewals,
    bumpDwellingCoverageToRCV,
    availableMarketsValues:availableMarketsValues_HomeOwners,
    selectForms:selectForms_HomeOwners,
    getLocationList_Orchid,
    clearAvailableMarket:clearAvailableMarketMulti,
    clearQuoteDetails:clearQuoteDetails_HomeOwners,
    coverageBlurEvent:coverageBlurEvent_HomeOwners,
    coverageSubmit:coverageSubmit_HomeOwners,
    clearAvailableMarket_Currency,
    UpdateParenState_ProductSelectionumber,
    clearAvailableMarketText:clearAvailableMarketText,
    clearAvailableMarket_Renewals:clearAvailableMarket_Renewals,
    clearAvailableMarket_All,
    retainPolicyForms,
    getNonConfiguredMarkets,
  },
  viewbusinessrulespl :
    {
      "tableUtils": {
        "AddRow": null,
        "onExpand": null,
        "onChildSave": null,
        "EditRow": "",
        "OnDetailEdit": "",
        "redirectTo": ""
      },
      "TableOptions": {
        "ChildrenSubApplicationName": null,
        "ChildrenComponent": null,
        "AddButtonNeeded": false,
        "SortingNeeded": false,
        "FilteringNeeded": false,
        "FilterIconNeeded": false,
        "rowsPerPageOptions": [15, 30, 45, 60, 100],
        "RowsPerPage": 15,
        "ActionName": "Action",
        "ActionListOptions": [],
      }
    },
  taxescarrier:{
    "tableUtils": {
      "AddRow": null,
      "onExpand": null,
      "onChildSave": null,
      "EditRow": handleRowLevelInputChange,
      "OnDetailEdit": "",
      "redirectTo": ""
    },
    "TableOptions": {
      "ChildrenSubApplicationName": null,
      "ChildrenComponent": null,
      "AddButtonNeeded": false,
      "SortingNeeded": false,
      "FilteringNeeded": false,
      "FilterIconNeeded": false,
      "rowsPerPageOptions": [],
      "RowsPerPage": 50,
      "ActionName": "Action",
      "ActionListOptions": [],
      "Paginations": null
    }
  },
  feescarrier: {
    "tableUtils": {
      "AddRow": null,
      "onExpand": null,
      "onChildSave": null,
      "EditRow": handleRowLevelInputChange,
      "OnDetailEdit": "",
      "redirectTo": ""
    },
    "TableOptions": {
      "ChildrenSubApplicationName": null,
      "ChildrenComponent": null,
      "AddButtonNeeded": false,
      "SortingNeeded": false,
      "FilteringNeeded": false,
      "FilterIconNeeded": false,
      "rowsPerPageOptions": [],
      "RowsPerPage": 50,
      "ActionName": "Action",
      "ActionListOptions": []
    }
  },
  componentDidUpdate: componentDidUpdate_Orchid,
  availablemarketheader:{
    onAdjustCoverage:onAdjustCoverage,
    PrintRateComparison:PrintRateComparison_HomeOwners
  },
  nonconfiguredavailablemarketheader:{
    onAdjustCoverage:onAdjustCoverage,
    PrintRateComparison:PrintRateComparison_HomeOwners
  },
  adjustcoverage:{
    onRefreshPremium:onRefreshPremium
  },
  ruleinformationlistpl:{
    "tableUtils": {
      "AddRow": null,
      "onExpand": null,
      "onChildSave": null,
      "EditRow": handleRowLevelInputChange,
      "OnDetailEdit": "",
      "redirectTo": ""
    },
    "TableOptions": {
      "ChildrenSubApplicationName": null,
      "ChildrenComponent": null,
      "AddButtonNeeded": false,
      "SortingNeeded": false,
      "FilteringNeeded": false,
      "FilterIconNeeded": false,
      "rowsPerPageOptions": [],
      "RowsPerPage": 50,
      "ActionName": "",
      "ActionListOptions": []
    },
    "tableFunctions": {
      "EditRow": handleRowLevelInputChange,
    },
    onChangeRuleStatus:onChangeRuleStatus_ProductSelection
  },
  ruleinformationlistdescriptionpl:{
    onSaveBusinessRule:onSaveBusinessRule_HomeOwners
  },
  "adjustcoverageaction":{
    closeToggle: closeToggle
  },
  adjustcoveragenavigation : {
    onAdjustCoverageNavigation:onAdjustCoverageNavigation,
    onRefreshPremium:onRefreshPremium,
    onRefreshPremiumV2:onRefreshPremiumV2,
    viewtoggle: viewtoggle,
    closeToggle: closeToggle
  },
  addcoveragenavigation : {
    onAdjustCoverageNavigation:onAdjustCoverageNavigation,
    onRefreshPremium:onRefreshPremium,
    saveAddCoverage
  },

  availablemarket:{
    dummyGetInteg:dummyGetInteg_Homes,
    viewBumpRules_HomeOwners,
    changeValues:invokePremiumandRating_HomeOwners,
    invokeRating:invokePremiumandRating_HomeOwnersV1,
    invokePremiumandRating_HomeOwners_CarrierSpecficRules,
    invokePremiumandRating_HomeOwners_Packages,
    invokePremiumandRating_HomeOwners_Packages_V02,
    toggleView,
    AvailableMarket_toggleView,
    createQuote_Orchid,
    applyToAll,
    createQuoteNC,
    continueFirm_Quote,
    referSub_UW,
    referSub_request,
    referSub_Package,
    DeclineSubmission:DeclineSubmission_HomeOwners,
    toggleButton,
    tableFunctions: {
      PreviewRateIndication:PreviewRateIndication,
      ViewRateIndication:ViewRateIndication,
      dummyGetInteg:dummyGetInteg_Homes_RateIndication,
      QuotaShare_popup:QuotaShare_popup,
      viewtoggle: viewtoggle,
      closeToggle: closeToggle
    }
  },
  handleInputChangeV2: handleInputChangeV2_Orchid,
}

const openPopUp_HomeOwners=async(state,props,EmailDetail,dialog="email")=>{
  try{
    let viewEmail;
    props.UpdateState('loading', true, true);
  let request=await constructTransactionRequestV2(state,props,{},false,false);
  let commonEventName=GetCommonEventName();
  let currentTemplate=props.pageInfo;
  request.EventName=commonEventName;
  if(EmailDetail){
    request.TargetEventName=props.pageInfo.eventName.getEmailDetail;
    request.ID=props.rowData.ID;
    request.PARENTID=props.componentProps.UIFlowReducer.quoteID;
    viewEmail ="true"
  }else{
    request.ID=props.componentProps.UIFlowReducer.quoteID;
    request.TargetEventName=props.pageInfo.eventName.getPrefilledEmail;
    viewEmail = "false"
  }
  const response=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
  // decodeHTML(response.data)
  let tObj={
    transactionPrimaryObject:{...props.componentState.transactionPrimaryObject,...response.data,viewEmail}
  }
  let {objectId,objectVerId}=currentTemplate
  let subAppInfo={
    subappName:props.componentState.subappNameList,
    objectId,
    objectVerId
  }
  const metares=await constructAndInvokeMetadataCall(subAppInfo,tObj)
  props.UpdateState("pageMetadata",metares,false);
  props.UpdateState("pagelevelValues",{...props.componentState.pagelevelValues,...response.data},false)
  props.UpdateState('loading', false, false);
  props.UpdateState(`${dialog}_showDialog`, true, true);
  }catch(e){
    console.log("Error in openPopUp_HomeOwners",e)
  }
}
const openPopUp_HomeOwners_CL=async(state,props,EmailDetail)=>{
  try{
    let viewEmail,detail={};
    props.UpdateState('loading', true, true);
  let currentTemplate=Object.keys(props.componentProps.UIFlowReducer.currentPageInfo).length>0 ? props.componentProps.UIFlowReducer.currentPageInfo : props.componentProps.UIFlowReducer.currentPageInfo;
  let routeInfo = store.getState().metadataReducer.route? store.getState().metadataReducer.route :''
  if(routeInfo==='Quote'){
    detail={TargetEventName:props.pageInfo.eventName.getPrefilledEmail,
      ROOTID:props.componentProps.UIFlowReducer.QuoteID,
    PARENTID:props.componentProps.UIFlowReducer.QuoteID,
      QuoteID:props.componentProps.UIFlowReducer.quoteID,PolicyID:"",EventName:GetCommonEventName()}
  }else if(routeInfo==='Policy'){
    detail={TargetEventName:props.pageInfo.eventName.getPrefilledEmail,ROOTID:props.componentProps.UIFlowReducer.policyID,PARENTID:props.componentProps.UIFlowReducer.policyID
      ,QuoteID:"",PolicyId:props.componentProps.UIFlowReducer.policyID,EventName:GetCommonEventName()}
  }else if(routeInfo==='Endorsement'){
    detail={TargetEventName:props.pageInfo.eventName.getPrefilledEmail,ROOTID:props.UIFlowReducer.quoteID,PARENTID:props.UIFlowReducer.quoteID
      ,QuoteID:props.componentProps.UIFlowReducer.quoteID,PolicyId:"",EventName:GetCommonEventName()}
  }
  let request=await constructTransactionRequestV2(state,props,{},false,false);
  Object.assign(request,detail)
  if(EmailDetail){
    request.ID=props.rowData.ID;
    viewEmail ="true"
  }else{
    viewEmail = "false"
  }
  request['ProductVerNumber']= props.componentProps.productDetails.ProductVerNumber
  const response=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
  let tObj={
    transactionPrimaryObject:{...props.componentState.transactionPrimaryObject,...response.data,viewEmail}
  }
  let {objectId,objectVerId}=currentTemplate
  let subAppInfo={
    subappName:props.componentState.subappNameList,
    objectId,
    objectVerId
  }
  const metares=await constructAndInvokeMetadataCall(subAppInfo,tObj)
  props.UpdateState("pageMetadata",metares,false);
  props.UpdateState("pagelevelValues",{...props.componentState.pagelevelValues,...response.data},false)
  props.UpdateState('loading', false, false);
  props.UpdateState('email_showDialog', true, true);
  }catch(e){
    console.log("Error in openPopUp_HomeOwners",e)
  }
}
const getEmailDetail_HomeOwners=(props)=>{
  openPopUp_HomeOwners(undefined,props,true);
}
const getEmailDetail_HomeOwners_CL=(props)=>{
  openPopUp_HomeOwners_CL(undefined,props,true);
}

async function onSaveBusinessRule_HomeOwners(EventName,propsObj,stateObj,component,functions,e,props){
  try{
  functions.UpdateState("loading",true,true)
  let subapplist=[`${props.componentProps.SublineList.SublineName}CoverageDetails`]
  reteriveSubappList(props.componentState.pageMetadata.ApplicationDetail.SubApplicationDetailList,`${props.componentProps.SublineList.SublineName}CoverageDetails`,subapplist,true)
  let details={TargetEventName:props.pageInfo.saveBusinessRule,
    [`${component.AttributeName}`]:stateObj[`${component.AttributeName.toLowerCase()}_dataList`],EventName:GetCommonEventName(),
    [`${component.AttributeParams}`]:stateObj.pagelevelValues[`${component.AttributeParams}`]}
    let request=constructTransactionRequestV2(stateObj,props.componentProps,{},true,false)
    Object.assign(request,details)
    request.ROOTID=propsObj.UIFlowReducer.submissionID;
    await AxiosInstance.post("core/invokeWorkflowEvent/V2",request).then(async(response) => {
      let constructTpo = {...stateObj.transactionPrimaryObject,AvailableMarket:{...props.componentProps.AvailableMarket,...propsObj.AvailableMarketList_dataList[props.componentState.IteratorIndex]["AvailableMarket"]},"AvailableMarket__IsReferralRuleApplied":"false",...response.data}
      let mountResult = await componentDidMount_Orchid(stateObj, propsObj, {...constructTpo,"saveRules":"true"})
      let newList = propsObj.AvailableMarketList_dataList;
      newList[props.componentState.IteratorIndex]['SubApplicationDetailList']= mountResult.pageMetadata.ApplicationDetail.SubApplicationDetailList
      props.componentProps.parentState.UpdateState("AvailableMarketList_dataList",newList,false);
      functions.UpdateState("transactionPrimaryObject",{...constructTpo},false)
      functions.UpdateState("pagelevelValues",stateObj.pagelevelValues,false)
      let temp={}
        temp.ruleinformationlistpl_dataList=response.data.RuleInformationList
        await mergeRowdatawithDatalist(mountResult.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{})
        functions.UpdateState([`${component.AttributeName.toLowerCase()}_dataList`],response.data.RuleInformationList,false)
        if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() == "failed"){
          triggerToast('Error in Save Business Rule')
        }else successToast("Business Rules Saved Successfully");
    })
  }catch(e){
    console.log("Error in Save Rules",e);
  }finally{
    functions.UpdateState("loading",false,true)
  }
}

const quoteListTableUtils = {
  "AddRow": null,
  "onExpand": null,
  "onChildSave": null,
  "EditRow": null,
  "OnDetailEdit": "",
  "redirectTo": ""
}
const quoteListTableOptions =  {
  "ChildrenSubApplicationName": null,
  "ChildrenComponent": null,
  "AddButtonNeeded": false,
  "SortingNeeded": false,
  "FilteringNeeded": false,
  "FilterIconNeeded": false,
  "RowsPerPage": 5,
  "rowsPerPageOptions": [5,10,25],
  "ActionName":null,
  "ActionListOptions": []
}

const notesTableOptions={
      ...quoteListTableOptions,
      ...{"ChildrenComponent":"childcomp",
          "AddButtonNeeded": true,"ActionName":"",
          "ActionListOptions": [{ActionDisplayName: "Delete",OnOptionClick: onDeleteNotesandAttachmentsQuote}]
        }
      }

const notesTableOptionsPolicy={
  ...quoteListTableOptions,
  ...{"ChildrenComponent":"childcomp",
      "AddButtonNeeded": true,"ActionName":"",
      "ActionListOptions": [{ActionDisplayName: "Delete",OnOptionClick: onDeleteNotesandAttachmentsPolicy}]
    }
  }

const getunderwriterworksheet_rating_obj = {
        getunderwriterworksheet_rating
      }

      const componentDidMount_PolicyDetails = async(state, props, UpdateState)=>{
        try{
          UpdateState("loading",true,true)
          let {getQuoteListTargetEventName,getQuoteListSearchName,getPolicyTargetEventName} =  props.pageInfo
          let pollEmail = props.pageInfo.pollEmail
          let getPolicyResponse= await getPolicyData(props,getPolicyTargetEventName)
          props.setUpdateExpandPanel && props.setUpdateExpandPanel(true);
          await store.dispatch({type: actionTypes.SET_INSUREDID, payload: getPolicyResponse.data.InsuredID});
          let getQuoteListResponse = await getQuoteList(props,getQuoteListTargetEventName,getQuoteListSearchName)
          let{transactionPrimaryObject}=state
          if(getPolicyResponse.data.Location){
          transactionPrimaryObject["StateId"]=getPolicyResponse.data.Location.StateRegionCode;
          }
          transactionPrimaryObject["DiligentEffortInformation"] = getPolicyResponse.data.DiligentEffort
          transactionPrimaryObject["IssueBinderNavigator"]="false"
          transactionPrimaryObject["TriggerBusinessRules"]="false"
          let response = await componentDidMount_Orchid(state,props,{...transactionPrimaryObject,...getPolicyResponse.data})
          let getEmailEventname = props.pageInfo.eventName.getEmailList;
          let getEmailList = getEmailEventname && await getEmail(props,response,props.pageInfo.eventName.getEmailList,getPolicyResponse.data.QuoteID)
          if(pollEmail){
            let intervalData;
            let intervalSec = 1000 * props.pageInfo.intervalSec;
            let timeOutSec = 1000 * props.pageInfo.timeOutSec;
            intervalData = setInterval(function() {
              getEmailList = updateEmailList(props,response,getEmailEventname,getPolicyResponse.data.QuoteID,UpdateState); }
              ,intervalSec)
            UpdateState('intervalData',intervalData,false)
            setTimeout(() => {
              clearInterval(intervalData);
            }, timeOutSec);
            }
          let translatedResponse=await EntitySubAppTranslationV2({...getPolicyResponse.data},{...response.pageMetadata.ApplicationDetail})
          let temp={}
          temp.ruleinformationlist_dataList=getPolicyResponse.data.RuleInformationList
          temp.policylist_dataList = getQuoteListResponse
          temp.emailsummary_dataList =getEmailList && getEmailList.data && getEmailList.data.Email
          // temp.ceaseinformationlistinpolicy_dataList=getPolicyResponse.data.CeaseInformationList
          await mergeRowdatawithDatalist(response.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{});
          UpdateState('templateClassName',props.pageInfo.className+"_readOnly",false)
          if(props.productDetails && props.productDetails.LOBName==='MPL'){
            return {
              ...response,
              loading : false,
              pagelevelValues : translatedResponse,
              formsmpl_dataList:getPolicyResponse.data.FormsMPL,
              documentsmpl_dataList : getPolicyResponse.data.DocumentsMPL,
              ruleinformationlist_dataList:getPolicyResponse.data.RuleInformationList,
              policyattachments_dataList: getPolicyResponse.data.PolicyAttachments,
              policylist_dataList : getQuoteListResponse,
              subjectivity_dataList: getPolicyResponse.data.Subjectivity
            }
          }
          return {
            loading : false,
            detailSubApp: response.detailSubApp,
            detailSummaryList : response.detailSummaryList,
            subappNameList : response.subappNameList,
            summarySubApp : response.summarySubApp,
            pageMetadata : response.pageMetadata,
            pagelevelValues : {...transactionPrimaryObject,...translatedResponse},
            forms_dataList:getPolicyResponse.data.Forms ? getPolicyResponse.data.Forms : [],
            documents_dataList : getPolicyResponse.data.Documents ? getPolicyResponse.data.Documents : [],
            notes_dataList:getPolicyResponse.data.Notes ? getPolicyResponse.data.Notes : [],
            ruleinformationlist_dataList:getPolicyResponse.data.RuleInformationList ? getPolicyResponse.data.RuleInformationList : [],
            // ceaseinformationlistinpolicy_dataList:getPolicyResponse.data.CeaseInformationList ? getPolicyResponse.data.CeaseInformationList : [],
            attachments_dataList:getPolicyResponse.data.Attachments ? getPolicyResponse.data.Attachments : [],
            policyattachments_dataList: getPolicyResponse.data.PolicyAttachments ? getPolicyResponse.data.PolicyAttachments : [],
            policylist_dataList : getQuoteListResponse,
            emailsummary_dataList : getEmailList.data.Email ? getEmailList.data.Email : [],
            summaryfeedetailssp_dataList : getPolicyResponse.data.Fee ? getPolicyResponse.data.Fee : [],
            summarytaxdetailssp_dataList : getPolicyResponse.data.Tax ? getPolicyResponse.data.Tax : [],
            transactionPrimaryObject : {...transactionPrimaryObject,...getPolicyResponse.data,...translatedResponse}
          }
        }catch(err){
          console.log('Error in componentDidMount_PolicyDetails',err)
          UpdateState("loading",false,true)
        }
      }

      const componentDidMount_Policy = async(state,props,UpdateState)=>{
        try{
          UpdateState("loading",true,true )
          let policynav =[]
          let reduxState = store.getState();
          let ID = reduxState.UIFlowReducer.policyID;
          let pageData={}
          if(props.pageInfo.dynamicFlow && reduxState.ownerResources.clientInfo && reduxState.ownerResources.clientInfo.multiTabEnable === 'Y'){
            let val=window.location.hash.split('/');
              if(val.length > 1 && val[2]){
                  let newVal=val[2];
                  pageData = decodeString(newVal)
              }
              ID=pageData && pageData.policyID ? pageData.policyID:reduxState.UIFlowReducer.policyID
              if(pageData && pageData.hasOwnProperty('Flow')){
                store.dispatch({type: actionType.SET_FLOW, payload: pageData.Flow});
              }
              store.dispatch({type: actionType.SET_UI_VALUES,payload: pageData});
          }
          let productDetails = await getProductDetailsByPolicyId(ID,props)
          if(ID==="ShrinkWrap-Testing")
            productDetails={
                  "ProductVerID": "362094",
                  "ProductName": "Shrink Wrap Product",
                  "LOBName": "SWP",
                  "LineOfBusiness": "SWP",
                  "ProductVerNumber": "SWP_1_V1",
                  "END_DATE": "2123-10-01 00:00:00.0",
                  "ProductID": "363023",
                  "InsuranceType": "ShrinkWrap",
                  "ProductNumber": "SWP_1"
              }
          await store.dispatch({
            type: actionType.SET_POLICY_DETAILS,
            payload: {
              policyID : ID
            }
          });
          await store.dispatch({
            type: actionType.SET_QUOTE_DETAILS,
            payload: ''
          });
          store.dispatch({
              type: "SET_LOB",
              payload: productDetails.LOBName,
          });
          store.dispatch({
              type: "SET_PRODUCT_DETAILS",
              payload: productDetails,
          });
          const LOB = productDetails.LOBName
          let templateInfoTemplates = reduxState.navigationReducer.templateInfo
          let policyObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"],[productDetails.ProductVerNumber]);
          if(policyObj.hasOwnProperty('productTemplateInfo')){
            //Util Construction in Templates
            Object.keys(policyObj.productTemplateInfo).forEach( async (template) => {
              policyObj.productTemplateInfo[template] = await constructTemplateWithUtil(policyObj.productTemplateInfo[template])
            })
            //SubTemplate construction in Templates
            Object.keys(policyObj.productTemplateInfo).forEach( async (template) => {
              policyObj.productTemplateInfo[template] = await constructTemplateWithUtil(policyObj.productTemplateInfo[template],['details','template'],policyObj.productTemplateInfo)
            })
            store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...policyObj.productTemplateInfo}})
          }
          let templatefromredux = {...templateInfoTemplates,...policyObj.productTemplateInfo};
          if (policyObj.hasOwnProperty("productNavigation")) {
            templateConstruct(policyObj, ["template"], templatefromredux);
            policynav = policyObj.productNavigation[`${LOB}_policy`];
            let pageNav = Object.assign({}, props.navigation);
            let pagename =props.pageInfo.landingpage;
            if(props.UIFlowReducer.pageName !== "" && !props.pageInfo.dynamicFlow)
            pagename=props.UIFlowReducer.pageName;
            if(props.pageInfo.dynamicFlow && pageData.pageName)
              {
                pagename = pageData.pageName
              }
            pageNav.pageNavigation.policy.sidebar[getRequiredIndex(pageNav.pageNavigation.policy.sidebar,'name','Policy')].children =  policynav
            let index = await getIndex(props,policynav,getRequiredIndex,pagename)
            await props.setNavigation(
              { ...pageNav, "productNavigation": { ...policyObj.productNavigation } }
            )
            await store.dispatch({
              type: actionTypes.SET_PAGENAME,
              payload: policynav[index]['pageName']
            })
            if(props.UIFlowReducer.pageName !== "")
    UpdateState("currentPageInfo",policynav[index],false)
    UpdateState("loading", false, false)
            store.dispatch(setCurrentPageIndex(policynav[index]["index"]))
            await store.dispatch({type: actionType.SET_ROUTE_INFO,payload:"Policy"})
          }
        } catch(err){
          console.log('Error in ComponentDidMount_Policy',err)
        }
      }

const policyDetailInformation = async(state, props, prevState, prevProps, UpdateState,newProps,res)=>{
  UpdateState("loading",true,true)
  let {getPolicyTargetEventName,getQuoteListTargetEventName,getQuoteListSearchName} =  props.pageInfo
  let getPolicyResponse= await getPolicyData(props,getPolicyTargetEventName)
  await store.dispatch({type: actionTypes.SET_INSUREDID, payload: getPolicyResponse.data.InsuredID});
  let getQuoteListResponse = await getQuoteList(props,getQuoteListTargetEventName,getQuoteListSearchName)
  let{transactionPrimaryObject}=state
  if(getPolicyResponse.data.Location){
  transactionPrimaryObject["StateId"]=getPolicyResponse.data.Location.StateRegionCode;
  }
  transactionPrimaryObject["DiligentEffortInformation"] = getPolicyResponse.data.DiligentEffort
  transactionPrimaryObject["IssueBinderNavigator"]="false"
  if(res && res.data && res.data.hasOwnProperty('RuleInformationList') && !res.data.hasOwnProperty("PolicyID")){
    UpdateState("viewbusinessrules_dataList",res.data.RuleInformationList,false)
    transactionPrimaryObject["TriggerBusinessRules"]="true"
  }else{
    transactionPrimaryObject["TriggerBusinessRules"]="false"
  }
  let response = await componentDidMount_Orchid(state,newProps,{...transactionPrimaryObject,...getPolicyResponse.data})
  let getEmailEventname = props.pageInfo.eventName.getEmailList;
 let getEmailList = getEmailEventname && await getEmail(props,response,props.pageInfo.eventName.getEmailList,getPolicyResponse.data.QuoteID)
  let translatedResponse=await EntitySubAppTranslationV2({...getPolicyResponse.data},{...response.pageMetadata.ApplicationDetail})
  let temp={}
  temp.ruleinformationlist_dataList=getPolicyResponse.data && getPolicyResponse.data.RuleInformationList
  temp.policylist_dataList = getQuoteListResponse
  temp.emailsummary_dataList =getEmailList && getEmailList.data && getEmailList.data.Email
  temp.ceaseinformationlistinpolicy_dataList=getPolicyResponse.data && getPolicyResponse.data.CeaseInformationList
  await mergeRowdatawithDatalist(response.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{});
  UpdateState('templateClassName',props.pageInfo.className+"_readOnly",false)
  if(props.productDetails && props.productDetails.LOBName==='MPL'){
    return {
      ...response,
      loading : false,
      pagelevelValues : translatedResponse,
      formsmpl_dataList:getPolicyResponse.data && getPolicyResponse.data.FormsMPL,
      documentsmpl_dataList : getPolicyResponse.data &&  getPolicyResponse.data.DocumentsMPL,
      ruleinformationlist_dataList: getPolicyResponse.data && getPolicyResponse.data.RuleInformationList,
      policyattachments_dataList:getPolicyResponse.data &&  getPolicyResponse.data.PolicyAttachments,
      policylist_dataList : getQuoteListResponse,
      subjectivity_dataList: getPolicyResponse.data && getPolicyResponse.data.Subjectivity
    }
  }
  return {
    loading : false,
    detailSubApp: response.detailSubApp,
    detailSummaryList : response.detailSummaryList,
    subappNameList : response.subappNameList,
    summarySubApp : response.summarySubApp,
    pageMetadata : response.pageMetadata,
    pagelevelValues : translatedResponse,
    forms_dataList: getPolicyResponse.data && getPolicyResponse.data.Forms ? getPolicyResponse.data.Forms : [],
    documents_dataList :getPolicyResponse.data &&  getPolicyResponse.data.Documents ? getPolicyResponse.data.Documents : [],
    notes_dataList:getPolicyResponse.data && getPolicyResponse.data.Notes ? getPolicyResponse.data.Notes : [],
    ruleinformationlist_dataList:getPolicyResponse.data && getPolicyResponse.data.RuleInformationList ? getPolicyResponse.data.RuleInformationList : [],
    ceaseinformationlistinpolicy_dataList:getPolicyResponse.data && getPolicyResponse.data.CeaseInformationList ? getPolicyResponse.data.CeaseInformationList : [],
    attachments_dataList:getPolicyResponse.data && getPolicyResponse.data.Attachments ? getPolicyResponse.data.Attachments : [],
    policyattachments_dataList: getPolicyResponse.data && getPolicyResponse.data.PolicyAttachments ? getPolicyResponse.data.PolicyAttachments : [],
    policylist_dataList : getQuoteListResponse,
    emailsummary_dataList :getPolicyResponse.data  && getEmailList &&  getEmailList.data.Email ? getEmailList.data.Email : [],
    summaryfeedetailssp_dataList :getPolicyResponse.data &&  getPolicyResponse.data.Fee ? getPolicyResponse.data.Fee : [],
    summarytaxdetailssp_dataList :getPolicyResponse.data &&  getPolicyResponse.data.Tax ? getPolicyResponse.data.Tax : []
  }
}

const getPolicyData = async(props,getPolicyTargetEventName)=>{
  let reduxState = store.getState();
  let request = {}
  request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request.EventName =  GetCommonEventName();
  if(props.pageInfo && props.pageInfo.getPolicyEventAction)
      request.EventAction = props.pageInfo.getPolicyEventAction;
  request.TargetEventName = getPolicyTargetEventName;
  request.ID =  reduxState.UIFlowReducer.policyID;
  request.ProductNumber = reduxState.UIFlowReducer.productDetails.ProductNumber;
  request.ProductVerNumber = reduxState.UIFlowReducer.productDetails.ProductVerNumber;
  let response = await AxiosInstance.post("core/invokeWorkflowEvent/V2", request);
  let PolicyStatus = response.data.PolicyStatus
  let PolicyNumber = response.data.PolicyNumber
  let PolicyFullNumber = response.data.PolicyFullNumber
  let quoteStatus = response.data.QuoteStatus
  let quoteNumber = response.data.QuoteNumber
  let quoteFullNumber = response.data.QuoteFullNumber
  let FeeName = response.data.Fee && response.data.Fee.length > 0 ? true : false;
  let TaxName = response.data.Tax && response.data.Tax.length > 0 ? true : false;
  await store.dispatch({type: actionTypes.SET_FEE_NAME, payload: FeeName});
  await store.dispatch({type: actionTypes.SET_TAX_NAME, payload: TaxName});
  await store.dispatch({
    type: actionType.SET_POLICY_DETAILS,
    payload: {
      PolicyStatus,
      PolicyNumber,
     PolicyFullNumber
    }
  });
  await store.dispatch({
    type: actionType.SET_QUOTE_DETAILS,
    payload: {
      quoteStatus,
      quoteNumber,
      quoteFullNumber
    }
  });
  store.dispatch({
    type: actionType.SET_QUOTE_STATUS,
    payload: {
      quoteStatus,
    }
  });
  await store.dispatch({
    type: actionType.SET_QUOTE_ID,
    payload: response.data.QuoteID
  });
  store.dispatch({
    type: actionType.SET_SUBMISSIONID,
    payload: response.data.SubmissionID
 })
 if(response.data && response.data.ProcessStatus){
  await store.dispatch({type: appActions.actionType.SET_PROCESS_STATUS, payload: response.data.ProcessStatus});
}
 if(props.pageInfo.ratingRequestResponseNeeded){
  await invokeThirdPartyApi(props,response)
  }
  props.setUpdateExpandPanel && props.setUpdateExpandPanel(true);
  props.setexpandPanelDetailData && props.setexpandPanelDetailData(response.data);
  return response
}

const previewIssuance_Orchid_Homeowners = async (propsObj,stateObj,component,e,props) => {
  const {functions:{UpdateState}} = props
  try{
    UpdateState('loading',true,true)
    let request = {}
    request["ServiceRequestDetail"] = getServiceRequestDetail()
    request.ServiceRequestDetail['Lob'] = props.componentProps.productDetails.LOBName
    request.TargetEventName = component.EventName;
    request.EventName =  GetCommonEventName();
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.ID = propsObj.UIFlowReducer.policyID
    try{
     await AxiosInstance.post(
        "/core/invokeWorkflowEvent/V2",
        request
      ).then( response => {
          let transactionRootPath = store.getState().ownerResources.clientInfo.UI_MEMORY_PDF_ROOT_PATH
          let relativePath = response.data.Documents.DocumentRelativePath
          let documentName = response.data.Documents.DocumentName
          FileHandlerAxios(`${transactionRootPath}${relativePath}${documentName}`)
      })
    }catch(err){
      console.log('Error in API Response : API Response Failed');
    }
    UpdateState('loading',false,true)
  }catch(error){
    console.log('Error in previewIssuance_Orchid_Homeowners',error);
  }
 }

const returnToQuoteDetails = async(propsObj,stateObj,component,e,props)=>{
  let response = await getPolicyData(propsObj,component.EventName)
  let quoteID = response.data.QuoteID
  await store.dispatch({
    type: actionType.SET_QUOTE_ID,
    payload: quoteID
  });
  await store.dispatch({
    type : actionTypes.SET_QUOTECONSOLE_INFO,
   payload :  {}
  })
  await store.dispatch({
    type : actionTypes.SET_POLICYCONSOLE_INFO,
   payload :  {}
  })
  store.dispatch({type: actionType.SET_PAGENAME, payload: ""});
  props.componentProps.history.push("/quote")
}

const returnToPolicyDetails = async(propsObj,stateObj,component,e,props)=>{
  let routeInfo = store.getState().metadataReducer.route ? store.getState().metadataReducer.route:''
	  if(routeInfo=='Quote'){
  let response = await getQuoteData(propsObj,component.EventName,props.functions.UpdateState)
  let policyID = response.data.PolicyID
  await store.dispatch({
    type:actionType.SET_POLICY_ID,
    payload:policyID
  });
	}
  await store.dispatch({
    type : actionTypes.SET_POLICYCONSOLE_INFO,
   payload :  {}
  })
  await store.dispatch({
    type : actionTypes.SET_QUOTECONSOLE_INFO,
   payload :  {}
  })
  store.dispatch({type: actionType.SET_PAGENAME, payload: ""});
  props.componentProps.history.push("/policy")
}

 const issuePolicy_Orchid_Homeowners = async (e,props,state,component,functions) => {
  let{UpdateState}=functions
  try{
    UpdateState("loading",true,true)
    let request=constructTransactionRequestV2(state,props,{},true,false)
    request["ServiceRequestDetail"]["Lob"]=props.productDetails.LOBName;
    request.EventName =  GetCommonEventName();
    if(props.pageInfo && props.pageInfo.eventAction)
      request["EventAction"] = props.pageInfo.eventAction;
    request.TargetEventName=component.EventName
    request.ID = store.getState().UIFlowReducer.policyID;
    if(props.pageInfo.hasOwnProperty("AdditionalKeys") && Object.keys(props.pageInfo.AdditionalKeys).length>0){
      request = {...request,...props.pageInfo.AdditionalKeys}
    }
    Object.assign(request, props.productDetails)
    AxiosInstance.post(
      "/core/invokeWorkflowEvent/V2",
      request
    ).then(async (response) => {
    try{
      let PolicyStatus = response.data.PolicyStatus
      let PolicyNumber = response.data.PolicyNumber
      let PolicyFullNumber = response.data.PolicyFullNumber
      store.dispatch({type: actionType.SET_POLICY_DETAILS,payload: {PolicyStatus,PolicyNumber,PolicyFullNumber}})
      const UIFlowReducer = store.getState().UIFlowReducer
      props = {...props,UIFlowReducer}
      let newProps ={
        subApplication :props.pageInfo.subApplication,
        objectId :props.pageInfo.objectId,
        objectVerId :props.pageInfo.objectVerId
      }
      const policyDetailResponse = await policyDetailInformation(state, props, null, null, UpdateState,newProps,response)
      const keyValuePairs = Object.entries(policyDetailResponse)
      keyValuePairs.forEach((value,i)=>{
        let updateFlag = (keyValuePairs.length)-1===i
        value[1] && UpdateState(value[0],value[1],updateFlag)
      })
      props.setUpdateExpandPanel && props.setUpdateExpandPanel(true);
      UpdateState('loading',false,true)
    }
    catch(error){
      console.log('Error in IssuePolicy',error);
    }
    if(response.data.hasOwnProperty('PolicyStatus') && response.data.PolicyStatus.toLowerCase() === "issued"){
        successToast("Policy Issued successfully",  { position: Toast.POSITION.TOP_RIGHT});
    }
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
      console.log('Error in IssuePolicy : API Response Failed')
      triggerToast("Unable to Issue Policy");
    }
    if(props.pageInfo.ratingRequestResponseNeeded){
      await invokeThirdPartyApi(props,response)
    }
    });
  }catch(error){
    console.log('Error in issuePolicy_Orchid_Homeowners',error);
  }
 }
 const issuePolicy_Orchid_Homeowners_V1 = async (req,props,e,state) => {
  let {component,functions} = props
  let{UpdateState} = functions
  try{
    UpdateState("loading",true,true)
    let reduxState = store.getState();
    let request =await constructTransactionRequestV1(state,props.componentProps,state.pagelevelValues,false,true,false,false);
    request["ServiceRequestDetail"]=getServiceRequestDetail();
    request["ServiceRequestDetail"]["Lob"]=props.componentProps.productDetails.LOBName;
    request.EventName =  GetCommonEventName();
    if(props.pageInfo && props.pageInfo.eventAction)
      request["EventAction"] = props.pageInfo.eventAction;
    if(props.pageInfo && props.pageInfo.TransactionType)
      request["TransactionType"] = props.pageInfo.TransactionType;
    if(props.pageInfo && props.pageInfo.RequiredProcess)
      request["RequiredProcess"] = props.pageInfo.RequiredProcess;
    request.TargetEventName=component.EventName
    request.ID = reduxState.UIFlowReducer.policyID;
    request.QuoteId = reduxState.UIFlowReducer.quoteID;
    if(props.pageInfo.hasOwnProperty("AdditionalKeys") && Object.keys(props.pageInfo.AdditionalKeys).length>0){
      request = {...request,...props.pageInfo.AdditionalKeys}
    }
    let {data:policyResponse}=await getPolicyData(props,props.pageInfo.getPolicyTargetEventName)
    let newProps ={
        subApplication :props.pageInfo.subappNameList,
        objectId :props.pageInfo.objectId,
        objectVerId :props.pageInfo.objectVerId
      }
    Object.assign(request, props.componentProps.productDetails)
    AxiosInstance.post(
      "/core/invokeWorkflowEvent/V2",
      request
    ).then(async (response) => {
    try{
      if(response.data.hasOwnProperty("Subjectivity")&& response.data.hasOwnProperty("AllowBind")&& response.data.AllowBind==="N"){
        let Subjectivity = "true";
        let tpo={...policyResponse,"Subjectivity":Subjectivity,"AllowBind":response.data.AllowBind}
        let mountResponse = await componentDidMount_Orchid(state,newProps,{...tpo})
        UpdateState("subjectivityvalidationssummary_dataList",response.data.Subjectivity,false)
        UpdateState("pageMetadata", mountResponse.pageMetadata, false);
      }
      else if( response.data.hasOwnProperty("RuleInformationList") && response.data.RuleInformationList.length > 0  && !(response.data.PolicyStatus && response.data.PolicyStatus === "Issued")){
        let tpo={...policyResponse,...response.data,"TriggerBusinessRules":"true"}
        let mountResponse = await componentDidMount_Orchid(state,newProps,{...tpo})
        UpdateState("viewbusinessrules_dataList",response.data.RuleInformationList,false)
        UpdateState("pageMetadata", mountResponse.pageMetadata, false);
      }
      else{
        let PolicyStatus = response.data.PolicyStatus
        let PolicyNumber = response.data.PolicyNumber
        let PolicyFullNumber = response.data.PolicyFullNumber
        store.dispatch({type: actionType.SET_POLICY_DETAILS,payload: {PolicyStatus,PolicyNumber,PolicyFullNumber}})
        store.dispatch({type: actionType.SET_PAGENAME, payload: props.pageInfo.RedirectPageName});
      }
      UpdateState('loading',false,true)
    }
    catch(error){
      console.log('Error in IssuePolicy',error);
    }
    if(response.data.hasOwnProperty('PolicyStatus') && response.data.PolicyStatus.toLowerCase() === "issued"){
        successToast("Policy Issued successfully",  { position: Toast.POSITION.TOP_RIGHT});
    }
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
      console.log('Error in IssuePolicy : API Response Failed')
      triggerToast("Unable to Issue Policy");
    }
    });
  }catch(error){
    console.log('Error in issuePolicy_Orchid_Homeowners',error);
  }
 }
const multipleUploads_HomeOwners=(props,setAttachment)=>{
  if(props.parentProps){
      let {parentProps:{pagelevelValues},component}=props;
      if(pagelevelValues && pagelevelValues[component.SubApplicationName] && pagelevelValues[component.SubApplicationName][component.AttributeName]){
        let onLoadValues=pagelevelValues[component.SubApplicationName][component.AttributeName]
        setAttachment(onLoadValues)
      }
    }
}
const ClosePopUp_HomeOwners=(state,props)=>{
  props.UpdateState('email_showDialog', false, true);
}

const ClosePopUp=(EventName,propsObj,stateObj,component,functions,e,props)=>props.UpdateState(`${component.SubApplicationName.toLowerCase()}_showDialog`, false, true);

const SendEmail_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
  props.UpdateState("loading",true,true);
  let id= propsObj.UIFlowReducer.quoteID
  let EventName1=propsObj.pageInfo.eventName.sendEmail
  let EventName2=propsObj.pageInfo.eventName.getEmailList;
  let requestForSendEmail=await EmailRequestConstruct(propsObj,stateObj,EventName1,id)
  requestForSendEmail[component.SubApplicationName]={...stateObj.pagelevelValues[component.SubApplicationName]}
  // sanitizeData(requestForSendEmail['Email'])
  let redux =store.getState();
  let transactionRootPath=redux.ownerResources.clientInfo.UI_TRANSACTION_ROOT_PATH;
  let url = requestForSendEmail[`${component.SubApplicationName}`][`${component.ResponseKey}`];
  let response;
  if(url){
    let filePath = url.map((value)=>(`${transactionRootPath}`+value.AttachmentUrl+value.AttachmentName));
  await AxiosInstance.post(`filehandler/getFileSize`,{rootPath:filePath,maxvalue:component.MaxValue})
  .then(async(resp) => {
    response = resp;
    if(resp.data.fileSizeValidation==='false'){
      let {transactionPrimaryObject} = stateObj
      let tpo={...transactionPrimaryObject,...resp.data}
      let subappinfo = { subappName:stateObj.subappNameList, objectId: propsObj.objectId, objectVerId: propsObj.objectVerId, };
      let metadataResponse = await constructAndInvokeMetadataCall(subappinfo, {transactionPrimaryObject:tpo});
      props.UpdateState("pageMetadata", metadataResponse, false);
    }
    return response;
  })
  }
  if (!url || response.data.fileSizeValidation==='true'){
    const responseFromSendEmail=await AxiosInstance.post("core/invokeWorkflowEvent/V2",requestForSendEmail);
    let getMailResponse = await getEmail(propsObj,stateObj,EventName2,id);
    props.UpdateState("emailsummary_dataList",getMailResponse.data.Email,false);
    props.UpdateState(`${component.SubApplicationName.toLowerCase()}_showDialog`, false, false);
  }
  }catch(e){
    console.log("Error in SendEmail_HomeOwners",e);
  }
  finally{
    props.UpdateState("loading",false,true);
  }
}

const EmailRequestConstruct=async(propsObj,stateObj,EventName,id)=>{
  let request=await constructTransactionRequestV2(stateObj,propsObj,{},false,false);
  request.ID=id;
  request.TargetEventName=EventName;
  let commonEventName=GetCommonEventName();
  request.EventName=commonEventName;
  if(stateObj && stateObj.pagelevelValues && stateObj.pagelevelValues.hasOwnProperty("TitanCategoryID")){
    request.TitanCategoryID=stateObj.pagelevelValues.TitanCategoryID
  }
  return request;
}

const getEmail = async(propsObj,stateObj,EventName,id)=>{
  let requestForGetEmailList=await EmailRequestConstruct(propsObj,stateObj,EventName,id);
  const responseFromGetEmailList=await AxiosInstance.post("core/invokeWorkflowEvent/V2",requestForGetEmailList);
  return responseFromGetEmailList
}

const componentDidMount_Endorsement=async(state,props,UpdateState)=>{
  try{
    UpdateState("loading",true,true)
    let reduxState = store.getState();
    let routeNav = [];
    let templateInfoTemplates = reduxState.navigationReducer.templateInfo
    await store.dispatch({ type: actionType.SET_ROUTE_INFO, payload: "Endorsement" })
    let LOB = reduxState.UIFlowReducer.productDetails.LOBName
    let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"], [reduxState.UIFlowReducer.productDetails.ProductVerNumber]);
    if(navObj.hasOwnProperty('productTemplateInfo')){
      //Util Construction in Templates
      Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
        navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template])
      })
      //SubTemplate construction in Templates
      Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
        navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
      })
      store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}})
    }
    let templatefromredux = {...templateInfoTemplates,...navObj.productTemplateInfo};
    if (navObj.hasOwnProperty("productNavigation")) {
      templateConstruct(navObj, ["template"], templatefromredux);
     const route = props.location.pathname.split('/')[1]
      routeNav = navObj.productNavigation[`${LOB}_${route}`]
        let pageNav = Object.assign({}, props.navigation);
      let pagename = props.pageInfo && props.pageInfo.landingpage;
      if(props.UIFlowReducer.pageName !== "")
      pagename=props.UIFlowReducer.pageName;
      pageNav.pageNavigation[route].sidebar[getRequiredIndex(pageNav.pageNavigation[route].sidebar,'name',props.pageInfo.name)].children =  routeNav
      let index = await getIndex(props,routeNav,getRequiredIndex,pagename)
       await props.setNavigation(
        { ...pageNav, "productNavigation": { ...navObj.productNavigation } }
      )
      await store.dispatch({
        type: actionTypes.SET_PAGENAME,
        payload: routeNav[index]['pageName']
      })
      if(props.UIFlowReducer.pageName !== "")
      UpdateState("currentPageInfo",routeNav[index],false)
      UpdateState("loading", false, false)
      store.dispatch(setCurrentPageIndex(routeNav[index]["index"]))
  }
  }
  catch (err) {
    console.log("Error in componentDidMount_Endorsement", err)
  }
}

const onResponseInspections = async (eventName,propsObj,stateObj,component,functions,e,props) => {
  try {
    let requestDetails = {
      OwnerId : sessionStorage.getItem("OwnerID"),
      EventName :  GetCommonEventName(),
      TargetEventName : eventName,
      ID : props.componentProps.UIFlowReducer.policyID,
      PARENTID : props.componentProps.UIFlowReducer.policyID,
      ROOTID : props.componentProps.UIFlowReducer.policyID
    }
    let request = {}
    request=await constructTransactionRequestV1(stateObj,propsObj,{...stateObj.pagelevelValues},false,true,false,false);
    request["ServiceRequestDetail"] = getServiceRequestDetail()
    Object.assign(request,requestDetails)
    let response = await AxiosInstance.post("core/invokeWorkflowEvent/V2", request);

    return response.data
  } catch(err){
    console.log('Error in onResponseInspections', err)
  }
}

const onSaveInspections = async(eventName,propsObj,stateObj,component,functions,e,props)=>{
  functions.UpdateState("loading",true,true)
  try{
     let response =await onResponseInspections(eventName,propsObj,stateObj,component,functions,e,props);
    if(response.hasOwnProperty('ResponseStatus') && response.ResponseStatus.toLowerCase() === "failed")
    {
      triggerToast("Failed to save inspection Details.",  { position: Toast.POSITION.TOP_RIGHT})
      console.log('Response Failed');
    }else{
      successToast("Inspection Details Saved successfully.");
    }
  }catch(e){
    console.log("error in onSaveInspections",e);
  }
  functions.UpdateState("loading",false,true)
}

const onInspectionReorder = async(eventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    functions.UpdateState('loading',true,true)
    let {objectId,objectVerId,subApplication} = propsObj.UIFlowReducer.currentPageInfo.template[0];
    let newProps = {
      objectId : objectId,
      objectVerId : objectVerId,
      subApplication : subApplication,
      subappName: subApplication
    }
    let {transactionPrimaryObject,pagelevelValues} = stateObj
    const res =await onResponseInspections(eventName,propsObj,stateObj,component,functions,e,props)

    let response=await componentDidMount_Orchid(stateObj,newProps,{...transactionPrimaryObject,...res});
   if(res.hasOwnProperty('ResponseStatus') && res.ResponseStatus.toLowerCase() === "failed")
   {
     triggerToast("Failed to reorder inspection vendors.",  { position: Toast.POSITION.TOP_RIGHT})
     console.log('Response Failed')
   }else{
     successToast("Inspection Reordered Successfully.");
     props.functions.UpdateState('pageMetadata',response.pageMetadata,false)
     props.functions.UpdateState('pagelevelValues',{...pagelevelValues,...res},false)
     props.functions.UpdateState('transactionPrimaryObject',{...transactionPrimaryObject,...res} ,false)
   }
   functions.UpdateState('loading',false,true)
  }catch(e){
    console.log('Error in onInspectionReorder',e)
    functions.UpdateState('loading',false,true)
  }
};
const onCancelInspections = async(eventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    functions.UpdateState('loading',true,true)
    let {objectId,objectVerId} = propsObj.UIFlowReducer.currentPageInfo.template[0];
    let subappInfo = {
      objectId : objectId,
      objectVerId : objectVerId,
      subappName: stateObj.subappNameList
    }
    let {transactionPrimaryObject} = stateObj
    let request=await constructTransactionRequestV1(stateObj,propsObj,{...stateObj.pagelevelValues},false,false,false,false);
    let tpo = {...transactionPrimaryObject,"Inspections":request["Inspections"]}
    const metadataresponse = await constructAndInvokeMetadataCall(subappInfo, {transactionPrimaryObject:{...tpo}}, null)
    let temp = deleteDependentAttribute(component, stateObj.pagelevelValues, stateObj.transactionPrimaryObject)
    let newObj = {}
    Object.assign(newObj,{...stateObj,"pageMetadata":metadataresponse,...temp})
    props.functions.updateValues({"pageMetadata":metadataresponse,"loading":false})
    let response =await onResponseInspections(eventName,propsObj,newObj,component,functions,e,props);
    if(response.hasOwnProperty('ResponseStatus') && response.ResponseStatus.toLowerCase() === "failed")
    {
      triggerToast("Failed to cancel inspection.",  { position: Toast.POSITION.TOP_RIGHT})
      console.log('Response Failed')
    }else{
      functions.UpdateState('loading',true,true)
      const metadatares = await constructAndInvokeMetadataCall(subappInfo, {transactionPrimaryObject:{...tpo,...response}}, null)
      props.functions.updateValues({"pageMetadata":metadatares,"transactionPrimaryObject":{...tpo,...response},"loading":false})
      successToast("Inspection cancellation has been initiated.");
    }
  }catch(e){
    console.log("error in onCancelInspections",e);
  }
}
const onClearInspectionCancelConfirmation = async(eventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    functions.UpdateState('loading',true,true)
    let {objectId,objectVerId} = propsObj.UIFlowReducer.currentPageInfo.template[0];
    let subappInfo = {
      objectId : objectId,
      objectVerId : objectVerId,
      subappName: stateObj.subappNameList
    }
    let {transactionPrimaryObject} = stateObj
    let response=await getPolicyData(props,propsObj.UIFlowReducer.currentPageInfo.getPolicyTargetEventName);
    let tpo = {...transactionPrimaryObject, ...response.data}
    const metadataresponse = await constructAndInvokeMetadataCall(subappInfo, {transactionPrimaryObject:{...tpo}}, null)
    props.functions.updateValues({"pageMetadata":metadataresponse,"transactionPrimaryObject":tpo,"loading":false})
  }catch(e){
    console.log('Error in onClearInspectionCancelConfirmation',e)
    functions.UpdateState('loading',false,true)
  }
}

const onPolicyNumberClick = async (propsObj,stateObj,component,e,props)=>{
  let {customTableRowIndex,componentProps,UpdateState} = props
  UpdateState("loading",true,true)
  const policyID = customTableRowIndex.PolicyID
  await store.dispatch({
    type: actionType.SET_POLICY_ID,
    payload: policyID
  });
  store.dispatch({type: actionType.SET_PAGENAME, payload: componentProps.navigationReducer.pageName})
  let route = store.getState().metadataReducer.route ? store.getState().metadataReducer.route : ''
  if(route==='Policy'){
    let {objectId,objectVerId,subApplication} = props.pageInfo;
    let newProps = {
      objectId : objectId,
      objectVerId : objectVerId,
      subApplication : subApplication,
      subappName: subApplication
    }
    let transactionPrimaryObject = {}
    let pagelevelValues = {}
        const policyDetailResponse = await policyDetailInformation({...stateObj,transactionPrimaryObject,pagelevelValues}, componentProps, null, null, UpdateState,newProps)
    const keyValuePairs = Object.entries(policyDetailResponse)
      await keyValuePairs.forEach((value,i)=>{
        let updateFlag = (keyValuePairs.length)-1===i
         value[1] && UpdateState(value[0],value[1],updateFlag)
      })
  }else if(route==='Quote'){
    await store.dispatch({
      type : actionTypes.SET_POLICYCONSOLE_INFO,
     payload :  {}
    })
    store.dispatch({type: actionType.SET_PAGENAME, payload: ''});
    propsObj.history.push("/policy")
   } else {
    propsObj.history.push("/policy")
   }
  UpdateState("loading",false,true)
}
const addEndorsement_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    props.functions.UpdateState("loading",true,true);
    let {objectId,objectVerId,subApplication} = props.pageInfo
    let subapp = {
      objectId : objectId,
      objectVerId : objectVerId,
      subappName: subApplication
    }
    let {data:{PolicyEffectiveDate,PolicyExpirationDate,TotalPremium,SubmissionNumber}}=await getPolicyData(propsObj,props.pageInfo.getPolicyTargetEventName);
    let tpo={"addEndorsementClicked":"true",PolicyEffectiveDate,PolicyExpirationDate,TotalPremium,"IssueBinderNavigator":"false",SubmissionNumber};
    props.functions.UpdateState("transactionPrimaryObject",tpo,false);
  const metadataRes=await constructAndInvokeMetadataCall(subapp,{transactionPrimaryObject:tpo});
  props.functions.UpdateState("loading",false,false);
  props.functions.UpdateState("pageMetadata",metadataRes,true);
}catch(e){
  console.log("Error in addEndorsement_HomeOwners",e);
}
}
const createEndorsement_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    let redux = store.getState();
    let quoteId = redux.UIFlowReducer.quoteID
  const response=await TriggerEndorsement(stateObj,propsObj,props.pageInfo.createEndorsement,propsObj.UIFlowReducer.policyID,props);
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed" && response.data.hasOwnProperty("Message")){
    triggerToast(response.data.ErrorMessage[0].userInfo,'Carrier Error : ',props.functions.UpdateState)
    return
  }
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed") {
    Toast.error('Unable to Create Endorsement', {POSITION: Toast.POSITION.TOP_CENTER})
    console.log('Response Failed ', response.data)
    return
  }
  let EndorsementQuoteDetails={}
  EndorsementQuoteDetails.quoteID=response.data.ID;
  EndorsementQuoteDetails.quoteStatus=response.data.QuoteStatus
  store.dispatch({
    type: actionType.SET_QUOTE_DETAILS,
    payload: {
      ...EndorsementQuoteDetails,quoteId
    }
  });
  store.dispatch({
    type: actionType.SET_QUOTE_STATUS,
    payload: {
      quoteStatus: EndorsementQuoteDetails.quoteStatus,
    }
  });
  await store.dispatch({
    type : actionTypes.SET_QUOTECONSOLE_INFO,
   payload :  {}
  })
  await store.dispatch({type: actionType.SET_PAGENAME, payload: ""});
  props.componentProps.history.push("/endorsement");
}catch(e){
  console.log("Error in createEndorsement_HomeOwners",e)
}
}
const updateEndorsement_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    const response=await TriggerEndorsement(stateObj,propsObj,props.pageInfo.updateEndorsement,propsObj.UIFlowReducer.quoteID,props);
    const translatedResponse=await EntitySubAppTranslationV2({...response.data},{...stateObj.pageMetadata.ApplicationDetail});
    props.functions.UpdateState('pagelevelValues',{...stateObj.pagelevelValues,...translatedResponse},true);
  }catch(e){
    console.log("Error in updateEndorsement_HomeOwners",e);
  }
}
let handleInsuredSave=(request,propsObj,e)=>{
  request={...request,"SelectedLineOfBusiness":[propsObj.componentProps.UIFlowReducer.productDetails]}
  return request
}
const handleSaveAccountInsured = async(response,propsObj)=>{
let pageDetail = propsObj.componentProps.navigation;
if(pageDetail && pageDetail.updateProductNavigationNeeded){
  let reduxState = store.getState();
  if(response && response[pageDetail.brokerageKey]){
    let splitParams = attributeSplit(pageDetail.splitFlowKey)
    if(reduxState.metadataReducer.Flow !==splitParams[response[pageDetail.brokerageKey]] ){
      store.dispatch({ type: actionType.SET_FLOW ,payload : splitParams[response[pageDetail.brokerageKey]] });
   await updateProdNav(propsObj.componentProps,pageDetail)
  }
  }
}
}
const getDynamicplProduct = async(props,value)=>{
  try {
  props.handleInputChangeV2 && props.handleInputChangeV2(value, props.component,props.isDynamicTable, props.datatableRowindex,props);
  let getProductDetailsRequest = {};
  let SelectedLineOfBusiness = [];
  let getProductDetailsResponse = {};
  let productDetails = {};
  getProductDetailsRequest["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  getProductDetailsRequest = Object.assign({}, getProductDetailsRequest, {
    OwnerId: sessionStorage.getItem("OwnerID"),
    EventName: "GetProductDetails_1.0.0.1"
  });
    SelectedLineOfBusiness.push({
      LOBName: 'PL',
      InsuranceType:'HomeOwners',
      ProductType :value[props.component.AttributeName] === 'Non-Configured' ? 'Non Configured' : 'Configured'
    });
    getProductDetailsRequest["SelectedLineOfBusiness"] = SelectedLineOfBusiness;
      getProductDetailsResponse = await AxiosInstance.post("/core/invokeWorkflowEvent/V3", getProductDetailsRequest);
      getProductDetailsResponse = getProductDetailsResponse.data;

    if (getProductDetailsResponse.hasOwnProperty("SelectedLineOfBusiness") && getProductDetailsResponse["SelectedLineOfBusiness"].length > 0) {
      productDetails = getProductDetailsResponse.SelectedLineOfBusiness[0];
    }
     store.dispatch({type: "SET_LOB", payload: productDetails.LOBName });
    store.dispatch({type: "SET_PRODUCT_DETAILS", payload: productDetails});
  return productDetails
  }
  catch (e) {
    console.error("Error in GetProductDetails API", e.message);
  }
}
const handleSaveAttachment = async (response, propsObj, stateObj)=>{
  try{
    let Attachments;
    const formData = new FormData();
    let values = stateObj.pagelevelValues[propsObj.component.SubApplicationName];
    if(stateObj.attachedFile){
      formData.append("uploadFileData", stateObj.attachedFile, stateObj.attachedFile.name);
    let fileUploadResponse = await AxiosInstance.post("/core/fileUpload?pathVariable=ATTACHMENTS_POLICY", formData);
    Attachments = {...values, AttachmentUrl: fileUploadResponse.data.newpath, AttachmentFileName: fileUploadResponse.data.newFileName}
    }else{
      Attachments = values;
    }
    let key = propsObj.componentProps.subapplicationName.toLowerCase() + "_dataList";
    propsObj.componentProps.tableUtils.onChildSave(key,[],Attachments, false, null, {}, {...Attachments},propsObj.componentProps)
  } catch(error){
    console.log("Error in handleSaveAttachment",error)
  }
}
const TriggerEndorsement=async(stateObj,propsObj,createEndorsement,ID,props)=>{
  try{
  props.functions.UpdateState('loading',true,true);
  let reduxState = store.getState()
  let request={};
  let newRequest=await constructTransactionRequestV1(stateObj,propsObj,{...stateObj.pagelevelValues},false,false,false,false);
  request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request={...request,...newRequest.NewEndorsementInformation};
  request.EventName =  GetCommonEventName();
  request.TargetEventName=createEndorsement;
  request.ID=ID;
  if (props.pageInfo && props.pageInfo.officeDetailsNeeded) {
    let officeMappingReq = {}
    officeMappingReq = constructOfficeMappingRequest(props)
    request = {...request, ...officeMappingReq}
    request.ProductNumber = reduxState.UIFlowReducer.productDetails.ProductNumber;
    request.ProductVerNumber = reduxState.UIFlowReducer.productDetails.ProductVerNumber;
  }
  const response= await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
  props.functions.UpdateState('loading',false,true);
  return response;
  }catch(e){
    console.log("Error in TriggerEndorsement",e);
  }
}
const saveClaimsHistory_HomeOwners=async(key,subappName,values,boolean,ID,props, state)=>{
  try{
  props.UpdateState("loading",true,true)
  let request = await constructTransactionRequestV1( state, props, {...state.pagelevelValues}, false,false,false, false);
  request.EventName =  GetCommonEventName();
  request.TargetEventName= props.pageInfo.eventName.saveLossHistory
  request.PARENTID=props.parentProps.BuildingID;
  request.ROOTID= props.UIFlowReducer.quoteID;
  request.ID=ID ? ID : "";
  request["ServiceRequestDetail"] = getServiceRequestDetail();
  request.OwnerId = sessionStorage.getItem("OwnerID");
  const response=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
  props.UpdateState("claimshistory_dataList",response.data.LossHistory,true);
  props.UpdateState("loading",false,true)
}catch(e){
  console.log("Error in saveClaimsHistory_HomeOwners",e);
}
}
const saveProperty_HomeOwners=async(key,subappName,values,boolean,ID,props, state)=>{
  updateArrayValueOnState(props,key,values,boolean,ID,subappName);
}

export const preventFormSubmit = async (e,props) =>{
  if (e.keyCode === 13) {
     e.preventDefault();
 }
}

const endorsementInformation = async(state,newProps,props,UpdateState)=>{
  let{transactionPrimaryObject}=state
  let CreateQuoteTargetEventName = props.pageInfo && props.pageInfo.CreateQuoteTargetEventName;
  let getQuoteResponse = await getQuoteData(props,CreateQuoteTargetEventName,UpdateState,state)
   if(getQuoteResponse.data.hasOwnProperty("RuleInformationList") && getQuoteResponse.data.RuleInformationList.length > 0 )
 {
  transactionPrimaryObject["BusinessRulesTriggered"]="true"
  transactionPrimaryObject["Cancel_NotInProgress"]="true"
  transactionPrimaryObject["firstCancelPage"]="true"
  transactionPrimaryObject["Reinstate_NotInProgress"]="true"
  transactionPrimaryObject["firstReinstatePage"]="true"
 }
 else {
  transactionPrimaryObject["BusinessRulesTriggered"]="false"
  transactionPrimaryObject["firstCancelPage"]="false"
  transactionPrimaryObject["firstReinstatePage"]="false"
  transactionPrimaryObject["IssueBinderNavigator"]="false"
 }
  transactionPrimaryObject["totalPremium"] = getQuoteResponse.data.TotalPremium;
  let response = await componentDidMount_Orchid(state,newProps,{...transactionPrimaryObject,...getQuoteResponse.data})
  let translatedResponse=await EntitySubAppTranslationV2({...getQuoteResponse.data},{...response.pageMetadata.ApplicationDetail})
  return {
    response,
    translatedResponse,
    getQuoteResponse,
    transactionPrimaryObject
  }
}

const endorsementDetailInformation= async(state,newprops,props,UpdateState)=>{
  UpdateState("loading",true,true);
  let {response,translatedResponse,getQuoteResponse,transactionPrimaryObject}=await endorsementInformation(state,newprops,props,UpdateState)
  let getEmailList = await getEmail(props,response,props.pageInfo.eventName.getEmailList,props.UIFlowReducer.quoteID);
  const {PolicyEffectiveDate,PolicyExpirationDate}=getQuoteResponse.data;
  return {
    loading : false,
    detailSubApp: response.detailSubApp,
    detailSummaryList : response.detailSummaryList,
    subappNameList : response.subappNameList,
    summarySubApp : response.summarySubApp,
    pageMetadata : response.pageMetadata,
    pagelevelValues : {...transactionPrimaryObject,...translatedResponse},
    documents_dataList : getQuoteResponse.data.Documents,
    notes_dataList:getQuoteResponse.data.Notes,
    policyattachments_dataList: getQuoteResponse.data.PolicyAttachments,
    emailsummary_dataList : getEmailList.data.Email,
    ceaseinformationlistinpolicy_dataList : getQuoteResponse.data.CeaseInformationList ? getQuoteResponse.data.CeaseInformationList : [],
    transactionPrimaryObject:{...transactionPrimaryObject,PolicyEffectiveDate,PolicyExpirationDate}
  }
}

const saveLocation_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    let {UpdateState} =  functions
    UpdateState("loading",true,true)
    let request = await constructTransactionRequestV1(stateObj,propsObj,{...stateObj.pagelevelValues},false,true,false,false);
    let eventName=GetCommonEventName();
    request.EventName=eventName;
    request["ServiceRequestDetail"] = getServiceRequestDetail();
    request["ServiceRequestDetail"]["Userrole"]=store.getState().userInfoReducer.Role;
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.TargetEventName=propsObj.pageInfo.eventName.saveLocationEndorsement;
    request.ROOTID=propsObj.UIFlowReducer.quoteID;
    request.LocationDetail.ROOTID=propsObj.UIFlowReducer.quoteID;
    request.Building.ROOTID=propsObj.UIFlowReducer.quoteID;
    const response=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    response.data.PersonalProperty && UpdateState("scheduleofpersonalproperty_dataList",response.data.PersonalProperty,false)
    UpdateState("loading",false,true)
    if(response.status===200 && ( !response.data.ResponseStatus || (response.data.ResponseStatus && response.data.ResponseStatus.toLowerCase() !== "failed"))){
      successToast( "Location Details Saved successfully.");
    }else if(response.data.ResponseStatus && response.data.ResponseStatus.toLowerCase() === "failed"){
      triggerToast( "Unable to Save Location Details.");
    }
  }catch(e){
    console.log("Error in saveLocation_HomeOwners",e);
  }
}

  const saveInsured_Endorsement_Orchid_PL = async (EventName,propsObj,stateObj,component,functions,e,props) => {
    try{
      functions.UpdateState('loading',true,true)
      let eventName=store.getState()
      let request = await constructTransactionRequest(stateObj,propsObj, stateObj.pagelevelValues,true,false)
      request["ServiceRequestDetail"]=getServiceRequestDetail();
      request.OwnerId = sessionStorage.getItem("OwnerID");
      request.EventName =  GetCommonEventName();
      request.TargetEventName=propsObj.pageInfo.eventName.saveInsured;
      request.ID = propsObj.UIFlowReducer.insuredID
      request.ROOTID = propsObj.UIFlowReducer.quoteID
      let response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
      if(response.status===200 && ( !response.data.ResponseStatus || (response.data.ResponseStatus && response.data.ResponseStatus.toLowerCase() !== "failed"))){
        successToast( "Insured Details Saved successfully.");
      }else if(response.data.ResponseStatus && response.data.ResponseStatus.toLowerCase() === "failed"){
        failureToast( "Unable to Save Insured Details.");
      }
    }catch{
      console.log('Issue In Save Insured PL');
    }finally{
      functions.UpdateState('loading',false,true)
    }
  }
  const onRefreshPremium_Endorsement=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
    await getEndorsementCoverageLimitsAndRates(propsObj,stateObj,component,props);
}
const savePremium_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
 await getEndorsementCoverageLimitsAndRates(propsObj,stateObj,component,props);
}
const getEndorsementCoverageLimitsAndRates=async(propsObj,stateObj,component,props)=>{
  try{
    props.functions.UpdateState("loading",true,true);
    let request=await constructTransactionRequestV1(stateObj,propsObj,{...stateObj.pagelevelValues},false,true,false,false);
    let subapplist = props.pageInfo.subApplication;
    let summarySubApp = [];
    let childDatalist = {}
    let dataListName = props.pageInfo.dataListName ? props.pageInfo.dataListName : []
    // subAppListConstructionV1(subapplist, {}, [], [], summarySubApp)
    dataListName.forEach(
      (key) =>{
         if(stateObj.hasOwnProperty(`${key.toLocaleLowerCase()}_dataList`)){
          childDatalist[key]=stateObj[`${key.toLocaleLowerCase()}_dataList`]
        }
      }
    )
      let eventName=GetCommonEventName();
      request.Coverage = {...request.Coverage,...childDatalist}
      request.EventName=eventName;
      request["ServiceRequestDetail"] = getServiceRequestDetail();
      request.OwnerId = sessionStorage.getItem("OwnerID");
      request.TargetEventName=props.pageInfo.getPremium;
      request.EventAction = props.pageInfo.RatingEventAction;
      request.ID=propsObj.UIFlowReducer.quoteID;
      request[component.AttributeName]=component.DefaultValue;
      const {data}=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
      if(data.hasOwnProperty('ResponseStatus') && data.ResponseStatus.toLowerCase() === "failed"){
        triggerToast("Unable to save Coverage Details.");
        return
      }
      else if (data.hasOwnProperty('RuleInformationList') && data.RuleInformationList.length > 0){
        failureToast( "Rule Message is Triggered.");
      }
      else{
        successToast( "Coverage Details Saved successfully.");
      }
      let Translatedresponse=await EntitySubAppTranslationV2({...data},{...stateObj.pageMetadata.ApplicationDetail});
      let {objectId,objectVerId,subApplication}= props.pageInfo;
      let selectedSubline = props.pageInfo && props.pageInfo.selectedSubline && props.pageInfo.selectedSubline;
    let neededSubapps = []
    selectedSubline && Object.keys(selectedSubline).length > 0 && Object.entries(selectedSubline).forEach(([key,value])=>{
      if(key === data.SublineName){
        neededSubapps.push(value)
      }
    })
    let subAppList = props.pageInfo && props.pageInfo.iteratorSubappList && props.pageInfo.iteratorSubappList.length && neededSubapps.length ? [...props.pageInfo.iteratorSubappList, ...neededSubapps] : neededSubapps.length ? neededSubapps : []
      let subAppInfo={
        subappName: subAppList.length ? subAppList :subApplication,
        objectId,
        objectVerId
      }
      let metadataRes;
      let tpo = {...stateObj.transactionPrimaryObject,...data}
      let additionalTpo;
      if(data.Coverage && data.Coverage.hasOwnProperty("CoverageRuleInformationList")){
        additionalTpo={...additionalTpo,"ShowBumpRules":"true"}
        props.functions.UpdateState("viewbumprulespl_dataList",data.Coverage.CoverageRuleInformationList,false);
      }
      else{
        additionalTpo={...additionalTpo,"ShowBumpRules":"false"}
      }
      if(data.hasOwnProperty("RuleInformationList")){
        additionalTpo={...additionalTpo,"ShowBusinessRules":"true"}
        props.functions.UpdateState("viewbusinessrulespl_dataList",data.RuleInformationList,false);
      }else{
        additionalTpo={...additionalTpo,"ShowBusinessRules":"false"}
      }
    let param ={ transactionPrimaryObject:{
      ...tpo,...additionalTpo
    }
    }
    metadataRes=await constructAndInvokeMetadataCall(subAppInfo,param);
    let temp={};
    temp.summarytaxdetails_dataList=data.Tax;
    temp.summaryfeedetails_dataList=data.Fee;
    let dataList={};
    await mergeRowdatawithDatalist(metadataRes.ApplicationDetail.SubApplicationDetailList,temp,dataList);
    props.functions.updateValues({"pagelevelValues":{...stateObj.pagelevelValues,...Translatedresponse},"transactionPrimaryObject":{...stateObj.transactionPrimaryObject,...Translatedresponse},...dataList,"loading":false,"pageMetadata":metadataRes});
      return;
  }catch(e){
    console.log("Error in getEndorsementCoverageLimitsAndRates",e);
  }
  finally{
    props.functions.UpdateState('loading',false,true)
  }
  }
  const showSummayOfChanges_HomeOwners=async(propsObj,stateObj,component,e,props)=>{
  try{
    props.functions.UpdateState("loading",true,true)
    let {subApplication,objectId,objectVerId}=props.pageInfo;
    let subAppInfo={
      subappName: subApplication,
      objectId,
      objectVerId
    };
    let request=await constructTransactionRequestV2(stateObj,propsObj,{},false,false);
    request.ID=propsObj.UIFlowReducer.quoteID;
    request.EventName =  GetCommonEventName();
    request.TargetEventName=props.pageInfo.SummaryOfChanges;
    if(props.pageInfo && props.pageInfo.eventAction)
      request.EventAction=props.pageInfo.eventAction.RevieweventAction;
    const responseFromSummaryAPI=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    if(responseFromSummaryAPI.data.hasOwnProperty('ResponseStatus') && responseFromSummaryAPI.data.ResponseStatus.toLowerCase() === "failed" && responseFromSummaryAPI.data.hasOwnProperty("Message")){
      triggerToast(responseFromSummaryAPI.data.ErrorMessage[0].userInfo,'Carrier Error : ',props.functions.UpdateState)
      return
    }
    let keys={};
    let mergeResponseKeysResponse = {};
    Object.keys(responseFromSummaryAPI.data).forEach(obj=>{
      keys[`${obj}__ValuesChanged`]="true";
    });
    const keyValuePairs = Object.entries(keys)
      await keyValuePairs.forEach((value,i)=>{
        let updateFlag = (keyValuePairs.length)-1===i
         value[1] && props.functions.UpdateState(value[0],value[1],updateFlag)
    })
    let obj={};
    let dataList={};
    const metadataRes=await constructAndInvokeMetadataCall(subAppInfo,{transactionPrimaryObject:{...stateObj.transactionPrimaryObject,...responseFromSummaryAPI.data,...keys}});
    if(responseFromSummaryAPI.data.RuleInformationList){
      obj["ruleinformationlist_dataList"]=responseFromSummaryAPI.data.RuleInformationList;
      await mergeRowdatawithDatalist(metadataRes.ApplicationDetail.SubApplicationDetailList,obj,dataList)
    }
    if(responseFromSummaryAPI.data.hasOwnProperty("EndorsementInformationHeader")){
      mergeResponseKeysResponse = await mergeResponseKeys(["EndorsementInformationHeader"],stateObj.pagelevelValues,responseFromSummaryAPI.data)
    }
    props.functions.updateValues({"pageMetadata":metadataRes,"insuredchanges_dataList":responseFromSummaryAPI.data.InsuredChanges,"locationchanges_dataList":responseFromSummaryAPI.data.LocationChanges,"underwritingchanges_dataList":responseFromSummaryAPI.data.UnderwritingChanges,"personalpropertychanges_dataList":responseFromSummaryAPI.data.PersonalPropertyChanges,"claimshistorychanges_dataList":responseFromSummaryAPI.data.ClaimsHistoryChanges,"additionalinterestschanges_dataList":responseFromSummaryAPI.data.AdditionalInterestsChanges,"coveragechanges_dataList":responseFromSummaryAPI.data.CoverageChanges,"premiumchanges_dataList":responseFromSummaryAPI.data.PremiumChanges,"underlyingexcesschanges_dataList":responseFromSummaryAPI.data.UnderlyingExcessChanges,"pagelevelValues":{...stateObj.pagelevelValues,...responseFromSummaryAPI.data,...mergeResponseKeysResponse},"transactionPrimaryObject":{...stateObj.transactionPrimaryObject,...keys,...mergeResponseKeysResponse},...obj});
    props.functions.UpdateState("SubApplicationEntity",metadataRes.ApplicationDetail.SubApplicationEntity,false)
    props.functions.UpdateState("loading",false,true)
  }catch(e){
    console.log("Error in showSummayOfChanges_HomeOwners",e);
  }
  }
  const offerEndorsement_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    functions.UpdateState("loading",true,true)
    props.functions.UpdateState("transactionPrimaryObject",{},false);
    let request=await constructTransactionRequestV2(stateObj,propsObj,{},false,false);
    request.ID=propsObj.UIFlowReducer.quoteID;
    request.EventName =  GetCommonEventName();
    request.TargetEventName=props.pageInfo.offerEndorsement;
    if(props.pageInfo.offerEndorsementeventaction)
    request.EventAction  = props.pageInfo.offerEndorsementeventaction;
    const {data}=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    let tpo={}
      let val={};
      Object.keys(stateObj).forEach(data=>{
        let  arr=data.split("__")
          if(arr[1]==="ValuesChanged"){
              val[data]=stateObj[data];
          }
      });

      if(data && data.Subjectivity && data.Subjectivity.length > 0 && data.IsEndorsementSubjectivityAvailable === "Y"){
        store.dispatch({type: actionType.SET_PAGENAME, payload: "Subjectivities"});
      }
    if(data && data.QuoteStatus&& data.QuoteStatus==='Offered'){
      props.functions.UpdateState("transactionPrimaryObject",{"ShowIssueEndorsement":"true"},false);
      let quoteStatus = data.QuoteStatus
      store.dispatch({
        type: actionType.SET_QUOTE_STATUS,
        payload: {
          quoteStatus
        }
      });
      tpo = {"ShowIssueEndorsement":"true","NavEndorsementSP__showRuleMessage":"false"}
    }else{
      tpo ={"NavEndorsementSP__showRuleMessage":"true",...val}
    }
    let {subApplication,objectId,objectVerId}=props.pageInfo;
    let subAppInfo={
      subappName: subApplication,
      objectId,
      objectVerId
    };
    const metadataResponse=await constructAndInvokeMetadataCall(subAppInfo,{transactionPrimaryObject:{...tpo,...stateObj.transactionPrimaryObject, ...data}});
    let responseFromTranslation=await EntitySubAppTranslationV2({...data},{...metadataResponse.ApplicationDetail});
    props.functions.UpdateState("pagelevelValues",{...stateObj.pagelevelValues,...responseFromTranslation},false);
    props.functions.UpdateState("pageMetadata",metadataResponse,true);
    functions.UpdateState("loading",false,true)
  }catch(e){
   console.log("Error in offerEndorsement_HomeOwners",e);
  }
  }
  const IssueEndorsement_HomeOwners=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
    try{
      functions.UpdateState("loading",true,true)
      let request=await constructTransactionRequestV1(stateObj,propsObj,{...stateObj.pagelevelValues},false,false,false,false);
      request.ID=propsObj.UIFlowReducer.quoteID;
      request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
      request.OwnerId = sessionStorage.getItem("OwnerID");
      request["ServiceRequestDetail"]["Lob"]=propsObj.productDetails.LOBName;
      request.EventName =  GetCommonEventName();
      request.TargetEventName=props.pageInfo.IssueEndorsement;
      if(props.pageInfo && props.pageInfo.eventAction)
        request.EventAction = props.pageInfo.eventAction.IssueEndorsementeventAction;
      const response=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
      if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed" && response.data.hasOwnProperty("Message")){
        triggerToast(response.data.ErrorMessage[0].userInfo,'Carrier Error : ',props.functions.UpdateState)
        return
      }
      if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
        if(response.data.hasOwnProperty('Message')){
          await trigger_canopius_validation_window(stateObj, propsObj, functions);
        }else{
          triggerToast("Unable to Issue Endorsement");
        }
      }else if(!response.data.hasOwnProperty("PolicyID") && response.data.hasOwnProperty("CeaseInformationList")){
        let {CreateQuoteTargetEventName}=props.pageInfo;
        let newProps ={
          subApplication : stateObj.subappNameList,
          objectId : propsObj.objectId,
          objectVerId : propsObj.objectVerId
        }
        const quoteDetailResponse = await quoteDetailInformation(stateObj, propsObj, null, null, functions.UpdateState,newProps)
        const keyValuePairs = Object.entries(quoteDetailResponse)
        await keyValuePairs.forEach((value,i)=>{
          let updateFlag = (keyValuePairs.length)-1===i
           value[1] && functions.UpdateState(value[0],value[1],updateFlag)
        })
      }
      else if(response.data.hasOwnProperty("RuleInformationList") && (response.data.RuleInformationList.length > 0) && (response.data.RuleStatus && response.data.RuleStatus === "InEligible") ) {
        let Translatedresponse=await EntitySubAppTranslationV2({...response.data},{...stateObj.pageMetadata.ApplicationDetail});
        let {objectId,objectVerId,subApplication}= props.pageInfo;
        let subAppInfo={
          subappName: subApplication,
          objectId,
          objectVerId
        }
        let metadataRes;
        let tpo = {...stateObj.transactionPrimaryObject,...response.data,...Translatedresponse}
        let additionalTpo;
          additionalTpo={...additionalTpo,"ShowBusinessRules":"true",}
          props.functions.UpdateState("viewbusinessrulessp_dataList",response.data.RuleInformationList,false);
        let param ={ transactionPrimaryObject:{
          ...tpo,...additionalTpo
        }};
        metadataRes=await constructAndInvokeMetadataCall(subAppInfo,param);
        let dataList={},temp={};
        temp["viewbusinessrulessp_dataList"]=response.data.RuleInformationList;
        await mergeRowdatawithDatalist(metadataRes.ApplicationDetail.SubApplicationDetailList,temp,dataList);
        props.functions.updateValues({"pagelevelValues":{...stateObj.pagelevelValues,...Translatedresponse},"transactionPrimaryObject":{...stateObj.transactionPrimaryObject,...Translatedresponse},...dataList,"loading":false,"pageMetadata":metadataRes});
        return;
      }
      else if(response.data.hasOwnProperty("Subjectivity") && (response.data.Subjectivity.length > 0) && response.data.hasOwnProperty("Subjectivity") && response.data.hasOwnProperty("AllowBind")&& response.data.AllowBind==="N")   {
        let Subjectivity = "true";
        let {objectId,objectVerId,subApplication}= props.pageInfo;
        let newProps ={
          subApplication : subApplication,
          objectId : objectId,
          objectVerId : objectVerId
      }
        let tpo = {...stateObj.transactionPrimaryObject,...response.data,"AllowBind":response.data.AllowBind,"Subjectivity":Subjectivity}
        let mountResponse = await componentDidMount_Orchid(stateObj,newProps,{...tpo})
        props.functions.UpdateState("subjectivityvalidationssummary_dataList",response.data.Subjectivity,false)
        props.functions.UpdateState("pageMetadata", mountResponse.pageMetadata, false);
      }
        else{
          await store.dispatch({
            type : actionTypes.SET_POLICYCONSOLE_INFO,
           payload :  {}
          })
          await store.dispatch({type: actionType.SET_PAGENAME, payload: "" });
          props.componentProps.history.push("/policy");
        }
    }catch(e){

      console.log("Error in IssueEndorsement_HomeOwners",e);
    }finally{
      functions.UpdateState("loading",false,true)
    }
  }

const componentDidMount_CancelReinstate_DetailsV2 = async (state,props,updatestate) => {
  updatestate("loading",true,true)
  try{
    let {getPolicyTargetEventName,getQuoteListTargetEventName,getQuoteListSearchName} = props.pageInfo;
    let getPolicyResponse= await getPolicyData(props,getPolicyTargetEventName)
    let tpo={
      ...state.pagelevelValues.PolicyInformationHeader,...{BusinessRulesTriggered:"true"},
        "IssueBinderNavigator":"false",...getPolicyResponse.data
    }
    let getQuoteListResponse = await getQuoteList(props,getQuoteListTargetEventName,getQuoteListSearchName)
    let request={}
    request["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
    request.ServiceRequestDetail['Lob'] = props.productDetails.LOBName
    request.SubmissionID = props.UIFlowReducer.submissionID;
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.EventName= props.pageInfo.eventName.getCancelReinstateList ? props.pageInfo.eventName.getCancelReinstateList : GetCommonEventName();
    if(props.pageInfo.eventName.getCancellistTargetEventName)
    request.TargetEventName=props.pageInfo.eventName.getCancellistTargetEventName;
    if(props.pageInfo.eventName.eventAction)
    request.EventAction=props.pageInfo.eventName.eventAction;
    request.PolicyID=props.UIFlowReducer.policyID;
    const cancelReinstateListResponse= await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
    props.setUpdateExpandPanel && props.setUpdateExpandPanel(true);
    tpo['Cancel_NotInProgress']=cancelReinstateListResponse.data.CancelInprogress ==="No" ? "true" : "false"
    tpo['Reinstate_NotInProgress']=cancelReinstateListResponse.data.ReinstateInprogress === "No" ? "true" : "false"
    let {PolicyEffectiveDate,PolicyExpirationDate,CancellationDate,InspectionReviewCompleteYN,SublineName,CarrierName,Coverage} = getPolicyResponse.data
    if(getPolicyResponse.data.CancellationDate){
      tpo['CancellationDate']=getPolicyResponse.data.CancellationDate
    }
    let response = await componentDidMount_Orchid(state,props,tpo);
    let tpoObj =await constructTransactionRequestV1(response,props,state.pagelevelValues,undefined,true,false,null)
    let translatedResponse=await EntitySubAppTranslationV2({...getPolicyResponse.data},{...response.pageMetadata.ApplicationDetail})
    let temp={}
    temp.cancelreinstatelist_dataList = cancelReinstateListResponse.data.Quote
    temp.policylist_dataList = getQuoteListResponse
    await mergeRowdatawithDatalist(response.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{})
    let values = {PolicyEffectiveDate,PolicyExpirationDate,CancellationDate,InspectionReviewCompleteYN,SublineName,CarrierName,Coverage}

    return {
      cancelreinstatelist_dataList : cancelReinstateListResponse.data.Quote,
      loading : false,
      pagelevelValues : {BusinessRulesTriggered:"true",
      Cancel_NotInProgress:"true",Reinstate_NotInProgress:"true",...tpo,...translatedResponse,...values},
      pageMetadata: response.pageMetadata,
      summarySubApp: response.summarySubApp,
      detailSummaryList: response.detailSummaryList,
      subappNameList: response.subappNameList,
      detailSubApp: response.detailSubApp,
      policylist_dataList : getQuoteListResponse,
      transactionPrimaryObject :{BusinessRulesTriggered:"true",
      IssueBinderNavigator:"false",
      Cancel_NotInProgress:"true",Reinstate_NotInProgress:"true",...tpo,...values,...tpoObj},
      summaryfeedetailssp_dataList : getPolicyResponse.data.Fee,
      summarytaxdetailssp_dataList : getPolicyResponse.data.Tax
    }
  } catch(err){
    updatestate("loading",false,true)
    console.log('Error in ComponentDidMount_CancelReinstate_Details',err)
  }
}

const createReinstate=async(eventName,propsobj,stateobj,component,functions,e,props)=>{
  try{
    let {UpdateState} = component
    let reduxState = store.getState()
    functions.UpdateState("loading",true,true)
    let request=await constructTransactionRequestV2(stateobj,propsobj,{...stateobj.pagelevelValues},false,false);
    request.EventName =  GetCommonEventName();
    request.TargetEventName=propsobj.pageInfo.eventName.createReinstate;
    request.ID=propsobj.UIFlowReducer.policyID;
    if (props.pageInfo && props.pageInfo.officeDetailsNeeded) {
      let officeMappingReq = {}
      officeMappingReq = constructOfficeMappingRequest(props)
      request = {...request, ...officeMappingReq}
      request.ProductNumber = reduxState.UIFlowReducer.productDetails.ProductNumber;
      request.ProductVerNumber = reduxState.UIFlowReducer.productDetails.ProductVerNumber;
    }
    const response= await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed" && response.data.hasOwnProperty("Message")){
      triggerToast(response.data.ErrorMessage[0].userInfo,'Carrier Error : ',functions.UpdateState)
      return
    }
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
      if(response.data.hasOwnProperty('Message')){
        await trigger_canopius_validation_window(stateobj, propsobj, functions);
      }
    }
    else{
      let quoteID=response.data.ID;
      store.dispatch({
        type: actionType.SET_QUOTE_DETAILS,
        payload: {
          quoteID
        }
      });
      store.dispatch({type: actionType.SET_PAGENAME, payload: "" });
      propsobj.history.push("/reinstate");
    }
    functions.UpdateState("loading",false,true)
  }catch(e){
    console.log("Error in createReinstate",e)
  }
}

const componentDidMount_CancelReinstate = async (state,props,UpdateState) => {
try{
  UpdateState("loading",true,true)
  let reduxState = store.getState();
  let routeNav = [];
  let templateInfoTemplates = reduxState.navigationReducer.templateInfo

  let LOB = reduxState.UIFlowReducer.productDetails.LOBName
  let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"], [reduxState.UIFlowReducer.productDetails.ProductVerNumber]);
  if(navObj.hasOwnProperty('productTemplateInfo')){
    //Util Construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template])
    })
    //SubTemplate construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
    })
    store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}})
  }
  let templatefromredux = {...templateInfoTemplates,...navObj.productTemplateInfo};
  if (navObj.hasOwnProperty("productNavigation")) {
    templateConstruct(navObj, ["template"], templatefromredux);
   const route = props.location.pathname.split('/')[1]
    routeNav = navObj.productNavigation[`${LOB}_${route}`]
      let pageNav = Object.assign({}, props.navigation);
    let pagename = props.pageInfo && props.pageInfo.landingpage;
    if(props.UIFlowReducer.pageName !== "")
    pagename=props.UIFlowReducer.pageName;
    pageNav.pageNavigation[route].sidebar[getRequiredIndex(pageNav.pageNavigation[route].sidebar,'name',props.pageInfo.name)].children =  routeNav
    let index = await getIndex(props,routeNav,getRequiredIndex,pagename)
     await props.setNavigation(
      { ...pageNav, "productNavigation": { ...navObj.productNavigation } }
    )
    await store.dispatch({
      type: actionTypes.SET_PAGENAME,
      payload: routeNav[index]['pageName']
    })
    if(props.UIFlowReducer.pageName !== "")
    UpdateState("currentPageInfo",routeNav[index],false)
    UpdateState("loading", false, false)
    store.dispatch(setCurrentPageIndex(routeNav[index]["index"]))
    props.setUpdateExpandPanel && props.setUpdateExpandPanel(true);
    await store.dispatch({ type: actionType.SET_ROUTE_INFO, payload: props.pageInfo.name })
}
}
catch (err) {
  console.log("Error in componentDidMount_Quote", err)
}
}

const cancelReinstateDetailInformation=async(state,props,UpdateState)=>{
UpdateState("loading",true,true)

let {objectId,objectVerId,subApplication,templateClassName} = props.pageInfo;
  UpdateState('templateClassName',templateClassName,false)
    let newProps = {
      objectId : objectId,
     objectVerId : objectVerId,
      subApplication : subApplication,
      subappName: subApplication
    }
let {response,translatedResponse,getQuoteResponse,transactionPrimaryObject} = await endorsementInformation(state,newProps,props,UpdateState)
let {getQuoteListTargetEventName,getQuoteListSearchName}=
props.pageInfo;
let getQuoteListResponse = getQuoteListTargetEventName && await getQuoteList(props,getQuoteListTargetEventName,getQuoteListSearchName)
let temp={}
temp.policylist_dataList = getQuoteListResponse
if(getQuoteResponse.data.RuleInformationList || getQuoteResponse.data.CeaseInformationList){
  temp.ruleinformationlist_dataList = getQuoteResponse.data.RuleInformationList || []
  temp.ceaseinformationlist_dataList=getQuoteResponse.data.CeaseInformationList || []
}
await mergeRowdatawithDatalist(response.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{})
props.setUpdateExpandPanel && props.setUpdateExpandPanel(true);
return{
  loading : false,
  detailSubApp: response.detailSubApp,
  detailSummaryList : response.detailSummaryList,
  subappNameList : response.subappNameList,
  summarySubApp : response.summarySubApp,
  pageMetadata : response.pageMetadata,
  pagelevelValues : translatedResponse,
  policylist_dataList : getQuoteListResponse,
  ceaseinformationlistinpolicy_dataList : getQuoteResponse.data.CeaseInformationList || [],
  ceaseinformationlist_dataList : getQuoteResponse.data.CeaseInformationList || [],
  cancelreinstatetax_dataList : getQuoteResponse.data.Tax,
  cancelreinstatefee_dataList : getQuoteResponse.data.Fee,
  ruleinformationlist_dataList : getQuoteResponse.data.RuleInformationList,
  transactionPrimaryObject : {...getQuoteResponse.data,...transactionPrimaryObject}
}
}

const issueReinstate = async(eventName,propsObj,stateObj,component,functions,e,props)=>{
try{
  functions.UpdateState("loading",true,true);
  let request=await constructTransactionRequestV1(stateObj,propsObj,{...stateObj.pagelevelValues},false,false,false,false);
   request.EventName =  GetCommonEventName();
   request.ServiceRequestDetail = getServiceRequestDetail()
  request.TargetEventName=props.pageInfo.eventName.issueReinstate;
  request["ServiceRequestDetail"]["Lob"]=propsObj.productDetails.LOBName
  request.ID=propsObj.UIFlowReducer.quoteID;
  request.ProductVerNumber = propsObj.productDetails.ProductVerNumber;
  request.ProductNumber = propsObj.productDetails.ProductNumber;
  const response= await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed" && response.data.hasOwnProperty("Message")){
    triggerToast(response.data.ErrorMessage[0].userInfo,'Carrier Error : ',functions.UpdateState)
    return
  }
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
    triggerToast("Unable to Issue Reinstate");
    console.log('Response Failed')
  }else if(response.data.hasOwnProperty("RuleInformationList") && (!response.data.hasOwnProperty("QuoteStatus") || (response.data.QuoteStatus !== 'Bound' && response.data.QuoteStatus !== 'Issued'))){
    let {transactionPrimaryObject} = stateObj
    let tpo={...transactionPrimaryObject,"ViewRuleMessages":"true", "firstReinstatePage": "false"}
    let subappinfo = { subappName:stateObj.subappNameList, objectId: propsObj.objectId, objectVerId: propsObj.objectVerId, };
    let metadataResponse = await constructAndInvokeMetadataCall(subappinfo, {transactionPrimaryObject: tpo});
    functions.UpdateState ("viewrulemessages_dataList",response.data.RuleInformationList,false)
    functions.UpdateState("pageMetadata", metadataResponse, false);
   }else if(!response.data.hasOwnProperty("PolicyID") && response.data.hasOwnProperty("CeaseInformationList")){
    let {CreateQuoteTargetEventName}=propsObj.UIFlowReducer && propsObj.UIFlowReducer.currentConsoleInfo.template[0]
    let newProps ={
      subApplication : stateObj.subappNameList,
      objectId : propsObj.objectId,
      objectVerId : propsObj.objectVerId
    }
    const quoteDetailResponse = await quoteDetailInformation(stateObj, propsObj, null, null, functions.UpdateState,newProps)
    const keyValuePairs = Object.entries(quoteDetailResponse)
    await keyValuePairs.forEach((value,i)=>{
      let updateFlag = (keyValuePairs.length)-1===i
       value[1] && functions.UpdateState(value[0],value[1],updateFlag)
    })
    functions.UpdateState("loading",false,true)
  }
  else{
    await store.dispatch({
      type : actionTypes.SET_POLICYCONSOLE_INFO,
     payload :  {}
    })
    await store.dispatch({
      type : actionTypes.SET_PAGENAME,
     payload :  ""
    })
    propsObj.history.push("/policy")
  }
  functions.UpdateState("loading",false,true);
}catch(e){
  console.log("Error in issueReinstate",e)
  functions.UpdateState("loading",false,true);
}
}

const issueReinstateExitmtd = async(response,propsObj,stateObj,e )=>{
  let {functions}=propsObj;
  try{
    if(response.hasOwnProperty('ResponseStatus') && response.ResponseStatus.toLowerCase() === "failed" && response.hasOwnProperty("Message")){
      triggerToast(response.data.ErrorMessage[0].userInfo,'Carrier Error : ',functions.UpdateState)
      return
    }
    if(response.hasOwnProperty('ResponseStatus') && response.ResponseStatus.toLowerCase() === "failed"){
      triggerToast("Unable to Issue Reinstate");
    }else if(response.hasOwnProperty("RuleInformationList") && (!response.hasOwnProperty("QuoteStatus") || (response.QuoteStatus !== 'Bound' && response.QuoteStatus !== 'Issued'))){
      let {transactionPrimaryObject} = stateObj
      let tpo={...transactionPrimaryObject,"ViewRuleMessages":"true", "firstReinstatePage": "false"}
      let subappinfo = { subappName:stateObj.subappNameList, objectId: stateObj.objectId, objectVerId: stateObj.objectVerId, };
      let metadataResponse = await constructAndInvokeMetadataCall(subappinfo, {transactionPrimaryObject: tpo});
      functions.UpdateState ("viewrulemessages_dataList",response.data.RuleInformationList,false)
      functions.UpdateState("pageMetadata", metadataResponse, false);
     }else if(!response.hasOwnProperty("PolicyID") && response.hasOwnProperty("CeaseInformationList")){
      let {CreateQuoteTargetEventName}=propsObj.UIFlowReducer && propsObj.UIFlowReducer.currentConsoleInfo.template[0]
      let newProps ={
        subApplication : stateObj.subappNameList,
        objectId : propsObj.objectId,
        objectVerId : propsObj.objectVerId
      }
      const quoteDetailResponse = await quoteDetailInformation(stateObj, propsObj, null, null, functions.UpdateState,newProps)
      const keyValuePairs = Object.entries(quoteDetailResponse)
      await keyValuePairs.forEach((value,i)=>{
        let updateFlag = (keyValuePairs.length)-1===i
         value[1] && functions.UpdateState(value[0],value[1],updateFlag)
      })
      functions.UpdateState("loading",false,true)
    }
    else{
      await store.dispatch({
        type : actionTypes.SET_POLICYCONSOLE_INFO,
       payload :  {}
      })
      await store.dispatch({
        type : actionTypes.SET_PAGENAME,
       payload :  "Policy Details"
      })
      propsObj.componentProps.history.push("/policy")
    }
    functions.UpdateState("loading",false,true);
  }catch(e){
    console.log("Error in issueReinstateExitmtd",e)
    functions.UpdateState("loading",false,true);
  }
  }


const componentDidMount_UW_WorksheetMPL=async(state,props,UpdateState)=>{
  try{
    UpdateState("loading",true,true)
    let subAppInfo = {
      objectId : props.objectId,
      objectVerId : props.objectVerId,
      subApplication : props.subApplication,
      subappName: props.subApplication
    }
    const metadataResponse = await constructAndInvokeMetadataCall(subAppInfo);
    let getQuoteResponse = await getQuoteData(props,props.UIFlowReducer.currentPageInfo.template[0].CreateQuoteTargetEventName,UpdateState)
      let TranslatedResponse = await getEntitySubAppTranslation(props,metadataResponse,getQuoteResponse.data.UnderWriterDetail);

      let underwritterworksheet_datalist =  TranslatedResponse ? [{...TranslatedResponse}] : [];
      let tpo = state.transactionPrimaryObject ? {...state.transactionPrimaryObject, ...getQuoteResponse.data} : {};
      let metadataResponseUpdated=await componentDidMount_Orchid(state,props,tpo);

    return {underwritterworksheet_datalist, pagelevelValues: {...getQuoteResponse.data}, transactionPrimaryObject: {...getQuoteResponse.data},loading: false,...metadataResponseUpdated};
  }
  catch(e){
    UpdateState("loading",false,true)
    console.log("error in componentDidMount_UW_WorksheetMPL",e);
  }
}


const discardCancelReinstate=async(eventName,stateobj,propsobj,component,functions,e,props)=>{
  let {UpdateState} = functions
  UpdateState("loading",true,true);
  let request = {};
  request.lapseDeclineStatus = component.AttributeName
  request["ServiceRequestDetail"] = getServiceRequestDetail();
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request.EventName =  GetCommonEventName();
  request.TargetEventName = eventName
  request.ID = store.getState().UIFlowReducer.quoteID;
  let response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request)
  let QuoteStatus = response.data.QuoteStatus
  await store.dispatch({
    type: actionType.SET_QUOTE_STATUS,
    payload: {
      QuoteStatus,
    }
  });
  await store.dispatch({
    type: actionType.SET_PAGENAME,
    payload: ""
  });
  UpdateState("loading",false,true);
  props.componentProps.history.push("/policy")
}

const CreateCancel_PL_Homeowners = async (eventname,propsobj,stateobj,component,functions,e,props) => {
  functions.UpdateState('loading',true,true)
try{
  let reduxState = store.getState()
  let request=await constructTransactionRequestV1(props.componentState,propsobj,props.componentState.pagelevelValues,undefined,true,false,null)
  request["ServiceRequestDetail"] = getServiceRequestDetail();
  request.EventName =  GetCommonEventName();
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request.TargetEventName=props.pageInfo.eventName.createCancel;
  request.ID=propsobj.UIFlowReducer.policyID;
  request.PolicyList && delete request.PolicyList
  if (props.pageInfo && props.pageInfo.officeDetailsNeeded) {
    let officeMappingReq = {}
    officeMappingReq = constructOfficeMappingRequest(props)
    request = {...request, ...officeMappingReq}
    request.ProductNumber = reduxState.UIFlowReducer.productDetails.ProductNumber;
    request.ProductVerNumber = reduxState.UIFlowReducer.productDetails.ProductVerNumber;
  }
  let response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed" && response.data.hasOwnProperty("Message")){
    triggerToast(response.data.ErrorMessage[0].userInfo,'Carrier Error : ',functions.UpdateState)
    return
  }
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed") {
    Toast.error('Unable to Create Cancel', {POSITION: Toast.POSITION.TOP_CENTER})
    console.log('Response Failed ', response.data)
    functions.UpdateState('loading', false, true)
    return
  }
  let quoteID=response.data.ID;
  store.dispatch({
  type: actionType.SET_QUOTE_DETAILS,
  payload: {
    quoteID
  }
});
if(response.data.hasOwnProperty("RuleInformationList") && response.data["RuleInformationList"].length > 0){
  let {transactionPrimaryObject} = stateobj
  let tpo={...transactionPrimaryObject,"ViewRuleMessages":"true", "firstCancelPage": "false"}
  let subappinfo = { subappName:stateobj.subappNameList, objectId: propsobj.objectId, objectVerId: propsobj.objectVerId, };
  let metadataResponse = await constructAndInvokeMetadataCall(subappinfo, {transactionPrimaryObject: tpo});
  functions.UpdateState ("viewrulemessages_dataList",response.data.RuleInformationList,false)
  functions.UpdateState("pageMetadata", metadataResponse, false);
 }
 else{store.dispatch({type: actionType.SET_PAGENAME, payload: ''});
 propsobj.history.push("/cancel")
}
}catch(e){
  console.log("Error in CancelPolicy_HomeOwners",e)
}finally{
  functions.UpdateState('loading',false,true)
}
}

const reviewCancel_Homeowners = async (eventname,propsobj,stateobj,component,functions,e,state) => {
  try{
    functions.UpdateState("loading",true,true);
    let transactionPrimaryObject={"BusinessRulesTriggered":"false","firstCancelPage":"false"}
      let {objectId,objectVerId,subApplication} = propsobj.pageInfo;
    let newProps = {
      objectId : objectId,
      objectVerId : objectVerId,
      subApplication : subApplication,
      subappName: subApplication
    }
    let requestValues = await constructTransactionRequestV1(stateobj,propsobj,{...stateobj.pagelevelValues},undefined,true,false,null)
    let request = JSON.parse(JSON.stringify(requestValues))
    deleteObjRowMetaData(request)
    request.ServiceRequestDetail = getServiceRequestDetail();
    request.EventName =  GetCommonEventName();
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.TargetEventName = propsobj.pageInfo.eventName.reviewCancel;
    request.ID = propsobj.UIFlowReducer.quoteID;
    let response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed" && response.data.hasOwnProperty("Message")){
      triggerToast(response.data.ErrorMessage[0].userInfo,'Carrier Error : ',functions.UpdateState)
      return
    }
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed") {
      Toast.error('Unable to Cancel Review', {POSITION: Toast.POSITION.TOP_CENTER})
      console.log('Response Failed ', response.data)
      return
    }
    if(response.data.hasOwnProperty("RuleInformationList") && response.data["RuleInformationList"].length > 0){
      let {transactionPrimaryObject} = stateobj
      let tpo={...transactionPrimaryObject,"ViewRuleMessages":"true", "firstReinstatePage": "false"}
      let subappinfo = { subappName:stateobj.subappNameList, objectId: propsobj.objectId, objectVerId: propsobj.objectVerId, };
      let metadataResponse = await constructAndInvokeMetadataCall(subappinfo, {transactionPrimaryObject: tpo});
      functions.UpdateState ("viewrulemessages_dataList",response.data.RuleInformationList,false)
      functions.UpdateState("pageMetadata", metadataResponse, false);
      functions.UpdateState("loading",false,true);
     }
     else{
    let metadataRes = await componentDidMount_Orchid(state,newProps,{...transactionPrimaryObject,...response.data})
    response.data.Tax && functions.UpdateState("cancelreinstatetax_dataList",response.data.Tax,false)
    response.data.Fee && functions.UpdateState("cancelreinstatefee_dataList",response.data.Fee,false)
    functions.UpdateState("pagelevelValues",{...stateobj.pagelevelValues,...response.data},false)
    functions.UpdateState("pageMetadata",metadataRes.pageMetadata,false);
    functions.UpdateState("transactionPrimaryObject",{...transactionPrimaryObject,...response.data},false);
    functions.UpdateState("loading",false,true);
    }
  }catch(e){
    console.log('reviewCancel_Homeowners Failed',e);
  }
}

const cancelNavigator = async (eventname,propsobj,stateobj,component,functions,e,state) => {
  try{
    functions.UpdateState("loading",true,true);
    let transactionPrimaryObject={...stateobj.pagelevelValues,...{"Cancel_NotInProgress":"true","firstCancelPage":"true"}}
    let {objectId,objectVerId,subApplication,CreateQuoteTargetEventName} =  propsobj.pageInfo;
	  let getQuoteResponse = await getQuoteData(propsobj,CreateQuoteTargetEventName,functions.UpdateState)
    let newProps = {
      objectId : objectId,
      objectVerId : objectVerId,
      subApplication : subApplication,
      subappName: subApplication
    }
    let tpo;
    if(getQuoteResponse.data.hasOwnProperty("RuleInformationList"))
    {
      tpo = {...transactionPrimaryObject,"BusinessRulesTriggered":"true"}
    }
    else {
      tpo= {...transactionPrimaryObject,"BusinessRulesTriggered":"false"}
    }
    let metadataRes = await componentDidMount_Orchid(state,newProps,{...tpo,...getQuoteResponse.data})
    functions.UpdateState("pageMetadata",metadataRes.pageMetadata,false);
    functions.UpdateState("transactionPrimaryObject",{...tpo,...getQuoteResponse.data},false);
    if(getQuoteResponse.data.hasOwnProperty("RuleInformationList"))
    {
    let temp={}
    temp.ruleinformationlist_dataList=getQuoteResponse.data.RuleInformationList
    await mergeRowdatawithDatalist(metadataRes.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{});
    functions.UpdateState("ruleinformationlist_dataList",getQuoteResponse.data.RuleInformationList,false)
    }
    functions.UpdateState("loading",false,true);
  }catch(e){
    console.log('cancelNavigator Failed',e);
    functions.UpdateState("loading",false,true);
  }
}
const reviewReinstate_V1 = async (eventname,propsobj,stateobj,component,functions,e,state) => {
  try{
    functions.UpdateState("loading",true,true);
    let transactionPrimaryObject={"firstReinstatePage":"false"}
    let {objectId,objectVerId,subApplication,CreateQuoteTargetEventName} = propsobj.pageInfo;
    let newProps = {
    objectId : objectId,
    objectVerId : objectVerId,
    subApplication : subApplication,
    subappName: subApplication
    }
    let request = {};
    request.ReinstateDetails = {...stateobj.pagelevelValues.ReinstateDetails}
    request.ServiceRequestDetail = getServiceRequestDetail();
    request.EventName = GetCommonEventName();
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.TargetEventName=eventname;
    request.ROOTID=propsobj.UIFlowReducer.quoteID;
    request.ID = propsobj.UIFlowReducer.quoteID;
    const response= await AxiosInstance.post("core/invokeWorkflowEvent/V2",request)
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed" ){
      // triggerToast(response.data.ErrorMessage[0].userInfo,'Carrier Error : ',functions.UpdateState)
      return
    }else{
    let getQuoteResponse = await getQuoteData(propsobj,CreateQuoteTargetEventName,functions.UpdateState)
    let metadataRes = await componentDidMount_Orchid(state,newProps,{...transactionPrimaryObject,...getQuoteResponse.data})
    functions.UpdateState("pagelevelValues",{...stateobj.pagelevelValues,...getQuoteResponse.data},false);
    functions.UpdateState("pageMetadata",metadataRes.pageMetadata,false);
    functions.UpdateState("transactionPrimaryObject",{transactionPrimaryObject,...getQuoteResponse.data},false);
    functions.UpdateState("loading",false,true);
  }
  }catch(e){
     console.log('reviewReinstate_Homeowners Failed',e);
    }
  }
const reviewReinstate = async (eventname,propsobj,stateobj,component,functions,e,state) => {
  try{
    functions.UpdateState("loading",true,true);
    let transactionPrimaryObject={"firstReinstatePage":"false"}
    let {objectId,objectVerId,subApplication,CreateQuoteTargetEventName} = propsobj.pageInfo;
    let newProps = {
    objectId : objectId,
    objectVerId : objectVerId,
    subApplication : subApplication,
    subappName: subApplication
    }
    let request = await constructTransactionRequestV1(stateobj,propsobj,{...stateobj.pagelevelValues},undefined,true,false,null)
    request.ServiceRequestDetail = getServiceRequestDetail();
    request.EventName = GetCommonEventName();
    request.OwnerId = sessionStorage.getItem("OwnerID");
    request.ID = propsobj.UIFlowReducer.quoteID;
    let getQuoteResponse = await getQuoteData(propsobj,CreateQuoteTargetEventName,functions.UpdateState)
    let metadataRes = await componentDidMount_Orchid(state,newProps,{...transactionPrimaryObject,...getQuoteResponse.data})
    functions.UpdateState("pagelevelValues",{...stateobj.pagelevelValues,...getQuoteResponse.data},false);
    functions.UpdateState("pageMetadata",metadataRes.pageMetadata,false);
    functions.UpdateState("transactionPrimaryObject",{transactionPrimaryObject,...getQuoteResponse.data},false);
    functions.UpdateState("loading",false,true);
  }catch(e){
     console.log('reviewReinstate_Homeowners Failed',e);
    }
  }

const reinstateNavigator = async (eventname,propsobj,stateobj,component,functions,e,state) => {
   try{
    functions.UpdateState("loading",true,true);
    let transactionPrimaryObject={...stateobj.pagelevelValues,...{"Reinstate_NotInProgress":"true","firstReinstatePage":"true"}}
    let {objectId,objectVerId,subApplication,CreateQuoteTargetEventName} = propsobj.pageInfo;
    let getQuoteResponse = await getQuoteData(propsobj,CreateQuoteTargetEventName,functions.UpdateState)
    let newProps = {
      objectId : objectId,
      objectVerId : objectVerId,
      subApplication : subApplication,
       subappName: subApplication
    }
    let metadataRes = await componentDidMount_Orchid(state,newProps,{...transactionPrimaryObject,...getQuoteResponse.data});
    functions.UpdateState("pageMetadata",metadataRes.pageMetadata,false);
    functions.UpdateState("transactionPrimaryObject",{...transactionPrimaryObject,...getQuoteResponse.data},false);
    functions.UpdateState("loading",false,true);
  }catch(e){
   console.log('ReinstateNavigator Failed',e); functions.UpdateState("loading",false,true);
  }
}

const issueCancel_Homeowners = async (eventname,propsobj,stateobj,component,functions,e,state) => {
  try{
    functions.UpdateState("loading",true,true);
    let request=await constructTransactionRequestV2(stateobj,propsobj,{...stateobj.pagelevelValues},false,false);
    request.EventName =  GetCommonEventName();
    request["ServiceRequestDetail"]["Lob"]=propsobj.productDetails.LOBName
    request.TargetEventName=propsobj.pageInfo.eventName.issueCancel;
    request.ID=propsobj.UIFlowReducer.quoteID;
    request.ProductVerNumber = propsobj.productDetails.ProductVerNumber;
    request.ProductNumber = propsobj.productDetails.ProductNumber;
    await AxiosInstance.post("core/invokeWorkflowEvent/V2",request).then(async(response)=>{
      if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed" && response.data.hasOwnProperty("Message")){
        triggerToast(response.data.ErrorMessage[0].userInfo,'Carrier Error : ',functions.UpdateState)
        return
      }
      if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
        triggerToast("Unable to Issue Cancel");
        console.log('Response Failed')
      }
      else if(response.data.hasOwnProperty("RuleInformationList") && response.data["RuleInformationList"].length > 0){
        let {transactionPrimaryObject} = stateobj
        let tpo={...transactionPrimaryObject,"ViewRuleMessages":"true", "firstCancelPage": "false"}
        let subappinfo = { subappName:stateobj.subappNameList, objectId: propsobj.objectId, objectVerId: propsobj.objectVerId, };
        let metadataResponse = await constructAndInvokeMetadataCall(subappinfo, {transactionPrimaryObject: tpo});
        functions.UpdateState ("viewrulemessages_dataList",response.data.RuleInformationList,false)
        functions.UpdateState("pageMetadata", metadataResponse, false);
       }
      else{
        await store.dispatch({
          type : actionTypes.SET_POLICYCONSOLE_INFO,
         payload :  {}
        })
        await store.dispatch({
          type : actionTypes.SET_PAGENAME,
         payload :  ""
        })
        propsobj.history.push("/policy")
      }
    })
    functions.UpdateState("loading",false,true);
  }catch(e){
    console.log('IssueCancel Failed',e);
    functions.UpdateState("loading",false,true);
  }
}

const agentESigner = async(propsObj,stateObj,component,e,props)=>{
  prefillESignerMailTemplate(propsObj,stateObj,component,e,props)
}

const insuredESigner = async(propsObj,stateObj,component,e,props)=>{
  prefillESignerMailTemplate(propsObj,stateObj,component,e,props)
}

const prefillESignerMailTemplate = async(propsObj,stateObj,component,e,props)=>{
  try{
  props.functions.UpdateState('loading', true, true);
  let request=await constructTransactionRequestV2(stateObj,props,{},false,false);
  let commonEventName=GetCommonEventName();
  request.EventName=commonEventName;
  request.ID=props.componentProps.UIFlowReducer.quoteID;
  request.TargetEventName=props.pageInfo.eventName.eSigner;
  request.RoleType  = component.Custom1
  let {objectId,objectVerId}=props.pageInfo
  //let {navigationReducer} = props.componentProp
  const UIFlowReducer = store.getState().UIFlowReducer
  let firstParam = {...propsObj,UIFlowReducer}
  let newProps = {
    objectId : objectId,
    objectVerId : objectVerId,
    subApplication : props.componentState.subappNameList,
    subappName: props.componentState.subappNameList
  }
  const response=await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
  if(response.data.hasOwnProperty('RuleInformationList')){
    let list = response.data.RuleInformationList
    let key = "true";
    const quoteDetailResponse = await quoteDetailInformation(stateObj, firstParam, null, null, props.functions.UpdateState,newProps,list,key)
    const keyValuePairs = Object.entries(quoteDetailResponse)
    await keyValuePairs.forEach((value,i)=>{
      let updateFlag = (keyValuePairs.length)-1===i
       value[1] && props.functions.UpdateState(value[0],value[1],updateFlag)
    })
  }else{
    let tObj={
      transactionPrimaryObject:{...props.componentState.transactionPrimaryObject,...response.data}
    }
    const metares=await constructAndInvokeMetadataCall(newProps,tObj)
    props.functions.UpdateState("pageMetadata",metares,false);
    props.functions.UpdateState("pagelevelValues",{...props.componentState.pagelevelValues,...response.data},false);
    props.functions.UpdateState('transactionPrimaryObject', tObj.transactionPrimaryObject, false);
    props.functions.UpdateState('loading', false, true);
    props.functions.UpdateState('email_showDialog', true, true);
  }
  }catch(e){
    console.log("Error in prefillESignerMailTemplate",e)
  }
}
const componentDidMount_Manage = async (state, props, prevState, prevProps) => {
  try{

    let reduxState = store.getState();

    if(!prevProps || ((props.location.pathname!== prevProps.location.pathname) || (props.UIFlowReducer.currentPageIndex !== prevProps.UIFlowReducer.currentPageIndex))){
      let {ObjectId: objectId, ObjectVerId:objectVerId, SubApplicationList} = props.UIFlowReducer.currentConsoleInfo;
      let primaryState = reduxState.metadataReducer.primaryState;
    let subappinfo = {
      subappName: SubApplicationList,
      objectId,
      objectVerId,
      primaryState,
    };
    let response = await constructAndInvokeMetadataCall(subappinfo, {
      transactionPrimaryObject: state.transactionPrimaryObject,
    });

    return { pageMetadata: response,pageSubAppInfo: subappinfo };
  }
  }
  catch(e){
    console.log("Error in componentDidMount_Manage", e);
  }
};
const handleInputChangeV2_Manage=(value, component,isDynamicTable,datatableRowindex, props, state,functions,DynamicProps)=> {
 let subAppInfo = {
    subappName: props.pageInfo["subApplication"],
    objectId: props.objectId,
    objectVerId: props.objectVerId
  }
 let obj =  handleInputChangeV2(value, component, props, state, functions,subAppInfo,{},true,DynamicProps)
 return obj;
}

const InvokeIntegrationConsole = async(EventName,propsObj,stateObj,component,functions,e,props)=> {
  let offsetInfo = {StartLimit: '0', EndLimit: '5'}
  await IntegrationConsoleAPI(propsObj, stateObj, component, props, offsetInfo, component.SubApplicationName)
}


const handleIntegrationConsole =async(offsetInfo, props)=> {
  try{
    let subappName = props.subAppInfo.SubApplicationDetail.ApplicationName.split('Summary')
    await IntegrationConsoleAPI(props.componentProps,props.componentState,{},props, offsetInfo, subappName[0])
  }
  catch(err){
    console.log("Error in handleIntegrationConsole", err)
  }
}

const InvokePDFGeneration_MPLSubmission=async(EventName,propsObj,stateObj,component,functions,e,props)=>{
  try{
    await InvokePDFGeneration(EventName,propsObj,stateObj,component,functions,e,props,true);
  }catch(e){
    console.log('Error in InvokePDFGeneration_MPLSubmission',e)
  }
}

const  IntegrationConsoleAPI=async(propsObj, stateObj, component, props, offsetInfo, SubApplicationName)=> {
  try{
    props.functions.UpdateState('loading',true,true);
    let request = {};
   request = {...offsetInfo}
    let values = await constructTransactionRequestV1(stateObj,propsObj, stateObj.pagelevelValues,null,false,false,'')
    request = {...request, ...values[SubApplicationName]}
  request["ServiceRequestDetail"]= Object.assign({},getServiceRequestDetail());
  request.OwnerId = sessionStorage.getItem("OwnerID");

  request.EventName=propsObj.UIFlowReducer.currentConsoleInfo.EventName;
   const response = await AxiosInstance.post("/core/invokeWorkflowEvent/V2", request)
    let temp = {}
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
      triggerToast("Response Failed");
      console.log("Response Failed");
    }else{
    temp[`${SubApplicationName.toLowerCase()}summary_dataList`] = response.data[SubApplicationName];

    let {ObjectId: objectId, ObjectVerId:objectVerId, SubApplicationList} = propsObj.UIFlowReducer.currentConsoleInfo;
    let subappinfo = {
      subappName: SubApplicationList,
      objectId,
      objectVerId,
    };
    let metadata = await constructAndInvokeMetadataCall(subappinfo, {
      transactionPrimaryObject: { "showResponse": "true"},
    });
    await mergeRowdatawithDatalist(metadata.ApplicationDetail.SubApplicationDetailList,temp,{});
  let dataList  = response.data[SubApplicationName].map(data=>{
    if(data.Status.toLowerCase()!=="failed"){
     let newData = data.rowMetaData.map(Attribute=> {
        if(Attribute.AttributeName === 'ViewError'){
          Attribute.ComponentType="outputtext"
          Attribute.AttributeStyleClass='hide'
        }
        return Attribute;
      })
      return {...data, rowMetaData: newData}
    }
    return data
    })

  props.functions.UpdateState(`${SubApplicationName.toLowerCase()}summary_dataList`, dataList, false)
  props.functions.UpdateState("pageMetadata", metadata, false)
  props.functions.UpdateState(`${SubApplicationName.toLowerCase()}summary__dataListSize`,response.data.Count,false);
  props.functions.UpdateState('loading',false,true);
  }
}
catch(e){
  console.log("Error in InvokeIntegrationConsole", e)
}
}


const ResetConsole =async(EventName,propsObj,stateObj,component,functions,e,props) => {
  try{

    props.functions.UpdateState('loading',true,true);
    let {ObjectId: objectId, ObjectVerId:objectVerId, SubApplicationList} = propsObj.UIFlowReducer.currentConsoleInfo;
    let subappinfo = {
    subappName: SubApplicationList,
    objectId,
    objectVerId,
  };
  let metadata = await constructAndInvokeMetadataCall(subappinfo, {
    transactionPrimaryObject: {},
  });
  props.functions.UpdateState(`${component.SubApplicationName.toLowerCase()}summary_dataList`, [], false)
  props.functions.UpdateState("pagelevelValues", {}, false)
  props.functions.UpdateState("transactionPrimaryObject", {}, false)
  props.functions.UpdateState("pageMetadata", metadata, false)
  props.functions.UpdateState('loading',false,true);
  }
  catch(err){
    console.log("err", err)
  }
}
const referQuote_Endorsement = (eventName,props,state,component,functions,e,prop) => {
  try{
    let targetEventName = props.pageInfo.ReferQuoteTargetEventName;
    referandOfferQuote(eventName,props,state,component,functions,e,prop,targetEventName)
  }
  catch(err){
    console.log('Error in referQuote_Endorsement',err)
  }
}

const TaxAndFeeOverride = async (value,component,dynammicTable,tableRowIndex,props,state) => {
  try{
  props.UpdateState('loading',true,true)
  let {rowData} = props
  let {objectId,objectVerId,subApplication} = props.componentProps.UIFlowReducer.currentPageInfo.template[0];
  let newProps = {
    objectId : objectId,
    objectVerId : objectVerId,
    subappName: subApplication
  }
  let Val =  component.SubApplicationName
  let metadataResponse = await constructAndInvokeMetadataCall(newProps,{transactionPrimaryObject: {...rowData,[Val]:{...rowData,...value}}});

  await props.handleRowLevelInputChange(value, props.component, props.customTableRowIndex, props);

  const SubapplicationList = metadataResponse.ApplicationDetail.SubApplicationDetailList

  SubapplicationList.forEach(subApplication=> {
    if(subApplication.ApplicationName===component.SubApplicationName){
      rowData.rowMetaData=subApplication.AttributeDetailList
    }
  })
  props.UpdateState('loading',false,true)
  }catch(err){
    console.log('Error in TaxAndFeeOverride',err)
  }
}
const onChangeMaskedCalendar=(props,value)=>{
  changeDOB(props,value,true)
}

async function setEditPrivilege(props,pageValues){
  try{
  let key = props.subapplicationName.toLowerCase() + "_dataList";
  let returnObj={}
  if(props.rowData && !props.rowData.CreatedRole){
    return returnObj;
  }
  let subappName=props.subapplicationName+'Detail'
  let currentRole=props.rowData.CreatedRole
  const reduxState=store.getState()
  let userRole=reduxState.userInfoReducer.userRole
  let dataList = props[key].map((data, index)=> {
    if(index === props.DataTableRowIndex){
      if(currentRole===userRole){
        returnObj={...data,...pageValues[subappName],'EditPrivilegeNeeded': 'true'}
        return returnObj;
      }else{
        returnObj={...data,...pageValues[subappName],'EditPrivilegeNeeded': 'false'}
        return returnObj;
      }
    }
    return data
   })
  props.UpdateState(key, dataList,false)
  return returnObj
}catch(e){
  console.log('Error in setEditPrivilege',e);
}
}

const referQuote_Homeowners = (eventName,props,state,component,functions,e,prop) => {
 let targetEventName = props.pageInfo.ReferQuoteTargetEventName;
 referandOfferQuote(eventName,props,state,component,functions,e,prop,targetEventName)
}

const getSubmission_Find = async (props,state) => {
  try{
  let request={}
  request.ServiceRequestDetail = getServiceRequestDetail();
  request.ID = props.parentProps.ID
  request.EventName = props.EventName
  request.TargetEventName = props.getSubmission
  let response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
  return response
  }
  catch(e){
    console.log('Error in Get SUbmission',e);
  }
}

const componentDidMount_FindSubmission = async (state, props, UpdateState)=> {
  try{
  UpdateState('loading',true,true)
  let getSubmissionResponse = await getSubmission_Find(props,state)
  let mountResult = await componentDidMount_Orchid(state, props, {...props.parentProps.FindSubmissionSummary,...getSubmissionResponse.data})
  let temp={}
  temp.ruleinformationlist_dataList = getSubmissionResponse.data.RuleInformationList
  await mergeRowdatawithDatalist(mountResult.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{});
  return {
    ...mountResult,
    pagelevelValues:{...props.pagelevelValues,...props.parentProps.FindSubmissionSummary,...getSubmissionResponse.data},
    transactionPrimaryObject:{...props.pagelevelValues,...props.parentProps.FindSubmissionSummary,...getSubmissionResponse.data},
    ruleinformationlist_dataList:getSubmissionResponse.data.RuleInformationList
  }
  }catch(e){
    triggerToast('Error in Get Details')
    console.log('Error in componentDidMount_FindSubmission',e);
  }finally{
    UpdateState('loading',false,true)
  }
}

const onSaveBusinessRule_FindSubmission = async (eventName,propsobj,stateobj,component,functions,e,props) => {
  try{
    functions.UpdateState("loading", true, true)
    let details={TargetEventName:props.pageInfo.saveBusinessRule,
    [`${component.AttributeName}`]:stateobj[`${component.AttributeName.toLowerCase()}_dataList`],EventName:props.pageInfo.EventName,
    [`${component.AttributeParams}`]:stateobj.pagelevelValues[`${component.AttributeParams}`],ROOTID:propsobj.parentProps.ID}
    let request = await constructTransactionRequestV2(stateobj,props,{},false,false);
    Object.assign(request,details)
    let response = await AxiosInstance.post("core/invokeWorkflowEvent/V2",request);
    let mountResult = await componentDidMount_Orchid(stateobj, propsobj, {...stateobj.transactionPrimaryObject,...response.data})
    functions.UpdateState("pageMetadata", mountResult.pageMetadata, false)
    functions.UpdateState("pagelevelValues", {...stateobj.pagelevelValues,...response.data}, false)
    functions.UpdateState("transactionPrimaryObject", {...stateobj.transactionPrimaryObject,...response.data}, false)
    let temp={}
    temp.ruleinformationlist_dataList=response.data[`${component.AttributeName}`]
    await mergeRowdatawithDatalist(stateobj.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{})
    response.data[`${component.AttributeName}`] && functions.UpdateState(`${component.AttributeName.toLowerCase()}_dataList`,response.data[`${component.AttributeName}`],false)
    if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
      triggerToast('Error in Save Business Rule')
    }else successToast("Business Rules Saved Successfully");
  }
  catch(e){
    triggerToast('Error in Save Business Rule')
    console.log("Error in onSaveBusinessRule_FindSubmission",e);
  }finally{
    functions.UpdateState("loading", false, true)
  }
}

const refer_Decline_FindSubmission = async (eventName,propsobj,stateobj,component,functions,e,props,type) => {
  try{
    functions.UpdateState("loading", true, true)
    let request = await constructTransactionRequestV2(stateobj,props,{},false,false);
    request.ID = propsobj.parentProps.ID
    request.EventName = props.pageInfo.EventName
    request.TargetEventName = type === props.pageInfo.ReferEventName ? props.pageInfo.ReferEventName : props.pageInfo.DeclineEventName
    await AxiosInstance.post("core/invokeWorkflowEvent/V2",request).then(async(response)=>{
      if(response.data.hasOwnProperty("ProcessStatus")){
        let temp={}
        temp.ruleinformationlist_dataList = stateobj.ruleinformationlist_dataList
        let mountResult = await componentDidMount_Orchid(stateobj, propsobj, {...stateobj.pagelevelValues,...response.data})
        functions.UpdateState("pagelevelValues", {...stateobj.pagelevelValues,...response.data}, false)
        functions.UpdateState("transactionPrimaryObject", {...stateobj.transactionPrimaryObject,...response.data}, false)
        functions.UpdateState("pageMetadata", mountResult.pageMetadata, false)
        await mergeRowdatawithDatalist(mountResult.pageMetadata.ApplicationDetail.SubApplicationDetailList,temp,{});
        type !== props.pageInfo.ReferEventName && triggerToast(`${response.data.ProcessStatus}`)
      }else{
        triggerToast(`Error in ${type}`)
      }
    })
  }
  catch(e){
    console.log("Error in refer_Decline_FindSubmission",e);
  }finally{
    functions.UpdateState("loading", false, true)
  }
}

const decline_Submission_Find = async (eventName,propsobj,stateobj,component,functions,e,props) => {
  try{
    await refer_Decline_FindSubmission(eventName,propsobj,stateobj,component,functions,e,props,props.pageInfo.DeclineType)
  }
  catch(e){
    console.log("Error in decline_Submission_Find",e);
  }
}

const refer_Submission_Find = async (eventName,propsobj,stateobj,component,functions,e,props) => {
  try{
    await refer_Decline_FindSubmission(eventName,propsobj,stateobj,component,functions,e,props,props.pageInfo.ReferType)
  }
  catch(e){
    console.log("Error in refer_Submission_Find",e);
  }
}

const availableMarket_Navigator = async (eventName,propsobj,stateobj,component,functions,e,props) => {
   try{
    functions.UpdateState("loading", true, true)
    await store.dispatch({
      type: actionTypes.SET_SUBMISSION_TYPE,
      payload: stateobj.pagelevelValues.SubmissionType
    })
    let prdDetailResponse = await getProductDetailsByInsuredId(propsobj.parentProps.InsuredID,propsobj.parentProps)
    let navObj = await getNavigation(["ProductNavigation"],[prdDetailResponse.ProductVerNumber])
    let templatefromredux = propsobj.templateInfo;
    if (navObj.hasOwnProperty("productNavigation")) {
    let key = prdDetailResponse.LOBName ? `${prdDetailResponse.LOBName}_submission` : "template";
    key = navObj.productNavigation[key] ? key : "template"
    templateConstruct(navObj, ["template"], templatefromredux);
    let prodnav = navObj.productNavigation[key];
    let pageNav = Object.assign({}, propsobj.navigation);
    pageNav.pageNavigation.submission.sidebar[1].children =  prodnav
    let index = getRequiredIndex(prodnav,'name','Available Markets')+1
    propsobj.setNavigation(pageNav);
    propsobj.setPageNavigation(prodnav);
    await store.dispatch({
      type: actionTypes.SET_CURRENT_PAGEINDEX,
      payload: index
    })
    await store.dispatch({
      type: actionTypes.SET_PRODUCT_DETAILS,
      payload: prdDetailResponse
    })
    await store.dispatch({
      type: actionType.SET_NAVIGATION,
      payload: {...propsobj.navigation,"productNavigation":{...navObj.productNavigation}},
    });
    }
    propsobj.history.push("/submission");
  }catch(e){
    console.log('Error in Navigation',e);
  }finally{
    functions.UpdateState("loading", false, true)
  }
}

function viewBumpRules_HomeOwners(propsObj,stateObj,component,e,props){
  try{
    let sublineName = propsObj.SublineList.SublineName
    let bumpList = stateObj[`${sublineName}CoverageInformationDetails`]
    let {componentProps:{parentState:{UpdateState}}}=props;
    UpdateState("popupPageValues",{...stateObj.pagelevelValues},false);
    UpdateState("BumpList",bumpList,false);
    UpdateState("ViewBumpRules__popupStatus",true,true);
  }
  catch(e){
    console.log("error in viewBumpRules_HomeOwners",e);
  }
}
const redirection = async (ID,goto,props) => {
  await store.dispatch({
    type: actionType.SET_FLOW_TYPE,
    payload: 'Find',
  });
  props.history.push({
      pathname: goto,
      state : { "ID" : ID}
  });
}

const handleRedirection=async (ID, goto,props)=>{
  let obj = props.currentFindConsoleInfo ? props.currentFindConsoleInfo : {}
  let { component, rowData, tableUtils } = props;
  let { name } = obj;
  goto=goto ? goto : rowData.ObjectType.toLowerCase();
  let Name= name ? name : rowData.ObjectType;
  switch(Name.toLowerCase())
  {
      case 'account' :
        {
            await store.dispatch({
              type: actionType.SET_ACCOUNTID,
              payload: ID,
            });
            redirection(ID,goto,props)
            break
        }
        case 'quote' :
        {
            await Promise.all([ store.dispatch({
              type: actionType.SET_QUOTE_ID,
              payload: ID,
            })])
            store.dispatch({
              type: actionType.SET_CURRENT_PAGEINDEX,
              payload: 1,
            })
            redirection(ID,goto,props)
            break
        }
        case 'policy' :
        {
            await Promise.all([ store.dispatch({
              type: actionType.SET_POLICY_ID,
              payload: ID,
            })])
            store.dispatch({
              type: actionType.SET_CURRENT_PAGEINDEX,
              payload: 1,
            })
            redirection(ID,goto,props)
            break
        }
        case 'policy by risk' :
        {
                await Promise.all([getNavigationDetails(ID, props, Name), store.dispatch({
                  type: actionType.SET_POLICY_ID,
                  payload: ID,
                }), store.dispatch({
                  type: actionType.SET_CURRENT_PAGEINDEX,
                  payload: 1,
                })])
                redirection(ID,goto,props)
                break
        }
      case 'submission' :
                {
                    await store.dispatch({
                      type: actionType.SET_SUBMISSIONID,
                      payload: ID,
                    });
                    ID = rowData.InsuredID;
                }
      case 'insured' :
            {
                await Promise.all([getNavigationDetails(ID, props, Name), store.dispatch({
                  type: actionType.SET_INSUREDID,
                  payload: ID,
                })])
                store.dispatch({
                  type: actionType.SET_CURRENT_PAGEINDEX,
                  payload: 2,
                })
                redirection(ID,goto,props);
                break;
            }
      default:
  }
}
export const getNavigationDetailsByInsured =async (ID, props) => {

  let getNavRequest = {},prodnav=[];
  let reduxState = store.getState()
  let request = Object.assign({}, getServiceRequestDetail());
  getNavRequest["ServiceRequestDetail"] = request
  getNavRequest.ID = ID
  getNavRequest.ROOTID=props.UIFlowReducer.submissionID?props.UIFlowReducer.submissionID:reduxState.UIFlowReducer.submissionID
  getNavRequest.OwnerId = sessionStorage.getItem("OwnerID");
  getNavRequest.EventName = "GetInsuredProductDetails";
  let response = await commonProductAxiosInstance.post("core/invokeWorkflowEvent/", getNavRequest);
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed"){
    console.log("Response Failed");
    triggerToast("Response Failed");
  }else{
  await store.dispatch({
    type: actionType.SET_AGENTID,
    payload: response.data.AgentId
  });
}
  let prdObj = response.data
  store.dispatch({
    type: "SET_LOB",
    payload: prdObj.LOBName,
  });
  store.dispatch({
    type: "SET_PRODUCT_DETAILS",
    payload: prdObj,
  });
  let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"],[prdObj.ProductVerNumber]);
  const redux=store.getState();
  let templateInfoTemplates = redux.navigationReducer.templateInfo;
  if(navObj.hasOwnProperty('productTemplateInfo')){
    //Util Construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template])
    })
    //SubTemplate construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
    })
    store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}})
  }
  let templatefromredux = {...templateInfoTemplates,...navObj.productTemplateInfo};
  if (navObj.hasOwnProperty("productNavigation")) {
    let key = prdObj.LOBName ? `${prdObj.LOBName}_submission` : "template";
    key = navObj.productNavigation[key] ? key : "template"
    templateConstruct(navObj, ["template"], templatefromredux);
    prodnav = navObj.productNavigation[key];
    let pageNav = Object.assign({}, redux.navigationReducer.navigation);
    let index =getRequiredIndex(pageNav.pageNavigation.submission.sidebar,'name','Submission')
    pageNav.pageNavigation.submission.sidebar[index].children = prodnav;
    await store.dispatch({
      type : actionType.SET_NAVIGATION,
     payload :  pageNav
    })
    await store.dispatch({
      type : actionType.SET_PAGE_NAVIGATION,
     payload :  prodnav
    })
    store.dispatch({
      type: actionType.SET_NAVIGATION,
      payload: {...redux.navigationReducer.navigation,"productNavigation":{...navObj.productNavigation}},
  });
  }
  return prodnav;
}

export const getNavigationDetailsBySubmission =async (ID, props) => {

  let getNavRequest = {},prodnav=[];
  let request = Object.assign({}, getServiceRequestDetail());
  getNavRequest["ServiceRequestDetail"] = request
  getNavRequest.ID = ID
  getNavRequest.PARENTID = ID
  getNavRequest.ROOTID= ID
  getNavRequest.OwnerId = sessionStorage.getItem("OwnerID");
  getNavRequest.EventName = "SolartisPLCommonMaster";
  getNavRequest.TargetEventName = "Verdant_SP_1_V1_SP_GetSubmission_1.0.0.2";
  let response = await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2", getNavRequest);
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed") {
    console.log("Response Failed");
    triggerToast("Response Failed");
  } else {
    // response.data = GetSubmissionRes
  await store.dispatch({
    type: actionType.SET_AGENTID,
    payload: response.data.AgentId
  });
}
  const redux = store.getState();
  let prdObj = response.data.SelectedLineOfBusiness[0]
  store.dispatch({
    type: "SET_LOB",
    payload: prdObj.LOBName,
  });
  store.dispatch({
    type: "SET_PRODUCT_DETAILS",
    payload: prdObj,
  });
  store.dispatch({ type: actionType.SET_FLOW, payload: prdObj.ProductType === 'Non Configured' ? 'BrokerageMarket' : 'QuoteNow'})
  let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"],[prdObj.ProductVerNumber]);
  let templateInfoTemplates = redux.navigationReducer.templateInfo;
  if(navObj.hasOwnProperty('productTemplateInfo')){
    //Util Construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template])
    })
    //SubTemplate construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
    })
    store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}})
  }
  let templatefromredux = {...templateInfoTemplates,...navObj.productTemplateInfo};
  if (navObj.hasOwnProperty("productNavigation")) {
    let key = prdObj.LOBName ? `${prdObj.LOBName}_submission` : "template";
    key = navObj.productNavigation[key] ? key : "template"
    templateConstruct(navObj, ["template"], templatefromredux);
    prodnav = navObj.productNavigation[key];
    let pageNav = Object.assign({}, redux.navigationReducer.navigation);
    let index =getRequiredIndex(pageNav.pageNavigation.submission.sidebar,'name','Submission')
    pageNav.pageNavigation.submission.sidebar[index].children = prodnav;
    await store.dispatch({
      type : actionType.SET_NAVIGATION,
     payload :  pageNav
    })
    await store.dispatch({
      type : actionType.SET_PAGE_NAVIGATION,
     payload :  prodnav
    })
    store.dispatch({
      type: actionType.SET_NAVIGATION,
      payload: {...redux.navigationReducer.navigation,"productNavigation":{...navObj.productNavigation}},
  });
  }
  let pageIndex
  if (redux.UIFlowReducer.landingPage && redux.authReducer.fromSSO) {
    let pageName = redux.UIFlowReducer.landingPage
    pageIndex =  prodnav.findIndex(i => i.pageName === pageName);
    store.dispatch({ type: actionType.SET_LANDING_PAGE, payload: '' });
  }
  store.dispatch({type: actionTypes.SET_UI_VALUES, payload: {currentPageIndex: pageIndex !== -1 ? pageIndex + 1 : 2 }})
  return prodnav;
}
export const getProductDetailsBySubmission =async (ID, props) => {

  let getNavRequest = {},prodnav=[];
  let request = Object.assign({}, getServiceRequestDetail());
  getNavRequest["ServiceRequestDetail"] = request
  getNavRequest.OwnerId = sessionStorage.getItem("OwnerID");
  getNavRequest.EntityName = props.pageInfo.EntityName;
  getNavRequest.EventName = props.pageInfo.CommonEventName;
  getNavRequest.TargetEventName = props.pageInfo.TargetEventName;
  getNavRequest.ID = ID
  getNavRequest.PARENTID = ID
  getNavRequest.ROOTID= ID
  let response = await commonProductAxiosInstance.post("core/invokeWorkflowEvent/V2", getNavRequest);
  let reduxState = store.getState();
  if(props.pageInfo.onLoadURLInfo){
    let val=window.location.hash.split('/'),pageData={},redirectRoute='',obj={};
    if(val.length > 1 && val[2]){
        let newVal=val[2];
        pageData = decodeString(newVal);
    }
    obj = await dynamicObjectConstruction(props.pageInfo.onLoadURLInfo,{...response.data,...reduxState.UIFlowReducer},obj,'response');
    pageData={...pageData,...obj}
    await store.dispatch({ type: actionType.SET_UI_VALUES, payload: {pageData:{...pageData}}})
    const encodedString= await constructEncodedString(pageData);
    redirectRoute+=`/${val[1]}/${encodedString}`;
    props.history.push(redirectRoute);
  }
  if(response.data.hasOwnProperty('ResponseStatus') && response.data.ResponseStatus.toLowerCase() === "failed") {
    console.log("Response Failed");
    triggerToast("Response Failed");
  } else {
  await store.dispatch({
    type: actionType.SET_AGENTID,
    payload: response.data.AgentId
  });
}
  const redux = store.getState();
  let prdObj =response.data && response.data;
  prdObj && store.dispatch({
    type: "SET_LOB",
    payload: prdObj.LOBName,
  });
  prdObj && store.dispatch({
    type: "SET_PRODUCT_DETAILS",
    payload: prdObj,
  });
  store.dispatch({ type: actionType.SET_FLOW, payload: prdObj.ProductType === 'Non Configured' ? 'BrokerageMarket' : 'QuoteNow'})
  let navObj = await getNavigation(["ProductNavigation","ProductTemplateInfo"],[prdObj.ProductVerNumber]);
  let templateInfoTemplates = redux.navigationReducer.templateInfo;
  if(navObj.hasOwnProperty('productTemplateInfo')){
    //Util Construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template])
    })
    //SubTemplate construction in Templates
    Object.keys(navObj.productTemplateInfo).forEach( async (template) => {
      navObj.productTemplateInfo[template] = await constructTemplateWithUtil(navObj.productTemplateInfo[template],['details','template'],navObj.productTemplateInfo)
    })
    store.dispatch({type: actionType.SET_TEMPLATE_INFO, payload: {...templateInfoTemplates,...navObj.productTemplateInfo}})
  }
  let templatefromredux = {...templateInfoTemplates,...navObj.productTemplateInfo};
  if (navObj.hasOwnProperty("productNavigation")) {
    let key = prdObj.LOBName ? `${prdObj.LOBName}_submission` : "template";
    key = navObj.productNavigation[key] ? key : "template"
    templateConstruct(navObj, ["template"], templatefromredux);
    prodnav = navObj.productNavigation[key];
    let pageNav = Object.assign({}, redux.navigationReducer.navigation);
    let index =getRequiredIndex(pageNav.pageNavigation.submission.sidebar,'name','Submission')
    pageNav.pageNavigation.submission.sidebar[index].children = prodnav;
    await store.dispatch({
      type : actionType.SET_NAVIGATION,
     payload :  pageNav
    })
    await store.dispatch({
      type : actionType.SET_PAGE_NAVIGATION,
     payload :  prodnav
    })
    store.dispatch({
      type: actionType.SET_NAVIGATION,
      payload: {...redux.navigationReducer.navigation,"productNavigation":{...navObj.productNavigation}},
  });
  }
  let pageIndex
  if (redux.UIFlowReducer.landingPage && redux.authReducer.fromSSO) {
    let pageName = redux.UIFlowReducer.landingPage
    pageIndex =  prodnav.findIndex(i => i.pageName === pageName);
    store.dispatch({ type: actionType.SET_LANDING_PAGE, payload: '' });
  }
  store.dispatch({type: actionTypes.SET_UI_VALUES, payload: {currentPageIndex: pageIndex !== -1 ? pageIndex + 1 : 2 }})
  return prodnav;
}
const getDashboardTableData = async (offsetInfo = {}, props, state, defaults, pageMetadata) => {
  try {
    props.functions && props.functions.UpdateState("loading", true, true);
    const redux=store.getState();
    let AgentID=redux.userInfoReducer.defaultAgentID;
    let { eventVersion, objectId, objectVerId, subApplication } = props.pageInfo;
    let eventURL = `core/invokeWorkflowEvent`, StartLimit = "0", EndLimit = "0"
    if(eventVersion) eventURL = `${eventURL}/${eventVersion}`
    if(Object.keys(offsetInfo).length === 0){
      let tableActions = getTableActions(pageMetadata, "pagination");
      EndLimit = tableActions.rowsPerPage;
    }
    let dashboardDataRequest = {}, dashboardDataResponse = {};
    dashboardDataRequest['ServiceRequestDetail'] = Object.assign({}, getServiceRequestDetail());
    dashboardDataRequest['OwnerId'] = sessionStorage.getItem('OwnerID') || "";
    if(AgentID) dashboardDataRequest['AgentID'] = AgentID;
    dashboardDataRequest = { ...dashboardDataRequest, ...props.pageInfo.eventNames, StartLimit, EndLimit, ...offsetInfo }
    if(defaults && Object.keys(defaults).length > 0){
      Object.keys(defaults).map( attr => {
        dashboardDataRequest = { ...dashboardDataRequest, ...defaults[attr]}
      } )
    }
    else
      Object.keys(props.parentProps.pagelevelValues).map( attr => dashboardDataRequest = { ...dashboardDataRequest, ...props.parentProps.pagelevelValues[attr] })
    const[dashboardEntity, dashboardData] = await Promise.all([getEntitySubAppMappingV2({subappName: subApplication, objectId, objectVerId, state: 'CW', subApplicationGroupList: []}, "V2"), AxiosInstance.post(eventURL, dashboardDataRequest)])
   let translatedData = await EntitySubAppTranslationV2(dashboardData.data, { "SubApplicationEntity" : dashboardEntity })
    translatedData = { ...translatedData, "loading": false }

    if(defaults)
    return {...translatedData,"pagelevelValues":{...defaults},"loading": false };
    else if(props.functions && props.functions.updateValues)
      props.functions.updateValues(translatedData)
  }
  catch(error) {
    console.log("Exception in getting Dashboard table data :: ", error)
  }
}

const objectChange=async(value,component, isDynamicTable, datatableRowindex,props)=>{
  try{
    props.functions.UpdateState('loading',true,true);
    let metadataTransaction={...props.pagelevelValues};
    deleteDependentAttribute(component,metadataTransaction,props.transactionPrimaryObject,props)
    let {subApplication,objectId,objectVerId}=props.pageInfo;
    let subAppInfo={
      subappName: subApplication,
      objectId,
      objectVerId
    }
    let keys = Object.keys(value);
    let attributeName = keys[0];
    let attributeValue = value[attributeName]
    metadataTransaction[component.SubApplicationName][attributeName] = attributeValue;
    let response = await constructAndInvokeMetadataCall(subAppInfo, { transactionPrimaryObject: metadataTransaction });
    let defaultValues = constructDefaultValues(response,{...metadataTransaction});
    props.functions.UpdateState("pagelevelValues",{...defaultValues,...metadataTransaction},false);
    props.functions.UpdateState("pageMetadata",response,false);
    props.functions.UpdateState("transactionPrimaryObject",{...defaultValues,...metadataTransaction},false);
    props.functions.UpdateState("loading",false,false);
    commonSelectMethod(component, value, props, false);
  }catch(e){
    console.log('Error in objectChange',e)
  }
}

const componentDidMount_TabChildren = async (state, props, functions) => {

  try{
    functions("loading",true,true);
    // temporary
    let dashboardData = { "loading": false }
    let tList = Object.assign({},state.transactionPrimaryObject);
    let { objectId, objectVerId } = props.pageInfo;
    let subappinfo = {subappName: props.pageInfo['subApplication'], objectId, objectVerId, state: 'CW', subApplicationGroupList: []}
    let response = await constructAndInvokeMetadataCall(subappinfo, {transactionPrimaryObject : tList});
    let defaultValues = constructDefaultValues(response);
    dashboardData = await getDashboardTableData({}, props, state, defaultValues, response)
    return { pageMetadata: response, subappNameList: props.pageInfo["subApplication"], transactionPrimaryObject : tList, ...dashboardData,"loading": false}
  }
  catch(error){
    console.log('error in Mount Tab Children :: ', error)
  }
  finally{

  }
}

const componentDidUpdate_TabChildren = async (state, props, prevState, prevProps, hocFunctions) => {

}

const handleInputChangeV2_NewUtil=async(value, component,isDynamicTable,datatableRowindex, props, state,functions
)=>{
  try{
    let obj = handleInputChangeV2( value, component, props, state, functions);
      deleteDependentAttribute(component,obj.pagelevelValues,obj.transactionPrimaryObject);
      let temp=obj;
      if(component.AjaxNeeded === "true"){
      let values
      values = await callBackmethod_Orchid(temp, props,state);
      return values;
      }else
      return temp;
  }catch(e){
    console.log('Error in handleInputChangeV2_NewUtil',e)
  }
}

const handleRowLevelInputChangerules = (value, component, datatableRowindex, props) => {
  let keys = Object.keys(value);
  let attributeName = keys[0];
  let attributeValue = value[attributeName].toString();
  let tablename = component.SubApplicationName.toLowerCase() + "_dataList";
  props.functions.saveTableData(tablename, attributeName, attributeValue, datatableRowindex);
}

const disablecheckbox = (rowData) => {
  let isReadOnly = rowData.FormType === 'Optional' ? false : true
  return isReadOnly
}
const handleQuotaShareUpdate_package=async(response,props,stateObj,e)=>{
try{
  if(response){
    let UpdateStateV1=props.componentProps.parentState.UpdateState;
    let UpdateStateV2=props.componentProps.parentPropsValue.parentState.UpdateState;
  const {metadataReducer} = store.getState();
  let {SubApplicationName,AttributeName}=props.component
  if("ProcessStatus" in response && metadataReducer.ProcessStatus !== response.ProcessStatus){
    store.dispatch({type: appActions.actionType.SET_PROCESS_STATUS, payload: response.ProcessStatus});
    store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
}
let iteratorIndex = props.componentProps.UIFlowReducer.Availablemarket_IteratorIndex
let {componentProps:{AvailableMarketList_dataList}}=props;
let summarySubApp = [];
let subappName=props.componentProps.parentPropsValue.iteratorSubappList
subAppListConstructionV1(subappName, {}, [], [], summarySubApp)
if(response  && response.hasOwnProperty("ExternalQuoteNumber")){ store.dispatch({ type: actionType.SET_EXTERNAL_QUOTE_NUMBER, payload: response.ExternalQuoteNumber }); }
const { ProductID: objectId, ProductVerID: objectVerId } =props.componentProps.productDetails;
let subappinfo = {
  subappName,
  objectId,
  objectVerId,
  state:"CW",
  subApplicationGroupList: [],
};
let editableCoverage = "activeConfiguration" in props.pageInfo && props.pageInfo.activeConfiguration=== 'package' ? true: false;
// await store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: false })

// let Tlist = {AvailableMarket:{...props.componentProps.parentPropsValue.AvailableMarket,...AvailableMarketList_dataList[iteratorIndex]["AvailableMarket"],"IsReferralRuleApplied": response.RuleStatus && response.RuleStatus ||"false"},...response}
let Tlist = {}
let metadataresponse = []
let checkRuleList;
let responseFromTranslation = {}
 checkRuleList = false;
 responseFromTranslation=await EntitySubAppTranslationV2({...response},props.componentProps.pageMetadata.ApplicationDetail);
// Tlist = {...Tlist, ...responseFromTranslation}
// metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
//   transactionPrimaryObject:Tlist,
//   EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
// });

let Promisedata = AvailableMarketList_dataList.map((data,i) =>{
  Tlist = {...AvailableMarketList_dataList[i],AvailableMarket:{...props.componentProps.AvailableMarket,...AvailableMarketList_dataList[i]["AvailableMarket"],"IsReferralRuleApplied":"false"}}
  if(iteratorIndex === i)
  Tlist = {...Tlist,...responseFromTranslation}
  Tlist["ShowBusinessRules"] ="false";
  return constructAndInvokeMetadataCall(subappinfo, {
    transactionPrimaryObject:Tlist,
    EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
  })
})
let metacalls = await Promise.allSettled(Promisedata)
metacalls.map((meta,i) => {
  if(meta.status == 'fulfilled' && meta.hasOwnProperty('value')){
    let metaresponse = meta.value && meta.value.ApplicationDetail && meta.value.ApplicationDetail.SubApplicationDetailList
    AvailableMarketList_dataList.map((data,index) => {
      if (index === i){
        data["SubApplicationDetailList"] = metaresponse
      }
      return
    })
  }
})
// let metadata=metadataresponse && metadataresponse.ApplicationDetail && metadataresponse.ApplicationDetail.SubApplicationDetailList
AvailableMarketList_dataList[iteratorIndex] = {...AvailableMarketList_dataList[iteratorIndex],...responseFromTranslation,...response}
AvailableMarketList_dataList[iteratorIndex]["AvailableMarket"]["TotalPremium"]=response.Coverage && response.Coverage.TotalPremium;
AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.parentPropsValue.SublineList.SublineName}CoverageInformationDetails`]= [];
AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.parentPropsValue.SublineList.SublineName}CoverageInformationDetails`]= (response.Coverage && response.Coverage.CoverageRuleInformationList) ? [...response.Coverage.CoverageRuleInformationList] : [] ;
AvailableMarketList_dataList[iteratorIndex][`feescarrier_dataList`] = response.Fee;
AvailableMarketList_dataList[iteratorIndex][`taxescarrier_dataList`] = response.Tax;
// AvailableMarketList_dataList[iteratorIndex]['SubApplicationDetailList']= metadata|| []
AvailableMarketList_dataList[iteratorIndex]['SubmissionRatingDataID']=  response.SubmissionRatingDataID;
AvailableMarketList_dataList[iteratorIndex]['isEligible']= response.RuleStatus && response.RuleStatus.toLowerCase();
AvailableMarketList_dataList[iteratorIndex]['viewbusinessrulespl_dataList']= response.RuleInformationList || [];
// if(response.CarrierDisplayName)
// AvailableMarketList_dataList[iteratorIndex]["AvailableMarket"].CarrierDisplayName = response.CarrierDisplayName && response.CarrierDisplayName;
UpdateStateV1('ruleinformationlistpl_dataList',response.RuleInformationList,false)
// response && response.Coverage &&  Object.keys(response.Coverage).forEach(
//    (key) => {
//     if(summarySubApp.includes(key)){
//       UpdateStateV1(`${key.toLocaleLowerCase()}_dataList`,response.Coverage[key],false);
//     }
//   }
// )
props.componentProps.parentPropsValue.pageInfo.dataListName.map((datalist)=> {
  if(datalist in response.Coverage)
  AvailableMarketList_dataList[iteratorIndex][`${datalist.toLocaleLowerCase()}_dataList`] =  response.data.Coverage[datalist]
})
let sortedList=[...AvailableMarketList_dataList]
let issort=sortedList.every( (data)=>data["AvailableMarket"] && data["AvailableMarket"].hasOwnProperty("TotalPremium") )
let temp={}
temp.ruleinformationlistpl_dataList = response.RuleInformationList
// if(response && response.hasOwnProperty("RuleStatus") &&metadata ){
// await mergeRowdatawithDatalist(metadata,temp,{});
// }

// if(prevprops && props.componentProps.selectedPolicy!==prevprops.componentProps.selectedPolicy){
//   summarySubApp.map(key=>{
//     UpdateState(`${key.toLocaleLowerCase()}_dataList`,[],false);
//     UpdateStateV1(`${key.toLocaleLowerCase()}_dataList`,[],false);
//   })
//   }
  UpdateStateV1("transactionPrimaryObject",Tlist,false);
  UpdateStateV2("PopUp_ScheduleOfForms",response.Coverage,false);
if(issort){
 let sortedList_ = sortedList.filter(data =>data.AvailableMarket && data.AvailableMarket.TotalPremium )
  sortedList_.length > 1 && sortedList.sort( (a,b)=>{return Number(a.AvailableMarket.TotalPremium) - Number(b.AvailableMarket.TotalPremium)});
    if(checkRuleList===undefined || checkRuleList===true){
      UpdateStateV2('AvailableMarketList_dataList',sortedList,false);
    }else{
      UpdateStateV1('AvailableMarketList_dataList',sortedList,false);
    }

}
else{
let sortedList=[AvailableMarketList_dataList[iteratorIndex],...AvailableMarketList_dataList]
if(checkRuleList===undefined || checkRuleList===true){
  UpdateStateV2('AvailableMarketList_dataList',sortedList,false);
}else{
  UpdateStateV1('AvailableMarketList_dataList',sortedList,false);

}
}
  UpdateStateV2('AvailableMarketList_dataList',sortedList,false);
  UpdateStateV1("pagelevelValues",AvailableMarketList_dataList[iteratorIndex],true);
store.dispatch({type:actionType.SET_UI_VALUES,payload: { "QuotaShare_popup": false}});
  }
}
catch(err){
  console.error("Error in handleQuotaShareUpdate",err)
}
}

const handleQuotaShareUpdate=async(response,props,stateObj,e)=>{
  try{
    if(response){
      let UpdateStateV1=props.componentProps.parentState.UpdateState;
      let UpdateStateV2=props.componentProps.parentPropsValue.parentState.UpdateState;
    const {metadataReducer} = store.getState();
    let {SubApplicationName,AttributeName}=props.component
    if("ProcessStatus" in response && metadataReducer.ProcessStatus !== response.ProcessStatus){
      store.dispatch({type: appActions.actionType.SET_PROCESS_STATUS, payload: response.ProcessStatus});
      store.dispatch({type: appActions.actionType.SET_EXPANDPANEL_DATA,payload: true})
  }
  let iteratorIndex = props.componentProps.UIFlowReducer.Availablemarket_IteratorIndex
  let {componentProps:{AvailableMarketList_dataList}}=props;
  let summarySubApp = [];
  let subappName=props.componentProps.parentPropsValue.iteratorSubappList
  subAppListConstructionV1(subappName, {}, [], [], summarySubApp)
  if(response  && response.hasOwnProperty("ExternalQuoteNumber")){ store.dispatch({ type: actionType.SET_EXTERNAL_QUOTE_NUMBER, payload: response.ExternalQuoteNumber }); }
  const { ProductID: objectId, ProductVerID: objectVerId } =props.componentProps.productDetails;
  let subappinfo = {
    subappName,
    objectId,
    objectVerId,
    state:"CW",
    subApplicationGroupList: [],
  };
  await store.dispatch({ type: actionType.SET_ADJUSTCOVERAGE, payload: false })

  let Tlist = {AvailableMarket:{...props.componentProps.parentPropsValue.AvailableMarket,...AvailableMarketList_dataList[iteratorIndex]["AvailableMarket"],"IsReferralRuleApplied": response.RuleStatus && response.RuleStatus ||"false"},...response}
  let metadataresponse = []
  let checkRuleList;
  let responseFromTranslation = {}
   checkRuleList = false;
   responseFromTranslation=await EntitySubAppTranslationV2({...response},props.componentProps.pageMetadata.ApplicationDetail);
  Tlist = {...Tlist, ...responseFromTranslation}
  metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
    transactionPrimaryObject:Tlist,
    EffectiveDate: props.value && props.value.EffectiveDate ? props.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
  });
  let metadata=metadataresponse && metadataresponse.ApplicationDetail && metadataresponse.ApplicationDetail.SubApplicationDetailList
  AvailableMarketList_dataList[iteratorIndex] = {...AvailableMarketList_dataList[iteratorIndex],...responseFromTranslation,...response}
  AvailableMarketList_dataList[iteratorIndex]["AvailableMarket"]["TotalPremium"]=response.Coverage && response.Coverage.TotalPremium;
  AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.parentPropsValue.SublineList.SublineName}CoverageInformationDetails`]= [];
  AvailableMarketList_dataList[iteratorIndex][`${props.componentProps.parentPropsValue.SublineList.SublineName}CoverageInformationDetails`]= (response.Coverage && response.Coverage.CoverageRuleInformationList) ? [...response.Coverage.CoverageRuleInformationList] : [] ;
  AvailableMarketList_dataList[iteratorIndex][`feescarrier_dataList`] = response.Fee;
  AvailableMarketList_dataList[iteratorIndex][`taxescarrier_dataList`] = response.Tax;
  AvailableMarketList_dataList[iteratorIndex]['SubApplicationDetailList']= metadata|| []
  AvailableMarketList_dataList[iteratorIndex]['SubmissionRatingDataID']=  response.SubmissionRatingDataID;
  AvailableMarketList_dataList[iteratorIndex]['isEligible']= response.RuleStatus && response.RuleStatus.toLowerCase();
  AvailableMarketList_dataList[iteratorIndex]['viewbusinessrulespl_dataList']= response.RuleInformationList || [];
  if(response.CarrierDisplayName)
  AvailableMarketList_dataList[iteratorIndex]["AvailableMarket"].CarrierDisplayName = response.CarrierDisplayName && response.CarrierDisplayName;
  UpdateStateV1('ruleinformationlistpl_dataList',response.RuleInformationList,false)
  response && response.Coverage &&  Object.keys(response.Coverage).forEach(
     (key) => {
      if(summarySubApp.includes(key)){
        UpdateStateV1(`${key.toLocaleLowerCase()}_dataList`,response.Coverage[key],false);
      }
    }
  )
  let sortedList=[...AvailableMarketList_dataList]
  let issort=sortedList.every( (data)=>data["AvailableMarket"] && data["AvailableMarket"].hasOwnProperty("TotalPremium") )
  let temp={}
  temp.ruleinformationlistpl_dataList = response.RuleInformationList
  if(response && response.hasOwnProperty("RuleStatus") &&metadata ){
  await mergeRowdatawithDatalist(metadata,temp,{});
  }

  // if(prevprops && props.componentProps.selectedPolicy!==prevprops.componentProps.selectedPolicy){
  //   summarySubApp.map(key=>{
  //     UpdateState(`${key.toLocaleLowerCase()}_dataList`,[],false);
  //     UpdateStateV1(`${key.toLocaleLowerCase()}_dataList`,[],false);
  //   })
  //   }
    UpdateStateV1("transactionPrimaryObject",Tlist,false);
    UpdateStateV2("PopUp_ScheduleOfForms",response.Coverage,false);
  if(issort){
   let sortedList_ = sortedList.filter(data =>data.AvailableMarket && data.AvailableMarket.TotalPremium )
    sortedList_.length > 1 && sortedList.sort( (a,b)=>{return Number(a.AvailableMarket.TotalPremium) - Number(b.AvailableMarket.TotalPremium)});
      if(checkRuleList===undefined || checkRuleList===true){
        UpdateStateV2('AvailableMarketList_dataList',sortedList,false);
      }else{
        UpdateStateV1('AvailableMarketList_dataList',sortedList,false);
      }

  }
  else{
  let sortedList=[AvailableMarketList_dataList[iteratorIndex],...AvailableMarketList_dataList]
  if(checkRuleList===undefined || checkRuleList===true){
    UpdateStateV2('AvailableMarketList_dataList',sortedList,false);
  }else{
    UpdateStateV1('AvailableMarketList_dataList',sortedList,false);

  }
  }
    UpdateStateV2('AvailableMarketList_dataList',sortedList,false);
    UpdateStateV1("pagelevelValues",AvailableMarketList_dataList[iteratorIndex],true);
  store.dispatch({type:actionType.SET_UI_VALUES,payload: { "QuotaShare_popup": false}});
    }
  }
  catch(err){
    console.error("Error in handleQuotaShareUpdate",err)
  }
  }

const handleQuotaShareSave=async (request,propsObj,e)=>{
  try{
  let PropertySplitPercent=0;
  let LiabilitySplitPercent=0;
  request.Quotashare.map((data)=>{
    PropertySplitPercent=PropertySplitPercent + Number(data.PropertySplitPercent);
    LiabilitySplitPercent=LiabilitySplitPercent + Number(data.LiabilitySplitPercent);
  })
  if(PropertySplitPercent==100 && LiabilitySplitPercent==100){
    let data=propsObj.componentProps.AvailableMarketList_dataList[propsObj.componentProps.UIFlowReducer.Availablemarket_IteratorIndex]
    let AvailableMarket ={
      "AvailableMarket": data.AvailableMarket,
      "ProductSelection":propsObj.componentProps.parentPropsValue.AvailableMarket,
      "insuredID":propsObj.componentProps.UIFlowReducer.insuredID,
      ...propsObj.componentProps.parentPropsValue.SublineList
    }
    request={
      ...request,
      "Coverage":data.Coverage,
      "AvailableMarket":AvailableMarket
    }
    return request;
  }
  return;
}
catch(err){
  console.error('Error in handleQuotaShareSave',err)
}
}

const saveQuotaShare_package = async (request, propsObj, e, stateObj) => {
  try {
    let UpdateStateV1 = propsObj.componentProps.parentState.UpdateState;
    let UpdateStateV2 =
      propsObj.componentProps.parentPropsValue.parentState.UpdateState;
    let UpdateStateV3 = propsObj.functions.UpdateState;
    let PropertySplitPercent = 0;
    let LiabilitySplitPercent = 0;
    request.Quotashare.map((data) => {
      PropertySplitPercent =
        PropertySplitPercent + Number(data.PropertySplitPercent);
      LiabilitySplitPercent =
        LiabilitySplitPercent + Number(data.LiabilitySplitPercent);
    });
    let AvailableMarketList = propsObj.componentProps
      .AvailableMarketList_dataList
      ? propsObj.componentProps.AvailableMarketList_dataList
      : [];


    let subappListLoader = [
      "AvailableMarket"
  ]

  const { ProductID: objectId, ProductVerID: objectVerId, ProductNumber, ProductVerNumber } =
  propsObj.componentProps.productDetails;

    let subappinfo = {
      objectId,
      objectVerId,
      state:"CW",
      subApplicationGroupList: [],
    };

    let tpo = {ProductSelection:  AvailableMarketList[0].ProductSelection}

    UpdateStateV2("loading", true, true)
    let LoadingMetaDataRes = await constructAndInvokeMetadataCall({...subappinfo,subappName: subappListLoader}, {
      transactionPrimaryObject:{...tpo, "AvailableMarketLoading": "true"},
      EffectiveDate: propsObj.value && propsObj.value.EffectiveDate ? propsObj.value.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    });

    let LoadingMetaData=(LoadingMetaDataRes && LoadingMetaDataRes.ApplicationDetail && LoadingMetaDataRes.ApplicationDetail.SubApplicationDetailList) || []

    const AvailableMarketList_Loader = AvailableMarketList.map((data)=> {
          return {
            ...data,
            SubApplicationDetailList: LoadingMetaData
          }
    })
    UpdateStateV2("loading", false, true)
    UpdateStateV2("AvailableMarketList_dataList", AvailableMarketList_Loader, true);


    let iteratorIndex =
      propsObj.componentProps.UIFlowReducer.Availablemarket_IteratorIndex;
    request["ProductNumber"] = ProductNumber;
    request["ProductVerNumber"] = ProductVerNumber;
    if (PropertySplitPercent == 100 && LiabilitySplitPercent == 100) {
      store.dispatch({
        type: actionType.SET_UI_VALUES,
        payload: { QuotaShare_popup: false },
      });
      let PromisedData = AvailableMarketList.map((data) => {
        let AvailableMarket = {
          AvailableMarket: data.AvailableMarket,
          ProductSelection: data.ProductSelection,
          insuredID: propsObj.componentProps.UIFlowReducer.insuredID,
          ...propsObj.componentProps.parentPropsValue.SublineList,
        };
        request = {
          ...request,
          Coverage: data.Coverage,
          AvailableMarket: AvailableMarket,
        };
        return AxiosInstance.post("core/invokeWorkflowEvent/V2", request);
      });
      let ratingCall = await Promise.allSettled(PromisedData);
      let checkRuleList;
      let promises = ratingCall.map(async (rating, i) => {
        if (rating.status == "fulfilled" && rating.hasOwnProperty("value")) {
          let response = rating.value.data;
          let Tlist = {};
          const { metadataReducer } = store.getState();
          if (
            "ProcessStatus" in response &&
            metadataReducer.ProcessStatus !== response.ProcessStatus
          ) {
            store.dispatch({
              type: appActions.actionType.SET_PROCESS_STATUS,
              payload: rating.value.ProcessStatus,
            });
            store.dispatch({
              type: appActions.actionType.SET_EXPANDPANEL_DATA,
              payload: true,
            });
          }
          if (response.data && response.data.SubmissionStatus)
            await store.dispatch({ type: Action.SET_SUBMISSION_STATUS, payload: response.data.SubmissionStatus });

          let summarySubApp = [];
          let subappName = [];
          let parentPropsValue = propsObj.componentProps.parentPropsValue;
          let subappNameList = parentPropsValue.iteratorSubappList;
          let selectedSubline = parentPropsValue.selectedSubline;
          let choosedsubline = parentPropsValue.SublineList.SublineName
            ? parentPropsValue.SublineList.SublineName
            : "";
          let neededSubapps = [];
          Object.keys(selectedSubline).length > 0 &&
            Object.entries(selectedSubline).forEach(([key, value]) => {
              if (key === choosedsubline) {
                neededSubapps.push(value);
              }
            });
          subappName = [...subappNameList, ...neededSubapps];
          subAppListConstructionV1(subappName, {}, [], [], summarySubApp);
          if (response && response.hasOwnProperty("ExternalQuoteNumber")) {
            store.dispatch({
              type: actionType.SET_EXTERNAL_QUOTE_NUMBER,
              payload: response.ExternalQuoteNumber,
            });
          }
          // const { ProductID: objectId, ProductVerID: objectVerId } =
          //   propsObj.componentProps.productDetails;
          let subappinfo = {
            subappName,
            objectId,
            objectVerId,
            state: "CW",
            subApplicationGroupList: [],
          };
          let metadataresponse = [];
          checkRuleList = false;
          let responseFromTranslation = {};
          responseFromTranslation = await EntitySubAppTranslationV2(
            { ...response },
            propsObj.componentProps.pageMetadata.ApplicationDetail
          );
          Tlist = {
            ...AvailableMarketList[i],
            AvailableMarket: {
              ...propsObj.componentProps.AvailableMarket,
              ...AvailableMarketList[i]["AvailableMarket"],
              IsReferralRuleApplied: "false",
            },
          };
          Tlist = { ...Tlist, ...responseFromTranslation };
          Tlist["ShowBusinessRules"] = "false";
          metadataresponse = await constructAndInvokeMetadataCall(subappinfo, {
            transactionPrimaryObject: Tlist,
            EffectiveDate:
              propsObj.value && propsObj.value.EffectiveDate
                ? propsObj.value.EffectiveDate
                : format(new Date(), "yyyy-MM-dd"),
          });
          AvailableMarketList[i] = {
            ...AvailableMarketList[i],
            ...responseFromTranslation,
            ...response,
          };
          AvailableMarketList[i]["AvailableMarket"]["TotalPremium"] =
            response.Coverage && response.Coverage.TotalPremium;
          AvailableMarketList[i][
            `${propsObj.componentProps.parentPropsValue.SublineList.SublineName}CoverageInformationDetails`
          ] = [];
          AvailableMarketList[i][
            `${propsObj.componentProps.parentPropsValue.SublineList.SublineName}CoverageInformationDetails`
          ] =
            response.Coverage && response.Coverage.CoverageRuleInformationList
              ? [...response.Coverage.CoverageRuleInformationList]
              : [];
          AvailableMarketList[i][`feescarrier_dataList`] = response.Fee;
          AvailableMarketList[i][`taxescarrier_dataList`] = response.Tax;
          AvailableMarketList[i]["SubApplicationDetailList"] =
            metadataresponse.ApplicationDetail.SubApplicationDetailList || [];
          AvailableMarketList[i]["SubmissionRatingDataID"] =
            response.SubmissionRatingDataID;
          AvailableMarketList[i]["isEligible"] =
            response.RuleStatus && response.RuleStatus.toLowerCase();
          AvailableMarketList[i]["viewbusinessrulespl_dataList"] =
            response.RuleInformationList || [];
          UpdateStateV1(
            "ruleinformationlistpl_dataList",
            response.RuleInformationList,
            false
          );
          propsObj.componentProps.parentPropsValue.pageInfo.dataListName.map(
            (datalist) => {
              if (datalist in response.Coverage)
                AvailableMarketList[i][
                  `${datalist.toLocaleLowerCase()}_dataList`
                ] = response.Coverage[datalist];
            }
          );
          UpdateStateV1("transactionPrimaryObject", Tlist, false);
          UpdateStateV2("PopUp_ScheduleOfForms", response.Coverage, false);
          UpdateStateV1("pagelevelValues", { ...Tlist }, false);
        }
      });
      await Promise.all(promises);
      let sortedList = [...AvailableMarketList];
      let issort = sortedList.every(
        (data) =>
          data["AvailableMarket"] &&
          data["AvailableMarket"].hasOwnProperty("TotalPremium")
      );
      if (issort) {
        let sortedList_ = sortedList.filter(
          (data) => data.AvailableMarket && data.AvailableMarket.TotalPremium
        );
        sortedList_.length > 1 &&
          sortedList.sort((a, b) => {
            return (
              Number(a.AvailableMarket.TotalPremium) -
              Number(b.AvailableMarket.TotalPremium)
            );
          });
        if (checkRuleList === undefined || checkRuleList === true) {
          UpdateStateV2("AvailableMarketList_dataList", sortedList, false);
        } else {
          UpdateStateV1("AvailableMarketList_dataList", sortedList, false);
        }
      } else {
        let sortedList = [...AvailableMarketList];
        if (checkRuleList === undefined || checkRuleList === true) {
          UpdateStateV2("AvailableMarketList_dataList", sortedList, false);
        } else {
          UpdateStateV1("AvailableMarketList_dataList", sortedList, false);
        }
      }
      UpdateStateV2("AvailableMarketList_dataList", sortedList, true);
      // store.dispatch({
      //   type: actionType.SET_UI_VALUES,
      //   payload: { QuotaShare_popup: false },
      // });
    }
  } catch (err) {
    console.error("Error in saveQuotaShare_package", err);
  }
};



export const handleOptionalForms= async (response,propsObj,stateObj,e)=>{
  try{
    if(response){
  const {  objectId , objectVerId } = propsObj.pageInfo;
  let subApplicationList = propsObj.pageInfo.subApplication;
  let entitySubAppObject = await getEntitySubAppMappingV2({subappName:["SOLScheduleOfFormsSummary"], objectId, objectVerId, state: 'CW', subApplicationGroupList: []}, "V2");
  let translatedData = await EntitySubAppTranslationV2(response, { "SubApplicationEntity" : entitySubAppObject });
  for (var i = 0; i < subApplicationList.length; i++) {
    if(translatedData[`${subApplicationList[i]}`])
    {
      propsObj.functions.UpdateStateContent(`${subApplicationList[i].toLowerCase()}_dataList`,translatedData[`${subApplicationList[i]}`] ,true);
      propsObj.functions.UpdateState(`${subApplicationList[i].toLowerCase()}_dataList`,translatedData[`${subApplicationList[i]}`],true);
    }
    else
    if(response[`${subApplicationList[i]}`])
    {
      propsObj.functions.UpdateStateContent(`${subApplicationList[i].toLowerCase()}_dataList`,response[`${subApplicationList[i]}`] ,true);
      propsObj.functions.UpdateState(`${subApplicationList[i].toLowerCase()}_dataList`,response[`${subApplicationList[i]}`] ,true);
    }
    }
  }
  }catch(e){
    console.log('Error in handleOptionalForms',e)
  }
}

const executeArrayIteration = async(req,propsObj,e)=>{
  let OptionalForms= [], ScheduleOfForms=[]
  req.SubmissionRatingDataID = propsObj.componentProps.AvailableMarketList_dataList[propsObj.componentProps.Availablemarket_IteratorIndex].SubmissionRatingDataID
  await req.OptionalForms && req.OptionalForms.map((form,ind)=>{
    if(form.SelectedForm==="true"  || form.SelectedForm===true){
      return OptionalForms=[...OptionalForms,form]
    }
  })
  await req.ScheduleOfForms && req.ScheduleOfForms.map((form,ind)=>{
    if(form.SelectedForm==="true"  || form.SelectedForm===true){
      return ScheduleOfForms=[...ScheduleOfForms,form]
    }
  })
  return {...req,OptionalForms, ScheduleOfForms}
}

export const requiredFormSelection=async(req,propsObj,e)=>{
  let check = {}
  if(req.Transaction.toLowerCase()==="add" && req.OptionalForms){
    let addFormAvailable = await checkIfKeyIsTrue(req.OptionalForms,"SelectedForm")
    if(!addFormAvailable){
      triggerToast("",propsObj.component.Custom1,propsObj.UpdateState)
      check = {}
      return;
    }else{
      let check = await executeArrayIteration(req,propsObj,e)
      return check;
    }
  }
  else if(req.Transaction.toLowerCase()==="remove" && req.ScheduleOfForms){
    let removeFormAvailable = await checkIfKeyIsTrue(req.ScheduleOfForms,"SelectedForm")
    if(!removeFormAvailable){
      triggerToast("",propsObj.component.Custom1,propsObj.UpdateState)
      check = {}
      return;
    } else if(removeFormAvailable){
      check = await executeArrayIteration(req,propsObj,e)
      return check
    }
  }
    return check
}

const checkIfKeyIsTrue = async(inputArray,key)=>{
  let isKeyPresent = false;
  await inputArray.map(element=>{
    if(element[key]==="true" || element[key]===true){
      isKeyPresent = true;
    }
  })
  return isKeyPresent;
}

const CommonUtils={
  handleInputChangeV2: handleInputChangeV2_Quote,
  updateValueWithSubplicationName : updateValueWithSubplicationName_PL,
  parentStateUpdateNeeded: false,
  getDocument: getDocument_forms,
}

const componentDidMount_BasePage = async (state, props, functions) => {
  let routeName = props.location.pathname.slice(1)
  let { navigationReducer: {navigation}, UIFlowReducer:{pageName}, pageInfo: { defaultPageName = "" } } = props;
  let obj = {}
  try{
    let sidebar = navigation.pageNavigation.hasOwnProperty(routeName) ? navigation.pageNavigation[routeName].sidebar : []
    let sidebarChildIndex = sidebar.findIndex(i => i.url === `/${routeName}`);
    if(sidebarChildIndex === -1) throw new Error("Navigation not available");
    let nav = sidebar[sidebarChildIndex].children || [];
    let currentPageName = pageName && pageName != "" ? pageName : defaultPageName != "" ? defaultPageName : nav[0].pageName ? nav[0].pageName : "";
    if(currentPageName === "") throw new Error("Page Name is empty");
    else {
      let pageIndex = nav.findIndex(i => i.pageName === currentPageName)
      obj['currentPageInfo'] = nav[pageIndex];
    }
    return obj
  }
  catch(err){
    console.log("Error in Base Page Mount :: ", err)
  }
}

const componentDidUpdate_BasePage = async (state, props, prevState, prevProps, functions) => {
  if(prevProps.location.pathname!==props.location.pathname || prevProps.UIFlowReducer.pageName !== props.UIFlowReducer.pageName){
    try{
      let obj = {};
      let { navigationReducer: { navigation}, UIFlowReducer:{pageName}} = props;
      let routeName = props.location.pathname.slice(1).split('/')[0];
      let sidebar = navigation.pageNavigation.hasOwnProperty(routeName) ? navigation.pageNavigation[routeName].sidebar : []
      let sidebarChildIndex = sidebar.findIndex(i => i.url === `/${routeName}`);
      if(sidebarChildIndex === -1) throw new Error("Navigation not available");
      let nav = sidebar[sidebarChildIndex].children || [];
      if(pageName === "") throw new Error("Page Name is empty");
      else {
        let pageIndex = nav.findIndex(i => i.pageName === pageName)
        obj['currentPageInfo'] = nav[pageIndex];
        store.dispatch(setCurrentPageIndex(nav[pageIndex]["index"]))
    }
      return obj
    }
    catch(err){
      console.log("Error in Base Page Update :: ", err);
    }
  }
}

const componentDidMount_IntegrationConsole = async (state, props, UpdateState) => {
  try{
    UpdateState("loading",true,true);
    let tList = Object.assign({}, state.transactionPrimaryObject);
    let { objectId, objectVerId } = props.pageInfo;
    let subappinfo = {subappName: props.pageInfo['subApplication'], objectId, objectVerId, state: 'CW', subApplicationGroupList: []}
    let response = await constructAndInvokeMetadataCall(subappinfo, {transactionPrimaryObject : tList});
    return { loading: false, pageMetadata: response, subappNameList: props.pageInfo["subApplication"], transactionPrimaryObject : tList,pageSubAppInfo:subappinfo }
  }
  catch(error){
    console.log('error in Mount Tab Children :: ', error)
  }
  finally{
  }
}

const componentDidUpdate_IntegrationConsole = async (state, props, prevState, prevProps, UpdateState) => {
  // console.log("props",prevProps,props);
  // console.log("State",state,prevState);
  // // if (Object.keys(state.pageMetadata).length !== Object.keys(prevState.pageMetadata).length)
  // if (props.UIFlowReducer.currentPageIndex!==prevProps.UIFlowReducer.currentPageIndex)
  // // if(state.pageMetadata!==prevState.pageMetadata)
  // return componentDidMount_IntegrationConsole(state, props, UpdateState)

  // return {};
}

const getIntegrationConsoleTableData = async (offsetInfo = {}, props) => {
  try {
    props.functions && props.functions.UpdateState("loading", true, true);
    let { eventVersion } = props.pageInfo;
    let {ApplicationName} = props.subAppInfo.SubApplicationDetail
    let eventURL = `core/invokeWorkflowEvent`
    if(eventVersion) eventURL = `${eventURL}/${eventVersion}`
    let getAPIRequest = {}, getAPIResponse = { "loading": false };
    getAPIRequest['ServiceRequestDetail'] = Object.assign({}, getServiceRequestDetail());
    getAPIRequest['OwnerId'] = sessionStorage.getItem('OwnerID') || "";
    getAPIRequest = { ...getAPIRequest, ...props.pageInfo.eventNames, ...offsetInfo }
    if (props && props.componentState && props.componentState.pagelevelValues)
      Object.keys(props.componentState.pagelevelValues).map( attr => getAPIRequest = { ...getAPIRequest, ...props.componentState.pagelevelValues[attr] })
     let response = await  AxiosInstance.post(eventURL,getAPIRequest)
     getAPIResponse[`${ApplicationName.toLowerCase()}_dataList`] = response.data[ApplicationName];
     getAPIResponse[`${ApplicationName.toLowerCase()}_dataListSize`] = response.data.Count;
    if(props.functions && props.functions.updateValues){
      props.functions.updateValues(getAPIResponse)
    }
  }
  catch(error) {
    console.log("Exception in getting IntegrationConsole table data :: ", error)
    triggerToast("Error  in getting IntegrationConsole table data")
  }
}
const viewData = async(dataListName, rowData, SubApplnName, props) => {
  try{
  let jsonValue;
  if(rowData.ContentType === 'application/json'){
      jsonValue = JSON.stringify(JSON.parse(props.componentvalue), null, 2);
  }
  else{
    jsonValue = props.componentvalue;
  }
  let Content = new Blob([jsonValue], {type: rowData.ContentType  });
    //Chrome & Firefox version
  if (navigator.userAgent.indexOf('Chrome') != -1 || navigator.userAgent.indexOf('Firefox') != -1) {
    const fileURL = URL.createObjectURL(Content);
    const w = window.open(fileURL, '_blank');
    w && w.focus();
  } else {
    // Safari & Opera iOS
    const fileURL = URL.createObjectURL(Content);
    window.location.href = fileURL;
  }
  }
  catch(error){
    console.log("Exception Json Parsing :: ", error)
    triggerToast("Invalid Content-Type")
  }
};
const updateValueWithSubplicationName_Acc = (updatedValue, component, prevState) => {
  let SubapplicationName = component.SubApplicationName;
  let pagelevelValues = Object.assign({}, prevState.pagelevelValues);
  if (!pagelevelValues[SubapplicationName]) { pagelevelValues[SubapplicationName] = {}; }
  pagelevelValues[SubapplicationName] = Object.assign({}, prevState.pagelevelValues[SubapplicationName], updatedValue);
  return { ...pagelevelValues }
  }

const handleOnReset = async(EventName,propsObj,stateObj,component,functions,e) =>{
  let subApplicationName = component.SubApplicationName
  let pageLevelValues = stateObj.pagelevelValues
  let resetData= {}
  pageLevelValues[subApplicationName] = {}
  resetData[pageLevelValues] = {...pageLevelValues}
  resetData[`${subApplicationName.toLowerCase()}summary_dataList`] = []
  resetData[`${subApplicationName.toLowerCase()}summary_dataListSize`] = undefined
  if(stateObj.pagelevelValues.hasOwnProperty(subApplicationName)){
    functions.updateValues(resetData)
  }
}

const invokeGetProductsList = async (attributeValue, component, props, functions) => {
  props.functions.UpdateState('loading',true,true)
  store.dispatch({ type: actionType.SHOW_LOADER })
  const reduxState = store.getState().UIFlowReducer;
  let reduxVal = reduxState.selectedProducts || [];
  let productsList = [], selectedObj = undefined;
  let getProductListReq = {}, getProductListRes = {}
  // let {Action = {}}=component, request={};

  // request={...request,ServiceRequestDetail:getServiceRequestDetail()}

  getProductListReq["ServiceRequestDetail"] = Object.assign({}, getServiceRequestDetail());
  getProductListReq = Object.assign({}, getProductListReq, {
    OwnerId: sessionStorage.getItem("OwnerID"),
    EventName: "SolartisCommonMaster_WF",
    TargetEventName : "GetProductListByLOB",
    EventAction : "GetProductListByLOB"
  });
  getProductListReq['LOBName'] = component.AttributeName;

  getProductListRes = await commonProductAxiosInstance.post("/core/invokeWorkflowEvent/V4", getProductListReq);

  productsList = getProductListRes.data.SelectedLineOfBusiness || []

  //Defaulting...
  // if(productsList.length === 1)
  //   selectedObj = productsList;

  // if(selectedObj) {
  //   let index = selectedObj.length > 0 ? reduxVal.findIndex(e => e.LineOfBusiness === selectedObj[0].LineOfBusiness) : -1
  //   if(index > -1){
  //       reduxVal.splice(index, 1);
  //   }
  //   reduxVal.push(selectedObj[0]);
  // }
  // store.dispatch({ type: actionType.SET_SELECTED_PRODUCTS, payload:component.DefaultValue  })

  if(component.DefaultValue){
    selectedObj = productsList.find(item => item.Value === component.DefaultValue)
    store.dispatch({type: actionTypes.SET_PRODUCT_DETAILS, payload: {...selectedObj}})
    store.dispatch({type: actionTypes.SET_LOB, payload: selectedObj.LineOfBusiness})
  }


  store.dispatch({type: actionTypes.SET_PRODUCT_DETAILS, payload: {...productsList[0]}})
  store.dispatch({type: actionTypes.SET_LOB, payload: productsList[0].LineOfBusiness})

  functions.setOptions(productsList);
  props.functions.UpdateState('loading',false,true)
}
//temporary method for shrinkwrap and transafe
const onQuoteNumberClickV2 = async (propsObj,stateObj,component,e,props)=>{
  let {customTableRowIndex,componentProps,UpdateState} = props
  UpdateState("loading",true,true)
  const quoteID = customTableRowIndex.ID
  await store.dispatch({
    type: actionType.SET_QUOTE_ID,
    payload: quoteID
  });
  store.dispatch({type: actionType.SET_PAGENAME, payload:"Quote Details"});
  let route = store.getState().metadataReducer.route ? store.getState().metadataReducer.route : ''
if(route==='Quote'){
    let {objectId,objectVerId,subApplication} = props.pageInfo;
    let newProps = {
      objectId : objectId,
      objectVerId : objectVerId,
      subApplication : subApplication,
      subappName: subApplication
    }
    let transactionPrimaryObject = {}
    let pagelevelValues = {}
    const quoteDetailResponse = await quoteDetailInformation({...stateObj,transactionPrimaryObject,pagelevelValues}, componentProps, null, null, UpdateState,newProps)
    const keyValuePairs = Object.entries(quoteDetailResponse)
      await keyValuePairs.forEach((value,i)=>{
        let updateFlag = (keyValuePairs.length)-1===i
         value[1] && UpdateState(value[0],value[1],updateFlag)
      })
   } else {
    propsObj.history.push("/quote")
   }
  UpdateState("loading",false,true)
}
//temporary method for shrinkwrap and transafe
const onPolicyNumberClickV2 = async (propsObj,stateObj,component,e,props)=>{
  let {customTableRowIndex,componentProps,UpdateState} = props
  UpdateState("loading",true,true)
  const policyID = customTableRowIndex.PolicyID
  await store.dispatch({
    type: actionType.SET_POLICY_ID,
    payload: policyID
  });
  store.dispatch({type: actionType.SET_PAGENAME, payload:"Policy Details"});
  let route = store.getState().metadataReducer.route ? store.getState().metadataReducer.route : ''
  if(route==='Policy'){
    let {objectId,objectVerId,subApplication} = props.pageInfo;
    let newProps = {
      objectId : objectId,
      objectVerId : objectVerId,
      subApplication : subApplication,
      subappName: subApplication
    }
    let transactionPrimaryObject = {}
    let pagelevelValues = {}
    const policyDetailResponse = await policyDetailInformation({...stateObj,transactionPrimaryObject,pagelevelValues}, componentProps, null, null, UpdateState,newProps)
    const keyValuePairs = Object.entries(policyDetailResponse)
      await keyValuePairs.forEach((value,i)=>{
        let updateFlag = (keyValuePairs.length)-1===i
         value[1] && UpdateState(value[0],value[1],updateFlag)
      })
  }else {
    propsObj.history.push("/policy")
   }
  UpdateState("loading",false,true)
}
//temporary method for shrinkwrap and transafe
const lapseOrDeclineConfirmV2 = async(eventName,propsObj,stateObj,component,functions,e,props)=>{
  let {UpdateState} = props.componentProps.parentState
  UpdateState("loading",true,true);
  let request = {};
  request = Object.assign(request,props.pagelevelValues)
  request["ServiceRequestDetail"] = getServiceRequestDetail();
  request.OwnerId = sessionStorage.getItem("OwnerID");
  request.EventName =  GetCommonEventName();
  request.TargetEventName = eventName
  request.lapseDeclineStatus = propsObj.lapseDeclineStatus
  request.quoteNumber = store.getState().UIFlowReducer.quoteNumber
  request.quoteFullNumber = store.getState().UIFlowReducer.quoteFullNumber
  request.ID = store.getState().UIFlowReducer.quoteID;
  let response = await createQuoteAxios.post("/core/invokeWorkflowEvent/V2", request)
  let QuoteStatus = response.data.QuoteStatus
  await store.dispatch({type: actionType.SET_QUOTE_STATUS,payload:{QuoteStatus}});
  const navigationReducer = store.getState().navigationReducer
  const metadataReducer = store.getState().metadataReducer
  const UIFlowReducer = store.getState().UIFlowReducer
  if(UIFlowReducer.pageName!=="Quote Details"){
    store.dispatch({type: actionType.SET_PAGENAME, payload:"Quote Details"});
    store.dispatch({type: actionType.SET_UI_VALUES,payload: { [`${props.pageInfo.name}_popup`]: false },});
  }
  else{
  props = {...props,UIFlowReducer,navigationReducer,metadataReducer}
  let newProps ={
    subApplication : props.componentProps.parentPropsValue.Template[0].subApplication,
    objectId : props.componentProps.parentPropsValue.Template[0].objectId,
    objectVerId : props.componentProps.parentPropsValue.Template[0].objectVerId
  }
  let detailResponse={};
  let dynamicProps={"pageInfo":props.componentProps.parentPropsValue.Template[0]}
  detailResponse = await quoteDetailInformation(props.componentProps, dynamicProps, null, null, UpdateState,newProps)
  const keyValuePairs = Object.entries(detailResponse)
  await keyValuePairs.forEach((value,i)=>{
    let updateFlag = (keyValuePairs.length)-1===i
    value[1] && UpdateState(value[0],value[1],updateFlag)
  })
  UpdateState("loading",false,true);
  store.dispatch({type: actionType.SET_UI_VALUES,payload: { [`${props.pageInfo.name}_popup`]: false },});
 }
}
const handleAllInDairyNotes =async (response,props,componentState)=>{
  if(props.rowData.ObjectType.toLowerCase()==="quote"){
    let ApplicationState={Flow:null}
    let SessionState={currentPageIndex:1,SubmissionNumber:props.rowData.TransactionNumber,quoteID:props.rowData.ID,pageName:"Quote Details"}
    props.history.push(`/quote`);
    await store.dispatch({ type: actionType.SET_UI_VALUES, payload: SessionState })
    await store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: ApplicationState })
  }else if(props.rowData.ObjectType.toLowerCase()==="policy"){
    let ApplicationState={Flow:null}
    let SessionState={currentPageIndex:1,SubmissionNumber:props.rowData.TransactionNumber,policyID:props.rowData.ID,pageName:"Policy Details"}
    props.history.push(`/policy`);
    await store.dispatch({ type: actionType.SET_UI_VALUES, payload: SessionState })
    await store.dispatch({ type: actionType.SET_APPLICATION_VALUES, payload: ApplicationState })
     }
}

const RedirectToLogin = async (response, props, state) => {
  try {
      if (!response.hasOwnProperty('RuleInformationList')) {
          let request = {};
          request.UserName = localStorage.getItem("userName");
          try {
              let res = await authAxios.post("/auth/logout", request);
              res = await res.data;
              if (res.Status.toLowerCase() === "success") {
                  localStorage.clear();
                  props.componentProps.history.push("/login");
              }
          } catch (apiError) {
              console.error("Error in Logout:", apiError);
              triggerToast("Logout Failed")
          }
      }
  } catch (error) {
      console.error("Error in RedirectToLogin:", error);
      triggerToast("Unable to Redirect Login")
  }
};


const redirectISO = () => {
  const w = window.open("https://ucisolartisuiux.solartis.net/api/auth/okta/ssologin", '_blank');
  w && w.focus();
}

export const HOCUtil = {
  Manage: {
    parentStateUpdateNeeded: false,
    componentDidMount  : componentDidMount_Manage,
    componentDidUpdate : componentDidMount_Manage,
    handleInputChangeV2: handleInputChangeV2_Manage,
    canopiusintegration: {
       InvokeIntegrationConsole,
       ResetConsole
    },
    canopiusintegrationsummary: {
            // showData,
      "tableUtils" : {
        "AddRow"    : "",
        "EditRow"   : "",
        "OnDetailEdit" : "",
        "getLazyLoadData" : handleIntegrationConsole,
    },
    "TableOptions": {
      "lazyLoading" : true,
      "ChildrenSubApplicationName": null,
      "AddButtonNeeded" : false,
      "SortingNeeded" : false,
      "FilteringNeeded" : false,
      "FilterIconNeeded" : false,
      "rowsPerPageOptions" : [5, 10, 25],
      "RowsPerPage" : 5,
      "ActionName"  : "Action",
      "ActionListOptions"  : []
  }
    }
  },
  Policy:{
    componentDidMount  : componentDidMount_Policy,
    componentDidUpdate: componentDidUpdate_BasePage
  },
  QuoteV2:{
    componentDidMount  : componentDidMount_QuoteV2,
    componentDidUpdate : componentDidUpdate_QuoteV2,
  },
  issuePolicy:{
    issuepolicybuttons:{
      issuePolicy:issuePolicy_Orchid_Homeowners_V1
    },
  },
  PolicyDetails:{
   ...CommonUtils,
   componentDidMount: componentDidMount_PolicyDetails,
   componentWillUnmount: componentWillUnmount_QuotePolicy,
   policyissuance: {
    previewIssuance:previewIssuance_Orchid_Homeowners
  },
  reinstatedetails:{
    createReinstate,
    reviewReinstate,
    reviewReinstate_V1
  },
        //Temporary fix RowsPerPage
  summarytaxdetailssp:{
    "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions,"RowsPerPage":25},
  },
  summaryfeedetailssp:{
    "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions,"RowsPerPage":25},
  },
  cancellationdetails: {
    CreateCancel_PL : CreateCancel_PL_Homeowners
  },
  policydetail:{
    issuePolicy:issuePolicy_Orchid_Homeowners,
    returnToQuoteDetails
  },
  viewbusinessrules:{
    "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions}
  },
  ceaseinformationlistinpolicy:{
    "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions},
  },
  endorsementlist:{
    "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions},
    onQuoteNumberClick
  },
  cancelreinstatelist:{
    "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions},
    onQuoteNumberClick
  },
  navendorsementsp:{
    addEndorsement:addEndorsement_HomeOwners,
    createEndorsement:createEndorsement_HomeOwners
  },
  policylist: {
    "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions},
    onQuoteNumberClick,
    onPolicyNumberClick,
    onQuoteNumberClickV2,
    onPolicyNumberClickV2
  },
  forms:{
    "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions,RowsPerPage:5},
  },
  documents: {
    "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions},
    getDocument:getDocument_quote,
  },
  formsmpl:{
    "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions,RowsPerPage:5},
  },
  documentsmpl: {
    "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions},
    getDocument:getDocument_quote,
  },
  notes: {
    "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,}},
    "TableOptions":notesTableOptionsPolicy,
    "tableFunctions": {"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,"deleteRow": onDeleteNotesandAttachmentsPolicy},
    setEditPrivilege
  },
  ruleinformationlist:{
    "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange,"onExpand": onExpand}},
    "TableOptions":{...quoteListTableOptions},
    onSaveBusinessRule
  },
  "email":{
    "dialogOptions": {
      "titleNeeded": false,
      "dialogTitle": "",
      "maxWidth": "md"
    },
    "dialogUtils": {
      "handleClose": ClosePopUp_HomeOwners
    },
    "SendEmail":SendEmail_HomeOwners,
    uploadEmail:uploadEmail_HomeOwners,
    ClosePopUp,
    multipleUploads:multipleUploads_HomeOwners
  },
  "emailsummary":{
    "tableUtils": {...quoteListTableUtils,"AddRow": openPopUp_HomeOwners},
    "TableOptions":{...quoteListTableOptions,"AddButtonNeeded": true},
    "tableFunctions": {"AddRow":openPopUp_HomeOwners},
     redirectTo: getEmailDetail_HomeOwners
  },
  policyattachments: {
    "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveAttachments,"AddRow":addRow,"onExpand": onExpand,}},
    "TableOptions":{...notesTableOptionsPolicy},
    "tableFunctions":{"onChildSave": onSaveAttachments,"AddRow":addRow,"onExpand": onExpand, "deleteRow": onDeleteNotesandAttachmentsPolicy},
    getDocument: getDocument_Attachment,
    setEditPrivilege
  }
  },
  InspectionDetails:{
    ...CommonUtils,
    componentDidMount: componentDidMount_InspectionDetailsV2,
    inspections:{
      onInspectionReorder,
      onCancelInspections,
      onClearInspectionCancelConfirmation
    },
    inspectionssave:{
      onSaveInspections
    },
    inspectionattachments:{
      "tableUtils": {...quoteListTableUtils,...{"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":{...quoteListTableOptions,...{"ChildrenComponent":"childcomp",
        "AddButtonNeeded": true,"ActionName":"",
        "ActionListOptions": [{ActionDisplayName: "Delete",OnOptionClick: onDeleteNotesandAttachmentsPolicy}]}
      },
    }
  },
  EndorsementWrapperDetails:{
    ...CommonUtils,
    componentDidMount: componentDidMount_Endorsement_detailsV2,
    endorsementlist:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      onQuoteNumberClick
    },

    navendorsementsp:{
      addEndorsement:addEndorsement_HomeOwners,
      createEndorsement:createEndorsement_HomeOwners
    },
    policylist: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      onQuoteNumberClick,
      onPolicyNumberClick
    },
          //Temporary fix RowsPerPage
    summarytaxdetailssp:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage":25},
    },
    summaryfeedetailssp:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage":25},
    },
    policydetail:{
      issuePolicy:issuePolicy_Orchid_Homeowners,
      returnToQuoteDetails
    }
  },
  CancelReinstate:{
    ...CommonUtils,
    handleInputChangeV2: handleInputChangeV2_Orchid,
    getDocument: getDocument_forms,
    parentStateUpdateNeeded: false,
    componentDidMount: componentDidMount_CancelReinstate_DetailsV2,
    policylist: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      onQuoteNumberClick,
      onPolicyNumberClick
    },
      //Temporary fix RowsPerPage
    summarytaxdetailssp:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage":25},
    },
    summaryfeedetailssp:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage":25},
    },
    policydetail:{
      issuePolicy:issuePolicy_Orchid_Homeowners,
      returnToQuoteDetails
    },
    reinstatedetails:{
      createReinstate,
      reviewReinstate,
      reviewReinstate_V1
    },
    cancellationdetails: {
        CreateCancel_PL : CreateCancel_PL_Homeowners
    },
    viewrulemessages:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions}
    },
    cancelreinstatelist:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      getDocument:getDocument_quote,
      onQuoteNumberClick
    }
  },
  QuoteDetails_CL:{
    quotedetail:{
      navigateToAvailableMarket : navigateToAvailableMarket_CL,
    },
    quotelist: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      onQuoteNumberClick,
      onPolicyNumberClick
    },
    componentDidMount:getQuoteDetail_CL,
    handleInputChangeV2: handleInputChangeV2_Quote,
    updateValueWithSubplicationName : updateValueWithSubplicationName_PL,
    getDocument: getDocument_forms,
    parentStateUpdateNeeded: false,
    forms:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,RowsPerPage:5},
    },
      //Temporary fix RowsPerPage
    summaryfeedetailssp:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage":25}
    },
    summarytaxdetailssp:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage":25}
    },
    documents: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      getDocument:getDocument_quote,
    },
    notes: {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":notesTableOptions,
      "tableFunctions": {"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,"deleteRow": onDeleteNotesandAttachmentsQuote},
      setEditPrivilege
    },
    ruleinformationlist:{
      "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange,"onExpand": onExpand}},
      "TableOptions":{...quoteListTableOptions},
    },
    "email":{
      "dialogOptions": {
        "titleNeeded": false,
        "dialogTitle": "",
        "maxWidth": "md"
      },
      "dialogUtils": {
        "handleClose": ClosePopUp_HomeOwners
      },
      "SendEmail":SendEmail_HomeOwners,
      uploadEmail:uploadEmail_HomeOwners,
      ClosePopUp,
      multipleUploads:multipleUploads_HomeOwners,
      "tableFunctions": {"AddRow":openPopUp_HomeOwners_CL}
    },
    "emailsummary":{
      "tableUtils": {...quoteListTableUtils,"AddRow": openPopUp_HomeOwners_CL},
      "TableOptions":{...quoteListTableOptions,"AddButtonNeeded": true},
      "tableFunctions": {"AddRow":openPopUp_HomeOwners_CL},
      redirectTo: getEmailDetail_HomeOwners_CL
    },
    policyattachments: {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveAttachments,"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":{...notesTableOptions},
      "tableFunctions": {"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,"deleteRow": onDeleteNotesandAttachmentsQuote},
      getDocument: getDocument_Attachment,
      setEditPrivilege
    }
  },
  Insured_CL:{
    componentDidMount:getInsuredDetail_CL,
    parentStateUpdateNeeded: false,
      },
  Quote:{
    componentDidMount  : componentDidMount_Quote,
    componentDidUpdate: componentDidUpdate_BasePage,
    handleInputChangeV2: handleInputChangeV2_Quote,
    updateValueWithSubplicationName : updateValueWithSubplicationName_PL,
    getDocument: getDocument_forms,
    parentStateUpdateNeeded: false,
    "e-signaturelinks":{
      insuredESigner,
      agentESigner
    },
    underwriterworksheet_merlindetails:{
       InvokePDFGeneration
       },
    underwriterworksheet_legacydetails:{
       InvokePDFGeneration
       },
    underwriterworksheet_canopius:{
       InvokePDFGeneration
      },
    ceaseinformationlistinpolicy:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
    },
      //Temporary fix RowsPerPage
    summarytaxdetailssp:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage":25},
    },
    summaryfeedetailssp:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage":25},
    },
    diligenteffortinformation:{
      saveDiligentEffort
    },
    declinequote:{
      lapseDeclineClose,
      lapseOrDeclineConfirm,
      lapseOrDeclineConfirmV2
      },
      lapsequote:{
        lapseDeclineClose,
        lapseOrDeclineConfirm,
        lapseOrDeclineConfirmV2
        },
    quotedetail:{
      TriggerOfferQuote:TriggerOfferQuote,
      lapseDecline_QuoteOrchid,
      navigateToAvailableMarket : navigateToAvailableMarket,
      navigateToAvailableMarket_Button: navigateToAvailableMarket_Button,
      bindQuote_Orchid: QuoteBinder_Orchid_HomeOwners,
      issueBinder_Orchid:issueBinder_Orchid_HomeOwners,
      returnToPolicyDetails,
      referQuote : referQuote_Homeowners
    },
    issuebindercontactdetails:{
      quoteNavigator:quoteDetailsNavigator_HomeOwners,
      previewBinder:previewBinder_HomeOwners,
      previewIssueBinder,
      bindQuote : bindQuote_HomeOwners
    },
    issuebinderbuttons:{
    previewBinder:previewBinder_HomeOwners
    },
      forms:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,RowsPerPage:5},
    },
    formsmpl:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,RowsPerPage:5},
    },
    quotelist: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      onQuoteNumberClick,
      onPolicyNumberClick,
      onQuoteNumberClickV2,
      onPolicyNumberClickV2
    },
    viewbusinessrules:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions}
    },
    ceaseinformationlist:{
      "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange}},
      "TableOptions":{...quoteListTableOptions},
      saveTransactionCeaseReasons
    },
    documents: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      getDocument:getDocument_quote,
    },
    documentsmpl: {
        "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      getDocument:getDocument_quote,
    },
    notes: {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":notesTableOptions,
      "tableFunctions": {"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,"deleteRow": onDeleteNotesandAttachmentsQuote},
      setEditPrivilege
    },
    scheduleofpersonalproperty:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,ChildrenComponent:"childcomp","ActionName":""},
    },
    ruleinformationlist:{
      "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange,"onExpand": onExpand}},
      "TableOptions":{...quoteListTableOptions},
      onChangeRuleStatus
    },
    savebusinessrules:{
      onSaveBusinessRule
    },
    worksheetnotesdetails:{
      saveWorkSheet:saveWorkSheet_HomeOwners,
      getWorkSheetRate:getWorkSheetRate_HomeOwners
    },
    "email":{
      "dialogOptions": {
        "titleNeeded": false,
        "dialogTitle": "",
        "maxWidth": "md"
      },
      "dialogUtils": {
        "handleClose": ClosePopUp_HomeOwners
      },
      "SendEmail":SendEmail_HomeOwners,
      uploadEmail:uploadEmail_HomeOwners,
      ClosePopUp,
      multipleUploads:multipleUploads_HomeOwners,
      "tableFunctions": {"AddRow":openPopUp_HomeOwners}
    },
    "emailsummary":{
      "tableUtils": {...quoteListTableUtils,"AddRow": openPopUp_HomeOwners},
      "TableOptions":{...quoteListTableOptions,"AddButtonNeeded": true},
      "tableFunctions": {"AddRow":openPopUp_HomeOwners},
      redirectTo: getEmailDetail_HomeOwners
   },
    summarytaxdetails:{
      "tableUtils": {...quoteListTableUtils,"EditRow": handleRowLevelInputChange_V2},
      "TableOptions":{...quoteListTableOptions},OnChangeAction: TaxAndFeeOverride
    },
    summaryfeedetails:{
      "tableUtils": {...quoteListTableUtils,"EditRow": handleRowLevelInputChange_V2},
      "TableOptions":{...quoteListTableOptions},OnChangeAction: TaxAndFeeOverride
    },
    claimshistory:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"ChildrenComponent":"childcomp","ActionName":""}
    },
    additionalinformation:{
      "tableUtils": {...quoteListTableUtils,...{"onChildSave":SaveAdditionalInsured_Quote_Homeowners,"AddRow":addRow,"onExpand": onExpand}},
      "TableOptions":{...quoteListTableOptions,...{"ChildrenComponent":"childcomp",
      "AddButtonNeeded": false,"ActionName":"",
      "ActionListOptions": [{ActionDisplayName: "Delete",OnOptionClick: deleteAdditionalInsured_Quote_Homeowners}]
    }},
    "tableFunctions": {"onChildSave":SaveAdditionalInsured_Quote_Homeowners,"AddRow":addRow,"onExpand": onExpand,"deleteRow": deleteAdditionalInsured_Quote_Homeowners}
  },
  subjectivity: {
    "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveSubjectivity,"AddRow":addRow,"onExpand": onExpand,}},
    "TableOptions":notesTableOptions,
  },
    policyattachments: {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveAttachments,"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":{...notesTableOptions},
      "tableFunctions": {"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,"deleteRow": onDeleteNotesandAttachmentsQuote},
      getDocument: getDocument_Attachment,
      setEditPrivilege
    },
    underwriterworksheetsave :{
      underwriterworksheet_save: underwriterworksheetQuote_save,
      getunderwriterworksheet_calculatepremium: getunderwriterworksheet_calculatepremium_quote,
      printUWWorkSheet: InvokePDFGeneration
    },
    iaplsectionpremiumdetails :getunderwriterworksheet_rating_obj,
    generalmodifiers :getunderwriterworksheet_rating_obj,
    iamlsectionpremiumdetails :getunderwriterworksheet_rating_obj,
    iflsectionpremium :getunderwriterworksheet_rating_obj,
    eplsectionpremiumdetails:getunderwriterworksheet_rating_obj,
    flsectionpremiumdetails:getunderwriterworksheet_rating_obj,
    underwriterworksheet:{
      DisplayCoverages
    }
    },
  QuoteDetails:{
    ...CommonUtils,
    handleInputChangeV2: handleInputChangeV2_Quote,
    componentDidMount: componentDidMount_QuoteDetails,
    componentWillUnmount: componentWillUnmount_QuotePolicy,
    "e-signaturelinks":{
      insuredESigner,
      agentESigner
    },
    ceaseinformationlistinpolicy:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
    },
          //Temporary fix RowsPerPage
    summarytaxdetailssp:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage":25}
    },
    summaryfeedetailssp:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage":25}
    },
        diligenteffortinformation:{
      saveDiligentEffort: saveDiligentEffortV2
    },
    declinequote:{
      lapseDeclineClose,
      lapseOrDeclineConfirm
      },
      lapsequote:{
        lapseDeclineClose,
        lapseOrDeclineConfirm
        },
    quotedetail:{
      TriggerOfferQuote:TriggerOfferQuote,
      offerQuoteResponseConstruction,
      lapseDecline_Quote:lapseDecline_QuoteOrchid,
      navigateToAvailableMarket : navigateToAvailableMarket,
      navigateToAvailableMarket_Button: navigateToAvailableMarket_Button,
      bindQuote_Orchid: QuoteBinder_Orchid_HomeOwners,
      issueBinder_Orchid:issueBinder_Orchid_HomeOwners,
      returnToPolicyDetails,
      referQuote : referQuote_Homeowners
    },
    issuebindercontactdetails:{
      quoteNavigator:quoteDetailsNavigator_HomeOwners,
      previewBinder:previewBinder_HomeOwners,
      previewIssueBinder,
      bindQuote : bindQuote_HomeOwners
    },
    issuebinderbindingquestions:{
      quoteNavigator:quoteDetailsNavigator_HomeOwners,
      previewBinder:previewBinder_HomeOwners,
      bindQuote : bindQuote_HomeOwners
    },
    forms:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,RowsPerPage:5},
    },
    formsmpl:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,RowsPerPage:5},
    },
    quotelist: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      onQuoteNumberClick,
      onPolicyNumberClick,
      onPolicyNumberClickV2,
      onQuoteNumberClickV2
    },
    viewbusinessrules:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions}
    },
    ceaseinformationlist:{
      "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange}},
      "TableOptions":{...quoteListTableOptions},
      saveTransactionCeaseReasons
    },
    documents: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      getDocument:getDocument_quote,
    },
    documentsmpl: {
        "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      getDocument:getDocument_quote,
    },
    notes: {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":notesTableOptions,
      "tableFunctions": {"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,"deleteRow": onDeleteNotesandAttachmentsQuote},
      setEditPrivilege
    },
    ruleinformationlist:{
      "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange,"onExpand": onExpand}},
      "TableOptions":{...quoteListTableOptions},
      onChangeRuleStatus
    },
    savebusinessrules:{
      onSaveBusinessRule
    },
    "email":{
      "dialogOptions": {
        "titleNeeded": false,
        "dialogTitle": "",
        "maxWidth": "md"
      },
      "dialogUtils": {
        "handleClose": ClosePopUp_HomeOwners
      },
      "SendEmail":SendEmail_HomeOwners,
      uploadEmail:uploadEmail_HomeOwners,
      ClosePopUp,
      multipleUploads:multipleUploads_HomeOwners,
      "tableFunctions": {"AddRow":openPopUp_HomeOwners}
    },
    "emailsummary":{
      "tableUtils": {...quoteListTableUtils,"AddRow": openPopUp_HomeOwners},
      "TableOptions":{...quoteListTableOptions,"AddButtonNeeded": true},
      "tableFunctions": {"AddRow":openPopUp_HomeOwners},
      redirectTo: getEmailDetail_HomeOwners
   },
    "subjectivity": {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveSubjectivity,"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":notesTableOptions,
    },
    "policyattachments": {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveAttachments,"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":{...notesTableOptions},
      "tableFunctions": {"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,"deleteRow": onDeleteNotesandAttachmentsQuote},
      getDocument: getDocument_Attachment,
      setEditPrivilege
    }
  },
  InsuredDetails:{
    ...CommonUtils,
    componentDidMount: componentDidMount_InsuredV2,
    navendorsementsp:{
      saveinsuredPL : saveInsured_Endorsement_Orchid_PL
    },
    insuredsearch:{resetInsuredPage}
  },
  LocationDetails:{
    ...CommonUtils,
    componentDidMount: componentDidMount_LocationV2,
    navendorsementsp:{
      saveLocation:saveLocation_HomeOwners,
    },
    scheduleofpersonalproperty:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,ChildrenComponent:"childcomp","ActionName":""},
    },
    underwritingdetails:{
    saveUWDetails_HomeOwners: saveUWDetails_HomeOwners
    },
    claimshistory:{
      "tableUtils": {...quoteListTableUtils,...{"onChildSave":saveClaimsHistory_HomeOwners,"AddRow":addRow,"onExpand": onExpand}},
      "TableOptions":{...quoteListTableOptions,"ChildrenComponent":"childcomp","ActionName":""},
      "tableFunctions": {"onChildSave":saveClaimsHistory_HomeOwners,"AddRow":addRow,"onExpand": onExpand}
    },
    additionalinformation:{
      "tableUtils": {...quoteListTableUtils,...{"onChildSave":SaveAdditionalInsured_Quote_Homeowners,"AddRow":addRow,"onExpand": onExpand}},
      "TableOptions":{...quoteListTableOptions,...{"ChildrenComponent":"childcomp","AddButtonNeeded": false,"ActionName":"","ActionListOptions": [{ActionDisplayName: "Delete",OnOptionClick: deleteAdditionalInsured_Quote_Homeowners}]
    }},
    "tableFunctions": {"onChildSave":SaveAdditionalInsured_Quote_Homeowners,"AddRow":addRow,"onExpand": onExpand,"deleteRow": deleteAdditionalInsured_Quote_Homeowners}
    }
  },
  CoverageDetails:{
    ...CommonUtils,
    componentDidMount: componentDidMount_CoverageDetailsV2,
    adjustcoverage:{
      onRefreshPremium:onRefreshPremium_Endorsement
    },
    navendorsementpl:{
      onRefreshPremium:onRefreshPremium_Endorsement
    },
    summarytaxdetails:{
      "tableUtils": {...quoteListTableUtils,"EditRow": handleRowLevelInputChange_V2},
      "TableOptions":{...quoteListTableOptions},OnChangeAction: TaxAndFeeOverride
    },
    summaryfeedetails:{
      "tableUtils": {...quoteListTableUtils,"EditRow": handleRowLevelInputChange_V2},
      "TableOptions":{...quoteListTableOptions},OnChangeAction: TaxAndFeeOverride
    }
  },
  UWWorksheetDetails:{
    ...CommonUtils,
    componentDidMount: componentDidMount_UW_WorksheetV2,
    underwriterworksheet_merlindetails:{
      InvokePDFGeneration,
      displayDocument
    },
    underwriterworksheet_legacydetails:{
      InvokePDFGeneration
    },
    underwriterworksheet_canopius:{
      InvokePDFGeneration
    },
    worksheetnotesdetails:{
      saveWorkSheet:saveWorkSheet_HomeOwners,
      getWorkSheetRate:getWorkSheetRate_HomeOwners
    },
    "tableFunctions": {"onChildSave":SaveAdditionalInsured_Quote_Homeowners,"AddRow":addRow,"onExpand": onExpand,"deleteRow": deleteAdditionalInsured_Quote_Homeowners},
    underwriterworksheetsave :{
      underwriterworksheet_save: underwriterworksheetQuote_save,
      getunderwriterworksheet_calculatepremium: getunderwriterworksheet_calculatepremium_quote,
      printUWWorkSheet: InvokePDFGeneration
    },
    iaplsectionpremiumdetails :getunderwriterworksheet_rating_obj,
    generalmodifiers :getunderwriterworksheet_rating_obj,
    iamlsectionpremiumdetails :getunderwriterworksheet_rating_obj,
    iflsectionpremium :getunderwriterworksheet_rating_obj,
    eplsectionpremiumdetails:getunderwriterworksheet_rating_obj,
    flsectionpremiumdetails:getunderwriterworksheet_rating_obj,
    underwriterworksheet:{
      DisplayCoverages,
      InvokePDFGeneration
    },
    summarytaxdetails:{
      "tableUtils": {...quoteListTableUtils,"EditRow": handleRowLevelInputChange_V2},
      "TableOptions":{...quoteListTableOptions},OnChangeAction: TaxAndFeeOverride
    },
    summaryfeedetails:{
      "tableUtils": {...quoteListTableUtils,"EditRow": handleRowLevelInputChange_V2},
      "TableOptions":{...quoteListTableOptions},OnChangeAction: TaxAndFeeOverride
    }
  },
  Orchid: {
    parentStateUpdateNeeded: false,
    componentDidMount: componentDidMount_Orchid,
    productselection:{
      getAvailableMarkets:getAvailableMarkets_HomeOwners,
      availableMarketsValues:availableMarketsValues_HomeOwners,
      selectForms:selectForms_HomeOwners,
      getLocationList_Orchid,
      clearAvailableMarket:clearAvailableMarket,
      clearAvailableMarketText:clearAvailableMarketText,
      VeRiskRCVCalculator
    },
    solscheduleofformssummary: {
      "tableUtils": {
        "AddRow": null,
        "onExpand": null,
        "onChildSave": null,
        "EditRow": "",
        "OnDetailEdit": "",
        "redirectTo": ""
      },
      "TableOptions": {
        "ChildrenSubApplicationName": null,
        "ChildrenComponent": null,
        "AddButtonNeeded": false,
        "SortingNeeded": false,
        "FilteringNeeded": false,
        "FilterIconNeeded": false,
        "rowsPerPageOptions": [15, 30, 45, 60, 100],
        "RowsPerPage": 15,
        "ActionName": "Action",
        "ActionListOptions": [],
      },
      getDocument: getDocument_forms,
      tableFunctions: {
        handleRowLevelInputChange: handleRowLevelInputChangerules,
        disablecheckbox : disablecheckbox,
      },
    },
    scheduleofformsbutton:{
      handleOptionalForms:handleOptionalForms,
      requiredFormSelection:requiredFormSelection
    },
    optionalformsbutton:{
      handleOptionalForms:handleOptionalForms,
      requiredFormSelection:requiredFormSelection,
      close:ClosePopUp__scheduleofforms
      },
      optionalforms:{
        "tableUtils": {
          "AddRow": null,
          "onExpand": null,
          "onChildSave": null,
          "EditRow": "",
          "OnDetailEdit": "",
          "redirectTo": ""
        },
        "TableOptions": {
          "ChildrenSubApplicationName": null,
          "ChildrenComponent": null,
          "AddButtonNeeded": false,
          "SortingNeeded": false,
          "FilteringNeeded": false,
          "FilterIconNeeded": false,
          "rowsPerPageOptions": [15, 30, 45, 60, 100],
          "RowsPerPage": 15,
          "ActionName": "Action",
          "ActionListOptions": [],
        },
        getDocument: getDocument_forms,
        tableFunctions: {
          handleRowLevelInputChange: handleRowLevelInputChangerules
        }
      },
    taxescarrier:{
      "tableUtils": {
        "AddRow": null,
        "onExpand": null,
        "onChildSave": null,
        "EditRow": handleRowLevelInputChange,
        "OnDetailEdit": "",
        "redirectTo": ""
      },
      "TableOptions": {
        "ChildrenSubApplicationName": null,
        "ChildrenComponent": null,
        "AddButtonNeeded": false,
        "SortingNeeded": false,
        "FilteringNeeded": false,
        "FilterIconNeeded": false,
        "rowsPerPageOptions": [],
        "RowsPerPage": 50,
        "ActionName": "Action",
        "ActionListOptions": [],
        "Paginations": null
      }
    },
    feescarrier: {
      "tableUtils": {
        "AddRow": null,
        "onExpand": null,
        "onChildSave": null,
        "EditRow": handleRowLevelInputChange,
        "OnDetailEdit": "",
        "redirectTo": ""
      },
      "TableOptions": {
        "ChildrenSubApplicationName": null,
        "ChildrenComponent": null,
        "AddButtonNeeded": false,
        "SortingNeeded": false,
        "FilteringNeeded": false,
        "FilterIconNeeded": false,
        "rowsPerPageOptions": [],
        "RowsPerPage": 50,
        "ActionName": "Action",
        "ActionListOptions": []
      }
    },
    componentDidUpdate: componentDidUpdate_Orchid,
    availablemarketheader:{
      onAdjustCoverage:onAdjustCoverage
    },
    adjustcoverage:{
      onRefreshPremium:onRefreshPremium
    },
    adjustcoveragenavigation : {
      onAdjustCoverageNavigation:onAdjustCoverageNavigation,
      onRefreshPremium:onRefreshPremium,
      viewtoggle: viewtoggle,
      closeToggle: closeToggle
    },
    availablemarket:{
      dummyGetInteg:dummyGetInteg_Homes,
      changeValues:invokePremiumandRating_HomeOwners,
      invokeRating:invokePremiumandRating_HomeOwnersV1,
      invokePremiumandRating_HomeOwners_CarrierSpecficRules,
      invokePremiumandRating_HomeOwners_Packages,
      invokePremiumandRating_HomeOwners_Packages_V02,
      toggleView,
      createQuote_Orchid,
      createQuoteNC,
    },
    handleInputChangeV2: handleInputChangeV2_Orchid,
  },
  SelectProgram : {
    ...Orchid,
    componentDidMount: componentDidMount_ProductSelection,
     updateValueWithSubplicationName: updateValueWithSubplicationName_GL,
  },
  SelectProgram_NonConfigured : {
    ...Orchid,
    componentDidMount: componentDidMount_NonConfiguredProductSelection,
    componentDidUpdate: componentDidUpdate_NonConfiguredProductSelection,
     updateValueWithSubplicationName: updateValueWithSubplicationName_GL,
  },
  SelectProgram_rateindication : {
    ...Orchid,
    componentDidMount: componentDidMount_ProductSelection_RateIndication,
    updateValueWithSubplicationName: updateValueWithSubplicationName_GL,
  },
  SelectProgram_CL:{
    ...Orchid,

    componentDidMount: componentDidMount_ProductSelection_CL,
        buildingpremium:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,RowsPerPage:5},
    },
    locationpremium:{
      "tableUtils": {...quoteListTableUtils,"onExpand": onExpand},
      "TableOptions":{...quoteListTableOptions,"ActionName":"Action",RowsPerPage:5},
    },
    policyinfo:{
      savePolicyInfo:savePolicyInfo_CL,
      PrintRate:getBuildingPremium_CL
    },
    totalpremium:{
      createQuote:createQuote_CL
    }
  },
  CarrierSelection : {
    ...Orchid,
    componentDidMount: componentDidMount_CarrierSelection,
    handleInputChangeV2: handleInputChangeV2Callback_MPL,
   updateValueWithSubplicationName : updateValueWithSubplicationName_GL,
   "ho3coveragedetails": {
    onRefreshPremiumV2: onRefreshPremiumV2
   }
  },
  AvailableMarketParent : {
    // ...Orchid,
    // componentDidMount: componentDidMount_Orchid,
  },
  Orchid_UW: {
    parentStateUpdateNeeded: false,
    componentDidMount: componentDidMount_Orchid,
    componentDidUpdate: componentDidUpdate_OrchidUW,
    handleInputChangeV2: handleInputChangeV2_Orchid,
    underwritingdetails: {
      saveUWDetails_HomeOwners: saveUWDetails_HomeOwners,
      saveUWDetails_HomeOwners_RateIndication:saveUWDetails_HomeOwners_RateIndication,
    },
  },
  CoverageSelection_MPL: {
    parentStateUpdateNeeded: true,
    componentDidMount: componentDidMount_CoverageSelection_MPL,
    handleInputChangeV2: handleInputChangeV2Callback_MPL,
    handleDateChange: handleDateChange_GL,
    // investmentadviserprofessionalliability : {clearValueMPL},
    // investmentfundliability : {clearValueMPL},
    // investmentadviserprofessionalliability : {clearValueMPL}
  },
  CoverageDetails_MPL: {
    parentStateUpdateNeeded: false,
    componentDidMount: componentDidMountDetail_MPL,
    componentDidUpdate: componentDidUpdate_Orchid,
    handleInputChangeV2: handleInputChangeV2Callback_MPL,
    onKeyDown : preventFormSubmit,
    coverageiapl2_1_e_isummary: TableControls,
    coverageiapl2_1_bsummary: TableControls,
    coverageiapl2_2_a: TableControls,
    iaplsave :  saveCoverageDetailMPL_obj,
    iflsave : saveCoverageDetailMPL_obj,
    iamlsave :  saveCoverageDetailMPL_obj,
    eplsave :  saveCoverageDetailMPL_obj,
    flsave :  saveCoverageDetailMPL_obj,

    coverageiapl2_1_c: {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveRow,"AddRow":addRow,"onExpand": onExpandRow,}},
      "TableOptions":{...notesTableOptions, "ActionListOptions": [{ActionDisplayName: "Delete",OnOptionClick: onDeleteRow}]},
      }
  },
  CoverageSelection_Summary_MPL:{
    parentStateUpdateNeeded: false,
    componentDidMount: CoverageSelectionQuote_MPL,
    handleInputChangeV2: handleInputChangeV2Callback_MPL
  },
  CoverageDetails_Summary_MPL:{
    parentStateUpdateNeeded: false,
    componentDidMount: CoverageDetailsQuote_MPL,
    handleInputChangeV2: handleInputChangeV2Callback_MPL
  },
  RatingCreateQuote_MPL:{
    parentStateUpdateNeeded: false,
    componentDidMount:componentDidMount_RatingCreateQuote_MPL,
    handleInputChangeV2: handleInputChangeV2Callback_MPL,
    ratingcreatequote:{
      showTable_RCQ:Rating_toggleView_MPL,
      CreateQuote_MPL


    },
    ratingcoveragedetails:{show_UWW:popup_Rating,
      show_BR:popup_Rating},
    ratingcoveragedetailssummary: TableControls,
  },
  UnderWriterWorkSheet_MPL :{
    parentStateUpdateNeeded: false,
    componentDidMount:componentDidMount_UW_WorksheetMPL,
    underwriterworksheetsave :{
      underwriterworksheet_save,
      getunderwriterworksheet_calculatepremium,
      printUWWorkSheet: InvokePDFGeneration_MPLSubmission
    },iaplsectionpremiumdetails :getunderwriterworksheet_rating_obj,
    iamlsectionpremiumdetails :getunderwriterworksheet_rating_obj,
    generalmodifiers :getunderwriterworksheet_rating_obj,
    iflsectionpremium :getunderwriterworksheet_rating_obj,
    eplsectionpremiumdetails:getunderwriterworksheet_rating_obj,
    flsectionpremiumdetails:getunderwriterworksheet_rating_obj,
    underwriterworksheet:{
      DisplayCoverages
    }
  },
  UnderWriterWorkSheetQuote_MPL :{
    parentStateUpdateNeeded: false,
    componentDidMount:componentDidMount_UW_WorksheetMPL,
    handleInputChangeV2: handleInputChangeV2Callback_MPL,
    underwriterworksheetsave :{
      underwriterworksheet_save: underwriterworksheetQuote_save,
      getunderwriterworksheet_calculatepremium: getunderwriterworksheet_calculatepremium_quote,
      printUWWorkSheet: InvokePDFGeneration_MPLSubmission
    },iaplsectionpremiumdetails :getunderwriterworksheet_rating_obj,
    iamlsectionpremiumdetails :getunderwriterworksheet_rating_obj,
    generalmodifiers :getunderwriterworksheet_rating_obj,
    iflsectionpremium :getunderwriterworksheet_rating_obj,
    eplsectionpremiumdetails:getunderwriterworksheet_rating_obj,
    flsectionpremiumdetails:getunderwriterworksheet_rating_obj,
    underwriterworksheet:{
      DisplayCoverages
    }
  },
  PL: {
    Home:{
      createNewSubmission,
    }
  },
  MPL: {
    Home:{
      createNewSubmission,
    }
  },
  CL: {
    Home:{
      createNewSubmission,
    }
  },
  Endorsement:{
    componentDidMount: componentDidMount_Endorsement,
    componentDidUpdate: componentDidUpdate_BasePage,
    handleInputChangeV2: handleInputChangeV2_Orchid,
    updateValueWithSubplicationName : updateValueWithSubplicationName_PL,
    parentStateUpdateNeeded: false,
    navendorsementsp:{
      saveLocation:saveLocation_HomeOwners,
      saveinsuredPL : saveInsured_Endorsement_Orchid_PL,
      offerEndorsement:offerEndorsement_HomeOwners,
      IssueEndorsement:IssueEndorsement_HomeOwners
    },
    viewbumprulespl:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
    },
    issueendorsement:{
      previewBinder:previewBinder_HomeOwners,
      IssueEndorsement:IssueEndorsement_HomeOwners
    },
    newendorsementinformation:{
      updateEndorsement:updateEndorsement_HomeOwners
    },
    underwriterworksheet_merlindetails:{
      InvokePDFGeneration
      },
    ceaseinformationlist:{
      "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange}},
      "TableOptions":{...quoteListTableOptions},
      saveTransactionCeaseReasons
    },
    ceaseinformationlistinpolicy:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
    },
   underwriterworksheet_legacydetails:{
      InvokePDFGeneration
      },
   underwriterworksheet_canopius:{
      InvokePDFGeneration
     },
    claimshistory:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"ChildrenComponent":"childcomp","ActionName":""},
    },
    scheduleofpersonalproperty:{
      "tableUtils": {...quoteListTableUtils,"AddRow":addRow,"onExpand": onExpandRow,"onChildSave":saveProperty_HomeOwners},
      "TableOptions":{...notesTableOptions},
      "tableFunctions": {"AddRow":addRow,"onExpand": onExpandRow,"onChildSave":saveProperty_HomeOwners,"deleteRow":onDeleteNotesandAttachmentsQuote}
    },
    documents: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      getDocument:getDocument_quote,
    },
    insured:{
      changeDOB:onChangeMaskedCalendar
    },
    additionalinsured:{
      changeDOB:onChangeMaskedCalendar
    },
    notes: {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":notesTableOptions,
      "tableFunctions":{"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,"deleteRow": onDeleteNotesandAttachmentsQuote},
      setEditPrivilege
    },
    "email":{
      "dialogOptions": {
        "titleNeeded": false,
        "dialogTitle": "",
        "maxWidth": "md"
      },
      "dialogUtils": {
        "handleClose": ClosePopUp_HomeOwners
      },
      "SendEmail":SendEmail_HomeOwners,
      uploadEmail:uploadEmail_HomeOwners,
      ClosePopUp,
      multipleUploads:multipleUploads_HomeOwners
    },
    "emailsummary":{
      "tableUtils": {...quoteListTableUtils,"AddRow": openPopUp_HomeOwners},
      "TableOptions":{...quoteListTableOptions,"AddButtonNeeded": true},
      "tableFunctions": {"AddRow":openPopUp_HomeOwners},
       redirectTo: getEmailDetail_HomeOwners
    },
    policyattachments: {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveAttachments,"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":{...notesTableOptions},
      "tableFunctions": {"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,"deleteRow": onDeleteNotesandAttachmentsQuote},
      getDocument: getDocument_Attachment,
      setEditPrivilege
    },
    viewbusinessrules:{
      "tableUtils":{...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"ActionName":""}
    },
    underwritingdetails:{
      saveUWDetails_HomeOwners,
      saveUWDetails_HomeOwners_RateIndication
    },
    additionalinformation:{
      "tableUtils": {...quoteListTableUtils,...{"onChildSave":SaveAdditionalInsured_Quote_Homeowners,"AddRow":addRow,"onExpand": onExpand}},
      "TableOptions":{...quoteListTableOptions,...{"ChildrenComponent":"childcomp",
      "AddButtonNeeded": true,"ActionName":"",
      "ActionListOptions": [{ActionDisplayName: "Delete",OnOptionClick: deleteAdditionalInsured_Quote_Homeowners}],
    }},
    "tableFunctions": {"onChildSave":SaveAdditionalInsured_Quote_Homeowners,"AddRow":addRow,"onExpand": onExpand,"deleteRow":deleteAdditionalInsured_Quote_Homeowners}
    },
    ruleinformationlist:{
      "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange,"onExpand": onExpand}},
      "TableOptions":{...quoteListTableOptions},
      onChangeRuleStatus
    },
    savebusinessrules:{
      onSaveBusinessRule
    },
    quotedetail:{
      lapseDecline_QuoteOrchid,
      returnToPolicyDetails,
      referQuote : referQuote_Endorsement
    },declinequote:{
      lapseDeclineClose,
      lapseOrDeclineConfirm
      },
      lapsequote:{
        lapseDeclineClose,
        lapseOrDeclineConfirm
        },
    adjustcoverage:{
      onRefreshPremium:onRefreshPremium_Endorsement
    },
    summarytaxdetails:{
      "tableUtils": {...quoteListTableUtils,"EditRow": handleRowLevelInputChange_V2},
      "TableOptions":{...quoteListTableOptions},OnChangeAction: TaxAndFeeOverride
    },
    summaryfeedetails:{
      "tableUtils": {...quoteListTableUtils,"EditRow": handleRowLevelInputChange_V2},
      "TableOptions":{...quoteListTableOptions},OnChangeAction: TaxAndFeeOverride
    },
    navendorsementpl:{
      savePremium:savePremium_HomeOwners
    },
    viewbusinessrulespl:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
    },
    endorsementsummary:{
      showSummayOfChanges:showSummayOfChanges_HomeOwners
    },
    insuredchanges:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage": 50
    }
    },locationchanges:{
      "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions,"RowsPerPage": 50},},
    underwritingchanges:{
      "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions,"RowsPerPage": 50},},
    personalpropertychanges:{
      "tableUtils": {...quoteListTableUtils},
    "TableOptions":{...quoteListTableOptions,"RowsPerPage": 50},
  },
  underlyingexcesschanges:{
    "tableUtils": {...quoteListTableUtils},
  "TableOptions":{...quoteListTableOptions,"RowsPerPage": 50},
},
    claimshistorychanges:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage": 50},
    },
    additionalinterestschanges:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage": 50},
    },
    coveragechanges:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage": 50},
    },
    premiumchanges:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions,"RowsPerPage": 50},
    }
  },
  EndorsementDetails:{
    ...CommonUtils,
    componentDidMount: componentDidMount_EndorsementDetails,
    newendorsementinformation:{
      updateEndorsement:updateEndorsement_HomeOwners
    },
    documents: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      getDocument:getDocument_quote,
    },

    notes: {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":notesTableOptions,
      "tableFunctions":{"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,"deleteRow": onDeleteNotesandAttachmentsQuote},
      setEditPrivilege
    },
    "email":{
      "dialogOptions": {
        "titleNeeded": false,
        "dialogTitle": "",
        "maxWidth": "md"
      },
      "dialogUtils": {
        "handleClose": ClosePopUp_HomeOwners
      },
      "SendEmail":SendEmail_HomeOwners,
      uploadEmail:uploadEmail_HomeOwners,
      ClosePopUp,
      multipleUploads:multipleUploads_HomeOwners
    },
    "emailsummary":{
      "tableUtils": {...quoteListTableUtils,"AddRow": openPopUp_HomeOwners},
      "TableOptions":{...quoteListTableOptions,"AddButtonNeeded": true},
      "tableFunctions": {"AddRow":openPopUp_HomeOwners},
       redirectTo: getEmailDetail_HomeOwners
    },
    policyattachments: {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveAttachments,"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":{...notesTableOptions},
      "tableFunctions": {"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,"deleteRow": onDeleteNotesandAttachmentsQuote},
      getDocument: getDocument_Attachment,
      setEditPrivilege
    },
    quotedetail:{
      lapseDecline_Quote: lapseDecline_QuoteOrchid,
      returnToPolicyDetails,
      referQuote : referQuote_Endorsement
    }
  },
  EndorsementSummary:{
    ...CommonUtils,
    componentDidMount: componentDidMount_EndorsementSummary,
    quotedetail: {
      lapseDecline_Quote:lapseDecline_QuoteOrchid,
      returnToPolicyDetails,
      referQuote: referQuote_Endorsement
    },
    endorsementsummary:{
      showSummayOfChanges:showSummayOfChanges_HomeOwners
    },
    savebusinessrules: {
      onSaveBusinessRule
    },
    insuredchanges: {
      "tableUtils": { ...quoteListTableUtils },
      "TableOptions": {
          ...quoteListTableOptions, "RowsPerPage": 50
      }
    },
    ruleinformationlist: {
      "tableUtils": { ...quoteListTableUtils, ...{ "EditRow": handleRowLevelInputChange, "onExpand": onExpand } },
      "TableOptions": { ...quoteListTableOptions },
      onChangeRuleStatus
    },
    navendorsementsp: {
      saveLocation: saveLocation_HomeOwners,
      saveinsuredPL: saveInsured_Endorsement_Orchid_PL,
      offerEndorsement: offerEndorsement_HomeOwners,
      IssueEndorsement: IssueEndorsement_HomeOwners
    },
    locationchanges: {
        "tableUtils": { ...quoteListTableUtils },
        "TableOptions": { ...quoteListTableOptions, "RowsPerPage": 50 },
    },
    underwritingchanges: {
        "tableUtils": { ...quoteListTableUtils },
        "TableOptions": { ...quoteListTableOptions, "RowsPerPage": 50 },
    },
    personalpropertychanges: {
        "tableUtils": { ...quoteListTableUtils },
        "TableOptions": { ...quoteListTableOptions, "RowsPerPage": 50 },
    },
    underlyingexcesschanges: {
      "tableUtils": { ...quoteListTableUtils },
      "TableOptions": { ...quoteListTableOptions, "RowsPerPage": 50 },
  },
    claimshistorychanges: {
        "tableUtils": { ...quoteListTableUtils },
        "TableOptions": { ...quoteListTableOptions, "RowsPerPage": 50 },
    },
    additionalinterestschanges: {
        "tableUtils": { ...quoteListTableUtils },
        "TableOptions": { ...quoteListTableOptions, "RowsPerPage": 50 },
    },
    coveragechanges: {
        "tableUtils": { ...quoteListTableUtils },
        "TableOptions": { ...quoteListTableOptions, "RowsPerPage": 50 },
    },
    premiumchanges: {
        "tableUtils": { ...quoteListTableUtils },
        "TableOptions": { ...quoteListTableOptions, "RowsPerPage": 50 },
    },
    issueendorsement: {
      previewBinder: previewBinder_HomeOwners,
      IssueEndorsement: IssueEndorsement_HomeOwners
    }
  },
  Reinstate:{
    componentDidMount: componentDidMount_CancelReinstate,
    componentDidUpdate: componentDidUpdate_BasePage,
    handleInputChangeV2: handleInputChangeV2_Orchid,
    parentStateUpdateNeeded: false,
    reinstatedetails:{
      reviewReinstate,
      reviewReinstate_V1
    },
    ceaseinformationlist:{
      "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange}},
      "TableOptions":{...quoteListTableOptions},
      saveTransactionCeaseReasons
    },
    ceaseinformationlistinpolicy:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
    },
    policylist: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      onQuoteNumberClick,
      onPolicyNumberClick
    },
    reinstatebuttons:{
      issueReinstate,
      issueReinstateExitmtd,
      previewBinder_HomeOwners,
      discardCancelReinstate,
      reinstateNavigator
    },
    cancelreinstatetax:{
      "tableUtils": {...quoteListTableUtils,"EditRow": handleRowLevelInputChange},
      "TableOptions":{...quoteListTableOptions},
    },
    cancelreinstatefee:{
      "tableUtils": {...quoteListTableUtils,"EditRow": handleRowLevelInputChange},
      "TableOptions":{...quoteListTableOptions},
    },
    quotedetail:{
      returnToPolicyDetails
    },
    viewrulemessages:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions}
    },
  },
  ReinstateSummary:{
    componentDidMount: cancelReinstateDetailInformation,
    handleInputChangeV2: handleInputChangeV2_Orchid,
    parentStateUpdateNeeded: false,
    reinstatedetails:{
      reviewReinstate,
      reviewReinstate_V1
    },
    ceaseinformationlist:{
      "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange}},
      "TableOptions":{...quoteListTableOptions},
      saveTransactionCeaseReasons
    },
    ceaseinformationlistinpolicy:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
    },
    policylist: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      onQuoteNumberClick,
      onPolicyNumberClick
    },
    reinstatebuttons:{
      issueReinstate,
      issueReinstateExitmtd,
      previewBinder_HomeOwners,
      discardCancelReinstate,
      reinstateNavigator
    },
    cancelreinstatetax:{
      "tableUtils": {...quoteListTableUtils,"EditRow": handleRowLevelInputChange},
      "TableOptions":{...quoteListTableOptions},
    },
    cancelreinstatefee:{
      "tableUtils": {...quoteListTableUtils,"EditRow": handleRowLevelInputChange},
      "TableOptions":{...quoteListTableOptions},
    },
    quotedetail:{
      returnToPolicyDetails
    },
    viewrulemessages:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions}
    },
  },
  Cancel:{
    componentDidMount  : componentDidMount_CancelReinstate,
    componentDidUpdate: componentDidUpdate_BasePage,
    handleInputChangeV2: handleInputChangeV2_Orchid,
    parentStateUpdateNeeded: false,
	  policyattachments: {
      "tableUtils": {...quoteListTableUtils,...{"onChildSave": onSaveAttachments,"AddRow":addRow,"onExpand": onExpand,}},
      "TableOptions":{...notesTableOptions},
      "tableFunctions": {"onChildSave": onSaveNotesAndAttachments,"AddRow":addRow,"onExpand": onExpand,"deleteRow": onDeleteNotesandAttachmentsQuote},
      getDocument: getDocument_Attachment,
      setEditPrivilege
    },
    quotedetail:{
      returnToPolicyDetails
    },
    policylist: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      onQuoteNumberClick,
      onPolicyNumberClick
    },
    cancelreinstatetax:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
    },
    cancelreinstatefee:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
    },
    cancelbuttons:{
      previewcancelDocument : previewBinder_HomeOwners,
      reviewCancel : reviewCancel_Homeowners,
      cancelNavigator,
      issueCancel : issueCancel_Homeowners,
      discardCancelReinstate
    },
    cancelreinstatesummary:{
      reviewCancel : reviewCancel_Homeowners
    },
    savebusinessrules:{
      onSaveBusinessRule
    },
    ruleinformationlist:{
      "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange,"onExpand": onExpand}},
      "TableOptions":{...quoteListTableOptions},
      onSaveBusinessRule,
      onChangeRuleStatus
    },
  },
  CancelSummary:{
    componentDidMount: cancelReinstateDetailInformation,
    handleInputChangeV2: handleInputChangeV2_Orchid,
    parentStateUpdateNeeded: false,
    quotedetail:{
      returnToPolicyDetails
    },
    policylist: {
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
      onQuoteNumberClick,
      onPolicyNumberClick
    },
    cancelreinstatetax:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
    },
    cancelreinstatefee:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions},
    },
    cancelbuttons:{
      previewcancelDocument : previewBinder_HomeOwners,
      reviewCancel : reviewCancel_Homeowners,
      cancelNavigator,
      issueCancel : issueCancel_Homeowners,
      discardCancelReinstate
    },
    cancelreinstatesummary:{
      reviewCancel : reviewCancel_Homeowners,
    },
    savebusinessrules:{
      onSaveBusinessRule
    },
    ruleinformationlist:{
      "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange,"onExpand": onExpand}},
      "TableOptions":{...quoteListTableOptions},
      onSaveBusinessRule,
      onChangeRuleStatus
    },
    viewrulemessages:{
      "tableUtils": {...quoteListTableUtils},
      "TableOptions":{...quoteListTableOptions}
    }
  },
  ResetPassword:{
    resetuserpassword:{
      RedirectToLogin
    }
  },
  Home:{
    ...Orchid, componentDidMount: componentDidMount_Home,
    updateValueWithSubplicationName : updateValueWithSubplicationName_PL,
    homeowners:{
      createNewSubmission
    },
    selectlob:{
      redirectISO
    },
    selectedproducts:{
      invokeGetProductsList
    },
    findbysubmission:{
      findConsoleNavigation
    },
    findbyquote:{
      findConsoleNavigation
    },
    findbypolicy:{
      findConsoleNavigation
    },
    managementprofessionalliability: {
      createNewSubmission
    },
    carrierquoteinformation:{
      callGetQuote : callGetQuote
    },
    quoteagentinformation:{
      callGetQuote : callGetQuote
    },
    mergedocumentsdetail:{
      callGetQuote : callGetQuote
    },
    nc_quoteinformationheader:{
      GetQuoteInsured : GetQuoteInsured
    },
    nc_policyinformationheader:{
      GetQuoteInsured : GetQuoteInsured
    },
    nc_endorsementinformationheader:{
      GetQuoteInsured,
      callGetQuote : callGetQuote
    },
    nc_lapsequote:{
      callGetQuote : callGetQuote
    },
    nc_declinequote:{
      callGetQuote : callGetQuote
    },
 },
 Dashboard:{
  ...Orchid,componentDidMount: componentDidMount_Dashboard,componentDidUpdate: componentDidUpdate_Dashboard
},
tabs:{
  ...Orchid,
  parentStateUpdateNeeded: false,
  "componentDidMount": componentDidMount_TabChildren,
  "componentDidUpdate":componentDidUpdate_TabChildren,
  "handleInputChangeV2": handleInputChangeV2_NewUtil,
  updateValueWithSubplicationName : updateValueWithSubplicationName_PL,
  "dashboarddiarynotessummary":{
    handleAllInDairyNotes
  }
},
DashboardUtil:{
  "tableFunctions":{
    "EditRow": handleRowLevelInputChange,"onExpand": onExpand,"onTableLazyLoadData": getDashboardTableData,
  },
  objectChange
},
DashboardBusinessRules:{
  "componentDidMount": componentDidMount_DashboardBusinessRules,
  "componentDidUpdate":componentDidUpdate_DashboardBusinessRules,
  parentStateUpdateNeeded:false,
  ruleinformationlist:{
    "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange,"onExpand": onExpand}},
    "TableOptions":{...quoteListTableOptions},
    onChangeRuleStatus
  },
},
colorPalette:{
  componentDidMount:componentDidMount_ColorPalette,
  componentDidUpdate:componentDidUpdate_ColorPalette,
  parentStateUpdateNeeded:false,
  colorpalette:{
    onClickApply:onClickApply,
    handleInputChangeV2:handleInputChangeV2_ColorPalette,
    dialogUtils:{
      handleClose:handleClose_ColorPalette,
    },
    dialogOptions:{
       maxWidth:"xs",
    },
  },
},
FindNavigation:{
  handleRedirection,
  "findinsured":{
    callMetadata:metadataCallAutoComplete
  },
  "findsubmission":{
    callMetadata:metadataCallAutoComplete
  },
  "findquote":{
    callMetadata:metadataCallAutoComplete
  }
},
 Find:{
  componentDidMount:componentDidMount_FindSubmission,
  handleInputChangeV2: handleInputChangeV2_Orchid,
  parentStateUpdateNeeded:false,
  ruleinformationlist:{
    "tableUtils": {...quoteListTableUtils,...{"EditRow": handleRowLevelInputChange,"onExpand": onExpand}},
    "TableOptions":{...quoteListTableOptions},
    onChangeRuleStatus
  },
  savebusinessrules:{
    onSaveBusinessRule : onSaveBusinessRule_FindSubmission,
    declineSubmission : decline_Submission_Find,
    referSubmission : refer_Submission_Find,
    availableMarket : availableMarket_Navigator
  }
 },
 basePageTemplate_PageName:{
  parentStateUpdateNeeded: false,
  componentDidMount: componentDidMount_BasePage,
  componentDidUpdate: componentDidUpdate_BasePage
},
 IntegrationConsole:{
  parentStateUpdateNeeded: false,
  componentDidMount: componentDidMount_IntegrationConsole,
  componentDidUpdate: componentDidUpdate_IntegrationConsole,
  "handleInputChangeV2": handleInputChangeV2_Manage,
  "updateValueWithSubplicationName": updateValueWithSubplicationName_Acc,
  "integration":{
   handleOnReset,
  },
  "integrationsummary": {
    "tableFunctions": {
      viewData,
      "onTableLazyLoadData": getIntegrationConsoleTableData,
    }
  },
},HomeUtil:{
  createNewSubmission
 },LocationUtil:{
  getCoordinatesV2,
  getCoordinates
},
FindChild:{
 findsubmission:{
   removeUnrelevantKeys
 }
},
quotaShare:{
  quotasharebuttons:{
    "handleQuotaShareSave":handleQuotaShareSave,
    "saveQuotaShare_package":saveQuotaShare_package,
    "hanvleQuotaShareUpdate":handleQuotaShareUpdate,
    "handleQuotaShareUpdate_package":handleQuotaShareUpdate_package
  }
},
InsuredPage:{
  insuredsearchbrokeragemarkets:{
    getDynamicplProduct
  },
  insuredbutton:{
    "handleInsuredSave":handleInsuredSave,
    "handleSaveAccountInsured" : handleSaveAccountInsured
  },
  insuredattachments:{
    getDocument: getDocument_Attachment
  },
  insuredattachmentsdetail:{
    "handleSaveAttachment": handleSaveAttachment
  }
 },
 NCEndoCoverageDetails : {
  endorsementproductselection:{
    NCEndoRatingAPICall
  }
 } ,
clearAvailableMarketText:clearAvailableMarketText,
retainPolicyForms:retainPolicyForms
};
async function removeUnrelevantKeys(props, newObj, attributeInfo){
   Object.keys(newObj).map(key =>{
     if(key === props.component.AttributeName){
       return;
     }
     delete newObj[key];
   })
  };

async function componentDidMount_Home(state, props, UpdateState){
  UpdateState("loading",true,true);
  const response = await componentDidMount_Orchid(state, props)
  return {
    "loading": false,
    ...response
  }
 }


async function componentDidUpdate_Dashboard(state,props,prevState,prevProps,UpdateState){
  if (props.location.pathname !== prevProps.location.pathname)
  return componentDidMount_Dashboard(state, props, UpdateState)
  return {};
}
async function componentDidMount_Dashboard(state, props, UpdateState){
  UpdateState("loading",true,true);
  const subappName=props.subApplication||props.subApplicationName;
  let subappinfo = {
    subappName:subappName,
    objectId: props.objectId,
    objectVerId: props.objectVerId
  };
  let metadataResponse = await constructAndInvokeMetadataCall(subappinfo);
  return {
    "pageMetadata": metadataResponse,
    "loading": false,
  }
 }

 async function resetInsuredPage(value,component, isDynamicTable, datatableRowindex,props){
    try{
      await props.handleInputChangeV2(value,component, isDynamicTable, datatableRowindex,props);
      props.reference.resetValidations();
    }catch(e){
      console.log('Error in resetInsuredPage',e);
    }

  }


