import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Button } from "@material-ui/core"
import { format } from 'date-fns'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { getParentState } from '../../../utils/Common/CommonUtils'
import { handleInputChangeV2 } from '../../../utils/Common/InputUtils'
import { connect } from 'react-redux'
import { hideLoader, showLoader } from '../../../../../actions/UIFlowActions'
import PageLoader from '../../../controls/PageLoader';
import { commonButtonMethod } from '../../../controls/ButtonUtil'
import { constructAndInvokeMetadataCall } from '../../../utils/Common/MetaUtils'
import container from '../../../utils/DIContainer'
import { mergeDeep } from '../../../utils/Common/NoCodeUtils'


const ChildCompV2 = (props) => {
  const [pageMetadata, setPageMetadata] = useState({})
  const [pagelevelValues, setPageLevelValues] = useState({})
  const [values, setValues] = useState({})
  const [transactionPrimaryObject, setTransactionPrimaryObject] = useState({})
  const [state, setState] = useState({});
  const [subappInfoDetails, setSubappDetails] = useState({})

  let childform = useRef()
  const dynamicRenderV2 = container.get('dynamicRenderV2')
  let subapplicationList = []
  let SubAppNameList = []

  useEffect( async () => {
    setState({...state,loading:true})
    let parentState = getParentState(props)
    let detailSubapp=props.rowData && props.rowData['Detail_SubapplicationName'];
    let childSubApp =props.mActionList && props.mActionList.childComponent && props.mActionList.childComponent.childSubapplication;
    SubAppNameList =childSubApp ? childSubApp:(detailSubapp?detailSubapp:[]);
    let values = { ...props.rowData }
    let transactionPrimaryObject = {}
    let pagelevelValues = {}
    let subappValue = {}
    Array.isArray(SubAppNameList) && SubAppNameList.map((val) => {
      let newVal = { ...props.rowData }
      transactionPrimaryObject = { ...newVal }
      pagelevelValues = { ...newVal }
      if (!props.rowData.hasOwnProperty(val))
        subappValue[val] = { ...props.rowData }
    })
    values = { ...values, ...subappValue }
    setValues(Object.assign({}, values, values))
    setTransactionPrimaryObject(Object.assign({}, transactionPrimaryObject, {transactionPrimaryObject, ...values}))
    setPageLevelValues(Object.assign({}, pagelevelValues, { pagelevelValues, ...values }))
    const { pageInfo: { objectId, objectVerId } } = props
    let subappDetails= {subappName: SubAppNameList, objectId, objectVerId, state: parentState}
    setSubappDetails(Object.assign({}, subappDetails))

    await getMetadataRequest(parentState, SubAppNameList, transactionPrimaryObject, values, pagelevelValues)
    setState({...state,loading:false})
}, [])

  const getMetadataRequest = async (parentState, childsubApp, transactionObject, value, pagelevelValue) => {
    props.showLoader()
    const { pageInfo: { objectId, objectVerId } } = props
    let pageLevelValues = Object.assign({}, {...props.pagelevelValues, ...props.parentProps.pagelevelValues, ...props.componentState.pagelevelValues})
    pageLevelValues[childsubApp] = value[childsubApp]
    let EffectiveDate = pagelevelValue && pagelevelValue.hasOwnProperty("PolicyInfo") ? pagelevelValue.PolicyInfo.EffectiveDate : format(new Date(), 'yyyy-MM-dd')
    let transactionValue = { ...transactionObject, ...value, ...pagelevelValue }
    await constructAndInvokeMetadataCall({ subappName: childsubApp, objectId, objectVerId, state: parentState }, { transactionPrimaryObject: transactionValue, EffectiveDate }, metadataAPI_callback)
  }

  const metadataAPI_callback = (request, response) => {
    setPageMetadata(response)
    props.hideLoader()
  }

  const executeScroll = () => {
    ReactDOM.findDOMNode(childform.current.errors[0]).scrollIntoView({ behavior: 'smooth', block: 'center' });
    if (false || !!document.documentMode) {
      window.scrollBy(0, -120);
    }
  }

  const handleInputChange = async (value, component, isDynamicTable, datatableRowindex) => {
    let values = await handleInputChangeV2(value, component, props, { pagelevelValues, transactionPrimaryObject, values }, functions, subappInfoDetails, true ,true);
    // if (values)
    //   updateStateValues(values);
    //props.functions.handleInputChangeV2(value, component, isDynamicTable, datatableRowindex)
  }

  const updateStateValues = (obj) => {
    setPageLevelValues(Object.assign({}, pagelevelValues, obj.pagelevelValues))
    if (obj.values)
      setValues(Object.assign({}, values, obj.values))
    setTransactionPrimaryObject(Object.assign({}, transactionPrimaryObject, obj.transactionPrimaryObject))
    if (obj.pageMetadata)
      setPageMetadata(obj.pageMetadata)
  }

  const UpdateState = (key, value, shouldRerender) => {
    if (shouldRerender) {
       setState((prevState) => (
        { ...prevState,  [key]: value }));
                }
        else {            state[key] = value;     }   };

  const updateValueWithSubplicationName = (updatedValue, component) => {
    let SubapplicationName = component.SubApplicationName;
    setPageLevelValues(prevStateName => {
      let stateName = JSON.parse(JSON.stringify(prevStateName));
      if (SubapplicationName) {
        if (!stateName[SubapplicationName]) stateName[SubapplicationName] = {};
        stateName[SubapplicationName] = { ...stateName[SubapplicationName], ...updatedValue };
      }
      else {
        stateName = {
          ...stateName,
          ...updatedValue
        }
      }
      return stateName;
    })
    setTransactionPrimaryObject(prevStateName => {
      let stateName = JSON.parse(JSON.stringify(prevStateName));
      if (SubapplicationName) {
        if (!stateName[SubapplicationName]) stateName[SubapplicationName] = {};
        stateName[SubapplicationName] = { ...stateName[SubapplicationName], ...updatedValue };
      }
      else {
        stateName = {
          ...stateName,
          ...updatedValue
        }
      }
      return stateName;
    })
  }

  const handleSubmit = async (e, actionCallback) => {
    let dynamicButton = false;
    if(e.target&&e.target.somevalue && Object.keys(e.target.somevalue.component.Action).length) dynamicButton = true;
    if(dynamicButton)
        {
            let { propsObj, component, functions, props } = e.target.somevalue
            let stateObj={
             ...state,
              "pageMetadata":{...pageMetadata},
              "pagelevelValues":{...pagelevelValues},
              "transactionPrimaryObject":{...transactionPrimaryObject}}
            commonButtonMethod(component, {...props} , stateObj,functions, e)
        }
        else
        {
          let Values = mergeDeep(pagelevelValues,values);
          let key = props.subapplicationName.toLowerCase() + "_dataList";
          props.tableUtils.onChildSave(key,subappInfoDetails.subappName,Values,false,values.ID, pageMetadata, { ...values }, props, state)
        }
  }

  const handleDetailAPICall = async (actionCallback) => {
    try {
      ReactDOM.findDOMNode(props.shrink.current).scrollIntoView({ behavior: 'smooth', block: 'start' });
      let key = props.subapplicationName.toLowerCase() + "_dataList"
      let detailSubapp=props.componentProps && props.componentProps.rowData && props.componentProps.rowData['Detail_SubapplicationName'];
      let childSubApp =props.mActionList && props.mActionList.childComponent && props.mActionList.childComponent.childSubapplication;
      actionCallback(key, childSubApp ? childSubApp:(detailSubapp?detailSubapp:""), values, false, values.ID, pageMetadata, { ...values, ...pagelevelValues }, props, state)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  // const handleHiddenInputChange = () => { }

  const functions = {
    UpdateState,
    updateStateValues,
    updateValues:updateStateValues,
    updateValueWithSubplicationName: updateValueWithSubplicationName,
    handleInputChangeV2: handleInputChange
  }

  if (pageMetadata.ApplicationDetail) {
    subapplicationList = pageMetadata.ApplicationDetail && pageMetadata.ApplicationDetail.SubApplicationDetailList;
  }
  let {childsaveNeeded= true} = props

  return (
    <>
      <ValidatorForm
        id='child'
        onSubmit={e => { handleSubmit(e, props.tableFunctions.onChildSave) }}
        onError={e => executeScroll()}
        ref={childform }
      >
        {dynamicRenderV2(subapplicationList, props, { ...state, pagelevelValues, transactionPrimaryObject }, functions, undefined, {}, {}, false, childform)}

        {!props.staticReadonlyRequired && childsaveNeeded && <Button
          type='button'
          color="primary"
          className="nav-btn customSaveButton theme_buttonColor"
          style={styles.button}
          //onClick={() => childform.submit()}
          onClick={(e) => childform.current.submit(e)}
        >
          Save
        </Button>}
        {
        state.loading && <PageLoader />
        }
      </ValidatorForm>
    </>
  )
}

const styles = {
  button: {
    float: 'right',
    marginTop: '15px'
  }
}

const mapStateToProps = state => (
  {
    UIFlowReducer: state.UIFlowReducer
  }
)

const mapDispatchToProps = (dispatch) => (
  {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader())
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(ChildCompV2)
