import React, { Component } from "react";
import { Grid, Typography, InputLabel } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
class Textarea extends Component {
  constructor(props) {
    super(props);
    this.labelRef = React.createRef();
    this.textareaRef = React.createRef();
  }

  componentDidMount = () => {

    if (this.props.component.DefaultValue !== "" && this.props.componentvalue === undefined) {
      this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component);
    }
    ValidatorForm.addValidationRule("IsEmpty", (value) => {
      if (!value) {
        return false;
      }
      return true;
    });
    this.autoResize();
  };

  componentDidUpdate(prevProps) {
    if (this.props.component.DefaultValue !== "" && this.props.componentvalue === undefined) {
      this.props.updateValueWithSubplicationName({ [this.props.component.AttributeName]: this.props.component.DefaultValue }, this.props.component, this.props, prevProps);
    }
    this.autoResize();
  }
  onChange = (e) => {
    e.target.value = e.target.value && e.target.value.trim() === "" ? "" : e.target.value;
    if (this.props.customTable) {
      let value = Object.assign({}, { [e.target.name]: e.target.value });
      this.props.handleRowLevelInputChange(value, this.props.component, this.props.customTableRowIndex,this.props);
    } else {
      let value = Object.assign({}, { [e.target.name]: e.target.value });
      this.props.handleInputChangeV2(value, this.props.component, this.props.isDynamicTable, this.props.datatableRowindex,this.props);
    }
    this.autoResize();
  }
  autoResize = () => {
    const textarea = this.textareaRef.current;   
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight ? `${textarea.scrollHeight}px` : "auto";
      textarea.style.overflow = "hidden"
    }
  };
  render() {
    const { component} = this.props;
    let componentvalue = this.props.componentvalue;
    let isRequired = component.IsRequired === "Y" ? true : false;
    let isReadOnly = component.IsReadonly;
    let componentLabel = isRequired
      ? this.props.componentLabel + " *"
      : this.props.componentLabel;

    componentLabel = (this.props.componentLabel !== "" && this.props.componentLabel !== undefined)
      ? ( isRequired ? this.props.componentLabel + "* " : this.props.componentLabel )
      : ( isRequired ? component.ComponentLabel + "* " : component.ComponentLabel );

    const validations = [];
    const errorMessages = [];
    if (isRequired) {
      validations.push("required");
      errorMessages.push(component.RequiredMessage);
      validations.push("IsEmpty");
      errorMessages.push(component.RequiredMessage);
    }

  if(component.MaxValue > 0) {
    validations.push('minNumber:'+component.MinValue);
    errorMessages.push(component.ValidatorMessage);
    validations.push('maxNumber:'+component.MaxValue);
    errorMessages.push(component.ValidatorMessage);
  }
  if (component.ValidationRegex) {
    validations.push('matchRegexp:'+ component.ValidationRegex);
    errorMessages.push(component.ValidatorMessage);
    }
    let value = "";
    if (component.DefaultValue === "" && componentvalue !== undefined) {
      value = componentvalue;
    }
    if (component.DefaultValue !== "" && componentvalue !== undefined) {
      value = componentvalue;
    }
    if (component.DefaultValue !== "" && componentvalue === undefined) {
      value = component.DefaultValue;
    }
    if (component.DefaultValue === "" && componentvalue === undefined) {
      value = "";
    }
    if (component.DefaultValue !== "" && component.AttributeValue === "" && componentvalue !== undefined) {
      value = componentvalue;
    }
    if( value !== "0")   // clear the leading zeros
    value = value.replace(/^0+/, '');
    let autocomplete="off";
    let custom3=component.Custom3 && JSON.parse(component.Custom3);
    (custom3 && Object.keys(custom3).length >0 && custom3.autoComplete === true )?autocomplete="on":autocomplete="off";
   let labelprops =  {}

    if(!component.Action.hasOwnProperty("RemoveTextareaLabelNeeded") || component.Action.RemoveTextareaLabelNeeded==="N"){
    labelprops.label = (component.AdjacentLabel !== "Y")&&(!this.props.isDynamicTable && (
      <Typography className="custom-label-down small_label text-font" style={styles.label} ref={this.labelRef}>
        {componentLabel}
      </Typography>
    ))
  }

  const styleObject = component.AdjacentLabel === 'Y' ? {display:'flex'} :{}

    return (
      <Grid item xs={12} sm={12} md={component.Size} lg={component.Size}  className={`${component.AdjacentLabel !== 'Y' ? 'custom-text' : null} ${component.AttributeStyleClass}`} style={styleObject}>
        {component.AdjacentLabel === 'Y' ?
            <>
              <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              className={`${component.AttributeStyleClass}_Label`}
              >
                <InputLabel htmlFor={component.AttributeName} className="custom-label-down text-font adjacent_label" ref={this.labelRef}>
                  {componentLabel}
                </InputLabel>
              </Grid>
              <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              className={`${component.AttributeStyleClass}_Test adjacent_TextValidator_Grid`}
              >
                <TextValidator
                  inputRef={this.textareaRef} 
                  name={component.AttributeName}
                  placeholder={componentLabel.replace(/&nbsp;/g, '')}
                  value={value}
                  defaultValue={value}
                  className={`custom-input ${component.StyleClass} lineHeight`}
                  onChange={(e) => this.onChange(e)}
                  disabled={isReadOnly}
                  validators={validations}
                  errorMessages={errorMessages}
                  fullWidth
                  multiline
                  variant="outlined"
                  rows={component.TextRows}
                  cols={component.TextColumns}
                  autoComplete={autocomplete}
        />
              </Grid>
            </>
            :<TextValidator
            inputRef={this.textareaRef}
              name={component.AttributeName}
              placeholder={componentLabel}
              {...labelprops}
              value={value}
              defaultValue={value}
              className={`custom-input ${component.StyleClass} lineHeight`}
              onChange={(e) => this.onChange(e)}
              disabled={isReadOnly}
              validators={validations}
              errorMessages={errorMessages}
              fullWidth
              multiline
              variant="outlined"
              rows={component.TextRows}
              cols={component.TextColumns}
              autoComplete={autocomplete}
              InputProps={{
                classes: {
                  input: 'small_label',
                },
              }}
        />}
      </Grid>
    );
  }
}

const styles = {
  label: {
    width: '100%'
  }
};

export default Textarea;
