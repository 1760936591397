import {
  SET_LANGUAGE_CODE,
  SET_IMAGE_BASE64_FORMAT,
  SET_FAVICON,
  SET_MINI_LOGO_PATH,
  SET_SPINNER_LOGO_PATH,
  SET_CLIENTINFO_KEY,
  THIRD_PARTY_LOGO,
  HOMEPAGE_LOGOS,
  OWNER_RESOURCE,
  SET_COLOR_PALETTE
} from "../actions/types";
import { expressServerBaseURL } from "../views/runtime/utils/Common/config";
import axios from "axios";
import store from "../store";
import { AxiosInstance, getNavigationRequest } from "../views/runtime/utils/Common/API"
 
export const ResourceAxios = async() => {
  return axios.post(`${expressServerBaseURL}resource/resource?domainName=${window.location.hostname}`)
    .then((responseData) => {
      const { LOGO_PATH, DICTIONARY_PATH, ClientInfo,AUTOMOBILE_LOGO_PATH,UMBERLLA_LOGO_PATH,PRIVATEFLOOD_LOGO_PATH,HOMEOWNERS_LOGO_PATH,FAVICON_PATH,MINI_LOGO_PATH,SPINNER_LOGO_PATH,VERISK_LOGO_PATH, USER_LOGO_PATH, LEXIS_LOGO_PATH,CL_LOGO_PATH, MPL_LOGO_PATH, LOGIN_USERLOGO_PATH,SEARCH_LOGO_PATH,COLOR_PALETTE} = responseData.data;
      const { OwnerID } = responseData.data["ClientInfo"];
      let languageCode = [];
      sessionStorage.setItem("OwnerID", OwnerID);
      Object.keys(JSON.parse(DICTIONARY_PATH)).forEach((languageKey) => {
        languageCode.push({
          LanguageCode:
            JSON.parse(DICTIONARY_PATH)[languageKey]["LanguageCode"],
          LanguageName:
            JSON.parse(DICTIONARY_PATH)[languageKey]["LanguageName"],
          LanguageIcon:
            JSON.parse(DICTIONARY_PATH)[languageKey]["LanguageIcon"],
        });
      });

      store.dispatch({
        type: SET_IMAGE_BASE64_FORMAT,
        imageFormat: LOGO_PATH,
        loginUser_logo_path: LOGIN_USERLOGO_PATH,
      });
      store.dispatch({
        type: SET_FAVICON,
        favicon: FAVICON_PATH,
      });
      store.dispatch({
        type: SET_MINI_LOGO_PATH,
        mini_logo_path: MINI_LOGO_PATH,
      });
      store.dispatch({
        type: SET_SPINNER_LOGO_PATH,
        spinner_logo_path: SPINNER_LOGO_PATH,
      });
      store.dispatch({
        type: SET_LANGUAGE_CODE,
        languageCode: languageCode,
      });
      store.dispatch({
        type: SET_CLIENTINFO_KEY,
        clientInfo: { ...ClientInfo },
      });
      store.dispatch({
        type: THIRD_PARTY_LOGO,
        veriskLogo: VERISK_LOGO_PATH,
        lexisLogo: LEXIS_LOGO_PATH,
        userLogo: USER_LOGO_PATH,
      });
      store.dispatch({
        type: HOMEPAGE_LOGOS,
        homeowners_logo_path: HOMEOWNERS_LOGO_PATH,
        search_logo_path:SEARCH_LOGO_PATH,
        privateflood_logo_path: PRIVATEFLOOD_LOGO_PATH,
        automobile_logo_path: AUTOMOBILE_LOGO_PATH,
        umberlla_logo_path: UMBERLLA_LOGO_PATH,
        cl_logo_path:CL_LOGO_PATH,
        mpl_logo_path:MPL_LOGO_PATH
      });
      store.dispatch({
        type: OWNER_RESOURCE,
        imagesource: responseData.data,
      });
      store.dispatch({
        type: SET_COLOR_PALETTE,
        ColorPalette: COLOR_PALETTE,
      });
      return { ...JSON.parse(DICTIONARY_PATH) };
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const getNavigation = async (navigations, productVerNum, EffectiveDate) => {
   let request = await getNavigationRequest(navigations, productVerNum, EffectiveDate);
   //let request= getNavigationRequestJSON;
  try {
  let response = await AxiosInstance.post("/core/invokeWorkflowEvent/V4", request);
    //  let response = sampleNavigationResponse
    let obj = {}

    if(response.data && response.data.PageNavigation){
      obj["pageNavigation"] = response.data.PageNavigation
    }
    if(response.data && response.data.ProductNavigation){
      obj["productNavigation"] = response.data.ProductNavigation
    }
    if(response.data && response.data.TemplateInfo){
      obj['templateInfo'] = response.data.TemplateInfo
    }
    // if(Object.keys(obj).length > 0){
    //   store.dispatch({
    //     type: SET_NAVIGATION,
    //     payload: obj,
    //   });
    // }

    if(response.data && response.data.QuoteNavigation){
      obj['quoteNavigation'] = response.data.QuoteNavigation
    }
    if(response.data && response.data.PolicyNavigation){
      obj['policyNavigation'] = response.data.PolicyNavigation
    }
    if(response.data && (response.data.EndorsementNavigation || response.data.CancelNavigation || response.data.ReinstateNavigation)){
      obj={...response.data}
    }
    if (response.data && response.data.ProductTemplateInfo){
      obj['productTemplateInfo'] = response.data.ProductTemplateInfo
    }
    return obj
  }
  catch (err) {
    console.log(err)
  }
}
